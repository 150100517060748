import { Component, OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit, OnDestroy {
  readyForRender = false;
  localizeReady = false
  paramsReady = false;
  subscriptions: Subscription[] = [];
  otherUserKey: string = null;
  constructor(private _localizeService: LocalizeService, private _router: Router, private _route: ActivatedRoute) { }

  scheduleAppointment($event) {
    $event.preventDefault();

    this._router.navigate(['home']);
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
      }
    }));
    this.subscriptions.push(
      this._route.params.subscribe((params) => {
        if (params && params.userKey) {
          this.otherUserKey = params.userKey
        }
        this.paramsReady = true;
        this.checkReady();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
  checkReady() {
    if (this.localizeReady && this.paramsReady) {
      this.readyForRender = true;
    }
  }

}

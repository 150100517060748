<ng-container *ngIf="readyForRender && !hasError">
    <h5 class="primary-text" id="titleId-UpcomingAppointments">{{ 'upcomingappointments' | lesCustomLocalize : 'label' }}</h5>
    <app-wcs-table
        [records]="activeAppointments"
        [settings]="displaySettings"
        [htmlId]="'UpcomingAppointments'"
        [currentUser]="currentUser"
        [currentSortProp]="'time'"
        [currentSortDirection]="'asc'"
        [pageSize]="6"
        [useBootstrapResponsiveTable]="false"
    ></app-wcs-table>

    <ng-container *ngIf="inactiveAppointments.length">
        <br />
        <h5 class="primary-text" id="titleId-PastOrCanceledAppointments">{{ 'pastorcanceledappointments' | lesCustomLocalize : 'label' }}</h5>
        <app-wcs-table
            [records]="inactiveAppointments"
            [settings]="displaySettings"
            [htmlId]="'PastOrCanceledAppointments'"
            [currentUser]="currentUser"
            [currentSortProp]="'time'"
            [currentSortDirection]="'desc'"
            [pageSize]="6"
            [useBootstrapResponsiveTable]="false"
        ></app-wcs-table>
    </ng-container>
</ng-container>

<app-error *ngIf="hasError" [htmlId]="'Appointments'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

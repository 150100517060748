
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// import environment
import { environment } from '../../../environments/environment';
import { Gender } from '../gender/gender';
import { GenderIdentity, SexualOrientation } from '../gender/SOGI';
import { LabReferenceCode } from '../lab-reference-code/lab-reference-code';
import { Locale } from '../localize/locale';
import { State } from '../state/state';
import { TimeZone } from '../time-zone/time-zone';
import { Ethnicity } from './ethnicity';
import { Race } from './race';
import { ZipCode } from './zipcode';

@Injectable()
export class MetadataService {
    private ethnicitySource = new BehaviorSubject<Ethnicity[]>(null);
    ethnicityList = this.ethnicitySource.asObservable();

    private localeSource = new BehaviorSubject<Locale[]>(null);
    localeList = this.localeSource.asObservable();

    private raceSource = new BehaviorSubject<Race[]>(null);
    raceList = this.raceSource.asObservable();

    private stateSource = new BehaviorSubject<State[]>(null);
    stateList = this.stateSource.asObservable();

    private timezoneSource = new BehaviorSubject<TimeZone[]>(null);
    timezoneList = this.timezoneSource.asObservable();

    private genderSource = new BehaviorSubject<Gender[]>(null);
    genderList = this.genderSource.asObservable();
    private sexualOrientationSource = new BehaviorSubject<SexualOrientation[]>(null);
    sexualOrientaionList = this.sexualOrientationSource.asObservable();
    private genderIdentitySource = new BehaviorSubject<GenderIdentity[]>(null);
    genderIdentityList = this.genderIdentitySource.asObservable();

    constructor(private _http: HttpClient) { }

    // getLocales() {
    //   this._http.get<Locale[]>('../../api/locales.json')
    //     .subscribe(data => {
    //       this.localeSource.next(data);
    //     });
    // }

    getLocales(companyKey: string) {
        let params = new HttpParams();
        params = params.append('companyKey', companyKey);

        return this._http.get<Locale[]>(environment.baseCoreApiEndpoint + '/metadata/languages', { params: params }).pipe(catchError(this.handleError));
    }

    getZipCodes(zip: string) {
        let params = new HttpParams();
        params = params.append('zipCode', zip);

        return this._http.get<ZipCode[]>(environment.baseCoreApiEndpoint + '/metadata/zips', { params: params }).pipe(catchError(this.handleError));
    }

    getTimeZones() {
        this._http.get<TimeZone[]>(environment.baseCoreApiEndpoint + '/metadata/timezone').subscribe((data) => {
            this.timezoneSource.next(data);
        });
    }

    getStates() {
        this._http.get<State[]>(environment.baseCoreApiEndpoint + '/metadata/states').subscribe((data) => {
            this.stateSource.next(data);
        });
    }

    getRaces() {
        this._http.get<Race[]>(environment.baseCoreApiEndpoint + '/metadata/races').subscribe((data) => {
            this.raceSource.next(data);
        });
    }

    getEthinicities() {
        this._http.get<Ethnicity[]>(environment.baseCoreApiEndpoint + '/metadata/ethnicities').subscribe((data) => {
            this.ethnicitySource.next(data);
        });
    }

    getLabReferenceCodes(programServiceTypeKey: string, programServiceKey: string) {
        let params = new HttpParams();
        params = params.append('programServiceTypeKey', programServiceTypeKey);
        params = params.append('programServiceKey', programServiceKey);

        return this._http.get<LabReferenceCode[]>(environment.baseCoreApiEndpoint + '/metadata/lab-reference-codes', { params: params }).pipe(catchError(this.handleError));
    }

    getGenders(languageCode: string) {
        let params = new HttpParams();
        params = params.append('languageCode', languageCode);

        return this._http.get<Gender[]>(environment.baseCoreApiEndpoint + '/metadata/genders', { params: params }).pipe(catchError(this.handleError));
    }
    getSexualOrientationList() {
        this._http.get<SexualOrientation[]>(environment.baseCoreApiEndpoint + '/metadata/sexual-orientations').subscribe((data) => {
            this.sexualOrientationSource.next(data);
        });
    }
    getGenderIdentityList() {
        this._http.get<GenderIdentity[]>(environment.baseCoreApiEndpoint + '/metadata/gender-identities').subscribe((data) => {
            this.genderIdentitySource.next(data);
        });
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

<ng-container *ngIf="readyForRender && !hasError">
    <app-wcs-table
        [records]="resultList"
        [settings]="displaySettings"
        [currentUser]="currentUser"
        [currentSortProp]="'resultDate'"
        [currentSortDirection]="'desc'"
        [htmlId]="'ResultList'"
        [pageSize]="pageSize"
        [useBootstrapResponsiveTable]="false"
        [turnOffChangeDetection]="true"
    ></app-wcs-table>
</ng-container>

<app-error *ngIf="hasError" [htmlId]="'ResultList'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { User } from '../../shared/user/user';
import { UserService } from '../../shared/user/user.service';
import { ColumnSetting, LinkSetting } from '../../shared/wcs-table/column-setting';
import { WcsTableComponent } from '../../shared/wcs-table/wcs-table.component';
import { CoachEditComponent } from '../coach-edit/coach-edit.component';
import { AddMassSecureMessageComponent } from '../secure-messaging/add-mass-secure-message/add-mass-secure-message.component';

@Component({
  selector: 'app-coach-list',
  templateUrl: './coach-list.component.html',
  styleUrls: ['./coach-list.component.css']
})
export class CoachListComponent implements OnInit, OnDestroy {
  @ViewChild(WcsTableComponent) tableComponent: WcsTableComponent;

  hasError = false;
  readyForRender = false;
  coaches: User[];
  modalRef: NgbModalRef;
  subscriptions: Subscription[] = [];
  currentUser: Person;

  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'fullName',
      headerLocalizeProp: 'fullname',
      showOnMobile: true,
      format: 'link',
      link: {
        text: '',
        onClick: this.viewCoachDetails
      },
      thisContext: this,
      sortable: true
    },
    {
      valueProp: 'hasBio',
      headerLocalizeProp: 'hasbio',
      showOnMobile: true,
      format: 'boolean',
      sortable: true
    },
    {
      valueProp: 'hasPhoto',
      headerLocalizeProp: 'hasphoto',
      showOnMobile: false,
      format: 'boolean',
      sortable: true
    },
    {
      valueProp: 'isGeneralInboxMonitor',
      headerLocalizeProp: 'generalinboxmonitor',
      showOnMobile: true,
      format: 'boolean',
      sortable: true
    },
    {
      valueProp: 'status',
      headerLocalizeProp: 'status',
      showOnMobile: false,
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      link: {
        text: 'edit',
        onClick: this.editCoach
      },
      thisContext: this,
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      link: {
        text: 'messagecoachparticipants',
        onClick: this.createMassMessage
      },
      thisContext: this,
      sortable: false
    }
  ];

  constructor(private _userService: UserService, private _localizeService: LocalizeService, private _modalService: NgbModal,
    private _router: Router, private _personService: PersonService) { }

  editCoach(coach: User) {
    if (!coach) {
      this.modalRef = this._modalService.open(AlertModalComponent, {
        backdrop: 'static'
      });
      this.modalRef.componentInstance.msg = this._localizeService.get('errorhandle', 'message');
      return;
    }
    this.modalRef = this._modalService.open(CoachEditComponent, {
      backdrop: 'static',
      windowClass: 'tq-modal'
    });
    this.modalRef.componentInstance.coach = coach;

    this.modalRef.result.then(() => {
      this.tableComponent.detectChanges();
    });
  }

  createMassMessage(coach: User) {
    if (!coach) {
      // TODO: Display error message
    }

    this.modalRef = this._modalService.open(AddMassSecureMessageComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    this.modalRef.componentInstance.coach = coach;
  }

  viewCoachDetails(coach: User) {
    this._router.navigate(['/coaching/dashboard', coach.key]);
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentUser = currentPerson;
      }
    }));

    this.subscriptions.push(this._userService.getCoachUsers().subscribe((coaches: any) => {
      if (coaches) {
        this.coaches = [];
        coaches.forEach(coach => {
          this.coaches.push(new User(coach));
        });
        this.readyForRender = true;
      }
    },
      error => {
        // TODO: Display error message?
        this.hasError = true;
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Partner } from './partner';

@Injectable()
export class PartnerService {
  private allPartnerSource = new BehaviorSubject<Partner[]>(null);
  allPartners = this.allPartnerSource.asObservable();

  constructor(private _http: HttpClient) {
    this.fetchPartners();
  }

  fetchPartners() {
    this._http.get<Partner[]>(environment.baseCoreApiEndpoint + '/partners')
      .subscribe(data => {
        this.allPartnerSource.next(data);
      });
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}

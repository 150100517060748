import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ApiAppointment } from '../../../scheduler/model/api-appointment';
import { IElectronicOrderModel, ILabcorpAppointmentLocation, ILabcorpAppointmentLocationSearch, ILabcorpAppointmentRequest, ILabcorpAppointmentRequestAppointment, ILabcorpAppointmentRequestPatient, ILabcorpAppointmentTimeSlot } from '../labcorp.appointment.models';

@Injectable()
export class LabcorpAppointmentService {
    constructor(private _http: HttpClient) { }

    queryAppointmentTimes(serviceId: number, locationId: number, programServiceKey: string, timeZone = 'EST'): Observable<ILabcorpAppointmentTimeSlot[]> {
        return this._http.get<ILabcorpAppointmentTimeSlot[]>(`${environment.baseSchedulerEndpoint}/psc/appointments/times?serviceId=${serviceId}&locationId=${locationId}&programServiceKey=${programServiceKey}&labTimeZone=${timeZone}`);
    }

    queryAppointmentLocations(query: ILabcorpAppointmentLocationSearch): Observable<any> {
        return this._http.post<ILabcorpAppointmentLocation[]>(`${environment.baseSchedulerEndpoint}/psc/locations/search`, query);
    }

    createAppointment(request: ILabcorpAppointmentRequest): Observable<any> {
        const that = this;
        return Observable.create(function (observer) {
            that._http.post<any>(`${environment.baseSchedulerEndpoint}/psc/appointments`, request).subscribe((r) => {
                that._http.post<any>(`${environment.baseDocumentApiEndpoint}/document/generateelectronicorder`, request.electronicOrder).subscribe((dr) => {
                    observer.next(r);
                    observer.complete();
                });

            });
        });
    }

    getAppointments(userKey: string, excludeCanceled: boolean): Observable<ApiAppointment[]> {
        return this._http.get<ApiAppointment[]>(`${environment.baseSchedulerEndpoint}/psc/appointments?userKey=${userKey}&excludeCanceled=${excludeCanceled}`);
    }

    cancelAppointment(appointmentKey: string, appointmentRequest: ILabcorpAppointmentRequest): Observable<any> {
        return this._http.put(`${environment.baseSchedulerEndpoint}/psc/appointments/${appointmentKey}/cancel`, appointmentRequest);
    }

    updateAppointment(appointmentKey: string, request: ILabcorpAppointmentRequestAppointment): Observable<any> {
        return this._http.put<any>(`${environment.baseSchedulerEndpoint}/psc/appointments/${appointmentKey}`, request);
    }

    locateAppointment(confirmationNumber: string, firstName: string, lastName: string): Observable<ILabcorpAppointmentRequestAppointment> {
        return this._http.get<ILabcorpAppointmentRequestAppointment>(`${environment.baseSchedulerEndpoint}/psc/appointments/locate/${confirmationNumber}?firstName=${firstName}&lastName=${lastName}`);
    }

    addAppointment(request: ILabcorpAppointmentRequest): Observable<any> {
        const that = this;
        return Observable.create(function (observer) {
            that._http.post<any>(`${environment.baseSchedulerEndpoint}/psc/appointments/locate`, request).subscribe((r) => {
                that._http.post<any>(`${environment.baseDocumentApiEndpoint}/document/generateelectronicorder`, request.electronicOrder).subscribe((dr) => {
                    if (!dr) {
                        observer.next(false);
                    }
                    observer.next(r);
                    observer.complete();
                });
            });
        });
    }

    getLocation(locationId: number): Observable<any> {
        return this._http.get(`${environment.baseSchedulerEndpoint}/psc/locations/${locationId}`);
    }
}

<div *ngIf="isReady && currentUser && currentUser.isParticipant">
    <div>
        <h1 class="primary-text">{{ 'welcome' | lesCustomLocalize : 'label' }}!</h1>
        <p [innerHTML]="'welcomeblurb' | lesCustomLocalize : 'message'"></p>
    </div>
    <div class="services-list">
        <app-services-list></app-services-list>
    </div>

    <div class="next-appts row">
        <div class="col-md-12" *ngIf="currentUser.hasSchedulerAccess">
            <app-past-appointments [disableIfUpcomingAppointment]="true"></app-past-appointments>
        </div>
    </div>

    <ng-container *ngIf="currentUser.hasResultsAccess">
        <h4 class="primary-text" id="titleId-YourLatestResults">{{ 'yourresultslastxmonths' | lesCustomLocalize : 'label' : 12 }}</h4>
        <app-result-list [pageSize]="2" [daysToShow]="365"></app-result-list>
        <a class="pt-2 pb-2" routerLink="/results">{{ 'gotoresults' | lesCustomLocalize : 'message' }}</a>
    </ng-container>
</div>

<div *ngIf="isReady && currentUser && !currentUser.isParticipant">
    <div>
        <h3 class="primary-text">{{ 'welcome' | lesCustomLocalize : 'label' }}!</h3>
        <p [innerHTML]="'welcomeblurbnonparticipant' | lesCustomLocalize : 'message'"></p>
    </div>
</div>

<div *ngIf="!isReady">
    <div class="alert alert-primary"><i class="fa fa-spin fa-refresh fa-2x" aria-hidden="true"></i> Loading...</div>
</div>

<div *ngIf="isReady && !currentUser">
    <div *ngIf="!unknownType" class="alert alert-primary"><i class="fa fa-spin fa-refresh fa-2x" aria-hidden="true"></i> {{ 'loading' | lesCustomLocalize : 'label' }}</div>
</div>

import { Component, EventEmitter, Input, OnDestroy,OnInit, Output } from '@angular/core';
import moment from 'moment';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../../person-widgets/person.widget.models';
import { ConsentAndAcknowledgement, ConsentDefinitionAcknowledgementModel, ConsentDefinitionAcknowledgementsPostModel } from '../../shared/consent-modal/consent';
import { ConsentService } from '../../shared/consent-modal/consent.service';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Ethnicity } from '../../shared/metadata/ethnicity';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Race } from '../../shared/metadata/race';
import { Person } from '../../shared/person/person';
import { State } from '../../shared/state/state';
import { UserService } from '../../shared/user/user.service';
import { UserInformation } from '../../shared/user/user-information';
import { Utils } from '../../shared/utils';
import { UserDataCaptureResult } from './../../user-data-capture-modal/user-data-capture';

@Component({
    selector: 'app-person-consent-widget',
    templateUrl: './person-consent-widget.component.html',
    styleUrls: ['./person-consent-widget.component.css']
})
export class PersonConsentWidgetComponent implements OnInit, OnDestroy {
    @Input() personWidget: PersonWidgetCollectionWidget;
    @Input() currentPerson: Person;
    @Input() otherPerson?: Person;
    @Input() programServiceKey: string;
    @Input() programServiceTypeKey: string;
    @Input() personKey: string;
    @Output() onWidgetCompleted = new EventEmitter<any>();
    @Output() onUpdateAttempted = new EventEmitter<any>();
    userInformation: UserInformation = new UserInformation();
    races: Race[];
    ethnicities: Ethnicity[];
    isPersonRender = false;
    states: State[];
    userDataCaptureResult: UserDataCaptureResult = new UserDataCaptureResult();
    subscriptions: ISubscription[] = [];
    _userKey: string;
    showError = false;
    errorList: any;
    errorMsg: string = null;
    consentRecord: ConsentAndAcknowledgement;
    isRenderReady = false;
    public hasError = false;
    public isAgreed = false;
    public isDeclined = false;
    public consentDefinitionAcknowledgementsPostModel: ConsentDefinitionAcknowledgementsPostModel;
    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentPerson;
    }

    get userKey(): string {
        return this._userKey;
    }
    constructor(private _metadataService: MetadataService, private _userService: UserService, private _localizeService: LocalizeService, private _consentService: ConsentService) { }
    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }
    ngOnInit(): void {
        this.loadConsent();
    }
    loadConsent() {
        const search: any = {};
        search['programServiceKey'] = this.programServiceKey;
        search['participantKey'] = this.person().participantKey;
        search['userKey'] = this.person().userKey;
        search['companyKey'] = this.person().company.key;
        search['consentDefinitionKey'] = this.personWidget.consentDefinitionKey;
        const langCode = this.person().user && this.person().user.locale && this.person().user.locale.languageLocale;
        if (langCode) {
            search['preferredLanguage'] = search['languageLocale'] = langCode;
        }
        this._consentService.getByKeyWithAcknowledgement(search).subscribe((r) => {
            this.consentRecord = r;
            //Need explicit  true/false for html component
            if (r.acceptedOn != null && r.declinedOn != null) {
                const isAcceptedLastest = moment(r.acceptedOn).isBefore(r.acceptedOn);
                const isDeclineLatest = moment(r.acceptedOn).isBefore(r.acceptedOn);
                if (isAcceptedLastest) {
                    this.isAgreed = true;
                }
                if (isDeclineLatest) {
                    this.isDeclined = true;
                }
            } else {
                this.isAgreed = r && r.acceptedOn && r.consentDefinitionKey != Globals.DefaultValues.EmptyGuid && Utils.isValidJsDate(r.acceptedOn) ? true : false;
                this.isDeclined = r && r.declinedOn && r.consentDefinitionKey != Globals.DefaultValues.EmptyGuid && Utils.isValidJsDate(r.declinedOn) ? true : false;
            }
            this.isRenderReady = true;
        });
    }

    localizeMessage(msgTag) {
        let errorTag = 'unknownerror';
        if (msgTag) {
            try {
                errorTag = msgTag.split('.')[1];
            } catch (error) { }
        }
        return errorTag;
    }
    localizeValidationErrors(err) {
        let errorName = this.localizeMessage(err.value);
        if (errorName !== 'unknownerror') errorName = err.key + errorName;
        return errorName;
    }

    private closePanel(complete: boolean, action: WidgetCloseAction) {
        //send back result
        const result = new WidgetCompleted();
        result.dataCollectionKey = this.personWidget.dataCollectionKey;
        result.widgetKey = this.personWidget.widgetKey;
        result.isComplete = complete;
        result.action = action;
        result.consentDefinitionKey = this.consentRecord.consentDefinitionKey;
        result.widgetPanelIndex = this.personWidget.widgetPanelIndex;
        this.onWidgetCompleted.emit(result);
    }
    onPreviousButtonClick() {
        //if button is present
        if (this.personWidget.position == WidgetCollectionPosition.first) return;

        this.processAction(WidgetCloseAction.backward, false);
    }
    onNextButtonClick() {
        if (this.isAgreed == false && this.isDeclined == false) {
            this.hasError = true;
            return;
        }
        this.processAction(WidgetCloseAction.forward, true);
    }
    closeError() {
        this.hasError = false;
    }
    private processAction(next: WidgetCloseAction, validate = true) {
        if (!validate) {
            return this.closePanel(false, next);
        }
        this.hasError = false;
        if (this.consentRecord.acceptedOn === null && this.consentRecord.declinedOn === null) {
            this.hasError = true;
            return;
        }
        const consentAck = this.getPostModel();
        //Edge Case: A person constantly toggling between Accept/Decline
        if (this.consentRecord.acceptedOn != null && this.consentRecord.declinedOn != null) {
            if (this.isAgreed) {
                consentAck.declinedOn = null;
            }
            if (this.isDeclined) {
                consentAck.acceptedOn = null;
            }
        }
        if (this.consentRecord.acknowledgementKey && this.consentRecord.acknowledgementKey != Globals.DefaultValues.EmptyGuid) {
            //update
            consentAck.createdBy = this.consentRecord.acknowledgementCreatedBy;
            consentAck.createdOn = this.consentRecord.acknowledgementCreatedOn;
            consentAck.modifiedBy = this.person().userKey;
            consentAck.modifiedOn = new Date();
            consentAck.personKey = this.personKey;
            consentAck.participantKey = this.person().participantKey;
            consentAck.userKey = this.person().userKey;
            this._consentService.setConsentCapture(consentAck).subscribe(
                (data) => {
                    if (!data) {
                        this.onError();
                    } else {
                        this.hasError = false;
                        this.closePanel(true, next);
                    }
                },
                (error) => {
                    this.onError();
                }
            );
        } else {
            //create
            this.consentDefinitionAcknowledgementsPostModel = new ConsentDefinitionAcknowledgementsPostModel([this.getPostModel()], new Date(), this.personKey, this.person().participantKey, this.person().userKey, null, this.person().userKey);
            this._consentService.setConsentCaptures(this.consentDefinitionAcknowledgementsPostModel).subscribe(
                (data) => {
                    if (!data) {
                        this.onError();
                    } else {
                        this.hasError = false;
                        this.closePanel(true, next);
                    }
                },
                (error) => {
                    this.onError();
                }
            );
        }
    }

    private onError(message: any = null, list: any = null) {
        this.hasError = true;
        if (list) {
            this.errorList = list;
        }
        if (message) {
            this.errorMsg = message;
        }
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
    }
    addUserKey() {
        if (!this.isValidGuid(this.userInformation.userKey)) {
            this.userInformation.userKey = this.person().userKey;
        }
    }
    isValidGuid(guid: string): boolean {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
    }
    getTranslation(tag: string) {
        return this._localizeService.getCombinedTag(tag, null, null, null, this.tagOptions);
    }
    getNextMessage() {
        if (this.personWidget.position == WidgetCollectionPosition.last) {
            return this.getTranslation('button.submit');
        }
        return this.getTranslation('button.next');
    }
    private getPostModel() {
        return new ConsentDefinitionAcknowledgementModel(this.consentRecord.consentDefinitionKey, this.programServiceKey, this.consentRecord.acceptedOn, this.consentRecord.declinedOn, this.consentRecord.acknowledgementKey);
    }

    toggleAgree(event: any) {
        if (event.target.checked) {
            this.consentRecord.acceptedOn = new Date();
            this.consentRecord.declinedOn = null;
            this.isDeclined = false;
        } else {
            this.consentRecord.acceptedOn = null;
            this.isAgreed = false;
        }
    }
    toggleDecline(event: any) {
        if (event.target.checked) {
            this.consentRecord.declinedOn = new Date();
            this.consentRecord.acceptedOn = null;
            this.isAgreed = false;
        } else {
            this.consentRecord.declinedOn = null;
            this.isDeclined = false;
        }
    }
    private replaceNewlines(txt) {
        if (txt != null) {
            return txt.replace(/<p\b[^>]*><br[\/]?><\/p>/g, '');
        }
        return null;
    }
    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
}

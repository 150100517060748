
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {map} from 'rxjs/operators';

import { Globals } from './shared/globals';
import { LocalizeService } from './shared/localize/localize.service';
import { Person } from './shared/person/person';
import { PersonService } from './shared/person/person.service';

@Injectable()
export class SearchGuard  {
  constructor(private _personService: PersonService, private _router: Router, private _localizeService: LocalizeService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._personService.currentPersonReplay.pipe(map( person => {
      if (person.canSearch) {
        return true;
      } else {
        this.noAccess();
        this._router.navigate(['/']);
        return false;
      }
    }));
  }

  private noAccess() {
    window.alert(this._localizeService.get('noaccess', 'message'));
  }
}

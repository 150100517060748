import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AccessMode } from '../../shared/access-mode';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';

@Component({
  selector: 'app-coaching-dashboard',
  templateUrl: './coaching-dashboard.component.html',
  styleUrls: ['./coaching-dashboard.component.css']
})
export class CoachingDashboardComponent implements OnInit, OnDestroy {
  allAccessMode = AccessMode.CoachingDashboard;
  secureMessagingMode = AccessMode.CoachingDashboard;
  showAppointments = true;
  showAllAccess = true;
  showSecureMessaging = true;
  readyForRender = false;
  localizeReady = false;
  subscriptions: ISubscription[] = [];

  sectionValues = Globals.SectionValues;
  sectionToShow = Globals.SectionValues.Appointments;
  coachKey: string;
  coach: Person = null;
  otherCoach = false;

  constructor(private _route: ActivatedRoute, private _localizeService: LocalizeService, private _personService: PersonService,
    private _router: Router) {
    _route.params.subscribe(params => {
      this.coachKey = params.key;
      this.otherCoach = true;
    });
  }

  changeSection(section) {
    this.sectionToShow = section;
  }

  checkReady() {
    this.readyForRender = this.localizeReady && this.coach !== null;
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(ready => {
      this.localizeReady = true;
      this.checkReady();
    }));

    this.subscriptions.push(this._personService.currentPerson.subscribe(person => {
      if (person && !person.isCoach && !person.isCoachAdmin) {
        this._router.navigate(['home']);
      } else {
        if (!this.otherCoach) {
          this.coachKey = person.userKey;
          this.coach = person;
          this.checkReady();
        }
      }
    }));

    if (this.otherCoach) {
      this.subscriptions.push(this._personService.getOtherPerson(this.coachKey, false).subscribe(otherCoach => {
        this.coach = otherCoach;
        this.checkReady();
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

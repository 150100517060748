<ng-container *ngIf="isReady">
    <div class="modal-header">
        <h4 class="secondary-text modal-title" id="titleId-UploadYourForm">{{ 'uploadChooseFile' | lesCustomLocalize : 'label' }}</h4>
        <button type="button" class="tertiary-bg close" id="actionId-CloseUploadYourForm" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="container">
            <div class="row col-md-12 top-buffer" id="messageId-UploadInstruction">
                {{ 'uploadInstruction' | lesCustomLocalize : 'label' }}
            </div>

            <div class="row col-md-12 top-buffer"></div>
            <input type="file" ng2FileSelect [uploader]="uploader" />

            <div class="well my-drop-zone top-buffer" ng2FileDrop [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }" (fileOver)="fileOverBase($event)" [uploader]="uploader">
                {{ 'uploadDragAndDrop' | lesCustomLocalize : 'label' }}
            </div>

            <table class="table">
                <thead class="primary-text">
                    <tr>
                        <th width="50%">{{ 'uploadName' | lesCustomLocalize : 'label' }}</th>
                        <th>{{ 'uploadSize' | lesCustomLocalize : 'label' }}</th>
                        <th>{{ 'uploadType' | lesCustomLocalize : 'label' }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue" class="secondary-text">
                        <td>
                            <strong>{{ item?.file?.name }}</strong>
                        </td>
                        <td>{{ item?.file?.size / 1024 / 1024 | number : '.2' }} MB</td>
                        <td>{{ item?.file?.type }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="progress" id="messageId-UploadProgress" style="">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ width: uploader.progress + '%' }"></div>
            </div>

            <div class="col-md-12 top-buffer row" style="margin-bottom: 5px"></div>

            <div *ngIf="isSuccess" id="messageId-UploadSuccess" class="col-md-12 top-buffer row alert-success">
                {{ 'uploadsuccess' | lesCustomLocalize : 'message' }}
            </div>

            <div *ngIf="isFailure" class="col-md-12 top-buffer row alert-danger">
                <div *ngIf="isFailure" class="alert-danger" id="messageId-UploadFailure">{{ 'uploadfailure' | lesCustomLocalize : 'message' }}</div>
                <div *ngIf="isFailure" class="alert-danger" id="messageId-UploadFailureReason">{{ 'uploadfailureReason' | lesCustomLocalize : 'message' }}</div>
            </div>

            <div class="row top-buffer col-md-12 pull-right">
                <button type="button" *ngIf="uploader.getNotUploadedItems().length" class="tertiary-bg btn btn-primary right-margin-buffer" (click)="uploader.uploadAll()" id="actionId-UploadAll" [disabled]="!uploader.getNotUploadedItems().length">
                    <span class="glyphicon glyphicon-upload"></span>{{ 'upload' | lesCustomLocalize : 'label' }}
                </button>

                <button type="button" class="tertiary-bg btn btn-secondary" *ngIf="uploader.getNotUploadedItems().length" (click)="uploader.clearQueue()" id="actionId-UploadRemove" [disabled]="!uploader.getNotUploadedItems().length">
                    <span class="glyphicon glyphicon-trash"></span> {{ 'uploadRemove' | lesCustomLocalize : 'label' }}
                </button>
            </div>
        </div>
    </div>
</ng-container>

<div class="modal-footer pt-10"></div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LabReferenceCode } from '../shared/lab-reference-code/lab-reference-code';
import { MetadataService } from '../shared/metadata/metadata.service';
import { PersonBiometric, PersonBiometricPut, PersonBiometricSubmits } from '../shared/user/person-biometric';
import { UserService } from '../shared/user/user.service';
import { HeightFeetSelection, HeightInchesSelection } from '../shared/user/user-biometric-selections';
import { UserBiometricCapture, UserBiometricCaptureResult } from './user-biometric-capture';

@Component({
    selector: 'app-biometric-capture-modal',
    templateUrl: './user-biometric-capture-modal.component.html',
    styleUrls: ['./user-biometric-capture-modal.component.css']
})
export class UserBiometricCaptureModalComponent implements OnInit, OnDestroy {
    _userKey: string;
    _programServiceTypeKey: string;
    _serviceKey: string;
    biometricForm: FormGroup;
    userBiometric: PersonBiometric = new PersonBiometric();
    labReferenceCodes: LabReferenceCode[] = [];
    userBiometricCaptureResult: UserBiometricCaptureResult = new UserBiometricCaptureResult();
    subscriptions: ISubscription[] = [];
    heightFeetChoices: HeightFeetSelection[] = [];
    heightInchesChoices: HeightInchesSelection[] = [];
    isRenderReady = false;
    showError = false;
    justDoNotKnowSelections = 0;
    noDigitsRegex = /^-?\d+$/;
    oneDigitRegex = /^[0-9]\d{0,9}(\.\d{1,1})?%?$/;
    errorList: any;
    errorMsg: string = null;

    showHeight = false;
    showWeight = false;
    showWaist = false;
    showNeck = false;
    showHip = false;
    showBloodPressure = false;
    showHeartRate = false;
    showBodyFat = false;

    // Three inputs are expected, user key, service type key and service key. Service type key
    // and service key are used to get all lab references codes. The lab reference codes control
    // what biometrics are captured.
    @Input() set userKey(val: string) {
        this._userKey = val;
    }
    get userKey(): string {
        return this._userKey;
    }

    @Input() set programServiceTypeKey(val: string) {
        this._programServiceTypeKey = val;
    }

    @Input() set programServiceKey(val: string) {
        this._serviceKey = val;
        this._userService.getUserBiometric(this._userKey, val).subscribe((info) => {
            if (info != null) {
                this.userBiometric = info;
            }
            if (val && this.isValidGuid(val)) {
                this.userBiometric.userKey = this._userKey;
                this.userBiometric.programServiceKey = val;
            }
        });
        this._metaDataService.getLabReferenceCodes(this._programServiceTypeKey, this._serviceKey).subscribe((codes) => {
            if (codes != null) {
                this.labReferenceCodes = codes;
                // Flip bits based on designated test codes for the service type key and service key.
                this.showHeight = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Height';
                });
                this.showWeight = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Weight';
                });
                this.showWaist = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Waist (in)';
                });
                this.showNeck = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Neck (in)';
                });
                this.showHip = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Hip (in)';
                });
                this.showBloodPressure = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Blood Pressure 1 (SBP/DBP)';
                });
                this.showHeartRate = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Heart Rate';
                });
                this.showBodyFat = _.find(this.labReferenceCodes, function (c: LabReferenceCode) {
                    return c.testCode === 'Body Fat';
                });
            }
            this.createForm();
        });
    }

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private _metaDataService: MetadataService, private _userService: UserService) {
        this.loadDateDropDowns();
    }
    ngOnInit(): void {
        console.info('UserBiometricCaptureModalComponent called.')
    }

    // Height in feet and inches are dop downs and are populated here.
    private loadDateDropDowns(): void {
        for (let iFeet = 2; iFeet <= 7; iFeet++) {
            const heightFeetChoice = new HeightFeetSelection();
            if (iFeet === 2) {
                heightFeetChoice.value = '';
                heightFeetChoice.desc = 'Select';
            } else {
                heightFeetChoice.value = iFeet.toString();
                heightFeetChoice.desc = iFeet.toString();
            }
            this.heightFeetChoices.push(heightFeetChoice);
        }

        for (let iFeet = -0.5; iFeet <= 11.5; iFeet += 0.5) {
            const heightInchesChoice = new HeightInchesSelection();
            if (iFeet === -0.5) {
                heightInchesChoice.value = '';
                heightInchesChoice.desc = 'Select';
            } else {
                heightInchesChoice.value = iFeet.toString();
                heightInchesChoice.desc = iFeet.toString();
            }
            this.heightInchesChoices.push(heightInchesChoice);
        }
        this.isReadyForRender();
    }

    // Form defined for all biometrics. Bits established above based on lab
    // references codes control which biometrics are visible. The form supports
    // and "I don't know" option for each biometric. When selected, form input
    // validation needs to be removed. As a result, adding and removing validation,
    // which can normally be done as part of form definition, are handled in a
    // separate function that can be leveraged when initially defining the form
    // and when the "I don't know" is used.
    private createForm(): void {
        this.biometricForm = this.formBuilder.group({
            heightFeet: [this.userBiometric.heightFeet],
            heightInches: [this.userBiometric.heightInches],
            weight: [this.userBiometric.weight],
            waistInches: [this.userBiometric.waistInches],
            neck: [this.userBiometric.neck],
            hip: [this.userBiometric.hip],
            bpSystolic: [this.userBiometric.bpSystolic],
            bpDiastolic: [this.userBiometric.bpDiastolic],
            heartRate: [this.userBiometric.heartRate],
            bodyFat: [this.userBiometric.bodyFat]
        });
        if (this.showHeight) {
            this.establishValidation('heightFeet');
            this.establishValidation('heightInches');
        }
        if (this.showWeight) {
            this.establishValidation('weight');
        }
        if (this.showWaist) {
            this.establishValidation('waistInches');
        }
        if (this.showNeck) {
            this.establishValidation('neck');
        }
        if (this.showHip) {
            this.establishValidation('hip');
        }
        if (this.showBloodPressure) {
            this.establishValidation('bpSystolic');
            this.establishValidation('bpDiastolic');
        }
        if (this.showHeartRate) {
            this.establishValidation('heartRate');
        }
        if (this.showBodyFat) {
            this.establishValidation('bodyFat');
        }
        this.isReadyForRender();
    }

    // Set validators and enable and required form input elements.
    private establishValidation(input: string): void {
        switch (input) {
            case 'heightFeet':
            case 'heightInches': {
                const control = this.biometricForm.get(input);
                control.setValidators(Validators.required);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'weight': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(50), Validators.max(500), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'waistInches': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(20), Validators.max(69), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'neck': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(8), Validators.max(30), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'hip': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(20), Validators.max(60), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'bpSystolic': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(70), Validators.max(240), Validators.pattern(this.noDigitsRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'bpDiastolic': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(20), Validators.max(150), Validators.pattern(this.noDigitsRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'heartRate': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(30), Validators.max(150), Validators.pattern(this.noDigitsRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'bodyFat': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(4), Validators.max(50), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
        }
    }

    // Invoked when the "I don't know" option has been unchecked.
    private establishFormValidation(input: string): void {
        const formInput = document.getElementById(input);
        if (formInput != null) {
            formInput.removeAttribute('disabled');
            formInput.setAttribute('required', 'required');
        }
    }

    // Invoked when the "I don't know" option has been checked.
    private removeFormValidation(input: string): void {
        const formInput = document.getElementById(input);
        if (formInput != null) {
            formInput.setAttribute('disabled', 'disabled');
            formInput.removeAttribute('required');
        }
    }

    // Clear validation is used when the "I don't know" option has been checked.
    private clearValidation(input: string): void {
        const control = this.biometricForm.get(input);
        control.clearValidators();
        control.setValue(null);
        control.setErrors(null);
        control.markAsPristine();
        control.markAsUntouched();
        control.updateValueAndValidity();
        this.removeFormValidation(input);
    }

    private isReadyForRender(): void {
        if (this.userBiometric && this.heightFeetChoices.length > 0 && this.heightInchesChoices.length > 0) {
            this.isRenderReady = true;
        }
    }

    // The "I don't know" option has been either checked or unchecked.
    theyJustDoNotKnow($event): void {
        const formIds = $event.currentTarget.value;
        const isChecked = $event.currentTarget.checked;
        const inputIds = formIds.split(',');
        for (const e of inputIds) {
            if (isChecked) {
                this.justDoNotKnowSelections++;
                this.theyJustDoNotKnow(e)
            } else {
                this.justDoNotKnowSelections--;
                this.establishValidation(e);
            }
        }
    }

    cancel(): void {
        this.userBiometricCaptureResult.hasBiometricCapture = false;
        this.activeModal.close(this.userBiometricCaptureResult);
    }

    // Validate bpSystolic exceeds bpDiastolic.
    private isBloodPressureValid(): boolean {
        const formModel = this.biometricForm.value;
        if (formModel.bpSystolic == null || formModel.bpSystolic == null) {
            return true;
        }
        if (formModel.bpSystolic < formModel.bpDiastolic) {
            return false;
        }
        return true;
    }

    save(): void {
        if (!this.biometricForm.valid) {
            return;
        }

        if (!this.isBloodPressureValid) {
            return;
        }

        this.addUserKey();

        const formModel = this.biometricForm.value;

        if (this.userBiometric.key && this.isValidGuid(this.userBiometric.key)) {
            const putModel = this.putBiometric;
            putModel.key = this.userBiometric.key;
            putModel.userKey = this.userBiometric.userKey;
            putModel.programServiceKey = this.userBiometric.programServiceKey;
            putModel.userProgramTransactionKey = this.userBiometric.userProgramTransactionKey;
            putModel.heightFeet = formModel.heightFeet;
            putModel.heightInches = formModel.heightInches;
            putModel.weight = formModel.weight;
            putModel.waistInches = formModel.waistInches;
            putModel.neck = formModel.neck;
            putModel.hip = formModel.hip;
            putModel.bpSystolic = formModel.bpSystolic;
            putModel.bpDiastolic = formModel.bpDiastolic;
            putModel.heartRate = formModel.heartRate;
            putModel.bodyFat = formModel.bodyFat;
            this._userService.updateUserBiometric(putModel).subscribe(
                (result) => {
                    this.closeModal(result);
                },
                (error) => {
                    this.closeModal(error);
                }
            );
        } else {
            const postModel = this.postBiometric;
            postModel.userKey = this.userBiometric.userKey;
            postModel.programServiceKey = this.userBiometric.programServiceKey;
            postModel.userProgramTransactionKey = this.userBiometric.userProgramTransactionKey;
            postModel.heightFeet = formModel.heightFeet;
            postModel.heightInches = formModel.heightInches;
            postModel.weight = formModel.weight;
            postModel.waistInches = formModel.waistInches;
            postModel.neck = formModel.neck;
            postModel.hip = formModel.hip;
            postModel.bpSystolic = formModel.bpSystolic;
            postModel.bpDiastolic = formModel.bpDiastolic;
            postModel.heartRate = formModel.heartRate;
            postModel.bodyFat = formModel.bodyFat;
            this._userService.createUserBiometric(postModel).subscribe(
                (response) => {
                    this.closeModal(response);
                },
                (error) => {
                    this.closeModal(error);
                }
            );
        }
    }

    get putBiometric() {
        return new PersonBiometricPut(this.userBiometric);
    }

    get postBiometric() {
        return new PersonBiometricSubmits(this.userBiometric);
    }

    localizeMessage(msgTag: string): string {
        let errorTag = 'unknownerror';
        if (msgTag) {
            try {
                errorTag = msgTag.split('.')[1];
            } catch (error) {
                console.error(error);
            }
        }
        return errorTag;
    }

    localizeValidationErrors(err): string {
        let errorName = this.localizeMessage(err.value);
        if (errorName !== 'unknownerror') {
            errorName = err.key + errorName;
        }
        return errorName;
    }

    closeModal(response: any): void {
        if (response.errorMessage && response.errors) {
            this.showError = true;
            this.errorList = response.errors;
            this.errorMsg = response.errorMessage;
        } else {
            this.userBiometricCaptureResult.hasBiometricCapture = true;
            const capture = new UserBiometricCapture();
            capture.userKey = this.userKey;
            capture.key = response.key;
            this.userBiometricCaptureResult.userBiometricCapture = capture;
            this.userBiometricCaptureResult.requiresBiometricCapture = true;
            this.activeModal.close(this.userBiometricCaptureResult);
        }
    }

    addUserKey(): void {
        if (!this.isValidGuid(this.userBiometric.userKey) && this.isValidGuid(this._userKey)) {
            this.userBiometric.userKey = this._userKey;
        }
    }

    isValidGuid(guid: string): boolean {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
}

<ng-container *ngIf="customizedMisc">
    <div class="row" id="titleId-CompanyCustomization">
        <div class="col-md-6">
            <h4 class="secondary-text" id="titleId-LanguagesSupported">{{ 'languagesSupported' | lesCustomLocalize : 'label' }}</h4>

            <div class="form-check">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="enus" disabled />
                    {{ 'usenglish' | lesCustomLocalize : 'label' }}
                </label>
            </div>

            <div class="form-check">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="esmx" />
                    {{ 'mxspanish' | lesCustomLocalize : 'label' }}
                </label>
            </div>

            <h4 class="secondary-text" id="titleId-CustomColors">{{ 'colors' | lesCustomLocalize : 'label' }}</h4>

            <div class="form-group">
                <label for="color1">{{ 'color1' | lesCustomLocalize : 'label' }}</label>
                <input
                    class="form-control"
                    id="color1"
                    [(colorPicker)]="customizedMisc.customizationStyle.primaryColor"
                    [(ngModel)]="customizedMisc.customizationStyle.primaryColor"
                    [style.background]="customizedMisc.customizationStyle.primaryColor"
                    maxlength="7"
                />
                <small id="color1Help" class="form-text text-muted">{{ 'color1info' | lesCustomLocalize : 'message' }}</small>
            </div>

            <div class="form-group">
                <label for="color2">{{ 'color2' | lesCustomLocalize : 'label' }}</label>
                <input
                    class="form-control"
                    id="color2"
                    [(colorPicker)]="customizedMisc.customizationStyle.secondaryColor"
                    [(ngModel)]="customizedMisc.customizationStyle.secondaryColor"
                    [style.background]="customizedMisc.customizationStyle.secondaryColor"
                    maxlength="7"
                />
                <small id="color2Help" class="form-text text-muted">{{ 'color2info' | lesCustomLocalize : 'message' }}</small>
            </div>

            <div class="form-group">
                <label for="color3">{{ 'color3' | lesCustomLocalize : 'label' }}</label>
                <input
                    class="form-control"
                    id="color3"
                    [(colorPicker)]="customizedMisc.customizationStyle.tertiaryColor"
                    [(ngModel)]="customizedMisc.customizationStyle.tertiaryColor"
                    [style.background]="customizedMisc.customizationStyle.tertiaryColor"
                    maxlength="7"
                />
                <small id="color3Help" class="form-text text-muted">{{ 'color3info' | lesCustomLocalize : 'message' }}</small>
            </div>
        </div>

        <div class="col-md-6">
            <h4 class="secondary-text" id="titleId-Logo">Logo</h4>

            <p>
                {{ 'logohint' | lesCustomLocalize : 'message' }}
            </p>

            <div *ngIf="customizedMisc.customizationStyle.logo">
                <div class="row">
                    <img class="img-fluid" src="{{ customizedMisc.customizationStyle.logo }}" />
                </div>
                <div class="row">
                    <a class="" href="javascript:void(0);" (click)="file.click()" id="actionId-UpdateLogoLanguagesSupported">
                        {{ 'updatelogo' | lesCustomLocalize : 'label' }}
                    </a>
                    /
                    <a class="" href="javascript:void(0);" (click)="removeLogo()" id="actionId-RemoveLogoLanguagesSupported">
                        {{ 'removelogo' | lesCustomLocalize : 'label' }}
                    </a>
                </div>
            </div>

            <div *ngIf="!customizedMisc.customizationStyle.logo" class="uploadArea d-flex">
                <div>
                    <a href="javascript:void(0);" (click)="file.click()" id="actionId-InsertLogoLanguagesSupported">
                        {{ 'insertlogo' | lesCustomLocalize : 'label' }}
                    </a>
                </div>
            </div>
            <input type="file" #file (change)="uploadLogo($event)" style="display: none" />

            <div *ngIf="imageError">
                <div class="alert alert-danger text-center">{{ 'uploadimagexbyxpixels' | lesCustomLocalize : 'message' : maxImageSizeX }}</div>
            </div>

            <div *ngIf="imageFileSizeError">
                <div class="alert alert-danger text-center">{{ 'uploadimageexceededmaxfilesize' | lesCustomLocalize : 'message' : maxImageFileSizeKB }}</div>
            </div>
        </div>
    </div>

    <div class="row pt-2">
        <div class="col-md-12 mx-auto">
            <button class="tertiary-bg btn btn-primary" id="actionId-SaveLanguagesSupported" (click)="submit()">{{ 'save' | lesCustomLocalize : 'label' }}</button>
        </div>
    </div>
</ng-container>

<app-loading *ngIf="!customizedMisc"></app-loading>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoachingModule } from '../coaching/coaching.module';
import { SchedulerModule } from '../scheduler/scheduler.module';
import { SearchGuard } from '../search-guard.service';
import { ServicesModule } from '../services/services.module';
import { SharedModule } from '../shared/shared.module';
import { ParticipantDetailComponent } from './participant-detail/participant-detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    CoachingModule,
    SchedulerModule,
    ServicesModule,
    RouterModule.forChild([
      { path: 'participant/:userKey', component: ParticipantDetailComponent, canActivate: [SearchGuard] }
    ]),
  ],
  declarations: [
    ParticipantDetailComponent,
  ]
})
export class ParticipantModule { }


import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../environments/environment";
import AppNotificationMessage from "./app-notification-message";

@Injectable()
export class AppNotificationService {
    constructor(private _http: HttpClient) { }


    getNotificationMessages(userKey: string): Observable<AppNotificationMessage[]> {
        let params = new HttpParams();
        if (userKey) {
            params = params.append('userKey', userKey);
        }
        return this._http.get<AppNotificationMessage[]>(environment.baseCoreApiEndpoint + '/app-notifications/messages', { params: params }).pipe(
            catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}
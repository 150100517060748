import { Globals } from '../globals';

export class InsuranceVerification {
    serviceDate: Date = new Date();
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: Date;
    labcorpPayerCode: string;
    subscriberId: string;
    personKey: any;
    userKey: any;
    organizationKey: any;
    auditUserKey: any;
    payorContractKey: any;
    participantKey: string;
}
export interface InsuranceVerificationResult {
    isCovered: boolean;
    coverageMessage: string;
    coverageContentTag: string;
    errorMessages: string[];
    personInsuranceKey: string;
    hasValidUserInsuranceAddress: boolean;
    address: Address;
}
export interface UserInsuranceAddressUpdateResult {
    isUpdated: boolean;
    errorMessages: string[];
}
export class PersonInsuranceProgram {
    key: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: Date;
    labcorpPayerCode: string;
    subscriberId: string;
    personKey: any;
}

export class Payor {
    key: string;
    name: string;
    code: string;
    organizationKey: string;
    stateCode: string;
    stateKey: string;
}
export interface PayorContract {
    key: string;
    organizationKey: string;
    programServiceKey: string;
    partnerKey: string;
    companyKey: string;
    validFromDate: Date;
    validThruDate: Date;
    stateKeys: string[];
    organization: PayorLookup;
}
export interface PayorLookup {
    organizationTypeKey: string;
    name: string;
    code: string;
}
export class PayorContractSearch {
    programServiceTypeKey: string;
    programServiceKey: string;
    partnerKey: string;
    companyKey: string;
}
export class PersonProgramBilling {
    key: string;
    personKey: string;
    participantKey: string;
    eventKey: string;
    programServiceKey: string;
    isDirectBill: boolean;
    personInsuranceKey: string;
    stateKey: string;
    groupNumber: string;
    subscriberId: string;
    medicareNumber: string;
    organizationKey: string;
    firstName: string;
    lastName: string;
    payorContractKey: string;
    source: string = Globals.PersonProgramBillingSources.WellConnectPlus;
    isValidated: boolean;
}

export interface PersonInsurance {
    policyHolderPersonKey: string;
    subscriberPersonKey: string;
    beneficiaryKey: string;
    subscriberPolicyholderRelationshipKey: string;
    coverageTypeKey: string;
    coverageStatusKey: string;
    payorOrganizationKey: string;
    payorPersonKey: string;
    effectiveFrom?: Date;
}

export class UserInsuranceAddress {
    address1: string;
    address2: string;
    city: string;
    stateAbbreviation: string;
    zip: string;
    serviceDate: Date = new Date();
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: Date;
    labcorpPayerCode: string;
    subscriberId: string;
    personKey: any;
    userKey: any;
    organizationKey: any;
    auditUserKey: any;
    payorContractKey: any;
    participantKey: string;
}

export class UserInsuranceAddressValidate {
    address: ValidateInsuranceAddress;
}

export class ValidateInsuranceAddress {
    address1: string;
    address2: string;
    city: string;
    stateAbbreviation: string;
    zip: string;
}

export class Address {
    line1: string;
    line2: string;
    city: string;
    stateAbbreviation: string;
    postalCode: string;
}

export class LocalStorageService {

    public static getValue(name) {
        const result = window.sessionStorage.getItem(name);
        if (result) {
            return JSON.parse(result);
        }
        return null;
    }

    public static deleteValue(name) {
        window.sessionStorage.removeItem(name);
    }
    public static setValue(name, value) {
        window.sessionStorage.setItem(name, JSON.stringify(value));
    }
    public static clearAllSession() {
        window.sessionStorage.clear();
    }
}
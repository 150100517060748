export class ProfilePut {
    userKey: string;
    participantKey: string;
    employeeID: string;
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: Date;
    emailAddress: string;
    languageLocale: string;
    phone: string;
    textMessagePhone: string;
    textUseSettingsPhone: boolean;
    receiveSmsReminders: boolean;
    stateKey: string;
    timeZoneKey: string;
    genderKey: string;
}

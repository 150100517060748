export class PersonBiometricBase {
    constructor(init?: Partial<PersonBiometricBase>) {
        Object.assign(this, init);
    }
    userKey: string;
    programServiceKey: string;
    userProgramTransactionKey: string;
    heightFeet: number;
    heightInches: number;
    weight: number;
    waistInches: number;
    neck: number;
    hip: number;
    bpSystolic: number;
    bpDiastolic: number;
    heartRate: number;
    bodyFat: number;
    pregnant: boolean;
    tobaccoUse: boolean;
}
export class PersonBiometric extends PersonBiometricBase {
    constructor(init?: Partial<PersonBiometric>) {
        super(init);
    }
    key: string;
}
export class PersonBiometricSubmits extends PersonBiometricBase {
    constructor(init: Partial<PersonBiometric>) {
        super(init);
    }
}

export class PersonBiometricPut extends PersonBiometricBase {
    constructor(init: Partial<PersonBiometric>) {
        super(init);
        this.key = init.key;
    }
    key: string;
}
export class PersonBiometricRequestVerification {
    constructor(public serviceKeys: string[], public userKey: string) { }
}
export class PersonBiometricResult {
    key: string;
    errorMessage: string;
    errors: any[];
}

<ng-container *ngIf="readyForRender">
    <div class="card next-appt">
        <div class="card-body">
            <div class="column">
                <div class="column">
                    <h5 class="card-title primary-text" id="titleId-YourNextCoachingAppointment">{{ 'yournextcoachingappt' | lesCustomLocalize : 'label' }}</h5>
                    <div *ngIf="nextAppointment" class="card-text">
                        <div class="row">
                            <div class="col">
                                {{ 'yournextcoachingapptdesc' | lesCustomLocalize : 'label' }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-3 form-field" id="displayId-NextAppointmentDate">{{ 'date' | lesCustomLocalize : 'label' }}:</div>
                            <div class="col-sm-9">
                                {{ nextAppointment.startDate | amFromUtc | amTz : participant.preferredTimeZone | amDateFormat : 'M/D/YY h:mm a' }}
                                {{ nextAppointment.timeZone }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-3 form-field" id="displayId-HealthCoach">{{ 'healthcoach' | lesCustomLocalize : 'label' }}:</div>
                            <div class="col-sm-9">
                                {{ nextAppointment.coachUser ? nextAppointment.coachUser.firstName + ' ' + nextAppointment.coachUser.lastName : ('na' | lesCustomLocalize : 'label') }}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="nextAppointment === null || nextAppointment === undefined" id="messageId-YouHaveNoUpcomingAppointments.">
                        {{ 'noappointmentsinfuture' | lesCustomLocalize : 'message' }}
                    </div>
                </div>
            </div>

            <div class="pt-2 card-text" *ngIf="showLinkToCoaching()">
                <a routerLink="/coaching" id="actionId-GoToTheCoachingPage">{{ 'gotoyourcoachingdashboard' | lesCustomLocalize : 'message' }}</a>
            </div>
        </div>
    </div>
</ng-container>

import { Component, OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Report } from '../../shared/reporting/report';
import { ReportingService } from '../../shared/reporting/reporting.service';
import { ReportUserAccess } from '../../shared/reporting/reportUserAccess';
import { Token } from '../../shared/reporting/token';
declare let initViz: any;
import '../../../assets/tableau-initViz.js';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseApiModel } from '../../scheduler/model/apimodels/api-models';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';



@Component({
  selector: 'app-contact-us',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportingDetailComponent implements OnInit, OnDestroy {
  readyForRender = false;
  hasError = false;
  subscriptions: ISubscription[] = [];
  userReport: Report;
  userReports: Report[];
  localizeServiceReady = false;
  reportKey: string;
  fullReportURL: string;
  token: Token;
  tableauReportUserAccess: ReportUserAccess;
  deviceType = 'desktop';
  hasReportedModalError = false;

  constructor(private _route: ActivatedRoute, private _localizeService: LocalizeService,
    private _reportingService: ReportingService,private _modalService: NgbModal) {
        _route.params.subscribe( params => {
            this.reportKey = params.key;
            console.log(`Key is ${this.reportKey}`);
        });
  }

  private getDeviceType(currentWidth: number):string{
    const width = currentWidth || window.innerWidth;
    //tableau recommended widths 500, 501-800, 800+
    if(width <= 500)
      return 'phone';
    else if(width > 500 && width <= 800)
      return 'tablet'
    else
      return 'desktop'
  }

  private onError() {
    this.hasError = true;
  }

  private isReadyForRender(){
    if(this.localizeServiceReady && this.userReports && this.token){
      this.readyForRender = true;
      this.fullReportURL = this.getFullReportURL(this.userReport, this.token.value);
      this.setDeviceType();
      this.showVisualization(this.fullReportURL);
    }
  }

  private showVisualization(reportURL: string) {
    // Show new visualization   
    new initViz(reportURL, this.tableauReportUserAccess.companyKey, this.tableauReportUserAccess.partnerKey,this.deviceType);    
  }
  private getFullReportURL(report: Report, trustedToken: string) {
    const fullURL = environment.embeddedEndPointOne + trustedToken + environment.embeddedEndPointTwo + report.url;
    return fullURL;
  }
  private setDeviceType() : void{
    this.deviceType = this.getDeviceType(null)
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe( isReady => {
        this.localizeServiceReady = true;
        this.isReadyForRender();
    },
    error => this.onError()));

    this.subscriptions.push(this._reportingService.allReports.subscribe( data => {
        if(data){
          if (!this.userReports && data.isSuccess) {
            this.userReports = data.data;           
            this.userReport =  data.data.find((r) => r.name === this.reportKey); 
            this.isReadyForRender();
        }else{
          this.processModalAlert(data);
        }
        }
      },
      error => this.onError()));

      this.subscriptions.push(this._reportingService.tableauUserAccess.subscribe( data => {
        if(data){
          if (data.isSuccess) {
            this.tableauReportUserAccess = data.data;
            this._reportingService.fetchReports(data.data.tableauUserId);
            this._reportingService.fetchTrustedToken(data.data.tableauUserName.replace("+","%2b"));
          }else{
            this.processModalAlert(data)          
          }
        }
      },
      error => this.onError()));

    this.subscriptions.push(this._reportingService.trustedToken.subscribe( data => {
      if(data){
        if (data.isSuccess) {
          this.token = data.data;
          this.isReadyForRender();
        }else{
          this.onError();
        }
      }
      },
      error => this.onError()));
  }

  processModalAlert(model: BaseApiModel){
    if(model && model.executionMessages && model.executionMessages.length)
      this.showModalAlert(model.executionMessages.map(m => m.localizeName));
    else if(model && !model.isSuccess)
      this.hasReportedModalError = true;
}
  showModalAlert(localizeMessageNames: string[]) {
    const modalRef = this._modalService.open(AlertModalComponent, { backdrop: 'static'});
    modalRef.componentInstance.localizeNames = localizeMessageNames;
    modalRef.result.then((result) => {      
      this.hasReportedModalError = true;  
    });
       
}
  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
  
}
import { LocationStrategy } from '@angular/common';
import { Component, Input,OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';

import { LocalizeService } from '../localize/localize.service';

@Component({
  selector: 'app-loaddocument',
  templateUrl: './load-document.component.html',
  styleUrls: ['./load-document.component.css']
})
export class LoadDocumentComponent implements OnInit {
  @Input() msg: string;
  @Input() showspin: boolean;
  @Input() fileName: string;

  readyForRender = false;
  downloadLink: SafeUrl;
  showDownload = false;
  showExperienceMessage = false;
  experienceMessage: string;

  constructor(private _localizeService: LocalizeService, public activeModal: NgbActiveModal, private deviceService: DeviceDetectorService, private location: LocationStrategy) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      this.closeModal();
      return false;
    });
  }

  ngOnInit() {
    this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        const device = this.deviceService.getDeviceInfo();
        if (device.os === 'mac' && device.browser !== 'safari') {
          this.showExperienceMessage = true;
          this.experienceMessage = this._localizeService.get('iosexperiencemessage', 'message');
        } else if (device.os === 'android' && device.browser !== 'chrome') {
          this.showExperienceMessage = true;
          this.experienceMessage = this._localizeService.get('androidexperiencemessage', 'message');
        }
        this.readyForRender = true;
      }
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';

import { ResultsGuard } from '../results-guard.service';
import { SharedModule } from '../shared/shared.module';
import { ReferenceRangesComponent } from './reference-ranges/reference-ranges.component';
import { ResultChartComponent } from './result-chart/result-chart.component';
import { DetailLineItemComponent } from './result-detail/detail-line-item/detail-line-item.component';
import { ResultDetailComponent } from './result-detail/result-detail.component';
import { ResultListComponent } from './result-list/result-list.component';
import { ResultsService } from './results.service';
import { ResultsComponent } from './results/results.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MomentModule,
    RouterModule.forChild([
      { path: 'results', component: ResultsComponent, canActivate: [ResultsGuard] },
      { path: 'results/:key', component: ResultDetailComponent, canActivate: [ResultsGuard] }
    ])
  ],
  exports: [
    ResultListComponent
  ],
  declarations: [
    ResultListComponent,
    ResultsComponent,
    ResultChartComponent,
    ResultDetailComponent,
    DetailLineItemComponent,
    ReferenceRangesComponent
  ],

  providers: [
    ResultsService
  ]
})
export class ResultsModule { }

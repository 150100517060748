
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ReportApiModel, ReportUserAccessApiModel, TokenApiModel } from '../../scheduler/model/apimodels/api-models';

@Injectable()
export class ReportingService {
  private allReportsSource = new BehaviorSubject<ReportApiModel>(null);
  private trustedTokenSource = new BehaviorSubject<TokenApiModel>(null);
  private tableauUserAccessSource = new BehaviorSubject<ReportUserAccessApiModel>(null);

  allReports = this.allReportsSource.asObservable();
  trustedToken = this.trustedTokenSource.asObservable();
  tableauUserAccess = this.tableauUserAccessSource.asObservable();

  constructor(private _http: HttpClient) {
  }

  public fetchTrustedToken(tableauUserName: string) {
    this._http.get<TokenApiModel>(environment.baseReportingApiEndpoint + environment.reportingAPITrustedTokenEndpoint + tableauUserName)
      .subscribe(data => {
        this.trustedTokenSource.next(data);
      });
  }

  public fetchTableauUserAccess(userKey: string) {
    this._http.get<ReportUserAccessApiModel>(environment.baseReportingApiEndpoint + environment.reportingAPIHasReportingAccessEndpoint + userKey)
      .subscribe(data => {
        this.tableauUserAccessSource.next(data);
      });
  }

  public fetchReports(tableauUserId: string) {
    this._http.get<ReportApiModel>(environment.baseReportingApiEndpoint + '/reports?userId=' + tableauUserId)
      .subscribe({
        next: (data) => this.allReportsSource.next(data),
        error: (e) => this.handleError(e)
      });
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}
import { Component, Input,OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Company } from '../../shared/company/company';
import { Globals } from '../../shared/globals';
import { CustomizationLanguage,CustomizationStyle, CustomizedMisc } from '../../shared/localize/customized-data';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { Utils } from '../../shared/utils';
import { CustomizeService } from '../customize.service';
@Component({
  selector: 'app-customize-misc',
  templateUrl: './customize-misc.component.html',
  styleUrls: ['./customize-misc.component.css']
})
export class CustomizeMiscComponent implements OnInit, OnDestroy {
  _currentUser: Person;
  @Input() set currentUser(val: Person) {
    this._currentUser = val;
    this.checkReady();
  }

  get currentUser() {
    return this._currentUser;
  }

  _company: Company;
  @Input() set company(val: Company) {
    this._company = val;

    this.checkReady();
  }

  get company() {
    return this._company;
  }

  enus = true;
  esmx: boolean;
  imageError = false;
  imageFileSizeError = false;
  maxImageSizeX = 200;
  maxImageSizeY = 65;
  maxImageFileSizeKB = 50;
  customizedMisc: CustomizedMisc;

  subscriptions: ISubscription[] = [];
  readyForRender = false;
  localizeReady = false;

  constructor(private _localizeService: LocalizeService, private _customizeService: CustomizeService) { }

  submit() {
    this.customizedMisc.customizationStyle.companyKey = this._company.key;
    this.customizedMisc.customizationLanguages = this.setLanguages();
    this._customizeService.modifyMisc(this.customizedMisc).subscribe(data => {
      this.customizedMisc = data;
      alert(this._localizeService.get('recordupdatesuccess', 'message'));
    }, error => {
      if (error && error.status === 400 && error.error === "ERROR_IMAGEMAXSIZEEXCEEDED") {
        this.imageFileSizeError = true;
        return;
      }
    });
  }

  private setLanguages(): CustomizationLanguage[] {
    const languages = [];

    if (this.enus) {
      let language = this.getEnglish();
      if (!language) {
        language = new CustomizationLanguage(null, this._company.key, Globals.Locales.USEnglish);
      }
      languages.push(language);

    }
    if (this.esmx) {
      let language = this.getSpanishMexican();
      if (!language) {
        language = new CustomizationLanguage(null, this._company.key, Globals.Locales.MexicoSpanish);
      }
      languages.push(language);
    }
    return languages;
  }

  private getEnglish() {
    return _.find(this.customizedMisc.customizationLanguages, function (l: CustomizationLanguage) {
      return l.languageLocale === Globals.Locales.USEnglish;
    });
  }

  private getSpanishMexican() {
    if (this.customizedMisc.customizationLanguages.length === 0) {
      return null;
    }

    return _.find(this.customizedMisc.customizationLanguages, function (l: CustomizationLanguage) {
      return l.languageLocale === Globals.Locales.MexicoSpanish;
    });
  }

  private uploadLogo(event: any) {
    this.imageError = false;
    this.imageFileSizeError = false;
    const logoFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(logoFile);
    fileReader.onload = () => {
      const img = new Image();
      img.onload = () => {
        this.customizedMisc.customizationStyle.logo = fileReader.result;
      };
      img.src = fileReader.result as string;
    };

  }

  private removeLogo(): void {
    this.customizedMisc.customizationStyle.logo = null;
    this.imageFileSizeError = false;
  }

  private checkReady() {
    if (this.localizeReady && this.currentUser && this._company) {
      this.fetchData();
      this.readyForRender = true;
    }
  }

  private fetchData() {
    this._customizeService.fetchMisc(this._company.key).subscribe(data => {
      if (data) {
        this.customizedMisc = data;
        if (!this.customizedMisc.customizationStyle) {
          this.customizedMisc.customizationStyle = new CustomizationStyle();
        }
      }
      // check with Frank that this will not be needed
      // this.enus = this.getEnglish() != null;
      this.esmx = this.getSpanishMexican() != null;
    });
    this._customizeService.fetchLogoMaxFileSize().subscribe(data => {
      this.maxImageFileSizeKB = data;
    });
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
      }
    }));

    this.fetchData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

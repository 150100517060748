<ng-container *ngIf="readyForRender">
    <div class="card next-appt">
        <div class="card-body">
            <h5 class="card-title primary-text" id="titleId-YourNextAppointment">{{ 'yournextscreeningappt' | lesCustomLocalize : 'label' }}</h5>
            <div *ngIf="nextAppointment" class="card-text">
                <div class="row">
                    <div class="col-sm-3 form-field">{{ 'date' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-sm-9">{{ getLocaleMonth(nextAppointment.time | amDateFormat : 'MMMM') }} {{ nextAppointment.time | amDateFormat : 'D, YYYY h:mm A' }} ({{ nextAppointment.timezone }})</div>
                </div>
                <div class="row">
                    <div class="col-sm-3 form-field">{{ 'service' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-sm-9">
                        {{ getProgramLabel(nextAppointment.serviceDisplayName) }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 form-field">{{ 'fasting' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-sm-9">
                        {{ nextAppointment.fastingToken | lesCustomLocalize : 'label' }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 form-field">{{ 'location' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-sm-9" [innerHTML]="nextAppointment.locationText"></div>
                </div>
                <div class="row">
                    <div class="col-sm-3 form-field">{{ 'appointmentfor' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-sm-9">
                        {{ nextAppointment.appointmentFor }}
                    </div>
                </div>
            </div>

            <div *ngIf="nextAppointment === null || nextAppointment === undefined" class="card-text">
                {{ 'noappointmentsinfuture' | lesCustomLocalize : 'message' }}
            </div>

            <div class="pt-2 card-text" *ngIf="nextAppointment">
                <a routerLink="/appointments" id="actionId-ViewYourAppointments">{{ 'viewyourlistofscreeningappointments' | lesCustomLocalize : 'message' }}</a>
            </div>

            <div class="pt-2 card-text" *ngIf="!nextAppointment">
                <a routerLink="/scheduler" id="actionId-ScheduleAppointment">{{ 'scheduleappointment' | lesCustomLocalize : 'label' }}</a>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="hasError || !readyForRender">
    <h5 class="primary-text" id="titleId-YourNextAppointment">{{ 'yournextscreeningappt' | lesCustomLocalize : 'label' }}</h5>
    <app-error *ngIf="hasError" [htmlId]="'YourNextAppointment'"></app-error>
    <app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>
</div>

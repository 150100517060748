import { Component, Input,OnInit } from '@angular/core';

import { LocalizeService } from '../localize/localize.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() msg: string;
  @Input() loadingIconClass = 'fa-spinner fa-spin';
  @Input() isOverlay = false;
  readyForRender = false;

  constructor(private _localizeService: LocalizeService) { }

  ngOnInit() {
    this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.readyForRender = true;
      }
    });
  }
}

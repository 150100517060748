import { Pipe, PipeTransform } from "@angular/core";

import { LocalizeService } from "./localize.service";

@Pipe({
  name: "lesCustomLocalize"
})
export class LocalizePipe implements PipeTransform {
  constructor(private _localizeService: LocalizeService) { }

  transform(
    value: string,
    tokenType: string,
    replaceToken1: string,
    replaceToken2: string,
    options: any
  ): string {
    return this._localizeService.getCombinedTag(
      value,
      tokenType,
      replaceToken1,
      replaceToken2,
      options
    );
  }
}

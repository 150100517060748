<input [id]="settings.formControlId" type="text"
       [name]="settings.formControlId"
       [class]="settings.classList"
       [ngbTypeahead]="onTypeaheadQuery"
       [resultFormatter]="settings.resultFormatter"
       [inputFormatter]="settings.inputFormatter"
       (selectItem)="settings.selectItem($event)"
       [disabled]="disabled"
       [value]="innerValue"
       [placeholder]="settings.placeHolderText"
       [autocomplete]="settings.autoComplete"
       [required] = "settings.requiredField"
        />


export class MonthSelection {
    month: string;
    desc: string;
}

export class DaySelection {
    day: string;
    desc: string;
}

export class YearSelection {
    year: string;
    desc: string;
}

import _ from 'lodash';
import moment from 'moment';

import { Globals } from '../../shared/globals';
import { Utils } from '../../shared/utils';
import { ApiEvent } from './api-event';
import { Event } from './event';
import { ProgramServiceAndType } from './program-service-and-type';
import { Slot } from './slot';

export class SlotOption {
    time: Date;
    hour: string;
    slots: Slot[] = [];
    serviceType: ProgramServiceAndType;
    dateSummaryTimeId: string;

    get count() {
        let cnt = 0;

        for (let i = 0; i < this.slots.length; i++) {
            if (!this.slots[i].reserved) {
                cnt++;
            }
        }

        return cnt;
    }

    addReservation(): boolean {
        let added = false;

        for (let i = 0; i < this.slots.length; i++) {
            if (!this.slots[i].reserved) {
                this.slots[i].reserved = true;
                added = true;
                break;
            }
        }

        return added;
    }

    removeReservation(): boolean {
        let removed = false;

        for (let i = 0; i < this.slots.length; i++) {
            if (this.slots[i].reserved) {
                this.slots[i].reserved = false;
                removed = true;
                break;
            }
        }

        return removed;
    }
}

export class DateSummaryServiceType {
    key: string;
    serviceTypeIcon: string;
    name: string;
    displayName: string;
    hour: string;
    dateSummaryTimeId: string;
    eventKey: string;
    eventIsClosed: boolean;
    bookForOthers: boolean;
    slotOptions: SlotOption[] = [];

    get numberOfSlots(): number {
        let count = 0;

        for (let i = 0; i < this.slotOptions.length; i++) {
            count += this.slotOptions[i].count;
        }
        return count;
    }

    constructor(serviceType: ProgramServiceAndType, dateSummaryTimeId: string, hour: string, eventKey: string, eventIsClosed: boolean, bookForOthers: boolean) {
        this.key = serviceType.programServiceTypeKey;
        this.name = serviceType.name;
        this.displayName = serviceType.displayName;
        this.eventKey = eventKey;
        this.eventIsClosed = eventIsClosed;
        this.dateSummaryTimeId = dateSummaryTimeId;
        this.hour = hour;
        this.bookForOthers = bookForOthers;
    }

    addSlot(slot: Slot) {
        let optionFound = false;

        for (let i = 0; i < this.slotOptions.length; i++) {
            if (new Date(this.slotOptions[i].time).getTime() === new Date(slot.time).getTime()) {
                this.slotOptions[i].slots.push(slot);
                optionFound = true;
                break;
            }
        }

        if (!optionFound) {
            const newOption = new SlotOption();
            const newOptionServiceType = new ProgramServiceAndType();
            newOptionServiceType.programServiceTypeKey = this.key;
            newOptionServiceType.name = this.name;
            newOption.serviceType = newOptionServiceType;
            newOption.time = slot.time;
            newOption.hour = this.hour;
            newOption.dateSummaryTimeId = this.dateSummaryTimeId;
            newOption.slots.push(slot);
            this.slotOptions.push(newOption);
        }

        this.slotOptions = _.sortBy(this.slotOptions, (so: SlotOption) => {
            return so.time;
        });
    }
}

export class DateSummaryTime {
    id: string;
    hour: string;
    serviceTypes: DateSummaryServiceType[] = [];

    get numberOfSlots(): number {
        let numberOfSlots = 0;

        for (const type of this.serviceTypes) {
            numberOfSlots += type.numberOfSlots;
        }

        return numberOfSlots;
    }

    addSlot(slot: Slot, eventIsClosed: boolean) {
        let serviceTypeFound = false;

        for (let i = 0; i < this.serviceTypes.length; i++) {
            if (slot.programServiceType.programServiceTypeKey === this.serviceTypes[i].key) {
                this.serviceTypes[i].addSlot(slot);
                serviceTypeFound = true;
                break;
            }
        }

        if (!serviceTypeFound) {
            const serviceType = new DateSummaryServiceType(slot.programServiceType, this.id, this.hour, slot.eventKey, eventIsClosed, slot.bookForOthers);
            serviceType.addSlot(slot);
            const serviceTypeIcon = Globals.ServiceTypeImages.find((s) => s.pgtkey === serviceType.key);
            if (serviceTypeIcon && serviceTypeIcon !== null) {
                serviceType.serviceTypeIcon = serviceTypeIcon.fontawesomeclass;
            } else {
                serviceType.serviceTypeIcon = 'fa-heart';
            }
            this.serviceTypes.push(serviceType);

            this.serviceTypes = _.sortBy(this.serviceTypes, (st: DateSummaryServiceType) => {
                return st.displayName;
            });
        }
    }

    timeHasEnough(serviceTypeNeeded: string, numberOfParticipants: number): number {
        let numberOfSlots = 0;

        const serviceType: DateSummaryServiceType = _.find(this.serviceTypes, (st: DateSummaryServiceType) => {
            return st.key === serviceTypeNeeded;
        });

        if (serviceType) {
            numberOfSlots += serviceType.numberOfSlots;
        }

        if (numberOfSlots >= numberOfParticipants) {
            return 1;
        } else if (numberOfSlots > 0) {
            return 0;
        } else {
            return -1;
        }
    }
}

export class DateSummary {
    dateLabel: string;
    date: Date;
    eventKey: string;
    programServiceTypeKey: string;
    dateHasOpenEvent: boolean;
    eventIsFull: boolean;
    times: DateSummaryTime[] = [];

    constructor(date: Date) {
        this.date = date;
        this.date.setHours(0, 0, 0, 0);
        this.dateLabel = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + Utils.dayOfWeek(date);
    }

    addTime(slot: Slot, eventIsClosed: boolean) {
        let added = false;
        const slotDateTime = moment(slot.time).toDate();

        for (let i = 0; i < this.times.length; i++) {
            if (this.times[i].hour === this.getHourString(slotDateTime)) {
                this.times[i].addSlot(slot, eventIsClosed);
                added = true;
                break;
            }
        }

        if (!added) {
            const newTime = new DateSummaryTime();
            newTime.id = this.getTimeId(slotDateTime);
            newTime.hour = this.getHourString(slotDateTime);
            newTime.addSlot(slot, eventIsClosed);
            this.times.push(newTime);
        }
    }

    get numberOfSlots(): number {
        let slots = 0;

        for (const time of this.times) {
            slots += time.numberOfSlots;
        }

        return slots;
    }

    dayHasOpenSlotsAcrossEvent(): boolean {
        let numberOfSlots = 0;

        for (let time = 0; time < this.times.length; time++) {
            for (let service = 0; service < this.times[time].serviceTypes.length; service++) {
                numberOfSlots += this.times[time].serviceTypes[service].numberOfSlots;
            }
        }

        if (numberOfSlots >= 0) {
            return true;
        } else {
            return false;
        }
    }

    dayHasEnough(serviceTypeNeeded: string, numberOfParticipants: number): number {
        let numberOfSlots = 0;

        _.each(this.times, (time: DateSummaryTime) => {
            const serviceType: DateSummaryServiceType = _.find(time.serviceTypes, (st: DateSummaryServiceType) => {
                return st.key === serviceTypeNeeded;
            });

            if (serviceType) {
                numberOfSlots += serviceType.numberOfSlots;
            }
        });

        if (numberOfSlots >= numberOfParticipants) {
            return 1;
        } else if (numberOfSlots > 0) {
            return 0;
        } else {
            return -1;
        }
    }

    private getHourString(date: Date) {
        const hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        return hour == 0 ? '12:00 AM' : hour + ':00 ' + (date.getHours() >= 12 ? 'PM' : 'AM');
    }

    private getTimeId(date: Date) {
        const hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        return date.getMonth() + 1 + '_' + date.getDate() + '_' + date.getFullYear() + '_' + hour + '00' + (date.getHours() >= 12 ? 'PM' : 'AM');
    }
}

export class DateSummaryCollection {
    dates: DateSummary[] = [];
    events: Event[] = [];

    getEvent(key: string): Event {
        return _.find(this.events, ['key', key]);
    }

    eventsExist(theDate: Date): boolean {
        const thisDate: DateSummary = _.find(this.dates, (dt: DateSummary) => {
            const thisStartDt = new Date(dt.date);
            return thisStartDt.getFullYear() === theDate.getFullYear() && thisStartDt.getMonth() === theDate.getMonth() && thisStartDt.getDate() === theDate.getDate();
        });

        if (thisDate) {
            return true;
        } else {
            return false;
        }
    }

    hasAvailableSlots(theDate: Date): boolean {
        if (this.dates.length) {
            const dateSummary: DateSummary = _.find(this.dates, (dt: DateSummary) => {
                const thisDt = new Date(dt.date);
                return thisDt.getFullYear() === theDate.getFullYear() && thisDt.getMonth() === theDate.getMonth() && thisDt.getDate() === theDate.getDate();
            });

            if (dateSummary) {
                return dateSummary.dayHasOpenSlotsAcrossEvent();
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getFirstAvailableDate(serviceTypes: string[], includeDisabled = false): Date {
        let first = null;

        const _dts: DateSummary[] = _.sortBy(this.dates, (dt: DateSummary) => {
            return dt.date;
        });

        let dateFound = false;
        if (_dts && _dts.length) {
            for (let i = 0; i < _dts.length; i++) {
                if (_dts[i].dateHasOpenEvent) {
                    for (let st = 0; st < serviceTypes.length; st++) {
                        if (this.dates[i].programServiceTypeKey === serviceTypes[st] || includeDisabled) {
                            first = _dts[i].date;
                            dateFound = true;
                            break;
                        }
                    }
                    if (dateFound) {
                        break;
                    }
                }
            }
        }

        // May have a condition where only closed or full events exist.
        if (!dateFound) {
            if (_dts && _dts.length) {
                for (let i = 0; i < _dts.length; i++) {
                    if (!_dts[i].dateHasOpenEvent) {
                        for (let st = 0; st < serviceTypes.length; st++) {
                            if (this.dates[i].programServiceTypeKey === serviceTypes[st] || includeDisabled) {
                                first = _dts[i].date;
                                dateFound = true;
                                break;
                            }
                        }
                        if (dateFound) {
                            break;
                        }
                    }
                }
            }
        }

        return first;
    }

    getFirstAvailableDateInMonth(theMonth: Date): Date {
        let first = null;

        const firstDateInMonth: DateSummary = _.find(this.dates, (thisDate: DateSummary) => {
            const thisDt = new Date(thisDate.date);
            return thisDt.getFullYear() === theMonth.getFullYear() && thisDt.getMonth() === theMonth.getMonth();
        });
        if (firstDateInMonth) {
            first = firstDateInMonth.date;
        }

        return first;
    }

    getFirstAvailableFutureDate(theMonth: Date): Date {
        let first = null;

        const firstDateInFuture: DateSummary = _.find(this.dates, (thisDate: DateSummary) => {
            const thisDt = new Date(thisDate.date);
            return thisDt >= theMonth;
        });
        if (firstDateInFuture) {
            first = firstDateInFuture.date;
        }

        return first;
    }

    addDate(slot: Slot, dateHasOpenEvent: boolean, eventKey: string, programServiceTypeKey: string, eventIsClosed: boolean, eventIsFull: boolean) {
        const timeToCompare = new Date(slot.time);
        timeToCompare.setHours(0, 0, 0, 0);

        let added = false;

        for (let i = 0; i < this.dates.length; i++) {
            if (this.dates[i].date.getTime() === timeToCompare.getTime()) {
                this.dates[i].addTime(slot, eventIsClosed);
                this.dates[i].eventKey = eventKey;
                this.dates[i].programServiceTypeKey = programServiceTypeKey;
                this.dates[i].dateHasOpenEvent = dateHasOpenEvent;
                this.dates[i].eventIsFull = eventIsFull;
                added = true;
                break;
            }
        }

        if (!added) {
            const dateSummary = new DateSummary(new Date(slot.time));
            dateSummary.eventKey = eventKey;
            dateSummary.programServiceTypeKey = programServiceTypeKey;
            dateSummary.dateHasOpenEvent = dateHasOpenEvent;
            dateSummary.eventIsFull = eventIsFull;
            dateSummary.addTime(slot, eventIsClosed);
            this.dates.push(dateSummary);
        }
    }

    removeAvailableSlot(eventKey: string, slotTime: Date): boolean {
        // Lots o' looping...
        let removed = false;
        _.each(this.dates, (date: DateSummary) => {
            _.each(date.times, (time: DateSummaryTime) => {
                _.each(time.serviceTypes, (serviceType: DateSummaryServiceType) => {
                    _.each(serviceType.slotOptions, (slotOption: SlotOption) => {
                        if (serviceType.eventKey === eventKey && new Date(slotOption.time).getTime() === new Date(slotTime).getTime()) {
                            // Remove any slot in the matching slotOption
                            slotOption.slots.pop();
                            removed = true;
                        }
                    });
                });
            });
        });

        return removed;
    }

    removeReservation(programServiceTypeKey: string, time: Date): boolean {
        const timeToCompare = new Date(time);
        timeToCompare.setHours(0, 0, 0, 0);

        let removed = false;

        const thisDateSummary: DateSummary = _.find(this.dates, (dateSummary: DateSummary) => dateSummary.date.getTime() === timeToCompare.getTime());

        if (!thisDateSummary) {
            return removed;
        }

        // TODO: Replace with better implementation...
        for (let i = 0; i < this.dates.length; i++) {
            const dateSummary = this.dates[i];
            if (dateSummary.date.getTime() === timeToCompare.getTime()) {
                for (let j = 0; j < dateSummary.times.length; j++) {
                    const dateSummaryTime = dateSummary.times[j];
                    for (let k = 0; k < dateSummaryTime.serviceTypes.length; k++) {
                        const serviceType = dateSummaryTime.serviceTypes[k];
                        if (serviceType.key === programServiceTypeKey) {
                            for (let l = 0; l < serviceType.slotOptions.length; l++) {
                                const slotOption = serviceType.slotOptions[l];
                                if (slotOption.time === time) {
                                    removed = slotOption.removeReservation();
                                    break;
                                }
                            }
                        }

                        if (removed) {
                            break;
                        }
                    }

                    if (removed) {
                        break;
                    }
                }

                if (removed) {
                    break;
                }
            }

            if (removed) {
                break;
            }
        }

        return removed;
    }

    removeReservations() {
        for (let i = 0; i < this.dates.length; i++) {
            const dateSummary = this.dates[i];
            for (let j = 0; j < dateSummary.times.length; j++) {
                const dateSummaryTime = dateSummary.times[j];
                for (let k = 0; k < dateSummaryTime.serviceTypes.length; k++) {
                    const serviceType = dateSummaryTime.serviceTypes[k];
                    for (let l = 0; l < serviceType.slotOptions.length; l++) {
                        const slotOption = serviceType.slotOptions[l];
                        slotOption.removeReservation();
                    }
                }
            }
        }
    }

    dayHasEnough(theDate: Date, serviceTypesNeeded: string[], numberOfParticipants: number): number {
        const hasEnough = [];

        if (this.dates.length) {
            const dateSummary: DateSummary = _.find(this.dates, (dt: DateSummary) => {
                const thisDt = new Date(dt.date);
                return thisDt.getFullYear() === theDate.getFullYear() && thisDt.getMonth() === theDate.getMonth() && thisDt.getDate() === theDate.getDate();
            });

            if (dateSummary) {
                _.each(serviceTypesNeeded, (st: string) => {
                    hasEnough.push(dateSummary.dayHasEnough(st, numberOfParticipants));
                });
            }
        }

        if (hasEnough.length === 0) {
            return -1;
        } else {
            const uniq = _.uniq(hasEnough);

            if (uniq.length === 1) {
                return uniq[0];
            } else {
                return 0;
            }
        }
    }

    timeHasEnough(timeId: string, serviceTypesNeeded: string[], numberOfParticipants: number): number {
        const hasEnough = [];

        if (this.dates.length) {
            const parts = timeId.split('_');
            const year = parseInt(parts[2], 10);
            const month = parseInt(parts[0], 10) - 1;
            const day = parseInt(parts[1], 10);
            const dateTime = new Date(year, month, day);

            const dateSummary: DateSummary = _.find(this.dates, (dt: DateSummary) => {
                const thisDt = dt.date;
                return thisDt.getFullYear() === dateTime.getFullYear() && thisDt.getMonth() === dateTime.getMonth() && thisDt.getDate() === dateTime.getDate();
            });

            if (dateSummary) {
                const theTime: DateSummaryTime = _.find(dateSummary.times, (time: DateSummaryTime) => {
                    return time.id === timeId;
                });

                if (theTime) {
                    _.each(serviceTypesNeeded, (st: string) => {
                        hasEnough.push(theTime.timeHasEnough(st, numberOfParticipants));
                    });
                }
            }
        }

        if (hasEnough.length === 0) {
            return -1;
        } else {
            const uniq = _.uniq(hasEnough);

            if (uniq.length === 1) {
                return uniq[0];
            } else {
                return 0;
            }
        }
    }

    constructor(apiEvents: ApiEvent[]) {
        for (const apiEvent of apiEvents) {
            let eventIsClosed = true;
            let dateHasOpenEvent = false;
            const thisScheduleLockDt = moment.utc(apiEvent.scheduleLockDate);
            const nowUtc = moment.utc();

            if (thisScheduleLockDt.isAfter(nowUtc)) {
                eventIsClosed = false;
                dateHasOpenEvent = true;
            }
            // If current event is closed, verify no other open events exist for this day.
            if (eventIsClosed) {
                const openApiEvent: ApiEvent = _.find(apiEvents, (event: ApiEvent) => {
                    const eventScheduleLockDt = new Date(event.scheduleLockDate);
                    return apiEvent.startDate === event.startDate && event.key !== apiEvent.key && moment(eventScheduleLockDt).isAfter(moment());
                });
                if (openApiEvent) {
                    dateHasOpenEvent = true;
                }
            }
            if (apiEvent.availableSlots.length) {
                for (const apiSlot of apiEvent.availableSlots) {
                    const slot = new Slot(apiSlot, apiEvent.bookForOthers);
                    slot.eventKey = apiEvent.key;
                    this.addDate(slot, dateHasOpenEvent, apiEvent.key, apiEvent.service.programServiceTypeKey, eventIsClosed, false);
                }
            } else {
                // If current event does not have slots, verify no other open events exist for this day with slots.
                const openApiEventWithSlots: ApiEvent = _.find(apiEvents, (event: ApiEvent) => {
                    const eventScheduleLockDt = new Date(event.scheduleLockDate);
                    return apiEvent.startDate === event.startDate && event.key !== apiEvent.key && moment(eventScheduleLockDt).isAfter(moment()) && event.availableSlots.length > 0;
                });
                if (!openApiEventWithSlots) {
                    // Date with empty slots added here to indicate event with full slots
                    const dateSummary = new DateSummary(new Date(apiEvent.startDate));
                    dateSummary.eventIsFull = true;
                    dateSummary.dateHasOpenEvent = dateHasOpenEvent;
                    dateSummary.eventKey = apiEvent.key;
                    dateSummary.programServiceTypeKey = apiEvent.service.programServiceTypeKey;
                    this.dates.push(dateSummary);
                }
            }

            const event = new Event(apiEvent);
            this.events.push(event);
        }
    }
}

import { Component, OnDestroy,OnInit } from '@angular/core';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';
import { ResultDataPointMapping } from '../model/mappings';
import { Result } from '../model/result';
import { ResultsService } from '../results.service';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit, OnDestroy {

  subscriptions: ISubscription[] = [];
  mappings: ResultDataPointMapping[];
  hasError: boolean;
  readyForRender: boolean;
  map: ResultDataPointMapping;
  mappingsForChart: ResultDataPointMapping[];
  showChart = true;
  showPossibleDelayInResultsWarning = false;
  constructor(private _localizeService: LocalizeService, private _resultService: ResultsService) { }
  onSelect(fieldName) {
    this.showChart = false;
    this.mappingsForChart = _.filter(this.mappings, (map: ResultDataPointMapping) => {
      return map.fieldName === fieldName;
    });
    this.showChart = true;
  }
  getMappingGroup() {
    return _.uniqBy(this.mappings, 'fieldName');
  }
  ngOnInit() {
    this.subscriptions.push(this._resultService.getCombined().subscribe(combined => {
      const resultsContainer = combined[0];
      if (resultsContainer.resultDetailCode === 'WARNING_NORESULTS_POSSIBLEDELAY') {
        this.showPossibleDelayInResultsWarning = true;
      }
      _.forEach(resultsContainer.results, (result: Result) => {
        this.mappings = _.merge(this.mappings, this._resultService.getMappings(result, combined[1]));
        this.mappings = this.getOnlyIncludedChart(this.mappings);
        this.readyForRender = true;
      });

    }));
    this.subscriptions.push(this._resultService.resultError.subscribe(resultsError => {
      if (resultsError) {
        this.onError(resultsError);
      }
    }));
    this.subscriptions.push(this._resultService.resultLookupError.subscribe(resultsError => {
      if (resultsError) {
        this.onError(resultsError);
      }
    }));

  }

  private getOnlyIncludedChart(mappings: ResultDataPointMapping[]) {
    return _.filter(mappings, (map: ResultDataPointMapping) => {
          return map.displayInfo.includeInTrendChart === true;
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) { });
  }
  private onError(errorMessage) {
    console.log(errorMessage);
    this.hasError = true;
  }

}

import { Globals } from '../globals';

export class PersonDataCollectedCriteria {
    companyKey?: any;
    eventKey?: any;
    programServiceKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    unMatchedDataKey?: any;
    applicationKey?: any = Globals.ApplicationKeys.WellConnectPlus;
    dataCollectionKey?: any;
}
export class PersonDataCollectedCollectionRequest {
    applicationKey: any = Globals.ApplicationKeys.WellConnectPlus;
    programServiceTypeKey?: any;
    partnerKey?: any;
    companyKey: any;
    programServiceKey?: any;
    eventKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    unMatchedDataKey?: any;
}
export interface PersonDataCollectionsToCollectVew {
    dataCollectionKey: any;
    collectionName: string;
    personDataToCollection: PersonDataToCollectView[];
}
export interface PersonDataToCollectView {
    dataPromptKey: any;
    internalName: string;
    prePromptTag: string;
    tag: string;
    postPromptTag: string;
    htmlInputElement: string;
    genderKey?: any;
    patientOlderThan?: number;
    patientYoungerThan?: number;
    response: string;
    sortOrder: number;
    isRequired: boolean;
    personDataCollectedKey?: any;
    personDataCollectedResponseKey?: any;
    validators: PersonDataPromptValidator;
    dataPromptSelectKey?: any;
    responseChoices: ResponseChoice[];
}
export interface PersonDataPromptValidator {
    min?: number;
    max?: number;
    required?: boolean;
    email?: boolean;
    minLenght?: boolean;
    maxLenght?: boolean;
    pattern?: string;
}
export class PersonDataCollected {
    key: any;
    dataCollectionKey: any;
    dataPromptKey: any;
    eventKey?: any;
    programServiceKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    createdBy?: any;
    modifiedBy?: any;
    isDeleted = false;
    companyKey: any;
}
export class PersonDataCollectedResponse {
    key: any;
    personDataCollectedKey: any;
    dataPromptKey: any;
    response: any;
    isDeleted = false;
}
export class PersonDataToCollectSaveRequest {
    personDataToCollect: PersonDataCollected;
    personDataCollectedResponses: PersonDataCollectedResponse[];
}
export class PatientInsuranceCaptureModel {
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    stateKey: any;
    memberNumber: any;
    groupNumber: any;
    primaryCoverage: any;
    primaryCoverageYes: boolean;
    primaryCoverageNo: boolean;
}
export class PersonValidation {
    applicationKey?: any = Globals.ApplicationKeys.WellConnectPlus;
    dataCollectionKey: any;
    companyKey: any;
    eventKey?: any;
    programServiceKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
}
export interface PersonValidationRuleViolationResult {
    generalPassFailTag: string;
    passFailTag: string[];
}
export class ResponseChoice {
    dataPromptSelectKey: string;
    dataPromptSelectOptionKey: string;
    option: string;
    value: string;
    sortOrder?: number;
    isDefault = false;
    isExclusive = false;
    isSelected = false;
    //for UI use only
    isDisabled = false;
}

import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../localize/localize.service';
import { AttestationCapture } from './attestation.capture';
import { AttestationModel } from './attestation.model';
import { AttestationService } from './attestation.service';
import { AttestationModalCompleteComponent } from './attestation-complete-modal.component';

@Component({
    selector: 'app-attestation-modal',
    templateUrl: './attestation-modal.component.html',
    styleUrls: ['./attestation-modal.component.css']
})
export class AttestationModalComponent {
    @Input() attestation: AttestationModel;
    @Input() userKey: string;
    @Input() participantKey: string;
    form: FormGroup;
    public isNoResponse: boolean;
    public isYesResponse: boolean;
    public isSubmitting = false;
    constructor(private _activeModal: NgbActiveModal, private _formBuilder: FormBuilder, private _attestationService: AttestationService, private _localizationService: LocalizeService, private _modalService: NgbModal) {
        this.form = this._formBuilder.group({
            userResponse: ['', Validators.required]
        });
    }

    close() {
        this._activeModal.close();
    }

    captureAttestationResponse(event) {
        if (!this.form.valid || this.isSubmitting) {
            return;
        }
        this.isSubmitting = true;
        this._attestationService
            .captureAttestationResponse(
                new AttestationCapture({
                    programServiceKey: this.attestation.programServiceKey,
                    serviceOptionKey: this.attestation.serviceOptionKey,
                    userKey: this.userKey,
                    response: this.isYesResponse
                })
            )
            .subscribe(
                {
                    next: (response) => {
                        if (response.errors && response.errors.length > 0) {
                            this.handleError();
                        }
                        this.close();
                        this.isSubmitting = false;
                        this.showCompletedModal();
                    },
                    error: () => this.handleError()
                });
    }

    handleError() {
        alert(this._localizationService.get('unknownerrortryagain', 'message'));
    }

    setUserResponse(e) {
        //this handles the mutually exclusive checkboxes and allows the actual captured value to be a nullable boolean
        let responseValue = '';
        if (e.target.checked) {
            responseValue = e.target.value;
        }
        this.userResponse.setValue(responseValue);
        this.isNoResponse = responseValue === 'false';
        this.isYesResponse = responseValue === 'true';
    }

    get userResponse() {
        const control = this.form.get('userResponse');
        return control;
    }

    showCompletedModal() {
        const modal = this._modalService.open(AttestationModalCompleteComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.title = this.attestation.titleText;
    }
}

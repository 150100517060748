<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-CompanyTextCustomization">{{ 'modify' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="tertiary-bg close" id="actionId-CloseCompanyTextCustomization" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form #modifyForm="ngForm" (ngSubmit)="update()" id="formId-CompanyTextCustomization">
    <div class="modal-body">
        <div class="form-group">
            <label for="name">{{ 'name' | lesCustomLocalize : 'label' }}</label>
            <div class="form-control" id="name">{{ override.name }}</div>
        </div>

        <div class="form-group">
            <label for="description">{{ 'description' | lesCustomLocalize : 'label' }}</label>
            <div class="form-control" id="description">{{ override.description }}</div>
        </div>

        <div class="form-group">
            <label for="localeName">{{ 'locale' | lesCustomLocalize : 'label' }}</label>
            <div class="form-control secondary-text" id="locale">{{ override.languageLocale }}</div>
        </div>

        <div class="form-group">
            <label for="default">{{ 'default' | lesCustomLocalize : 'label' }}</label>
            <div class="form-control" id="default">{{ override.default }}</div>
        </div>

        <div class="form-group">
            <label for="override"
                >{{ 'override' | lesCustomLocalize : 'label' }} <span *ngIf="override.maxCustomLength">({{ 'maxlengthis' | lesCustomLocalize : 'message' : override.maxCustomLength }})</span></label
            >
            <textarea class="form-control" name="override" rows="4" [(ngModel)]="override.text" [maxLength]="getMaxLength()"></textarea>
        </div>
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn" id="actionId-CancelCompanyTextCustomization" (click)="activeModal.close()">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
        <button type="submit" class="tertiary-bg btn btn-primary" id="actionId-SaveCompanyTextCustomization" [disabled]="disableSubmit">{{ 'save' | lesCustomLocalize : 'label' }}</button>
    </div>
</form>

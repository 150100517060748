export class AttestationCapture {
    constructor(init?: Partial<AttestationCapture>) {
        Object.assign(this, init);
    }

    programServiceKey: string;
    serviceOptionKey: string;
    userKey: string;
    response: boolean;
}

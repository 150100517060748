import { Company } from '../../shared/company/company';

export class MessageToUser {
  key: string;
  image: string;
  biography: string;
  firstName: string;
  lastName: string;
  suffix: string;
  company: Company;

  private _initials: string;
  get initials(): string {
    if (this.firstName && this.lastName) {
      return this.firstName.substring(0, 1) + this.lastName.substring(0, 1);
    } else {
      return undefined;
    }
  }

  get profilePhoto(): string{
    if(this.image){
      return atob(this.image);
    } else{
      return undefined;
    }
  }

  constructor(config) {
    this.key = config.key;
    this.image = config.image;
    this.biography = config.biography;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.suffix = config.suffix;
    this.company = config.company;
    this.image = config.image;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { NewSecureMessage } from './new-secure-message';
import { SecureMessage } from './secure-message';

@Injectable()
export class NewMessageService {
  private messageSource = new Subject<SecureMessage>();
  newMessage = this.messageSource.asObservable();

  private createNewMessageSource = new Subject<NewSecureMessage>();
  createNewMessage = this.createNewMessageSource.asObservable();

  constructor() { }

  setLatestMessage(message: SecureMessage) {
    this.messageSource.next(message);
  }

  setCreateNewMessage(message: NewSecureMessage) {
    this.createNewMessageSource.next(message);
  }
}

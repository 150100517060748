<div class="modal-header">
    <h4 class="modal-title" id="titleId-CancelAppointments">{{ 'cancelappointment' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="close" id="actionId-CloseCancelAppointments" aria-label="Close" (click)="activeModal.close('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <p>{{ 'cancelappointmentconfirm' | lesCustomLocalize : 'message' }}</p>

    <div class="form-group row mt-0" *ngIf="notCurrentUser()">
        <label for="cancelReason" class="col-sm-3 col-form-label"
            ><b>{{ 'reason' | lesCustomLocalize : 'label' }}:</b></label
        >
        <div class="col-sm-9">
            <select class="form-control" id="formSelectId-CancelReason" name="cancelReason" [(ngModel)]="selectedCancelReason" [required]="true">
                <option value=""></option>
                <option *ngFor="let reason of cancelReasons" [ngValue]="reason">{{ reason.reason }}</option>
            </select>
        </div>
    </div>
</div>

<div class="modal-footer pt-10">
    <button type="button" class="btn" id="actionId-NoToCancelAppointments" (click)="activeModal.close('cancel')">{{ 'no' | lesCustomLocalize : 'label' }}</button>
    <button type="submit" class="btn btn-primary" id="actionId-YesCancelAppointments" [disabled]="notCurrentUser() && !selectedCancelReason ? true : false" (click)="cancelAppointment()">{{ 'yes' | lesCustomLocalize : 'label' }}</button>
</div>

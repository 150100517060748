import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _oauthService: OAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._oauthService.getAccessToken()) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this._oauthService.getAccessToken())
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}

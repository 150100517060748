export class TestConstants {
    public static get HeightFeet(): string {
        return 'A7DA324B-F1F5-46C3-8155-EF032B61A9E6';
    }
    public static get HeightInches(): string {
        return 'BA5890B9-9E70-44A7-BCF0-3E02296D35FF';
    }
    public static get Weight(): string {
        return '4179141B-536B-4E44-A793-57B8094431D1';
    }
    public static get WaistInches(): string {
        return '98E97958-0073-49EF-99BC-4F2D0382CA8E';
    }
    public static get Neck(): string {
        return '8827667D-C8A3-4FAE-9070-0B3FA8E0D1CF';
    }
    public static get Hip(): string {
        return '6B98CBA6-34DA-4199-8A8B-3DC9367AC61B';
    }
    public static get BloodPressureSBP(): string {
        return 'E69F359E-615F-47C2-84F1-561F5910DB2C';
    }
    public static get BloodPressureDBP(): string {
        return 'FF4C588F-24EB-4F67-A28E-EA3C78671AD7';
    }
    public static get HeartRate(): string {
        return '6CFD9C57-DEDC-4008-B471-7C63D07E0DAE';
    }
    public static get BodyFat(): string {
        return '686E71BA-5570-450C-AC2A-B739FD46EE4E';
    }
}

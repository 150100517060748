import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../../shared/localize/localize.service';


@Component({
  selector: 'app-person-widget-modal',
  templateUrl: './person-widget-modal.component.html',
  styleUrls: ['./person-widget-modal.component.css']
})
export class PersonWidgetModalComponent implements OnInit {
  @Input() header: string = this._localizeService.getCombinedTag('title.alert');
  @Input() msg: string = this._localizeService.get('unknownerror', 'message');
  @Input() msgTag: string;
  @Input() msgTags: string[];
  msgLanguage: string;
  msgTagLanguages: any[];
  showCustomError = false;
  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService) { }

  ngOnInit() {
    if (this.msgTag) {
      this.msgLanguage = this._localizeService.getCombinedTag(this.msgTag)
      this.showCustomError = true;
    }
    if (this.msgTags && this.msgTags.length) {
      this.msgTagLanguages = [];
      this.msgTags.forEach(element => {
        this.msgTagLanguages.push(this._localizeService.getCombinedTag(element))
      });
      this.showCustomError = true;
    }
  }
}

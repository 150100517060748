<ng-container *ngIf="readyForRender">
    <h1 class="primary-text">{{ 'appointments' | lesCustomLocalize : 'label' }}</h1>
    <p>{{ 'myappointmentsblurb' | lesCustomLocalize : 'message' }}</p>

    <div class="pb-2">
        <a *ngIf="otherUserKey" routerLink="/participant/{{ otherUserKey }}" routerLinkActive="active">{{ 'scheduleappointment' | lesCustomLocalize : 'label' }}</a>
        <a *ngIf="!otherUserKey" routerLink="/home" routerLinkActive="active">{{ 'scheduleappointment' | lesCustomLocalize : 'label' }}</a>
    </div>

    <app-appointment-list></app-appointment-list>
</ng-container>

<app-loading *ngIf="!readyForRender" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

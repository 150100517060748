import { Globals } from './globals';

export interface Consent {
    consentLanguageKey: string;
    userKey: string;
    hasConsent: boolean;
    consentDateTime: Date;
    isHIPAA: boolean;
}

export class Service {
    allowOptinPaperCommunication: boolean;
    programServiceGroupKey: string;
    companyKey: string;
    programServiceKey: string;
    serviceType: string;
    programType: string;
    programName: string;
    name: string;
    serviceDescription: string;
    startDate: Date;
    endDate: Date;
    portalPublishDate: Date;
    phoneNumber: string;
    faxNumber: string;
    email: string;
    dueDate: Date;
    lookBackDate: Date;
    ddocsFormGuid: string;
    serviceCardLabel: string;
    requiresConsent: boolean;
    insurancePreferred: boolean;
    programServiceType: ProgramServiceType;
    programServiceTypeKey: string;
    isEligibleOnly: boolean;
    isElectronicUpload: boolean;
    isBookForOthers: boolean;
    showOnPortal: boolean;
    informationUrl: string;
    subsidiaryCode: string;
    subsidiaryName: string;
    hasEligibilityConditions: string;
    navigationMessage: string;
    displayName: string;
    customizationCode: string;
    qualificationRuleOverrideCode: string;
    orderBy: number;
    isOnlineScheduler?: boolean;
    get hasDownload(): boolean {
        return this.serviceType === Globals.ServiceTypes.PDF;
    }

    get hasUpload(): boolean {
        return this.programType.toLocaleLowerCase().indexOf('php') > 0;
    }

    get hasAttestation(): boolean {
        return this.serviceType === Globals.ServiceTypes.Attestation;
    }
    programOrderableTests: ProgramOrderableTest[];

}
export interface ServiceQualificationRuleFailures {
    programServiceKey: string;
    programServiceTypeKey: string;
    programServiceGroupKey: string;
    displayName: string;
    compareValue: string;
    compareOperator: string;
    secondaryCompareValue: string;
    secondaryCompareValueType: string;
    failureMessage: string;
    overrideableByProgramService: boolean;
}
export interface ServicePaymentOptions {
    key: string;
    paymentOptionKey: string;
    name: string;
    description?: string;
    sortOrder: number;
    isDeleted: boolean;
}
export class UserServices {
    programService: Service;
    serviceQualificationRuleFailures: ServiceQualificationRuleFailures[];
    servicePaymentOptions: ServicePaymentOptions[];
}
export class ProgramServiceType {
    key: string;
    type: string;
    description: string;
}
export interface ProgramOrderableTest {
    key: string;
    programServiceKey: string;
    orderableTestKey: string;
    orderableTestModality: string;
}

export enum GicModality {
    HomeTestKit,
    PSC,
    Both,
    None,
}
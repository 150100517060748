import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate,NgbDateStruct, NgbInputDatepicker, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { PageScrollService } from 'ngx-page-scroll-core';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { ConsentsNeeded, ConsentsNeededByServiceSearchModel } from '../../shared/consent-modal/consent';
import { ConsentService } from '../../shared/consent-modal/consent.service';
import { ConsentModalComponent } from '../../shared/consent-modal/consent-modal.component';
import { Globals } from '../../shared/globals';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { ZipCode } from '../../shared/metadata/zipcode';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { SevereSymptomCheckPost } from '../../shared/servere-symptom-modal/severe-symptom';
import { SevereSymptomService } from '../../shared/servere-symptom-modal/severe-symptom.service';
import { SevereSymptomModalComponent } from '../../shared/servere-symptom-modal/severe-symptom-modal.component';
import { UserServices } from '../../shared/service';
import { BootstrapSize, SizeCheckerService } from '../../shared/size-checker/size-checker.service';
import { UserService } from '../../shared/user/user.service';
import { UserInformationRequestVerification } from '../../shared/user/user-information';
import { Utils } from '../../shared/utils';
import { UserDataCaptureResult } from '../../user-data-capture-modal/user-data-capture';
import { UserDataCaptureModalComponent } from '../../user-data-capture-modal/user-data-capture-modal.component';
import { ApiAppointment } from '../model/api-appointment';
import { Appointment } from '../model/appointment';
import { DateSummary, DateSummaryCollection, DateSummaryServiceType, DateSummaryTime } from '../model/date-summary';
import { EventDateAndLocation, EventDateAndLocationCollection } from '../model/event-dates-and-location';
import { EventLocation, EventLocationSelection, EventLocationSelections, EventLocationWithServiceTypeCollection } from '../model/event-location';
import { Itinerary, ItineraryAppointment, ItinerarySubmission } from '../model/itinerary';
import { AvailableProgramServiceType, ProgramServiceAndType } from '../model/program-service-and-type';
import { SlotSearchCriteria } from '../model/slot-search-criteria';
import { UnsuccessfulAppointment } from '../model/unsuccessful-appointment';
import { SchedulerService } from '../scheduler.service';
import { CancelExistingAndAddNewConfirmComponent } from './cancel-existing-and-add-new-confirm/cancel-existing-and-add-new-confirm.component';

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit, OnDestroy {
    @ViewChild('selectdays') selectDays: ElementRef;
    @ViewChild('gototop') toTop: ElementRef;
    @ViewChild('gototopmobile') toTopMobile: ElementRef;
    @ViewChild('filterByDateDatePicker') dateFilterDatePicker: NgbInputDatepicker;
    @ViewChild('filterByLocationDatePicker')
    locationFilterDatePicker: NgbInputDatepicker;

    readyForRender = false;
    hasError = false;
    currentUser: Person;
    otherPerson: Person;
    otherUserKey: string;
    userServices: UserServices[] = [];
    localizeReady = false;
    subscriptions: ISubscription[] = [];
    currentWidth: BootstrapSize;
    currentBodyHeight: number;
    userAppts: ApiAppointment[] = [];

    modalRef: NgbModalRef;
    loadingVisible = false;

    showViewAppointmentsLink = false;
    showExistingAppointments = false;
    showDateLegend = false;
    showDateFilterLegend = false;
    isProcessing = false;

    sectionToShow = 'search';
    isFetching = false;
    noEvents = false;
    eventDateAndLocationCollection: EventDateAndLocationCollection = null;
    eventLocationWithServiceTypeCollection: EventLocationWithServiceTypeCollection = null;
    filteredLocations: EventLocation[];
    filteredLocationsWithService: EventLocationSelections[];
    filtersAreVisible = true;
    bookForMe = true;
    bookForGuest = false;
    dateFilterDaySelected = false;
    dataCaptureAddedProgramKeys: string[];
    userDataCaptureResult: UserDataCaptureResult = new UserDataCaptureResult();
    // Filter Options
    locationsAvailable: EventLocation[];
    serviceTypesAvailable: AvailableProgramServiceType[];
    radiiAvailable: any[] = Globals.Radii;
    enableBookForOthers = false;
    locationBy = 'zip';

    // Filters
    selectedSearchMode = 'location';
    selectedZipCode: string = null;
    selectedZipCodeObjs: ZipCode[] = [];
    emptyRadius = 9999;
    defaultRadius: number = Globals.DefaultRadius;
    selectedRadius: number = this.emptyRadius;
    selectedLocation: EventLocation = null;
    selectedDateFilterLocation: string = null;
    selectedServiceTypes: string[] = [];
    dateFilterDay: NgbDateStruct;
    dateOfEvent: NgbDateStruct;
    filterByDateSelectedDay: Date;

    // Just a way to get a collection of numbers to drive the participant ordinal.
    // Note that the original understanding of the requirement was that there could be n
    // number of additional participants. However, this was later clarified that there
    // will only be up to 1 additional participant.
    //
    numberOfParticipants = 1;

    get numberOfParticipantsCollection(): number[] {
        const coll = [];

        for (let i = 0; i < this.numberOfParticipants; i++) {
            coll.push(i + 1);
        }

        return coll;
    }

    dateCollectionSummary: DateSummaryCollection;
    itinerary: Itinerary;

    constructor(
        private _personService: PersonService,
        private _localizeService: LocalizeService,
        private _modalService: NgbModal,
        private _router: Router,
        private route: ActivatedRoute,
        private _activatedRoute: ActivatedRoute,
        private _schedulerService: SchedulerService,
        private _metadataService: MetadataService,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any,
        private _sizeCheckerService: SizeCheckerService,
        private _consentService: ConsentService,
        private _userService: UserService,
        private _severeSymptomService: SevereSymptomService
    ) { }

    person() {
        return this.otherPerson ? this.otherPerson : this.currentUser;
    }

    onLocationChange(by: string) {
        if (by !== 'zip') {
            this.selectedZipCode = null;
            this.selectedRadius = this.defaultRadius;
            this.filterLocationsByCurrentLocation();
        }
    }

    //
    // Filter the locations based on the "current location".
    // If not already set, set the selected location to the nearest
    // visible location based on the "current location" and fetch
    // for the available slots.
    // Assumption: There will always be at least one available location.
    //
    filterLocationsByCurrentLocation(): void {
        const that = this;

        this.selectedZipCode = this._localizeService.get('currentlocation', 'label');

        navigator.geolocation.getCurrentPosition((pos) => {
            _.each(that.locationsAvailable, (loc: EventLocation) => {
                loc.distanceFromStartingPoint = Utils.calculateDistance(pos.coords.latitude, pos.coords.longitude, loc.latitude, loc.longitude);
            });

            that.performLocationSort.call(that, pos);

            this.checkForVisibleLocations();
        });
    }

    //
    // Fired when zip code changes and on initial display. Adjust list of locations based on zip code provided
    // unless current location has been selected.
    //
    calculateLocationDistances(): void {
        if (this.selectedSearchMode === 'location') {
            this.closeFilterByLocationPicker();
        } else {
            this.closeFilterByDatePicker();
        }

        if (this.selectedZipCode) {
            if (this.selectedRadius === this.emptyRadius) {
                this.selectedRadius = this.defaultRadius;
            }
            this.subscriptions.push(
                this._metadataService.getZipCodes(this.selectedZipCode).subscribe(
                    (zips) => {
                        this.selectedZipCodeObjs = zips;

                        if (zips.length) {
                            const zip: ZipCode = zips[0];
                            _.each(this.locationsAvailable, (loc: EventLocation) => {
                                loc.distanceFromStartingPoint = Utils.calculateDistance(zip.latitude, zip.longitude, loc.latitude, loc.longitude);
                            });
                        } else {
                            _.each(this.locationsAvailable, (loc: EventLocation) => {
                                loc.distanceFromStartingPoint = null;
                            });

                            alert(this._localizeService.get('zipcodenotfound', 'message'));
                            this.selectedZipCode = null;
                            return;
                        }

                        this.performLocationSort();

                        this.checkForVisibleLocations();

                        this.selectedLocation = null;
                    },
                    (error) => {
                        this.onError();
                    }
                )
            );
        } else {
            this.selectedRadius = this.emptyRadius;
            this.dateCollectionSummary = null;
            // When zip code is cleared we need to reestablish the locations drop-down
            _.each(this.locationsAvailable, (loc: EventLocation) => {
                loc.distanceFromStartingPoint = null;
            });
            if (this.selectedSearchMode === 'date' && this.dateFilterDaySelected && this.filterByDateSelectedDay) {
                if (this.checkForVisibleLocationsWhenFilteringByDate(this.filterByDateSelectedDay)) {
                    this.performLocationSort();
                }
            } else {
                this.filteredLocations = this.locationsAvailable;
                this.performLocationSort();
            }
            this.selectedLocation = null;
        }
    }

    //
    // Fired when radius changes. Adjust list of locations based on radius selected.
    //
    radiusSelected($event): void {
        this.dateCollectionSummary = null;
        this.selectedLocation = null;
        this.dateOfEvent = null;
        this.selectedRadius = parseInt($event.currentTarget.value, 10);
        this.performLocationSort();
        this.checkForVisibleLocations();
    }

    //
    // Get slots when filtering by date.
    //
    fetchSlotsFilteredByDate(): void {
        this.clearAppts();
        this.closeFilterByDatePicker();
        this.continueFetchingSlots(this.selectedDateFilterLocation, true, moment().toDate());
    }

    //
    // Get slots when filtering by location.
    //
    fetchSlotsFilteredByLocation(): void {
        this.clearAppts();
        this.closeFilterByLocationPicker();
        if (!this.selectedLocation) {
            this.selectedLocation = null;
        }
        if (this.selectedLocation) {
            this.continueFetchingSlots(this.selectedLocation.key, false, moment().toDate());
        } else {
            this.continueFetchingSlots(null, false, moment().toDate());
        }
    }

    //
    // Call API to fetch slots for date range.
    //
    private continueFetchingSlots(locationKey: string, dateFilterBeingUsed: boolean, theDay: Date): void {
        this.dateOfEvent = null;
        this.dateCollectionSummary = null;
        const criteria = new SlotSearchCriteria();
        criteria.locationKey = locationKey ? locationKey : null;
        criteria.serviceTypeKeys = this.selectedServiceTypes;
        criteria.userKey = this.otherPerson ? this.otherPerson.userKey : this.currentUser.userKey;
        const dateToUse = moment.utc([theDay.getFullYear(), theDay.getMonth(), theDay.getDate(), 0, 0, 0, 0]);
        criteria.rangeStart = dateToUse.toDate();
        criteria.rangeEnd = dateToUse.add(1, 'years').toDate();

        if (!criteria.isValid()) {
            return;
        }

        this.isFetching = true;

        this.showLoader(true, this._localizeService.get('searchingforslots', 'message'));

        this.subscriptions.push(
            this._schedulerService.getAvailableSlots(criteria).subscribe(
                (summary) => {
                    this.dateCollectionSummary = summary;
                    this.decrementSelectedSlots();

                    this.isFetching = false;

                    if (!dateFilterBeingUsed) {
                        let firstAvailableDate = this.dateCollectionSummary.getFirstAvailableDate(this.selectedServiceTypes);

                        if (!firstAvailableDate) {
                            firstAvailableDate = this.dateCollectionSummary.getFirstAvailableDate(this.selectedServiceTypes, true);
                        }

                        if (firstAvailableDate) {
                            this.dateOfEvent = new NgbDate(firstAvailableDate.getFullYear(), firstAvailableDate.getMonth() + 1, firstAvailableDate.getDate());
                        }
                    }

                    if (!dateFilterBeingUsed) {
                        this.onDateOfEventChange();
                    } else {
                        if (this.dateFilterDaySelected && this.dateFilterDay && this.selectedDateFilterLocation && this.dateCollectionSummary) {
                            const that = this;
                            window.setTimeout(function () {
                                const scrollTo = new Date(that.dateFilterDay.year, that.dateFilterDay.month - 1, that.dateFilterDay.day);
                                that.scrollToDate(scrollTo);
                            }, 0);
                        }
                    }

                    this.showLoader(false);
                },
                (error) => {
                    this.hasError = true;
                    this.isFetching = false;
                    this.showLoader(false);
                }
            )
        );
    }

    //
    // Reduce the available slot count for any matching participant itinerary events.
    //
    private decrementSelectedSlots() {
        if (this.itinerary.numberOfAppointments > 0) {
            for (let par = 0; par < this.itinerary.participants.length; par++) {
                for (let appt = 0; appt < this.itinerary.participants[par].appointments.length; appt++) {
                    for (let date = 0; date < this.dateCollectionSummary.dates.length; date++) {
                        for (let time = 0; time < this.dateCollectionSummary.dates[date].times.length; time++) {
                            for (let service = 0; service < this.dateCollectionSummary.dates[date].times[time].serviceTypes.length; service++) {
                                if (this.itinerary.participants[par].appointments[appt].programServiceType.programServiceTypeKey === this.dateCollectionSummary.dates[date].times[time].serviceTypes[service].key) {
                                    for (let slot = 0; slot < this.dateCollectionSummary.dates[date].times[time].serviceTypes[service].slotOptions.length; slot++) {
                                        const slotOption = this.dateCollectionSummary.dates[date].times[time].serviceTypes[service].slotOptions[slot];
                                        if (slotOption.time === this.itinerary.participants[par].appointments[appt].time) {
                                            slotOption.addReservation();
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //
    // Check to see if locations are available for current filters.
    //
    private checkForVisibleLocations(): boolean {
        if (this.selectedSearchMode === 'location') {
            return this.checkForVisibleLocationsWhenFilteringByLocation();
        } else {
            return this.checkForVisibleLocationsWhenFilteringByDate(this.filterByDateSelectedDay);
        }
    }

    //
    // Check to see if locations are available for current filters. If so and only one location remains
    // fetch slots for that location. If radius not provided or set to 0 look for an exact zip code match.
    //
    private checkForVisibleLocationsWhenFilteringByLocation(): boolean {
        let hasVisibleLocations = false;

        if (this.locationsAvailable.length) {
            this.filteredLocations = null;
            if (this.selectedRadius === this.emptyRadius) {
                if (this.selectedZipCode === this._localizeService.get('currentlocation', 'label')) {
                    this.filteredLocations = this.locationsAvailable.filter((loc) => loc.distanceFromStartingPoint <= 0.005);
                } else {
                    this.filteredLocations = this.locationsAvailable.filter((loc) => loc.zip === this.selectedZipCode);
                }
            } else {
                this.filteredLocations = this.locationsAvailable.filter((loc) => loc.distanceFromStartingPoint <= this.selectedRadius);
            }

            if (this.filteredLocations.length > 0) {
                hasVisibleLocations = true;
            }
        }

        if (!hasVisibleLocations) {
            this.selectedLocation = null;
            this.dateCollectionSummary = null;
            let msg = '';
            if (this.selectedRadius === this.emptyRadius) {
                msg = this._localizeService.get('nolocationsfound', 'message');
            } else {
                msg = this._localizeService.get('nolocationswithinxmiles', 'message', this.selectedRadius.toString());
            }
            alert(msg);
        }

        return hasVisibleLocations;
    }

    //
    // Check to see if locations are available for current filtered day and filters. If so and only one location remains
    // fetch slots for that location. If radius not provided or set to 0 look for an exact zip code match.
    //
    private checkForVisibleLocationsWhenFilteringByDate(theDate: Date): boolean {
        let hasVisibleLocations = false;

        if (this.selectedServiceTypes.length && this.locationsAvailable.length) {
            this.filteredLocationsWithService = [];
            let locationsInRange: EventLocation[];
            if (this.selectedRadius === 0) {
                if (this.selectedZipCode === this._localizeService.get('currentlocation', 'label')) {
                    locationsInRange = this.locationsAvailable.filter((loc) => loc.distanceFromStartingPoint <= 0.005);
                } else {
                    locationsInRange = this.locationsAvailable.filter((loc) => loc.zip === this.selectedZipCode);
                }
            } else {
                locationsInRange = this.locationsAvailable.filter((loc) => loc.distanceFromStartingPoint <= this.selectedRadius);
            }

            // Check to see if filter locations exist for the selected day. If so, construct the location drop-down.
            if (locationsInRange.length > 0) {
                for (let loc = 0; loc < locationsInRange.length; loc++) {
                    for (let service = 0; service < this.selectedServiceTypes.length; service++) {
                        for (let dateAndLoc = 0; dateAndLoc < this.eventDateAndLocationCollection.eventDateAndLocation.length; dateAndLoc++) {
                            let thisDt = new Date(this.eventDateAndLocationCollection.eventDateAndLocation[dateAndLoc].startDate);
                            if (
                                thisDt.getFullYear() === theDate.getFullYear() &&
                                thisDt.getMonth() === theDate.getMonth() &&
                                thisDt.getDate() === theDate.getDate() &&
                                this.eventDateAndLocationCollection.eventDateAndLocation[dateAndLoc].programServiceTypeKey === this.selectedServiceTypes[service] &&
                                this.eventDateAndLocationCollection.eventDateAndLocation[dateAndLoc].eventLocationKey === locationsInRange[loc].key
                            ) {
                                // If event is closed or full, add to selection group and move on.
                                let thisScheduleLockDt = new Date(this.eventDateAndLocationCollection.eventDateAndLocation[dateAndLoc].scheduleLockDate);
                                if (!this.eventDateAndLocationCollection.eventDateAndLocation[dateAndLoc].hasSLotsAvailable || moment(thisScheduleLockDt).isBefore(moment())) {
                                    this.addLocationToLocationDropDown(true, 50, 'closedorfullevents', null, null, locationsInRange[loc].key, locationsInRange[loc].name);
                                } else {
                                    // If looking for multiple service types, check if this location is offering other service type.
                                    if (this.selectedServiceTypes.length > 1) {
                                        const bothServiceTypesExist: EventDateAndLocation = _.find(this.eventDateAndLocationCollection.eventDateAndLocation, (edl: EventDateAndLocation) => {
                                            thisDt = new Date(edl.startDate);
                                            thisScheduleLockDt = new Date(edl.scheduleLockDate);
                                            return (
                                                thisDt.getFullYear() === theDate.getFullYear() &&
                                                thisDt.getMonth() === theDate.getMonth() &&
                                                thisDt.getDate() === theDate.getDate() &&
                                                edl.eventLocationKey === this.eventDateAndLocationCollection.eventDateAndLocation[dateAndLoc].eventLocationKey &&
                                                edl.programServiceTypeKey !== this.selectedServiceTypes[service] &&
                                                edl.hasSLotsAvailable &&
                                                moment(thisScheduleLockDt).isAfter(moment())
                                            );
                                        });
                                        // If the location has events for both services so we need to group using "and".
                                        if (bothServiceTypesExist) {
                                            this.addLocationToLocationDropDown(false, 10, bothServiceTypesExist.programServiceTypeKey, this.selectedServiceTypes[service], 'and', locationsInRange[loc].key, locationsInRange[loc].name);
                                        } else {
                                            // If no other conditions are satisfied the location is grouped under the single service.
                                            this.addLocationToLocationDropDown(false, 30, this.selectedServiceTypes[service], null, null, locationsInRange[loc].key, locationsInRange[loc].name);
                                        }
                                    } else {
                                        // When dealing with a single service selection, the location is grouped under the selected service.
                                        this.addLocationToLocationDropDown(false, 30, this.selectedServiceTypes[service], null, null, locationsInRange[loc].key, locationsInRange[loc].name);
                                    }
                                }
                            }
                        }
                    }
                }
                this.filteredLocationsWithService = _.sortBy(this.filteredLocationsWithService, ['sortOrder']);
            }

            if (this.filteredLocationsWithService !== null && this.filteredLocationsWithService.length > 0) {
                hasVisibleLocations = true;
            }
        }

        if (!hasVisibleLocations) {
            this.selectedLocation = null;
            this.dateCollectionSummary = null;
            let msg = '';
            if (this.selectedRadius === this.emptyRadius) {
                msg = this._localizeService.get('nolocationsfound', 'message');
            } else {
                msg = this._localizeService.get('nolocationswithinxmiles', 'message', this.selectedRadius.toString());
            }
            alert(msg);
        }

        return hasVisibleLocations;
    }

    // Construct the group named. Check for existing selection group. If found, add location else create selection group.
    private addLocationToLocationDropDown(groupLiteral: boolean, sortOrder: number, serviceType1: string, serviceType2: string, connector: string, locationKey: string, locationName: string): void {
        let groupName = 'tbd'; // Initial value should not survive.
        if (groupLiteral) {
            groupName = this._localizeService.get(serviceType1, 'label');
        } else {
            const serviceType1Display: AvailableProgramServiceType = _.find(this.serviceTypesAvailable, (s: AvailableProgramServiceType) => {
                return s.key === serviceType1;
            });
            if (serviceType1Display) {
                groupName = this.getProgramLabel(serviceType1Display.displayName);
                if (serviceType2 !== null) {
                    const serviceType2Display: AvailableProgramServiceType = _.find(this.serviceTypesAvailable, (s: AvailableProgramServiceType) => {
                        return s.key === serviceType2;
                    });
                    if (serviceType2Display) {
                        groupName += ' ' + this._localizeService.get(connector, 'label') + ' ' + this.getProgramLabel(serviceType2Display.displayName);
                    } else {
                        groupName += ' ' + this._localizeService.get(connector, 'label') + ' ' + serviceType2;
                    }
                }
            } else {
                groupName = serviceType1; // This condition should never be reached.
            }
        }
        // Check if group name currently exists.
        const serviceGroupExists: EventLocationSelections = _.find(this.filteredLocationsWithService, (els: EventLocationSelections) => {
            return els.groupName === groupName || (els.serviceTypeKey1 === serviceType1 && els.serviceTypeKey2 === serviceType2) || (els.serviceTypeKey1 === serviceType2 && els.serviceTypeKey2 === serviceType1);
        });
        // If the group exists, add the location provided it hasn't already been added
        if (serviceGroupExists) {
            const locationExists: EventLocationSelection = _.find(serviceGroupExists.eventLocationSelection, (loc: EventLocationSelection) => {
                return loc.name === locationName;
            });
            // Add the location if the location doesn't currently exist for the group.
            if (!locationExists) {
                const newEventLocationSelection = new EventLocationSelection(locationKey, locationName);
                newEventLocationSelection.key = locationKey;
                newEventLocationSelection.name = locationName;
                serviceGroupExists.eventLocationSelection.push(newEventLocationSelection);
            }
        } else {
            // Create new group and add the location.
            const newEventLocationSelection = new EventLocationSelection(locationKey, locationName);
            const selections: EventLocationSelection[] = [];
            selections.push(newEventLocationSelection);
            const newEventLocationSelections = new EventLocationSelections(groupName, sortOrder, serviceType1, serviceType2, selections);
            this.filteredLocationsWithService.push(newEventLocationSelections);
        }
    }

    private performLocationSort() {
        if (this.locationsAvailable.length) {
            this.locationsAvailable = _.sortBy(this.locationsAvailable, ['locationName']);
        }
    }

    showGeolocation() {
        return this.locationsAvailable.length && window.navigator && window.navigator.geolocation;
    }

    selectAllContent($event) {
        $event.target.select();
    }

    // When a date is selected scroll to the date provided an event exists for the day selected.
    onDateOfEventChange() {
        const that = this;
        window.setTimeout(function () {
            const dt = that.dateOfEvent;
            if (dt && that.dateCollectionSummary) {
                const theDate = new Date(dt.year, dt.month - 1, dt.day);
                if (that.dateCollectionSummary.eventsExist(theDate)) {
                    that.scrollToDate(theDate);
                }
            }
        }, 0);
    }

    onDateFilterChange() {
        const that = this;
        this.dateCollectionSummary = null;
        this.filteredLocationsWithService = null;
        this.selectedDateFilterLocation = null;
        this.dateFilterDaySelected = true;
        window.setTimeout(function () {
            const dt = that.dateFilterDay;
            if (dt) {
                that.filterByDateSelectedDay = new Date(dt.year, dt.month - 1, dt.day);
                // Populate location drop down based on selected date.
                if (that.eventDateAndLocationCollection.eventsExist(that.filterByDateSelectedDay)) {
                    that.checkForVisibleLocationsWhenFilteringByDate(that.filterByDateSelectedDay);
                }
            }
        }, 0);
    }

    scrollToDate(dt: Date) {
        if (!dt || !this.dateCollectionSummary) {
            return;
        }

        this.closeFilterByLocationPicker();
        this.closeFilterByDatePicker();
        const target = window.document.getElementById(dt.getTime().toString());
        if (target) {
            target.scrollIntoView(true);
        } else {
            alert(this._localizeService.get('noavailabletimeslotforthisday', 'message'));
        }
    }

    filterByLocationSelected() {
        this.filteredLocations = this.locationsAvailable;
        this.selectedLocation = null;
        this.selectedZipCode = null;
        this.dateFilterDay = null;
        this.dateFilterDaySelected = false;
        this.closeFilterByDatePicker();
    }

    filterByDateSelected() {
        this.closeFilterByLocationPicker();
        this.selectedZipCode = null;
        this.selectedRadius = this.emptyRadius;
        if (!this.eventDateAndLocationCollection === null) {
            if (this.dateFilterDaySelected && this.filterByDateSelectedDay) {
                this.checkForVisibleLocationsWhenFilteringByDate(this.filterByDateSelectedDay);
            }
        }
    }

    minDate() {
        const today = new Date();
        return {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
    }

    maxDate() {
        const today = new Date();
        return {
            year: today.getFullYear() + 1,
            month: today.getMonth() + 1,
            day: today.getDate()
        };
    }

    getDateString(dt: NgbDate) {
        if (dt) {
            const theDate = new Date(dt.year, dt.month - 1, dt.day);
            return dt.month + '/' + dt.day + '/' + dt.year + ' ' + Utils.dayOfWeek(theDate);
        } else {
            return '';
        }
    }

    getMonthString(dt: NgbDate) {
        if (dt) {
            const theDate = new Date(dt.year, dt.month - 1, dt.day);
            const momDate = moment(theDate);
            return this._localizeService.get(momDate.format('MMMM').toLowerCase(), 'month') + ' ' + momDate.format('YYYY');
        } else {
            return '';
        }
    }

    getDayString(date: string) {
        if (date.split(' ').length !== 2) {
            return date;
        }

        const dateString = date.split(' ')[0];
        const dayOfWeek = date.split(' ')[1];
        return dateString + ' ' + this._localizeService.get(dayOfWeek.toLowerCase(), 'day');
    }

    getLocaleDay(day: string) {
        return this._localizeService.get(day.toLowerCase(), 'day');
    }

    getLocaleMonth(month: string) {
        return this._localizeService.get(month.toLowerCase(), 'month');
    }

    getDayFilterClass(date: NgbDateStruct) {
        const theDate = new Date(date.year, date.month - 1, date.day);

        if (!this.eventDateAndLocationCollection) {
            return '';
        }

        const style = this.eventDateAndLocationCollection.getDateStyle(theDate, this.selectedServiceTypes, null);
        if (style.length > 0) {
            return 'day' + style;
        } else {
            return '';
        }
    }

    getDayLocationClass(date: NgbDateStruct) {
        const theDate = new Date(date.year, date.month - 1, date.day);

        if (!this.eventDateAndLocationCollection) {
            return '';
        }

        const style = this.eventDateAndLocationCollection.getDateStyle(theDate, this.selectedServiceTypes, this.selectedLocation ? this.selectedLocation.name : null);
        if (style.length > 0) {
            return 'day' + style;
        } else {
            return '';
        }
    }

    dayHasEnough(theDate: Date) {
        return this.dateCollectionSummary.dayHasEnough(theDate, this.selectedServiceTypes, this.numberOfParticipants);
    }

    timeHasEnough(timeId: string) {
        return this.dateCollectionSummary.timeHasEnough(timeId, this.selectedServiceTypes, this.numberOfParticipants);
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    next() {
        if (this.sectionToShow === 'search') {
            this.sectionToShow = 'select';
        } else if (this.sectionToShow === 'select') {
            this.sectionToShow = 'itinerary';
        }
    }

    optionSelected(serviceType: DateSummaryServiceType, participantOrdinal: number, time: Date) {
        return this.itinerary.containsAppointment(participantOrdinal, serviceType.key, time);
    }

    serviceTypeSelected($event) {
        const serviceType = $event.currentTarget.value;
        const isChecked = $event.currentTarget.checked;

        if (isChecked) {
            this.selectedServiceTypes.push(serviceType);
        } else {
            this.selectedServiceTypes = _.filter(this.selectedServiceTypes, (type: string) => type !== serviceType);
        }
        this.checkSelectionServiceTypes();
    }

    private checkSelectionServiceTypes() {
        if (this.selectedServiceTypes.length === 0) {
            this.clearFilters();
            this.exposeFiltersIfAppropriate();
        } else {
            this.exposeFiltersIfAppropriate();
            if (this.selectedSearchMode === 'location') {
                this.fetchSlotsFilteredByLocation();
            } else {
                this.closeFilterByDatePicker();
                this.fetchSlotsFilteredByDate();
            }
        }
    }

    private closeFilterByLocationPicker(): void {
        this.showDateLegend = false;
        if (this.locationFilterDatePicker) {
            this.locationFilterDatePicker.close();
        }
    }

    private closeFilterByDatePicker(): void {
        this.showDateFilterLegend = false;
        if (this.dateFilterDatePicker) {
            this.dateFilterDatePicker.close();
        }
    }

    isSelectedServiceType(key: string) {
        return key && this.selectedServiceTypes.indexOf(key) !== -1;
    }

    serviceTypeSelectedCount() {
        return this.selectedServiceTypes ? this.selectedServiceTypes.length : 0;
    }

    bookForMeSelected($event) {
        this.bookForMe = $event.currentTarget.checked;
        if (!this.bookForMe && !this.bookForGuest) {
            this.clearFilters();
        }
        this.exposeFiltersIfAppropriate();
    }

    bookForGuestSelected($event) {
        this.bookForGuest = $event.currentTarget.checked;
        if (!this.bookForGuest && !this.bookForMe) {
            this.clearFilters();
        }
        this.exposeFiltersIfAppropriate();
    }

    clearFilters() {
        this.dateCollectionSummary = null;
        this.selectedLocation = null;
        this.dateOfEvent = null;
        this.dateFilterDay = null;
        this.selectedDateFilterLocation = null;
    }

    exposeFiltersIfAppropriate() {
        if ((this.bookForMe || this.bookForGuest) && this.serviceTypeSelectedCount() > 0) {
            this.filtersAreVisible = true;
        } else {
            this.filtersAreVisible = false;
        }
    }

    noApptsForThisDay() {
        let noAppts = true;
        let theDay = null;

        if (!this.dateOfEvent && !this.dateFilterDay) {
            return noAppts;
        }

        if (this.dateCollectionSummary !== null) {
            if (this.dateFilterDay) {
                theDay = this.dateFilterDay;
            } else {
                theDay = this.dateOfEvent;
            }

            for (let i = 0; i < this.dateCollectionSummary.dates.length; i++) {
                const date = this.dateCollectionSummary.dates[i].date;
                if (date.getFullYear() === theDay.year && date.getMonth() + 1 === theDay.month && date.getDate() === theDay.day) {
                    noAppts = false;
                    break;
                }
            }
        }

        return noAppts;
    }

    // If currently filtering by date, the selected date is used otherwise the event date from the date popup.
    noApptsForThisMonth() {
        let noAppts = true;
        let theDay = null;

        if (!this.dateOfEvent && !this.dateFilterDay) {
            return noAppts;
        }

        if (this.dateFilterDay) {
            theDay = this.dateFilterDay;
        } else {
            theDay = this.dateOfEvent;
        }

        if (this.dateCollectionSummary !== null) {
            for (let i = 0; i < this.dateCollectionSummary.dates.length; i++) {
                const date = this.dateCollectionSummary.dates[i].date;
                if (date.getFullYear() === theDay.year && date.getMonth() + 1 === theDay.month) {
                    noAppts = false;
                    break;
                }
            }
        }

        return noAppts;
    }

    // If currently filtering by date, the selected date is used otherwise the event date from the date popup.
    showDate(dateCollectionSummaryDate: DateSummary) {
        const date = dateCollectionSummaryDate.date;
        let theDay = null;

        if (!this.dateOfEvent && !this.dateFilterDay) {
            return false;
        }

        if (this.dateFilterDay) {
            theDay = this.dateFilterDay;
        } else {
            theDay = this.dateOfEvent;
        }

        // Do any of the times support the service type(s) selected?
        let doesSupport = false;
        if (this.selectedServiceTypes.indexOf(dateCollectionSummaryDate.programServiceTypeKey) !== -1) {
            doesSupport = true;
        }
        return doesSupport && date.getFullYear() === theDay.year && date.getMonth() + 1 === theDay.month;
    }

    // If currently filtering by date, the selected date is used otherwise the event date from the date popup.
    timeShouldBeShown(dateSummaryTime: DateSummaryTime) {
        let showtime = false;

        if (!dateSummaryTime.serviceTypes && dateSummaryTime.serviceTypes.length === 0) {
            return false;
        }

        // Does an open event exists for any service on this day
        const serviceTypeFound: DateSummaryServiceType = _.find(dateSummaryTime.serviceTypes, (serviceType: DateSummaryServiceType) => {
            return !serviceType.eventIsClosed;
        });
        if (serviceTypeFound) {
            showtime = true;
        }

        return showtime;
    }

    // If currently filtering by date, the selected date is used otherwise the event date from the date popup.
    showTime(dateSummaryTime: DateSummaryTime) {
        let theDay = null;

        if (!this.dateOfEvent && !this.dateFilterDay) {
            return false;
        }

        if (this.dateFilterDay) {
            theDay = this.dateFilterDay;
        } else {
            theDay = this.dateOfEvent;
        }

        // Does the time support any of the service type(s) selected?
        let doesSupport = false;
        const that = this;

        _.each(dateSummaryTime.serviceTypes, (st: DateSummaryServiceType) => {
            if (that.selectedServiceTypes.indexOf(st.key) !== -1) {
                doesSupport = true;
            }
        });

        return doesSupport;
    }

    // Only show next month option is searching by location and a date has been selected.
    showNextMonth() {
        if (this.dateOfEvent && this.selectedSearchMode === 'location') {
            const eventDate = new Date(this.dateOfEvent.year, this.dateOfEvent.month, 1);

            const firstFutureDate = this.dateCollectionSummary.getFirstAvailableFutureDate(eventDate);

            return firstFutureDate !== null;
        } else {
            return false;
        }
    }

    toggleExistingAppointments($event) {
        $event.preventDefault();
        this.showExistingAppointments = !this.showExistingAppointments;
    }

    toggleDateFilterLegend() {
        this.showDateFilterLegend = !this.showDateFilterLegend;
    }

    toggleDateLegend() {
        this.showDateLegend = !this.showDateLegend;
    }

    toggleLocationFilterDatePicker() {
        this.locationFilterDatePicker.toggle();
        this.toggleDateLegend();
        document.getElementsByTagName('ngb-datepicker')[0].setAttribute('class', '');
    }

    toggleDateFilterDatePicker() {
        this.dateFilterDatePicker.toggle();
        this.toggleDateFilterLegend();
        document.getElementsByTagName('ngb-datepicker')[0].setAttribute('class', '');
    }

    nextMonth($event) {
        $event.preventDefault();

        if (this.dateOfEvent) {
            const eventDate = new Date(this.dateOfEvent.year, this.dateOfEvent.month, 1);
            const firstDateInNextMonth = this.dateCollectionSummary.getFirstAvailableFutureDate(eventDate);
            if (firstDateInNextMonth !== null) {
                this.dateOfEvent = new NgbDate(firstDateInNextMonth.getFullYear(), firstDateInNextMonth.getMonth() + 1, firstDateInNextMonth.getDate());
            } else {
                this.dateOfEvent = new NgbDate(eventDate.getFullYear(), eventDate.getMonth() + 1, 1);
            }

            window.document.getElementById('select-days-top').scrollIntoView();
            window.scrollTo(0, 0);
        }
    }

    onSelectDaysScroll() {
        if (this.selectDays.nativeElement.scrollTop > 400) {
            this.toTop.nativeElement.classList.remove('hidden');
        } else {
            this.toTop.nativeElement.classList.add('hidden');
        }
    }

    onWindowScroll() {
        if (this.currentWidth !== BootstrapSize.lg && this.currentWidth !== BootstrapSize.xl && this.toTopMobile) {
            if (window.scrollY > 400) {
                this.toTopMobile.nativeElement.classList.remove('hidden');
            } else {
                this.toTopMobile.nativeElement.classList.add('hidden');
            }
        }
    }

    goToTop() {
        window.document.getElementById('select-days-top').scrollIntoView();
        window.scrollTo(0, 0);
    }

    goToTopMobile() {
        window.scrollTo(0, 0);
    }

    deleteAppt(evt, appt: Appointment) {
        evt.preventDefault();
        this.itinerary.removeAppointment(appt.programServiceType.programServiceTypeKey, appt.participantOrdinal, appt.dateSummaryTimeId);
        this.dateCollectionSummary.removeReservation(appt.programServiceType.programServiceTypeKey, appt.time);
    }

    clearAppts() {
        this.itinerary.removeAppointments();
        if (this.dateCollectionSummary != null) {
            this.dateCollectionSummary.removeReservations();
        }
    }

    addAppointment(evt, dateSummaryServiceType: DateSummaryServiceType, participantIndex) {
        const idx = evt.currentTarget.selectedIndex;

        if (participantIndex > 1 && !dateSummaryServiceType.bookForOthers) {
            alert(this._localizeService.get('notavailableforguests', 'message'));
            evt.currentTarget.selectedIndex = 0;
            return;
        }

        if (idx !== 0) {
            const slotOption = dateSummaryServiceType.slotOptions[evt.currentTarget.selectedIndex - 1];

            // Check if an existing appointment exists for this type of service. If so,
            // save information so it can be removed below once the new slot is added.
            let appointmentServiceTypeKey = null;
            let appointmentParticipantIndex = null;
            let appointmentDateSummaryTimeId = null;
            let appointmentTimeToBeRemoved = null;
            if (this.itinerary.hasServiceType(slotOption.serviceType.programServiceTypeKey, participantIndex)) {
                for (let i = 0; i < this.itinerary.participants.length; i++) {
                    if (this.itinerary.participants[i].ordinal === participantIndex) {
                        for (let a = 0; a < this.itinerary.participants[i].appointments.length; a++) {
                            appointmentTimeToBeRemoved = this.itinerary.participants[i].appointments[a].time;
                            if (this.itinerary.participants[i].appointments[a].programServiceType.programServiceTypeKey === slotOption.serviceType.programServiceTypeKey) {
                                appointmentServiceTypeKey = slotOption.serviceType.programServiceTypeKey;
                                appointmentParticipantIndex = participantIndex;
                                appointmentDateSummaryTimeId = this.itinerary.participants[i].appointments[a].dateSummaryTimeId;
                                break;
                            }
                        }
                    }
                }
            }

            const added = slotOption.addReservation();

            if (added) {
                // Remove existing appointment once we know the add worked.
                if (appointmentServiceTypeKey !== null && appointmentParticipantIndex !== null && appointmentDateSummaryTimeId !== null) {
                    this.itinerary.removeAppointment(appointmentServiceTypeKey, appointmentParticipantIndex, appointmentDateSummaryTimeId);
                    this.dateCollectionSummary.removeReservation(appointmentServiceTypeKey, appointmentTimeToBeRemoved);
                }
                const thisEvent = this.dateCollectionSummary.getEvent(dateSummaryServiceType.eventKey);

                const newAppt = new Appointment();
                newAppt.time = slotOption.time;
                newAppt.programServiceType = slotOption.serviceType;
                newAppt.programServiceType.displayName = dateSummaryServiceType.displayName;
                newAppt.dateSummaryTimeId = dateSummaryServiceType.dateSummaryTimeId;
                newAppt.userKey = this.currentUser.userKey;
                newAppt.participantOrdinal = participantIndex;
                newAppt.eventLocation = this.selectedLocation;
                newAppt.eventKey = thisEvent.key;
                newAppt.fasting = thisEvent.fastingState;
                newAppt.room = thisEvent.room;
                newAppt.programServiceKey = thisEvent.programServiceKey;

                // Check for existing appointments for reschedule
                const existingAppointmentKey = this.checkForExistingAppointment(newAppt);
                if (existingAppointmentKey.length > 0) {
                    newAppt.existingAppointmentKey = existingAppointmentKey;
                    newAppt.alreadyHasAppointmentInFuture = true;
                } else {
                    newAppt.existingAppointmentKey = null;
                    newAppt.alreadyHasAppointmentInFuture = false;
                }
                // If the primary participant, check to see if consent is needed.

                const userConsent: UserServices = _.find(this.userServices, (sc: UserServices) => {
                    return sc.programService.programServiceKey === thisEvent.programServiceKey;
                });
                if (participantIndex === 1 && userConsent) {
                    const consenstsNeeded = new ConsentsNeededByServiceSearchModel(true, [userConsent.programService.programServiceKey]);
                    this._consentService.getConsentsNeededByService(consenstsNeeded).subscribe((results) => {
                        if (results && results.result.length) {
                            newAppt.consentNeeded = true;
                        }
                        this.itinerary.addAppointment(newAppt);
                        // Display the participant first in the itinerary.
                        if (this.itinerary.participants.length > 1) {
                            this.itinerary.participants = _.sortBy(this.itinerary.participants, ['ordinal']);
                        }
                    });
                } else {
                    this.itinerary.addAppointment(newAppt);
                    // Display the participant first in the itinerary.
                    if (this.itinerary.participants.length > 1) {
                        this.itinerary.participants = _.sortBy(this.itinerary.participants, ['ordinal']);
                    }
                }
            } else {
                alert(this._localizeService.get('noavailabletimeslot', 'message'));
                evt.currentTarget.selectedIndex = 0;
                return;
            }
        } else {
            // Remove the appointment from the itinerary.
            const apptDateRemoved: Date = this.itinerary.removeAppointment(dateSummaryServiceType.key, participantIndex, dateSummaryServiceType.dateSummaryTimeId);

            // Remove the reservation from the slot option
            for (let i = 0; i < dateSummaryServiceType.slotOptions.length; i++) {
                const option = dateSummaryServiceType.slotOptions[i];

                if (option.time === apptDateRemoved) {
                    option.removeReservation();
                    break;
                }
            }
        }
    }

    // Check if participant has an existing appointment this type of service and location.
    // If so, mark the appointment accordingly. Result will display a message in the itinerary
    // and be referenced if they proceed and book the appointment.
    private checkForExistingAppointment(appointment: Appointment): string {
        let existingAppointmentKey = '';
        if (this.userAppts.length > 0) {
            for (let existingAppt = 0; existingAppt < this.userAppts.length; existingAppt++) {
                if (appointment.programServiceType.programServiceTypeKey === this.userAppts[existingAppt].slot.programServiceTypeKey && !this.userAppts[existingAppt].isCanceled) {
                    if (appointment.participantOrdinal === 1 && !this.userAppts[existingAppt].onBehalfOf) {
                        existingAppointmentKey = this.userAppts[existingAppt].key;
                        break;
                    } else {
                        if (appointment.participantOrdinal === 2 && this.userAppts[existingAppt].onBehalfOf) {
                            existingAppointmentKey = this.userAppts[existingAppt].key;
                            break;
                        }
                    }
                }
            }
        }
        return existingAppointmentKey;
    }

    clearAppointment(participantIndex: number) {
        for (let i = 0; i < this.itinerary.participants.length; i++) {
            if (this.itinerary.participants[i].ordinal === participantIndex) {
                for (let a = 0; a < this.itinerary.participants[i].appointments.length; a++) {
                    this.itinerary.removeAppointment(this.itinerary.participants[i].appointments[a].programServiceType.programServiceTypeKey, participantIndex, this.itinerary.participants[i].appointments[a].dateSummaryTimeId);
                }
            }
        }
    }
    private getPrimaryUserServiceKeys(submission: ItinerarySubmission): string[] {
        const serviceKeys: string[] = [];
        _.each(submission.appointments, (appt: ItineraryAppointment) => {
            if (appt.onBehalfOfFirstName == null && appt.onBehalfOfLastName == null) {
                serviceKeys.push(appt.programServiceKey);
            }
        });
        return serviceKeys;
    }
    processNoConsent(submission: ItinerarySubmission) {
        if (this.itinerary.forSubmission.hasAppointmentsInFuture()) {
            const modalRef = this._modalService.open(CancelExistingAndAddNewConfirmComponent, { backdrop: 'static' });
            modalRef.result.then((result) => {
                if (result === 'confirmed') {
                    submission.rescheduleExistingEnabled = true;
                    this.continueWithBookingAppointments(submission);
                }
            });
        } else {
            this.continueWithBookingAppointments(submission);
        }
    }

    captureSevereSymptomCapture(submission: ItinerarySubmission) {
        const checkRequest = new SevereSymptomCheckPost();
        checkRequest.serviceKeys = this.getPrimaryUserServiceKeys(submission);
        checkRequest.userKey = this.otherUserKey ? this.otherUserKey : this.currentUser.userKey;
        this._severeSymptomService.checkSevereSymptomResponse(checkRequest).subscribe((response) => {
            // if the user has not responded today, or they have, but answered 'yes' to severesymptoms then require an answer severesymptom;
            if (!response || (response && response.toLowerCase() === 'true')) {
                this.promptForSevereSymptomResponse(false, checkRequest.userKey, submission);
            }
            // otherwise, they've answered 'No' or this is not applicable for this service
            else {
                this.captureUserData(submission);
            }
        });
    }

    private promptForSevereSymptomResponse(displaySevereSymptomWarning: boolean, userKey: string, submission: ItinerarySubmission) {
        this.modalRef = this._modalService.open(SevereSymptomModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        this.modalRef.componentInstance.displaySevereSymptomWarning = displaySevereSymptomWarning;
        this.modalRef.componentInstance.userKey = userKey;
        this.modalRef.result.then((hasSevereSymptoms) => {
            if (hasSevereSymptoms === false) {
                this.captureUserData(submission);
            } else {
                // Do not proceed onto the next step
                return;
            }
        });
    }

    captureUserData(submission: ItinerarySubmission) {
        const serviceKeys = this.getPrimaryUserServiceKeys(submission);
        if (serviceKeys.length) {
            const postBody = new UserInformationRequestVerification(serviceKeys, this.person().userKey);
            this._userService.isUserInformationNeededAll(postBody).subscribe((response) => {
                if (response) {
                    this.promptForDataCapture(submission);
                } else {
                    this.processNoConsent(submission);
                }
            });
        } else {
            this.processNoConsent(submission);
        }
    }

    // Check if any appointments currently exists. If so, display action model and
    // see if the participant wishes to continue, cancelling existing appointments and creating
    // the new appointments or wishes to cancel.
    // When booking the appointment(s) a check must be made to ensure consent has been given
    // for each service. If not, a modal is presented to capture account for each service
    // needed.
    bookAppointments(): void {
        this.isProcessing = true;
        if (!this.itinerary.isValid()) {
            alert(this._localizeService.get('guestnamerequired', 'message'));
            return;
        }

        const submission: ItinerarySubmission = this.itinerary.forSubmission;
        if (submission.appointments.length) {
            const consentNeededServiceKeys = this.getPrimaryUserServiceKeys(submission);
            const consenstsNeeded = new ConsentsNeededByServiceSearchModel(true, consentNeededServiceKeys);
            //get consent needed
            if (consentNeededServiceKeys.length > 0 && !this.otherUserKey) {
                this._consentService.getConsentsNeededByService(consenstsNeeded).subscribe((results) => {
                    if (results && results.result.length > 0) {
                        this.captureConsents(submission, results.result, consentNeededServiceKeys);
                    } else {
                        this.captureSevereSymptomCapture(submission);
                    }
                });
            } else {
                this.captureSevereSymptomCapture(submission);
            }
        } else {
            // This scenario should never really happen...
            alert(this._localizeService.get('noappointmentsforbooking', 'message'));
            this.isProcessing = false;
        }
    }

    promptForDataCapture(submission: ItinerarySubmission) {
        const userDataCaptureModal = this._modalService.open(UserDataCaptureModalComponent, {
            windowClass: 'userdatacapture-modal-window',
            backdrop: 'static',
            keyboard: false
        });
        const that = this;
        userDataCaptureModal.componentInstance.userKey = this.person().userKey;
        userDataCaptureModal.result.then((result) => {
            //
            const response = result as UserDataCaptureResult;
            this.userDataCaptureResult = response;
            if (this.userDataCaptureResult.hasDataCapture) {
                //continue
                this.processNoConsent(submission);
            } else {
                that.isProcessing = false;
                // are going to pop up again?
            }
        });
    }
    // Present the consent modal for each service requiring consent.
    captureConsents(submission: ItinerarySubmission, consentsNeeded: ConsentsNeeded[], consentNeededServiceKeys: string[]) {
        this.promptNextConsents(consentsNeeded, submission, consentNeededServiceKeys);
    }

    private promptNextConsents(consentsNeeded: ConsentsNeeded[], submission: ItinerarySubmission, consentNeededServiceKeys: string[]): void {
        if (consentsNeeded.length <= 0) {
            this.captureSevereSymptomCapture(submission);
        }

        const outstandingConsentsNeededServiceKeys = consentNeededServiceKeys;
        const nextConsentNeededServiceKey = outstandingConsentsNeededServiceKeys.pop();

        const consentsNeededForService: ConsentsNeeded[] = _.filter(consentsNeeded, (consentNeeded: ConsentsNeeded) => {
            return consentNeeded.programServiceKey === nextConsentNeededServiceKey;
        });

        if (consentsNeededForService.length > 0) {
            this.getConsent(consentsNeededForService).then((result) => {
                if (result) {
                    // Clear the consent needed flag from appointment for primary participant
                    for (const element of this.itinerary.participants) {
                        if (element.ordinal === 1) {
                            for (const appt of element.appointments) {
                                if (appt.programServiceKey === nextConsentNeededServiceKey) {
                                    appt.consentNeeded = false;
                                }
                            }
                        }
                    }

                    // Determine next step
                    //  - Prompt for next consent, if more
                    //  - continue with severe symptom capture
                    if (outstandingConsentsNeededServiceKeys.length <= 0) {
                        this.captureSevereSymptomCapture(submission);
                    } else {
                        this.promptNextConsents(consentsNeeded, submission, outstandingConsentsNeededServiceKeys);
                    }
                }
            });
        } else {
            this.promptNextConsents(consentsNeeded, submission, outstandingConsentsNeededServiceKeys);
        }
    }

    private getConsent(outstandingConsent: ConsentsNeeded[]): Promise<boolean> {
        return new Promise((resolve) => {
            this.modalRef = this._modalService.open(ConsentModalComponent, {
                backdrop: 'static',
                size: 'lg'
            });
            this.modalRef.componentInstance.consentsNeeded = outstandingConsent;
            this.modalRef.componentInstance.personKey = this.currentUser.personKey;
            this.modalRef.componentInstance.userKey = this.currentUser.userKey;
            this.modalRef.componentInstance.participantKey = this.currentUser.participantKey;
            this.modalRef.result.then(
                (result) => {
                    if (result.hasConsent) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                (error) => {
                    resolve(false);
                }
            );
        });
    }

    private continueWithBookingAppointments(submission: ItinerarySubmission): void {
        this.showLoader(true);
        this.subscriptions.push(
            this._schedulerService.submitItinerary(submission).subscribe(
                (resp) => {
                    this.showLoader(false);

                    if (resp.status === 200) {
                        const that = this;
                        let hasConflict = false;
                        let consentNeeded = false;
                        let overlappingServiceTypes = false;
                        let notEligible = false;
                        _.each(resp.body, (badAppt: UnsuccessfulAppointment) => {
                            if (!notEligible && badAppt.invalidReason === Globals.AppointmentInvalidReasons.NotEligble) {
                                notEligible = true;
                            } else if (!hasConflict && badAppt.invalidReason === Globals.AppointmentInvalidReasons.Conflict) {
                                hasConflict = true;
                            } else if (!consentNeeded && badAppt.invalidReason === Globals.AppointmentInvalidReasons.ConsentNeeded) {
                                consentNeeded = true;
                            } else if (!overlappingServiceTypes && badAppt.invalidReason === Globals.AppointmentInvalidReasons.OverlappingServiceTypes) {
                                overlappingServiceTypes = true;
                            } else {
                                that.dateCollectionSummary.removeAvailableSlot(badAppt.eventKey, badAppt.slotTime);
                                that.itinerary.markAppointmentAsInvalid(badAppt.eventKey, badAppt.slotTime, badAppt.onBehalfOfLastName !== null && badAppt.onBehalfOfLastName !== undefined, badAppt.invalidReason);
                            }
                        });
                        if (hasConflict) {
                            alert(this._localizeService.get('conflictingappointment', 'message'));
                        } else if (consentNeeded) {
                            alert(this._localizeService.get('missingconsent', 'message'));
                        } else if (overlappingServiceTypes) {
                            alert(this._localizeService.get('onlyoneapptperservice', 'message'));
                        } else if (notEligible) {
                            alert(this._localizeService.get('noteligibletobookappointments', 'message'));
                        } else {
                            alert(this._localizeService.get('oneormoreslotsnolongeravailable', 'message'));
                        }
                        this.isProcessing = false;
                    } else if (resp.status === 201) {
                        this._personService.fetchPerson();
                        alert(this._localizeService.get('itinerarysuccessfullybooked', 'message'));
                        if (!this.otherPerson) {
                            this._router.navigate(['/appointments']);
                        } else {
                            this._router.navigate(['/participant/' + this.otherPerson.userKey]);
                        }
                    }
                },
                (error) => {
                    this.showLoader(false);
                    if (error.status && error.body) {
                        if (error.status === 400 && error.body === 'ERROR_USERINFORMATION_REQUIRED') {
                            this.promptForDataCapture(submission);
                            return;
                        }
                    }
                    alert(this._localizeService.get('unknownerrortryagain', 'message'));
                    this.isProcessing = false;
                }
            )
        );
    }

    private showLoader(doShow: boolean, msg: string = null) {
        window.setTimeout(() => {
            if (doShow && !this.loadingVisible) {
                this.loadingVisible = true;
                this.modalRef = this._modalService.open(LoadingComponent, {
                    backdrop: 'static',
                    windowClass: 'loadingModal'
                });

                if (msg) {
                    this.modalRef.componentInstance.msg = msg;
                }
            } else {
                this.modalRef.close();
                this.loadingVisible = false;
            }
        }, 100);
    }
    private loadDataForUser(person: Person) {
        this.itinerary = new Itinerary(person.userKey);
        this.setDefaultZipCodeRadiusBy(person);
        this.onReady();

        this.subscriptions.push(
            this._schedulerService.getAvailableLocations(person.userKey).subscribe(
                (locations) => {
                    this.locationsAvailable = locations;
                    this.filteredLocations = locations;
                    this.selectedZipCode = person.zip;
                    this.performLocationSort();
                    this.calculateLocationDistances();
                    this.onReady();
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._schedulerService.getEventDatesAndLocations(person.userKey).subscribe(
                (collection) => {
                    this.eventDateAndLocationCollection = new EventDateAndLocationCollection(collection);
                    this.onReady();
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._schedulerService.getAppointments(person.userKey).subscribe(
                (appts) => {
                    if (appts.length) {
                        this.userAppts = appts.filter((i) => !i.isCanceled && moment(i.time).isAfter(moment()));
                        this.userAppts = _.sortBy(this.userAppts, function (o) {
                            return moment(o.time);
                        }).reverse();
                        this.showViewAppointmentsLink = true;
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._schedulerService.getAvailableServiceTypes(person.userKey).subscribe(
                (types) => {
                    this.serviceTypesAvailable = types;

                    if (this.serviceTypesAvailable.length === 1) {
                        this.selectedServiceTypes.push(this.serviceTypesAvailable[0].key);
                    }

                    types.forEach((type) => {
                        if (type.maySupportBookForOthers) {
                            this.enableBookForOthers = true;
                        }
                        const serviceTypeIcon = Globals.ServiceTypeImages.find((s) => s.pgtkey === type.key);
                        if (serviceTypeIcon && serviceTypeIcon !== null) {
                            type.serviceTypeIcon = serviceTypeIcon.fontawesomeclass;
                        } else {
                            type.serviceTypeIcon = 'fa-heart';
                        }
                    });

                    if (this.enableBookForOthers) {
                        this.numberOfParticipants = 2;
                    }

                    this.onReady();
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.loadConsents();
    }

    private loadConsents() {
        if (!this.otherPerson) {
            if (this.currentUser.isCustomerAdmin && this.currentUser.isParticipant) {
                this.userServices = this.currentUser.UserServices;
            } else {
                this.userServices = this.currentUser.UserServices.filter((consent) => new Date(consent.programService.portalPublishDate) <= new Date(Date.now()));
            }
        }
    }

    private onReady() {
        if (this.currentUser && this.localizeReady && this.locationsAvailable && this.serviceTypesAvailable) {
            if (this.locationsAvailable.length === 0 || this.serviceTypesAvailable.length === 0) {
                this.noEvents = true;
            } else {
                this.readyForRender = true;
            }

            this.showLoader(false);
        }

        this.showLoader(false);
    }

    private onError() {
        this.hasError = true;
        this.showLoader(false);
    }

    //
    // Component is used by participants to schedule event appointments. Filters are provided
    // that allow the list of locations to be limited. A call to get locations is made once
    // and filters used to qualify the list of locations is handled here. The participant can
    // schedule their event and, when supported, schedule a quest appointment. An array is
    // used to hold appointment information.
    //
    ngOnInit() {
        this.showLoader(true);
        const that = this;

        window.onscroll = function () {
            that.onWindowScroll();
        };

        this.subscriptions.push(
            this._localizeService.isReady.subscribe(
                (isReady) => {
                    if (isReady) {
                        this.localizeReady = true;
                        this.onReady();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._sizeCheckerService.currentSize.subscribe((size) => {
                this.currentWidth = size;

                if (size === BootstrapSize.md || size === BootstrapSize.sm || size === BootstrapSize.xs) {
                } else {
                }
            })
        );

        this.subscriptions.push(
            this._sizeCheckerService.currentBodyHeight.subscribe((height) => {
                this.currentBodyHeight = height;
            })
        );

        this.subscriptions.push(
            this.route.queryParams.subscribe((params) => {
                this.otherUserKey = params.user;

                if (!this.currentUser) {
                    this.subscriptions.push(
                        this._personService.currentPerson.subscribe(
                            (person) => {
                                if (person) {
                                    this.currentUser = person;

                                    if (this.otherUserKey) {
                                        this.subscriptions.push(
                                            this._personService.getOtherPerson(this.otherUserKey, false).subscribe(
                                                (other) => {
                                                    if (other) {
                                                        this.otherPerson = other;
                                                        this.loadDataForUser(this.otherPerson);
                                                    }
                                                },
                                                (error) => {
                                                    this.onError();
                                                }
                                            )
                                        );
                                    } else {
                                        this.loadDataForUser(this.currentUser);
                                    }
                                }
                            },
                            (error) => {
                                this.onError();
                            }
                        )
                    );
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }

    public getProgramLabel(token: string, programServiceTypeKey: string = null, programServiceKey: string = null) {
        if (programServiceTypeKey && programServiceTypeKey != '') {
            const options = `{"programServiceKey":"${programServiceKey}","programServiceTypeKey":"${programServiceTypeKey}"}`;
            return this._localizeService.get(Utils.getServiceToken(token), 'program', null, null, null, null, options);
        } else {
            return this._localizeService.get(Utils.getServiceToken(token), 'program');
        }
    }

    private setDefaultZipCodeRadiusBy(person: Person) {
        if (person && person.company && person.company.defaultZipCodeRadius !== null && person.company.defaultZipCodeRadius !== undefined) {
            this.defaultRadius = person.company.defaultZipCodeRadius;
        }
    }
}

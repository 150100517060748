import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from "lodash";
import moment from 'moment';
import { Subscription } from 'rxjs';

import { AppointmentViewerComponent } from '../../../coaching/appointments/appointment-viewer/appointment-viewer.component';
import { CoachingAppointmentService } from '../../../coaching/appointments/appointments.service';
import { LabcorpAppointSchedulerComponent } from '../../../services/appointments/labcorp.appointment.component';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { ColumnSetting } from '../../../shared/wcs-table/column-setting';
import { WcsTableComponent } from '../../../shared/wcs-table/wcs-table.component';
import { ApiAppointment } from '../../model/api-appointment';
import { SchedulerService } from '../../scheduler.service';
import { CancelConfirmComponent } from '../cancel-confirm/cancel-confirm.component';
import { ResendConfirmationEmailComponent } from '../resend-confirmation-email/resend-confirmation-email.component';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit, OnDestroy {
  @ViewChild(WcsTableComponent) tableComponent: WcsTableComponent;
  @Input() otherUser: Person;

  hasError = false;
  readyForRender = false;
  localizeReady = false;
  isLoadingAppointments = false;
  screeningAppointmentsRetrieved = false;
  pscAppointmentsRetrieved = false;
  coachingAppointmentsRetrieved = false;
  subscriptions: Subscription[] = [];
  currentUser: Person;
  activeAppointments: ApiAppointment[] = [];
  inactiveAppointments: ApiAppointment[] = [];
  modalRef: NgbModalRef;
  isOtherPersonLoaded = false
  otherPersonKey: any = null;
  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'timeText',
      headerLocalizeProp: 'date',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'confirmationNumber',
      headerLocalizeProp: 'confirmationNumber',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'serviceDisplayName',
      headerLocalizeProp: 'servicetype',
      format: 'programTypeToken',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'fastingToken',
      headerLocalizeProp: 'fasting',
      format: 'localizeToken',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'locationText',
      headerLocalizeProp: 'location',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'appointmentFor',
      headerLocalizeProp: 'appointmentfor',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'isCanceled',
      headerLocalizeProp: 'canceled',
      showOnMobile: true,
      format: 'boolean',
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'modify',
        onClick: this.modifyAppointment
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.isActiveAppointment,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'cancel',
        onClick: this.cancelAppointment
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showCancelAppointment,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'viewconfirmation',
        onClick: this.viewConfirmation
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showViewConfirmation,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'Resend',
        onClick: this.resendConfirmationEmail
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showResendConfirmationEmail,
          val: true
        }]
    }
  ];

  constructor(private _localizeService: LocalizeService, private _schedulerService: SchedulerService,
    private _personService: PersonService, private _modalService: NgbModal, private _router: Router, private _apptService: CoachingAppointmentService,
    private _route: ActivatedRoute
  ) {
    _route.params.subscribe(params => {
      this.otherPersonKey = params.userKey;
    });

  }

  cancelAppointment(appt: ApiAppointment) {
    if (!appt.isCanceled) {
      //perform action based on appointment type
      let modalRef: NgbModalRef;
      switch (appt.appointmentType) {
        case "Coaching":
          // not implemented
          break;
        case "PatientServiceCenter":
          modalRef = this._modalService.open(LabcorpAppointSchedulerComponent, {
            backdrop: "static"
          });
          modalRef.componentInstance.apiAppointment = appt;
          modalRef.componentInstance.currentUser = this.otherUser ? this.otherUser : this.currentUser;
          modalRef.result.then((result) => {
            if (result) {
              this._personService.fetchPerson();  //refresh person
              this.getAppointments();
              this.tableComponent.detectChanges();
            }
          });
          break;
        default:
          modalRef = this._modalService.open(CancelConfirmComponent, { backdrop: 'static' });
          modalRef.result.then((result) => {
            if (result === 'submit') {
              this._personService.fetchPerson();  //refresh person
              this.getAppointments();
              this.tableComponent.detectChanges();
            }
          });

          modalRef.componentInstance.currentUser = this.currentUser;
          modalRef.componentInstance.appointmentKey = appt.key;
          modalRef.componentInstance.appointmentUserKey = appt.owner.key;
          break;
      }
    }
  }

  showCancelAppointment(appt: ApiAppointment) {
    if (appt.appointmentType === "Coaching") {
      return false;
    }

    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();

    return appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  viewConfirmation(appt: ApiAppointment) {
    window.open(appt.confirmationUrl, '_blank');
  }

  showViewConfirmation(appt: ApiAppointment) {
    if (appt.appointmentType !== "PatientServiceCenter") {
      return false;
    }

    return (appt.confirmationUrl != null && appt.confirmationUrl.length > 0);
  }

  modifyAppointment(appt: ApiAppointment) {
    if (!appt.isCanceled) {
      let modalRef: NgbModalRef;
      //perform action based on appointment type
      switch (appt.appointmentType) {
        case "Coaching":
          this.viewCoachingAppt(appt);
          break;
        case "PatientServiceCenter":
          modalRef = this._modalService.open(LabcorpAppointSchedulerComponent, {
            backdrop: "static"
          });
          modalRef.componentInstance.apiAppointment = appt;
          modalRef.componentInstance.currentUser = this.otherUser ? this.otherUser : this.currentUser;
          modalRef.result.then((result) => {
            if (result) {
              this._personService.fetchPerson();  //refresh person
              this.getAppointments();
              this.tableComponent.detectChanges();
            }
          });
          break;
        default:
          const userKey = this.getPersonOfInterestKey();
          if (this.currentUser.userKey === userKey) {
            this._router.navigate(['/scheduler']);
          }
          else {
            this._router.navigate(['/scheduler'], { queryParams: { user: userKey } });
          }
          break;
      }
    }
  }

  viewCoachingAppt(appt: ApiAppointment) {
    const that = this;

    this.modalRef = this._modalService.open(AppointmentViewerComponent, {
      backdrop: 'static',
      windowClass: 'fs-modal',
      beforeDismiss: function () {
        that.tableComponent.detectChanges();
        return true;
      }
    });

    this.modalRef.componentInstance.participant = this.getUserOfInterest();
    this.modalRef.componentInstance.confirmationNumber = appt.itineraryKey;
  }


  isActiveAppointment(appt: ApiAppointment) {
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();

    return appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  checkReady() {
    if (this.localizeReady && !this.isLoadingAppointments && this.isOtherPersonLoaded === true) {
      this.readyForRender = true;
    }
  }

  checkAppointmentsReady() {
    if (this.screeningAppointmentsRetrieved && this.pscAppointmentsRetrieved && this.coachingAppointmentsRetrieved) {
      this.isLoadingAppointments = false;
    }
  }

  getAppointments() {
    if (this.isLoadingAppointments) {
      return;
    }
    this.isLoadingAppointments = true;

    const that = this;
    this.activeAppointments = [];
    this.inactiveAppointments = [];
    this.screeningAppointmentsRetrieved = false;
    this.pscAppointmentsRetrieved = false;
    this.coachingAppointmentsRetrieved = false;

    this.subscriptions.push(this._schedulerService.getAppointments(this.getPersonOfInterestKey()).subscribe(appts => {
      that.screeningAppointmentsRetrieved = true;

      that.activeAppointments = that.activeAppointments.concat(appts.filter(appt => this.isActiveAppointment(appt)));
      that.inactiveAppointments = that.inactiveAppointments.concat(appts.filter(appt => !this.isActiveAppointment(appt)));
      that.checkAppointmentsReady();
      that.checkReady();

      if (that.tableComponent) {
        that.tableComponent.detectChanges();
      }
    }, error => {
      this.screeningAppointmentsRetrieved = true;
      that.checkAppointmentsReady();
      that.checkReady();
    }));

    this.subscriptions.push(this._schedulerService.getPscAppointments(this.getPersonOfInterestKey()).subscribe(appts => {
      that.pscAppointmentsRetrieved = true;

      if (appts.length) {
        that.activeAppointments = that.activeAppointments.concat(appts.filter(appt => this.isActiveAppointment(appt)));
        that.inactiveAppointments = that.inactiveAppointments.concat(appts.filter(appt => !this.isActiveAppointment(appt)));

        this.activeAppointments = _.sortBy(this.activeAppointments, function (o) {
          return moment(o.time);
        });

        this.inactiveAppointments = _.sortBy(this.inactiveAppointments, function (o) {
          return moment(o.time);
        }).reverse();
      }
      that.checkAppointmentsReady();
      that.checkReady();

      if (that.tableComponent) {
        that.tableComponent.detectChanges();
      }
    }, error => {
      this.pscAppointmentsRetrieved = true;
      that.checkAppointmentsReady();
      that.checkReady();
    }));

    this.subscriptions.push(this._apptService.getUpcomingAppts(this.getPersonOfInterestKey(), true, this.currentUser).subscribe(appts => {
      that.coachingAppointmentsRetrieved = true;

      if (appts.length) {
        appts.forEach(function (appt) { appt.appointmentType = "Coaching" });
        that.activeAppointments = that.activeAppointments.concat(appts.filter(appt => this.isActiveAppointment(appt)));
        that.inactiveAppointments = that.inactiveAppointments.concat(appts.filter(appt => !this.isActiveAppointment(appt)));

        this.activeAppointments = _.sortBy(this.activeAppointments, function (o) {
          return moment(o.time);
        });
      }

      that.checkAppointmentsReady();
      that.checkReady();

      if (that.tableComponent) {
        that.tableComponent.detectChanges();
      }
    }, error => {
      this.coachingAppointmentsRetrieved = true;
      that.checkAppointmentsReady();
      that.checkReady();
    }));
  }

  getUserOfInterest() {
    return this.otherUser ? this.otherUser : this.currentUser;
  }
  getPersonOfInterestKey() {
    return this.otherPersonKey ? this.otherPersonKey : this.currentUser.userKey;
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentUser = currentPerson;

        this.getAppointments();
      }
    }));

    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
        this.checkReady();
      }
    }));
    this.loadOtherPerson();
  }
  loadOtherPerson() {
    if (!this.otherPersonKey) {
      this.isOtherPersonLoaded = true;
      this.checkReady()
    } else {
      this._personService.getOtherPerson(this.otherPersonKey, false).subscribe(other => {
        this.otherUser = other;
        this.isOtherPersonLoaded = true;
        this.checkReady();
      })
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  resendConfirmationEmail(appt: ApiAppointment) {
    const modalRef = this._modalService.open(ResendConfirmationEmailComponent, { backdrop: 'static' });
    modalRef.result.then((result) => {
      if (result === 'submit') {
        this.getAppointments();
        this.tableComponent.detectChanges();
      }
    });

    modalRef.componentInstance.currentUser = this.currentUser;
    modalRef.componentInstance.appointmentKey = appt.key;
    modalRef.componentInstance.appointmentUserKey = appt.owner.key;
  }

  showResendConfirmationEmail(appt: ApiAppointment) {
    if (appt.appointmentType === "PatientServiceCenter") {
      return false;
    }
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();
    const validUser = (this.currentUser.isWcsAdmin || this.currentUser.isSupportUser
      || this.currentUser.isPartnerAdmin || this.currentUser.isCustomerAdmin);

    if (appt.appointmentType === "PatientServiceCenter") {
      return false;
    }
    return validUser && appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }
}

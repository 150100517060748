import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../user/user';
import { ConsentAndAcknowledgement, ConsentByKeyForPersonRequest, ConsentDefinitionAcknowledgementModel, ConsentDefinitionAcknowledgementsPostModel, ConsentsNeededByServiceSearchModel, ConsentsNeededResult } from './consent';

@Injectable()
export class ConsentService {
    private currentUserSource = new BehaviorSubject<User | null>(null);
    currentUser = this.currentUserSource.asObservable();

    constructor(private _http: HttpClient) { }

    setConsentCaptures(consentCaps: ConsentDefinitionAcknowledgementsPostModel): Observable<boolean> {
        return this._http.post<boolean>(environment.baseCoreApiEndpoint + '/consent-definition-acknowledgement', consentCaps).pipe(
            catchError(this.handleError)
        );
    }

    setConsentCapture(consentCap: ConsentDefinitionAcknowledgementModel): Observable<boolean> {
        return this._http.put<boolean>(environment.baseCoreApiEndpoint + '/consent-definition-acknowledgement', consentCap).pipe(
            catchError(this.handleError)
        );
    }

    getConsentsNeededByService(consentsNeededByServiceSearchModel: ConsentsNeededByServiceSearchModel): Observable<ConsentsNeededResult> {
        return this._http.post<ConsentsNeededResult>(environment.baseCoreApiEndpoint + '/consent-definition/needed-by-service', consentsNeededByServiceSearchModel).pipe(
            catchError(this.handleError)
        );
    }

    getByKeyWithAcknowledgement(consentSearchModel: ConsentByKeyForPersonRequest): Observable<ConsentAndAcknowledgement> {
        return this._http.post<ConsentAndAcknowledgement>(environment.baseCoreApiEndpoint + '/consent-definition/consent/for-person', consentSearchModel).pipe(
            catchError(this.handleError)
        );
    }

    getOffsiteConsents(): Observable<ConsentsNeededResult> {
        return this._http.get<ConsentsNeededResult>(environment.baseCoreApiEndpoint + '/consent-definition/offsite-consents').pipe(
            catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse): Observable<never> {
        let errorMessage = '';
        if (err.error instanceof Error) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(() => new Error(errorMessage));
    }
}

import { Component, EventEmitter, Input, OnDestroy,OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, SubscriptionLike as ISubscription } from 'rxjs';

import { PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../../person-widgets/person.widget.models';
import { AddressAutoCompleteComponent } from '../../shared/addressautocomplete/addressautocomplete.component';
import { AddressAutoCompleteSettings } from '../../shared/addressautocomplete/addressautocomplete-settings';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { Gender } from '../../shared/gender/gender';
import { Globals } from '../../shared/globals';
import { InsuranceVerification, PayorContract, PayorContractSearch, PersonProgramBilling, UserInsuranceAddress, UserInsuranceAddressValidate, ValidateInsuranceAddress } from '../../shared/insurance/insurance';
import { InsuranceService } from '../../shared/insurance/insurance.service';
import { InsuranceAddress } from '../../shared/insurance/insurance-address';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Ethnicity } from '../../shared/metadata/ethnicity';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Race } from '../../shared/metadata/race';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { IUsAutoCompleteSuggestion } from '../../shared/smartystreets/smartystreets.models';
import { State } from '../../shared/state/state';
import { UserService } from '../../shared/user/user.service';

@Component({
    selector: 'app-person-insurance-widget',
    templateUrl: './person-insurance-widget.component.html',
    styleUrls: ['./person-insurance-widget.component.css']
})
export class PersonInsuranceWidgetComponent implements OnInit, OnDestroy {
    @Input() personWidget: PersonWidgetCollectionWidget;
    @Input() currentPerson: Person;
    @Input() otherPerson?: Person;
    @Input() programServiceKey: string;
    @Input() programServiceTypeKey: string;
    @Input() personKey: string;
    @Output() onWidgetCompleted = new EventEmitter<any>();
    @Output() onUpdateAttempted = new EventEmitter<any>();

    isOrgProvidedInsurance = false;
    insuranceAddress: InsuranceAddress = new InsuranceAddress();
    races: Race[];
    ethnicities: Ethnicity[];
    isPersonRender = false;
    coverageSelectedStates: State[];
    states: State[];
    genders: Gender[];
    insuranceForm: FormGroup;
    subscriptions: ISubscription[] = [];
    coverageChoices: PayorContract[] = [];
    coverageStateChoices: PayorContract[] = [];
    isRenderReady = false;
    isLoading = false;
    noDigitsRegex = /^-?\d+$/;
    errorList: any;
    errorMsg = '';
    public hasError = false;
    showProgress = false;
    hasMultipleCoverageOptions = false;
    primaryCoverageYes = false;
    primaryCoverageNo = false;
    pastDirectBillSelected = false;
    showPrimary = true;
    modalRef: NgbModalRef;
    isMedicareSelected = false;
    hasInvalidPrimaryCoverage = false;

    // Verification Checks for Insurance/Phoenix
    @ViewChild(AddressAutoCompleteComponent) userInsuranceAddressAutocompleteQuery: AddressAutoCompleteComponent;
    insuranceVerificationCompleted = false;
    addressVerificationCompleted = false;
    addressVerificationRequired = false;
    userInsuranceAddressAutocompleteSettings: AddressAutoCompleteSettings;
    addressInsuranceForm: FormGroup;
    hasLabcorpPayerCodeSelected = false;
    //

    validatingMedicareSubject = new Subject();
    personProgramBilling: PersonProgramBilling;
    skipValidation = false;

    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentPerson;
    }
    logInPerson(): Person {
        return this.currentPerson ? this.currentPerson : this.otherPerson;
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _metaDataService: MetadataService,
        private _userService: UserService,
        private _localizationService: LocalizeService,
        private _personService: PersonService,
        private _insuranceService: InsuranceService,
        private _modalService: NgbModal
    ) {
        _metaDataService.getStates();
    }

    ngOnInit(): void {
        const that = this;
        // Auto-complete feature
        this.userInsuranceAddressAutocompleteSettings = new AddressAutoCompleteSettings();
        this.userInsuranceAddressAutocompleteSettings.formControlId = 'userInsuranceAddressAutocompleteQuery';
        this.userInsuranceAddressAutocompleteSettings.inputFormatter = (v) => {
            return this.userInsuranceAddressAutoCompleteInputFormatter(v);
        };
        this.userInsuranceAddressAutocompleteSettings.resultFormatter = (s) => {
            return this.userInsuranceAddressAutoCompleteResultFormatter(s);
        };
        this.userInsuranceAddressAutocompleteSettings.selectItem = (e) => {
            this.userInsuranceAddressAutoCompleteSelect(e);
        };
        this.userInsuranceAddressAutocompleteSettings.typeaheadMinLength = 4;
        this.userInsuranceAddressAutocompleteSettings.placeHolderText = this._localizationService.get('pscaddressplaceholder', 'label');
        this.userInsuranceAddressAutocompleteSettings.autoComplete = 'nothankyou';
        this.userInsuranceAddressAutocompleteSettings.classList = 'form-control ng-pristine ng-invalid ng-touched';
        this.userInsuranceAddressAutocompleteSettings.requiredField = true;
        //

        this.subscriptions.push(
            this._metaDataService.stateList.subscribe(
                (data) => {
                    this.states = that.coverageSelectedStates = data;
                },
                (err) => {
                    this.onError();
                }
            )
        );
        this.subscriptions.push(
            this._metaDataService.getGenders(this._localizationService.locale).subscribe((d) => {
                if (!d) {
                    return;
                }
                if (d.length == 0) {
                    this.onError();
                }
                this.genders = d;
            })
        );

        this.checkPaymentOptions();
        const search = new PayorContractSearch();
        search.companyKey = this.person().company.key;
        search.programServiceKey = this.programServiceKey;
        search.programServiceTypeKey = this.currentProgramServiceTypeKey;
        this.subscriptions.push(
            this._insuranceService.getPayors(search).subscribe(
                (data) => {
                    that.coverageChoices = that.coverageStateChoices = data;
                    this.loadInsurance();
                },
                (err) => {
                    this.onError();
                }
            )
        );
    }

    private loadInsurance() {
        const that = this;
        this._insuranceService.getInsuranceByProgram(this.personKey, this.programServiceKey).subscribe(
            (programBillingResult) => {
                if (programBillingResult == null) {
                    this._insuranceService.getInsuranceByPerson(this.personKey, this.programServiceKey).subscribe(
                        (personInsuranceResult) => {
                            if (personInsuranceResult != null) {
                                //Filter by CoverageChoices
                                const insuranceRecord = that.getInsuranceRecordFromResult(personInsuranceResult);
                                if (insuranceRecord) {
                                    if (insuranceRecord.participantKey !== "") {
                                        this.isOrgProvidedInsurance = true;
                                    }
                                    that.personProgramBilling = insuranceRecord;
                                    that.createForm(that.personProgramBilling, insuranceRecord.isDirectBill);
                                    that.filterStateChoicesByCoverageSelected(insuranceRecord.organizationKey);
                                }
                                else {
                                    that.personProgramBilling = null;
                                    that.createForm(that.personProgramBilling);
                                }
                            }
                            else {
                                that.personProgramBilling = null;
                                that.createForm(that.personProgramBilling);
                            }

                        },
                        (err) => {
                            this.onError();
                        }
                    );
                }
                else {
                    if (that.hasValidInsuranceRecord(programBillingResult)) {
                        if (programBillingResult.participantKey !== "") {
                            this.isOrgProvidedInsurance = true;
                        }
                        that.personProgramBilling = programBillingResult;
                        that.personProgramBilling.payorContractKey = this.coverageChoices.find(x => x.organizationKey === programBillingResult.organizationKey).key;
                        that.createForm(that.personProgramBilling, programBillingResult.isDirectBill);
                        that.filterStateChoicesByCoverageSelected(programBillingResult.organizationKey);
                    } else { //possibly a direct bill
                        that.personProgramBilling = programBillingResult;
                        that.createForm(that.personProgramBilling, programBillingResult.isDirectBill);
                    }
                }
            },
            (err) => {
                this.onError();
            }
        );
    }

    public hasValidInsuranceRecord(insuranceRecord: PersonProgramBilling): boolean {
        return this.coverageChoices.some(x => x.organizationKey === insuranceRecord.organizationKey);
    }

    public filterStateChoicesByCoverageSelected(orgKey: string): void {
        this.coverageSelectedStates = orgKey != '' ? this.states.filter(state => this.coverageChoices.find(x => x.organizationKey === orgKey).stateKeys.includes(state.key)) : this.states;
        this.setValidation('state');
    }
    public getInsuranceRecordFromResult(insuranceRecords: PersonProgramBilling[]): PersonProgramBilling {
        const validRecords = insuranceRecords.filter(record => this.coverageChoices.some(x => x.organizationKey === record.organizationKey));
        if (validRecords.length > 0) {
            let result = validRecords.find(record => record.participantKey != null);
            if (!result) {
                result = validRecords[0];
            }
            if (!this.isValidGuid(result.payorContractKey)) result.payorContractKey = this.coverageChoices.find(x => x.organizationKey === result.organizationKey).key;
            return result;
        }
        return null;
    }
    private isValid(): boolean {
        let isValid = false;

        if (!this.primaryCoverageYes && this.primaryCoverageNo) {
            isValid = true;
        } else {
            // Check InsuranceForm Validity
            if (this.insuranceFormValid) isValid = true;
            // If address verification is required, check AddressInsuranceForm Validity
            if (isValid && this.addressVerificationRequired) {
                if (!this.addressInsuranceFormValid) isValid = false;
            }
        }

        return isValid;
    }

    private isValidGuid(guid: string): boolean {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
    }
    get insuranceFormValid() {
        return this.insuranceForm.valid;
    }
    get addressInsuranceFormValid() {
        return this.addressInsuranceForm.valid;
    }
    get currentProgramServiceTypeKey() {
        return this.person().UserServices.find((r) => r.programService.programServiceKey.toLowerCase() == this.programServiceKey).programService.programServiceTypeKey;
    }
    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }
    get genderKey() {
        if (this.person().gender && this.genders && this.genders.length > 0) {
            const genderValue = this.person().gender.substring(0, 1).toLowerCase();
            const gender = this.genders.find((g) => g.labcorpValue.toLowerCase() == genderValue.toLowerCase());
            return gender ? gender.key : null;
        }
        return null;
    }
    get gender() {
        if (this.person().gender && this.genders && this.genders.length > 0) {
            const genderValue = this.person().gender.substring(0, 1).toLowerCase();
            const gender = this.genders.find((g) => g.labcorpValue.toLowerCase() == genderValue.toLowerCase());
            return gender ? gender.labcorpValue : null;
        }
        return null;
    }
    get stateKey() {
        if (this.person().state && this.states) {
            const s = this.states.find((r) => r.abbreviation.toLowerCase() === this.person().state.toLowerCase());
            if (s) {
                return s.key;
            }
        }
        return '';
    }

    private createForm(currentInsurance?: PersonProgramBilling, isDirectBill = false): void {
        this.insuranceForm = this.formBuilder.group({
            firstName: [currentInsurance && currentInsurance.firstName ? currentInsurance.firstName : this.person().firstName, Validators.required],
            lastName: [currentInsurance && currentInsurance.lastName ? currentInsurance.lastName : this.person().lastName, Validators.required],
            dateOfBirth: [this.person().dateOfBirth, this.dateOfBirthValid()],
            state: [currentInsurance && currentInsurance.stateKey ? currentInsurance.stateKey : '', Validators.required],
            labcorpPayerCode: [currentInsurance && currentInsurance.organizationKey && currentInsurance.payorContractKey ? currentInsurance.payorContractKey : '', Validators.required],
            subscriberId: [currentInsurance && currentInsurance.subscriberId && currentInsurance.payorContractKey ? currentInsurance.subscriberId : '', Validators.required],
            groupNumber: [currentInsurance && currentInsurance.groupNumber && currentInsurance.payorContractKey ? currentInsurance.groupNumber : '', Validators.pattern(this.noDigitsRegex)],
            medicareNumber: [currentInsurance && currentInsurance.medicareNumber ? currentInsurance.medicareNumber : '', this.medicareNumberValid()]
        });

        this.addressInsuranceForm = this.formBuilder.group({
            userInsuranceAddressAutocompleteQuery: ['', Validators.required],
            address2: [!this.addressVerificationCompleted ? '' : this.currentPerson.participant ? this.currentPerson.participant.address2 : ''],
            city: [!this.addressVerificationCompleted ? '' : this.currentPerson.city ? this.currentPerson.city : this.currentPerson.participant ? this.currentPerson.participant.city : '', Validators.required],
            stateKey: [!this.addressVerificationCompleted ? '' : this.currentPerson.state ? this.currentPerson.state : this.currentPerson.participant ? this.currentPerson.participant.state : '', Validators.required],
            zipCode: [
                !this.addressVerificationCompleted ? '' : this.currentPerson.zip ? this.currentPerson.zip : this.currentPerson.participant ? this.currentPerson.participant.zip : '',
                [Validators.required, Validators.minLength(5), Validators.maxLength(5)]
            ]
        });

        if (isDirectBill) {
            this.primaryCoverageNo = true;
            this.showPrimary = false;
            this.pastDirectBillSelected = true;
            this.clearValidation();
        } else {
            this.primaryCoverageYes = true;
            if (currentInsurance && currentInsurance.payorContractKey && currentInsurance.payorContractKey != Globals.DefaultValues.EmptyGuid) {
                this.setSelectedCoverage(currentInsurance.payorContractKey);
                this.hasLabcorpPayerCodeSelected = true;
            }
        }
        this.isReadyForRender();
    }
    // SMARTY AUTO-COMPLETE LOGIC
    userInsuranceAddressAutoCompleteSelect(event: NgbTypeaheadSelectItemEvent) {
        const suggestion = event.item as IUsAutoCompleteSuggestion;
        this.userInsuranceAddressAutocompleteQuery.value = suggestion.street;
        this.addressInsuranceForm.controls.userInsuranceAddressAutocompleteQuery.setValue(suggestion.street);
        this.addressInsuranceForm.controls.city.setValue(suggestion.city);
        this.addressInsuranceForm.controls.stateKey.setValue(suggestion.state);
        this.addressInsuranceForm.controls.zipCode.setValue(suggestion.zipCode);
        if (suggestion.secondary != null && suggestion.secondary.trim().length > 0) this.addressInsuranceForm.controls.address2.setValue(suggestion.secondary);
        this.userInsuranceAddressAutoCompleteValidate();
    }

    userInsuranceAddressAutoCompleteValidate() {
        this.userInsuranceAddressAutocompleteSettings.classList = 'form-control ng-pristine ng-valid ng-touched';
    }

    userInsuranceAddressAutoCompleteInValidate() {
        this.userInsuranceAddressAutocompleteSettings.classList = 'form-control ng-dirty ng-invalid ng-touched';
    }

    checkAddressInputValidity() {
        if (this.userInsuranceAddressAutocompleteQuery.checkInnerValue.trim().length < 4) {
            this.userInsuranceAddressAutoCompleteInValidate();
            this.addressInsuranceForm.controls.city.setValue('');
            this.addressInsuranceForm.controls.stateKey.setValue('');
            this.addressInsuranceForm.controls.zipCode.setValue('');
            this.addressInsuranceForm.controls.address2.setValue('');
        } else {
            this.userInsuranceAddressAutoCompleteValidate();
        }
    }

    userInsuranceAddressAutoCompleteInputFormatter(value: IUsAutoCompleteSuggestion) {
        if (value) {
            return value.street;
        }
        return '';
    }

    userInsuranceAddressAutoCompleteResultFormatter(value: IUsAutoCompleteSuggestion) {
        if (value.secondary != null && value.secondary.trim().length > 0) return `${value.street} - ${value.secondary}, ${value.city}, ${value.state} ${value.zipCode}`;
        else return `${value.street} ${value.city}, ${value.state} ${value.zipCode}`;
    }
    // END of SMARTY
    setSelectedCoverage(payorContractKey) {
        if (payorContractKey) {
            const selected = this.coverageStateChoices.find((r) => r.key.toLowerCase() == payorContractKey.toLowerCase());
            if (selected) {
                if (selected.organization.organizationTypeKey == Globals.OrganizationTypes.Medicare) {
                    this.isMedicareSelected = true;
                    this.setValidation('medicareNumber');
                    this.clearValidation('subscriberId');
                } else {
                    this.isMedicareSelected = false;
                    this.clearValidation('medicareNumber');
                    this.setValidation('subscriberId');
                    this.hasLabcorpPayerCodeSelected = true;
                }
            } else {
                this.onError();
            }
        }
    }
    toggleShowPrimary($event) {
        const formId = $event.currentTarget.value;
        const isChecked = $event.currentTarget.checked;
        if (formId == 'no') {
            //primaryCoverageNo
            this.primaryCoverageNo = isChecked;
            this.clearValidation('medicareNumber');
            this.showPrimary = false;
            this.isMedicareSelected = false;
            this.primaryCoverageYes = false;
            this.hasLabcorpPayerCodeSelected = false;

            // Clears all Validators for Insurance Form and Address Insurance Form
            this.clearAllPaymentWidgetPersonalValidation();
        } else {
            //primaryCoverageYes
            this.primaryCoverageYes = isChecked;
            this.primaryCoverageNo = false;
            const selected = this.insuranceForm.get('labcorpPayerCode').value;
            if (selected && selected != Globals.DefaultValues.EmptyGuid && selected != this._localizationService.get('noneselected', 'label')) {
                const selectedValue = this.coverageChoices.find((r) => r.key.toLowerCase() == selected.toLowerCase());
                if (selectedValue && selectedValue.organization.organizationTypeKey == Globals.OrganizationTypes.Medicare) {
                    this.isMedicareSelected = true;
                    this.setValidation('medicareNumber');
                    this.setValidation('labcorpPayerCode');
                }
            } else {
                this.setValidation('labcorpPayerCode');
            }

            if (this.insuranceForm.get('labcorpPayerCode').value != '' && this.insuranceForm.get('labcorpPayerCode').value.length > 0) {
                this.hasLabcorpPayerCodeSelected = true;
            }
            this.showPrimary = isChecked;
        }
    }

    filterByStateSelection($selectedValue) {
        this.coverageStateChoices = $selectedValue != '' ? this.coverageChoices.filter((item) => item.stateKeys.indexOf($selectedValue) !== -1) : this.coverageChoices;
        this.setValidation('state');
    }
    onPrimaryCoverageSelection(value) {
        this.isOrgProvidedInsurance = false;
        if (value && value != '') {
            const selected = this.coverageStateChoices.find((r) => r.key.toLowerCase() == value.toLowerCase());
            if (selected) {
                this.filterStateChoicesByCoverageSelected(selected.organizationKey);
                if (selected.organization.organizationTypeKey == Globals.OrganizationTypes.Medicare) {
                    if (this.personProgramBilling && selected.organizationKey === this.personProgramBilling.organizationKey) {
                        this.insuranceForm.get('medicareNumber').setValue(this.personProgramBilling.medicareNumber);
                        this.insuranceForm.get('groupNumber').setValue(this.personProgramBilling.groupNumber);
                        this.isOrgProvidedInsurance = true;
                    }
                    else {
                        this.insuranceForm.get('subscriberId').setValue('');
                        this.insuranceForm.get('groupNumber').setValue('');
                        this.insuranceForm.get('medicareNumber').setValue('');
                    }
                    this.isMedicareSelected = true;
                    this.setValidation('medicareNumber');
                    this.clearValidation('subscriberId');
                } else {
                    this.isMedicareSelected = false;
                    this.clearValidation('medicareNumber');
                    if (this.personProgramBilling && selected.organizationKey === this.personProgramBilling.organizationKey) {
                        this.insuranceForm.get('subscriberId').setValue(this.personProgramBilling.subscriberId);
                        this.insuranceForm.get('groupNumber').setValue(this.personProgramBilling.groupNumber);
                        this.isOrgProvidedInsurance = true;
                    } else {
                        this.insuranceForm.get('subscriberId').setValue('');
                        this.insuranceForm.get('groupNumber').setValue('');
                        this.insuranceForm.get('medicareNumber').setValue('');
                    }
                    this.setValidation('subscriberId');
                    this.hasLabcorpPayerCodeSelected = true;
                }
            } else {
                this.onError();
            }
        } else {
            this.filterStateChoicesByCoverageSelected('');
            this.setValidation('labcorpPayerCode');
            this.hasLabcorpPayerCodeSelected = false;
        }
    }
    private establishFormValidation(input: string): void {
        const formInput = document.getElementById(input);
        if (formInput != null) {
            formInput.removeAttribute('disabled');
            formInput.setAttribute('required', 'required');
        }
    }

    private setValidation(field: string) {
        switch (field) {
            case 'medicareNumber':
                const medicareControl = this.insuranceForm.get(field);
                medicareControl.setValidators([Validators.required, this.medicareNumberValid()]);
                medicareControl.updateValueAndValidity();
                medicareControl.setErrors({ required: true });
                setTimeout(() => {
                    this.establishFormValidation(field);
                }, 100);
                break;
            case 'subscriberId':
                const subControl = this.insuranceForm.get(field);
                subControl.setValidators([Validators.required]);
                subControl.updateValueAndValidity();
                if (!subControl.value) subControl.setErrors({ required: true });
                setTimeout(() => {
                    this.establishFormValidation(field);
                }, 100);
                break;
            case 'labcorpPayerCode':
                const payorControl = this.insuranceForm.get(field);
                payorControl.setValidators([Validators.required]);
                payorControl.updateValueAndValidity();
                payorControl.setErrors({ required: true });
                /* if (!payorControl.value || payorControl.value == "None Selected") payorControl.setErrors({ 'required': true });*/
                setTimeout(() => {
                    this.establishFormValidation(field);
                }, 100);
                break;

            case 'state':
                const stateControl = this.insuranceForm.get(field);
                stateControl.setValidators([Validators.required]);
                stateControl.updateValueAndValidity();
                if (!stateControl.value || stateControl.value == 'null') stateControl.setErrors({ required: true });
                setTimeout(() => {
                    this.establishFormValidation(field);
                }, 100);
                break;
            default:
                break;
        }
    }
    private clearValidation(field?: string): void {
        let control: any;
        if (field) {
            control = this.insuranceForm.get(field);
            if (control) {
                control.clearValidators();
                control.markAsPristine();
                control.markAsUntouched();
                control.updateValueAndValidity();
            }
        } else {
            Object.keys(this.insuranceForm.controls).forEach((key) => {
                control = this.insuranceForm.get(key);
                if (control) {
                    control.clearValidators();
                    control.markAsPristine();
                    control.markAsUntouched();
                    control.updateValueAndValidity();
                }
            });
        }
    }

    private clearAllPaymentWidgetPersonalValidation() {
        let control: any;
        Object.keys(this.insuranceForm.controls).forEach((key) => {
            control = this.insuranceForm.get(key);
            if (control) {
                control.clearValidators();
                control.markAsPristine();
                control.markAsUntouched();
                control.updateValueAndValidity();
            }
        });

        Object.keys(this.addressInsuranceForm.controls).forEach((key) => {
            control = this.insuranceForm.get(key);
            if (control) {
                control.clearValidators();
                control.markAsPristine();
                control.markAsUntouched();
                control.updateValueAndValidity();
            }
        });
    }

    private isReadyForRender(): void {
        this.isRenderReady = true;
    }

    localizeMessage(msgTag: string): string {
        let errorTag = 'unknownerror';
        if (msgTag) {
            try {
                errorTag = msgTag.split('.')[1];
            } catch (error) { }
        }
        return errorTag;
    }

    localizeValidationErrors(err): string {
        let errorName = this.localizeMessage(err.value);
        if (errorName !== 'unknownerror') {
            errorName = err.key + errorName;
        }
        return errorName;
    }

    showErorr(response: any): void {
        if (response.errorMessage && response.errors) {
            this.hasError = true;
            this.errorList = response.errors;
            this.errorMsg = response.errorMessage;
        }
    }

    onNextButtonClick() {
        if (this.isValid()) {
            this.processAction(WidgetCloseAction.forward, true);
        } else {
            this.hasError = true;
        }
    }
    onPreviousButtonClick() {
        //if button is present
        if (this.personWidget.position == WidgetCollectionPosition.first) return;
        this.processAction(WidgetCloseAction.backward, false);
    }
    closeError() {
        this.errorMsg = '';
        this.hasError = false;
    }
    private processAction(next: WidgetCloseAction, validate = true) {
        if (!validate) {
            return this.closePanel(false, next);
        }
        if (!this.insuranceForm.valid || !this.personKey) {
            this.onError();
            return;
        }
        const audit = this.currentPerson.userKey;
        if (this.primaryCoverageNo == true && !this.isDirectBillClient) {
            this.onError();
            return;
        } else if (this.primaryCoverageNo == true) {
            //skip insurance validation
            const directBill = new PersonProgramBilling();
            directBill.personKey = this.personKey;
            directBill.isDirectBill = true;
            directBill.programServiceKey = this.programServiceKey;
            directBill.organizationKey = null;
            directBill.subscriberId = null;
            directBill.groupNumber = null;
            directBill.payorContractKey = null;
            this.skipValidation = true;
            if (this.personProgramBilling && this.isValidGuid(this.personProgramBilling.key)) {
                //update
                directBill.key = this.personProgramBilling.key;
                directBill.eventKey = this.personProgramBilling.eventKey;
                this._insuranceService.updateProgramInsurance(directBill, audit).subscribe((r) => {
                    if (r) {
                        this.closePanel(true, next);
                    } else {
                        //save error - display errors
                        this.onError();
                    }
                });
            } else {
                //create
                this._insuranceService.createProgramInsurance(directBill, audit).subscribe(
                    (saveResult) => {
                        if (saveResult) {
                            this.closePanel(true, next);
                        } else {
                            //save error - display errors
                            this.onError();
                        }
                    },
                    (err) => {
                        this.onError();
                    }
                );
            }
        } else {
            //begin loading
            this.isRenderReady = false;
            this.isLoading = true;
            const selectedStateKey = this.insuranceForm.get('state').value;
            const groupNumber = this.insuranceForm.get('groupNumber').value;
            const verify = this.getVerificationFromForm();
            if (!verify) {
                return;
            }
            // 1. Verify the Insurance - Mark insuranceVerificationCompleted(true) if verify returns non-null and IsCovered
            // 2. Check if addreddVerificationCompleted(true), continue if true : else show address form.
            if (!this.insuranceVerificationCompleted) {
                this._insuranceService.verify(verify).subscribe(
                    (s) => {
                        if (s && s.isCovered) {
                            this.hasInvalidPrimaryCoverage = false;
                            this.insuranceVerificationCompleted = true;
                            if (s.hasValidUserInsuranceAddress) this.addressVerificationCompleted = true;
                            else {
                                // This code runs when Phoenix could not find an address, but a PersonAddress for this user
                                // does exist, and we should suggest to the user whether this address can be used.
                                if (s.address) {
                                    // Pre-Populate Suggestion for Contact Form
                                    this.addressInsuranceForm.controls.userInsuranceAddressAutocompleteQuery.setValue(s.address.line1);
                                    this.addressInsuranceForm.controls.address2.setValue(s.address.line2);
                                    this.addressInsuranceForm.controls.city.setValue(s.address.city);
                                    this.addressInsuranceForm.controls.zipCode.setValue(s.address.postalCode);
                                    this.addressInsuranceForm.controls.stateKey.setValue(s.address.stateAbbreviation);
                                    this.userInsuranceAddressAutoCompleteValidate();
                                    //
                                }
                            }
                            const personProgram = new PersonProgramBilling();
                            personProgram.organizationKey = verify.organizationKey;
                            personProgram.subscriberId = verify.subscriberId;
                            personProgram.personKey = verify.personKey;
                            personProgram.stateKey = selectedStateKey;
                            personProgram.groupNumber = groupNumber;
                            personProgram.isDirectBill = false;
                            personProgram.programServiceKey = this.programServiceKey;
                            personProgram.payorContractKey = verify.payorContractKey;
                            personProgram.source = Globals.PersonProgramBillingSources.WellConnectPlus;
                            if (this.personProgramBilling) personProgram.participantKey = this.personProgramBilling.participantKey;
                            personProgram.isValidated = true;

                            if (this.personProgramBilling && this.personProgramBilling.key && this.personProgramBilling.key != Globals.DefaultValues.EmptyGuid && !this.isNewInsurance(s.personInsuranceKey)) {
                                //update
                                personProgram.key = this.personProgramBilling.key;
                                personProgram.eventKey = this.personProgramBilling.eventKey;
                                personProgram.personInsuranceKey = this.personProgramBilling.personInsuranceKey;
                                this._insuranceService.updateProgramInsurance(personProgram, audit).subscribe((r) => {
                                    if (r) {
                                        if (this.addressVerificationCompleted) {
                                            this.addressVerificationRequired = false;
                                            this.closePanel(true, next);
                                        } else {
                                            this.addressVerificationRequired = true;
                                            this.isLoading = false;
                                            this.isRenderReady = true;
                                        }
                                    } else {
                                        //save error - display errors
                                        this.onError();
                                    }
                                });
                            } else {
                                //create
                                personProgram.personInsuranceKey = s.personInsuranceKey;
                                this._insuranceService.createProgramInsurance(personProgram, audit).subscribe(
                                    (saveResult) => {
                                        if (saveResult) {
                                            if (this.addressVerificationCompleted) {
                                                this.addressVerificationRequired = false;
                                                this.closePanel(true, next);
                                            } else {
                                                this.addressVerificationRequired = true;
                                                this.isLoading = false;
                                                this.isRenderReady = true;
                                            }
                                        } else {
                                            //save error - display errors
                                            this.onError();
                                        }
                                    },
                                    (err) => {
                                        this.onError();
                                    }
                                );
                            }
                        } else {
                            this.hasInvalidPrimaryCoverage = true;
                            const errorList = [{ tag: s.coverageContentTag.split('.')[1], token: s.coverageContentTag.split('.')[0], secondary: false }];
                            if (this.isDirectBillClient) {
                                errorList.push({ tag: 'insinvalidalternative', token: 'message', secondary: true });
                            }
                            this.displayVerificationError(errorList);
                        }
                    },
                    (err) => {
                        this.hasInvalidPrimaryCoverage = true;
                        const tagError: string = this.formatHTTP(err);
                        if (!tagError) {
                            this.onError();
                        } else {
                            this.displayVerificationError([{ tag: tagError.split('.')[1], token: tagError.split('.')[0], secondary: false }]);
                        }
                    }
                );
            } else {
                // Start Verification Process
                const addressModel = new UserInsuranceAddressValidate();
                const address = new ValidateInsuranceAddress();

                address.address1 = this.userInsuranceAddressAutocompleteQuery.checkInnerValue; // Text Displayed on screen (Either by selecting suggested address or by manually typing )
                address.address2 = this.addressInsuranceForm.controls.address2.value;
                address.city = this.addressInsuranceForm.controls.city.value;
                address.stateAbbreviation = this.addressInsuranceForm.controls.stateKey.value;
                address.zip = this.addressInsuranceForm.controls.zipCode.value;
                addressModel.address = address;

                this.subscriptions.push(
                    this._insuranceService.validateAddress(addressModel).subscribe(
                        (data) => {
                            if (data.isValid) {
                                const updateAddress = new UserInsuranceAddress();

                                if (data.standardizedAddress) {
                                    // Populate with Standardized Address
                                    updateAddress.address1 = data.standardizedAddress.address1;
                                    updateAddress.address2 = data.standardizedAddress.address2;
                                    updateAddress.city = data.standardizedAddress.city;
                                    updateAddress.zip = data.standardizedAddress.zip;
                                    updateAddress.stateAbbreviation = data.standardizedAddress.stateAbbreviation;
                                } else {
                                    // Populate Address
                                    updateAddress.address1 = address.address1;
                                    updateAddress.address2 = address.address2;
                                    updateAddress.city = address.city;
                                    updateAddress.zip = address.zip;
                                    updateAddress.stateAbbreviation = address.stateAbbreviation;
                                }
                                // Populate Verified PersonInsurance Record Data
                                updateAddress.subscriberId = verify.subscriberId;
                                updateAddress.personKey = verify.personKey;
                                updateAddress.userKey = verify.userKey;
                                updateAddress.organizationKey = verify.organizationKey;
                                updateAddress.auditUserKey = verify.auditUserKey;
                                updateAddress.participantKey = verify.participantKey;

                                // Update the address for this Insurance User
                                this._insuranceService.updateInsuranceAddress(updateAddress).subscribe(
                                    (s) => {
                                        if (s && s.isUpdated) {
                                            this.addressVerificationCompleted = true;
                                            this.addressVerificationRequired = false;
                                            this.closePanel(true, next);
                                        } else {
                                            //Display Unknown Error
                                            this.addressVerificationCompleted = false;
                                            this.isLoading = false;
                                            this.isRenderReady = true;
                                            const errorList = [{ tag: 'unknownerrortryagain', token: s.errorMessages[0], secondary: false }];
                                            this.displayVerificationError(errorList);
                                        }
                                    },
                                    (err) => {
                                        const tagError: string = this.formatHTTP(err);
                                        if (!tagError) {
                                            this.onError();
                                        } else {
                                            this.displayVerificationError([{ tag: tagError.split('.')[1], token: tagError.split('.')[0], secondary: false }]);
                                        }
                                    }
                                );
                            } else {
                                // Display Invalid Message
                                this.onError(this._localizationService.get('invalidaddress', 'message'));
                                this.userInsuranceAddressAutoCompleteInValidate();
                                this.isLoading = false;
                                this.isRenderReady = true;
                                this.hasError = true;
                                this.addressInsuranceForm.controls.userInsuranceAddressAutocompleteQuery.setValue(data.standardizedAddress.address1);
                                this.addressInsuranceForm.controls.address2.setValue(data.standardizedAddress.address2);
                                this.addressInsuranceForm.controls.city.setValue(data.standardizedAddress.city);
                                this.addressInsuranceForm.controls.zipCode.setValue(data.standardizedAddress.zip);
                                this.addressInsuranceForm.controls.stateKey.setValue(data.standardizedAddress.stateAbbreviation);
                            }
                        },
                        (error) => {
                            this.onError(this._localizationService.get('unknownerrortryagain', 'message'));
                        }
                    )
                );
            }
        }
    }
    private formatHTTP(err): string {
        if (err && err.error) {
            const tag = err.error.coverageContentTag;
            return tag ? tag : null;
        }
        return null;
    }
    shouldDisableButton(type: string, position: number): boolean {
        if (this.primaryCoverageNo == true) return false;
        if (position == 0 && type == 'back') {
            return true;
        }
        if (!this.insuranceForm.valid || this.isLoading || this.skipValidation || (!this.primaryCoverageNo && !this.primaryCoverageYes)) {
            return true;
        }
    }
    private isNewInsurance(newKey: string): boolean {
        if (!newKey || !this.personProgramBilling || !this.personProgramBilling.personInsuranceKey) {
            return true;
        }
        return newKey.toLowerCase() != this.personProgramBilling.personInsuranceKey.toLowerCase();
    }
    checkPaymentOptions(): void {
        //propably not a real scenario but let's leave it here for testing.
        if (!this.isDirectBillClient && !this.isPrivateInsuranceOrMedicarePartB) {
            this.isRenderReady = false;
            // Future Concern: What do we do in the situation that there are no payment options? Any fallback from this? Does it get this far in terms of
            // the payment widget even being loaded without payment options?
            alert('There are no payment options.');
        }
        else if (this.isDirectBillClient && this.isPrivateInsuranceOrMedicarePartB && !this.isInsurancePreferred) {
            this.hasMultipleCoverageOptions = true;
        }
    }

    get userServices() {
        const services = this.person().UserServices;
        return services;
    }
    get isDirectBillClient(): boolean {
        const options = this.userServices.find((r) => r.programService.programServiceKey.toLowerCase() == this.programServiceKey.toLowerCase()).servicePaymentOptions;
        if (!options || options.length == 0) return false;

        const hasAny = options.find((r) => r.paymentOptionKey.toLowerCase() == Globals.PaymentOptions.DirectBill);
        return hasAny && !hasAny.isDeleted;
    }
    get isPrivateInsuranceOrMedicarePartB(): boolean {
        const options = this.userServices.find((r) => r.programService.programServiceKey.toLowerCase() == this.programServiceKey.toLowerCase()).servicePaymentOptions;
        if (!options || options.length == 0) return false;

        const hasAny = options.find((r) => r.paymentOptionKey.toLowerCase() == Globals.PaymentOptions.MedicarePartB || r.paymentOptionKey.toLowerCase() == Globals.PaymentOptions.PrivateInsurance);
        return hasAny && !hasAny.isDeleted;
    }

    get isInsurancePreferred(): boolean {
        return this.userServices.find((r) => r.programService.programServiceKey.toLowerCase() == this.programServiceKey.toLowerCase()).programService.insurancePreferred;
    }
    private getVerificationFromForm(): InsuranceVerification {
        const values = this.insuranceForm.value;
        const verify = new InsuranceVerification();
        verify.dateOfBirth = values.dateOfBirth;
        verify.firstName = values.firstName;
        verify.lastName = values.lastName;
        verify.gender = this.gender;
        verify.subscriberId = this.isMedicareSelected ? values.medicareNumber.split('-').join('') : values.subscriberId;
        verify.personKey = this.personKey;
        verify.auditUserKey = this.logInPerson().userKey;
        if (this.personProgramBilling) verify.participantKey = this.personProgramBilling.participantKey;
        const selected = this.coverageChoices.find((r) => r.key.toLowerCase() == values.labcorpPayerCode.toLowerCase());
        if (!selected) {
            this.onError();
            return null;
        }
        verify.organizationKey = selected.organizationKey;
        verify.payorContractKey = selected.key;
        return verify;
    }

    private closePanel(complete: boolean, action: WidgetCloseAction) {
        //send back result
        this.isLoading = false;
        this.skipValidation = false;
        const result = new WidgetCompleted();
        result.dataCollectionKey = this.personWidget.dataCollectionKey;
        result.widgetKey = this.personWidget.widgetKey;
        result.isComplete = complete;
        result.action = action;
        result.widgetPanelIndex = this.personWidget.widgetPanelIndex;
        this.onWidgetCompleted.emit(result);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
    onError(msg = null) {
        this.isRenderReady = true;
        this.hasError = true;
        this.isLoading = false;
        this.skipValidation = false;
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
        if (msg) {
            this.errorMsg = msg;
        }
    }
    getTranslation(tag: string) {
        return this._localizationService.getCombinedTag(tag, null, null, null, this.tagOptions);
    }
    getNextMessage() {
        return this.getTranslation('button.next');
    }
    displayVerificationError(errorList: any[]) {
        this.isLoading = false;
        this.isRenderReady = true;
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.localizeTags = errorList;
        this.modalRef.result.then((result) => { });
    }
    private dateOfBirthValid(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let value = control.value;
            if (!value) {
                return null;
            }

            value = value.split('/');
            if (!value || value.length < 3) {
                return { dateOfBirthValid: true };
            }
            const month = parseInt(value[0], 10) - 1;
            const day = parseInt(value[1], 10);
            const year = parseInt(value[2]);
            if (month > 11 || month < 0 || (month == 1 && day > 29)) {
                return { dateOfBirthValid: true };
            }
            if (day > 31 || day < 1) {
                return { dateOfBirthValid: true };
            }
            const currentYear = new Date().getFullYear();
            if (year >= currentYear || year.toString().length !== 4) {
                return { dateOfBirthValid: true };
            }
            return null;
        };
    }
    private medicareNumberValid(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let value = control.value;
            if (!value) {
                return null;
            }
            let anyCharInvalid = false;

            value = value.split('-').join('');
            //11 chars
            if (!/^[A-Za-z0-9]{11,11}$/i.test(value)) {
                return { medicareNumberValid: true };
            }

            for (let index = 0; index < value.length; index++) {
                //1, 4, 7, 10, 11 - all numbers
                if ([0, 3, 6, 9, 10].indexOf(index) > -1) {
                    if (!/[0-9]+/.test(value[index])) {
                        anyCharInvalid = true;
                        break;
                    }
                }
                //2, 5, 8,9 - all letters
                if ([1, 4, 7, 8].indexOf(index) > -1) {
                    if (!/[A-Za-z]+/.test(value[index])) {
                        anyCharInvalid = true;
                        break;
                    }
                }
                //3,6 - letters/numbers
                if ([2, 5].indexOf(index)) {
                    if (!/[A-Za-z0-9]+/.test(value[index])) {
                        anyCharInvalid = true;
                        break;
                    }
                }
            }
            return anyCharInvalid ? { medicareNumberValid: true } : null;
        };
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Person } from '../person';

@Component({
    selector: 'app-person-blurb',
    templateUrl: './person-blurb.component.html',
    styleUrls: ['./person-blurb.component.css']
})
export class PersonBlurbComponent implements OnInit {
    @Input() person: Person;
    @Input() headingLocalizeProp = 'userinfo';
    @Input() htmlId = 'Unknown';

    constructor(private _router: Router) { }

    goToDetail($event) {
        $event.preventDefault();
        if (this.person) {
            this._router.navigate(['/participant/' + this.person.userKey]);
        }
    }

    ngOnInit() {
        console.info('Init PersonBlurbComponent');
    }
}

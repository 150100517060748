import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { SchedulerModule } from '../scheduler/scheduler.module';
import { AppNotificationService } from '../shared/app-notification/app-notification-service';
import { AttestationModalCompleteComponent } from "../shared/attestation/attestation-complete-modal.component";
import { AttestationModalComponent } from "../shared/attestation/attestation-modal.component";
import { GapsInCareService } from "../shared/gaps-in-care/gaps-in-care.service";
import { GapsInCareModalCompleteComponent } from "../shared/gaps-in-care/gaps-in-care-complete-modal.component";
import { GapsInCareModalComponent } from "../shared/gaps-in-care/gaps-in-care-modal.component";
import { GapsInCareSelectionModalComponent } from '../shared/gaps-in-care/gaps-in-care-selection-modal.component';
import { ServiceQualificationRuleOverrideCodeService } from '../shared/service-qualification-rule-failure-modal/service-qualification-rule-override-code-service';
import { SharedModule } from '../shared/shared.module';
import { UserBiometricCaptureModalComponent } from '../user-biometric-capture-modal/user-biometric-capture-modal.component';
import { LabcorpAppointSchedulerComponent } from "./appointments/labcorp.appointment.component";
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ServicesComponent } from './services/services.component';
import { ServicesService } from './services-list/services.service';
import { ServicesListComponent } from './services-list/services-list.component';
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FileUploadModule,
        MomentModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            { path: 'services', component: ServicesComponent }
        ]),
        FormsModule,
        NgbModule,
        MomentModule,
        NgxPageScrollCoreModule,
        NgbTooltipModule,
        SchedulerModule,
        NgbTypeaheadModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [ServicesComponent, ServicesListComponent, FileUploadComponent, UserBiometricCaptureModalComponent, AttestationModalComponent, AttestationModalCompleteComponent, LabcorpAppointSchedulerComponent, GapsInCareModalComponent, GapsInCareModalCompleteComponent, GapsInCareSelectionModalComponent],
    providers: [ServicesService, AppNotificationService, ServiceQualificationRuleOverrideCodeService, GapsInCareService, provideNgxMask()],
    exports: [
        ServicesListComponent
    ]
})
export class ServicesModule { }

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileItem, FileUploader } from 'ng2-file-upload';

import { environment } from '../../../environments/environment';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Service } from '../../shared/service';
import { ServicesService } from '../services-list/services.service';

const URL = environment.baseDocumentApiEndpoint + '/document';
@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, private servicesService: ServicesService) { }
    @Input() service: Service;
    @Input() userKey: string;
    public uploader: FileUploader = new FileUploader({ url: URL });
    public hasBaseDropZoneOver = false;
    public hasAnotherDropZoneOver = false;
    public isReady = false;
    public isFailure = false;
    public isSuccess = false;
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    ngOnInit() {
        this.uploader.autoUpload = false;
        this._localizeService.isReady.subscribe((isReady) => {
            this.isReady = true;
        });
        this.uploader.onCompleteItem = (fileItem: FileItem) => {
            this.servicesService.uploadDocumentValidation(fileItem).subscribe(
                (valid) => {
                    if (!valid) {
                        this.isFailure = true;
                    } else {
                        const blob = new Blob([fileItem._file], { type: fileItem.file.type });
                        this.upload(blob, fileItem.file.name);
                    }
                },
                (errResponse) => {
                    this.isFailure = true;
                    this.uploader.clearQueue();
                }
            );
        };
        this.uploader.onAfterAddingFile = () => {
            this.isFailure = false;
            this.isSuccess = false;

            if (this.uploader.queue.length > 1) {
                this.uploader.removeFromQueue(this.uploader.queue[0]);
            }
        };
    }
    private upload(blob: Blob, fileName: string) {
        this.servicesService.uploadDocument(this.service.programServiceKey, this.userKey, fileName, blob).subscribe(
            (response) => {
                this.isSuccess = true;

                this.uploader.clearQueue();
            },
            (errResponse) => {
                this.isFailure = true;
                this.uploader.clearQueue();
            }
        );
    }
}

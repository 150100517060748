import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { WCSMessage } from "./wcs-message";

@Injectable()
export class WCSMessageService {
    private messageSource = new Subject<WCSMessage>();
    message = this.messageSource.asObservable();

    sendMessage(message: WCSMessage) {
        this.messageSource.next(message);
    }

    clearMessage() {
        this.messageSource.next(null);
    }
}
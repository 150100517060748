<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-ReferenceInformation">{{ 'referenceRangeTitle' | lesCustomLocalize : 'label' }}</h4>
</div>

<table [ngClass]="{ 'table table-striped wcs': true }" id="resultsId-ReferenceInformation">
    <thead>
        <tr>
            <td class="secondary-text">{{ 'referenceRangeDisplay' | lesCustomLocalize : 'label' }}</td>
            <td class="secondary-text">{{ 'referenceRiskDisplay' | lesCustomLocalize : 'label' }}</td>
            <td *ngIf="showGender()" class="secondary-text">{{ 'referenceGender' | lesCustomLocalize : 'label' }}</td>
            <td *ngIf="showAge()" class="secondary-text">{{ 'referenceAge' | lesCustomLocalize : 'label' }}</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ref of referenceRanges" class="table table-striped">
            <td *ngIf="ref.rangeDisplay" class="d-xs-table-cell">
                <span>{{ ref.rangeDisplay }}</span
                ><span *ngIf="showUnits(ref)"> {{ ref.units }}</span>
            </td>
            <td class="d-xs-table-cell" [style.color]="ref.riskColor">{{ ref.riskDisplay }}</td>
            <td *ngIf="showGender()" class="d-xs-table-cell">{{ ref.gender }}</td>
            <td *ngIf="showAge()" class="d-xs-table-cell">{{ ref.age }}</td>
        </tr>
    </tbody>
</table>

<app-error *ngIf="hasError" [htmlId]="'ReferenceInformation'"></app-error>

<div class="modal-footer pt-10">
    <button type="button" class="tertiary-bg btn" id="actionId-CloseReferenceInformation" (click)="activeModal.close('Close click')">{{ 'close' | lesCustomLocalize : 'label' }}</button>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../localize/localize.service';
import { MetadataService } from '../metadata/metadata.service';
import { Person } from '../person/person';
import { CompleteProfile, Profile } from '../profile/profile';
import { ProfileService } from '../profile/profile.service';
import { State } from '../state/state';
import { UserService } from '../user/user.service';
import { UserInformation } from '../user/user-information';
import { CustomValidators } from '../validators';
import { AddressModel, AddressValidateViewModel, GapsInCareModel, GapsInCareViewModel } from './gaps-in-care.model';
import { GapsInCareService } from './gaps-in-care.service';
import { GapsInCareModalCompleteComponent } from './gaps-in-care-complete-modal.component';

@Component({
    selector: 'app-gaps-in-care-modal',
    templateUrl: './gaps-in-care-modal.component.html',
    styleUrls: ['./gaps-in-care-modal.component.css']
})
export class GapsInCareModalComponent implements OnInit, OnDestroy {
    @Input() gapsInCareViewModel: GapsInCareViewModel;
    @Input() currentPerson: Person;
    @Input() title: string;
    form: FormGroup;
    states: State[];
    subscriptions: ISubscription[] = [];
    userInformation: UserInformation = new UserInformation();
    gapsInCare: GapsInCareModel = new GapsInCareModel();
    _userKey: string;
    public isSubmitting = false;
    isAddressReady = false;
    isPhoneReady = false;
    isEmailReady = false;
    isReady = false;
    captureGapsInCareRequestForm: FormGroup;
    addressValidationMessage = '';

    @Input() set userKey(val: string) {
        this._userKey = val;
        this.gapsInCare.companyKey = this.gapsInCareViewModel.companyKey;
        this.gapsInCare.programServiceKey = this.gapsInCareViewModel.programServiceKey;
        this.gapsInCare.transactionId = this.gapsInCareViewModel.transactionId;
        this.gapsInCare.participantServiceEligibilityKey = this.gapsInCareViewModel.participantServiceEligibilityKey;
        this.gapsInCare.lcaAccountNumber = this.gapsInCareViewModel.lcaAccountNumber;
        this.gapsInCare.gicModality = this.gapsInCareViewModel.gicModality;

        this._userService.getUserInformation(val).subscribe((info) => {
            if (info != null) {
                this.userInformation = info;
                this.gapsInCare.address1 = info.address1;
                this.gapsInCare.address2 = info.address2;
                this.gapsInCare.city = info.city;
                this.gapsInCare.stateAbbreviation = info.stateAbbreviation;
                this.gapsInCare.zip = info.zip;
                this.gapsInCare.userKey = this.currentPerson.user.key;
                this.gapsInCare.participantKey = this.currentPerson.user.participantKey;
            }
            this.isAddressReady = true;
            this.isReadyForRender();

            if (val && this.isValidGuid(val)) this.userInformation.userKey = val;
        });

        this.subscriptions.push(
            this._profileService.getOtherUserProfile(val).subscribe((profile: CompleteProfile) => {
                const gicForm = this.captureGapsInCareRequestForm;
                const emailGroup = gicForm.controls.emailGroup;
                const controlEmail = emailGroup.get('email');

                controlEmail.setValue(profile.emailAddress);
                controlEmail.setErrors(null);
                controlEmail.markAsPristine();
                controlEmail.markAsUntouched();

                const controlConfirmEmail = emailGroup.get('confirmEmail');
                controlConfirmEmail.setErrors(null);
                controlConfirmEmail.markAsPristine();
                controlConfirmEmail.markAsUntouched();

                this.gapsInCare.email = profile.emailAddress;
                this.gapsInCare.receiveSmsReminders = profile.receiveSmsReminders;
                this.gapsInCare.receivePaperCommunications = false;

                if (profile.textMessagePhone != null && profile.textMessagePhone.length > 0) {
                    this.gapsInCare.phone = profile.textMessagePhone;
                } else {
                    this.gapsInCare.phone = profile.phone;
                }

                this.isEmailReady = true;
                this.isPhoneReady = true;
                this.isReadyForRender();
            })
        );
    }
    get userKey(): string {
        return this._userKey;
    }
    constructor(
        private _activeModal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        private _localizationService: LocalizeService,
        private _modalService: NgbModal,
        private _metadataService: MetadataService,
        private _userService: UserService,
        private _profileService: ProfileService,
        private formBuilder: FormBuilder,
        private _gapsInCareService: GapsInCareService
    ) {
        _metadataService.getStates();
        this.createForm();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this._metadataService.stateList.subscribe((data) => {
                this.states = data;
                this.isReadyForRender();
            })
        );
    }

    close() {
        this._activeModal.close();
    }

    save() {
        if (this.isSubmitting) {
            return;
        }
        this.isSubmitting = true;

        const addressModel = new AddressValidateViewModel();
        const address = new AddressModel();
        address.address1 = this.gapsInCare.address1;
        address.address2 = this.gapsInCare.address2;
        address.city = this.gapsInCare.city;
        address.stateAbbreviation = this.gapsInCare.stateAbbreviation;
        address.zip = this.gapsInCare.zip;
        addressModel.address = address;

        this.subscriptions.push(
            this._gapsInCareService.validateAddress(addressModel).subscribe(
                (data) => {
                    if (data.isValid) {
                        this.addressValidationMessage = '';
                        this.gapsInCare.address1 = data.standardizedAddress.address1;
                        this.gapsInCare.address2 = data.standardizedAddress.address2;
                        this.gapsInCare.city = data.standardizedAddress.city;
                        this.gapsInCare.stateAbbreviation = data.standardizedAddress.stateAbbreviation;
                        this.gapsInCare.zip = data.standardizedAddress.zip;

                        this.subscriptions.push(
                            this._gapsInCareService.createServiceOrder(this.gapsInCare).subscribe(
                                (data) => {
                                    this.close();
                                    this.isSubmitting = false;
                                    this.showCompletedModal();
                                },
                                (error) => {
                                    this.close();
                                    this.isSubmitting = false;
                                    this.handleError();
                                }
                            )
                        );
                    } else {
                        // handle address errors
                        this.isSubmitting = false;
                        this.addressValidationMessage = this._localizationService.get(data.errorMessage, 'message');
                    }
                },
                (error) => {
                    this.close();
                    this.isSubmitting = false;
                    this.handleError();
                }
            )
        );
    }

    handleError() {
        alert(this._localizationService.get('unknownerrortryagain', 'message'));
    }

    showCompletedModal() {
        const modal = this._modalService.open(GapsInCareModalCompleteComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        modal.componentInstance.title = this._localizationService.get(this.gapsInCareViewModel.titleLabel, 'program');
    }
    isValidGuid(guid: string) {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
    }
    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }

    private createForm(): void {
        this.captureGapsInCareRequestForm = this._formBuilder.group({
            address1: '',
            address2: '',
            city: '',
            stateAbbreviation: '',
            zip: '',
            emailGroup: this.formBuilder.group(
                {
                    email: ['', CustomValidators.email],
                    confirmEmail: ''
                },
                { validator: this.match(['email', 'confirmEmail']) }
            ),
            phoneGroup: this.formBuilder.group(
                {
                    phone: '',
                    confirmPhone: ''
                },
                { validator: this.match(['phone', 'confirmPhone']) }
            ),
            receiveSmsReminders: false,
            receivePaperCommunications: false
        });
    }

    private isReadyForRender(): void {
        if (this.isAddressReady && this.isPhoneReady && this.isEmailReady && this.states) {
            this.isReady = true;
        }
    }

    // Validate two control values (for one form group) match.
    // Note: Expects the two form control parameters to compare.
    private match(params: string[]): ValidatorFn {
        return (control: AbstractControl): Record<string, boolean> | null => {
            if (!control.get(params[0]).value && !control.get(params[1]).value) {
                return null;
            } else if (!control.get(params[0]).touched || control.get(params[0]).pristine) {
                return null;
            } else if (control.get(params[0]).value !== control.get(params[1]).value) {
                return { match: true };
            }
            return null;
        };
    }
}

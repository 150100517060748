import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Globals } from '../globals';
import { PersonBiometric, PersonBiometricSubmits } from '../user/person-biometric';
import { UserService } from '../user/user.service';
import { AttestationCapture } from './attestation.capture';
import { AttestationModel } from './attestation.model';

@Injectable()
export class AttestationService {
    constructor(private _http: HttpClient, private _userService: UserService) { }

    getAttestation(programServiceKey: string, languageCode: string): Observable<AttestationModel> {
        return this._http.get<AttestationModel>(`${environment.baseCoreApiEndpoint}/attestation?programServiceKey=${programServiceKey}&languageLocale=${languageCode}`);
    }

    captureAttestationResponse(attestationCapture: AttestationCapture): Observable<any> {
        //create userBiometric and populate attestation field based on the ServiceOptions
        const userBiometricSubmit = new PersonBiometricSubmits(
            new PersonBiometric({
                userKey: attestationCapture.userKey,
                programServiceKey: attestationCapture.programServiceKey,
                pregnant: attestationCapture.serviceOptionKey.localeCompare(Globals.ServiceOptions.Attestation.Pregnant, undefined, { sensitivity: 'base' }) === 0 ? attestationCapture.response : null,
                tobaccoUse: attestationCapture.serviceOptionKey.localeCompare(Globals.ServiceOptions.Attestation.TobaccoUse, undefined, { sensitivity: 'base' }) === 0 ? attestationCapture.response : null
            })
        );
        return this._userService.createUserBiometric(userBiometricSubmit);
    }
}

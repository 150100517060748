/*
    Inspired by Angular 4 - https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
    and Deborah Kurata from Pluralsight.
*/

import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyValue(value: any): boolean {
    return value === null || value.length === 0;
}

// https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
const EMAIL_REGEXP =
    /^[\w!#$%&'*+\-/=?\^_`{|}~]+(\.[\w!#$%&'*+\-/=?\^_`{|}~]+)*@((([\-\w]+\.)+[a-zA-Z]{2,30})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/;

export class CustomValidators {

    static email(control: AbstractControl): ValidationErrors {
        // Allow empty values
        if (isEmptyValue(control.value)) {
            return null;
        }
        // Compare against email regular expression
        else if (!EMAIL_REGEXP.test(control.value)) {
            return { 'email': true };
        }
        return null;
    }
}

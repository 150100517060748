<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-BodyBiometrics">{{ 'userbiometriccapturemodalheader' | lesCustomLocalize : 'label' }}</h4>
</div>

<div class="modal-body" *ngIf="biometricForm && isRenderReady">
    <div class="row capture-header pl-3 pr-4">
        {{ 'requirementbiometriccapture' | lesCustomLocalize : 'message' }}
    </div>

    <form [formGroup]="biometricForm" id="formId-BodyBiometrics" (ngSubmit)="save()" novalidate>
        <div class="form-group row" *ngIf="showHeight">
            <div class="col-sm-4">
                <label for="heightFeet">{{ 'heightfeet' | lesCustomLocalize : 'label' }}</label>
                <select class="form-control" name="heightFeet" id="heightFeet" required formControlName="heightFeet">
                    <option *ngFor="let heightFeet of heightFeetChoices" [value]="heightFeet.value">{{ heightFeet.desc }}</option>
                </select>
            </div>
            <div class="col-sm-4">
                <label for="heightInches">{{ 'heightinches' | lesCustomLocalize : 'label' }}</label>
                <select class="form-control" name="heightInches" id="heightInches" required formControlName="heightInches">
                    <option *ngFor="let heightInches of heightInchesChoices" [value]="heightInches.value">{{ heightInches.desc }}</option>
                </select>
            </div>
            <div class="col-sm-4">
                <label for="heightFeetDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="heightFeet,heightInches" name="heightFeetDoNotKnow" id="heightFeetDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div class="alert alert-danger" id="validationErrorId-HeightFeetInvalid" *ngIf="biometricForm.get('heightFeet').touched && !biometricForm.get('heightFeet').pristine && biometricForm.controls.heightFeet.errors?.required">
                    {{ 'heightfeetinvalid' | lesCustomLocalize : 'error' }}
                </div>
                <div class="alert alert-danger" id="validationErrorId-HeightInchesInvalid" ngIf="biometricForm.get('heightInches').touched && !biometricForm.get('heightInches').pristine && biometricForm.controls.heightInches.errors?.required">
                    {{ 'heightinchesinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="showWeight">
            <div class="col-sm-8">
                <label for="weight">{{ 'weight' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="5" name="weight" id="weight" required formControlName="weight" />
            </div>
            <div class="col-sm-4">
                <label for="weightDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="weight" name="weightDoNotKnow" id="weightDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-WeightInvalid"
                    *ngIf="
                        biometricForm.get('weight').touched &&
                        !biometricForm.get('weight').pristine &&
                        (biometricForm.controls.weight.errors?.required || biometricForm.controls.weight.errors?.min || biometricForm.controls.weight.errors?.max || biometricForm.controls.weight.errors?.pattern)
                    "
                >
                    {{ 'weightinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="showWaist">
            <div class="col-sm-8">
                <label for="waistInches">{{ 'waistinches' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="4" name="waistInches" id="waistInches" required formControlName="waistInches" />
            </div>
            <div class="col-sm-4">
                <label for="waistInchesDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="waistInches" name="waistInchesDoNotKnow" id="waistInchesDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-WaistInvalid"
                    *ngIf="
                        biometricForm.get('waistInches').touched &&
                        !biometricForm.get('waistInches').pristine &&
                        (biometricForm.controls.waistInches.errors?.required || biometricForm.controls.waistInches.errors?.min || biometricForm.controls.waistInches.errors?.max || biometricForm.controls.waistInches.errors?.pattern)
                    "
                >
                    {{ 'waistInchesinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="showNeck">
            <div class="col-sm-8">
                <label for="neck">{{ 'neck' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="4" name="neck" id="neck" required formControlName="neck" />
            </div>
            <div class="col-sm-4">
                <label for="neckDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="neck" name="neckDoNotKnow" id="neckDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-NeckInvalid"
                    *ngIf="
                        biometricForm.get('neck').touched &&
                        !biometricForm.get('neck').pristine &&
                        (biometricForm.controls.neck.errors?.required || biometricForm.controls.neck.errors?.min || biometricForm.controls.neck.errors?.max || biometricForm.controls.neck.errors?.pattern)
                    "
                >
                    {{ 'neckinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="showHip">
            <div class="col-sm-8">
                <label for="hip">{{ 'hip' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="4" name="hip" id="hip" required formControlName="hip" />
            </div>
            <div class="col-sm-4">
                <label for="hipDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="hip" name="hipDoNotKnow" id="hipDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-HipInvalid"
                    *ngIf="
                        biometricForm.get('hip').touched &&
                        !biometricForm.get('hip').pristine &&
                        (biometricForm.controls.hip.errors?.required || biometricForm.controls.hip.errors?.min || biometricForm.controls.hip.errors?.max || biometricForm.controls.hip.errors?.pattern)
                    "
                >
                    {{ 'hipinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="row capture-header pl-2 pb-0" id="displayId-BloodPressureSection" *ngIf="showBloodPressure">
            {{ 'bloodpressure' | lesCustomLocalize : 'label' }}
        </div>
        <div class="form-group row" *ngIf="showBloodPressure">
            <div class="col-sm-4">
                <label for="bpSystolic">{{ 'bpsystolic' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="3" name="bpSystolic" id="bpSystolic" required formControlName="bpSystolic" />
            </div>
            <div class="col-sm-4">
                <label for="bpDiastolic">{{ 'bpdiastolic' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="3" name="bpDiastolic" id="bpDiastolic" required formControlName="bpDiastolic" />
            </div>
            <div class="col-sm-4">
                <label for="bpSystolicDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="bpSystolic,bpDiastolic" name="bpSystolicDoNotKnow" id="bpSystolicDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-DiastolicInvalid"
                    *ngIf="
                        biometricForm.get('bpSystolic').touched &&
                        !biometricForm.get('bpSystolic').pristine &&
                        (biometricForm.controls.bpSystolic.errors?.required || biometricForm.controls.bpSystolic.errors?.min || biometricForm.controls.bpSystolic.errors?.max || biometricForm.controls.bpSystolic.errors?.pattern)
                    "
                >
                    {{ 'bpsystolicinvalid' | lesCustomLocalize : 'error' }}
                </div>
                <div
                    class="alert alert-danger"
                    id="validationErrorId-DiastolicInvalid"
                    *ngIf="
                        biometricForm.get('bpDiastolic').touched &&
                        !biometricForm.get('bpDiastolic').pristine &&
                        (biometricForm.controls.bpDiastolic.errors?.required ||
                            biometricForm.controls.bpDiastolic.errors?.invalid ||
                            biometricForm.controls.bpDiastolic.errors?.min ||
                            biometricForm.controls.bpDiastolic.errors?.max ||
                            biometricForm.controls.bpDiastolic.errors?.pattern)
                    "
                >
                    {{ 'bpdiastolicinvalid' | lesCustomLocalize : 'error' }}
                </div>
                <div
                    class="alert alert-danger"
                    id="validationErrorId-DiastolicInvalid"
                    *ngIf="
                        biometricForm.get('bpSystolic').touched &&
                        !biometricForm.get('bpSystolic').pristine &&
                        biometricForm.get('bpDiastolic').touched &&
                        !biometricForm.get('bpDiastolic').pristine &&
                        biometricForm.get('bpSystolic').value < biometricForm.get('bpDiastolic').value
                    "
                >
                    {{ 'bpdiastolicinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="showHeartRate">
            <div class="col-sm-8">
                <label for="heartRate">{{ 'heartrate' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="3" name="heartRate" id="heartRate" required formControlName="heartRate" />
            </div>
            <div class="col-sm-4">
                <label for="heartRateDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="heartRate" name="heartRateDoNotKnow" id="heartRateDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-HeartRateInvalid"
                    *ngIf="
                        biometricForm.get('heartRate').touched &&
                        !biometricForm.get('heartRate').pristine &&
                        (biometricForm.controls.heartRate.errors?.required || biometricForm.controls.heartRate.errors?.min || biometricForm.controls.heartRate.errors?.max || biometricForm.controls.heartRate.errors?.pattern)
                    "
                >
                    {{ 'heartrateinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="showBodyFat">
            <div class="col-sm-8">
                <label for="bodyFat">{{ 'bodyfat' | lesCustomLocalize : 'label' }}</label>
                <input type="number" class="form-control" maxlength="4" name="bodyFat" id="bodyFat" required formControlName="bodyFat" />
            </div>
            <div class="col-sm-4">
                <label for="bodyFatDoNotKnow">
                    {{ 'idonotknow' | lesCustomLocalize : 'label' }}
                    <input type="checkbox" class="inline-checkbox" value="bodyFat" name="bodyFatDoNotKnow" id="bodyFatDoNotKnow" (click)="theyJustDoNotKnow($event)" />
                </label>
            </div>
            <div class="col-sm-8">
                <div
                    class="alert alert-danger"
                    id="validationErrorId-BodyFatInvalid"
                    *ngIf="
                        biometricForm.get('bodyFat').touched &&
                        !biometricForm.get('bodyFat').pristine &&
                        (biometricForm.controls.bodyFat.errors?.required || biometricForm.controls.bodyFat.errors?.min || biometricForm.controls.bodyFat.errors?.max || biometricForm.controls.bodyFat.errors?.pattern)
                    "
                >
                    {{ 'bodyfatinvalid' | lesCustomLocalize : 'error' }}
                </div>
            </div>
        </div>

        <div class="row capture-header p-2" *ngIf="justDoNotKnowSelections > 0">
            <span class="error-text" id="errorAlertId-BodyBiometrics">{{ 'biometricidontknowused' | lesCustomLocalize : 'message' }}</span>
        </div>

        <div class="row" *ngIf="showError && errorMsg.length">
            <div class="col-sm-12" id="validationErrorId-BodyBiometrics">
                <span class="error-msg">{{ 'validationerrors' | lesCustomLocalize : 'message' }}</span>
                <hr />
            </div>
            <div class="col-sm-12" id="validationErrorId-BodyBiometrics" *ngFor="let error of errorList">
                <ul>
                    <li class="error-text">{{ lesCustomLocalizeValidationErrors(error) | lesCustomLocalize : 'error' }}</li>
                </ul>
            </div>
        </div>

        <div class="modal-footer pt-10">
            <button class="tertiary-bg btn btn-primary" id="actionId-CancelBodyBiometrics" (click)="cancel()">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
            <button type="submit" [disabled]="!biometricForm.valid" class="tertiary-bg btn btn-primary" id="actionId-SaveBodyBiometrics">
                {{ 'save' | lesCustomLocalize : 'label' }}
            </button>
        </div>
    </form>
</div>

<app-loading *ngIf="!isRenderReady"></app-loading>

import { Component, OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { WCSMessageService } from '../../services/wcs-message/wcs.message.service';
import { WCSMessage, WCSMessageTypes } from '../../services/wcs-message/wcs-message';
import { AccessMode } from '../../shared/access-mode';
import { CompanyService } from '../../shared/company/company.service';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';

@Component({
  selector: 'app-participant-detail',
  templateUrl: './participant-detail.component.html',
  styleUrls: ['./participant-detail.component.css']
})
export class ParticipantDetailComponent implements OnInit, OnDestroy {
  userKey: string;
  readyForRender = false;
  localizeReady = false;
  hasError = false;
  accessMode = AccessMode.ParticipantDetail;
  secureMessagingMode = AccessMode.ParticipantDetail;
  subscriptions: ISubscription[] = [];
  participant: Person = null;
  canSeeCoaching = false;
  canSeeScreening = false;
  canSeeServices = false;
  isCoaching = false;
  appUser: Person = null;
  sectionValues = Globals.SectionValues;
  sectionToShow = Globals.SectionValues.Appointments;

  constructor(private route: ActivatedRoute, private _router: Router, private _personService: PersonService,
    private _localizeService: LocalizeService, private _companyService: CompanyService, private _messageService: WCSMessageService) { }

  changeSection(section) {
    this.sectionToShow = section;
  }

  private onError() {
    this.hasError = true;
  }

  private checkReady() {
    if (this.localizeReady && this.participant) {

      if (this.appUser && (this.appUser.isAnyAdmin || this.appUser.isSupportUser)) {
        this._companyService.fetchCompany(this.participant.company.key);
      }

      this.readyForRender = true;
    }
  }

  private loadData() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      this.localizeReady = true;
      this.checkReady();
    },
      error => this.onError()));

    this.subscriptions.push(this._personService.currentPerson.subscribe(user => {
      if (user && !user.canSearch) {
        this._router.navigate(['home']);
      }

      this.appUser = user;// our logged in user
      this.canSeeCoaching = user.canSeeParticipantCoaching;
      this.canSeeScreening = user.canSeeParticipantScreening;
      this.canSeeServices = user.canSeeParticipantServices;
      this.isCoaching = user.isCoaching;

      this.subscriptions.push(this._personService.getOtherPerson(this.userKey, false).subscribe(otherUser => {
        this.participant = otherUser;
        this.checkReady();
      },
        error => this.onError()));
      this.subscriptions.push(this._companyService.company.subscribe(company => {
        if (company) {
          const message: WCSMessage = { type: WCSMessageTypes.AdminCompanyInformation, sender: null, data: company };
          this._messageService.sendMessage(message);
        }
      }))
    },
      error => this.onError()));
  }

  ngOnInit() {
    const that = this;

    this.route.params.subscribe(function (params) {
      that.localizeReady = false;
      that.readyForRender = false;
      that.participant = null;
      that.hasError = false;

      that.userKey = params.userKey;

      that.loadData();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

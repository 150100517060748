export class UserBiometricCaptureResult {
  requiresBiometricCapture: boolean;
  hasBiometricCapture: boolean;
  userBiometricCapture: UserBiometricCapture;
}

export class UserBiometricCapture {
  userKey: string;
  key: string;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, OnDestroy {
  subscriptions: ISubscription[] = [];
  readyForRender = false;

  constructor(private _localizeService: LocalizeService) { }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      this.readyForRender = isReady;
    }));
  }
  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}


import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileItem } from 'ng2-file-upload';
import { DeviceDetectorService as Ng2DeviceService } from 'ngx-device-detector';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoadDocumentComponent } from '../../shared/load-document/load-document.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { PersonService } from '../../shared/person/person.service';
import { IEmailDocumentPostViewModel } from '../appointments/labcorp.appointment.models';
import { ThirdPartyResponse } from './thirdParty.model';

@Injectable()
export class ServicesService {
    constructor(private _http: HttpClient, private _localizeService: LocalizeService, private _modalService: NgbModal, private sanitizer: DomSanitizer, private deviceService: Ng2DeviceService, private _personService: PersonService) { }

    modalRef: NgbModalRef;

    getThirdParty(companyKey: string, programServiceKey: string, participantKey: string, userKey: string): Observable<ThirdPartyResponse> {
        let params = new HttpParams();
        params = params.append('programServiceKey', programServiceKey);
        params = params.append('participantKey', participantKey);
        params = params.append('companyKey', companyKey);
        params = params.append('userKey', userKey);
        return this._http.get<ThirdPartyResponse>(environment.baseCoreApiEndpoint + '/thirdParty', { params: params }).pipe(catchError(this.handleError));
    }

    emailDocument(request: IEmailDocumentPostViewModel): Observable<boolean> {
        return this._http.put<boolean>(`${environment.baseDocumentApiEndpoint}/document`, request);
    }

    getImmunization(screenDataKey: string, participantKey: string, filename = 'ImmunizationRecord') {
        let params = new HttpParams();
        params = params.append('screenDataKey', screenDataKey);
        params = params.append('participantKey', participantKey);

        this.showDocumentLoader(true, true, false, this._localizeService.get('generatingdocument', 'message'));

        this._http
            .get(environment.baseDocumentApiEndpoint + '/document/getimmunizationrecord', {
                params: params,
                responseType: 'blob'
            })
            .subscribe(
                (response) => {
                    const device = this.deviceService.getDeviceInfo();
                    // http://www.practicalaem.com/2015/11/20/issues-with-download-files-in-ie-using-angular-and-fix/
                    if (this.deviceService.isDesktop() && (device.browser === 'ms-edge' || device.browser === 'ie')) {
                        // Internet Explorer browser or Edge
                        const fn = filename.replace(/\s/g, '') + '.pdf';
                        window.navigator.msSaveBlob(response, fn);
                        this.showDocumentLoader(false);
                    } else {
                        // Other browsers
                        const blobUri = URL.createObjectURL(response);
                        if (!this.deviceService.isDesktop()) {
                            this.modalRef.componentInstance.downloadLink = this.sanitizer.bypassSecurityTrustUrl(blobUri);
                            this.modalRef.componentInstance.showDownload = true;
                        } else {
                            const a = document.createElement('a');
                            a.download = filename.replace(/\s/g, '') + '.pdf';
                            a.type = 'application/pdf';
                            a.href = blobUri;
                            document.body.appendChild(a);
                            a.click();
                            this.showDocumentLoader(false);
                        }
                    }
                },
                (error) => {
                    this.customHandleError(error, true);
                }
            );
    }
    canDownloadProof(screenDataKey: string, participantKey: string) {
        let params = new HttpParams();
        params = params.append('screenDataKey', screenDataKey);
        params = params.append('participantKey', participantKey);
        return this._http.get<boolean>(environment.baseDocumentApiEndpoint + '/document/usercandownloadvaccinationproof', {
            params: params
        });
    }

    getDocument(programServiceKey: string, userKey: string, filename = 'export'): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('programServiceKey', programServiceKey);
        params = params.append('userKey', userKey);

        this.showDocumentLoader(true, true, false, this._localizeService.get('generatingdocument', 'message'), userKey, programServiceKey, filename);

        return this._http
            .get(environment.baseDocumentApiEndpoint + '/document', {
                params: params,
                responseType: 'blob'
            }).pipe(
                map(
                    (response) => {
                        const device = this.deviceService.getDeviceInfo();
                        // http://www.practicalaem.com/2015/11/20/issues-with-download-files-in-ie-using-angular-and-fix/
                        if (this.deviceService.isDesktop() && (device.browser === 'ms-edge' || device.browser === 'ie')) {
                            // Internet Explorer browser or Edge
                            const fn = filename.replace(/\s/g, '') + '.pdf';
                            window.navigator.msSaveBlob(response, fn);
                            this.showDocumentLoader(false);
                        } else {
                            // Other browsers
                            const blobUri = URL.createObjectURL(response);
                            if (!this.deviceService.isDesktop()) {
                                this.modalRef.componentInstance.downloadLink = this.sanitizer.bypassSecurityTrustUrl(blobUri);
                                this.modalRef.componentInstance.showDownload = true;
                            } else {
                                const a = document.createElement('a');
                                a.download = filename.replace(/\s/g, '') + '.pdf';
                                a.type = 'application/pdf';
                                a.href = blobUri;
                                document.body.appendChild(a);
                                a.click();
                                this.showDocumentLoader(false);
                            }
                        }
                        //reload person
                        this._personService.fetchPerson();
                        return true;
                    },
                    (error) => {
                        this.customHandleError(error);
                        return false;
                    }
                ));
    }

    uploadDocumentValidation(fileItem: FileItem): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('fileType', fileItem.file.type);
        params = params.append('fileSize', fileItem.file.size.toString());
        return this._http.get<boolean>(environment.baseDocumentApiEndpoint + '/document/validate', { params: params });
    }

    uploadDocument(programServiceKey: string, userKey: string, fileName: string, blob: Blob) {
        const input = new FormData();
        input.append('file', blob);
        input.append('fileName', fileName);
        input.append('programServiceKey', programServiceKey);
        input.append('userKey', userKey);
        return this._http.post(environment.baseDocumentApiEndpoint + '/document', input);
    }

    private customHandleError(err: HttpErrorResponse, showDefault = false) {
        if (err.status === 417) {
            // Required Info is missing. Custom exception
            this.showDocumentLoader(true, false, true, this._localizeService.get('preventvoucherdownload', 'error'));
        } else if (showDefault) {
            this.showDocumentLoader(true, false, true, this._localizeService.get('download', 'error'));
        }
        {
            this.handleError(err);
        }
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }

    private showDocumentLoader(doShow: boolean, showspin = true, updateModel = false, msg: string = null, userKey: string = null, programServiceKey: string = null, fileName: string = null) {
        if (doShow) {
            if (!updateModel) {
                this.modalRef = this._modalService.open(LoadDocumentComponent, {
                    backdrop: 'static',
                    windowClass: 'loadingModal'
                });
                this.modalRef.componentInstance.userKey = userKey;
                this.modalRef.componentInstance.programServiceKey = programServiceKey;
                this.modalRef.componentInstance.fileName = fileName;
            }
            if (msg) {
                this.modalRef.componentInstance.msg = msg;
            }
            this.modalRef.componentInstance.showspin = showspin;
        } else {
            this.modalRef.close();
        }
    }
}

import { AbbreviatedUser } from '../../shared/abbreviated-user';

export class SecureMessageAccessRequest {
  key: string;
  requestingUser: AbbreviatedUser;
  requestedUser: AbbreviatedUser;
  status: string;
  requestDate: Date;

  constructor(config) {
    this.key = config.key;
    this.requestDate = config.requestDate;
    this.requestedUser = config.requestedUser;
    this.requestingUser = config.requestingUser;
    this.status = config.status;
  }

  get participant(): string {
    return this.requestedUser ? (this.requestedUser.firstName + ' ' + this.requestedUser.lastName) : undefined;
  }

  get coach(): string {
    return this.requestingUser ? (this.requestingUser.firstName + ' ' + this.requestingUser.lastName) : undefined;
  }

  get company(): string {
    return this.requestedUser && this.requestedUser.companyName ? this.requestedUser.companyName : undefined;
  }
}

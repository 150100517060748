import { DIServiceLocator } from '../../di.service.locator';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Utils } from '../../shared/utils';
import { EventLocation } from './event-location';
import { ProgramServiceAndType } from './program-service-and-type';

export class Appointment {
    key: string;
    eventLocation: EventLocation;
    userKey: string;
    date: Date;
    time: Date;
    eventKey: string;
    programServiceKey: string;
    programServiceTypeKey: string;
    programServiceType: ProgramServiceAndType;
    dateSummaryTimeId: string;
    onBehalfOfFirstName: string;
    onBehalfOfLastName: string;
    participantOrdinal: number;
    fasting: string;
    room: string;
    alreadyTaken = false;
    alreadyHasAppointmentInFuture = false;
    existingAppointmentKey: string;
    hasConflict = false;
    consentNeeded = false;
    localizeService: LocalizeService = DIServiceLocator.injector.get(LocalizeService);
    get fastingToken(): string {
        return Utils.getFastingStateToken(this.fasting);
    }

    get locationText(): string {
        let location = '';

        if (this.eventLocation) {
            if (this.eventLocation.address1) {
                location += this.eventLocation.address1;
            }

            if (this.eventLocation.address2) {
                location += ' ' + this.eventLocation.address2 + '<br />';
            }

            if (this.eventLocation.address1 && !this.eventLocation.address2) {
                location += '<br />';
            }

            if (this.eventLocation.city) {
                location += this.eventLocation.city;
            }

            if (this.eventLocation.state) {
                location += ', ' + this.eventLocation.state;
            }

            if (this.eventLocation.zip) {
                location += ' ' + this.eventLocation.zip;
            }

            if (this.eventLocation.city || this.eventLocation.state || this.eventLocation.zip) {
                location += '<br />';
            }

            if (this.room) {
                location += this.room;
            }
            if (this.eventLocation.closed) {
                location += '<br />' + this.localizeService.getCombinedTag('message.locationclosed');
            }
            if (this.eventLocation.unavailable) {
                location += this.localizeService.getCombinedTag('message.locationunavailable');
            }
        }

        return location;
    }
}

<div class="modal-header">
    <h4 class="modal-title" id="titleId-ResendEmail">{{ 'resendconfirmationemail' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="close" id="actionId-CloseResendEmail" aria-label="Close" (click)="activeModal.close('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="form-group">
        <select (change)="setSelectedOption($event.target.value)" class="form-control">
            <option value="">{{ 'selectconfirmationtype' | lesCustomLocalize : 'label' }}</option>
            <option *ngFor="let confirm of confirmationTypes" value="{{ confirm.value }}">{{ confirm.description }}</option>
        </select>
    </div>

    <div class="alert alert-danger resend-error" *ngIf="showSelectionError">
        <span>{{ 'selectresenderror' | lesCustomLocalize : 'label' }}</span>
    </div>
</div>

<div class="modal-footer pt-10">
    <button type="button" class="btn" id="actionId-CancelResendEmail" (click)="activeModal.close('cancel')">{{ 'resendcancel' | lesCustomLocalize : 'label' }}</button>
    <button type="submit" class="btn btn-primary" id="actionId-SendResendEmail" (click)="resendConfirmationEmail()">{{ 'resendsend' | lesCustomLocalize : 'label' }}</button>
</div>

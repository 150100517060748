<div class="modal-header">
    <h2 class="secondary-text modal-title" id="titleId-LabcorpPatientServiceCenter">{{ 'pscmodalheader' | lesCustomLocalize : 'label' }}</h2>
    <span class="close" (click)="cancelModal(this, true)">
        <i class="fas fa-times"></i>
    </span>
</div>

<div class="modal-body" style="max-height: calc(100vh-250px)" *ngIf="isRenderReady">
    <div *ngIf="ShowChoiceCard">
        <h3 class="tertiary-text" id="messageId-SelectBelow-LabcorpPatientServiceCenter">{{ 'pscmakeselection' | lesCustomLocalize : 'label' }}</h3>

        <div class="list-group">
            <button *ngIf="!UserHasActiveAppointment" id="actionId-MakeAnAppointmentAtLabcorpPatientServiceCenter" (click)="makeAppointmentClick()" type="button" class="list-group-item list-group-item-action">
                {{ 'pscmakeappointment' | lesCustomLocalize : 'label' }}
            </button>
            <button *ngIf="UserHasActiveAppointment" id="actionId-ModifyExistingAppointment" (click)="modifyAppointmentClick()" type="button" class="list-group-item list-group-item-action">
                {{ 'pscmodifyexistingappointment' | lesCustomLocalize : 'label' }}
            </button>
            <button *ngIf="UserHasActiveAppointment" id="actionId-CancelExistingAppointment" (click)="cancelAppointmentClick()" type="button" class="list-group-item list-group-item-action">
                {{ 'psccancelexistingappointment' | lesCustomLocalize : 'label' }}
            </button>
            <button *ngIf="!UserHasActiveAppointment" id="actionId-LocateAppointmentBookedOnLabcorpcom" (click)="locateAppointmentClick()" type="button" class="list-group-item list-group-item-action">
                {{ 'psclocateappointment' | lesCustomLocalize : 'label' }}
            </button>
            <button (click)="downloadRequisitionFormClick()" id="actionId-DownloadTestRequisitionFormOnly" type="button" class="list-group-item list-group-item-action">{{ 'pscdownloadrequisitionform' | lesCustomLocalize : 'label' }}</button>
            <button (click)="emailRequisitionFormClick(true)" id="actionId-EmailTestRequisitionFormOnly" type="button" class="list-group-item list-group-item-action">{{ 'emailrequisitionform' | lesCustomLocalize : 'label' }}</button>
        </div>
    </div>

    <div *ngIf="ShowFindLocation">
        <h3 class="tertiary-text" id="messageId-MakeAnAppointmentAtLabcorpLab">{{ 'makeanappointmentatlab' | lesCustomLocalize : 'message' }}</h3>
        <form autocomplete="off" id="formId-Appointment-LabcorpPatientServiceCenter">
            <div [formGroup]="searchForm">
                <div class="row mb-2">
                    <div class="col-sm-2">
                        <button (click)="locateMeClick()" id="actionId-LocateMeLabcorpPatientServiceCenter" [ngClass]="isMobile ? '' : 'pull-right'" class="tertiary-bg btn btn-primary">
                            <i class="fa fa-location-arrow" aria-hidden="true"></i> {{ 'locateme' | lesCustomLocalize : 'label' }}
                        </button>
                    </div>
                    <div class="col-sm-5">
                        <app-addressautocomplete [settings]="labcorpLocationAutocompleteSettings" formControlName="locationQuery"></app-addressautocomplete>
                    </div>
                    <div class="col-sm-2">
                        <select formControlName="locationRadius" class="form-control">
                            <option value="10">10 {{ 'miles' | lesCustomLocalize : 'label' }}</option>
                            <option value="25">25 {{ 'miles' | lesCustomLocalize : 'label' }}</option>
                            <option value="50">50 {{ 'miles' | lesCustomLocalize : 'label' }}</option>
                            <option value="100">100 {{ 'miles' | lesCustomLocalize : 'label' }}</option>
                        </select>
                    </div>
                    <div [class]="isMobile ? 'col-sm-2' : 'col-sm-1'">
                        <button (click)="searchLocations()" class="tertiary-bg btn btn-primary" id="actionId-SearchLabcorpPatientServiceCenter">{{ 'search' | lesCustomLocalize : 'label' }}</button>
                    </div>
                    <div *ngIf="!isMobile" class="col-sm-2 pull-right">
                        <div class="pull-right ms-2">
                            <a title="Desktop" id="actionId-DesktopLabcorpPatientServiceCenter" (click)="toggleMap(true, true)"
                                ><span [ngClass]="ShowLocationMap ? 'lightblue' : ''" class="material-icons" style="cursor: pointer">vertical_split</span></a
                            >
                        </div>
                        <div class="pull-right ms-2">
                            <a title="Mobile" id="actionId-MobileLabcorpPatientServiceCenter" (click)="toggleMap(false, isMobile === false)"
                                ><span [ngClass]="!ShowLocationMap ? 'lightblue' : ''" class="material-icons" style="cursor: pointer">table_rows</span></a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="ShowLocationsList && locationsList.length">
        <div class="row psc-row-height">
            <div [class]="ShowLocationMap ? 'col-sm-6' : 'col-sm-12'" [ngClass]="isMobile ? '' : 'psc-result-height'">
                <div class="row">
                    <div class="col-sm-12">
                        <hr class="psc-result-divider" />
                    </div>
                </div>
                <ng-container *ngFor="let location of locationsList; let i = index">
                    <div [ngClass]="selectedLabLocation !== null && location.id === selectedLabLocation.id ? 'border border-primary rounded' : ''" class="row pt-1">
                        <div class="col-sm-6">
                            <ul class="list-unstyled psc-address">
                                <li>{{ location.address1 }}</li>
                                <li *ngIf="location.address2">{{ location.address2 }}</li>
                                <li>{{ location.city }}, {{ location.stateProvince }} {{ location.postalCode }}</li>
                            </ul>
                        </div>
                        <div class="col-sm-4 mx-auto">
                            <p class="tertiary-text card-text-center">{{ location.distanceFromStartingPoint | number : '1.0-2' }} mi</p>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn tertiary-bg btn-primary pull-right" type="button" (click)="selectLocation(location.id)">
                                {{ 'select' | lesCustomLocalize : 'label' }}
                            </button>
                        </div>
                        <div class="col-sm-12 accordion" id="{{ 'a-' + location.id }}">
                            <div class="row">
                                <div class="col-sm-12">
                                    <button
                                        type="button"
                                        class="btn btn-link"
                                        id="actionId-MoreDetailsLabcorpPatientServiceCenter"
                                        (click)="showLocationDetails[location.id] = !showLocationDetails[location.id]"
                                        [attr.aria-expanded]="showLocationDetails[location.id]"
                                        attr.aria-controls="{{ 'dt-' + location.id }}"
                                    >
                                        {{ 'moredetails' | lesCustomLocalize : 'label' }}
                                    </button>
                                </div>
                                <div id="{{ 'dt-' + location.id }}" [ngbCollapse]="!showLocationDetails[location.id]" class="col-sm-12">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-sm-12 font-weight-bold">{{ 'hours' | lesCustomLocalize : 'label' }}:</div>
                                        </div>
                                        <div *ngFor="let hour of location.serviceHours" class="row">
                                            <div class="col-sm-6">{{ hour.openingTime | date : 'shortTime' }}-{{ hour.closingTime | date : 'shortTime' }}</div>
                                            <div class="col-sm-6">
                                                <ul class="list-inline">
                                                    <li class="list-inline-item" *ngFor="let day of hour.daysOfWeek">
                                                        {{ day | titlecase }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 font-weight-bold">{{ 'contact' | lesCustomLocalize : 'label' }}:</div>
                                        </div>
                                        <div class="row" *ngFor="let phoneType of location.contactNumbers">
                                            <div class="col-sm-6">{{ phoneType.label | titlecase }}:</div>
                                            <div class="col-sm-6">
                                                {{ phoneType.value }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <hr class="psc-result-divider" />
                        </div>
                    </div>
                </ng-container>
            </div>

            <div [style.display]="ShowLocationMap ? 'block' : 'hidden'" [class]="ShowLocationMap ? 'col-sm-6' : ''">
                <div id="googleMap"></div>
            </div>

            <div *ngIf="allLocationsList.length > pageSize" [ngClass]="ShowLocationMap ? 'col-sm-6' : 'col-sm-12'" class="pager">
                <ngb-pagination [collectionSize]="allLocationsList.length" [(page)]="currentPage" [maxSize]="maxPagesSize" [pageSize]="pageSize" [rotate]="true" [boundaryLinks]="true" (pageChange)="onLocationListPageChange($event)"></ngb-pagination>
            </div>
        </div>
    </div>

    <div *ngIf="ShowAppointmentDetailsCard">
        <h3 class="tertiary-text" id="titleId-AppointmentLocatedLabcorpPatientServiceCenter" *ngIf="isAppointmentAssociation">
            {{ 'appointmentlocated' | lesCustomLocalize : 'message' }}
        </h3>

        <p *ngIf="useModifyFlow === true">{{ 'modifyappointment' | lesCustomLocalize : 'message' }}</p>
        <p class="primary-text">{{ 'pscappointmentdetails' | lesCustomLocalize : 'label' }}</p>

        <div class="row">
            <div class="col-sm-12">
                <p class="tertiary-text mb-0">{{ 'psclabaddress' | lesCustomLocalize : 'label' }}</p>
            </div>
            <div class="col-sm-11">
                <ul class="list-unstyled">
                    <li>{{ selectedLabLocation.address1 }}</li>
                    <li *ngIf="selectedLabLocation.address2">{{ selectedLabLocation.address2 ? selectedLabLocation.address2 : '' }}</li>
                    <li>{{ selectedLabLocation.city }}, {{ selectedLabLocation.stateProvince }} {{ selectedLabLocation.postalCode }}</li>
                </ul>
            </div>
            <div class="col-sm-1">
                <a *ngIf="AllowAppointmentEdit === true" title="Edit" id="actionId-EditLabcorpPatientServiceCenter" (click)="editLabLocationClick()" style="cursor: pointer">
                    <i class="fas fa-edit"></i>
                </a>
            </div>
        </div>

        <div *ngIf="isTimeSlotSelected" class="row">
            <div class="col-sm-12">
                <p class="tertiary-text mb-0">{{ 'pscdatetime' | lesCustomLocalize : 'label' }}</p>
            </div>
            <div class="col-sm-11 mb-0">
                <p>
                    <small>{{ requestedAppointment.appointmentTime | date : 'fullDate' }} at {{ requestedAppointment.appointmentTime | date : 'shortTime' }}</small>
                </p>
            </div>
            <div class="col-sm-1">
                <a *ngIf="AllowAppointmentEdit === true" id="actionId-EditTimeSlotLabcorpPatientServiceCenter" title="Edit" (click)="goToTimeSlotList()" style="cursor: pointer">
                    <i class="fas fa-edit"></i>
                </a>
            </div>
        </div>
        <hr />
    </div>

    <div *ngIf="ShowTimeSlotList">
        <div [formGroup]="slotFilterForm">
            <div class="row mb-5">
                <div class="col-sm-10">
                    <input
                        type="text"
                        id="appointmentDate"
                        name="appointmentDate"
                        formControlName="appointmentDate"
                        class="form-control input-lg"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [markDisabled]="isDisabled"
                        (ngModelChange)="onTimeSlotDateSelected($event)"
                        (blur)="onTimeSlotDateSelected()"
                        required
                    />
                </div>
                <div class="col-sm-2">
                    <a title="Toggle" (click)="d.toggle()"><i class="fa fa-calendar" aria-hidden="true"></i></a>
                </div>
            </div>
        </div>

        <div *ngIf="ShowNoSlotsMessage" class="row">
            <div class="col-sm-12 card card-text-center">
                {{ 'noappointmentsavailable' | lesCustomLocalize : 'message' }}
            </div>
        </div>

        <div *ngFor="let timeSlot of displayTimeSlotsList; let i = index">
            <div *ngIf="i % 4 === 0" class="row">
                <div class="col-sm-3 mb-1">
                    <button
                        *ngIf="i < displayTimeSlotsList.length"
                        class="btn tertiary-bg btn-primary btn-block psc-timeslot"
                        id="actionId-SelectTimeSlotLabcorpPatientServiceCenter"
                        [ngClass]="displayTimeSlotsList[i].Selected ? 'active' : ''"
                        (click)="selectLocationAvailableTimeSlotsClick(displayTimeSlotsList[i].DateTime)"
                    >
                        {{ displayTimeSlotsList[i].DateTime | date : 'shortTime' }}
                    </button>
                </div>

                <div class="col-sm-3 mb-1">
                    <button
                        *ngIf="i + 1 < displayTimeSlotsList.length"
                        class="btn tertiary-bg btn-primary btn-block psc-timeslot"
                        id="actionId-SelectTimeSlotLabcorpPatientServiceCenter"
                        [ngClass]="displayTimeSlotsList[i + 1].Selected ? 'active' : ''"
                        (click)="selectLocationAvailableTimeSlotsClick(displayTimeSlotsList[i + 1].DateTime)"
                    >
                        {{ displayTimeSlotsList[i + 1].DateTime | date : 'shortTime' }}
                    </button>
                </div>

                <div class="col-sm-3 mb-1">
                    <button
                        *ngIf="i + 2 < displayTimeSlotsList.length"
                        class="btn tertiary-bg btn-primary btn-block psc-timeslot"
                        id="actionId-SelectTimeSlotLabcorpPatientServiceCenter"
                        [ngClass]="displayTimeSlotsList[i + 2].Selected ? 'active' : ''"
                        (click)="selectLocationAvailableTimeSlotsClick(displayTimeSlotsList[i + 2].DateTime)"
                    >
                        {{ displayTimeSlotsList[i + 2].DateTime | date : 'shortTime' }}
                    </button>
                </div>

                <div class="col-sm-3 mb-1">
                    <button
                        *ngIf="i + 3 < displayTimeSlotsList.length"
                        class="btn tertiary-bg btn-primary btn-block psc-timeslot"
                        id="actionId-SelectTimeSlotLabcorpPatientServiceCenter"
                        [ngClass]="displayTimeSlotsList[i + 3].Selected ? 'active' : ''"
                        (click)="selectLocationAvailableTimeSlotsClick(displayTimeSlotsList[i + 3].DateTime)"
                    >
                        {{ displayTimeSlotsList[i + 3].DateTime | date : 'shortTime' }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="ShowAppointmentRequestForm">
        <p class="primary-text">{{ 'psccontactinformation' | lesCustomLocalize : 'label' }}</p>
        <form autocomplete="off" id="formId-ContactInformation-LabcorpPatientServiceCenter">
            <div [formGroup]="contactForm" class="form-group">
                <div [hidden]="isAppointmentModification" class="row">
                    <div class="col-sm-12 mb-4">
                        <label for="address">{{ 'address' | lesCustomLocalize : 'label' }}</label>
                        <app-addressautocomplete [settings]="patientAutocompleteSettings" formControlName="patientAutocompleteQuery" (change)="checkAddressInputValidity()"></app-addressautocomplete>
                    </div>
                </div>

                <div [hidden]="isAppointmentModification" class="row">
                    <div class="col-sm-4 mb-4">
                        <label for="city">{{ 'city' | lesCustomLocalize : 'label' }}</label>
                        <input type="text" class="form-control" name="city" id="city" required formControlName="city" />
                    </div>
                    <div [hidden]="isAppointmentModification" class="col-sm-4" mb-4>
                        <label for="stateKey">{{ 'state' | lesCustomLocalize : 'label' }}</label>
                        <select name="stateKey" id="stateKey" required class="form-control" formControlName="stateKey">
                            <option value="null">{{ 'noneselected' | lesCustomLocalize : 'label' }}</option>
                            <option *ngFor="let state of statesList" [value]="state.abbreviation">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                    <div [hidden]="isAppointmentModification" class="col-sm-4 mb-4">
                        <label for="zipCode">{{ 'zipcode' | lesCustomLocalize : 'label' }}</label>
                        <input type="text" maxlength="5" pattern="\d*" class="form-control" name="zipCode" id="zipCode" required formControlName="zipCode" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <label for="email">{{ 'emailaddress' | lesCustomLocalize : 'label' }}</label>
                        <input type="email" class="form-control" name="email" id="email" required email formControlName="email" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <label for="phone">{{ 'mobilephonepreferred' | lesCustomLocalize : 'label' }}</label>
                        <input type="tel" class="form-control" name="phone" id="phone" formControlName="phone" (change)="editContactPhoneChange($event)" [required]="useGicFlow" />
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mb-1">
                            <div class="form-check">
                                <label class="form-check-label ms-3">
                                    <input class="form-check-input" type="checkbox" name="smsApproved" id="smsApproved" formControlName="smsApproved" />
                                    {{ 'receivesms' | lesCustomLocalize : 'label' }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 mb-1 small">
                        <p class="small pb-1"><span [innerHTML]="'pscappointmenttextmessage' | lesCustomLocalize : 'message'"></span></p>
                    </div>
                </div>
                <div *ngIf="useGicFlow" class="row">
                    <div class="col-sm-12 mb-1">
                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" name="receivePaperCommunications" id="receivePaperCommunications" formControlName="receivePaperCommunications" />
                                {{ 'receivepapercomm' | lesCustomLocalize : 'label' }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-1 small">
                        <p *ngIf="!isContactFormValid() || !isValidAddress" class="text-danger">{{ 'forminvalid' | lesCustomLocalize : 'message' }}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="useModifyFlow" class="row">
                <div class="col-sm-12">
                    <p>{{ 'confirmappointmentupdate' | lesCustomLocalize : 'message' }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <button type="button" class="btn btn-block btn-primary" id="psc-BookAppointment" (click)="bookAppointment()" [disabled]="isSubmitting || !isContactFormValid() || !isValidAddress">{{ 'continue' | lesCustomLocalize : 'label' }}</button>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="ShowCancelConfirm">
        <div class="row">
            <div class="col-sm-12">
                <p class="text-danger text-center">{{ 'areyousureyouwanttocancel' | lesCustomLocalize : 'message' }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <button type="button" class="btn btn-block btn-primary" id="actionId-PSCCancelAppointment" (click)="cancelAppointment()">{{ 'continue' | lesCustomLocalize : 'label' }}</button>
            </div>
        </div>
    </div>

    <div *ngIf="ShowBookingConfirmation">
        <div class="row">
            <div class="col-sm-12 mb-2">
                <p>{{ 'appointmentconfirmation1' | lesCustomLocalize : 'message' }}</p>
            </div>
            <div class="col-sm-12" *ngIf="!useGicFlow">
                <p>{{ 'appointmentconfirmation2' | lesCustomLocalize : 'message' }}</p>
            </div>
            <div class="col-sm-12" *ngIf="useGicFlow">
                <p>{{ 'appointmentconfirmation3' | lesCustomLocalize : 'message' }}</p>
            </div>
        </div>

        <div class="row mb-4" *ngIf="!useGicFlow">
            <div class="col-sm-12">
                <button class="btn btn-block btn-primary" id="actionId-DownloadFormLabcorpPatientServiceCenter" type="button" [disabled]="didDownloadDocument" (click)="downloadRequisitionFormClick()">
                    {{ 'downloadform' | lesCustomLocalize : 'label' }}
                </button>
            </div>
            <div class="col-sm-12 secondary-text" *ngIf="didDownloadDocument">
                {{ 'pscrequisitionformdownloading' | lesCustomLocalize : 'message' }}
            </div>
        </div>

        <div class="row" *ngIf="!useGicFlow">
            <div class="col-sm-12">
                <button class="btn btn-block btn-primary" id="actionId-EmailFormLabcorpPatientServiceCenter" type="button" [disabled]="didSendEmailAttachment" (click)="emailRequisitionFormClick()">
                    {{ 'emailform' | lesCustomLocalize : 'label' }}
                </button>
            </div>
            <div class="col-sm-12 secondary-text" *ngIf="didSendEmailAttachment">
                {{ 'pscrequisitionformattached' | lesCustomLocalize : 'message' }}
            </div>
        </div>
        <div class="row" *ngIf="useGicFlow">
            <div class="col-sm-12">
                <button class="btn btn-block btn-primary" id="actionId-NoEmailFormLabcorpPatientServiceCenter" type="button" (click)="cancelModal(this, true)">{{ 'close' | localize : 'label' }}</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="ShowLocateAppointmentForm">
    <h3 class="primary-text pl-3">{{ 'psclocateappointmentprimary' | lesCustomLocalize : 'message' }}</h3>
    <hr />
    <div class="row">
        <div class="col-sm-12">
            <p class="text-center">{{ 'psclocateappointmentsecondary' | lesCustomLocalize : 'message' }}</p>
        </div>
    </div>

    <div *ngIf="ShowAppointmentNotLocated">
        <div class="row">
            <div class="col-sm-12">
                <p class="text-danger text-center">{{ 'pscappointmentnotlocated' | lesCustomLocalize : 'message' }}</p>
            </div>
        </div>
    </div>

    <div [formGroup]="locateAppointmentForm" class="form-group">
        <div class="row p-2">
            <div class="col-sm-12 mb-4">
                <label for="firstName">{{ 'firstname' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="firstName" id="firstName" required formControlName="firstName" />
            </div>
            <div class="col-sm-12 mb-4">
                <label for="lastName">{{ 'lastname' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="lastName" id="lastName" required formControlName="lastName" />
            </div>
        </div>
        <div class="col-sm-12 mb-4">
            <label for="confirmationNumber">{{ 'confirmationnumber' | lesCustomLocalize : 'label' }}</label>
            <input type="text" class="form-control" name="confirmationNumber" id="confirmationNumber" required formControlName="confirmationNumber" />
        </div>

        <div class="col-sm-12 mb-4" style="text-align: right">
            <button type="button" class="tertiary-bg btn btn-primary" id="psc-LocateAppointment" (click)="locateAppointment()" [disabled]="isSubmitting || locateAppointmentForm.valid == false">
                {{ 'submit' | lesCustomLocalize : 'label' }}
            </button>
        </div>
    </div>
</div>
<div *ngIf="showAddAppointment">
    <div class="row mb-4 p-1">
        <div class="col-sm-12">
            <button class="btn btn-block btn-primary" id="actionId-PSCAddAppointment" type="button" (click)="addAppointment()">{{ 'confirm' | lesCustomLocalize : 'label' }}</button>
        </div>
    </div>
</div>
<div *ngIf="ShowAppointmentLocated">
    <div class="row">
        <div class="secondary-text col-sm-12">
            {{ 'appointmentlocated' | lesCustomLocalize : 'message' }}
        </div>
    </div>
</div>

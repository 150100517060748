import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { BootstrapSize, SizeCheckerService } from '../../shared/size-checker/size-checker.service';
import { LocalizeService } from '../localize/localize.service';
import { Person } from '../person/person';
import { ColumnSetting, DoShowFunction } from './column-setting';

@Component({
  selector: 'app-wcs-table',
  templateUrl: './wcs-table.component.html',
  styleUrls: ['./wcs-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WcsTableComponent implements OnInit, OnDestroy {
  private _settings: ColumnSetting[];
  private _records: any[];
  private _turnOffChangeDetection = false;

  get settings(): ColumnSetting[] {
    return this._settings;
  }

  maxSize = 5;
  _currentPage = 1;

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(val: number) {
    this._currentPage = val;

    if (this._turnOffChangeDetection) {
      this._cdr.detectChanges();
    }
  }

  subscriptions: ISubscription[] = [];
  readyForRender = false;
  currentSize: BootstrapSize;
  bootstrapSize = BootstrapSize;
  defaultSortApplied = false;

  @Input() currentUser: Person;
  @Input() set settings(val: ColumnSetting[]) {
    this._settings = val;
    this.performDefaultSort();
  }
  @Input() pageSize = 5;
  @Input() htmlId = "Unknown";
  @Input() set records(val: any[]) {
    if (val && typeof (val.length) !== 'undefined') {
      this.isLoading = false;
      this._records = val;

      this.performDefaultSort();

      if (this._turnOffChangeDetection) {
        this._cdr.detach();
        this._cdr.detectChanges();
      }
    }
  }

  get records(): any[] {
    return this._records;
  }

  @Input() set turnOffChangeDetection(val: boolean) {
    this._turnOffChangeDetection = val;
  }

  _currentSortProp: string;

  @Input() set currentSortProp(val: string) {
    this._currentSortProp = val;
    this.performDefaultSort();
  }

  get currentSortProp(): string {
    return this._currentSortProp;
  }

  _currentSortDirection: string;

  @Input() set currentSortDirection(val: string) {
    this._currentSortDirection = val;
    this.performDefaultSort();
  }

  get currentSortDirection(): string {
    return this._currentSortDirection;
  }

  @Input() isLoading = true;
  @Input() isError = false;
  @Input() useBootstrapResponsiveTable = true;
  @Input() maintainPageSize = true;

  constructor(private _localizeService: LocalizeService, private _sizeCheckerService: SizeCheckerService,
    private _cdr: ChangeDetectorRef) { }

  performDefaultSort() {
    if (this.currentSortProp && this.currentSortDirection && this.settings && this.settings.length
      && this.records && this.records.length && !this.defaultSortApplied) {
      for (let i = 0; i < this.settings.length; i++) {
        if (this.settings[i].valueProp === this.currentSortProp) {
          this.sort(this.settings[i], this.currentSortDirection === 'asc');
          this.defaultSortApplied = true;
          break;
        }
      }
    }
  }

  detectChanges(gotoFirstPage = false) {
    this._cdr.detectChanges();

    if (gotoFirstPage) {
      this._currentPage = 1;
    }
  }

  get recordsToShow(): any[] {
    if (this.currentSize === BootstrapSize.xs) {
      return this._records;
    }

    if (this._records) {
      const lastPage = Math.ceil(this._records.length / this.pageSize);
      const begin = this.currentPage === 1 ? 0 : ((this.currentPage - 1) * this.pageSize);
      const end = this.currentPage === lastPage ? (this._records.length + 1) : (this.currentPage * this.pageSize);
      const recs = this._records.slice(begin, end);

      if (this.maintainPageSize) {
        while (recs.length < this.pageSize && this.currentPage === lastPage && this.currentPage !== 1) {
          recs.push({});
        }
      }

      return recs;
    }
  }

  getMapForValue(map: ColumnSetting, record: any): ColumnSetting[] {
    if (this.isEmpty(record) || this.evaluateColumnSetting(map, record)) {
      return [map];
    } else if (map.alternativeVals && map.alternativeVals.length) {
      let valMap;

      for (let i = 0; i < map.alternativeVals.length; i++) {
        if (this.evaluateColumnSetting(map.alternativeVals[i], record)) {
          valMap = map.alternativeVals[i];
          break;
        }
      }

      return valMap ? [valMap] : [map];
    }
  }

  doShowColumn(map: ColumnSetting) {
    return this.evaluateDoShowFns(map.doShowFns, map.thisContext);
  }

  onClick(fn: Function, context: any, record: any) {
    fn.call(context, record);
  }

  isEmpty(obj: object) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }
    return true;
  }

  sortXs() {
    for (let i = 0; i < this.settings.length; i++) {
      if (this.settings[i].valueProp === this.currentSortProp && this.settings[i].sortable) {
        this.sort(this.settings[i]);

        if (this.currentSortDirection === 'desc') {
          this.records.reverse();
        }
        break;
      }
    }
  }

  sortClick(map: ColumnSetting, isDefault = false) {
    if (map.sortable) {
      if (this.currentSortProp && this.currentSortProp === map.valueProp) {
        this.records.reverse();
        this.currentSortDirection = this.currentSortDirection === 'asc' ?
          'desc' : 'asc';

        if (this._turnOffChangeDetection) {
          this._cdr.detectChanges();
        }

        return;
      }

      this.sort(map);

      this.currentSortProp = map.valueProp;
      this.currentSortDirection = 'asc';

      if (this._turnOffChangeDetection) {
        this._cdr.detectChanges();
      }
    }
  }

  private sort(map: ColumnSetting, isAscending = true) {
    if (map.format === 'datetime' || map.format === 'dateasis') {
      this.records.sort(function (a, b) {
        return new Date(a[map.valueProp]).getTime() - new Date(b[map.valueProp]).getTime();
      });

      if (!isAscending) {
        this.records.reverse();
      }
    } else {
      this.records.sort(function (a, b) {
        let aVal;
        let bVal;

        if (map.format === 'boolean') {
          aVal = (a[map.valueProp] === true || a[map.valueProp] === 1) ? 'Y' : 'N';
          bVal = (b[map.valueProp] === true || b[map.valueProp] === 1) ? 'Y' : 'N';
        } else if (map.alternateSortProp) {
          aVal = a[map.alternateSortProp] ? a[map.alternateSortProp] : '';
          bVal = b[map.alternateSortProp] ? b[map.alternateSortProp] : '';
        } else {
          aVal = a[map.valueProp] ? a[map.valueProp] : '';
          bVal = b[map.valueProp] ? b[map.valueProp] : '';
        }

        aVal = aVal ? aVal.toUpperCase() : aVal;
        bVal = bVal ? bVal.toUpperCase() : bVal;

        if (aVal < bVal) {
          return isAscending ? -1 : 1;
        } else if (aVal > bVal) {
          return isAscending ? 1 : -1;
        } else {
          return 0;
        }
      });
    }
  }

  private evaluateColumnSetting(map: ColumnSetting, record: any) {
    // If the column isn't shown then dont show the value...
    if (!this.evaluateDoShowFns(map.doShowFns, map.thisContext, record)) {
      return false;
    } else {
      let doShow = true;

      if (doShow && map.doShowValueIfPropertyExists && map.doShowValueIfPropertyExists.length) {
        doShow = this.evaluatePropertyExists(record, map.doShowValueIfPropertyExists, true);
      }

      if (doShow && map.doShowValueIfPropertyNotExists && map.doShowValueIfPropertyNotExists.length) {
        doShow = this.evaluatePropertyExists(record, map.doShowValueIfPropertyExists, false);
      }

      if (doShow && map.doShowValueFns && map.doShowValueFns.length) {
        doShow = this.evaluateDoShowFns(map.doShowValueFns, map.thisContext, record);
      }

      return doShow;
    }
  }

  private evaluatePropertyExists(record: any, props: string[], doesExist: boolean) {
    if (props && props.length) {
      let doShow = true;

      for (let i = 0; i < props.length; i++) {
        doShow = typeof record[props[i]] !== 'undefined' && record[props[i]] !== null && doesExist;
        if (!doShow) {
          break;
        }
      }

      return doShow;
    } else {
      return true;
    }
  }

  private evaluateDoShowFns(doShowFns: DoShowFunction[], thisContext: any, record?: any) {
    if (doShowFns && doShowFns.length) {
      let doShow = true;

      for (let i = 0; i < doShowFns.length; i++) {
        doShow = doShowFns[i].val === doShowFns[i].fn.call(thisContext, record);
        if (!doShow) {
          break;
        }
      }

      return doShow;
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.readyForRender = true;
      }
    }));

    this.subscriptions.push(this._sizeCheckerService.currentSize.subscribe(currentSize => {
      this.currentSize = currentSize;
    }));
  }



  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

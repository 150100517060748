import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../../shared/localize/localize.service';

@Component({
  selector: 'app-cancel-existing-and-add-new-confirm',
  templateUrl: './cancel-existing-and-add-new-confirm.component.html',
  styleUrls: ['./cancel-existing-and-add-new-confirm.component.css']
})
export class CancelExistingAndAddNewConfirmComponent implements OnInit {
  subscriptions: ISubscription[] = [];

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService) {
  }

  confirmAction() {
    this.activeModal.close('confirmed');
  }

  ngOnInit() {
  }
}

<div *ngIf="readyForRender">
    <h1 class="primary-text" id="titleId-CoachingAdminDashboard">{{ 'coachingadmindashboard' | lesCustomLocalize : 'label' }}</h1>
    <ng-container *ngIf="currentUser">
        <app-person-blurb [person]="currentUser" [htmlId]="'User'" [headinglesCustomLocalizeProp]="'user'"></app-person-blurb>
    </ng-container>

    <div class="container-fluid">
        <div class="row d-lg-none">
            <div class="col text-center pb-3">
                <div class="btn-group" role="group" id="actionId-CoachingInfoChoices">
                    <input type="radio" [value]="sectionValues.Coaches" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="{{ 'sectionToShow_' + sectionValues.Coaches }}" />
                    <label class="btn btn-primary" id="actionId-Coaches" for="{{ 'sectionToShow_' + sectionValues.Coaches }}"> {{ 'coaches' | lesCustomLocalize : 'label' }} </label>
                    <input type="radio" [value]="sectionValues.Appointments" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="{{ 'sectionToShow_' + sectionValues.Appointments }}" />
                    <label class="btn btn-primary" id="actionId-Appointments" for="{{ 'sectionToShow_' + sectionValues.Appointments }}"> {{ 'appointments' | lesCustomLocalize : 'label' }} </label>
                    <input type="radio" [value]="sectionValues.AllAccess" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="{{ 'sectionToShow_' + sectionValues.AllAccess }}" />
                    <label class="btn btn-primary" id="actionId-AllAccess" for="{{ 'sectionToShow_' + sectionValues.AllAccess }}"> {{ 'allaccess' | lesCustomLocalize : 'label' }} </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{ section: true, 'col-md-12': true, 'col-lg-6': true, 'd-none d-lg-block': sectionToShow !== sectionValues.Coaches }">
                <app-coach-list></app-coach-list>
            </div>
            <div [ngClass]="{ 'col-md-12': true, 'col-lg-6': true }">
                <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.Appointments }">
                    <app-coaching-appointment-list [mode]="allAccessMode"></app-coaching-appointment-list>
                </div>
                <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.AllAccess }">
                    <app-secure-message-access-request-list-component [mode]="allAccessMode"></app-secure-message-access-request-list-component>
                </div>
            </div>
        </div>
    </div>
</div>


import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import _ from "lodash";
import { combineLatest as observableCombineLatest, ReplaySubject, throwError as observableThrowError } from 'rxjs';
import { map as observableMap } from 'rxjs/operators';

import { environment } from "../../environments/environment";
import { LocalizeService } from "../shared/localize/localize.service";
import { Person } from "../shared/person/person";
import { DisplayInfo } from "./model/displayInfo";
import displayInfoUI from "./model/displayInfoUI.json";
import {
  ResultDataPointMapping,
  ResultDataPointMappingCollection,
} from "./model/mappings";
import { Result, ResultsContainer, ScreenDataDetail } from "./model/result";
import { ReferenceRange, ResultLookup } from "./model/result-lookup";

@Injectable()
export class ResultsService {
  private resultsSource = new ReplaySubject<ResultsContainer>();
  results = this.resultsSource.asObservable();

  private resultErrorSource = new ReplaySubject<boolean>();
  resultError = this.resultErrorSource.asObservable();

  private lookupSource = new ReplaySubject<ResultLookup>();
  resultLookup = this.lookupSource.asObservable();

  private lookupErrorSource = new ReplaySubject<boolean>();
  resultLookupError = this.lookupErrorSource.asObservable();

  private mappingSource = new ReplaySubject<ResultDataPointMappingCollection>();
  mappingCollection = this.mappingSource.asObservable();

  private riskGenders = ["M", "F"];

  constructor(private _http: HttpClient, private _localizeService: LocalizeService) {
    this.fetchResults();
    this.fetchResultLookup();
  }
  getCombined() {
    return observableCombineLatest([this.results, this.resultLookup]);
  }

  getDisplayInfo(): DisplayInfo[] {
    return displayInfoUI;
  }

  getReferenceRange(person: Person, referenceRanges: ReferenceRange[]) {
    if (!referenceRanges.length) {
      return null;
    }

    const filter = _.filter(referenceRanges, (range: ReferenceRange) => {
      range.riskDetailDisplay = range.riskDisplay;
      let invalid;
      const lang = range.languageCode || 'en-us'
      if (
        lang.toLowerCase() != person.locale.languageLocale.toLowerCase()
      ) {
        invalid = true;
      }
      if (range.ageMax && range.ageMax < person.participant.age) {
        invalid = true;
      }
      if (range.ageMin && range.ageMin > person.participant.age) {
        invalid = true;
      }

      //M or F have specific ranges, otherwise show both ranges
      const personGender = person.gender.slice(0, 1);
      if (range.gender) {
        if (this.riskGenders.indexOf(personGender) >= 0 && range.gender != personGender) {
          invalid = true;
        } else {
          range.riskDetailDisplay = this._localizeService.get("dependsongender", "label");
        }
      }

      if (!invalid) {
        return range;
      }
    });
    return _.orderBy(filter, "sortOrder");
  }

  getRiskReferences(
    person: Person,
    map: ResultDataPointMapping
  ): ReferenceRange[] {
    if (!map.referenceRanges.length) {
      return null;
    }
    const value = map.resultProperty;
    const references = this.getReferenceRange(person, map.referenceRanges);
    const filter = _.filter(references, (range: ReferenceRange) => {
      let invalid;

      if (
        isNaN((value as any)) &&
        (map.resultProperty === range.valueMax || value === range.valueMin)
      ) {
        return range;
      }
      if (!isNaN((value as any))) {
        if (
          !range.fastingState ||
          (range.fastingState && range.fastingState == map.fastingState)
        ) {
          if (!range.valueMax && range.valueMin && value >= range.valueMin) {
            return range;
          }
          if (!range.valueMin && range.valueMax && value <= range.valueMax) {
            return range;
          }
          if (
            range.valueMin &&
            range.valueMax &&
            value >= range.valueMin &&
            value <= range.valueMax
          ) {
            return range;
          }
        }
      }
    });
    return _.orderBy(filter, "severityOrder");
  }
  getHigherRiskReference(
    person: Person,
    map1: ResultDataPointMapping,
    map2: ResultDataPointMapping
  ) {
    const risk1: ReferenceRange = _.first(this.getRiskReferences(person, map1));
    const risk2: ReferenceRange = _.first(this.getRiskReferences(person, map2));
    if (!risk2 || risk1.severityOrder >= risk2.severityOrder) {
      return risk1;
    }
    return risk2;
  }
  getMappings(res: Result, resultLookUp: ResultLookup) {
    const displayInfo = this.getDisplayInfo();
    const mappings: ResultDataPointMapping[] = [];
    if (res) {
      if (res.labCollectionData) {
        this.getMap(
          res.labCollectionData,
          resultLookUp,
          displayInfo,
          mappings,
          res.screenDataDetails
        );
      }
      if (res.screenData) {
        this.getMap(
          res.screenData,
          resultLookUp,
          displayInfo,
          mappings,
          res.screenDataDetails
        );
      }
      if (res.riskStratification) {
        this.getMap(
          res.riskStratification,
          resultLookUp,
          displayInfo,
          mappings,
          res.screenDataDetails
        );
      }
    }
    _.forEach(mappings, (map: ResultDataPointMapping) => {
      map.fastingState = res.screenData.fastingState as any;
      map.serviceDate = res.screenData.dateofService ? new Date(res.screenData.dateofService) : null;
    });
    return mappings;
  }
  addNote(map: ResultDataPointMapping, screenDataDetail: ScreenDataDetail) {
    if (screenDataDetail && screenDataDetail.notes) {
      map.displayInfo["notes"] = screenDataDetail.notes;
    }
  }
  getMap(
    resultData,
    lookUps: ResultLookup,
    displayInfo: DisplayInfo[],
    mappings,
    details: ScreenDataDetail[] = null
  ) {
    const isPregnant = 'Pregnant';
    const _withUnderscore = ['fiT_OccultBlood_Fecal', 'Albumin_Urine', 'Creatinine_Urine', 'ProteinTotal_Urine']
    for (const property in resultData) {
      let references = null;
      if (property.toLowerCase() == isPregnant.toLocaleLowerCase()) {
        references = _.filter(lookUps.referenceRanges, (reference) => {
          return (
            reference.fieldName.toLowerCase() ==
            property.toLowerCase()
          );
        });
      } else if (_.some(_withUnderscore, function (d) { return d.toLowerCase() == property.toLowerCase() })) {
        references = _.filter(lookUps.referenceRanges, (reference) => {
          return (
            reference.fieldName.toLowerCase().slice(4) ==
            property.toLowerCase()
          );
        });
      } else {
        references = _.filter(lookUps.referenceRanges, (reference) => {
          return (
            reference.fieldName.toLowerCase().slice(4).replace("_", "") ==
            property.toLowerCase()
          );
        });
      }

      let reference: ReferenceRange;
      if (references || references.length) {
        if (references.length > 0 && resultData[property] != null) {
          reference = _.head(references);
          const resultMap = new ResultDataPointMapping();
          resultMap.resultProperty = resultData[property];
          resultMap.referenceRanges = [];
          _.forEach(references, (refererence: ReferenceRange) => {
            resultMap.referenceRanges.push(refererence);
          });
          resultMap.displayInfo = _.findLast(displayInfo, (info: DisplayInfo) => {
            return info.fieldName == reference.fieldName;
          });

          if (reference.fieldName.toLowerCase() == isPregnant.toLowerCase()) {
            resultMap.fieldName = isPregnant;
            resultMap.displayInfo["localizedBlurb"] =
              "blurb" + isPregnant;
            resultMap.displayInfo["localizedName"] =
              "result" + isPregnant;
          } else {
            resultMap.fieldName = reference.fieldName.slice(4).replace("_", "");
            resultMap.displayInfo["localizedBlurb"] =
              "blurb" + resultMap.displayInfo.fieldName.slice(4);
            resultMap.displayInfo["localizedName"] =
              "result" + resultMap.displayInfo.fieldName.slice(4);
          }
          //add notes from screendatadetail based on fieldName
          resultMap.displayInfo["notes"] = this.getDetail(
            resultMap.displayInfo.fieldName,
            details
          );
          mappings.push(resultMap);
        }
      }
    }
  }
  getDetail(fieldName: string, details: ScreenDataDetail[]) {
    if (fieldName && details && details.length) {
      const detail = details.find((d: ScreenDataDetail) => {
        return d.columnMatch && d.columnMatch.toLowerCase() == fieldName.toLowerCase();
      });
      if (detail) return detail.notes;
    }
    return "";
  }

  fetchResults() {
    return this._http
      .get<ResultsContainer>(environment.baseSchedulerEndpoint + "/results").pipe(
        observableMap((rc: ResultsContainer) => {
          rc.results = rc.results.map((r) => {
            return new Result(r);
          });
          return rc;
        }))
      .subscribe(
        (data) => {
          data.results.sort(function (a, b) {
            return +new Date(a.resultDate) - +new Date(b.resultDate);
          });

          this.resultsSource.next(data);
        },
        (err) => {
          this.resultErrorSource.next(true);
        }
      );
  }

  fetchResultLookup() {
    this._http
      .get<ResultLookup>(environment.baseSchedulerEndpoint + "/resultsLookup")
      .subscribe(
        (data) => {
          const lookup = data;
          this.lookupSource.next(data);
        },
        (err) => {
          this.lookupErrorSource.next(true);
        }
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}

<div class="profile" *ngIf="readyForRender">
    <div class="container-fluid">
        <h1 class="primary-text" id="titleId-MyProfile">{{ 'myprofile' | lesCustomLocalize : 'label' }}</h1>
        <p>{{ 'myprofileblurb' | lesCustomLocalize : 'message' }}</p>

        <h4 class="primary-text" id="titleId-MyProfileDetails">
            {{ 'details' | lesCustomLocalize : 'label' }}
            <a href="javascript:void(0)" *ngIf="!userInformationEditToggle" id="actionId-UserInformationEdit" (click)="toggleUserInformationEdit()" title="{{ userInformationEditToggleLabel }}"><i class="fa fa-edit fa-1x" aria-hidden="true"></i></a>
        </h4>

        <div class="col-sm-12 information-text" *ngIf="!profile.isNoEligibility">
            {{ 'userprofilemessage' | lesCustomLocalize : 'message' }}
        </div>
        <div class="col-sm-12 information-text" *ngIf="profile.isNoEligibility">
            {{ 'userprofileeditmessage' | lesCustomLocalize : 'message' }}
        </div>

        <div class="row box-bordered">
            <div class="col-sm-12" *ngIf="!userInformationEditToggle">
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'firstname' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ profile.firstName }}</div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'lastname' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ profile.lastName }}</div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'emailaddress' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ profile.email }}</div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'dateofbirth' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ profile.dateOfBirth | amDateFormat : 'M/D/YYYY' }}
                    </div>
                </div>
                <div class="row mb-xs-3 mb-sm-2" *ngIf="profile.isNoEligibility">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'employeeid' | lesCustomLocalize : 'login' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ profile.employeeID }}</div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'gender' | lesCustomLocalize : 'login' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ getGenderDescription(profile.genderKey) }}</div>
                </div>
            </div>

            <form [formGroup]="userInformationForm" id="formId-MyProfile" *ngIf="userInformationEditToggle" (ngSubmit)="saveUserInformation()" novalidate>
                <div class="col-sm-12">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <h5 class="secondary-text">
                                {{ 'firstname' | lesCustomLocalize : 'label' }}
                            </h5>
                            <input type="text" class="form-control form-section-md" formControlName="firstName" required [readonly]="!profile.isNoEligibility" placeholder="{{ 'firstname' | lesCustomLocalize : 'label' }}" />
                            <div class="alert alert-danger" *ngIf="userInformationForm.get('firstName').touched && !userInformationForm.get('firstName').pristine && userInformationForm.controls.firstName.errors?.required">
                                {{ 'firstnamerequired' | lesCustomLocalize : 'login' }}
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <h5 class="secondary-text">
                                {{ 'lastname' | lesCustomLocalize : 'label' }}
                            </h5>
                            <input type="text" class="form-control form-section-md" formControlName="lastName" required [readonly]="!profile.isNoEligibility" placeholder="{{ 'lastname' | lesCustomLocalize : 'label' }}" />
                            <div class="alert alert-danger" *ngIf="userInformationForm.get('lastName').touched && !userInformationForm.get('lastName').pristine && userInformationForm.controls.lastName.errors?.required">
                                {{ 'lastnamerequired' | lesCustomLocalize : 'login' }}
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <h5 class="secondary-text">
                                {{ 'emailaddress' | lesCustomLocalize : 'label' }}
                            </h5>
                            <input type="text" class="form-control form-section-md" formControlName="email" required [readonly]="!profile.isNoEligibility" placeholder="{{ 'emailaddress' | lesCustomLocalize : 'label' }}" />
                            <div class="alert alert-danger" *ngIf="userInformationForm.get('email').touched && !userInformationForm.get('email').pristine && userInformationForm.controls.email.errors?.required">
                                {{ 'emailrequired' | lesCustomLocalize : 'login' }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group form-inline" formGroupName="dateOfBirthGroup">
                        <div class="col-sm-12 pl-0">
                            <h5 class="secondary-text">
                                {{ 'dateofbirth' | lesCustomLocalize : 'label' }}
                            </h5>
                        </div>
                        <div class="col-sm-2 pl-0">
                            <select
                                class="form-control form-section-sm"
                                formControlName="dateOfBirthMonth"
                                required
                                [attr.disabled]="profile.isNoEligibility ? null : false"
                                [ngClass]="{
                                    'ng-invalid': userInformationForm.controls.dateOfBirthGroup.errors?.isValidDate
                                }"
                            >
                                <option *ngFor="let month of birthMonthRange" [value]="month.month">
                                    {{ month.desc }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-2 pl-0">
                            <select
                                class="form-control form-section-sm"
                                formControlName="dateOfBirthDay"
                                required
                                [attr.disabled]="profile.isNoEligibility ? null : false"
                                [ngClass]="{
                                    'ng-invalid': userInformationForm.controls.dateOfBirthGroup.errors?.isValidDate
                                }"
                            >
                                <option *ngFor="let day of birthDayRange" [value]="day.day">
                                    {{ day.desc }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-3 pl-0">
                            <select
                                class="form-control form-section-sm"
                                formControlName="dateOfBirthYear"
                                required
                                [attr.disabled]="profile.isNoEligibility ? null : false"
                                [ngClass]="{
                                    'ng-invalid': userInformationForm.controls.dateOfBirthGroup.errors?.isValidDate
                                }"
                            >
                                <option *ngFor="let year of birthYearRange" [value]="year.year">
                                    {{ year.desc }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="col-sm-12 pl-0 alert alert-danger"
                            *ngIf="
                                ((userInformationForm.get('dateOfBirthGroup.dateOfBirthMonth').touched && !userInformationForm.get('dateOfBirthGroup.dateOfBirthMonth').pristine) ||
                                    (userInformationForm.get('dateOfBirthGroup.dateOfBirthDay').touched && !userInformationForm.get('dateOfBirthGroup.dateOfBirthDay').pristine) ||
                                    (userInformationForm.get('dateOfBirthGroup.dateOfBirthYear').touched && !userInformationForm.get('dateOfBirthGroup.dateOfBirthYear').pristine)) &&
                                userInformationForm.controls.dateOfBirthGroup.errors?.isValidDate
                            "
                        >
                            {{ 'dateofbirthrequiredandvalid' | lesCustomLocalize : 'login' }}
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="profile.isNoEligibility">
                        <div class="col-sm-12">
                            <h5 class="secondary-text">
                                {{ 'employeeid' | lesCustomLocalize : 'login' }}
                            </h5>
                            <input type="text" class="form-control form-section-md" formControlName="employeeID" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <h5 class="secondary-text">
                                {{ 'gender' | lesCustomLocalize : 'login' }}
                            </h5>
                            <select class="form-control form-section-sm" formControlName="gender" required>
                                <option *ngFor="let gender of genderList" [value]="gender.key" [selected]="gender.key == profile.genderKey">
                                    {{ gender.displayName }}
                                </option>
                            </select>
                            <div class="alert alert-danger" *ngIf="userInformationForm.get('gender').touched && !userInformationForm.get('gender').pristine && userInformationForm.controls.gender.errors?.required">
                                {{ 'genderrequired' | lesCustomLocalize : 'login' }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-12 pl-5">
                        <button type="button" id="actionId-CancelMyProfile" (click)="cancelUserInformationEdit()" class="tertiary-bg btn btn-secondary me-5">
                            {{ 'cancel' | lesCustomLocalize : 'label' }}
                        </button>
                        <button type="submit" id="actionId-SaveMyProfile" [disabled]="userInformationForm.pristine || !userInformationForm.valid || submitDisabled" class="tertiary-bg btn btn-primary">
                            {{ 'save' | lesCustomLocalize : 'label' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <h4 class="primary-text" id="titleId-Settings">
            {{ 'settings' | lesCustomLocalize : 'label' }}
            <a href="javascript:void(0)" id="actionId-UserSettingsEdit" *ngIf="!userSettingsEditToggle" (click)="toggleUserSettingsEdit()" title="{{ userInformationEditToggleLabel }}"><i class="fa fa-edit fa-1x" aria-hidden="true"></i></a>
        </h4>

        <div class="col-sm-12 information-text">
            {{ 'usersettingseditmessage' | lesCustomLocalize : 'message' }}
        </div>

        <div class="row box-bordered">
            <div class="col-sm-12" *ngIf="!userSettingsEditToggle">
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'preferredemail' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ profile.emailAddress }}</div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'preferredphone' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">{{ profile.phone }}</div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'receivetextmessages' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ translateBoolean(profile.receiveSmsReminders) }}
                    </div>
                </div>
                <div class="row mb-xs-3 mb-sm-2" *ngIf="profile.receiveSmsReminders">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'sendtexttoalternatephone' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ translateBoolean(profile.textUseSettingsPhone) }}
                    </div>
                </div>
                <div class="row mb-xs-3 mb-sm-2" *ngIf="profile.receiveSmsReminders && profile.textUseSettingsPhone">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'alternatephone' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ profile.textMessagePhone }}
                    </div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'preferredlanguage' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ getLocaleDescription(profile.languageLocale) }}
                    </div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'preferredtimezone' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ getTimeZoneDescription(profile.timeZoneKey) }}
                    </div>
                </div>
                <div class="row mb-xs-3 mb-sm-2">
                    <div class="col-xs-12 col-sm-2 text-primary">{{ 'preferredstate' | lesCustomLocalize : 'label' }}:</div>
                    <div class="col-xs-12 col-sm-10">
                        {{ getStateDescription(updatedProfile.stateKey) }}
                    </div>
                </div>
            </div>

            <form [formGroup]="settingsForm" id="formId-UserSettings" *ngIf="userSettingsEditToggle" (ngSubmit)="saveSettings()" novalidate>
                <div class="col-sm-12">
                    <h4 class="text-info" id="titleId-UserSettings">
                        {{ 'profilesettingseditinstructions' | lesCustomLocalize : 'message' }}
                    </h4>

                    <hr />

                    <input type="hidden" formControlName="preferredGender" value="{{ profile.genderKey }}" />
                    <p class="profile-text">{{ 'preferredemaildescription' | lesCustomLocalize : 'message' }}</p>

                    <div formGroupName="emailGroup">
                        <div class="form-group form-inline" style="display: flex; align-items: stretch; vertical-align: top">
                            <div class="col-sm-12 pl-0">
                                <h5 class="secondary-text">
                                    {{ 'preferredemail' | lesCustomLocalize : 'label' }} -
                                    <span class="current-value">{{ 'current' | lesCustomLocalize : 'label' }}: {{ profile.emailAddress }}</span>
                                </h5>
                            </div>
                            <div class="col-sm-5 main-field">
                                <input type="text" class="form-control form-section-md" formControlName="preferredEmailNew" placeholder="{{ 'newemail' | lesCustomLocalize : 'label' }}" />
                                <div class="alert alert-danger" *ngIf="settingsForm.controls.emailGroup.controls.preferredEmailNew.errors?.email">
                                    {{ 'emailinvalidformat' | lesCustomLocalize : 'message' }}
                                </div>
                            </div>
                            <div class="col-sm-5 secondary-validation-field">
                                <input
                                    type="text"
                                    class="form-control form-section-md"
                                    [ngClass]="{
                                        'ng-invalid': settingsForm.controls.emailGroup.errors?.match
                                    }"
                                    formControlName="preferredEmailConfirm"
                                    placeholder="{{ 'confirmemail' | lesCustomLocalize : 'label' }}"
                                />
                                <div class="alert alert-danger" *ngIf="settingsForm.get('emailGroup.preferredEmailConfirm').touched && !settingsForm.get('emailGroup.preferredEmailConfirm').pristine && settingsForm.controls.emailGroup.errors?.match">
                                    {{ 'confirmmatchnew' | lesCustomLocalize : 'message' }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>{{ 'preferredphonedescription' | lesCustomLocalize : 'message' }}</p>

                    <div formGroupName="phoneGroup">
                        <div class="form-group form-inline" style="display: flex; align-items: stretch; vertical-align: top">
                            <div class="col-sm-12 pl-0">
                                <h5 class="secondary-text">
                                    {{ 'preferredphone' | lesCustomLocalize : 'label' }} -
                                    <span class="current-value">{{ 'current' | lesCustomLocalize : 'label' }}: {{ profile.phone }}</span>
                                </h5>
                            </div>
                            <div class="col-sm-5 main-field">
                                <input type="text" mask="(000) 000-0000" class="form-control form-section-md" formControlName="phoneNew" placeholder="{{ 'newphone' | lesCustomLocalize : 'label' }}" />
                            </div>
                            <div class="col-sm-5 secondary-validation-field">
                                <input
                                    type="text"
                                    class="form-control form-section-md"
                                    mask="(000) 000-0000"
                                    [ngClass]="{
                                        'ng-invalid': settingsForm.controls.phoneGroup.errors?.match
                                    }"
                                    formControlName="preferredPhoneConfirm"
                                    placeholder="{{ 'confirmphone' | lesCustomLocalize : 'label' }}"
                                />
                                <div class="alert alert-danger" *ngIf="settingsForm.get('phoneGroup.preferredPhoneConfirm').touched && !settingsForm.get('phoneGroup.preferredPhoneConfirm').pristine && settingsForm.controls.phoneGroup.errors?.match">
                                    {{ 'confirmmatchnew' | lesCustomLocalize : 'message' }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>{{ 'receivesmsreminders' | lesCustomLocalize : 'label' }}</p>

                    <div class="form-group row">
                        <div class="col-sm-12 additional-information-indent">
                            <h5 class="secondary-text">
                                {{ 'preferredtextmessagephonedescription' | lesCustomLocalize : 'message' }}
                            </h5>
                        </div>
                        <div class="col-sm-2">
                            <select class="form-control form-section-sm" formControlName="receiveSmsReminders">
                                <option value="true">{{ 'yes' | lesCustomLocalize : 'label' }}</option>
                                <option value="false">{{ 'no' | lesCustomLocalize : 'label' }}</option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="settingsForm.controls.receiveSmsReminders.value === 'true' || settingsForm.controls.receiveSmsReminders.value == true">
                        <div class="form-group row">
                            <div class="col-sm-12 ms-3 pl-0">
                                <h5 class="secondary-text">
                                    {{ 'preferredtextusepreferredphonequestion' | lesCustomLocalize : 'message' }}
                                </h5>
                            </div>
                            <div class="col-sm-2">
                                <select class="form-control form-section-sm" formControlName="preferredTextUsePreferredPhone">
                                    <option value="true">{{ 'yes' | lesCustomLocalize : 'label' }}</option>
                                    <option value="false">{{ 'no' | lesCustomLocalize : 'label' }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        *ngIf="
                            (settingsForm.controls.receiveSmsReminders.value === 'true' || settingsForm.controls.receiveSmsReminders.value == true) &&
                            (settingsForm.controls.preferredTextUsePreferredPhone.value === 'true' || settingsForm.controls.preferredTextUsePreferredPhone.value)
                        "
                    >
                        <div formGroupName="textMessagePhoneGroup">
                            <div class="form-group form-inline" style="display: flex; align-items: stretch; vertical-align: top">
                                <div class="col-sm-12 pl-0">
                                    <h5 class="secondary-text">
                                        {{ 'alternatephone' | lesCustomLocalize : 'label' }} -
                                        <span class="current-value">{{ 'current' | lesCustomLocalize : 'label' }}: {{ profile.textMessagePhone }}</span>
                                    </h5>
                                </div>
                                <div class="col-sm-5 main-field">
                                    <input type="text" class="form-control form-section-md" mask="(000) 000-0000" width="20" formControlName="preferredTextMessagePhoneNew" placeholder="{{ 'newtextmessagephone' | lesCustomLocalize : 'label' }}" />
                                </div>
                                <div class="col-sm-6 secondary-validation-field">
                                    <input
                                        type="text"
                                        class="form-control form-section-md"
                                        [ngClass]="{
                                            'ng-invalid form-control-danger': settingsForm.controls.textMessagePhoneGroup.errors?.match
                                        }"
                                        formControlName="preferredTextMessagePhoneConfirm"
                                        mask="(000) 000-0000"
                                        width="20"
                                        placeholder="{{ 'confirmtextmessagephone' | lesCustomLocalize : 'label' }}"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="
                                            settingsForm.get('textMessagePhoneGroup.preferredTextMessagePhoneConfirm').touched &&
                                            !settingsForm.get('textMessagePhoneGroup.preferredTextMessagePhoneConfirm').pristine &&
                                            settingsForm.controls.textMessagePhoneGroup.errors?.match
                                        "
                                    >
                                        {{ 'confirmmatchnew' | lesCustomLocalize : 'message' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <h5 class="secondary-text">
                                {{ 'preferredlanguage' | lesCustomLocalize : 'label' }}
                            </h5>
                            <select class="form-control form-section-md" formControlName="preferredLanguage">
                                <option *ngFor="let locale of localeList" [value]="locale.locale">
                                    {{ locale.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <h5 class="secondary-text">
                                {{ 'preferredtimezone' | lesCustomLocalize : 'label' }}
                            </h5>
                            <select class="form-control form-section-md" formControlName="preferredTimeZoneKey">
                                <option value="null">
                                    {{ 'noneselected' | lesCustomLocalize : 'label' }}
                                </option>
                                <option *ngFor="let timezone of timeZoneList" [value]="timezone.key">
                                    {{ timezone.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <h5 class="secondary-text">
                                {{ 'preferredstate' | lesCustomLocalize : 'label' }}
                            </h5>
                            <select class="form-control form-section-md" formControlName="preferredStateKey">
                                <option value="null">
                                    {{ 'noneselected' | lesCustomLocalize : 'label' }}
                                </option>
                                <option *ngFor="let state of stateList" [value]="state.key">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row pl-5">
                        <button type="button" id="actionId-CancelUserSettings" (click)="cancelUserSettingsEdit()" class="tertiary-bg btn btn-secondary me-5">
                            {{ 'cancel' | lesCustomLocalize : 'label' }}
                        </button>
                        <button type="submit" id="actionId-SaveUserSettings" [disabled]="settingsForm.pristine || !settingsForm.valid || submitDisabled" class="tertiary-bg btn btn-primary">
                            {{ 'save' | lesCustomLocalize : 'label' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-loading *ngIf="!readyForRender"></app-loading>

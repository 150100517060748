export class ConsentsNeededResult {
    status: number;
    errorMessage: string;
    result: ConsentsNeeded[];
}

export class ConsentsNeeded {
    consentDefinitionKey: string;
    companyKey: string;
    isHIPAA: boolean;
    declineNotAvailable: boolean;
    programServiceKey: string;
    languageCode: string;
    authorizationPrompt: string;
    authorizationText: string;
    acceptActionText: string;
    declineText: string;
    declineActionText: string;
    declineConfirmationText: string;
    isAdditional: boolean;
}
export class ConsentAndAcknowledgement extends ConsentsNeeded {
    consentDefinitionName: string;
    acceptedOn: any;
    declinedOn: any;
    acknowledgementKey: any;
    acknowledgementCreatedBy: any;
    acknowledgementCreatedOn: any;
}
export class ConsentByKeyForPersonRequest {
    consentDefinitionKey: string;
    userKey: string;
    participantKey: string;
    preferredLanguage: string;
    languageLocale : string;
}
export class ConsentDefinitionAcknowledgementsPostModel {
    constructor(
        public ConsentDefinitionAcknowledgements: ConsentDefinitionAcknowledgementModel[],
        public createdOn: Date,
        public personKey: string,
        public participantKey: string,
        public userKey: string,
        public unmatchedDataKey: string,
        public createdBy: string
    ) {}
}

export class ConsentDefinitionAcknowledgementModel {
    createdOn: Date;
    modifiedBy: string = null;
    createdBy: string = null;
    modifiedOn?: Date = null;
    personKey: string;
    participantKey: string;
    userKey: string;

    constructor(public consentDefinitionKey: string, public programServiceKey: string, public acceptedOn: Date, public declinedOn: Date, public key: string) {}
}

export class RequiredConsent {
    constructor(public consentDefinitionKey = '', public hipaaAuth = false, public addOnConsentRequired = false) {}
}

export class ConsentsNeededByServiceSearchModel {
    constructor(public excludeOnsiteConsent = true, public programServiceKeys: string[]) {}
}

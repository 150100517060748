<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-SevereSymptom">{{ header }}</h4>
    <button type="button" class="close" id="actionId-CloseSevereSymptom" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div *ngIf="!displaySevereSymptomWarning">
        <span>
            {{ 'severesymptominquiry' | lesCustomLocalize : 'message' }}
        </span>
        <br />
        <br />
        <span style="font-size: small">
            {{ 'severesymptominquiryinfo' | lesCustomLocalize : 'message' }}
        </span>
    </div>

    <div *ngIf="displaySevereSymptomWarning">
        <span>
            {{ 'severesymptomwarning1' | lesCustomLocalize : 'message' }}
        </span>
        <br />
        <br />
        <span>
            {{ 'severesymptomwarning2' | lesCustomLocalize : 'message' }}
        </span>
    </div>
</div>

<div class="modal-footer pt-10">
    <button *ngIf="displaySevereSymptomWarning" class="tertiary-bg btn btn-primary" id="actionId-DismissSevereSymptom" (click)="dismissWarning()">{{ 'dismiss' | lesCustomLocalize : 'label' }}</button>
    <button *ngIf="!displaySevereSymptomWarning" type="button" id="actionId-YesSevereSymptom" class="tertiary-bg btn btn-secondary" (click)="confirmSevereSymptoms()">{{ 'yes' | lesCustomLocalize : 'label' }}</button>
    <button *ngIf="!displaySevereSymptomWarning" type="button" id="actionId-NoSevereSymptom" class="tertiary-bg btn btn-primary" (click)="denySevereSymptoms()">{{ 'no' | lesCustomLocalize : 'label' }}</button>
</div>

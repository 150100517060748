<div *ngIf="readyForRender">
    <h1 class="primary-text" id="titleId-CoachingDashboard">{{ 'coachingdashboard' | lesCustomLocalize : 'label' }}</h1>

    <ng-container *ngIf="coach">
        <app-person-blurb [person]="coach" [headinglesCustomLocalizeProp]="'coach'" [htmlId]="'Coach'"></app-person-blurb>
    </ng-container>

    <div class="container-fluid">
        <div class="row d-lg-none">
            <div class="col text-center pb-3">
                <div class="btn-group" id="actionId-CoachingInfoChoices">
                    <input type="radio" class="btn-check" [value]="sectionValues.Appointments" name="{{ sectionToShow }}" [(ngModel)]="sectionToShow" id="{{ 'coach_sectionToShow_' + sectionValues.Appointments }}" />
                    <label class="btn btn-primary" id="actionId-Appointments" for="{{ 'coach_sectionToShow_' + sectionValues.Appointments }}">
                        {{ 'appointments' | lesCustomLocalize : 'label' }}
                    </label>
                    <input type="radio" class="btn-check" [value]="sectionValues.Messaging" name="sectionToShow" [(ngModel)]="sectionToShow" id="{{ 'coach_sectionToShow_' + sectionValues.Messaging }}" />
                    <label class="btn-primary" id="actionId-Messages" for="{{ 'coach_sectionToShow_' + sectionValues.Messaging }}">
                        {{ 'messages' | lesCustomLocalize : 'label' }}
                    </label>
                    <input type="radio" class="btn-check" [value]="sectionValues.AllAccess" name="sectionToShow" [(ngModel)]="sectionToShow" id="{{ 'coach_sectionToShow_' + sectionValues.AllAccess }}" />
                    <label class="btn-primary" id="actionId-AllAccess" for="{{ 'coach_sectionToShow_' + sectionValues.AllAccess }}">
                        {{ 'allaccess' | lesCustomLocalize : 'label' }}
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="{ section: true, 'col-md-12': true, 'col-lg-6': true }">
                <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.Appointments }">
                    <app-coaching-appointment-list [mode]="allAccessMode" [coach]="coach"></app-coaching-appointment-list>
                </div>
                <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.AllAccess }">
                    <app-secure-message-access-request-list-component [mode]="allAccessMode" [coach]="coach"></app-secure-message-access-request-list-component>
                </div>
            </div>
            <div [ngClass]="{ 'col-md-12': true, 'col-lg-6': true, 'd-none d-lg-block': sectionToShow !== sectionValues.Messaging }">
                <app-secure-messaging [mode]="secureMessagingMode" [coach]="coach"></app-secure-messaging>
            </div>
        </div>
    </div>
</div>

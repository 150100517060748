import { HttpClient as Http, } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SecurityContext } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { CoachingAppointment } from '../appointment';

@Component({
  selector: 'app-appointment-viewer',
  templateUrl: './appointment-viewer.component.html',
  styleUrls: ['./appointment-viewer.component.css']
})
export class AppointmentViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('form') postForm: ElementRef;
  @Input() participantExternalId: string;
  @Input() userExternalId: string;
  @Input() confirmationNumber = '';
  @Input() participant: Person;

  currentUser: Person;
  timeTradeUrl = environment.timeTradeClickToSchedule;
  subscriptions: ISubscription[] = [];

  firstName = 'Participant';
  lastName = 'Portal';
  email = 'noreply@wellnesscorporatesolutions.com';
  mobilePhone = '3012297555';
  contactPhone = '3012297555';
  appointmentType = 'coaching';
  campaignId = 1;
  bookedBy: string;
  locationId: string;

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, private _http: Http,
    private _personService: PersonService) { }

  close() {
    this.activeModal.dismiss('Cross click');
  }

  submitForm($event): boolean {
    $event.stopPropagation();
    this._http.post(this.urlToUse(), {});
    return true;
  }

  urlToUse() {
    return this.confirmationNumber !== '' ?
      (environment.timeTradeFind + '&appointmentId=' + this.confirmationNumber + '&attendee_person_lastName=' + this.participant.userKey) :
      environment.timeTradeClickToSchedule;
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(person => {
      if (person) {
        this.currentUser = person;

        if (person.isCoach || person.isCoachAdmin) {
          this.campaignId = null;
          this.bookedBy = 'Coach';
          this.locationId = this.participant ? this.participant.coachingDemographic : null;
        } else if (person.isParticipant) {
          this.locationId = person.coachingDemographic;
          this.bookedBy = 'Participant';
        } else {
          this.bookedBy = 'Support';
          this.locationId = this.participant ? this.participant.coachingDemographic : null;
        }
      }
    }));
  }

  ngAfterViewInit() {
    this.postForm.nativeElement.submit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

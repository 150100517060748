<div class="row">
    <div class="col-md-12">
        <h1 class="primary-text" id="titleId-Results">{{ 'results' | lesCustomLocalize : 'label' }}</h1>
        <p [innerHTML]="'resultsblurb' | lesCustomLocalize : 'message'"></p>
    </div>
</div>

<div class="row" *ngIf="showPossibleDelayInResultsWarning">
    <div class="col-md-12">
        <p class="text-danger warning-message" id="messageId-ResultsrMayNotAppearForTwentyFourHours" [innerHTML]="'resultspossibleresultsdelaywarning' | lesCustomLocalize : 'message'"></p>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <h4 class="primary-text" id="titleId-ViewTheDetails">{{ 'yourresults' | lesCustomLocalize : 'label' }}</h4>
        <app-result-list></app-result-list>
    </div>

    <div class="col-md-6">
        <h4 class="primary-text" id="titleId-ViewYourResultsOverTime">{{ 'resultsovertime' | lesCustomLocalize : 'label' }}</h4>
        <app-result-chart></app-result-chart>
        <div class="row justify-content-md-center pt-2" *ngIf="readyForRender && !hasError"></div>
    </div>
</div>

import { ApiSlot } from './api-slot';
import { ProgramServiceAndType } from './program-service-and-type';

export class Slot {
  title: string;
  name: string;
  programServiceType: ProgramServiceAndType;
  time: Date;
  duration: number;
  timeUtc: Date;
  reserved = false;
  bookForOthers: boolean;

  eventKey: string;

  get dateOnly(): string {
    return this.time ? this.time.getFullYear() + '-' + (this.time.getMonth() + 1) + '-' + this.time.getDate() : null;
  }

  constructor(slot: ApiSlot, bookForOthers: boolean) {
    this.title = slot.title;
    this.name = slot.name;
    this.programServiceType = slot.programServiceType;
    this.time = slot.time;
    this.duration = slot.duration;
    this.timeUtc = slot.timeUtc;
    this.bookForOthers = bookForOthers;
  }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-GapsInCare">{{ 'gapsincareselectionmodaltitle' | lesCustomLocalize : 'title' }}</h4>
    <button type="button" class="close" id="actionId-Close-GapsInCareSelectionModal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="gicSelectionForm" id="formId-GapsInCareSelectionModal" (ngSubmit)="save()" novalidate>
    <div class="modal-body">
        <div class="row capture-header p-2">
            {{ 'gapsincareselectionmodal' | lesCustomLocalize : 'message' }}
        </div>

        <div class="form-group row">
            <div class="col-sm-12 gic-radio">
                <label for="Home Test Kit" class="form-radio__label">
                    <input type="radio" class="form-radio__large" formControlName="modality" required value="Home Test Kit" id="Home Test Kit" />
                    {{ 'label.gicmodality.htk' | lesCustomLocalize : '' }}
                </label>
                <p class="gic-radio-description content_only">
                    {{ 'message.gicmodality.htk-info' | lesCustomLocalize : '' }}
                </p>
            </div>
            <div class="col-sm-12 gic-radio">
                <label for="PSC Visit" class="form-radio__label">
                    <input type="radio" class="form-radio__large" formControlName="modality" required value="PSC Visit" id="PSC Visit" />
                    {{ 'label.gicmodality.psc' | lesCustomLocalize : '' }}
                </label>
                <p class="gic-radio-description content_only">
                    {{ 'message.gicmodality.psc-info' | lesCustomLocalize : '' }}
                </p>
            </div>
        </div>
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn btn-secondary" id="actionId-Cancel-GapsInCareSelectionModal" (click)="close()">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
        <button [disabled]="!this.gicSelectionForm.valid" id="actionId-Submit-GapsInCareSelectionModal" type="submit" class="tertiary-bg btn btn-primary" value="Submit">
            {{ 'submit' | lesCustomLocalize : 'label' }}
        </button>
    </div>
</form>

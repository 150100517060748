<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-Alert">{{ header }}</h4>
    <button type="button" class="close" id="actionId-Close-Alert" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" [innerHTML]="msg" *ngIf="!showCustomError"></div>

<div class="modal-body" *ngIf="showCustomError" id="errorAlertId-Confirm-Alert">
    <p *ngFor="let error of customErrorList" [innerHTML]="error"></p>
    <p class="error-muted" *ngFor="let error of mutedErrorList" [innerHTML]="error"></p>
</div>

<div class="modal-footer pt-10">
    <button *ngIf="download" class="tertiary-bg btn btn-primary" id="actionId-Confirm-Alert" (click)="downloadPDF()">{{ confirmBtn }}</button>
    <button type="button" class="tertiary-bg btn btn-primary" id="actionId-Cancel-Alert" (click)="activeModal.close('Close click')">{{ cancelBtn }}</button>
</div>

import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error-reload',
  templateUrl: './error-reload.component.html',
  styleUrls: ['./error-reload.component.css']
})
export class ErrorReloadComponent implements OnInit {
  isReadyForRender = true;


  retry() {
    window.location.href = environment.originUrl;
  }

  ngOnInit() {
    console.info('Init ErrorReloadComponent.')
  }
}

import { State } from "../state/state";

export class InsuranceAddressBase {
    userKey: string;
    address1: string;
    address2: string;
    city: string;
    stateKey: string;
    zip: string;
}
export class InsuranceAddress extends InsuranceAddressBase {
    state: State;
    key: string;
}
export class InsuranceAddressSubmits extends InsuranceAddressBase {
    constructor(insuranceAddressInformation: InsuranceAddress) {
        super()
        this.userKey = insuranceAddressInformation.userKey;
        this.address1 = insuranceAddressInformation.address1;
        this.address2 = insuranceAddressInformation.address2;
        this.city = insuranceAddressInformation.city;
        this.stateKey = insuranceAddressInformation.stateKey;
        this.zip = insuranceAddressInformation.zip;
    }
}

export class InsuranceAddressPut extends InsuranceAddressBase {
    key: string;
    constructor(insuranceAddressInformation: InsuranceAddress) {
        super()
        this.key = insuranceAddressInformation.key;
        this.userKey = insuranceAddressInformation.userKey;
        this.address1 = insuranceAddressInformation.address1;
        this.address2 = insuranceAddressInformation.address2;
        this.city = insuranceAddressInformation.city;
        this.stateKey = insuranceAddressInformation.stateKey;
        this.zip = insuranceAddressInformation.zip;
    }
}
export class InsuranceAddressRequestVerification {
    constructor(public serviceKeys: string[], public userKey: string) { }
}
export class InsuranceAddressResult {
    key: string;
    errorMessage: string;
    errors: any[]
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../shared/localize/localize.service';
import { Ethnicity } from '../shared/metadata/ethnicity';
import { MetadataService } from '../shared/metadata/metadata.service';
import { Race } from '../shared/metadata/race';
import { State } from '../shared/state/state';
import { UserService } from '../shared/user/user.service';
import { UserInformation, UserInformationPut, UserInformationSubmits } from '../shared/user/user-information';
import { UserDataCapture, UserDataCaptureResult, UserDataCaptureType } from './user-data-capture';

@Component({
    selector: 'app-data-capture-modal',
    templateUrl: './user-data-capture-modal.component.html',
    styleUrls: ['./user-data-capture-modal.component.css']
})

export class UserDataCaptureModalComponent implements OnInit, OnDestroy {

    userInformation: UserInformation = new UserInformation();
    races: Race[];
    ethnicities: Ethnicity[];
    isPersonRender = false;
    states: State[];
    userDataCaptureResult: UserDataCaptureResult = new UserDataCaptureResult;
    subscriptions: ISubscription[] = [];
    _userKey: string;
    showError = false;
    errorList: any;
    errorMsg: string = null;

    @Input() set userKey(val: string) {
        this._userKey = val;
        this._userService.getUserInformation(val).subscribe(info => {
            if (info != null) {
                this.userInformation = info;
            }
            if (val && this.isValidGuid(val))
                this.userInformation.userKey = val;
        })
    }
    get userKey(): string {
        return this._userKey;
    }
    constructor(private _metadataService: MetadataService, public activeModal: NgbActiveModal,
        private _userService: UserService, private _localizeService: LocalizeService) {

        _metadataService.getEthinicities();
        _metadataService.getRaces();
        _metadataService.getStates();
    }

    ngOnInit(): void {
        this.subscriptions.push(this._metadataService.ethnicityList.subscribe(data => {
            this.ethnicities = data;
        }));
        this.subscriptions.push(this._metadataService.raceList.subscribe(data => {
            this.races = data
        }))
        this.subscriptions.push(this._metadataService.stateList.subscribe(data => {
            this.states = data;
        }))
    }

    cancel() {
        this.userDataCaptureResult.hasDataCapture = false;
        this.errorMsg = null;
        this.showError = false;
        this.activeModal.close(this.userDataCaptureResult)
    }

    save() {
        this.addUserKey();

        if (this.userInformation.key && (this.isValidGuid(this.userInformation.key))) {
            const putModel = this.putInformation;
            this._userService.updateUserInformation(putModel).subscribe(result => {
                this.closeModal(result);
            }, error => {
                this.closeModal(error)
            })
        } else {
            const postModel = this.postInformation;
            this._userService.createUserInformation(postModel).subscribe(response => {
                this.closeModal(response);
            }, error => {
                this.closeModal(error);
            })
        }
    }
    get putInformation() {
        return new UserInformationPut(this.userInformation)
    }

    get postInformation() {
        return new UserInformationSubmits(this.userInformation);
    }
    localizeMessage(msgTag) {
        let errorTag = "unknownerror";
        if (msgTag) {
            try {
                errorTag = msgTag.split('.')[1];
            } catch (error) {
                console.error(error);
            }
        }
        return errorTag;
    }
    localizeValidationErrors(err) {
        let errorName = this.localizeMessage(err.value);
        if (errorName !== 'unknownerror')
            errorName = err.key + errorName;
        return errorName;
    }
    closeModal(response: any) {
        if (response.errorMessage && response.errors) {
            this.showError = true;
            this.errorList = response.errors;
            this.errorMsg = response.errorMessage
        } else {
            this.userDataCaptureResult.hasDataCapture = true;
            const capture = new UserDataCapture;
            capture.userKey = this.userKey;
            capture.key = response.key
            capture.type = UserDataCaptureType.COVID;
            this.userDataCaptureResult.userDataCapture = capture;
            this.userDataCaptureResult.requiresDataCapture = true;
            this.activeModal.close(this.userDataCaptureResult);
        }
    }
    addUserKey() {
        if (!this.isValidGuid(this.userInformation.userKey) && this.isValidGuid(this._userKey)) {
            this.userInformation.userKey = this._userKey;
        }
    }
    isValidGuid(guid: string) {
        return (/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid));
    }
    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
}

import { Component, EventEmitter, Input, OnInit,Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../../person-widgets/person.widget.models';
import { AttestationCapture } from '../../shared/attestation/attestation.capture';
import { AttestationModel } from '../../shared/attestation/attestation.model';
import { AttestationService } from '../../shared/attestation/attestation.service';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';

@Component({
    selector: 'app-person-attestation-widget',
    templateUrl: './person-attestation-widget.component.html',
    styleUrls: ['./person-attestation-widget.component.css']
})
export class PersonAttestationWidgetComponent implements OnInit {
    @Input() personWidget: PersonWidgetCollectionWidget;
    @Input() currentPerson: Person;
    @Input() otherPerson?: Person;
    @Input() programServiceKey: string;
    @Input() programServiceTypeKey: string;
    @Input() personKey: string;
    @Output() onWidgetCompleted = new EventEmitter<any>();
    @Output() onUpdateAttempted = new EventEmitter<any>();
    attestation: AttestationModel;
    attestationForm: FormGroup;
    public isNoResponse: boolean;
    public isYesResponse: boolean;
    public isSubmitting = false;
    public isRenderReady = false;
    public hasError = false;
    errorMessage = '1 or more of your field is not valid. Please correct and try again. TBD';
    constructor(private _formBuilder: FormBuilder, private _attestationService: AttestationService, private _localizationService: LocalizeService) {
        this.attestationForm = this._formBuilder.group({
            userResponse: ['', Validators.required]
        });
    }
    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }
    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentPerson;
    }

    ngOnInit(): void {
        this.loadAttestation();
    }
    captureAttestationResponse() { }
    loadAttestation() {
        const locale = this.person().locale ? this.person().locale.languageLocale : 'en-us';
        this._attestationService.getAttestation(this.programServiceKey, locale).subscribe(
            (r) => {
                if (r) {
                    this.attestation = r;
                    this.hasError = false;
                } else {
                    this.onError();
                }
                this.isRenderReady = true;
            },
            (error) => {
                this.isRenderReady = true;
                this.onError();
            }
        );
    }
    onError() {
        this.hasError = true;
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
    }

    get userResponse() {
        const control = this.attestationForm.get('userResponse');
        return control;
    }
    private processAction(next: WidgetCloseAction, validate = true) {
        if (!validate) {
            return this.closePanel(false, next);
        }
        if (!this.attestationForm.valid || this.isSubmitting) {
            return;
        }
        this.isSubmitting = true;
        this._attestationService
            .captureAttestationResponse(
                new AttestationCapture({
                    programServiceKey: this.attestation.programServiceKey,
                    serviceOptionKey: this.attestation.serviceOptionKey,
                    userKey: this.person().userKey,
                    response: this.userResponse.value
                })
            )
            .subscribe(
                (response) => {
                    this.isSubmitting = false;
                    if (response.errors && response.errors.length > 0) {
                        this.onError();
                    } else {
                        this.closePanel(true, next);
                    }
                },
                (error) => this.onError()
            );
    }
    onNextButtonClick(isValid) {
        if (!isValid) {
            this.hasError = true;
            return;
        }
        this.processAction(WidgetCloseAction.forward, true);
    }
    onPreviousButtonClick() {
        //if button is present
        if (this.personWidget.position == WidgetCollectionPosition.first) return;
        this.processAction(WidgetCloseAction.backward, false);
    }
    closeError() {
        this.hasError = false;
    }
    private closePanel(complete: boolean, action: WidgetCloseAction) {
        //send back result
        const result = new WidgetCompleted();
        result.dataCollectionKey = this.personWidget.dataCollectionKey;
        result.widgetKey = this.personWidget.widgetKey;
        result.isComplete = complete;
        result.action = action;
        result.widgetPanelIndex = this.personWidget.widgetPanelIndex;
        this.onWidgetCompleted.emit(result);
    }
    getTranslation(tag: string) {
        return this._localizationService.getCombinedTag(tag, null, null, null, this.tagOptions);
    }
    getNextMessage() {
        if (this.personWidget.position == WidgetCollectionPosition.last) {
            return this.getTranslation('button.submit');
        }
        return this.getTranslation('button.next');
    }
}

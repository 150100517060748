<div class="messaging-widget" *ngIf="readyForRender">
    <h4 class="primary-text" id="titleId-SecureMessaging">
        {{ 'securemessaging' | lesCustomLocalize : 'label' }}
        <small class="text-muted" *ngIf="mode === AccessMode.ParticipantView"
            ><button type="button" class="tertiary-bg btn btn-primary btn-sm" id="actionId-NewSecureMessaging" (click)="newMessage()"><i class="fa fa-envelope" aria-hidden="true"></i> {{ 'new' | lesCustomLocalize : 'label' }}</button></small
        >
        <small class="text-muted" *ngIf="mode === AccessMode.ParticipantDetail"
            ><button type="button" class="tertiary-bg btn btn-primary btn-sm" id="actionId-NewSecureMessaging" (click)="newMessage(participant)">
                <i class="fa fa-envelope" aria-hidden="true"></i> {{ 'new' | lesCustomLocalize : 'label' }}
            </button></small
        >
    </h4>

    <form #secureMessagingForm="ngForm" id="formId-SecureMessaging">
        <div *ngIf="specificMessageShown" class="filter-overlay text-center">
            {{ 'specificmessagerequested' | lesCustomLocalize : 'message' }}<br />
            <button type="button" class="tertiary-bg btn btn-link" id="actionId-RestoreFiltersSecureMessaging" (click)="restoreFilters()">{{ 'restorefilters' | lesCustomLocalize : 'label' }}</button>
        </div>

        <div class="form-group">
            <select class="form-control" name="dateFilter" [(ngModel)]="dateFilter" (change)="getSecureMessages()">
                <option value="">{{ 'all' | lesCustomLocalize : 'label' }}</option>
                <option value="today">{{ 'today' | lesCustomLocalize : 'label' }}</option>
                <option value="last7days">{{ 'lastxdays' | lesCustomLocalize : 'label' : '7' }}</option>
                <option value="last30days">{{ 'lastxdays' | lesCustomLocalize : 'label' : '30' }}</option>
                <option value="last6months">{{ 'lastxmonths' | lesCustomLocalize : 'label' : '6' }}</option>
            </select>
        </div>

        <div>
            <div class="btn-group btn-sm" role="group">
                <input type="checkbox" class="btn-check" id="unreadOnly-checked" (change)="onUnreadOnly()" [checked]="unreadOnly" />
                <label class="btn btn-info tertiary-bg" for="unreadOnly-checked" *ngIf="unreadOnly"> <i class="fa fa-check-square" aria-hidden="true"></i> {{ 'unreadonly' | lesCustomLocalize : 'label' }} </label>
                <input type="checkbox" class="btn-check" id="unreadOnly-unchecked" (change)="onUnreadOnly()" [checked]="!unreadOnly" />
                <label class="btn btn-info tertiary-bg" for="unreadOnly-unchecked" *ngIf="!unreadOnly"> <i class="fa fa-square" aria-hidden="true"></i> {{ 'unreadonly' | lesCustomLocalize : 'label' }} </label>

                <input type="checkbox" class="btn-check" id="followUpOnly-checked" (change)="onFollowUpOnly()" [checked]="followUpOnly" *ngIf="mode !== AccessMode.ParticipantView" />
                <label class="btn btn-info tertiary-bg" for="followUpOnly-checked" *ngIf="mode !== AccessMode.ParticipantView && followUpOnly">
                    <i class="fa fa-check-square" aria-hidden="true"></i> {{ 'followuponly' | lesCustomLocalize : 'label' }}
                </label>
                <input type="checkbox" class="btn-check" id="followUpOnly-unchecked" (change)="onFollowUpOnly()" [checked]="!followUpOnly" *ngIf="mode !== AccessMode.ParticipantView" />
                <label class="btn btn-info tertiary-bg" for="followUpOnly-unchecked" *ngIf="mode !== AccessMode.ParticipantView && !followUpOnly">
                    <i class="fa fa-square" aria-hidden="true"></i> {{ 'followuponly' | lesCustomLocalize : 'label' }}
                </label>

                <input type="checkbox" class="btn-check" id="generalInboxOnly-checked" (change)="onGeneralInboxOnly()" [checked]="generalInboxOnly" *ngIf="currentUser.isGeneralInboxMonitor" />
                <label class="btn btn-info tertiary-bg" for="generalInboxOnly-checked" *ngIf="currentUser.isGeneralInboxMonitor && generalInboxOnly">
                    <i class="fa fa-check-square" aria-hidden="true"></i> {{ 'generalinboxonly' | lesCustomLocalize : 'label' }}
                </label>
                <input type="checkbox" class="btn-check" id="generalInboxOnly-unchecked" (change)="onGeneralInboxOnly()" [checked]="!generalInboxOnly" *ngIf="currentUser.isGeneralInboxMonitor" />
                <label class="btn btn-info tertiary-bg" for="generalInboxOnly-unchecked" *ngIf="currentUser.isGeneralInboxMonitor && !generalInboxOnly">
                    <i class="fa fa-square" aria-hidden="true"></i> {{ 'generalinboxonly' | lesCustomLocalize : 'label' }}
                </label>
            </div>
            <div class="pull-right pr-2">
                <i class="fa fa-sync fa-2x" [class.fa-spin]="fetchingRecords" (click)="getSecureMessages()" aria-hidden="true"></i>
            </div>
        </div>
    </form>

    <div class="container-fluid pl-0 pt-2" *ngIf="messages && messages.length === 0">
        <div class="alert alert-info" role="alert">
            {{ 'nosecuremessagesfound' | lesCustomLocalize : 'message' }}
        </div>
    </div>

    <div *ngIf="messages && messages.length" class="pt-2">
        <div *ngFor="let message of messages" [ngClass]="{ 'card secure-message': true, 'unread-msg': unreadByUser(message) }">
            <div class="row">
                <div class="col-md-12 pr-0">
                    <div class="avatar-wrapper">
                        <img *ngIf="message.sendingUserPhoto" [src]="message.sendingUserPhoto" class="avatar img-fluid img-thumbnail" />
                        <div *ngIf="!message.sendingUserPhoto" class="avatar no-img img-fluid img-thumbnail">
                            <div class="align-middle">{{ message.sendingUserInitials }}</div>
                        </div>
                    </div>
                    <div class="message-header">
                        <div>
                            <b>{{ 'from' | lesCustomLocalize : 'label' }}: {{ message.sendingUser.firstName }} {{ message.sendingUser.lastName }}, {{ message.sendingUser.companyName }}</b
                            ><br />
                            <span *ngIf="!message.isGeneralInbox"
                                ><b>{{ 'to' | lesCustomLocalize : 'label' }}: {{ message.receivingUser.firstName }} {{ message.receivingUser.lastName }}, {{ message.receivingUser.companyName }}</b></span
                            >
                            <span *ngIf="message.isGeneralInbox"
                                ><b>{{ 'to' | lesCustomLocalize : 'label' }}: {{ 'generalinbox' | lesCustomLocalize : 'label' }}</b></span
                            >
                        </div>
                        <div>
                            <span class="audit">{{ 'sent' | lesCustomLocalize : 'label' }}: {{ message.sentOn | amFromUtc | amTz : currentUser.preferredTimeZone | amDateFormat : 'M/D/YY h:mm a' }}</span>
                            <span class="audit" *ngIf="message.readOn && mode !== AccessMode.ParticipantView"
                                >| {{ 'read' | lesCustomLocalize : 'label' }}: {{ message.readOn | amFromUtc | amTz : currentUser.preferredTimeZone | amDateFormat : 'M/D/YY h:mm a' }}</span
                            >
                            <span *ngIf="doShowMarkAsRead(message)"
                                ><a (click)="markMessageAsRead(message)">{{ 'markasread' | lesCustomLocalize : 'label' }}</a> <i class="fa fa-spinner fa-pulse fa-fw" *ngIf="message.markAsReadInflight"></i
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="initial-body" [innerHTML]="message.message" *ngIf="message.hasFullThreadAccess"></div>
                    <div *ngIf="!message.hasFullThreadAccess" class="mx-auto p-4 bg-secondary text-center text-white">{{ 'thismessagecannotbeviewedwithoutallaccess' | lesCustomLocalize : 'message' }}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 message-count">
                    <a *ngIf="message.repliesTotalCount > 0" (click)="toggleShowReplies(message)" (mouseenter)="message.commentLinkHover = true" (mouseleave)="message.commentLinkHover = false"
                        ><i [ngClass]="{ fa: true, 'fa-comment-o': !message.commentLinkHover, 'fa-comment': message.commentLinkHover }" aria-hidden="true"></i
                        ><strong> {{ message.repliesTotalCount }} ({{ message.unreadReceiveCount }} {{ 'unread' | lesCustomLocalize : 'label' | lowercase }})</strong></a
                    >
                    <a *ngIf="message.repliesTotalCount === 0 && message.hasFullThreadAccess" (click)="addReply(message)"
                        ><i class="fa fa-reply" aria-hidden="true"></i> <strong>{{ 'addnewreply' | lesCustomLocalize : 'label' }}</strong></a
                    >
                </div>
            </div>

            <div class="message-replies">
                <div class="pt-4" *ngIf="message.showChildren">
                    <div *ngFor="let child of message.childrenMessages">
                        <div class="row" [ngClass]="child.sendingUser.key !== currentUser.key ? 'pr-1' : 'pl-1'">
                            <div class="col-sm-12">
                                <div [ngClass]="child.sendingUser.key !== currentUser.key ? 'text-right' : 'text-left'">
                                    <b>{{ 'from' | lesCustomLocalize : 'label' }}: {{ child.sendingUser.firstName }} {{ child.sendingUser.lastName }}</b>
                                </div>
                                <div class="speech-bubble" [innerHTML]="child.message" [ngClass]="child.sendingUser.key !== currentUser.key ? 'receiver' : 'sender'"></div>
                                <span class="audit">{{ 'sent' | lesCustomLocalize : 'label' }}: {{ child.sentOn | amFromUtc | amTz : currentUser.preferredTimeZone | amDateFormat : 'M/D/YY h:mm a' }}</span
                                ><br />
                                <span class="audit" *ngIf="child.readOn && mode !== AccessMode.ParticipantView"
                                    >{{ 'read' | lesCustomLocalize : 'label' }}: {{ child.readOn | amFromUtc | amTz : currentUser.preferredTimeZone | amDateFormat : 'M/D/YY h:mm a' }}</span
                                >
                                <span *ngIf="doShowMarkAsRead(child)"
                                    ><a (click)="markMessageAsRead(child, message)">{{ 'markasread' | lesCustomLocalize : 'label' }}</a></span
                                >
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div class="text-center" *ngIf="doShowAddReply(message)">
                        <button type="button" class="tertiary-bgbtn btn-primary btn-sm" id="actionId-AddNewReplySecureMessaging" (click)="addReply(message)">
                            <i class="fa fa-reply" aria-hidden="true"></i> {{ 'addnewreply' | lesCustomLocalize : 'label' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-error *ngIf="hasError" [htmlId]="'SecureMessaging'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loadingx' | lesCustomLocalize : 'label' : ('securemessaging' | lesCustomLocalize : 'label')"></app-loading>

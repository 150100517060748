import { Component, OnDestroy, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { AlertModalComponent } from '../../../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { User } from '../../../shared/user/user';
import { MessageParticipantsPost } from '../message-participants-post';
import { SecureMessagingService } from '../secure-messaging.service';

@Component({
  selector: 'app-add-mass-secure-message',
  templateUrl: './add-mass-secure-message.component.html',
  styleUrls: ['./add-mass-secure-message.component.css']
})
export class AddMassSecureMessageComponent implements OnInit, OnDestroy {
  readyForRender = false;
  modalRef: NgbModalRef;
  disableSubmit = false;
  subscriptions: Subscription[] = [];

  coach: User;
  messageBody: string;

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService,
              private _messageService: SecureMessagingService, private _modalService: NgbModal,
              private _santizeService: Sanitizer) { }

  sendMessage(){
    this.disableSubmit = true;
    const messagePost = new MessageParticipantsPost(
      this.coach.key,
      this._santizeService.sanitize(SecurityContext.HTML, this.messageBody)
    );
    this.subscriptions.push(this._messageService.sendMassMessage(messagePost).subscribe( response =>{
      this.disableSubmit = false;
      this.onSuccess();
      this.activeModal.close();
    },
    error => {
      this.disableSubmit = false;
      this.onError();
    }));
  }

  private onSuccess(){
    this.modalRef = this._modalService.open(AlertModalComponent, {
      backdrop: 'static'
    });
    this.modalRef.componentInstance.msg = this._localizeService.get('updatesuccess', 'message');
    this.modalRef.componentInstance.header = this._localizeService.get('success', 'label');
  }

  private onError(msg: string = null){
    alert(msg ? msg : this._localizeService.get('unknownerror', 'message'));
  }


  ngOnInit() {
    this.readyForRender = true;
  }

  ngOnDestroy(){
    this.subscriptions.forEach( sub =>{
      sub.unsubscribe();
    });
  }

}

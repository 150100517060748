<div class="container widgets-page" id="titleId-PersonDemographicWidget">
    <div class="mobile-tight" *ngIf="demographicForm && isRenderReady">
        <div [innerHTML]="personWidget.descriptionLanguage | safeHtml"></div>
        <form [formGroup]="demographicForm" class="les-input-view-mobile" id="formId-PersonDemographicWidget" novalidate>
            <div [formGroup]="demographicAddressForm" class="form-group" novalidate>
                <div class="primary-coverage-section">
                    <div class="container form-group mobile-field">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="les-label" for="address">{{ 'address' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                                <app-addressautocomplete [settings]="demographicAddressAutocompleteSettings" formControlName="demographicAddressAutocompleteQuery" (change)="checkAddressInputValidity()"></app-addressautocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="address2">{{ 'address2' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="address2" id="address2" formControlName="address2" />
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="city">{{ 'label.city' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="city" id="city" required formControlName="city" />
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="stateKey">{{ 'state' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <select name="stateKey" id="stateKey" required class="form-control" formControlName="stateKey" (change)="onStateKeyChange($event.target.value)">
                                <option value="">{{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}</option>
                                <option *ngFor="let state of states" [value]="state.key">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="zipCode">{{ 'zipcode' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <input type="text" maxlength="5" pattern="\d*" class="form-control" name="zipCode" id="zipCode" required formControlName="zipCode" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group mobile-field">
                <div class="col-sm-12">
                    <label class="les-label" for="phoneNumber">{{ 'phonenumber' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                    <input type="text" class="form-control" name="phone" id="phone" required formControlName="phoneNumber" placeholder="555-555-5555" pattern="^\s*(?:\+?\d{1,3})?[- (]*\d{3}(?:[- )]*\d{3})?[- ]*\d{4}(?: *[x/#]\d+)?\s*$" />
                </div>
            </div>
            <div class="form-group mobile-field">
                <div class="col-sm-12">
                    <label class="les-label" for="ethnicity">{{ 'ethnicity' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                    <select name="ethnicityKey" id="ethnicityKey" class="form-control" required formControlName="ethnicityKey" minlength="32">
                        <option value="null">
                            {{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}
                        </option>
                        <option *ngFor="let ethnicity of ethnicities" [value]="ethnicity.key">
                            {{ ethnicity.labcorpValue | lesCustomLocalize : 'ethnicity' : null : null : tagOptions }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group mobile-field">
                <div class="col-sm-12">
                    <label class="les-label" for="race">{{ 'race' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                    <select name="raceKey" id="raceKey" class="form-control" required formControlName="raceKey" minlength="32">
                        <option value="null">
                            {{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}
                        </option>
                        <option *ngFor="let race of races" [ngValue]="race.key">
                            {{ race.labcorpValue | lesCustomLocalize : 'race' : null : null : tagOptions }}
                        </option>
                    </select>
                </div>
            </div>
            <ng-container *ngIf="isSOGI">
                <div class="form-group">
                    <div class="col-sm-12">
                        <label class="les-label" for="gender">{{ 'genderidentity' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                        <select name="genderIdentityKey" id="genderIdentityKey" class="form-control" required formControlName="genderIdentityKey" (change)="onGenderChange()">
                            <option value="null">
                                {{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}
                            </option>
                            <option *ngFor="let g of genderIdentityList" [ngValue]="g.key">
                                {{ getTranslation('gender.code.' + g.labcorpValue.toLowerCase()) }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group" *ngIf="userInformation.genderIdentityKey == 'f3ffab18-7329-4e4f-8e03-6872ed234bd8'">
                    <div class="col-sm-12">
                        <label class="les-label" for="genderIdentityOther">{{ 'genderIdentityOther' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                        <input type="text" name="genderIdentityOther" id="genderIdentityOther" required formControlName="genderIdentityOther" class="form-control" minlength="1" maxlength="60" />
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-sm-12">
                        <label class="les-label" for="sexual-orientation">{{ 'sexualorientation' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                        <select name="sexualOrientationKey" id="sexualOrientationKey" required formControlName="sexualOrientationKey" class="form-control" (change)="onSexualOrientationChange()">
                            <option value="null">
                                {{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}
                            </option>
                            <option *ngFor="let s of sexualOrientationList" [ngValue]="s.key">
                                {{ getTranslation('sexualorientation.code.' + s.labcorpValue.toLowerCase()) }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group" *ngIf="userInformation.sexualOrientationKey == '24303c0c-1844-4254-bdb5-3896ed251fcf'">
                    <div class="col-sm-12">
                        <label class="les-label" for="sexualOrientationOther">{{ 'sexualOrientationOther' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                        <input type="text" name="sexualOrientationOther" id="sexualOrientationOther" class="form-control" required formControlName="sexualOrientationOther" minlength="1" maxlength="60" />
                    </div>
                </div>
            </ng-container>

            <div class="alert alert-danger" *ngIf="showError && isFormInvalid">
                <button type="button" class="close" id="actionId-ClosePersonDemographicWidgetErrorAlert" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span [innerHTML]="'message.invalidform' | lesCustomLocalize : '' : null : null : tagOptions"></span>
            </div>

            <div class="alert alert-danger" *ngIf="showError && errorMsg?.length > 0 && errorMsg == 'invalidAddress' && !isFormInvalid">
                <button type="button" class="close" id="actionId-ClosePersonDemographicWidgetErrorAlert" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span [innerHTML]="'message.invalidaddress' | lesCustomLocalize : '' : null : null : tagOptions"></span>
            </div>
            <div class="alert alert-danger" *ngIf="showError && errorMsg?.length > 0 && errorMsg != 'invalidAddress' && !isFormInvalid">
                <button type="button" class="close" id="actionId-ClosePersonDemographicWidgetErrorAlert" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span [innerHTML]="'message.invalidform' | lesCustomLocalize : '' : null : null : tagOptions"></span>
            </div>

            <div class="form-group row mobile-field" *ngIf="personWidget.footerLanguage">
                <div class="col-sm-12">
                    <p [innerHTML]="personWidget.footerLanguage | safeHtml"></p>
                </div>
            </div>

            <nav class="widget-nav" aria-label="Page navigation">
                <ul class="pagination justify-content-center">
                    <li *ngIf="personWidget.position > 0" [ngClass]="{ 'page-item': true, disabled: personWidget.position == 0 }">
                        <a class="les-widget-link" id="titleId-PreviousPatientInsuranceWidget" (click)="onPreviousButtonClick()" [attr.disabled]="personWidget.position == 0 ? 'true' : null">Previous</a>
                    </li>
                    <li [ngClass]="{ 'page-item': true }">
                        <a class="les-widget-link" (click)="onNextButtonClick(isValid())" id="actionId-NextPersonDemographicWidget"> {{ 'button.next' | lesCustomLocalize : '' : null : null : tagOptions }} </a>
                    </li>
                </ul>
            </nav>
        </form>
    </div>
</div>


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { LocalizeService } from './shared/localize/localize.service';
import { PersonService } from './shared/person/person.service';

@Injectable()
export class CoachAdminGuard {
  constructor(private _personService: PersonService, private _router: Router, private _localizeService: LocalizeService) { }

  canActivate() {
    return this._personService.currentPersonReplay.pipe(map(person => {
      if (person.isCoachAdmin) {
        return true;
      } else {
        this.noAccess();
        this._router.navigate(['/']);
        return false;
      }
    }));
  }

  private noAccess() {
    window.alert(this._localizeService.get('noaccess', 'message'));
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from "lodash";
import moment, { tz } from 'moment-timezone';
import { Subscription } from 'rxjs';

import { AppointmentViewerComponent } from '../../../coaching/appointments/appointment-viewer/appointment-viewer.component';
import { CoachingAppointmentService } from '../../../coaching/appointments/appointments.service';
import { LabcorpAppointSchedulerComponent } from '../../../services/appointments/labcorp.appointment.component';
import { AccessMode } from '../../../shared/access-mode';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { ColumnSetting } from '../../../shared/wcs-table/column-setting';
import { WcsTableComponent } from '../../../shared/wcs-table/wcs-table.component';
import { ApiAppointment } from '../../model/api-appointment';
import { SchedulerService } from '../../scheduler.service';
import { CancelConfirmComponent } from '../cancel-confirm/cancel-confirm.component';
import { ResendConfirmationEmailComponent } from '../resend-confirmation-email/resend-confirmation-email.component';

@Component({
  selector: 'app-upcoming-appointments',
  templateUrl: './upcoming-appointments.component.html',
  styleUrls: ['./upcoming-appointments.component.css']
})
export class UpcomingAppointmentsComponent implements OnInit, OnDestroy {
  @ViewChild(WcsTableComponent) tableComponent: WcsTableComponent;
  @Input() mode: AccessMode;
  @Input() otherUser: Person;
  @Input() participant: Person;

  accessMode = AccessMode;
  hasError = false;
  readyForRender = false;
  localizeReady = false;
  isLoadingAppointments = false;
  screeningAppointmentsRetrieved = false;
  pscAppointmentsRetrieved = false;
  coachingAppointmentsRetrieved = false;
  subscriptions: Subscription[] = [];
  currentUser: Person;
  activeAppointments: ApiAppointment[] = [];
  modalRef: NgbModalRef;
  showExistingAppointments = true;

  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'timeText',
      headerLocalizeProp: 'date',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'confirmationNumber',
      headerLocalizeProp: 'confirmationNumber',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'serviceDisplayName',
      headerLocalizeProp: 'servicetype',
      format: 'programTypeToken',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'locationText',
      headerLocalizeProp: 'location',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'appointmentFor',
      headerLocalizeProp: 'appointmentfor',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'manageText',
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: null,
        onClick: this.modifyAppointment
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.isActiveAppointment,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'cancel',
        onClick: this.cancelAppointment
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showCancelAppointment,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'viewconfirmation',
        onClick: this.viewConfirmation
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showViewConfirmation,
          val: true
        }]
    }, {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'Resend',
        onClick: this.resendConfirmationEmail
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showResendConfirmationEmail,
          val: true
        }]
    }
  ];

  constructor(private _localizeService: LocalizeService, private _schedulerService: SchedulerService,
    private _personService: PersonService, private _modalService: NgbModal, private _router: Router, private _apptService: CoachingAppointmentService) { }

  public UpdateAppointmentList() {
    this.getAppointments();
    this.tableComponent.detectChanges();
  }
  buildAppointmentLink(appt: ApiAppointment) {
    return "foo";
  }
  cancelAppointment(appt: ApiAppointment) {
    if (!appt.isCanceled) {
      let modalRef: NgbModalRef;
      //perform action based on appointment type
      switch (appt.appointmentType) {
        case "Coaching":
          // not implemented
          break;
        case "PatientServiceCenter":
          modalRef = this._modalService.open(LabcorpAppointSchedulerComponent, {
            backdrop: "static"
          });
          modalRef.componentInstance.apiAppointment = appt;
          modalRef.componentInstance.currentUser = this.otherUser ? this.otherUser : this.currentUser;
          modalRef.componentInstance.isCancel = true;
          modalRef.result.then((result) => {
            if (result) {
              this.getAppointments();
              this.tableComponent.detectChanges();
            }
          });
          break;
        default:
          modalRef = this._modalService.open(CancelConfirmComponent, { backdrop: 'static' });
          modalRef.result.then((result) => {
            if (result === 'submit') {
              this.getAppointments();
              this.tableComponent.detectChanges();
            }
          });

          modalRef.componentInstance.currentUser = this.currentUser;
          modalRef.componentInstance.appointmentKey = appt.key;
          modalRef.componentInstance.appointmentUserKey = appt.owner.key;
          break;
      }
    }
  }

  showCancelAppointment(appt: ApiAppointment) {
    if (appt.appointmentType != null) {
      return false;
    }

    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();

    return appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  viewConfirmation(appt: ApiAppointment) {
    window.open(appt.confirmationUrl, '_blank');
  }

  showViewConfirmation(appt: ApiAppointment) {
    if (appt.appointmentType !== "PatientServiceCenter") {
      return false;
    }

    return (appt.confirmationUrl != null && appt.confirmationUrl.length > 0);
  }

  modifyAppointment(appt: ApiAppointment) {
    if (!appt.isCanceled) {
      let modalRef: NgbModalRef;
      //perform action based on appointment type
      switch (appt.appointmentType) {
        case "Coaching":
          this.viewCoachingAppt(appt);
          break;
        case "PatientServiceCenter":
          modalRef = this._modalService.open(LabcorpAppointSchedulerComponent, {
            backdrop: "static"
          });
          modalRef.componentInstance.apiAppointment = appt;
          modalRef.componentInstance.currentUser = this.otherUser ? this.otherUser : this.currentUser;
          modalRef.result.then((result) => {
            if (result) {
              this.getAppointments();
              this.tableComponent.detectChanges();
            }
          });
          break;
        default:
          const userKey = this.getUserOfInterest().userKey;
          if (this.currentUser.userKey === userKey) {
            this._router.navigate(['/scheduler']);
          }
          else {
            this._router.navigate(['/scheduler'], { queryParams: { user: userKey } });
          }
          break;
      }
    }
  }

  viewCoachingAppt(appt: ApiAppointment) {
    const that = this;

    this.modalRef = this._modalService.open(AppointmentViewerComponent, {
      backdrop: 'static',
      windowClass: 'fs-modal',
      beforeDismiss: function () {
        //that.getAppts(true);
        that.tableComponent.detectChanges();
        return true;
      }
    });

    this.modalRef.componentInstance.participant = this.getUserOfInterest();
    this.modalRef.componentInstance.confirmationNumber = appt.itineraryKey;
  }

  isActiveAppointment(appt: ApiAppointment) {
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();

    // toggle labels based on appointment type
    if (appt.appointmentType != null) {
      appt.manageText = this._localizeService.get("viewmodifyappointment", "label");
    } else {
      appt.manageText = this._localizeService.get("modify", "label");
    }

    return appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  checkReady() {
    if (this.localizeReady && !this.isLoadingAppointments) {
      this.readyForRender = true;
    }
  }

  checkAppointmentsReady() {
    if (this.screeningAppointmentsRetrieved && this.pscAppointmentsRetrieved && this.coachingAppointmentsRetrieved) {
      this.isLoadingAppointments = false;
    }
  }

  getAppointments() {
    if (this.isLoadingAppointments) {
      return;
    }
    this.isLoadingAppointments = true;

    const that = this;
    this.activeAppointments = [];

    this.subscriptions.push(this._schedulerService.getAppointments(this.getUserOfInterest().userKey).subscribe(appts => {
      that.screeningAppointmentsRetrieved = true;

      if (appts.length) {
        that.activeAppointments = that.activeAppointments.concat(appts.filter(appt => that.isActiveAppointment(appt)));
        that.activeAppointments = _.sortBy(that.activeAppointments, function (o) {
          return moment(o.time);
        });
      }
      that.checkAppointmentsReady();
      that.checkReady();

      if (that.tableComponent) {
        that.tableComponent.detectChanges();
      }
    }, error => {
      this.screeningAppointmentsRetrieved = true;
      that.checkAppointmentsReady();
      that.checkReady();
    }));

    this.subscriptions.push(this._schedulerService.getPscAppointments(that.getUserOfInterest().userKey).subscribe(appts => {
      that.pscAppointmentsRetrieved = true;

      if (appts.length) {
        that.activeAppointments = that.activeAppointments.concat(appts.filter(appt => that.isActiveAppointment(appt)));
        that.activeAppointments = _.sortBy(that.activeAppointments, function (o) {
          return moment(o.time);
        });
      }
      that.checkAppointmentsReady();
      that.checkReady();

      if (that.tableComponent) {
        that.tableComponent.detectChanges();
      }
    }, error => {
      this.pscAppointmentsRetrieved = true;
      that.checkAppointmentsReady();
      that.checkReady();
    }));

    this.subscriptions.push(this._apptService.getUpcomingAppts(this.getUserOfInterest().userKey, false, this.currentUser).subscribe(appts => {
      that.coachingAppointmentsRetrieved = true;

      if (appts.length) {
        const activeAppts = appts.filter(appt => that.isActiveAppointment(appt));

        if (activeAppts.length) {
          activeAppts.forEach((appt) => {
            appt.timezone = moment.tz(appt.timeUtc, that.getUserOfInterest().preferredTimeZone).format('z');
            appt.appointmentType = "Coaching";
            if (that._localizeService.getTagExists(appt.location.address1, "label")) {
              appt.location.address1 = that._localizeService.get(appt.location.address1, "label");
            }
          });
          that._schedulerService.hasActiveAppointments = true;
          that.activeAppointments = that.activeAppointments.concat(activeAppts);
          that.activeAppointments = _.sortBy(that.activeAppointments, function (o) {
            return moment(o.time);
          });
        }
      }
      that.checkAppointmentsReady();
      that.checkReady();

      if (that.tableComponent) {
        that.tableComponent.detectChanges();
      }
    }, error => {
      this.coachingAppointmentsRetrieved = true;
      that.checkAppointmentsReady();
      that.checkReady();
    }));
  }

  getUserOfInterest() {
    return this.otherUser ? this.otherUser : this.currentUser;
  }

  toggleExistingAppointments($event) {
    $event.preventDefault();
    this.showExistingAppointments = !this.showExistingAppointments;
  }
  resendConfirmationEmail(appt: ApiAppointment) {
    const modalRef = this._modalService.open(ResendConfirmationEmailComponent, { backdrop: 'static' });
    modalRef.result.then((result) => {
      if (result === 'submit') {
        this.getAppointments();
        this.tableComponent.detectChanges();
      }
    });
    modalRef.componentInstance.currentUser = this.getUserOfInterest();
    modalRef.componentInstance.appointmentKey = appt.key;
    modalRef.componentInstance.appointmentUserKey = appt.owner.key;
  }
  showResendConfirmationEmail(appt: ApiAppointment) {
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();
    const validUser = (this.currentUser.isWcsAdmin || this.currentUser.isSupportUser
      || this.currentUser.isPartnerAdmin || this.currentUser.isCustomerAdmin);

    if (appt.appointmentType === "PatientServiceCenter") {
      return false;
    }
    return validUser && appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentUser = currentPerson;

        this.getAppointments();
      }
    }));

    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
        this.checkReady();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}

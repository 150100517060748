import { Component, OnDestroy,OnInit } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js/auto';
import _ from 'lodash';
import { DateFormatPipe } from 'ngx-moment';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { ResultDataPointMapping } from '../model/mappings';
import { Result } from '../model/result';
import { ResultsService } from '../results.service';

@Component({
  selector: 'app-result-chart',
  templateUrl: './result-chart.component.html',
  styleUrls: ['./result-chart.component.css']
})
export class ResultChartComponent implements OnInit, OnDestroy {
  canvas: any;
  ctx: any;

  subscriptions: ISubscription[] = [];
  mappings: ResultDataPointMapping[] = [];
  results: Result[];
  hasError: boolean;
  readyForRender = false;
  chart;
  currentPerson: Person = null;
  combinedReady = false;

  constructor(private _localizeService: LocalizeService, private _resultService: ResultsService,
    private _dateFormatPipe: DateFormatPipe, private _personService: PersonService) { }

  onSelect(fieldName) {
    this.updateChart(fieldName);
  }

  getMappingGroup() {
    let filtered = _.filter(this.mappings, (map: ResultDataPointMapping) => {
      return map.displayInfo.secondaryDisplay !== true && map.displayInfo.includeInTrendChart === true;
    });
    filtered = _.uniqBy(filtered, 'fieldName');
    filtered = _.orderBy(filtered, (map: ResultDataPointMapping) => { return map.displayInfo.fieldSort; });
    filtered = _.orderBy(filtered, (map: ResultDataPointMapping) => { return map.displayInfo.groupSort; });

    return filtered;
  }
  getSecondaryMaps(map) {
    if (!map.displayInfo.combineWithField && !this.mappings) { return null; }
    else {
      const filterByDate = _.filter(this.mappings, (mp: ResultDataPointMapping) => {
        return mp.serviceDate && mp.serviceDate.getTime() === map.serviceDate.getTime();
      });
      const secondaryMap = _.find(filterByDate, (mp: ResultDataPointMapping) => {
        return mp.displayInfo.fieldName === map.displayInfo.combineWithField;
      });

      return secondaryMap;

    }
  }

  initChart(): void {
    if (!this.readyForRender) {
      return;
    }
    const chartGroup = document.getElementById("chart-group");
    chartGroup.removeAttribute("hidden");
    const defaultChart = _.head(this.getMappingGroup());
    if (defaultChart) { this.updateChart(defaultChart.fieldName); }
  }

  checkReady() {
    this.readyForRender = this.combinedReady && this.currentPerson !== null;
    return this.readyForRender;
  }

  ngOnInit() {
    this.subscriptions.push(this._resultService.getCombined().subscribe(combined => {
      this.results = combined[0].results;
      _.forEach(this.results, (result: Result) => {
        this.mappings = this.mappings.concat(this._resultService.getMappings(result, combined[1]));
      });
      this.mappings = this.getOnlyChart(this.mappings);
      this.combinedReady = true;
      this.checkReady();
      this.initChart()
    }));

    this.subscriptions.push(this._resultService.resultError.subscribe(resultsError => {
      if (resultsError) {
        this.onError(resultsError);
      }
    }));

    this.subscriptions.push(this._resultService.resultLookupError.subscribe(resultsError => {
      if (resultsError) {
        this.onError(resultsError);
      }
    }));

    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentPerson = currentPerson;

        this.checkReady();
        this.initChart();
      }
    }));
  }

  private getOnlyChart(mappings: ResultDataPointMapping[]) {
    return _.filter(mappings, (map: ResultDataPointMapping) => {
      return map.displayInfo.includeInTrendChart === true;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function () { });
  }
  private onError(errorMessage) {
    console.log(errorMessage)
    this.hasError = true;
  }
  private updateChart(fieldName) {

    let chartMappings = _.filter(this.mappings, (map: ResultDataPointMapping) => {
      return map.fieldName === fieldName;
    });
    if (chartMappings.length > 0) {
      chartMappings = _.orderBy(chartMappings, ['serviceDate']);
    }

    if (this.chart) {
      this.chart.destroy();
    }
    this.canvas = document.getElementById('myChart');
    if (!this.canvas)
      return;
    this.ctx = this.canvas.getContext('2d');

    if (chartMappings.length > 0) {
      const colors = [];
      const dates = [];
      const dataPts = [];
      const dataPtsSecondary = [];
      let displayName;
      const riskDisplays = [];
      const defaultColor = 'rgba(105, 105, 105, 0.8)';

      _.forEach(chartMappings, (map: ResultDataPointMapping) => {
        if (parseInt(map.resultProperty, 10) >= 0 && map.serviceDate) {//remove those without service dates
          displayName = this._localizeService.get(map.displayInfo.localizedName, 'label');
          dates.push(this._dateFormatPipe.transform(map.serviceDate, 'M/D/YYYY'));
          dataPts.push(map.resultProperty);

          const references = this._resultService.getRiskReferences(this.currentPerson, map);
          const secondaryMap = this.getSecondaryMaps(map);
          let riskReference;
          if (secondaryMap) {

            riskReference = this._resultService.getHigherRiskReference(this.currentPerson, map, secondaryMap);
          }
          else {
            riskReference = _.first(references);
          }

          if (references.length > 0) {
            colors.push(riskReference.riskColor);
            riskDisplays.push(riskReference.riskDisplay);
          }
          else {
            colors.push(defaultColor);
            riskDisplays.push('');
          }
          if (secondaryMap) {
            dataPtsSecondary.push(secondaryMap.resultProperty);
          }
        }
      });
      const chartConfig = {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: displayName,
              data: dataPts,
              borderColor: defaultColor,
              backgroundColor: colors,
              borderWidth: 2,
              tension: 0,
              pointStyle: 'circle',
              pointRadius: 5,
              fill: false
            },
            {
              label: displayName,
              data: dataPtsSecondary,
              borderColor: defaultColor,
              backgroundColor: colors,
              borderWidth: 2,
              tension: 0,
              pointStyle: 'circle',
              pointRadius: 5,
              fill: false
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: (tooltipItems) => {
                  const dataIndex = tooltipItems[0].dataIndex;
                  return riskDisplays[dataIndex] || '';
                }
              }
            }
          },
          scales: {
            y: {
              min: Math.floor(Math.min(...dataPts.concat(dataPtsSecondary)) * 0.9),
              max: Math.ceil(Math.max(...dataPts.concat(dataPtsSecondary)) * 1.1)
            },
            x: {
              offset: true
            }
          }
        }
      }
      this.chart = new Chart(this.ctx, this.createCharConfiguration(chartConfig));
    }
  }
  createCharConfiguration({ data, options }): ChartConfiguration {
    return {
      type: 'line',
      data: data,
      options: options
    } as ChartConfiguration
  }


}

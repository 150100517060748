import moment from 'moment';

import { Globals } from './globals';

export class Utils {
  public static toUTC(date: Date): any {
    return moment(date).utc().toDate();
  }

  public static windowHeight(): number {
    return window.innerHeight - 75;
  }

  public static daysBetween(date1, date2) {
    const day = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.round(difference_ms / day);
  }

  public static calculateDistance(lat1, lon1, lat2, lon2, unit = null) {
    //If lat's and lon's are the same (i.e., same location)
    //no need to calculate, just return 0;
    if ((lat1 == lat2) && (lon1 == lon2)) { return 0; }

    /* The check above is necessary as Math.acos(dist) (see line below) will return NaN,
     * as the value of dist (at that point) does not fall within the -1 to 1 range for Math.acos().
     *
     * All remaining calculations fail, and ultimately NaN is returned
     * to the calling function.
     *
     * When a user searched for Locations by Zip in the scheduler-component (w/o the check above),
     * event locations that had the same lat/lon coordinates of the specified Zip,
     * were removed from the location dropdown due to the NaN value, even though the distance
     * was within the selected radius.
    */
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }
    return dist;
  }

  public static dayOfWeek(dt: Date): string {
    let dayOfWeek = '';

    switch (dt.getDay()) {
      case 0:
        dayOfWeek = 'Sunday';
        break;
      case 1:
        dayOfWeek = 'Monday';
        break;
      case 2:
        dayOfWeek = 'Tuesday';
        break;
      case 3:
        dayOfWeek = 'Wednesday';
        break;
      case 4:
        dayOfWeek = 'Thursday';
        break;
      case 5:
        dayOfWeek = 'Friday';
        break;
      case 6:
        dayOfWeek = 'Saturday';
        break;
    }

    return dayOfWeek;
  }

  public static isEquivalent(a, b) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (const propName of aProps) {
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  public static getFastingStateToken(fastingState: string) {
    if (fastingState) {
      const state = fastingState.toLowerCase().replace('-', '');
      return Globals.FastingTokens[state];
    } else {
      return '';
    }
  }

  public static getServiceToken(serviceName: string) {
    if (serviceName) {
      const service = serviceName.toLocaleLowerCase().trim().replace(/[^a-z0-9]/g, '');
      const serviceToken = Globals.ServiceTokens[service];
      if (serviceToken) {
        return serviceToken;
      }
      else {
        return serviceName;
      }
    }
    else {
      return '';
    }

  }

  public static getServiceDescriptions(desc: string) {
    if (desc) {
      const serviceDesc = desc.trim().replace(/[^a-zA-z]/g, '');
      const serviceDescToken = Globals.ServiceDescriptionTokens[serviceDesc];
      if (serviceDescToken) {
        return serviceDescToken;
      }
      else {
        return desc;
      }
    }
    else {
      return '';
    }
  }

  public static isMobileDevice() {
    return (/android|webos|iphone|ipad|ipod|blackberry|windows phone/).test(navigator.userAgent.toLowerCase());
  }
  public static isValidJsDate(value: Date): boolean {
    if (!value) {
      return false;
    }
    value = new Date(value);
    const dateValues: any = {
      month: parseInt(value.getMonth().toString()) + 1,
      day: parseInt(value.getDate().toString()),
      year: parseInt(value.getFullYear().toString())
    };
    let month = parseInt(dateValues.month, 10) + '';
    if (month && month.toString().length < 2) {
      month = `0${month}`;
    }
    let day = parseInt(dateValues.day, 10) + '';
    if (day && day.toString().length < 2) {
      day = `0${day}`;
    }
    const year = parseInt(dateValues.year);
    const aDate = [year, month, day].join('-');
    const m = moment(aDate, 'YYYY-MM-DD', true);
    return m.isValid()
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../localize/localize.service';
import { SevereSymptomCapture } from './severe-symptom';
import { SevereSymptomService } from './severe-symptom.service';

@Component({
  selector: 'app-severe-symptom-modal',
  templateUrl: './severe-symptom-modal.component.html',
  styleUrls: ['./severe-symptom-modal.component.css']
})
export class SevereSymptomModalComponent implements OnInit {
  @Input() displaySevereSymptomWarning: boolean;
  @Input() userKey: string;
  header: string = this._localizeService.get('severesymptommodalheader', 'label');

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, private _severeSymptomService: SevereSymptomService) { }
  ngOnInit(): void {
    console.info('SevereSymptomModalComponent called.');
  }


  confirmSevereSymptoms() {
    const answerCapture = new SevereSymptomCapture();
    answerCapture.IsExperiencingSevereSymptoms = true;
    answerCapture.userKey = this.userKey;
    this._severeSymptomService.captureResponse(answerCapture)
      .subscribe({
        complete: () => {
          this.displaySevereSymptomWarning = true;
        },
        error: () => {
          alert(this._localizeService.get('error', 'message'));
          return;
        }
      });
  }

  denySevereSymptoms() {
    const answerCapture = new SevereSymptomCapture();
    answerCapture.IsExperiencingSevereSymptoms = false;
    answerCapture.userKey = this.userKey;
    this._severeSymptomService.captureResponse(answerCapture)
      .subscribe(
        {
          complete: () => this.activeModal.close(false),
          error: () => {
            alert(this._localizeService.get('error', 'message'));
            return;
          },
        }
      );
  }

  dismissWarning() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close();
  }
}

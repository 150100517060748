import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AbbreviatedUser } from '../../shared/abbreviated-user';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MessageToUser } from './message-to-user';
import { NewMessageService } from './new-message.service';
import { NewSecureMessage } from './new-secure-message';
import { SecureMessage } from './secure-message';
import { SecureMessagingService } from './secure-messaging.service';

@Component({
  selector: 'app-new-message',
  templateUrl: './add-secure-message.component.html',
  styleUrls: ['./add-secure-message.component.css']
})
export class AddSecureMessageComponent implements OnInit, OnDestroy {
  @Input() toUser: AbbreviatedUser;
  @Input() regardingKey: string;
  @Input() parentMessageKey: string;
  @Input() isGeneralInbox = false;
  @Input() unreadKeys: string[];

  toUserDynamic: MessageToUser;
  messageBody: string;
  toUsers: MessageToUser[] = [];
  subscriptions: ISubscription[] = [];
  disableSubmit = false;
  hasError = false;

  constructor(public activeModal: NgbActiveModal, private _secureMessageService: SecureMessagingService,
    private _sanitizer: DomSanitizer, private _newMessageService: NewMessageService, private _localizeService: LocalizeService) {
  }

  sendMessage() {
    this.disableSubmit = true;

    const msg = this.buildNewMessage();
    this.subscriptions.push(this._secureMessageService.createSecureMessage(msg)
      .subscribe(newMsg => {
        if (this.unreadKeys && this.unreadKeys.length) {
          this._secureMessageService.markAsRead(this.unreadKeys).subscribe( r => {
            this._newMessageService.setLatestMessage(newMsg);
            this.activeModal.close();
          });
        } else {
          this._newMessageService.setLatestMessage(newMsg);
          this.activeModal.close();
        }
      }, (err) => {
          if (err && err.status) { 
              switch (err.status) {
                case 401:
                case 403:
                  alert(this._localizeService.get('unauthorizedaccess', 'message'));
                  break;
                default:
                  alert(this._localizeService.get('unknownerror', 'message'));
                  break;
              }
            
          } else {
            alert(this._localizeService.get('unknownerror', 'message'));
          }       
        this.disableSubmit = false;
      }));
  }

  private buildNewMessage(): NewSecureMessage {
    const msg = new NewSecureMessage();
    msg.regardingKey = this.regardingKey ? this.regardingKey : null;
    msg.parentMessageKey = this.parentMessageKey ? this.parentMessageKey : null;
    msg.message = this._sanitizer.sanitize(SecurityContext.HTML, this.messageBody);
    msg.isGeneralInbox = this.toUserDynamic && this.toUserDynamic.key === 'generalinbox' || this.isGeneralInbox === true;
    msg.receivingUserKey = this.toUser ? this.toUser.key : (msg.isGeneralInbox ? null : this.toUserDynamic.key);

    return msg;
  }

  private onError(msg: string = null) {
    if (!this.toUser) {
      this.hasError = true;
      if (this.toUsers.length === 0) {
        this.addGeneralInbox();
      }
    }
  }

  private addGeneralInbox() {
    const generalInbox = new MessageToUser({
      key: 'generalinbox',
      firstName: this._localizeService.get('generalinbox', 'label'),
      // generalInbox.company: this._localizeService.get('wellnesscorporateservices', 'label');
      biography: this._localizeService.get('generalinboxbio', 'message')
    });
    this.toUsers.push(generalInbox);
  }

  ngOnInit() {
    this.subscriptions.push(this._secureMessageService.getMessageToUsers()
      .subscribe(users => {
        this.toUsers = users;

        this.addGeneralInbox();
      },
      error => this.onError()));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}

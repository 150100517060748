<div class="container widgets-page patient-insurance" id="titleId-PatientInsuranceWidget">
    <div class="mobile-tight" *ngIf="insuranceForm && isRenderReady">
        <div [innerHTML]="personWidget.descriptionLanguage | safeHtml"></div>

        <form [formGroup]="insuranceForm" class="insurance-form" id="formId-PatientInsuranceWidget" novalidate>
            <div>
                <div class="form-group mobile-field">
                    <div class="col-sm-12">
                        <input
                            type="checkbox"
                            class="inline-checkbox"
                            value="yes"
                            [disabled]="isInsurancePreferred && !primaryCoverageNo && !hasInvalidPrimaryCoverage"
                            [checked]="primaryCoverageYes"
                            id="primaryCoverageYes"
                            name="primaryCoverageYes"
                            (click)="toggleShowPrimary($event)"
                        />
                        <label class="les-label" for="primaryCoverageSelection" for="primaryCoverageYes">
                            {{ 'label.paymenttypeinsurance' | lesCustomLocalize : '' : null : null : tagOptions }}
                        </label>
                        <div class="les-sencondary-label mb-2">{{ 'message.paymenttypeinsurance' | lesCustomLocalize : '' : null : null : tagOptions }}</div>
                    </div>
                </div>

                <div class="primary-coverage-section">
                    <div class="form-group mobile-field" *ngIf="showPrimary">
                        <div class="col-sm-12">
                            <label class="les-label" for="firstName">{{ 'label.firstname' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="firstName" id="firstName" required formControlName="firstName" />
                        </div>
                    </div>

                    <div class="form-group mobile-field" *ngIf="showPrimary">
                        <div class="col-sm-12">
                            <label class="les-label" for="lastName">{{ 'label.lastname' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="lastName" id="lastName" required formControlName="lastName" />
                        </div>
                    </div>

                    <div class="form-group mobile-field" *ngIf="showPrimary">
                        <div class="col-sm-12">
                            <label class="les-label" for="dateOfBirth">{{ 'label.dateofbirth' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="dateOfBirth" id="dateOfBirth" required formControlName="dateOfBirth" mask="00/00/0000" placeholder="MM/DD/YYYY" />
                        </div>
                    </div>

                    <div class="form-group mobile-field" *ngIf="showPrimary">
                        <div class="col-sm-12">
                            <label class="les-label" for="state">{{ 'label.stateofservice' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                            <span [ngbTooltip]="statePopup">
                                <i class="fa fa-info-circle"></i>
                            </span>
                            <ng-template #statePopup>
                                <span>
                                    {{ 'tooltip.stateofservicetooltip' | lesCustomLocalize : '' : null : null : tagOptions }}
                                </span>
                            </ng-template>
                            <select name="state" id="state" required class="form-control" formControlName="state" (change)="filterByStateSelection($event.target.value)">
                                <option value="">{{ 'noneselected' | lesCustomLocalize : 'label' }}</option>
                                <option *ngFor="let state of coverageSelectedStates" [value]="state.key">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <ng-container *ngIf="primaryCoverageYes == true && showPrimary">
                        <div class="form-group mobile-field">
                            <div class="col-sm-12">
                                <label class="les-label" for="labcorpPayerCode">{{ 'label.primaryhealthcoverage' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                                <label *ngIf="isOrgProvidedInsurance">{{ 'message.insurancebyorg' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                                <select name="labcorpPayerCode" id="labcorpPayerCode" required class="form-control" formControlName="labcorpPayerCode" (change)="onPrimaryCoverageSelection($event.target.value)">
                                    <option value="">{{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}</option>
                                    <option *ngFor="let payor of coverageStateChoices" [value]="payor.key">
                                        {{ payor?.organization?.name }}
                                    </option>
                                </select>
                                <div class="medicare-advantage" *ngIf="isMedicareSelected">
                                    <div [innerHTML]="'message.medicareadvantagerequired' | lesCustomLocalize : '' : null : null : tagOptions" class="les-information-text"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isMedicareSelected && showPrimary && insuranceForm.get('labcorpPayerCode').value != '' && insuranceForm.get('labcorpPayerCode').value != '00000000-0000-0000-0000-000000000000'">
                        <div class="form-group mobile-field">
                            <div class="col-sm-12">
                                <label class="les-label" for="medicareNumber">{{ 'label.medicarenumber' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                                <input type="text" class="form-control" name="medicareNumber" id="medicareNumber" mask="0SA0-SA0-SS00" formControlName="medicareNumber" />
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isMedicareSelected && hasLabcorpPayerCodeSelected && primaryCoverageYes == true">
                        <div class="form-group mobile-field">
                            <div class="col-sm-12">
                                <label class="les-label" for="subscriberId">{{ 'label.primaryhealthcoverageid' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                                <input type="text" class="form-control" name="subscriberId" id="subscriberId" required formControlName="subscriberId" />
                            </div>
                        </div>
                        <div class="form-group mobile-field secondary">
                            <div class="col-sm-12">
                                <label class="les-label" for="groupnumber">{{ 'label.primaryhealthcoveragegroup' | lesCustomLocalize : '' : null : null : tagOptions }}</label>
                                <input type="text" class="form-control" name="groupNumber" id="groupNumber" formControlName="groupNumber" />
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div [formGroup]="addressInsuranceForm" class="form-group" *ngIf="addressVerificationRequired && showPrimary" novalidate>
                <div class="form-group mobile-field">
                    <div class="col-sm-12">
                        <div class="les-sencondary-label mb-2">{{ 'widget.insurancenoaddress' | lesCustomLocalize : '' : null : null : tagOptions }}</div>
                    </div>
                </div>

                <div class="primary-coverage-section">
                    <div class="container form-group mobile-field">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="les-label" for="address">{{ 'address' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                                <app-addressautocomplete [settings]="userInsuranceAddressAutocompleteSettings" formControlName="userInsuranceAddressAutocompleteQuery" (change)="checkAddressInputValidity()"></app-addressautocomplete>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="address2">{{ 'address2' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="address2" id="address2" formControlName="address2" />
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="city">{{ 'label.city' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <input type="text" class="form-control" name="city" id="city" required formControlName="city" />
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="stateKey">{{ 'state' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <select name="stateKey" id="stateKey" required class="form-control" formControlName="stateKey">
                                <option value="">{{ 'noneselected' | lesCustomLocalize : 'label' : null : null : tagOptions }}</option>
                                <option *ngFor="let state of states" [value]="state.abbreviation">
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group mobile-field">
                        <div class="col-sm-12">
                            <label class="les-label" for="zipCode">{{ 'zipcode' | lesCustomLocalize : 'label' : null : null : tagOptions }}</label>
                            <input type="text" maxlength="5" pattern="\d*" class="form-control" name="zipCode" id="zipCode" required formControlName="zipCode" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group mobile-field" *ngIf="isDirectBillClient && (!isInsurancePreferred || (isInsurancePreferred && hasInvalidPrimaryCoverage) || primaryCoverageNo || pastDirectBillSelected)">
                <div class="col-sm-12">
                    <input type="checkbox" class="inline-checkbox" value="no" [checked]="primaryCoverageNo" id="primaryCoverageNo" name="primaryCoverageNo" (click)="toggleShowPrimary($event)" />
                    <label class="les-label" for="primaryCoverageNo">
                        {{ 'label.paymenttypedirectbill' | lesCustomLocalize : '' : null : null : tagOptions }}
                    </label>
                    <div class="les-sencondary-label information-text">{{ 'message.paymenttypedirectbill' | lesCustomLocalize : '' : null : null : tagOptions }}</div>
                </div>
            </div>

            <div class="alert alert-danger" *ngIf="hasError && errorMsg?.length == 0">
                <button type="button" class="close" id="actionId-CloseErrorPatientInsuranceWidgetInvalidForm" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span [innerHTML]="'message.invalidform' | lesCustomLocalize : '' : null : null : tagOptions"></span>
            </div>

            <div class="alert alert-danger" *ngIf="hasError && errorMsg?.length > 0">
                <button type="button" class="close" id="actionId-ClosePatientInsuranceWidgetErrorAlert" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span class="error-msg" [innerHTML]="errorMsg | safeHtml"></span>
            </div>

            <nav class="widget-nav" aria-label="Page navigation">
                <ul class="pagination justify-content-center">
                    <li *ngIf="personWidget.position > 0" [ngClass]="{ 'page-item': true, disabled: personWidget.position == 0 }">
                        <a class="les-widget-link" id="titleId-PreviousPatientInsuranceWidget" (click)="onPreviousButtonClick()" [attr.disabled]="personWidget.position == 0 ? 'true' : null">Previous</a>
                    </li>
                    <li [ngClass]="{ 'page-item': true, disabled: !isValid() || isLoading || skipValidation || (!primaryCoverageYes && !primaryCoverageNo) }">
                        <a class="les-widget-link" (click)="onNextButtonClick(isValid())" id="action-NextPatientInsuranceWidget"> {{ 'button.next' | lesCustomLocalize : '' : null : null : tagOptions }} </a>
                    </li>
                </ul>
            </nav>
        </form>
    </div>
</div>

<app-loading *ngIf="isLoading" [msg]="'message.insvalidationinprogress' | lesCustomLocalize : '' : null : null : tagOptions" [isOverlay]="true"></app-loading>
<app-loading *ngIf="skipValidation" [msg]="'message.insvalidationskipvalidation' | lesCustomLocalize : '' : null : null : tagOptions" [isOverlay]="true"></app-loading>


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AddressValidateResult } from '../gaps-in-care/gaps-in-care.model';
import { InsuranceVerification, InsuranceVerificationResult, PayorContract, PayorContractSearch, PersonProgramBilling, UserInsuranceAddress, UserInsuranceAddressUpdateResult, UserInsuranceAddressValidate } from './insurance';

@Injectable()
export class InsuranceService {
    constructor(private _http: HttpClient) { }

    verify(search: InsuranceVerification): Observable<InsuranceVerificationResult> {
        return this._http.post<InsuranceVerificationResult>(environment.baseCoreApiEndpoint + '/insurance/verify', search);
    }
    updateInsuranceAddress(search: UserInsuranceAddress): Observable<UserInsuranceAddressUpdateResult> {
        return this._http.post<UserInsuranceAddressUpdateResult>(environment.baseCoreApiEndpoint + '/insurance/update-insurance-address', search);
    }

    getPayors(search: PayorContractSearch): Observable<PayorContract[]> {
        return this._http.post<PayorContract[]>(environment.baseCoreApiEndpoint + '/payors/contracts/search', search).pipe(catchError(this.handleError));
    }
    getInsuranceByProgram(personKey: string, programServiceKey: string): Observable<PersonProgramBilling> {
        return this._http.get<PersonProgramBilling>(environment.baseCoreApiEndpoint + `/person/billing/program-insurance?personKey=${personKey}&programServiceKey=${programServiceKey}`).pipe(catchError(this.handleError));
    }
    getInsuranceByPerson(personKey: string, programServiceKey: string): Observable<PersonProgramBilling[]> {
        return this._http.get<PersonProgramBilling[]>(environment.baseCoreApiEndpoint + `/person/person-insurance?personKey=${personKey}&programServiceKey=${programServiceKey}`).pipe(catchError(this.handleError));
    }
    createProgramInsurance(programInsurance: PersonProgramBilling, audit: string): Observable<boolean> {
        return this._http.post<boolean>(environment.baseCoreApiEndpoint + '/person/billing/program-insurance?audit=' + audit, programInsurance).pipe(catchError(this.handleError));
    }
    updateProgramInsurance(programInsurance: PersonProgramBilling, audit: string): Observable<boolean> {
        return this._http.put<boolean>(environment.baseCoreApiEndpoint + '/person/billing/program-insurance?audit=' + audit, programInsurance).pipe(catchError(this.handleError));
    }

    validateAddress(address: UserInsuranceAddressValidate): Observable<AddressValidateResult> {
        return this._http.post<AddressValidateResult>(environment.baseCoreApiEndpoint + '/address/validate', address).pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { LocalizeService } from "../localize/localize.service";

@Component({
  selector: "app-gaps-in-care-complete-modal",
  templateUrl: "./gaps-in-care-complete-modal.component.html",
  styleUrls: ["./gaps-in-care-complete-modal.component.css"],
})

export class GapsInCareModalCompleteComponent {
  @Input() title: string;
  constructor(private _activeModal: NgbActiveModal, private _localizationService: LocalizeService) {

  }

  close() {
    this._activeModal.close();
    window.location.reload();
  }
}

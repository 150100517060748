
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Person, PersonCreatePost } from './person';

@Injectable()
export class PersonService {
    private currentPersonSource = new BehaviorSubject<Person>(null);
    currentPerson = this.currentPersonSource.asObservable();

    private currentPersonErrorSource = new BehaviorSubject<boolean>(false);
    currentPersonError = this.currentPersonErrorSource.asObservable();

    private otherPersonSource = new Subject<Person>();
    otherPerson = this.otherPersonSource.asObservable();

    private pendoUserSource = new Subject<any>();
    pendoUser = this.pendoUserSource.asObservable();

    // Probably should have implemented replaysubject from the beginning.
    // TODO: refactor to use this instead?  No initial value needed with replay...
    private currentPersonReplaySource = new ReplaySubject<Person>(1);
    currentPersonReplay = this.currentPersonReplaySource.asObservable();

    constructor(private _http: HttpClient) { }

    fetchPerson() {
        this._http.get<Person>(environment.personInfoEndpoint)
            .pipe(
                retry(1),
            )
            .subscribe(
                {
                    next: (data) => {
                        const user = new Person(data);
                        this.currentPersonSource.next(user);
                        this.currentPersonReplaySource.next(user);
                    },
                    error: () => {
                        this.currentPersonErrorSource.next(true);
                    }
                });
    }

    getOtherPerson(key: string, isParticipant: boolean): Observable<Person> {
        let params = new HttpParams();
        if (key)
            params = params.append('key', key);
        params = params.append('keyType', isParticipant ? 'Participant' : 'User');

        return this._http.get<Person>(environment.personInfoEndpoint, { params: params })
            .pipe(
                retry(1),
            ).pipe(
                map(resp => {
                    const user = new Person(resp);
                    this.otherPersonSource.next(user);
                    return user;
                }),
                catchError((this.handleError)),);
    }

    getCurrentPerson() {
        if (!this.currentPersonErrorSource.value && this.currentPersonSource && this.currentPersonSource.value) {
            return this.currentPersonSource.value;
        } else {
            return null;
        }
    }

    createPerson(create: PersonCreatePost): Observable<string> {
        return this._http.post<string>(environment.baseCoreApiEndpoint + '/person/person-key', create).pipe(
            catchError(this.handleError))
    }
    fetchPendoUser(companyToken: string) {
        let params = new HttpParams();
        params = params.append('companyToken', companyToken);
        return this._http.get(environment.baseCoreApiEndpoint + '/pendo/person', { params: params })
            .pipe(retry(1)).subscribe(data => {
                this.pendoUserSource.next(data);
            });
    }
    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

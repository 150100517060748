import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AccessMode } from '../../../shared/access-mode';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { Utils } from '../../../shared/utils';
import { CoachingAppointment } from '../appointment';
import { CoachingAppointmentService } from '../appointments.service';


@Component({
  selector: 'app-next-coaching-appointment',
  templateUrl: './next-appointment.component.html',
  styleUrls: ['./next-appointment.component.css']
})
export class NextAppointmentComponent implements OnInit, OnDestroy {
  @Input() mode: AccessMode;
  @Input() participant: Person;
  @Input() nextAppointment: CoachingAppointment;
  subscriptions: ISubscription[] = [];
  readyForRender = false;

  private nextAppts: CoachingAppointment[] = [];

  constructor(private _localizeService: LocalizeService, private _apptService: CoachingAppointmentService) { }

  getNextAppointment() {
    if (this.nextAppts && this.nextAppts.length) {
      this.nextAppts = _.orderBy(this.nextAppts, ['startDate'], ['asc']);
      this.nextAppointment = null;
      for (let i = 0; i < this.nextAppts.length; i++) {
        if (this.nextAppts[i].status && this.nextAppts[i].status.toUpperCase() === 'SCHEDULED') {
          this.nextAppointment = this.nextAppts[i];

          // Retrieve the time zone abbreviation for display purposes
          this.nextAppointment.timeZone = moment.tz(this.nextAppointment.startDate, this.participant.preferredTimeZone).format('z');
          break;
        }
      }
    }
  }

  showLinkToCoaching() {
    return this.mode === AccessMode.Home;
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe( isReady => {
      if (isReady) {
        this.readyForRender = true;
      }
    }));


    this.subscriptions.push(this._apptService.nextAppts.subscribe( appts => {
      this.nextAppts = appts;

      this.getNextAppointment();
    }));

    this._apptService.getFutureAppts(this.participant.userKey);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}

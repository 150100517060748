import { Component, EventEmitter, Input, OnDestroy,OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../../person-widgets/person.widget.models';
import { Gender } from '../../shared/gender/gender';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Person } from '../../shared/person/person';
import { PersonDataCollected, PersonDataCollectedCriteria, PersonDataCollectedResponse, PersonDataToCollectSaveRequest, PersonDataToCollectView, ResponseChoice } from '../../shared/person-data-collection/person.data.collection.models';
import { PersonDataCollectionService } from '../../shared/person-data-collection/person.data.collection.service';

@Component({
    selector: 'app-person-data-collection-widget',
    templateUrl: './person-data-collection-widget.component.html',
    styleUrls: ['./person-data-collection-widget.component.css']
})
export class PersonDataCollectionWidgetComponent implements OnInit, OnDestroy {
    @Input() personWidget: PersonWidgetCollectionWidget;
    @Input() currentPerson: Person;
    @Input() otherPerson?: Person;
    @Input() programServiceKey: string;
    @Input() programServiceTypeKey: string;
    @Input() personKey: string;
    @Input() widgetTitle: string;
    @Output() onWidgetCompleted = new EventEmitter<any>();
    @Output() onUpdateAttempted = new EventEmitter<any>();

    search: PersonDataCollectedCriteria = new PersonDataCollectedCriteria();
    subscriptions: ISubscription[] = [];
    isRenderReady = false;
    personDataCollection: PersonDataToCollectView[];
    personDataCollectedKey: any;
    genderList: Gender[];
    hasError = false;
    showError = false;
    errorMessage = '1 or more of your field is not valid. Please correct and try again. TBD';

    public personForm: FormGroup = this.fb.group({});
    constructor(private _localizeService: LocalizeService, private fb: FormBuilder, private _metadataService: MetadataService, private _personDataCollectionService: PersonDataCollectionService) {
        this._metadataService.getStates();
    }

    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }

    public getHtmlId(token: string) {
        return token.replace(/\s+/g, '-');
    }
    public getFriendlyId(token: string, title: string) {
        return `${token}-${title.replace(/ /g, '-')}-widget`;
    }

    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentPerson;
    }

    logInPerson(): Person {
        return this.currentPerson ? this.currentPerson : this.otherPerson;
    }

    ngOnInit(): void {
        const locale = this.person().locale ? this.person().locale.languageLocale : 'en-us';
        this.subscriptions.push(
            this._metadataService.getGenders(locale).subscribe(
                (data) => {
                    if (data) {
                        this.genderList = data;
                        this.loadDataCollection();
                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );
    }

    get genderKey() {
        if (this.person().gender && this.genderList && this.genderList.length > 0) {
            const genderValue = this.person().gender.substring(0, 1).toLowerCase();
            const gender = this.genderList.find((g: Gender) => g.labcorpValue.toLowerCase() == genderValue.toLowerCase());
            return gender ? gender.key : null;
        }
        return null;
    }

    loadDataCollection() {
        this.search.programServiceKey = this.programServiceKey;
        this.search.participantKey = this.person().participantKey;
        this.search.userKey = this.person().userKey;
        this.search.companyKey = this.person().company.key;
        this.search.dataCollectionKey = this.personWidget.dataCollectionKey;
        if (this.search.userKey && this.search.dataCollectionKey) {
            this._personDataCollectionService.getPersonDataToCollect(this.personKey, this.search).subscribe(
                (res) => {
                    //grab personDataCollectedKey
                    if (res && res.length > 0) {
                        const firstResponse = res.find((r) => r.personDataCollectedKey);
                        if (firstResponse) {
                            this.personDataCollectedKey = firstResponse.personDataCollectedKey;
                        }
                    }
                    //validate gender
                    const gender = this.person().gender;
                    if (gender) {
                        const singleCharGender = gender.substring(0, 1).toUpperCase();
                        const genderValue = this.genderList.find((r) => r.labcorpValue.toUpperCase() === singleCharGender);
                        if (!genderValue) {
                            //Some error?
                            return;
                        }
                        res = res.filter((f) => !f.genderKey || (f.genderKey && f.genderKey.toLowerCase() == genderValue.key.toLowerCase()));
                    }
                    //validate patientOlderThan
                    if (res && res.length > 0 && this.person().dateOfBirth) {
                        const personAge = moment().diff(this.person().dateOfBirth, 'years');
                        if (personAge > 0) {
                            res = res.filter((f) => !f.patientOlderThan || f.patientOlderThan == 0 || (f.patientOlderThan > 0 && personAge >= f.patientOlderThan));
                        }
                    }
                    //patientYoungerThan
                    if (res && res.length > 0 && this.person().dateOfBirth) {
                        const personAge = moment().diff(this.person().dateOfBirth, 'years');
                        if (personAge > 0) {
                            res = res.filter((f) => !f.patientYoungerThan || f.patientYoungerThan == 0 || (f.patientYoungerThan > 0 && personAge <= f.patientYoungerThan));
                        }
                    }

                    this.personDataCollection = res;
                    if (this.personDataCollection && this.personDataCollection.length) {
                        this.buildForm(this.personDataCollection);
                    } else {
                        //TOD some error here
                    }
                    this.isRenderReady = true;
                },
                (error) => {
                    this.isRenderReady = true;
                }
            );
        } else {
            this.onError();
        }
    }

    buildForm(formControls: PersonDataToCollectView[]) {
        for (const control of formControls) {
            //
            //will there be validations besides required?
            const validators = [];
            //FOR FUTURE: DO NOT REMOVE
            // if (control.validators) {
            //     for (const [key, value] of Object.entries(control.validators)) {
            //         switch (key) {
            //             case 'required':
            //                 validators.push(Validators.required);
            //                 break;
            //             case 'min':
            //                 validators.push(Validators.min);
            //                 break;
            //             case 'max':
            //                 validators.push(Validators.max);
            //                 break;
            //             default:
            //                 break;
            //         }
            //     }
            // }
            //currently required is on the control object itself
            if (control.isRequired && !_.includes(validators, Validators.required)) {
                validators.push(Validators.required);
            }
            //manage defaults
            let response = control.response;

            switch (control.htmlInputElement.toLowerCase()) {
                case Globals.HTMLInputElements.SingleSelect:
                    let location = control.responseChoices.findIndex((t) => t.value.toLowerCase() == response);
                    if (location > -1) {
                        control.responseChoices[location].isSelected = true;
                    } else {
                        location = control.responseChoices.findIndex((t) => t.isDefault);
                        if (location > -1) {
                            control.responseChoices[location].isSelected = true;
                            response = control.responseChoices[location].value.toLowerCase();
                        }
                    }
                    this.personForm.addControl(control.internalName, this.fb.control(response, validators));
                    break;
                case Globals.HTMLInputElements.MultiSelect:
                    // Check if there are any selected choices
                    const selectedChoice = control.responseChoices.find((t) => t.isSelected);
                    if (!selectedChoice) {
                        // Check for IsDefault
                        const defaultChoiceIndex = control.responseChoices.findIndex((t) => t.isDefault);
                        if (defaultChoiceIndex > -1) {
                            // Set default choice to IsSelected
                            control.responseChoices[defaultChoiceIndex].isSelected = true;
                        }
                    }
                    // Check for IsSelected & IsExclusive
                    const selectedExclusiveFound = control.responseChoices.find((t) => t.isExclusive && t.isSelected);
                    if (selectedExclusiveFound) {
                        for (const choice of control.responseChoices) {
                            if (!choice.isSelected) choice.isDisabled = true;
                        }
                    }
                    if (control.responseChoices.find((x) => x.isSelected)) {
                        const selectedResponses = control.responseChoices.map((t) => {
                            return new FormControl(t.isSelected || false);
                        });
                        const formArraySelected = new FormArray(selectedResponses);
                        this.personForm.addControl(control.internalName, formArraySelected);
                    } else {
                        const selectedResponses = control.responseChoices.map((t) => {
                            return new FormControl(t.isSelected || null, validators);
                        });
                        const formArraySelected = new FormArray(selectedResponses);
                        this.personForm.addControl(control.internalName, formArraySelected);
                    }

                    break;
                default:
                    this.personForm.addControl(control.internalName, this.fb.control(response, validators));
                    break;
            }
        }
    }

    onNextButtonClick() {
        if (!this.isValid()) {
            this.hasError = true;
            return;
        }
        this.processAction(WidgetCloseAction.forward, true);
    }

    private processAction(next: WidgetCloseAction, validate = true) {
        if (!validate) {
            return this.closePanel(false, next);
        }
        const postModel = this.extractFormValues();

        const userKey = this.currentPerson ? this.currentPerson.userKey : this.person().userKey;
        const companyKey = this.person().company.key;
        this._personDataCollectionService.savePersonDataCollectedResponses(this.personKey, companyKey, postModel).subscribe(
            (res) => {
                this.closePanel(true, next);
            },
            (error) => {
                this.onError();
            }
        );
    }

    private extractFormValues(): PersonDataToCollectSaveRequest {
        const requestModel = new PersonDataToCollectSaveRequest();
        const personDataCollected: PersonDataCollected = new PersonDataCollected();
        personDataCollected.companyKey = this.person().company.key;
        personDataCollected.personKey = this.personKey;
        const audit = this.currentPerson && this.currentPerson.userKey ? this.currentPerson.userKey : this.person().userKey;
        if (this.personDataCollectedKey) {
            personDataCollected.key = this.personDataCollectedKey;
            personDataCollected.modifiedBy = audit;
        } else {
            personDataCollected.createdBy = audit;
        }
        personDataCollected.dataCollectionKey = this.personWidget.dataCollectionKey;
        personDataCollected.programServiceKey = this.programServiceKey;
        if (this.person().participantKey) {
            personDataCollected.participantKey = this.person().participantKey;
        }
        personDataCollected.userKey = this.person().userKey;

        requestModel.personDataToCollect = personDataCollected;
        const responses: PersonDataCollectedResponse[] = [];
        Object.keys(this.personForm.controls).forEach((key) => {
            const model = this.personDataCollection.find((r) => r.internalName === key);
            if (model && model.htmlInputElement == Globals.HTMLInputElements.MultiSelect) {
                const multiResponses = this.personForm.value[key]
                    .map((x, i) => {
                        return model.responseChoices[i];
                    })
                    .filter((t) => t.isSelected);
                if (multiResponses && multiResponses.length > 0) {
                    multiResponses.forEach((t: ResponseChoice) => {
                        responses.push(this.pushPostModel(model, t.value, audit));
                    });
                } else {
                    // multi-response has no selected options. Setting the record response to null.
                    responses.push(this.pushPostModel(model, null, audit));
                }
            } else if (model) {
                //process non multiselect
                const value = this.personForm.get(key).value;
                responses.push(this.pushPostModel(model, value, audit));
            }
        });
        requestModel.personDataCollectedResponses = responses;
        return requestModel;
    }

    private pushPostModel(model: PersonDataToCollectView, value: string, audit: string) {
        const postModel: any = {};
        postModel['response'] = value;
        if (model.dataPromptKey) {
            postModel['dataPromptKey'] = model.dataPromptKey;
        }
        if (model.personDataCollectedKey) {
            postModel['personDataCollectedKey'] = model.personDataCollectedKey;
        }
        if (model.personDataCollectedResponseKey) {
            postModel['key'] = model.personDataCollectedResponseKey;
            postModel['modifiedBy'] = audit;
        } else {
            postModel['createdBy'] = audit;
        }
        return postModel;
    }

    onMultiSelectChange(choice: ResponseChoice, internalName: string) {
        const dataPromptIndex = this.personDataCollection.findIndex((t) => t.internalName == internalName);
        if (dataPromptIndex > -1) {
            const allChoices = this.personDataCollection[dataPromptIndex].responseChoices;
            const currentSelectedChoiceIndex = allChoices.findIndex((t) => t.value == choice.value);
            const currentSelected = allChoices[currentSelectedChoiceIndex];
            currentSelected.isSelected = !choice.isSelected;

            if (currentSelected.isExclusive) {
                allChoices.forEach((t) => {
                    if (t.value != currentSelected.value) {
                        if (currentSelected.isSelected) {
                            t.isSelected = false;
                            t.isDisabled = true;
                        } else {
                            t.isDisabled = false;
                        }
                    }
                });
            } else if (!currentSelected.isExclusive) {
                allChoices.forEach((t) => {
                    if (t.value != currentSelected.value) {
                        t.isDisabled = false;
                    }
                });
            }
            allChoices[currentSelectedChoiceIndex] = currentSelected;
            this.personDataCollection[dataPromptIndex].responseChoices = allChoices;
            if (this.personDataCollection[dataPromptIndex].isRequired) {
                if (allChoices.find((x) => x.isSelected)) {
                    const selectedResponses = allChoices.map((t) => {
                        return new FormControl(t.isSelected || false, Validators.required);
                    });

                    const formArraySelected = new FormArray(selectedResponses);
                    this.personForm.setControl(this.personDataCollection[dataPromptIndex].internalName, formArraySelected);
                } else {
                    const selectedResponses = allChoices.map((t) => {
                        return new FormControl(t.isSelected || null, Validators.required);
                    });

                    const formArraySelected = new FormArray(selectedResponses);
                    this.personForm.setControl(this.personDataCollection[dataPromptIndex].internalName, formArraySelected);
                }
            } else {
                const selectedResponses = allChoices.map((t) => {
                    return new FormControl(t.isSelected || false);
                });
                const formArraySelected = new FormArray(selectedResponses);
                this.personForm.setControl(this.personDataCollection[dataPromptIndex].internalName, formArraySelected);
            }
        } else {
            console.error(`ERROR: no existing data prompts for question name: ${internalName}`);
        }
    }

    onPreviousButtonClick() {
        //if button is present
        if (this.personWidget.position == WidgetCollectionPosition.first) return;

        this.processAction(WidgetCloseAction.backward, false);
    }

    isValid(): boolean {
        // Check if the form is invalid.
        if (this.personForm.invalid) {
            this.onError();
            return false;
        }
        return true;
    }

    private closePanel(complete: boolean, action: WidgetCloseAction) {
        //send back result
        const result = new WidgetCompleted();
        result.dataCollectionKey = this.personWidget.dataCollectionKey;
        result.widgetKey = this.personWidget.widgetKey;
        result.isComplete = complete;
        result.action = action;
        result.widgetPanelIndex = this.personWidget.widgetPanelIndex;
        this.onWidgetCompleted.emit(result);
    }

    showRequired(name: string) {
        const field = this.personForm.get(name);
        if (!field || !field.validator) return false;

        const validator = field.validator({} as AbstractControl);
        return validator && validator.required;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }

    private onError() {
        this.hasError = true;
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
    }

    getTranslation(tag: string) {
        return this._localizeService.getCombinedTag(tag, null, null, null, this.tagOptions);
    }

    getNextMessage() {
        if (this.personWidget.position == WidgetCollectionPosition.last) {
            return this.getTranslation('button.submit');
        }
        return this.getTranslation('button.next');
    }

    close() {
        this.hasError = false;
    }

    closeError() {
        this.hasError = false;
    }
}

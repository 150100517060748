
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ConsentsNeededByServiceSearchModel } from './shared/consent-modal/consent';
import { ConsentService } from './shared/consent-modal/consent.service';
import { Globals } from './shared/globals';
import { LocalizeService } from './shared/localize/localize.service';
import { PersonService } from './shared/person/person.service';

@Injectable()
export class ConsentGuard {
  constructor(private _personService: PersonService, private _router: Router, private _localizeService: LocalizeService,
    private _consentService: ConsentService,) { }


  canActivate() {
    // Does the user have access to the service?
    // If so, does the user have consent?
    return this._personService.currentPersonReplay.pipe(mergeMap(person => {
      if (!person.isParticipant) {
        return observableOf(true);
      }
      const coachingServiceKeys = person.getCoachingServiceKeys;
      if (!person.hasCoaching || coachingServiceKeys.length === 0) {
        this.noAccess();
        this._router.navigate(['/' + Globals.Routes.services]);
        return observableOf(false);
      }
      const consenstsNeeded = new ConsentsNeededByServiceSearchModel(
        true,
        person.getCoachingServiceKeys
      );
      return this._consentService.getConsentsNeededByService(consenstsNeeded).pipe(map(results => {
        if (results && results.result.length) {
          return false;
        } else {
          return true;
        }
      }), map(canactiavte => {
        if (canactiavte) {
          return true;
        } else {
          this.noAccess();
          this._router.navigate(['/' + Globals.Routes.services]);
          return false;
        }
      }),)
    }));
  }

  private noAccess() {
    window.alert(this._localizeService.get('musthaveserviceandconsent', 'message'));
  }
}

import _ from 'lodash';
import moment from 'moment';

import { Company } from '../company/company';
import { Globals } from '../globals';
import { Locale } from '../localize/locale';
import { Participant } from '../participant/participant';
import { Partner } from '../partner/partner';
import { UserServices } from '../service';
import { User } from '../user/user';

export class Person {
    user: User;
    participant: Participant;

    get acceptedTOUPP(): boolean {
        return this.user ? this.user.acceptedTOUPP : false;
    }

    get firstName(): string {
        return this.participant ? this.participant.firstName : this.user.firstName;
    }

    get lastName(): string {
        return this.participant ? this.participant.lastName : this.user.lastName;
    }

    get fullName(): string {
        return this.firstName ? this.firstName + ' ' + this.lastName : this.lastName;
    }

    get company(): Company {
        return this.user ? this.user.company : null;
    }
    get companyKey(): string {
        return this.user && this.user.company ? this.user.company.key : null;
    }
    get partner(): Partner {
        return this.participant ? this.participant.partner : this.user.partner;
    }

    get participantKey(): string {
        return this.participant ? this.participant.key : null;
    }

    get userKey(): string {
        return this.user ? this.user.key : null;
    }
    get personKey(): string {
        return this.user ? this.user.personKey : null;
    }

    get email(): string {
        return this.participant ? this.participant.email : this.user.email;
    }

    get userEmail(): string {
        return this.user ? this.user.email : null;
    }

    get participantEmail(): string {
        return this.participant ? this.participant.email : null;
    }

    get userInfoEmail(): string {
        if (!this.user) {
            return null;
        }
        if (this.user.preferredEmailAddress) {
            return this.user.preferredEmailAddress;
        }
        return this.user.email;
    }
    get address1(): string {
        if (!this.user) {
            return null;
        }
        if (this.user.address1) {
            return this.user.address1;
        }
        return this.participant && this.participant.address1 ? this.participant.address1 : '';
    }
    get zip(): string {
        if (!this.user) {
            return null;
        }
        if (this.user.zipCode) {
            return this.user.zipCode;
        }
        return this.participant && this.participant.zip ? this.participant.zip : '';
    }
    get city(): string {
        if (!this.user) {
            return null;
        }
        if (this.user.city) {
            return this.user.city;
        }
        return this.participant && this.participant.city ? this.participant.city : '';
    }
    get state(): string {
        if (!this.user) {
            return null;
        }
        if (this.user.state) {
            return this.user.state;
        }
        return this.participant && this.participant.state ? this.participant.state : '';
    }

    get dateOfBirth(): string {
        const dt = this.participant ? this.participant.dateOfBirth : this.user.dateOfBirth;
        if (dt) {
            return moment(dt).format('MM/DD/YYYY');
        } else {
            return null;
        }
    }

    get terminateDate(): string {
        const dt = this.participant ? this.participant.terminationDate : null;
        if (dt) {
            return moment(dt).format('MM/DD/YYYY');
        } else {
            return null;
        }
    }

    get preferredPhone(): string {
        return this.user ? this.user.phone : null;
    }
    get preferredLanguage(): string {
        return this.user ? this.user.languageLocale : null;
    }
    get preferredTextMessagePhone(): string {
        if (!this.user) {
            return null;
        }
        return this.user.textMessagePhone ? this.user.textMessagePhone : this.user.phone;
    }
    get preferredTextUsePreferredPhone(): boolean {
        return this.user ? this.user.textUseSettingsPhone : false;
    }

    get participantPhone(): string {
        return this.participant ? this.participant.phone : null;
    }

    get participantPhone2(): string {
        return this.participant ? this.participant.phone2 : null;
    }

    get hasPhoneValues(): boolean {
        if (this.participant) {
            if (this.participant.phone) {
                return true;
            } else if (this.participant.phone2) {
                return true;
            }
        }
        if (this.user) {
            if (this.user.phone) {
                return true;
            } else if (this.user.textMessagePhone) {
                return true;
            }
        }

        return false;
    }

    get hasPreferredPhoneValues(): boolean {
        if (this.user == null) {
            return false;
        }
        if (!this.user.phone && !this.user.textMessagePhone) {
            return false;
        }
        return true;
    }

    get gender(): string {
        return this.user ? this.user.gender : this.participant ? this.participant.gender : null;
    }

    get hasPreferredGender() {
        return this.user && this.user.gender.length == 0 ? false : true;
    }

    get preferredGender(): string {
        return this.user.gender;
    }

    get marketingEmailOptIn(): boolean {
        return this.user ? this.user.marketingEmailOptIn : false;
    }

    get locale(): Locale {
        return this.user ? this.user.locale : null;
    }

    get preferredTimeZone(): string {
        // TODO: Is this a safe assumption?
        return this.user && this.user.timeZone ? this.user.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    get isAnyAdmin(): boolean {
        return this.isCoachAdmin || this.isWcsAdmin || this.isPartnerAdmin || this.isCustomerAdmin;
    }
    get canCustomize(): boolean {
        return this.user && this.user.isWcsAdmin;
    }

    get isWcsAdmin(): boolean {
        return this.user ? this.user.isWcsAdmin : false;
    }

    get isSupportUser(): boolean {
        return this.user ? this.user.isSupportUser : false;
    }

    get isCoach(): boolean {
        return this.user ? this.user.isCoach : false;
    }

    get isCoachAdmin(): boolean {
        return this.user ? this.user.isCoachAdmin : false;
    }

    get isCoaching(): boolean {
        return this.user ? this.user.isCoach || this.user.isCoachAdmin : false;
    }

    get isParticipant(): boolean {
        return this.user ? this.user.isParticipant : false;
    }

    get isGeneralInboxMonitor(): boolean {
        return this.user ? this.user.isGeneralInboxMonitor : false;
    }

    get isCustomerAdmin(): boolean {
        return this.user ? this.user.isCustomerAdmin : false;
    }

    get isPartnerAdmin(): boolean {
        return this.user ? this.user.isPartnerAdmin : false;
    }

    get coachingDemographic(): string {
        return this.participant ? this.participant.coachingDemographic : null;
    }

    get isGuestUser(): boolean {
        return !this.participant && this.isParticipant;
    }

    get canUserAdmin(): boolean {
        return this.isCustomerAdmin || this.isPartnerAdmin || this.isWcsAdmin || this.isSupportUser || this.isCoach || this.isCoachAdmin;
    }

    get canSearch(): boolean {
        return this.isCoach || this.isCoachAdmin || this.isCustomerAdmin || this.isPartnerAdmin || this.isWcsAdmin || this.isSupportUser;
    }

    get canSeeParticipantCoaching(): boolean {
        return this.isCoach || this.isCoachAdmin || this.isWcsAdmin || this.isSupportUser;
    }

    get canSeeParticipantScreening(): boolean {
        return this.isWcsAdmin || this.isSupportUser || this.isCustomerAdmin || this.isPartnerAdmin;
    }

    get canSeeParticipantServices(): boolean {
        return this.isWcsAdmin || this.isSupportUser || this.isCustomerAdmin || this.isPartnerAdmin;
    }

    get UserServices(): UserServices[] {
        return this.user.userServices;
    }

    get hasCoaching(): boolean {
        let has = false;

        if (
            _.find(this.user.userServices, (sc: UserServices) => {
                return sc.programService && sc.programService.serviceType === Globals.ServiceTypes.Coaching;
            })
        ) {
            has = true;
        }
        return has;
    }

    get getCoachingServiceKeys(): string[] {
        return _.filter(this.user.userServices, (sc: UserServices) => {
            return sc.programService && sc.programService.serviceType === Globals.ServiceTypes.Coaching;
        }).map(function (service: UserServices) {
            return service.programService.programServiceKey;
        });
    }

    get hasResultsAccess(): boolean {
        return this.participant && this.user && this.user.company && this.user.company.hasResultsAccess;
    }

    get hasSchedulerAccess(): boolean {
        return this.user && this.user.hasSchedulerAccess;
    }

    get hasOnlineScheduler(): boolean {
        return this.user && this.user.hasOnlineScheduler;
    }
    get canImpersonate() {
        return (this.user && this.isAnyAdmin) || this.isSupportUser;
    }

    constructor(config) {
        if (config.user) {
            this.user = new User(config.user);
        }
        this.participant = config.participant;
    }
}

export class PersonCreatePost {
    constructor(person: Person, createby, genderKey) {
        this.userKey = person.userKey;
        this.participantKey = person.participantKey;
        this.auditUserKey = createby;
        this.genderKey = genderKey;
        this.firstName = person.firstName;
        this.lastName = person.lastName;
        this.dateOfBirth = person.dateOfBirth;
        this.personKey = person.personKey;
    }
    userKey: string;
    participantKey: string;
    auditUserKey: string;
    genderKey: string;
    firstName: string;
    lastName: string;
    dateOfBirth: any;
    personKey: string;
}

import { ApiEvent } from './api-event';

export class Event {
    key: string;
    name: string;
    startDate: Date;
    endDate: Date;
    programServiceKey: string;
    programServiceTypeKey: string;
    fastingState: string;
    clientRefCode: string;
    room: string;
    scheduleLockDate: Date;

    constructor(apiEvent: ApiEvent) {
        this.key = apiEvent.key;
        this.name = apiEvent.name;
        this.startDate = apiEvent.startDate;
        this.endDate = apiEvent.endDate;
        this.programServiceKey = apiEvent.service.programServiceKey;
        this.programServiceTypeKey = apiEvent.service.programServiceTypeKey;
        this.fastingState = apiEvent.fastingState;
        this.clientRefCode = apiEvent.clientRefCode;
        this.room = apiEvent.room;
        this.scheduleLockDate = apiEvent.scheduleLockDate;
    }
}

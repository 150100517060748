
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ResultsService } from '../results/results.service';
import { Globals } from '../shared/globals';
import { Person } from '../shared/person/person';
import { PortalPrompt } from './portalprompt';

@Injectable()
export class PortalPromptModalService {
    constructor(private _http: HttpClient, private _resultsService: ResultsService) { }

    getPortalPromptForUser(person: Person): Promise<PortalPrompt> {
        return new Promise((resolve) => {
            if (!person.hasResultsAccess) resolve(null);
            this._resultsService.getCombined().subscribe((results) => {
                if (!results[0].results.length) {
                    resolve(null);
                }

                this._http
                    .get<PortalPrompt>(environment.baseCoreApiEndpoint + `/portal-prompts/get-portal-prompt-for-user?userKey=${person.userKey}`)
                    .pipe(retry(1)).pipe(
                        catchError(this.handleError))
                    .subscribe((prompt) => {
                        resolve(prompt);
                    });
            });
        });
    }

    savePromptAcknowledgement(promptKey: string, userKey: string, hidePermanently: boolean) {
        return this._http.post<any>(environment.baseCoreApiEndpoint + '/portal-prompts/acknowledge-portal-prompt', { promptKey: promptKey, userKey: userKey, hidePermanently: hidePermanently }).pipe(catchError(this.handleError));
    }


    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }

    get portalPromptPopupStyle() {
        return {
            border: `1.2px solid ${Globals.Colors.color2}`,
            borderRadius: '51%'
        };
    }
}

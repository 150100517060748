<div *ngIf="portalPrompt">
    <div class="modal-header">
        <h4 class="secondary-text modal-title" id="titleId-PortalPrompt" [innerHTML]="portalPrompt.title"></h4>
    </div>

    <div class="modal-body" [innerHTML]="portalPrompt.body"></div>

    <div *ngIf="portalPrompt.permanentlyHideCheckboxText && portalPrompt.permanentlyHideCheckboxText !== ''" class="modal-body">
        <input type="checkbox" id="hidePermanently" [checked]="hidePromptPermanently" (change)="hidePromptPermanently = !hidePromptPermanently" />
        <label for="hidePermanently" [innerHTML]="portalPrompt.permanentlyHideCheckboxText"></label>
    </div>

    <div class="modal-footer pt-10">
        <button class="tertiary-bg btn btn-primary" id="actionId-ClosePortalPrompt" (click)="savePromptAcknowledgement()">{{ 'close' | lesCustomLocalize : 'label' }}</button>
    </div>
</div>

import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateFormatPipe } from 'ngx-moment';

import { LocalizeService } from '../../shared/localize/localize.service';
import { Utils } from '../../shared/utils';
import { TzPipe } from '../date-helpers/tz-pipe';

@Pipe({ name: 'formatCell' })
export class FormatCellPipe implements PipeTransform {
    constructor(private _currencyPipe: CurrencyPipe, private _dateFormatPipe: DateFormatPipe, private _tzPipe: TzPipe, private _localizeService: LocalizeService) { }

    transform(value: any, format: string, timezone: string, record: any) {
        if (value === undefined) {
            return this._localizeService.get('na', 'label');
        }

        if (format === 'default') {
            if (Array.isArray(value)) {
                if (typeof value[0] !== 'object') {
                    return value.join(', ');
                } else {
                    return value
                        .map((obj) => {
                            return obj.name;
                        })
                        .join(', ');
                }
            }
            if (typeof value === 'object') {
                return value.name;
            }
        }

        if (format === 'boolean') {
            return value === 'true' || value === true ? 'Y' : 'N';
        }

        if (format === 'localizeToken') {
            return this._localizeService.get(value, 'label');
        }

        if (format === 'datetime') {
            return this._dateFormatPipe.transform(this._tzPipe.transform(moment.utc(value), timezone), 'M/D/YY h:mm a');
        }

        if (format === 'dateasis') {
            return this._dateFormatPipe.transform(value, 'M/D/YYYY');
        }

        if (format === 'datetimeasis') {
            return this._dateFormatPipe.transform(value, 'M/D/YYYY h:mm a');
        }

        if (format === 'currency') {
            return this._currencyPipe.transform(value, 'USD', true);
        }

        if (format === 'programToken') {
            return this._localizeService.get(Utils.getServiceToken(value), 'program');
        }
        //this is temporary. In the future, programtype should use their own category
        if (format === 'programTypeToken') {
            if (record) {
                const options = `{"programServiceKey":"${record.programServiceKey}","programServiceTypeKey":"${record.programServiceTypeKey}"}`;
                return this._localizeService.get(Utils.getServiceToken(value), 'program', null, null, null, null, options);
            }
            return this._localizeService.get(Utils.getServiceToken(value), 'program');
        }
        return value;
    }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-AattestationResponse">{{ attestation.titleText }}</h4>
    <button type="button" class="close" id="actionId-Close-AattestationResponse" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="form" (ngSubmit)="captureAttestationResponse($event)" id="formId-AattestationResponse" name="form.form" validate>
    <div class="modal-body">
        <div [innerHTML]="attestation.introText | safeHtml"></div>

        <div *ngIf="attestation.allowNoResponse" class="form-row">
            <div class="col-sm-0">
                <input name="noResponse" id="noResponse" type="checkbox" value="false" [checked]="isNoResponse" (change)="setUserResponse($event)" />
            </div>
            <div class="col-sm-10" [innerHTML]="attestation.noText | safeHtml"></div>
        </div>

        <div *ngIf="attestation.allowYesResponse" class="form-row">
            <div class="col-sm-0">
                <input type="checkbox" value="true" [checked]="isYesResponse" (change)="setUserResponse($event)" />
            </div>
            <div class="col-sm-10" [innerHTML]="attestation.yesText | safeHtml"></div>
        </div>

        <div [innerHTML]="attestation.outroText | safeHtml"></div>

        <input type="hidden" [value]="" name="userResponse" formControlName="userResponse" />
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn btn-secondary" id="ctionId-Cancel-AattestationResponse" (click)="close()">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
        <button [disabled]="!this.form.valid || this.isSubmitting" id="actionId-Submit-AattestationResponse" type="submit" class="tertiary-bg btn btn-primary" value="Submit">
            {{ 'submit' | lesCustomLocalize : 'label' }}
        </button>
    </div>
</form>

<div class="primary-widget-container" id="titleId-ConsentWidget">
    <div *ngIf="consentRecord && isRenderReady" class="consentWidgetContainer">
        <div class="row">
            <div class="col-xs-12 mb-2">
                <div [innerHTML]="personWidget.descriptionLanguage | safeHtml"></div>
            </div>
        </div>

        <p class="error text-danger font-weight-bold" *ngIf="isOffsiteConsents">{{ 'loginConsentPrompt' | lesCustomLocalize : 'label' : null : null : tagOptions }}</p>
        <div class="consent-content les-information-text" [innerHTML]="replaceNewlines(consentRecord.authorizationText) | safeHtml"></div>
        <br />

        <div class="" style="display: block">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="agree" [(ngModel)]="isAgreed" (change)="toggleAgree($event)" />
                <label class="form-check-label" for="agree">
                    {{ consentRecord.acceptActionText }}
                </label>
            </div>

            <div class="form-check" *ngIf="!consentRecord.declineNotAvailable">
                <input type="checkbox" class="form-check-input" id="decline" [(ngModel)]="isDeclined" (change)="toggleDecline($event)" />
                <label class="form-check-label" for="decline">
                    {{ consentRecord.declineActionText }}
                    <span *ngIf="consentRecord.declineText" [innerHTML]="consentRecord.declineText | safeHtml" class="les-information-text"> </span>
                </label>
            </div>

            <div class="row" *ngIf="isDeclined && consentRecord.declineConfirmationText">
                <div class="col-md-12">
                    <div [innerHTML]="replaceNewlines(consentRecord.declineConfirmationText) | safeHtml"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p *ngIf="hasError" class="error text-danger font-weight-bold">
                        {{ 'missingconsent2' | lesCustomLocalize : 'message' : null : null : tagOptions }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group row mobile-field" *ngIf="personWidget.footerLanguage">
        <div class="col-sm-12">
            <div [innerHTML]="personWidget.footerLanguage | safeHtml" class="les-information-text"></div>
        </div>
    </div>

    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
            <li *ngIf="personWidget.position > 0" [ngClass]="{ 'page-item': true, disabled: personWidget.position == 0 }">
                <a class="les-widget-link" (click)="onPreviousButtonClick()" id="actionId-PreviousConsentWidget" [attr.disabled]="personWidget.position == 0 ? 'true' : null">Previous</a>
            </li>
            <li [ngClass]="{ 'page-item': true, disabled: !isDeclined && !isAgreed }">
                <a class="les-widget-link" (click)="onNextButtonClick()" id="actionId-NextConsentWidget"> {{ 'button.next' | lesCustomLocalize : '' : null : null : tagOptions }} </a>
            </li>
        </ul>
    </nav>
</div>

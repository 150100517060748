<ng-container>
    <div id="chart-group" hidden>
        <!-- "hidden" attribute used only to initial hide the chart group while loading the data -->
        <div class="chart-container pt-2" style="position: relative; height: 300px">
            <canvas id="myChart"></canvas>
        </div>

        <div class="row justify-content-md-center pt-2" *ngIf="readyForRender && !hasError">
            <div class="col-md-4">
                <select name="map" *ngIf="getMappingGroup().length > 0" (change)="onSelect($event.target.value)">
                    <option *ngFor="let map of getMappingGroup()" [value]="map.fieldName">{{ map.displayInfo.localizedName | lesCustomLocalize : 'label' }}</option>
                </select>
            </div>
        </div>
    </div>
</ng-container>

<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>


import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { TransactionIdResult } from '../../services/services-list/service-qualification/service-qualification';
import { GicModality, Service } from '../service';
import { AddressValidateResult, AddressValidateViewModel, GapsInCareModel } from './gaps-in-care.model';

@Injectable()
export class GapsInCareService {
    constructor(private _http: HttpClient) { }

    getTransactionId(programServiceKey: string, userKey: string, qualificationRuleOverrideCode: string = null): Observable<TransactionIdResult> {
        let params = new HttpParams();
        params = params.append('programServiceKey', programServiceKey);
        params = params.append('userKey', userKey);
        params = params.append('overrideCode', qualificationRuleOverrideCode);
        return this._http
            .get<TransactionIdResult>(environment.baseCoreApiEndpoint + '/service-orders/transaction-id', {
                params: params
            }).pipe(
                catchError(this.handleError));
    }

    createServiceOrder(gapsInCare: GapsInCareModel) {
        return this._http.post(environment.baseCoreApiEndpoint + '/service-orders', gapsInCare).pipe(catchError(this.handleError));
    }

    validateAddress(address: AddressValidateViewModel): Observable<AddressValidateResult> {
        return this._http.post<AddressValidateResult>(environment.baseCoreApiEndpoint + '/address/validate', address).pipe(catchError(this.handleError));
    }
    gicModality(service: Service): GicModality {
        const programOrderableTest = service.programOrderableTests;
        if (programOrderableTest && programOrderableTest.length > 0) {
            const hasHtk = programOrderableTest.some(t => t.orderableTestModality === 'Home Test Kit');
            const hasPsc = programOrderableTest.some(t => t.orderableTestModality === 'PSC Visit');

            if (hasHtk && hasPsc) return GicModality.Both;
            if (hasHtk) return GicModality.HomeTestKit;
            if (hasPsc) return GicModality.PSC;
        }
        return GicModality.None;
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (err.status === 400 && err.error) {
                errorMessage = err.error;
            } else {
                errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
            }
        }
        return observableThrowError(() => errorMessage);
    }
}


import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,throwError as observableThrowError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { OrderableTestsTest } from '../billable/orderable-tests-test';
import { PersonBiometric, PersonBiometricPut, PersonBiometricResult, PersonBiometricSubmits } from './person-biometric';
import { PersonPortalSettings } from './person-portal-settings';
import { PersonPortalSettingsPut } from './person-portal-settings-put';
import { User } from './user';
import { UserAddressValidation, UserAddressValidationResult, UserInformation, UserInformationPut, UserInformationRequestVerification, UserInformationResult, UserInformationSubmits } from './user-information';
import { UserPut } from './user-put';

@Injectable()
export class UserService {
    private currentUserSource = new BehaviorSubject<User>(null);
    currentUser = this.currentUserSource.asObservable();
    constructor(private _http: HttpClient) { }

    fetchUser() {
        this._http
            .get<User>(environment.userInfoEndpoint)
            .pipe(retry(1))
            .subscribe((data) => {
                const user = new User(data);
                this.currentUserSource.next(user);
            });
    }

    getOtherUser(userKey: string) {
        let params = new HttpParams();
        params = params.append('key', userKey);
        params = params.append('keyType', 'User');

        return this._http.get<User>(environment.userInfoEndpoint, { params: params }).pipe(retry(1)).pipe(catchError(this.handleError));
    }

    getUserSettings(userKey: string) {
        let params = new HttpParams();
        params = params.append('userKey', userKey);

        return this._http
            .get<PersonPortalSettings>(environment.baseCoreApiEndpoint + '/person/portal-settings', {
                params: params
            })
            .pipe(retry(1)).pipe(
                catchError(this.handleError));
    }

    setUserSettings(settings: PersonPortalSettingsPut) {
        return this._http.put(environment.baseCoreApiEndpoint + '/person/portal-settings', settings).pipe(catchError(this.handleError));
    }

    getCoachUsers() {
        let params = new HttpParams();
        params = params.append('userType', 'coach');
        return this._http.get<User>(environment.baseCoreApiEndpoint + '/users', { params: params }).pipe(catchError(this.handleError));
    }

    updateCoachUser(userPut: UserPut) {
        return this._http.put<User>(environment.baseCoreApiEndpoint + '/users', userPut).pipe(catchError(this.handleError));
    }

    getUserInformation(userKey: string) {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        return this._http.get<UserInformation>(environment.baseCoreApiEndpoint + '/users/information', { params: params }).pipe(catchError(this.handleError));
    }

    getDemographicWidgetInformation(userKey: string) {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        return this._http.get<UserInformation>(environment.baseCoreApiEndpoint + '/users/information/demographic-info', { params: params })
            .pipe(catchError(this.handleError));
    }
    isUserInformationNeeded(userKey: string, programServiceKey: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        params = params.append('programServiceKey', programServiceKey);
        return this._http.get<any>(environment.baseCoreApiEndpoint + '/users/information/is-needed', { params: params }).pipe(catchError(this.handleError));
    }

    isUserInformationNeededAll(userInformationRequest: UserInformationRequestVerification): Observable<boolean> {
        return this._http.post<boolean>(environment.baseCoreApiEndpoint + '/users/information/is-needed-all', userInformationRequest).pipe(catchError(this.handleError));
    }

    createUserInformation(userInformationSubmit: UserInformationSubmits) {
        return this._http.post<UserInformationResult>(environment.baseCoreApiEndpoint + '/users/information', userInformationSubmit).pipe(catchError(this.handleError));
    }

    validateUserInformation(addressValidation: UserAddressValidation) {
        return this._http.post<UserAddressValidationResult>(environment.baseCoreApiEndpoint + '/users/information/validate-address', addressValidation)
            .pipe(catchError(this.handleError));
    }

    updateUserInformation(userInformationPut: UserInformationPut) {
        return this._http.put<UserInformationResult>(environment.baseCoreApiEndpoint + '/users/information', userInformationPut).pipe(catchError(this.handleError));
    }

    getUserBiometric(userKey: string, programServiceKey: string) {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        params = params.append('programServiceKey', programServiceKey);
        return this._http.get<PersonBiometric>(environment.baseCoreApiEndpoint + '/person/biometric', { params: params }).pipe(catchError(this.handleError));
    }

    getSelfBioMetricTests(userKey: string, programServiceKey: string) {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        params = params.append('programServiceKey', programServiceKey);
        return this._http.get<OrderableTestsTest[]>(environment.baseCoreApiEndpoint + '/person/biometric/biometric-tests', { params: params }).pipe(catchError(this.handleError));
    }

    isUserBiometricNeeded(userKey: string, programServiceTypeKey: string, programServiceKey: string): Observable<boolean> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        params = params.append('programServiceTypeKey', programServiceTypeKey);
        params = params.append('programServiceKey', programServiceKey);
        return this._http
            .get<any>(environment.baseCoreApiEndpoint + '/person/biometric/is-needed', {
                params: params
            }).pipe(
                catchError(this.handleError));
    }

    createUserBiometric(userBiometricSubmit: PersonBiometricSubmits) {
        return this._http.post<PersonBiometricResult>(environment.baseCoreApiEndpoint + '/person/biometric', userBiometricSubmit).pipe(catchError(this.handleError));
    }

    updateUserBiometric(userBiometricPut: PersonBiometricPut) {
        return this._http.put<PersonBiometricResult>(environment.baseCoreApiEndpoint + '/person/biometric', userBiometricPut).pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (err.status === 400 && err.error) {
                errorMessage = err.error;
            } else {
                errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
            }
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

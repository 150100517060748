
export class UserDataCaptureResult{
    requiresDataCapture: boolean;
    hasDataCapture: boolean
    userDataCapture: UserDataCapture
}
export class UserDataCapture{
    userKey: string;
    key: string;
    type: UserDataCaptureType
}
export enum UserDataCaptureType{
    COVID 
}
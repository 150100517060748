import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DocumentInterruptSource, Idle } from '@ng-idle/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { environment } from '../environments/environment';
import { authConfig } from './auth.config';
import { CustomizeService } from './customize/customize.service';
import { PortalPromptModalComponent } from './portal-prompt-modal/portal-prompt-modal.component';
import { PortalPromptModalService } from './portal-prompt-modal/portal-prompt-modal.service';
import { SelfVerifyModalComponent } from './self-verify-modal/self-verify-modal.component';
import { SelfVerifyModalService } from './self-verify-modal/self-verify-modal.service';
import { WCSMessageService } from './services/wcs-message/wcs.message.service';
import { WCSMessage, WCSMessageTypes } from './services/wcs-message/wcs-message';
import { AlertModalComponent } from './shared/alert-modal/alert-modal.component';
import { Company } from './shared/company/company';
import { ConsentsNeeded } from './shared/consent-modal/consent';
import { ConsentService } from './shared/consent-modal/consent.service';
import { ConsentModalComponent } from './shared/consent-modal/consent-modal.component';
import { Globals } from './shared/globals';
import { Customization } from './shared/localize/customized-data';
import { LocalizeService } from './shared/localize/localize.service';
import { Person } from './shared/person/person';
import { PersonService } from './shared/person/person.service';
import { ReportingService } from './shared/reporting/reporting.service';
import { TimeoutModalComponent } from './shared/timeout-modal/timeout-modal.component';
declare let pendoBootstrap: any;
import './../assets/pendo-init.js';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    thisYear = new Date().getFullYear();
    isReadyForRender = false;
    isReadyForLocalize = false;
    isReadyForCustomize = false;
    hasReloadError = false;
    isReadyRepaint = false;
    hasError = false;
    company: string;
    subscriptions: ISubscription[] = [];
    modalRef: NgbModalRef;
    currentUser: Person;
    isNavbarCollapsed = true;
    userAdminURL = environment.userAdminURL;
    selfVerifyUrl = environment.selfVerifyUrl;
    userHasTableauAccess = false;
    tableauUserAccessFetched = false;
    formattedCompanyInformation = "";
    idleState = 'Not started.';
    timedOut = false;
    displayVerifyLink = true;
    // Logout Reminder
    showLogoutReminder = false;
    selfVerifyStyle = null;
    logoSrc = '/assets/LabcorpLogo.png';
    portalPromptPopupStyle = null;
    testMode: string;
    constructor(private _oauthService: OAuthService, private _localizeService: LocalizeService, private _reportingService: ReportingService,
        private _router: Router, private _activatedRoute: ActivatedRoute, private _personService: PersonService,
        private _modalService: NgbModal, private _cookieService: CookieService, private _customizeService: CustomizeService,
        private idle: Idle, private _messageService: WCSMessageService,
        private _selfVerifyModalService: SelfVerifyModalService,
        private _consentService: ConsentService,
        private _portalPromptModalService: PortalPromptModalService,
        private _cdr: ChangeDetectorRef
    ) {

        this.subscriptions.push(this._activatedRoute.queryParams.subscribe((params: Params) => {
            this.company = this.getCompanyParam();
            this.testMode = this.isTestMode()
            this.configureWithNewConfigApi();
        }));

        const interruptSource = [new DocumentInterruptSource(
            'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')];

        idle.setIdle(Globals.Timeout.Idle);
        idle.setTimeout(Globals.Timeout.Timeout);
        idle.setInterrupts(interruptSource);

        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
            console.log('No longer idle...');
        });

        idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            console.log('Timed out.');
            window.location.href = '/logout';
        });

        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!';
            console.log('Idle start...');
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!';
            if (countdown === Globals.Timeout.Warning) {
                this.modalRef = this._modalService.open(TimeoutModalComponent, {
                    backdrop: 'static'
                });
            }
        });

        this.reset();
    }

    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    getCompanyParam() {
        const url = window.location.href;
        const regex = new RegExp('[?&]company(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results || !results[2]) {
            return null;
        }
        return decodeURIComponent(results[2].replace(/\+/g, ''));
    }
    isTestMode() {
        const url = window.location.href;
        const regex = new RegExp('[?&]istestmode(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results || !results[2]) {
            return null;
        }
        return decodeURIComponent(results[2].replace(/\+/g, ''));
    }

    showTermsOfUse() {
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static',
            windowClass: "terms-and-privacy-modal"
        });
        this.modalRef.componentInstance.header = this._localizeService.get('termsofuse', 'label');
        this.modalRef.componentInstance.msg = this._localizeService.get('loading', 'label');
        this.modalRef.componentInstance.cancelBtn = this._localizeService.get('ok', 'label')

        this.subscriptions.push(this._localizeService.getTermsOfUse().subscribe(tou => {
            this.modalRef.componentInstance.msg = tou;
        }));

        this.modalRef.componentInstance.download = environment.identityProvider
            + '/Registration/OpenPDF?fileName=WCPlus_TOU.pdf';
    }

    showPrivacyPolicy() {
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static',
            windowClass: "terms-and-privacy-modal"
        });
        this.modalRef.componentInstance.header = this._localizeService.get('privacypolicy', 'label');
        this.modalRef.componentInstance.msg = this._localizeService.get('loading', 'label');
        this.modalRef.componentInstance.cancelBtn = this._localizeService.get('ok', 'label')
        this.subscriptions.push(this._localizeService.getPrivacyPolicy().subscribe(pp => {
            this.modalRef.componentInstance.msg = pp;
        }));

        this.modalRef.componentInstance.download = environment.identityProvider
            + '/Registration/OpenPDF?fileName=WCPlus_Privacy_Policy.pdf';
    }
    loadPendo(pendouser) {
        new pendoBootstrap(pendouser)
    }
    private configureWithNewConfigApi() {
        this._oauthService.configure(authConfig);
        this._oauthService.customQueryParams = {}
        if (this.company) {
            this._oauthService.customQueryParams['company'] = this.company;
        }
        if (this.testMode) {
            this._oauthService.customQueryParams['istestmode'] = this.testMode;

        }



        this._oauthService.setupAutomaticSilentRefresh();

        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
        this._oauthService.loadDiscoveryDocument().then(() => {
            this._oauthService.tryLogin().then(() => {
                if (!this._oauthService.hasValidAccessToken()) {
                    // Ensure to set the cookie to the root path ('/')
                    this._cookieService.set('initialhref', window.location.href, null, '/');
                    this._oauthService.initImplicitFlow();
                } else {
                    if (this._cookieService.get('initialhref')) {
                        const location = this._cookieService.get('initialhref');
                        this._cookieService.delete('initialhref');
                        window.location.assign(location);
                    }
                    this._personService.fetchPerson();
                }
            })
                .catch(error => {
                    // The error response (OAuthErrorEvent) and the
                    // 'invalid_nonce_in_state' error type are based on documentation from
                    // https://manfredsteyer.github.io/angular-oauth2-oidc/docs/classes/OAuthErrorEvent.html#info
                    if (error.type && error.type === 'invalid_nonce_in_state') {
                        // Redirect the user to the Portal to restart the OpenID
                        // Connect Implicit Flow
                        window.location.assign(environment.originUrl);
                    }
                });
        });
    }

    public closeLogoutReminder() {
        this.showLogoutReminder = false;
    }

    private onError() {
        const that = this;

        this.hasError = true;

        // Give them time to read...
        setTimeout(function () {
            that._router.navigate(['logout']);
        }, 3000);
    }

    ngOnInit() {
        const css = this.setCSS();
        const style = document.createElement('style');
        style.innerHTML = css;
        document.getElementsByTagName('head')[0].appendChild(style);
        this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
            if (isReady) {
                this.isReadyForLocalize = isReady;
                this.isReady();
            }
        }));

        this.subscriptions.push(this._localizeService.shouldRerender.subscribe(shouldRepaint => {
            if (shouldRepaint) {
                that.isReadyForRender = false;
                this._cdr.detectChanges();
                this.isReadyForRender = true;
            }
        }));

        if (window.location.href.indexOf('error-reload') !== -1) {
            this.hasReloadError = true;
            return;
        }

        this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
            this.isReadyForRender = false;
            if (currentPerson) {

                this.currentUser = currentPerson;

                if (!currentPerson.acceptedTOUPP) {
                    alert('You must complete registration and accept the Terms of Use and Notice of Privacy Practices before proceeding.');
                    this._router.navigate(['logout']);
                    return;
                }

                if (this.currentUser) {
                    this.processSelfVerifyModal();
                    this.processPortalPromptModal();
                }

                if (!this.currentUser.isAnyAdmin) {
                    this.processConsentPromptModal();
                }

                this._reportingService.fetchTableauUserAccess(this.currentUser.userKey);
                this.subscriptions.push(this._reportingService.tableauUserAccess.subscribe(
                    {
                        next: (data) => {
                            this.tableauUserAccessFetched = true;
                            this.isReady();
                            if (data) {
                                if (data.isSuccess) {
                                    const currentTabUser = data.data;
                                    if (currentTabUser && !currentTabUser.revokedDate) {
                                        this.userHasTableauAccess = true;
                                    }
                                }
                            }
                        },
                        error: () => this.onError()

                    }));
                this._personService.fetchPendoUser(this.getCompanyParam() || "")
                this.subscriptions.push(this._personService.pendoUser.subscribe(data => {
                    if (data && data.account) {
                        this.loadPendo(data)
                    } else {
                        console.error('ERROR: Error retrieving pendo user with result -  ' + data);
                    }
                }))

                this.subscriptions.push(this._customizeService.fetchCustomization(currentPerson.company.key).subscribe(customization => {
                    this.setCustomizeStyle(customization);
                    this.isReadyForCustomize = true;
                    this.isReady();
                }));
            }
        }));
        this.subscriptions.push(this._messageService.message.subscribe(message => {
            this.processWCSMessage(message);
        }))
        this.subscriptions.push(this._personService.currentPersonError.subscribe(isError => {
            if (isError) {
                this.hasReloadError = true;
                this._router.navigate(['error-reload']);
            }
        }));


        // Setup Logout Reminder
        const that = this;
        setTimeout(function () {
            if (that.currentUser.isWcsAdmin || that.currentUser.isSupportUser) {
                return;
            }
            that.showLogoutReminder = true;
        }, Globals.LogoutReminder.Timeout);
    }

    private isReady() {
        if (this.isReadyForCustomize && this.isReadyForLocalize && this.tableauUserAccessFetched) {
            this.isReadyForRender = true;
        }
    }

    setCompanyInformationForAdmin(company: Company) {
        this.formattedCompanyInformation = company.loginTypeVisitorDescription();
    }

    processSelfVerifyModal() {
        if (!this.currentUser)
            return;

        if (this._selfVerifyModalService.shouldSeeSelfVerifyModal(this.currentUser)) {
            const currentWindow = window.innerWidth;
            const selfVerifyModalInstance = this._modalService.open(SelfVerifyModalComponent, {
                size: currentWindow <= 375 ? 'sm' : 'lg',
                backdrop: false,
                keyboard: false,
                windowClass: 'modal-selfverify'
            })
            selfVerifyModalInstance.componentInstance.person = this.currentUser;
            this.selfVerifyStyle = this._selfVerifyModalService.selfVerifyStyle;

        }
    }

    processConsentPromptModal() {
        if (!this.currentUser)
            return;

        this._consentService
            .getOffsiteConsents()
            .subscribe((result) => {
                if (result && result.result.length) {
                    this.captureConsent(result.result);
                }
            });
    }

    private captureConsent(outstandingConsent: ConsentsNeeded[]): Promise<boolean> {
        return new Promise((resolve) => {
            this.modalRef = this._modalService.open(ConsentModalComponent, {
                backdrop: "static",
                size: "lg",
            });
            this.modalRef.componentInstance.consentsNeeded = outstandingConsent;
            this.modalRef.componentInstance.personKey = this.currentUser.personKey;
            this.modalRef.componentInstance.userKey = this.currentUser.userKey;
            this.modalRef.componentInstance.participantKey = this.currentUser.participantKey;
            this.modalRef.componentInstance.isOffsiteConsents = true;
            this.modalRef.result.then(
                (result) => {
                    if (result.hasConsent) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                () => {
                    resolve(false);
                }
            );
        });
    }


    processPortalPromptModal() {
        if (!this.currentUser)
            return;

        this._portalPromptModalService.getPortalPromptForUser(this.currentUser).then((prompt) => {
            if (prompt == null) {
                return;
            }
            const currentWindow = window.innerWidth;
            const portalPromptPopupModalInstance = this._modalService.open(PortalPromptModalComponent, {
                size: currentWindow <= 375 ? 'sm' : 'lg',
                backdrop: false,
                keyboard: false,
                windowClass: 'modal-portal-popup'
            })
            portalPromptPopupModalInstance.componentInstance.person = this.currentUser;
            portalPromptPopupModalInstance.componentInstance.portalPrompt = prompt;
            this.portalPromptPopupStyle = this._portalPromptModalService.portalPromptPopupStyle;
        });
    }

    processWCSMessage(message: WCSMessage) {
        if (message) {
            switch (message.type) {
                case WCSMessageTypes.AdminCompanyInformation:
                    if (message.data) {
                        const result = message.data;
                        const company = new Company();
                        company.companyName = result.companyName;
                        company.loginType = result.loginType;
                        company.visitorCode = result.visitorCode;
                        this.setCompanyInformationForAdmin(company);
                    }
                    break;
                case WCSMessageTypes.SelfVerifyStyleUpdate:
                    this.selfVerifyStyle = message.data;
                    break;
            }
        }
    }

    private setCSS() {
        let cssDefault = '.primary-text { color: ' + Globals.Colors.color1 + ' !important}';
        cssDefault += '.primary-bg { background-color: ' + Globals.Colors.color1 + ' !important; border-color: ' +
            Globals.Colors.color1 + ' !important}';
        cssDefault += '.secondary-bg { background-color: ' + Globals.Colors.color2 + ' !important; border-color: ' +
            Globals.Colors.color2 + ' !important}';
        cssDefault += '.secondary-text { color: ' + Globals.Colors.color2 + ' !important}';
        cssDefault += '.tertiary-bg { background-color: ' + Globals.Colors.color3 + ' !important; border-color: ' +
            Globals.Colors.color3 + ' !important}';
        cssDefault += '.tertiary-text { color: ' + Globals.Colors.color3 + ' !important}';

        return cssDefault;
    }

    private setCustomizeStyle(customization: Customization) {
        if (customization && customization.customizationStyle) {
            let hasColorOverrides = false;
            let cssCustomize = '';

            if (customization.customizationStyle.primaryColor) {
                hasColorOverrides = true;
                cssCustomize += '.primary-text { color: ' + customization.customizationStyle.primaryColor + ' !important}';
                cssCustomize += '.primary-bg { background-color: ' + customization.customizationStyle.primaryColor + ' !important; border-color: ' +
                    customization.customizationStyle.primaryColor + ' !important}';
            }

            if (customization.customizationStyle.secondaryColor) {
                hasColorOverrides = true;
                cssCustomize += '.secondary-bg { background-color: ' + customization.customizationStyle.secondaryColor + ' !important; border-color: ' +
                    customization.customizationStyle.secondaryColor + ' !important}';
                cssCustomize += '.secondary-text { color: ' + customization.customizationStyle.secondaryColor + ' !important}';
            }

            if (customization.customizationStyle.tertiaryColor) {
                hasColorOverrides = true;
                cssCustomize += '.tertiary-bg { background-color: ' + customization.customizationStyle.tertiaryColor + ' !important; border-color: ' +
                    customization.customizationStyle.tertiaryColor + ' !important}';

                cssCustomize += '.tertiary-text { color: ' + customization.customizationStyle.tertiaryColor + ' !important}';
            }
            if (customization.customizationStyle.quaternaryColor) {
                hasColorOverrides = true;
                cssCustomize += '.quaternaryColor-bg { background-color: ' + customization.customizationStyle.quaternaryColor + ' !important; border-color: ' +
                    customization.customizationStyle.quaternaryColor + ' !important}';
                cssCustomize += '.quaternaryColor-text { color: ' + customization.customizationStyle.quaternaryColor + ' !important}';
            }
            if (customization.customizationStyle.logo) {
                hasColorOverrides = true;

                this.logoSrc = customization.customizationStyle.logo;
            }

            if (hasColorOverrides) {
                const style = document.createElement('style');
                style.innerHTML = cssCustomize;
                document.getElementsByTagName('head')[0].appendChild(style);
            }
        }
    }


    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
}

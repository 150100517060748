<div *ngIf="readyForRender">
    <div *ngIf="mode !== AccessMode.ParticipantView || allAccessRequests.length > 0">
        <h4 class="primary-text" id="titleId-AllAccessRequests">{{ 'allaccessrequests' | lesCustomLocalize : 'label' }}</h4>
        <div *ngIf="showRequestAllAccess()">
            <a (click)="requestAllAccess()" id="actionId-RequestAllAccessfromUser">{{ 'requestallaccessfromuser' | lesCustomLocalize : 'label' }}</a>
        </div>

        <app-wcs-table
            [records]="allAccessRequests"
            [settings]="displaySettings"
            [currentUser]="currentUser"
            [currentSortProp]="'requestDate'"
            [currentSortDirection]="'desc'"
            [useBootstrapResponsiveTable]="false"
            [htmlId]="'AllAccessRequests'"
        ></app-wcs-table>
    </div>
</div>

<app-error *ngIf="hasError" [htmlId]="'AllAccessRequests'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loadingx' | lesCustomLocalize : 'label' : ('allaccessrequests' | lesCustomLocalize : 'label')"></app-loading>

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-LoadDocument">
        {{ fileName }}
    </h4>
    <button type="button" class="close" id="actionId-CloseLoadDocument" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="alert alert-info" *ngIf="readyForRender">
    <div *ngIf="!showDownload">
        <span *ngIf="showspin"><i class="fa fa-spinner fa-spin"></i></span> <span *ngIf="msg">{{ msg }}</span
        ><span *ngIf="!msg">{{ 'loading' | lesCustomLocalize : 'label' }}</span>
    </div>

    <a *ngIf="showDownload" type="application/pdf" id="actionId-CloseLoadDocument" [download]="fileName" [href]="downloadLink" class="tertiary-bg btn btn-block" (click)="closeModal()">{{ 'button2' | lesCustomLocalize : 'program' }}</a>

    <div *ngIf="showDownload && showExperienceMessage">
        <span>{{ experienceMessage }}</span>
    </div>
</div>

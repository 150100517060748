
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of,throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ServiceQualificationRuleFailures } from '../service';
import { ServiceQualificationRuleOverrideCodeResponse } from './service-qualification-rule-override-response-model';

@Injectable()
export class ServiceQualificationRuleOverrideCodeService {
    constructor(private _http: HttpClient) { }

    shouldAllowOverrideCodeUse(programServiceKey: string, serviceQualificationRuleFailures: ServiceQualificationRuleFailures[]): boolean {
        //check that all the failures apply to this service and are overridable
        const allow =
            serviceQualificationRuleFailures &&
            serviceQualificationRuleFailures.length > 0 &&
            serviceQualificationRuleFailures.every(
                (i) => (i.programServiceKey === programServiceKey && i.overrideableByProgramService) || (typeof i.programServiceGroupKey != 'undefined' && i.programServiceGroupKey != null && i.programServiceGroupKey.length > 0)
            );
        return allow;
    }

    validateOverrideCode(overrideCode: string, programServiceKey: string, serviceQualificationRuleFailures: ServiceQualificationRuleFailures[]): Observable<ServiceQualificationRuleOverrideCodeResponse> {
        if (!this.shouldAllowOverrideCodeUse(programServiceKey, serviceQualificationRuleFailures)) {
            const response = new ServiceQualificationRuleOverrideCodeResponse();
            response.serviceQualificationRuleFailures = serviceQualificationRuleFailures;
            response.isValid = false;
            return of(response);
        }
        //validate code
        return this._http
            .post<ServiceQualificationRuleOverrideCodeResponse>(`${environment.baseCoreApiEndpoint}/program-qualification-overrides?code=${overrideCode}&programServiceKey=${programServiceKey}`, {}, { observe: 'response' }).pipe(
                map((response) => {
                    return response.body;
                }),
                catchError(this.handleError),);
    }

    useOverrideCode(overrideCode: string, programServiceKey: string, userKey: string): Observable<ServiceQualificationRuleOverrideCodeResponse> {
        return this._http
            .put<ServiceQualificationRuleOverrideCodeResponse>(`${environment.baseCoreApiEndpoint}/program-qualification-overrides?code=${overrideCode}&programServiceKey=${programServiceKey}&userKey=${userKey}`, {}, { observe: 'response' }).pipe(
                map((response) => {
                    return response.body;
                }),
                catchError(this.handleError),);
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

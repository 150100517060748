<div class="container widgets-page pb-2" *ngIf="isRenderReady && _widgetCollectionWidgets.length > 0">
    <div class="row personWidgetHeaderContainer" id="titleId-WidgetCollection">
        <div class="col-md-12 widget-columns">
            <h3>
                {{ personWidgetCollection.titleLanguage }}
            </h3>
        </div>
        <div class="col-md-12" style="text-align: left; margin-top: 5px">
            {{ personWidgetCollection.descriptionLanguage }}
        </div>
    </div>

    <div class="widget-engine-container row">
        <div class="personWidgetSwitchAccordionContainer col-sm-12 widget-columns">
            <div ngbAccordion #acc="ngbAccordion" [closeOthers]="true" style="padding-top: 5px">
                <div
                    *ngFor="let widgetPerson of _widgetCollectionWidgets; let i = index"
                    id="panelID_{{ widgetPerson.widgetPanelIndex }}"
                    class="{{ widgetPerson.status }}"
                    ngbAccordionItem="panelID_{{ widgetPerson.widgetPanelIndex }}"
                    [collapsed]="'panelID_' + widgetPerson.widgetPanelIndex !== activeId"
                    (hidden)="onAccordionHidden('panelID_' + widgetPerson.widgetPanelIndex)"
                    (shown)="onAccordionShown('panelID_' + widgetPerson.widgetPanelIndex)"
                >
                    <div ngbAccordionHeader class="{{ widgetPerson.status }}" [ngClass]="{ ' active': 'panelID_' + widgetPerson.widgetPanelIndex === activeId }">
                        <button type="button" class="btn personWidgetHeaderButton w-100 d-flex justify-content-between align-items-center" ngbAccordionToggle>
                            <span class="widgetPersonTitle flex-grow-1" id="{{ 'titleId-' + getHtmlId(widgetPerson.titleLanguage) + 'Widget' }}">
                                {{ widgetPerson.titleLanguage }}
                            </span>
                            <span class="widgetPersonTitleIcon flex-shrink-0">
                                <i *ngIf="widgetPerson.widgetIcon" class="{{ widgetPerson.widgetIcon }}"></i>
                            </span>
                        </button>
                    </div>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                            <ng-template ngbPanelContent>
                                <ng-container [ngSwitch]="widgetPerson.widgetKey">
                                    <div class="personWidgetSwitchAccordion" *ngSwitchCase="'6fc555aa-0285-4fed-a23b-ae73a8b291bb'">
                                        <!--consent-->
                                        <app-person-consent-widget
                                            [personWidget]="widgetPerson"
                                            [currentPerson]="currentUser"
                                            [otherPerson]="otherPerson"
                                            (onWidgetCompleted)="onCompleted($event)"
                                            [programServiceKey]="programServiceKey"
                                            [personKey]="personKey"
                                            [programServiceTypeKey]="programServiceTypeKey"
                                            (onUpdateAttempted)="onUpdateAttempted($event)"
                                        >
                                        </app-person-consent-widget>
                                    </div>

                                    <div class="personWidgetSwitchAccordion" *ngSwitchCase="'60b466f7-6a4f-42aa-befd-51f3f6528695'">
                                        <!--patient data collection-->
                                        <app-person-data-collection-widget
                                            [personWidget]="widgetPerson"
                                            [currentPerson]="currentUser"
                                            [otherPerson]="otherPerson"
                                            (onWidgetCompleted)="onCompleted($event)"
                                            [programServiceKey]="programServiceKey"
                                            [personKey]="personKey"
                                            [programServiceTypeKey]="programServiceTypeKey"
                                            (onUpdateAttempted)="onUpdateAttempted($event)"
                                            [widgetTitle]="widgetPerson.titleLanguage"
                                        >
                                        </app-person-data-collection-widget>
                                    </div>

                                    <div class="personWidgetSwitchAccordion" *ngSwitchCase="'e7a4d432-9128-4b5f-9aeb-2ee4f1188b42'">
                                        <!--Self-Reporting(Biometrics)-->
                                        <app-person-biometric-widget
                                            [personWidget]="widgetPerson"
                                            [currentPerson]="currentUser"
                                            [otherPerson]="otherPerson"
                                            (onWidgetCompleted)="onCompleted($event)"
                                            [programServiceKey]="programServiceKey"
                                            [personKey]="personKey"
                                            [programServiceTypeKey]="programServiceTypeKey"
                                            (onUpdateAttempted)="onUpdateAttempted($event)"
                                        >
                                        </app-person-biometric-widget>
                                    </div>

                                    <div class="personWidgetSwitchAccordion" *ngSwitchCase="'acb9ba73-8087-4ebe-97b1-471dd70dd509'">
                                        <!--Payment insurace-->
                                        <app-person-insurance-widget
                                            [personWidget]="widgetPerson"
                                            [currentPerson]="currentUser"
                                            [otherPerson]="otherPerson"
                                            (onWidgetCompleted)="onCompleted($event)"
                                            [programServiceKey]="programServiceKey"
                                            [personKey]="personKey"
                                            [programServiceTypeKey]="programServiceTypeKey"
                                            (onUpdateAttempted)="onUpdateAttempted($event)"
                                        >
                                        </app-person-insurance-widget>
                                    </div>

                                    <div class="personWidgetSwitchAccordion" *ngSwitchCase="'fb3d0f66-915f-46d8-acaa-5df611760dff'">
                                        <!--Attestation-->
                                        <app-person-attestation-widget
                                            [personWidget]="widgetPerson"
                                            [currentPerson]="currentUser"
                                            [otherPerson]="otherPerson"
                                            (onWidgetCompleted)="onCompleted($event)"
                                            [programServiceKey]="programServiceKey"
                                            [personKey]="personKey"
                                            [programServiceTypeKey]="programServiceTypeKey"
                                            (onUpdateAttempted)="onUpdateAttempted($event)"
                                        >
                                        </app-person-attestation-widget>
                                    </div>

                                    <div class="personWidgetSwitchAccordion" *ngSwitchCase="'bde8a1e5-b664-4004-85cc-97cade1f88ea'">
                                        <!--Demographics-->
                                        <app-person-demographic-widget
                                            [personWidget]="widgetPerson"
                                            [currentPerson]="currentUser"
                                            [otherPerson]="otherPerson"
                                            (onWidgetCompleted)="onCompleted($event)"
                                            [programServiceKey]="programServiceKey"
                                            [personKey]="personKey"
                                            [programServiceTypeKey]="programServiceTypeKey"
                                            (onUpdateAttempted)="onUpdateAttempted($event)"
                                        >
                                        </app-person-demographic-widget>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div></div>

                <div class="alert alert-danger col-sm-12" *ngIf="hasError">
                    <app-error [htmlId]="'SecureMessaging'"></app-error>
                </div>
            </div>

            <div class="row mt-2 personWidgetHeaderContainer" *ngIf="showContinueButton">
                <div class="col-md-12">
                    <p>{{ 'message.widgetcontinue' | lesCustomLocalize : '' }}</p>
                    <button type="button" class="les-widget-link" id="actionId-ContinueWidgetCollection" (click)="onContinueButton()">
                        {{ 'button.continue' | lesCustomLocalize : '' }}
                    </button>
                </div>
            </div>
        </div>

        <app-loading *ngIf="!isRenderReady"></app-loading>
    </div>
</div>

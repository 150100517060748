<div *ngIf="readyForRender">
    <div *ngIf="notificationMessages.length > 0" class="row" id="displayId-NotificationMessages">
        <div *ngFor="let message of notificationMessages" class="app-notification" [innerHTML]="message.message | safeHtml"></div>
    </div>

    <div class="row" *ngIf="getUser().hasOnlineScheduler || getUser().hasSchedulerAccess">
        <div class="col-md-12">
            <app-upcoming-appointments [otherUser]="otherUser"></app-upcoming-appointments>
        </div>
    </div>

    <div class="row pt-2">
        <ng-container *ngFor="let userService of userServices">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <div class="card text-center secondary-bg mb-2">
                    <div class="card-body secondary-bg">
                        <div class="title-wrapper">
                            <h5 class="card-title" id="{{ 'titleId-' + getHtmlId(userService.programService.serviceCardLabel?.toUpperCase(), userService.programService) }}">
                                {{ getProgramLabel(userService.programService.serviceCardLabel?.toUpperCase(), userService.programService) }}
                            </h5>
                        </div>

                        <hr />

                        <p class="tertiary-text card-text">
                            {{ getProgramDescription(userService.programService.serviceDescription, userService.programService) }}
                        </p>

                        <div class="card-buttons">
                            <div class="card-unavailable" *ngIf="userService.serviceQualificationRuleFailures.length > 0">
                                <span>{{ 'notcurrentlyavailable' | lesCustomLocalize : 'message' }}</span>
                                <button
                                    class="btn-link"
                                    (click)="openQualificationRuleFailureReason(userService.serviceQualificationRuleFailures)"
                                    id="{{ 'actionId-Why' + getHtmlId(userService.programService.serviceCardLabel?.toUpperCase(), userService.programService) }}"
                                >
                                    {{ 'why' | lesCustomLocalize : 'label' }}
                                </button>
                            </div>

                            <div *ngIf="userService.serviceQualificationRuleFailures.length === 0">
                                <button
                                    *ngIf="!userService.programService.isElectronicUpload && !isDownLoad(userService.programService) && adminCanPerform(userService.programService)"
                                    class="tertiary-bg btn btn-block"
                                    id="{{ 'actionId-' + getHtmlButtonId(userService.programService.serviceCardLabel?.toUpperCase(), userService.programService, userService.programService.customizationCode, 'button') }}"
                                    (click)="go(userService)"
                                >
                                    {{ getProgramCustomization(userService.programService.customizationCode, 'button', userService.programService) }}
                                </button>

                                <input type="file" #file (change)="upload($event, userService.programService)" style="display: none" />

                                <button
                                    *ngIf="isDownLoad(userService.programService)"
                                    class="tertiary-bg btn btn-block"
                                    id="{{ 'actionId-' + getHtmlButtonId(userService.programService.serviceCardLabel?.toUpperCase(), userService.programService, userService.programService.customizationCode, 'button2') }}"
                                    (click)="go(userService)"
                                >
                                    {{ getProgramCustomization(userService.programService.customizationCode, 'button2') }}
                                </button>

                                <button
                                    *ngIf="userService.programService.isElectronicUpload && !isMobileDevice && adminCanPerform(userService.programService)"
                                    class="hidden-phone tertiary-bg btn btn-block"
                                    id="{{ 'actionId-' + getHtmlButtonId(userService.programService.serviceCardLabel?.toUpperCase(), userService.programService, userService.programService.customizationCode, 'button3') }}"
                                    (click)="go(userService, true)"
                                >
                                    {{ getProgramCustomization(userService.programService.customizationCode, 'button3', userService.programService) }}
                                </button>
                            </div>
                        </div>

                        <p class="valid-through">
                            {{ 'validthrough' | lesCustomLocalize : 'label' : (userService.programService.endDate | amDateFormat : 'M/D/YYYY') }}
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { SchedulerGuard } from '../scheduler-guard.service';
import { SharedModule } from '../shared/shared.module';
import { AppointmentListComponent } from './appointments/appointment-list/appointment-list.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { CancelConfirmComponent } from './appointments/cancel-confirm/cancel-confirm.component';
import { ModifyAppointmentComponent } from './appointments/modify-appointment/modify-appointment.component';
import { NextScreeningAppointmentComponent } from './appointments/next-screening-appointment/next-screening-appointment.component';
import { PastAppointmentsComponent } from './appointments/past-appointments/past-appointments.component';
import { ResendConfirmationEmailComponent } from './appointments/resend-confirmation-email/resend-confirmation-email.component';
import { UpcomingAppointmentsComponent } from './appointments/upcoming-appointments/upcoming-appointments.component';
import { SchedulerService } from './scheduler.service';
import { CancelExistingAndAddNewConfirmComponent } from './scheduler/cancel-existing-and-add-new-confirm/cancel-existing-and-add-new-confirm.component';
import { SchedulerComponent } from './scheduler/scheduler.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'appointments', component: AppointmentsComponent, canActivate: [SchedulerGuard] },
      { path: 'appointments/:userKey', component: AppointmentsComponent, canActivate: [SchedulerGuard] },
      { path: 'scheduler', component: SchedulerComponent, canActivate: [SchedulerGuard] }
    ]),
    SharedModule,
    FormsModule,
    NgbModule,
    MomentModule,
    NgxPageScrollCoreModule,
    NgbTooltipModule
  ],
  declarations: [
    AppointmentsComponent,
    SchedulerComponent,
    AppointmentListComponent,
    CancelConfirmComponent,
    CancelExistingAndAddNewConfirmComponent,
    NextScreeningAppointmentComponent,
    ModifyAppointmentComponent,
    ResendConfirmationEmailComponent,
    UpcomingAppointmentsComponent,
    PastAppointmentsComponent],

  exports: [
    NextScreeningAppointmentComponent,
    AppointmentListComponent,
    UpcomingAppointmentsComponent,
    PastAppointmentsComponent
  ],
  providers: [
    SchedulerService
  ]
})
export class SchedulerModule { }

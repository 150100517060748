import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {

    // Url of the Identity Provider
    issuer: environment.identityProvider,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/home',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment.clientId,

    // set the scope for the permissions the client should request
    scope: 'openid profile email roles coachingapi coreapi documentapi securityapi screeningapi reportingapi coreapi.app.management coreapi.person.access',

    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    // Activate Session Checks:
    sessionChecksEnabled: true,

    // 0-1
    timeoutFactor: 0.7
};

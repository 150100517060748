
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../user/user';
import { SevereSymptomCapture, SevereSymptomCheckPost } from './severe-symptom';
@Injectable()
export class SevereSymptomService {
    private currentUserSource = new BehaviorSubject<User>(null);
    currentUser = this.currentUserSource.asObservable();

    constructor(private _http: HttpClient) { }

    checkSevereSymptomResponse(requestBody: SevereSymptomCheckPost): Observable<string> {
        return this._http.post<string>(environment.baseCoreApiEndpoint + '/integrations/pixel/severe-symptom-check', requestBody).pipe(catchError(this.handleError));
    }

    captureResponse(requestBody: SevereSymptomCapture): Observable<any> {
        return this._http.post(environment.baseCoreApiEndpoint + '/integrations/pixel/severe-symptoms', requestBody).pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

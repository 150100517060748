import { Component, OnDestroy,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { ServicesListComponent } from '../services/services-list/services-list.component';
import { AccessMode } from '../shared/access-mode';
import { LocalizeService } from '../shared/localize/localize.service';
import { Person } from '../shared/person/person';
import { PersonService } from '../shared/person/person.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  isReady = false;
  currentUser: Person = null;
  subscriptions: ISubscription[] = [];
  coachingAccessMode: AccessMode = AccessMode.Home;

  constructor(private _personService: PersonService, private _localizeService: LocalizeService, private _router: Router) { }

  ngOnInit() {
    this._localizeService.isReady.subscribe(isReady => {
      this.isReady = isReady;
    });

    this._personService.currentPerson.subscribe(person => {
      if (person.isCoach) {
        this._router.navigate(['coaching/dashboard']);
      } else if (person.isCoachAdmin) {
        this._router.navigate(['coaching/admin']);
      } else {
        this.currentUser = person;
      }
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { User } from '../../shared/user/user';
import { UserService } from '../../shared/user/user.service';
import { UserPut } from '../../shared/user/user-put';

@Component({
  selector: 'app-coach-edit',
  templateUrl: './coach-edit.component.html',
  styleUrls: ['./coach-edit.component.css']
})
export class CoachEditComponent implements OnInit {
  coach: User;
  coachImageDataURL: string;
  imageError = false;
  maxImageSizeX = 200;
  maxImageSizeY = 200;
  modalRef: NgbModalRef;
  disableSubmit = false;

  constructor(private _userService: UserService, private _sanitizer: DomSanitizer, public activeModal: NgbActiveModal,
              private _localizeService: LocalizeService, private _modalService: NgbModal) { }

  updateCoach(){
    this.disableSubmit = true;
    const putModel = new UserPut();
    putModel.key = this.coach.key;
    putModel.suffix = this._sanitizer.sanitize(SecurityContext.HTML, this.coach.suffix);
    putModel.biography = this._sanitizer.sanitize(SecurityContext.HTML ,this.coach.biography);
    putModel.isGeneralInboxMonitor = this.coach.isGeneralInboxMonitor;
    putModel.image = this.coachImageDataURL ? btoa(this.coachImageDataURL) : null;
    this._userService.updateCoachUser(putModel).subscribe( coach => {
      if(coach){
        //this.coach = new User(coach);
        this.mapToExistingCoach(coach);
        this.disableSubmit = false;
        this.onSuccess();
        this.activeModal.close();
      }
    },
    error => {
      this.disableSubmit = false;
      this.onError();
    });
  }

  // Only map the updateable attributes
  private mapToExistingCoach(coach: User) {
    this.coach.biography = coach.biography;
    this.coach.image = coach.image;
    this.coach.suffix = coach.suffix;
  }

  uploadPhoto(event: any){
    this.imageError = false;
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (event) => {
      const img = new Image();
      img.onload = () =>{
        // Disallow any images
        if(img.width !== this.maxImageSizeX && img.height !== this.maxImageSizeY){
          this.imageError = true;
          return;
        }
        this.coachImageDataURL = fileReader.result[0];
      };
      img.src = fileReader.result[0];
    }
  }

  private removePhoto(): void {
    this.coachImageDataURL = null;
  }

  private onSuccess(){
    this.modalRef = this._modalService.open(AlertModalComponent, {
      backdrop: 'static'
    });
    this.modalRef.componentInstance.msg = this._localizeService.get('updatesuccess', 'message');
    this.modalRef.componentInstance.header = this._localizeService.get('success', 'label');
  }

  private onError(msg: string = null){
    alert(msg ? msg : this._localizeService.get('unknownerror', 'message'));
  }

  ngOnInit() {
    if(this.coach){
      this.coachImageDataURL = !this.coach.image ? null : atob(this.coach.image);
    }
  }
}

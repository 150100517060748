export class PersonPortalSettingsPut {
    key: string;
    emailAddress: string;
    phone: string;
    receiveSmsReminders: boolean;
    textUseSettingsPhone: boolean;
    textMessagePhone: string;
    languageLocale: string;
    timeZoneKey: string;
    stateKey: string;
}

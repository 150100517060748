<h4 class="secondary-text" id="titleId-CustomizableText">{{ 'customizabletext' | lesCustomLocalize : 'label' }}</h4>

<div class="pb-2">
    <input type="text" *ngIf="readyForRender" name="filter" class="form-control" [(ngModel)]="filter" (keyup)="filterResults()" placeholder="{{ 'filter' | lesCustomLocalize : 'label' }}" />
</div>

<app-wcs-table
    [records]="filteredOverrides"
    [settings]="displaySettings"
    [currentUser]="currentUser"
    [htmlId]="'CustomizableText'"
    [currentSortProp]="'name'"
    [currentSortDirection]="'asc'"
    [useBootstrapResponsiveTable]="false"
    [pageSize]="10"
    [turnOffChangeDetection]="true"
></app-wcs-table>

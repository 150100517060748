import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: any, styles: any) {
    if (styles) {
      const style = ` style = '${styles}'`;
      const index = value.indexOf('>');
      if (index > -1) {
        value = [value.slice(0, index), style, value.slice(index)].join('');
      }
      return this.sanitized.bypassSecurityTrustHtml(value);
    }
    else {
      return this.sanitized.bypassSecurityTrustHtml(value);
    }
  }
}


<div class="container-fluid" *ngIf="readyForRender">
    <div class="pt-3">
        <div class="row d-lg-none">
            <div class="col text-center pb-3">
                <div class="btn-group" role="group" id="action-ParticipantInfoChoices">
                    <input type="radio" [value]="sectionValues.Appointments" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="{{ 'participant_sectionToShow_' + sectionValues.Appointments }}" />
                    <label class="btn btn-primary" id="actionId-Appointments" for="{{ 'participant_sectionToShow_' + sectionValues.Appointments }}"> {{ 'appointments' | lesCustomLocalize : 'label' }} </label>
                    <input type="radio" [value]="sectionValues.Messaging" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="{{ 'participant_sectionToShow_' + sectionValues.Messaging }}" />
                    <label class="btn btn-primary" id="actionId-Messages" for="{{ 'participant_sectionToShow_' + sectionValues.Messaging }}"> {{ 'messages' | lesCustomLocalize : 'label' }} </label>
                    <input type="radio" [value]="sectionValues.AllAccess" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="{{ 'participant_sectionToShow_' + sectionValues.AllAccess }}" *ngIf="hasAllAccessRequestsToShow" />
                    <label class="btn btn-primary" id="actionId-AllAccess" for="{{ 'participant_sectionToShow_' + sectionValues.AllAccess }}" *ngIf="hasAllAccessRequestsToShow"> {{ 'allaccess' | lesCustomLocalize : 'label' }} </label>
                </div>
            </div>
        </div>
        <div class="pb-1" *ngFor="let request of allAccessPending">
            <div class="alert alert-warning" role="alert">
                {{ 'allaccesspending' | lesCustomLocalize : 'message' : request.requestingUser.firstName + ' ' + request.requestingUser.lastName }} <i class="fa fa-info-circle pointer" aria-hidden="true" (click)="allAccessInfo()"></i>
                <button type="button" class="btn tertiary-bg btn tertiary-bg-primary btn tertiary-bg-sm ms-2" id="actionId-GrantAllAccess" (click)="grant(request)">{{ 'grant' | lesCustomLocalize : 'label' }}</button>
                <button type="button" class="btn tertiary-bg btn tertiary-bg-secondary btn tertiary-bg-sm ms-2" id="actionId-RejectAllAccess" (click)="reject(request)">{{ 'reject' | lesCustomLocalize : 'label' }}</button>
            </div>
        </div>

        <div class="row">
            <div [ngClass]="{ 'col-md-12': true, 'col-lg-6': true }">
                <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.Appointments }">
                    <app-next-coaching-appointment [mode]="accessMode" [participant]="participant"></app-next-coaching-appointment>
                    <div class="spacer"></div>
                    <app-coaching-appointment-list [mode]="accessMode" [participant]="participant" (sectionChangeRequest)="changeSection($event)"></app-coaching-appointment-list>
                </div>

                <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.AllAccess }">
                    <app-secure-message-access-request-list-component [mode]="accessMode" [participant]="participant" (hasAllAccessToView)="hasAllAccess($event)"></app-secure-message-access-request-list-component>
                </div>
            </div>

            <div [ngClass]="{ 'col-md-12': true, 'col-lg-6': true, 'd-none d-lg-block': sectionToShow !== sectionValues.Messaging }">
                <app-secure-messaging [mode]="secureMessagingMode" [participant]="participant"></app-secure-messaging>
            </div>
        </div>
    </div>
</div>

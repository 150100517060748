export class SecureMessageSearchParams {
  userKey: string;
  perspective: string;
  isUnread: boolean;
  isGeneralInbox: boolean;
  dateFilter: Date;
  hasRegarding: boolean;
  regardingType: string;
  offset: number;
  numberOfRecords: number;
}

export interface CustomizedData {
  companyKey: string;
  token: string;
  text: string;
  languageLocale: string;
  key: string;
}



export class CustomizationStyle {
  logo: string | any;
  companyKey: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  quaternaryColor: string;
}

export class CustomizationLanguage {
  constructor(public key: string, public companyKey: string, public languageLocale: string) {
  }
}

export class CustomizedMisc {
  customizationStyle: CustomizationStyle;
  customizationLanguages: CustomizationLanguage[];
}
export class Customization {
  customizationStyle: CustomizationStyle;
  customizationLanguages: CustomizationLanguage[];
  customizationTexts: CustomizedData[]
}






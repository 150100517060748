[
  {
    "groupName": "Health Risk",
    "groupSort": 1,
    "fieldName": "rst_BiometricScore",
    "fieldSort": 1,
    "units": "",
    "includeInTrendChart": false,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Health Risk",
    "groupSort": 1,
    "fieldName": "rst_MetabolicSyndrome",
    "fieldSort": 2,
    "units": "",
    "includeInTrendChart": false,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Health Risk",
    "groupSort": 1,
    "fieldName": "rst_Framingham",
    "fieldSort": 3,
    "units": "",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Health Risk",
    "groupSort": 1,
    "fieldName": "rst_ADADiabetesScore",
    "fieldSort": 4,
    "units": "",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_HeightFeet",
    "fieldSort": 1,
    "units": "Feet",
    "includeInTrendChart": "",
    "combineWithField": "scr_HeightInches",
    "secondaryDisplay": true,
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_HeightInches",
    "fieldSort": 1,
    "units": "Inches",
    "includeInTrendChart": "",
    "combineWithField": "scr_HeightFeet",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_Weight",
    "fieldSort": 2,
    "units": "lbs",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_BMI",
    "fieldSort": 3,
    "units": "Kg/m2",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_BodyFat",
    "fieldSort": 4,
    "units": "%",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_WaistInches",
    "fieldSort": 5,
    "units": "Inches",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_Hip",
    "fieldSort": 6,
    "units": "Inches",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_HipWaistRatio",
    "fieldSort": 7,
    "units": "",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_Neck",
    "fieldSort": 8,
    "units": "Inches",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },

  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_BPDiastolic1",
    "fieldSort": 9,
    "units": "mmHg",
    "includeInTrendChart": true,

    "combineWithField": "scr_BPSystolic1",
    "secondaryDisplay": "",
    "fieldDelimiter": "/"
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_BPSystolic1",
    "fieldSort": 9,
    "units": " ",
    "includeInTrendChart": true,
    "combineWithField": "scr_BPDiastolic1",
    "secondaryDisplay": true,
    "fieldDelimiter": "/"
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_BPDiastolic2",
    "fieldSort": 10,
    "units": "mmHg",
    "includeInTrendChart": true,

    "combineWithField": "scr_BPSystolic2",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_BPSystolic2",
    "fieldSort": 10,
    "units": " ",
    "includeInTrendChart": true,
    "combineWithField": "scr_BPDiastolic2",
    "secondaryDisplay": true,
    "fieldDelimiter": "/"
  },
  {
    "groupName": "Biometrics",
    "groupSort": 2,
    "fieldName": "scr_HeartRate",
    "fieldSort": 11,
    "units": "BPM",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Lipids",
    "groupSort": 3,
    "fieldName": "scr_TotalCholesterol",
    "fieldSort": 1,
    "units": "mg/dL",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Lipids",
    "groupSort": 3,
    "fieldName": "scr_HDL",
    "fieldSort": 2,
    "units": "mg/dL",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Lipids",
    "groupSort": 3,
    "fieldName": "scr_TotalHDLRatio",
    "fieldSort": 3,
    "units": "",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Lipids",
    "groupSort": 3,
    "fieldName": "scr_LDL",
    "fieldSort": 4,
    "units": "mg/dL",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Lipids",
    "groupSort": 3,
    "fieldName": "lcd_VLDL",
    "fieldSort": 5,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Lipids",
    "groupSort": 3,
    "fieldName": "scr_Triglycerides",
    "fieldSort": 6,
    "units": "mg/dL",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "scr_Glucose",
    "fieldSort": 1,
    "units": "mg/dL",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "scr_A1C",
    "fieldSort": 2,
    "units": "%",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_AlkalinePhosphatase",
    "fieldSort": 3,
    "units": "IU/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_AlbuminSerum",
    "fieldSort": 4,
    "units": "g/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_GlobulinSerum",
    "fieldSort": 5,
    "units": "g/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_AGRatio",
    "fieldSort": 6,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_ALT",
    "fieldSort": 7,
    "units": "IU/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_ProteinSerum",
    "fieldSort": 8,
    "units": "g/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_AST",
    "fieldSort": 9,
    "units": "IU/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_GGT",
    "fieldSort": 10,
    "units": "IU/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Bilirubin",
    "fieldSort": 11,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_IndirectBilirubin",
    "fieldSort": 12,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_DirBilirubin",
    "fieldSort": 13,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_BUN",
    "fieldSort": 14,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Creatinine",
    "fieldSort": 15,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_BUNCreatinineRatio",
    "fieldSort": 16,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_eGFR_AfricanAmerican",
    "fieldSort": 18,
    "units": "mL/min/1.73",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_eGFR_NonAfricanAmerican",
    "fieldSort": 17,
    "units": "mL/min/1.73",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_CO2",
    "fieldSort": 19,
    "units": "mmol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Chloride",
    "fieldSort": 20,
    "units": "mmol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Sodium",
    "fieldSort": 21,
    "units": "mmol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Potassium",
    "fieldSort": 22,
    "units": "mmol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_magnesium",
    "fieldSort": 23,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Phosphorus",
    "fieldSort": 24,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Calcium",
    "fieldSort": 25,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Iron",
    "fieldSort": 26,
    "units": "ug/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_Ferritin",
    "fieldSort": 27,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_IronSat",
    "fieldSort": 28,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_TIBC",
    "fieldSort": 29,
    "units": "ug/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_UIBC",
    "fieldSort": 30,
    "units": "ug/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_LDH",
    "fieldSort": 31,
    "units": "U/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": 4,
    "fieldName": "lcd_UricAcid",
    "fieldSort": 32,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Hemoglobin",
    "fieldSort": 1,
    "units": "g/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Hemotocrit",
    "fieldSort": 2,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_RBCCount",
    "fieldSort": 3,
    "units": "x10e6/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_MCHC",
    "fieldSort": 4,
    "units": "g/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_MCHG",
    "fieldSort": 5,
    "units": "pg",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_MCV",
    "fieldSort": 6,
    "units": "fL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_MeanPlateleteVol",
    "fieldSort": 7,
    "units": "fL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_PlateletCount",
    "fieldSort": 8,
    "units": "k/mm3",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_RedCellDistribution",
    "fieldSort": 9,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_WBC",
    "fieldSort": 10,
    "units": "x10e3/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Basophils",
    "fieldSort": 11,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_BasophilsABS",
    "fieldSort": "12",
    "units": "x10e3/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Eosinophils",
    "fieldSort": "13",
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_EosinophilsABS",
    "fieldSort": "14",
    "units": "x10e3/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Lymphocyte",
    "fieldSort": 15,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_LymphoctyeABS",
    "fieldSort": 16,
    "units": "x10e3/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Monocytes",
    "fieldSort": 17,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_MonocytesABS",
    "fieldSort": 18,
    "units": "x10e3/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_Neutrophils",
    "fieldSort": 19,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Completed Blood Count",
    "groupSort": 5,
    "fieldName": "lcd_NeutrophilsABS",
    "fieldSort": 20,
    "units": "x10e3/uL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_TSH",
    "fieldSort": 1,
    "units": "uIU/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_thyroxine",
    "fieldSort": 2,
    "units": "ug/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_FreeThyroxineIndex",
    "fieldSort": 3,
    "units": "ug/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_FreeT4",
    "fieldSort": 4,
    "units": "ng/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_T3Total",
    "fieldSort": 5,
    "units": "ng/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_T3Uptake",
    "fieldSort": 6,
    "units": "%",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_FSH",
    "fieldSort": 7,
    "units": "IU/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_EnhEstradiol",
    "fieldSort": 8,
    "units": "pg/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_TestosteroneTot",
    "fieldSort": 9,
    "units": "ng/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Hormones",
    "groupSort": 6,
    "fieldName": "lcd_InsulinSerum",
    "fieldSort": 10,
    "units": "uIU/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Nutritional",
    "groupSort": 7,
    "fieldName": "lcd_VitaminD",
    "fieldSort": 1,
    "units": "ng/mL",
    "includeInTrendChart": true,
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Nutritional",
    "groupSort": 7,
    "fieldName": "lcd_VitB12",
    "fieldSort": 2,
    "units": "ng/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Nutritional",
    "groupSort": 7,
    "fieldName": "lcd_folate",
    "fieldSort": 3,
    "units": "ug/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_Cotinine_Nicotine",
    "fieldSort": 1,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_Cotinine_serum",
    "fieldSort": 2,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_Cotinine_urine",
    "fieldSort": 3,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_Nicotine_serum",
    "fieldSort": 4,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_Nicotine_urine",
    "fieldSort": 5,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_TobaccoUse",
    "fieldSort": 6,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "scr_PSA",
    "fieldSort": 7,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "pregnant",
    "fieldSort": 20,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_Fructosamine",
    "fieldSort": 8,
    "units": "mmol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_GSP",
    "fieldSort": 9,
    "units": "umol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_CRP",
    "fieldSort": 10,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_homocysteine",
    "fieldSort": 11,
    "units": "umol/L",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_CEA",
    "fieldSort": 12,
    "units": "ng/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_CA125",
    "fieldSort": 13,
    "units": "units/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_NTPROBNP",
    "fieldSort": 14,
    "units": "pg/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": 8,
    "fieldName": "lcd_FIT_OccultBlood_Fecal",
    "fieldSort": 15,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Medical Conditions",
    "groupSort": 9,
    "fieldName": "scr_MedConditionBMI",
    "fieldSort": 1,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Medical Conditions",
    "groupSort": 9,
    "fieldName": "scr_MedConditionBP",
    "fieldSort": 2,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Medical Conditions",
    "groupSort": 9,
    "fieldName": "scr_MedConditionChol",
    "fieldSort": 3,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Medical Conditions",
    "groupSort": 9,
    "fieldName": "scr_MedConditionGlucose",
    "fieldSort": 4,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Flu",
    "groupSort": "20",
    "fieldName": "scr_vaccinetype",
    "fieldSort": 1,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Flu",
    "groupSort": "20",
    "fieldName": "scr_lotnumber",
    "fieldSort": 2,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Flu",
    "groupSort": "20",
    "fieldName": "scr_manufacturer",
    "fieldSort": 3,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Flu",
    "groupSort": "20",
    "fieldName": "scr_vaclocation",
    "fieldSort": 4,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19PCR",
    "fieldSort": 1,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19SerologyIgG",
    "fieldSort": 2,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19SerologyIgM",
    "fieldSort": 3,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19SerologyIgA",
    "fieldSort": 4,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19SerologyABRoche",
    "fieldSort": 5,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19SerologyABSiemens",
    "fieldSort": 6,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19AntigenPOC",
    "fieldSort": 7,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "COVID-19",
    "groupSort": "10",
    "fieldName": "lcd_COVID19SerologySemiquantAb",
    "fieldSort": 8,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Blood Chemistry",
    "groupSort": "4",
    "fieldName": "lcd_EAG",
    "fieldSort": 3,
    "units": "",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": "8",
    "fieldName": "lcd_AlbCreatRatio",
    "fieldSort": 18,
    "units": "mg/g creat",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": "8",
    "fieldName": "lcd_Albumin_Urine",
    "fieldSort": 16,
    "units": "ug/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": "8",
    "fieldName": "lcd_Creatinine_Urine",
    "fieldSort": 17,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": "8",
    "fieldName": "lcd_UrineProteinCreatinineRatio",
    "fieldSort": 20,
    "units": "mg/g creat",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": "8",
    "fieldName": "lcd_PTHIntact",
    "fieldSort": 21,
    "units": "pg/mL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  },
  {
    "groupName": "Other",
    "groupSort": "8",
    "fieldName": "lcd_ProteinTotal_Urine",
    "fieldSort": 19,
    "units": "mg/dL",
    "includeInTrendChart": "",
    "combineWithField": "",
    "secondaryDisplay": "",
    "fieldDelimiter": ""
  }  
]

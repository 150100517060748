
import { HttpClient, HttpErrorResponse,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject,throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MessageParticipantsPost } from './message-participants-post';
import { MessageToUser } from './message-to-user';
import { NewSecureMessage } from './new-secure-message';
import { SecureMessage } from './secure-message';
import { SecureMessageSearchParams } from './secure-message-search-params';

@Injectable()
export class SecureMessagingService {
  private requestedMessageSource = new Subject<SecureMessage>();
  requestedMessage = this.requestedMessageSource.asObservable();

  constructor(private _http: HttpClient, private _localizeService: LocalizeService) { }

  getSecureMessages(params: SecureMessageSearchParams): Observable<SecureMessage[]> {
    return this._http.post<SecureMessage[]>(environment.baseSecureMessagingApiEndpoint + '/search', params).pipe(
      map((secureMessages: SecureMessage[]) => {
        return secureMessages.map(msg => {
          return new SecureMessage(msg);
        });
      }),
      catchError(this.handleError),);

  }

  requestMessage(id: string) {
    this._http.get<SecureMessage>(environment.baseSecureMessagingApiEndpoint + '/' + id, {
      params: new HttpParams().set('includeReplies', 'true')
    }).pipe(
      map(resp => {
        return new SecureMessage(resp);
      }))
      .subscribe(data => {
        const msg = new SecureMessage(data);
        this.requestedMessageSource.next(msg);
      });
  }

  getSecureMessage(id: string): Observable<SecureMessage> {
    return this._http.get<SecureMessage>(environment.baseSecureMessagingApiEndpoint + '/' + id, {
      params: new HttpParams().set('includeReplies', 'true')
    }).pipe(
      map(resp => {
        return new SecureMessage(resp);
      }),
      catchError(this.handleError),);
  }

  markAsRead(ids: string[]): Observable<void> {
    return this._http.post<void>(environment.baseSecureMessagingApiEndpoint + '/read', ids).pipe(
      catchError(this.handleError));
  }

  createSecureMessage(msg: NewSecureMessage) {
    return this._http.post(environment.baseSecureMessagingApiEndpoint, msg).pipe(
      map(resp => {
        return new SecureMessage(resp);
      }),
      catchError(this.handleError),);
  }

  getMessageToUsers(): Observable<MessageToUser[]> {
    return this._http.get(environment.baseSecureMessagingApiEndpoint + '/availableusers').pipe(
      map((users: MessageToUser[]) => {
        return users.map(user => {
          return new MessageToUser(user);
        });
      }),
      catchError(this.handleError),);
  }

  sendMassMessage(post: MessageParticipantsPost): Observable<void> {
    return this._http.post<void>(environment.baseSecureMessagingApiEndpoint + '/message-participants', post).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}

<ng-container *ngIf="readyForRender && !hasError && !isDisabled">
    <h4 class="primary-text" id="titleId-Appointments">{{ 'appointments' | lesCustomLocalize : 'label' }}</h4>
    <ng-container *ngIf="inactiveAppointments.length">
        <br />
        <h5 class="primary-text" id="titleId-PastOrCanceledAppointments">{{ 'pastorcanceledappointments' | lesCustomLocalize : 'label' }}</h5>
        <app-wcs-table
            [records]="inactiveAppointments"
            [settings]="displaySettings"
            [htmlId]="'PastOrCanceledAppointments'"
            [currentUser]="currentUser"
            [currentSortProp]="'time'"
            [currentSortDirection]="'desc'"
            [pageSize]="6"
            [useBootstrapResponsiveTable]="false"
        ></app-wcs-table>
    </ng-container>

    <div *ngIf="!inactiveAppointments.length" class="card-text">
        {{ 'noappointmentsinactive' | lesCustomLocalize : 'message' }}
    </div>

    <div class="pt-2 card-text">
        <a *ngIf="otherUser" id="actionId-ViewYourAppointments" routerLink="/appointments/{{ otherUser.userKey }}">{{ 'viewyourlistofscreeningappointments' | lesCustomLocalize : 'message' }}</a>
        <a *ngIf="!otherUser" id="actionId-ViewYourAppointments" routerLink="/appointments">{{ 'viewyourlistofscreeningappointments' | lesCustomLocalize : 'message' }}</a>
    </div>
</ng-container>

<app-error *ngIf="hasError" [htmlId]="'PastOrCanceledAppointments'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

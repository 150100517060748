<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-AddressValidation">{{ header }}</h4>
    <button type="button" class="close" id="actionId-Close-AddressValidation" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" id="errorAddressValidationId-Confirm-AddressValidation">
    <p>{{invalidMsg}}</p>
    <div *ngIf="addressSuggestion" class="address">
        <p>{{addressSuggestion.address1}}</p>
        <p *ngIf="addressSuggestion.address2">{{addressSuggestion.address2}}</p>
        <p>{{addressSuggestion.city}}, {{addressSuggestion.stateAbbreviation}} {{addressSuggestion.zip}}</p>
    </div>
</div>

<div class="modal-footer pt-10">
    <button type="button" class="tertiary-bg btn btn-primary" id="actionId-Confirm-AddressValidation" (click)="setFlag(true)">{{ continueBtn }}</button>
    <button type="button" class="tertiary-bg btn btn-primary" id="actionId-Cancel-AddressValidation" (click)="setFlag(false)">{{ editBtn }}</button>
</div>


import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { environment } from "../../../environments/environment";
import { Globals } from "../globals";
import { LocalStorageService } from "../local-storage-service";
import { AppicationContentSearch, ApplicationContent, UITranslationView } from "./application-content-models";

@Injectable()
export class ApplicationContentService {
    APPLICATION_KEY = '036DC096-5EDA-4E2B-A512-8D60D1BA2979';
    API_PATH = '/application/content'
    constructor(private _http: HttpClient) { }
    applicationContent: Observable<ApplicationContent[]>;
    uiTranslations: Observable<UITranslationView[]>;
    _sessionExpirationInMin: number = 60000 * 5;
    getApplicationContents(search?: AppicationContentSearch): Observable<ApplicationContent[]> {
        return this._http.post<ApplicationContent[]>(environment.baseCoreApiEndpoint + this.API_PATH + '/search', search).pipe(
            catchError(this.handleError))

    }
    loadAll(force?: boolean): Observable<ApplicationContent[]> {
        const search = new AppicationContentSearch()
        if (!this.applicationContent || force) {
            this.applicationContent = this._http.post<ApplicationContent[]>(environment.baseCoreApiEndpoint + this.API_PATH + '/search', search)
                .pipe(shareReplay(1)).pipe(
                    catchError(this.handleError))
        }
        return this.applicationContent;
    }
    GetUITranslations(companyKey?: string): Observable<UITranslationView[]> {
        if (companyKey) {
            const translated = this.getTranslationFromSession(companyKey)
            if (translated) {
                this.uiTranslations = of(translated)
                return this.uiTranslations;
            }
        }

        let params = new HttpParams();
        if (companyKey) {
            params = params.append('companyKey', companyKey);
        }
        params = params.append('applicationKey', this.APPLICATION_KEY)
        this.uiTranslations = this._http.get<UITranslationView[]>(environment.baseCoreApiEndpoint + this.API_PATH + '/translations', { params: params }).pipe(
            map(r => {
                if (companyKey) {
                    this.setTranslationToSession(r, companyKey);
                }
                return r;
            }),
            catchError(this.handleError),)
        return this.uiTranslations;
    }
    private setTranslationToSession(value, companyToken) {
        const validThru = new Date(new Date().getTime() + this._sessionExpirationInMin);
        const sessionValue = {
            translations: value,
            companyToken: companyToken,
            validThru: validThru.toISOString()
        }
        LocalStorageService.setValue(Globals.SessionValues.WCPlusTranslations, sessionValue);
    }
    private getTranslationFromSession(companyToken) {
        const val = LocalStorageService.getValue(Globals.SessionValues.WCPlusTranslations);
        if (val) {
            if (!val.companyToken || (companyToken && val.companyToken.toLowerCase() != companyToken.toLowerCase())) {
                return null;
            }
            if (!val.validThru) {
                return null;
            }
            const whenExpired = new Date(val.validThru);
            if (whenExpired > new Date()) {
                return val.translations;
            }
        } return null;
    }
    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(() => err);
    }
}
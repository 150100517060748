<div class="modal-header">
    <h4 class="primary-text modal-title" id="titleId-CoachingAppointment">{{ 'coachingappointment' | lesCustomLocalize : 'label' }}</h4>
    <button type="button tertiary-bg" class="close" id="actionId-CloseCoachingAppointment" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body p-0 tt">
    <iframe id="timetrade" name="timetrade" width="100%" frameborder="0" allow="geolocation"></iframe>

    <form target="timetrade" id="formId-CoachingAppointment" action="{{ urlToUse() }}" #form method="POST" (ngSubmit)="submitForm($event)">
        <ng-container *ngIf="confirmationNumber !== ''">
            <input type="hidden" name="attendee_person_lastName" value="{{ participant.userKey }}" id="attendee_person_lastName" />
            <input type="hidden" name="appointmentId" value="{{ confirmationNumber }}" id="appointmentId" />
        </ng-container>

        <ng-container *ngIf="confirmationNumber === ''">
            <input type="hidden" name="attendee_person_lastName" value="{{ participant.userKey }}" id="attendee_person_lastName" />
            <input type="hidden" name="attendeeId" value="{{ participant.userKey }}" id="attendeeId" />
            <input type="hidden" name="attendee_userName" value="{{ participant.userKey }}" id="attendee_userName" />
            <input type="hidden" name="attendee_person_firstName" value="{{ participant.userKey }}" id="attendee_person_firstName" />
            <input type="hidden" name="attendee_email" value="{{ email }}" id="attendee_email" />
            <input type="hidden" name="attendee_mobile_phoneNumber" value="{{ mobilePhone }}" id="attendee_mobile_phoneNumber" />
            <input type="hidden" name="attendee_phone_phoneNumber" value="{{ contactPhone }}" id="attendee_phone_phoneNumber" />
            <input type="hidden" name="questionId__company" value="{{ participant.company.companyName }}" id="questionId__company" />
            <input type="hidden" name="questionId__booked_by" value="{{ bookedBy }}" id="questionId__booked_by" />
            <input type="hidden" name="campaignId" value="{{ campaignId }}" id="CampaignID" />
            <input type="hidden" name="locationId" value="{{ locationId }}" id="locationId" />
            <input type="hidden" name="appointmentTypeGroupId" value="{{ participant.company.companyId }}" id="appointmentTypeGroupId" />
            <input type="hidden" name="appointmentTypeId" value="{{ appointmentType }}" id="appointmentTypeId" />
        </ng-container>
    </form>
</div>

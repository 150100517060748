import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { SecurityContext } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { ConfirmationType } from '../../../shared/confirmation/confirmation-types';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { SchedulerService } from '../../scheduler.service';

@Component({
  selector: 'app-resend-confirmation-email',
  templateUrl: './resend-confirmation-email.component.html',
  styleUrls: ['./resend-confirmation-email.component.css']
})
export class ResendConfirmationEmailComponent implements OnInit, OnDestroy {
  @Input() currentUser: Person;
  @Input() appointmentKey: string;
  @Input() appointmentUserKey: string;
  subscriptions: ISubscription[] = [];
  confirmationTypes: any[] = [];
  currentConfirmationType:ConfirmationType;
  confirmationEnums: ConfirmationType[];
  showSelectionError = false;
  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService,
    private _schedulerService: SchedulerService) {
  }

  notCurrentUser(): boolean {
    return this.currentUser.userKey !== this.appointmentUserKey;
  }

  resendConfirmationEmail() {
    if(Object.values(ConfirmationType).includes(this.currentConfirmationType)){
      this.subscriptions.push(this._schedulerService.resendConfirmationEmail(this.appointmentKey,this.currentConfirmationType).subscribe(() => {
        this.activeModal.close('submit');
      }));
    }else{
      this.showSelectionError = true;
    }  
  }
  
  initConfirmationTypes(){
    this.confirmationTypes = [
      {value: ConfirmationType[ConfirmationType.CONFIRM], description:'Confirmation' },
      {value: ConfirmationType[ConfirmationType.CANCEL], description:'Cancellation' },
      {value: ConfirmationType[ConfirmationType.REMINDER], description:'Reminder' }
  ]
  }
  setSelectedOption(selectedOption: string){
    this.currentConfirmationType = null;
    this.showSelectionError = false;
   if(selectedOption && this.isValidConfirmationType(selectedOption)){
     const validType = this.getValidConfirmationType(selectedOption);
     this.currentConfirmationType = validType;
     console.log(this.currentConfirmationType);
   }
  }

  ngOnInit() {
    this.initConfirmationTypes();
  }

  isValidConfirmationType(confirmationType :string){
    switch(confirmationType){
      case 'CONFIRM':
      case 'CANCEL':
      case 'REMINDER':
        return true;
      default:
        return false
    }
  }

  getValidConfirmationType(confirmationType :string) : ConfirmationType{
    switch(confirmationType){
      case 'CONFIRM':
        return ConfirmationType.CONFIRM;
      case 'CANCEL':
        return ConfirmationType.CANCEL;
      case 'REMINDER':
        return ConfirmationType.REMINDER;
      default:
        return null
    }
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}





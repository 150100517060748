<div *ngIf="isRenderReady">
    <div class="modal-header">
        <h4 class="secondary-text modal-title" id="titleId-PhoneNumberRequired">{{ 'coachingphonerequired' | lesCustomLocalize : 'label' }}</h4>
        <button type="button" class="close" id="actionId-ClosePhoneNumberRequired" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <form [formGroup]="phoneForm" id="formId-PhoneNumberRequired" (ngSubmit)="savePhone()" name="form.phoneForm" validate>
        <ng-container>
            <div class="modal-body">
                <div class="col-sm-12">
                    <p>{{ 'coachingphonerequiredmsg' | lesCustomLocalize : 'label' }}</p>

                    <div class="form-group row">
                        <label id="inputId-ProfilePrimaryPhone">{{ 'preferredphone' | lesCustomLocalize : 'label' }}</label>
                        <div class="col-sm-6">
                            <input type="text" name="phoneNew" mask="(000) 000-0000" class="form-control" formControlName="phoneNew" />
                            <div *ngIf="phoneNew.invalid && (phoneNew.dirty || phoneNew.touched)">
                                <span class="text-danger">{{ 'primaryphonerequired' | lesCustomLocalize : 'login' }}</span>
                            </div>
                        </div>
                    </div>
                    <button type="submit" id="actionId-SaveProfilePrimaryPhone" [disabled]="phoneForm && (phoneForm.pristine || !phoneForm.valid)" class="tertiary-bg btn btn-primary pull-right" value="Save">
                        {{ 'save' | lesCustomLocalize : 'label' }}
                    </button>
                </div>
            </div>
        </ng-container>
    </form>
</div>

import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';
import { TextOverride } from '../../shared/localize/text-override';
import { CustomizeService } from '../customize.service';

@Component({
  selector: 'app-modify-text',
  templateUrl: './modify-text.component.html',
  styleUrls: ['./modify-text.component.css']
})
export class ModifyTextComponent implements OnInit, OnDestroy {
  override: TextOverride = new TextOverride();

  originalText: string;

  @Input() set textOverride(val: TextOverride) {
    this.override.category = val.category;
    this.override.companyKey = val.companyKey;
    this.override.default = val.default;
    this.override.description = val.description;
    this.override.key = val.key;
    this.override.languageLocale = val.languageLocale;
    this.override.localeName = val.localeName;
    this.override.maxCustomLength = val.maxCustomLength;
    this.override.name = val.name;
    this.override.text = val.text;
    this.override.token = val.token;

    this.originalText = val.text;
  }

  subscriptions: ISubscription[] = [];
  disableSubmit = false;
  hasError = false;

  constructor(public activeModal: NgbActiveModal, private _sanitizer: DomSanitizer, private _localizeService: LocalizeService,
    private _customizeService: CustomizeService) {
  }

  getMaxLength() {
    if (!this.override.maxCustomLength) {
      return 500;
    }
    else {
      return this.override.maxCustomLength;
    }
  }

  update() {
    if (this.override.text.length === 0 && (!this.originalText || this.originalText.trim().length === 0)) {
      alert(this._localizeService.get('overriderequired', 'message'));
      return;
    }

    this.disableSubmit = true;

    this._customizeService.modifyTextOverride(this.override).subscribe((data: any) => {
      this.override = data;
      this.activeModal.close(data);
    },
      error => {
        alert(this._localizeService.get('unknownerrortryagain', 'message'));
      });
  }


  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { PersonService } from './shared/person/person.service';

@Injectable()
export class PreferredLanguageInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const _personService = this.injector.get(PersonService);
    const currentPerson = _personService.getCurrentPerson();
    if (currentPerson && currentPerson.locale) {
      const langReq = req.clone({
        headers: req.headers.set('X-Preferred-Language', currentPerson.locale.languageLocale)
      });
      return next.handle(langReq);
    } else {
      return next.handle(req);
    }
  }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-SecureMessage">{{ 'newmessage' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="close" id="actionId-CloseSecureMessage" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form #newMessageForm="ngForm" id="formId-NewMessage" (ngSubmit)="sendMessage()">
    <div class="modal-body">
        <div class="form-group row mt-0" *ngIf="toUser">
            <label for="toStatic" class="col-sm-1 col-form-label" id="{{ 'formInputId-' + participant + 'ToUser' }}"
                ><b>{{ 'to' | lesCustomLocalize : 'label' }}:</b></label
            >
            <div class="col-sm-11">
                <input type="text" readonly class="form-control-plaintext" id="toStatic" name="toStatic" value="{{ toUser.firstName }} {{ toUser.lastName }}" />
            </div>
        </div>

        <div class="form-group row mt-0" *ngIf="!toUser && isGeneralInbox">
            <label for="toGI" class="col-sm-1 col-form-label" id="{{ 'formInputId-' + participant + 'GeneralInboxTo' }}"
                ><b>{{ 'to' | lesCustomLocalize : 'label' }}:</b></label
            >
            <div class="col-sm-11">
                <input type="text" readonly class="form-control-plaintext" id="toGI" name="toGI" value="{{ 'generalinbox' | lesCustomLocalize : 'label' }}" />
            </div>
        </div>

        <div *ngIf="!toUser && !isGeneralInbox">
            <div class="form-group row mt-0">
                <label for="toDynamic" class="col-sm-1 col-form-label" id="{{ 'formInputId-' + participant + 'To' }}"
                    ><b>{{ 'to' | lesCustomLocalize : 'label' }}:</b></label
                >
                <div class="col-sm-11">
                    <select class="form-control" name="toUserDynamic" [(ngModel)]="toUserDynamic" [required]="!toUser" #userSelect="ngModel">
                        <option value=""></option>
                        <option *ngFor="let user of toUsers" [ngValue]="user">
                            {{ user.firstName }} {{ user.lastName }}<span *ngIf="suffix">, {{ suffix }}</span>
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mt-0" *ngIf="toUserDynamic && toUserDynamic.profilePhoto">
                <div class="col-sm-2">
                    <img *ngIf="toUserDynamic.image" [src]="toUserDynamic.profilePhoto" class="avatar img-fluid img-thumbnail" />
                </div>
                <div class="col-sm-10" id="{{ 'displayId-' + participant + 'BiographyWithProfilePhoto' }}" [innerHTML]="toUserDynamic.biography"></div>
            </div>

            <div class="form-group row mt-0" id="{{ 'displayId-' + participant + 'Biography' }}" *ngIf="toUserDynamic && !toUserDynamic.profilePhoto">
                <div class="col-sm-12" [innerHTML]="toUserDynamic.biography"></div>
            </div>

            <div *ngIf="hasError" id="{{ 'errorAlertId-' + participant + 'GettingListOfUsers' }}" class="alert alert-danger">
                {{ 'securemessagetousererror' | lesCustomLocalize : 'message' }}
            </div>

            <div *ngIf="userSelect.invalid && (userSelect.dirty || userSelect.touched)" class="alert alert-danger">
                <div *ngIf="userSelect.errors.required">
                    {{ 'touserisrequired' | lesCustomLocalize : 'message' }}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <b>{{ 'message' | lesCustomLocalize : 'label' }}:</b>
            </div>
        </div>

        <div class="form-group row" id="displayId-MessageBody">
            <div class="col-sm-12" id="new-message-body">
                <quill-editor [(ngModel)]="messageBody" name="messageBody" bounds="#new-message-body" [style]="{ height: '300px' }" required #msgBody="ngModel">
                    <div quill-editor-toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" [title]="'Bold'"></button>
                            <button class="ql-italic" [title]="'Italic'"></button>
                            <button class="ql-underline" [title]="'Underline'"></button>
                            <button class="ql-strike" [title]="'Strikethrough'"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-indent" value="-1"></button>
                            <button class="ql-indent" value="+1"></button>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-header" [title]="'Heading'">
                                <option selected></option>
                                <option value="1"></option>
                                <option value="2"></option>
                                <option value="3"></option>
                                <option value="4"></option>
                                <option value="5"></option>
                                <option value="6"></option>
                            </select>
                            <select class="ql-align" [title]="'Aligment2'">
                                <option selected></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-link" [title]="'Link'"></button>
                        </span>
                    </div>
                </quill-editor>

                <div *ngIf="msgBody.invalid && (msgBody.dirty || msgBody.touched)" class="alert alert-danger">
                    <div *ngIf="msgBody.errors.required" id="{{ 'validationErrorId-' + participant + 'MessageBodyRequired' }}">
                        {{ 'messagebodyrequired' | lesCustomLocalize : 'message' }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn btn-primary" id="actionId-CancelSecureMessage" (click)="activeModal.close('Close click')">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
        <button type="submit" class="tertiary-bg btn btn-primary" id="actionId-SendSecureMessage" [disabled]="!newMessageForm.form.valid || disableSubmit">
            <i class="fa fa-paper-plane-o" aria-hidden="true"></i> {{ 'send' | lesCustomLocalize : 'label' }}
        </button>
    </div>
</form>

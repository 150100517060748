<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-ServiceQualificationRuleFailure">{{ header }}</h4>
    <button type="button" class="close" aria-label="Close" id="actionId-CloseServiceQualificationRuleFailure" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="failure-message" *ngFor="let message of messageList" [innerHTML]="replaceNewlines(message) | safeHtml"></div>
    <div class="failure-override" *ngIf="showOverrideCode">
        <label for="overrideCode">{{ 'enteroverridecode' | lesCustomLocalize : 'label' }}</label>
        <input type="text" maxlength="8" name="overrideCode" id="overrideCode" [(ngModel)]="overrideCodeValue" />
        <button type="button" class="tertiary-bg btn btn-secondary" id="actionId-ApplyServiceQualificationRuleFailure" (click)="validateOverrideCode(overrideCodeValue)">{{ 'apply' | lesCustomLocalize : 'label' }}</button>
        <div *ngIf="hasError" class="alert alert-danger">
            {{ 'invalidoverridecode' | lesCustomLocalize : 'message' }}
        </div>
    </div>
</div>

<div class="modal-footer pt-10">
    <div class="float-right pb-2">
        <button type="button" class="tertiary-bg btn btn-primary" id="actionId-CloseServiceQualificationRuleFailure" (click)="activeModal.close('Close click')">{{ 'close' | lesCustomLocalize : 'label' }}</button>
    </div>
</div>

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-AattestationComplete">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" id="actionId-CloseAattestationComplete" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    {{ 'responsesubmitted' | lesCustomLocalize : 'message' }}
</div>

<div class="modal-footer pt-10">
    <button type="button" class="tertiary-bg btn btn-secondary" id="actionId-CloseAattestationComplete" (click)="close()">{{ 'close' | lesCustomLocalize : 'label' }}</button>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';

import { SharedModule } from '../shared/shared.module';
import { PersonWidgetService } from './person.widget.service';
import { PersonAttestationWidgetComponent } from './person-attestation-widget/person-attestation-widget.component';
import { PersonBiometricWidgetComponent } from './person-biometric-widget/person-biometric-widget.component';
import { PersonConsentWidgetComponent } from './person-consent-widget/person-consent-widget.component';
import { PersonDataCollectionWidgetComponent } from './person-data-collection-widget/person-data-collection-widget.component';
import { PersonDemographicWidgetComponent } from './person-demographic-widget/person-demographic-capture.component';
import { PersonInsuranceWidgetComponent } from './person-insurance-widget/person-insurance-widget.component';
import { PersonWidgetEngineComponent } from './person-widget-engine/person-widget-engine.component';
import { PersonWidgetModalComponent } from './person-widget-modal/person-widget-modal.component';

const routes = [
  { path: 'person/collect', component: PersonWidgetEngineComponent }
]
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    NgbModule,
    MomentModule,
    NgbTooltipModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],

  declarations: [
    PersonWidgetEngineComponent,
    PersonWidgetModalComponent,
    PersonDataCollectionWidgetComponent,
    PersonAttestationWidgetComponent,
    PersonBiometricWidgetComponent,
    PersonConsentWidgetComponent,
    PersonDemographicWidgetComponent,
    PersonInsuranceWidgetComponent
  ],
  exports: [
    PersonAttestationWidgetComponent,
    PersonBiometricWidgetComponent,
    PersonConsentWidgetComponent,
    PersonDemographicWidgetComponent,
    PersonInsuranceWidgetComponent
  ],
  providers: [
    PersonWidgetService,
    provideNgxMask()
  ]
})
export class PersonWidgetModule { }

<div *ngIf="displayDetail()" class="row result-detail-line-item">
    <div class="col-md-2">
        <button type="button" *ngIf="showBlurb(map)" class="btn btn-link" id="actionId-ShowBlurbResultDetail" (click)="clickBlurb(map)">
            {{ getTitle(map) | lesCustomLocalize : 'label' }}
        </button>
        <span *ngIf="!showBlurb(map)">{{ getTitle(map) | lesCustomLocalize : 'label' }}</span>
    </div>

    <div class="col-md-2">
        <ng-container *ngIf="getSecondaryMap()">
            <span>{{ getValue(getSecondaryMap()) }}</span>
            <span *ngIf="getUnits(getSecondaryMap()) && !exc">{{ getUnits(getSecondaryMap()) }}{{ getSecondaryMap().displayInfo.fieldDelimiter }}</span>
        </ng-container>
        <span>{{ getValue(map) }}</span>
        <span *ngIf="getUnits(map) && !exc">{{ getUnits(map) }}</span>
    </div>

    <div class="col-md-4">
        <span *ngIf="getRisk(map) && getRisk(map).riskDisplay && !exc" [style.color]="getRisk(map).riskColor">{{ getRisk(map).riskDetailDisplay }}</span>
        <button type="button" class="fa-button" id="actionId-ShowAllRangesResultDetail" *ngIf="showIcon(map)" (click)="displayAllRanges(map)">
            <i class="fa fa-info-circle info screen-only"></i>
        </button>
        <button type="button" class="fa-button" id="actionId-ShowSecondaryRangesResultDetail" (click)="displayAllRanges(getSecondaryMap())" *ngIf="!showIcon(map) && getSecondaryMap() && showIcon(getSecondaryMap())">
            <i class="fa fa-info-circle info screen-only"></i>
        </button>
    </div>

    <div class="col-md-4">
        <div class="d-md-none result-detail-line-header-items" id="displayId-ResultDetailNotes" *ngIf="showNote(map)">
            {{ 'notes' | lesCustomLocalize : 'label' }}
        </div>
        <div *ngIf="showNote(map)" [innerHTML]="getNote(map)"></div>
    </div>
</div>

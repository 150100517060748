export class GapsInCareModel {
    companyKey: string;
    lcaAccountNumber: string;
    programServiceKey: string;
    participantKey: string;
    participantServiceEligibilityKey: string;
    transactionId: string;
    userKey: string;
    address1: string;
    address2: string;
    city: string;
    stateAbbreviation: string;
    zip: string;
    email: string;
    phone: string;
    receiveSmsReminders: boolean;
    receivePaperCommunications: boolean;
    gicModality: string;
}

export class GapsInCareViewModel {
    companyKey: string;
    lcaAccountNumber: string;
    participantServiceEligibilityKey: string;
    transactionId: string;
    programServiceKey: string;
    titleLabel: string;
    introText: string;
    outroText: string;
    allowOptinPaperCommunication: boolean;
    gicModality: string;
}

export class AddressValidateViewModel {
    address: AddressModel;
}

export class AddressModel {
    address1: string;
    address2: string;
    city: string;
    stateAbbreviation: string;
    zip: string;
}
export class AddressValidateResult {
    isValid: boolean;
    errorMessage: string;
    standardizedAddress: AddressModel
}

import { Component, Input, OnInit } from '@angular/core';

import { LocalizeService } from '../localize/localize.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
    readyForRender = false;

    constructor(private _localizeService: LocalizeService) {}

    @Input() htmlId = 'Unknown';

    ngOnInit() {
        this._localizeService.isReady.subscribe((isReady) => {
            if (isReady) {
                this.readyForRender = true;
            }
        });
    }
}

import { DisplayInfo } from "./displayInfo";
import { ReferenceRange, ResultLookup } from './result-lookup';

export class ResultDataPointMapping {
  fieldName: string;
  resultProperty: string;
  resultPropertyProperty: string;
  referenceRanges: ReferenceRange[] = [];
  displayInfo: DisplayInfo;
  serviceDate: Date;
  fastingState: string;
}


export class ResultDataPointMappingCollection {
  mappings: ResultDataPointMapping[] = [];

  constructor(data) {
    this.mappings = data;
  }

  byResultInfo(resultProperty: string, resultPropertyProperty: string): ResultDataPointMapping {
    const mapping = this.mappings.filter((map) => {
      return map.resultProperty.toLowerCase() === resultProperty.toLowerCase() &&
        map.resultPropertyProperty.toLowerCase() === resultPropertyProperty.toLowerCase();
    });

    return mapping.length ? mapping[0] : null;
  }

  byFieldName(fieldName: string): ResultDataPointMapping {
    const mapping = this.mappings.filter((map) => {
      return map.fieldName.toLowerCase() === fieldName.toLowerCase();
    });

    return mapping.length ? mapping[0] : null;
  }

  addReferenceRange(refRange: ReferenceRange): boolean {
    let added = false;

    for (let i = 0; i < this.mappings.length; i++) {
      if (this.mappings[i].fieldName === refRange.fieldName) {
        this.mappings[i].referenceRanges.push(refRange);
        added = true;
        break;
      }
    }

    return added;
  }
}

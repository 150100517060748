import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { LocalizeService } from "../localize/localize.service";

@Component({
  selector: "attestation-complete-modal",
  templateUrl: "./attestation-complete-modal.component.html",
  styleUrls: ["./attestation-complete-modal.component.css"],
})

export class AttestationModalCompleteComponent {
  @Input() title: string;
  constructor(private _activeModal: NgbActiveModal, private _localizationService: LocalizeService) {
    
  }

  close() {
    this._activeModal.close();
  }  
}

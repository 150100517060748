import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../localize/localize.service';
import { ConsentDefinitionAcknowledgementModel, ConsentDefinitionAcknowledgementsPostModel, ConsentsNeeded, RequiredConsent } from './consent';
import { ConsentService } from './consent.service';

@Component({
    selector: 'app-consent-modal',
    templateUrl: './consent-modal.component.html',
    styleUrls: ['./consent-modal.component.css']
})
export class ConsentModalComponent implements OnInit {
    @Input() personKey: string;
    @Input() userKey: string;
    @Input() participantKey: string;
    @Input() consentsNeeded: ConsentsNeeded[];
    @Input() isOffsiteConsents = false;
    public readyForRender = false;
    public hasError = false;
    public consent: ConsentsNeeded;
    public consentDefinitionAcknowledgementModel: ConsentDefinitionAcknowledgementModel = new ConsentDefinitionAcknowledgementModel(null, null, null, null, null);
    public requiredConsents: RequiredConsent[] = [];
    public currentPageIndex = 0;
    public consentDefinitionAcknowledgementModelsNeeded: ConsentDefinitionAcknowledgementModel[] = [];
    public consentDefinitionAcknowledgementsPostModel: ConsentDefinitionAcknowledgementsPostModel;
    public showNextButton = false;
    public showBackButton = false;
    public showSaveButton = false;
    public showAttemptSaveError = false;
    public isAgreed = false;
    public isDeclined = false;
    constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, private _consentService: ConsentService) { }

    ngOnInit() {
        if (this.consentsNeeded) {
            if (this.consentsNeeded.length) {
                const keys = this.consentsNeeded.map(function (a) {
                    return a.consentDefinitionKey;
                });
                const firstKey = keys[this.currentPageIndex];
                this.setConsent(firstKey, true);
            } else {
                //there is nothing to process here. just close this modal
            }
        }
    }
    addConsent(con: ConsentsNeeded) {
        const consent = this.requiredConsents.find((req: RequiredConsent) => {
            return req.consentDefinitionKey == con.consentDefinitionKey;
        });
        if (!consent) {
            const req = new RequiredConsent(con.consentDefinitionKey, con.isHIPAA, con.isAdditional);
            this.requiredConsents.push(req);
        }
    }

    setCapture(con: ConsentsNeeded) {
        //find existing consent capture
        const cap = this.consentDefinitionAcknowledgementModelsNeeded.find((cap: ConsentDefinitionAcknowledgementModel) => {
            return cap.consentDefinitionKey === con.consentDefinitionKey;
        });
        if (cap) {
            this.consentDefinitionAcknowledgementModel = cap;
        } else {
            this.consentDefinitionAcknowledgementModel = new ConsentDefinitionAcknowledgementModel(con.consentDefinitionKey, con.programServiceKey, null, null, null);
            this.consentDefinitionAcknowledgementModelsNeeded.push(this.consentDefinitionAcknowledgementModel);
        }
    }

    setConsent(consentKey: string, setReady = false) {
        const currentConsent = this.consentsNeeded.find((cap: ConsentsNeeded) => {
            return cap.consentDefinitionKey === consentKey;
        });
        this.consent = currentConsent;
        this.consent.authorizationText = this.replaceNewlines(this.consent.authorizationText);
        //create consent capture
        this.addConsent(this.consent);
        this.setCapture(this.consent);
        this.isAgreed = false;
        this.isDeclined = false;
        if (this.consentDefinitionAcknowledgementModel.acceptedOn != null) {
            this.isAgreed = true;
        }
        if (this.consentDefinitionAcknowledgementModel.declinedOn != null) {
            this.isDeclined = true;
        }
        this.setPagination();
        this.shouldShowSaveButton();
        if (setReady) this.readyForRender = true;
    }

    getCurrentPage(): number {
        return this.currentPageIndex + 1;
    }

    setPagination() {
        if (this.consent) {
            this.showNextButton = this.currentPageIndex + 1 < this.consentsNeeded.length;
            this.showBackButton = this.currentPageIndex > 0;
        }
    }
    shouldShowSaveButton() {
        this.showSaveButton = this.consentsNeeded.length == this.getCurrentPage();
    }
    next() {
        //update current capture in array
        this.consentDefinitionAcknowledgementModelsNeeded[this.currentPageIndex] = this.consentDefinitionAcknowledgementModel;
        //get next in line
        if (this.currentPageIndex < this.consentsNeeded.length) {
            this.currentPageIndex++;
            const key = this.consentsNeeded[this.currentPageIndex].consentDefinitionKey;
            this.setConsent(key);
        }
    }
    private isHIPAAMet(con: RequiredConsent, cap: ConsentDefinitionAcknowledgementModel) {
        if (con.hipaaAuth == true) {
            return cap.acceptedOn || cap.declinedOn;
        }
        return true;
    }
    prev() {
        this.consentDefinitionAcknowledgementModelsNeeded[this.currentPageIndex] = this.consentDefinitionAcknowledgementModel;
        if (this.currentPageIndex > 0) {
            this.currentPageIndex--;
            const key = this.consentsNeeded[this.currentPageIndex].consentDefinitionKey;
            this.setConsent(key);
        }
    }

    save() {
        this.showAttemptSaveError = false;

        const requiredConsents = this.requiredConsents.filter((con: RequiredConsent) => {
            return !con.hipaaAuth || con.hipaaAuth == null;
        });

        if (requiredConsents) {
            requiredConsents.forEach((con: RequiredConsent) => {
                const consentCap = this.consentDefinitionAcknowledgementModelsNeeded.find((cap: ConsentDefinitionAcknowledgementModel) => {
                    return cap.consentDefinitionKey === con.consentDefinitionKey;
                });
                if (consentCap && consentCap.acceptedOn === null && consentCap.declinedOn === null) {
                    if (!this.showAttemptSaveError) {
                        this.showAttemptSaveError = true;
                    }
                }
            });
        }

        if (this.showAttemptSaveError) {
            return;
        }
        const hipaaConsents = this.requiredConsents.filter((con: RequiredConsent) => {
            return con.hipaaAuth == true;
        });
        if (hipaaConsents && hipaaConsents.length) {
            hipaaConsents.forEach((con: RequiredConsent) => {

                const consentCap = this.consentDefinitionAcknowledgementModelsNeeded.find((cap: ConsentDefinitionAcknowledgementModel) => {
                    return cap.consentDefinitionKey === con.consentDefinitionKey;
                });
                if (consentCap && !this.isHIPAAMet(con, consentCap)) {
                    if (!this.showAttemptSaveError) {
                        this.showAttemptSaveError = true;
                    }
                }
            });
        }
        //post all capture
        if (this.showAttemptSaveError) {
            return;
        }

        let saveSavedError = false;
        this.consentDefinitionAcknowledgementsPostModel = new ConsentDefinitionAcknowledgementsPostModel(this.consentDefinitionAcknowledgementModelsNeeded, new Date(), this.personKey, this.participantKey, this.userKey, null, this.userKey);
        this._consentService.setConsentCaptures(this.consentDefinitionAcknowledgementsPostModel).subscribe(
            {
                next: (data) => {
                    if (!data) {
                        saveSavedError = true;
                    } else {
                        saveSavedError = false;
                    }
                    if (saveSavedError) {
                        this.showAttemptSaveError = true;
                        return;
                    } else {
                        this.activeModal.close({ hasConsent: true });
                    }
                },
                error: () => { this.hasError = true; }
            });
    }

    toggleAgree(event: any) {
        if (event.target.checked) {
            this.consentDefinitionAcknowledgementModel.acceptedOn = new Date();
            this.consentDefinitionAcknowledgementModel.declinedOn = null;
            this.isDeclined = false;
        } else {
            this.consentDefinitionAcknowledgementModel.acceptedOn = null;
            this.isAgreed = false;
        }
    }

    toggleDecline(event: any) {
        if (event.target.checked) {
            this.consentDefinitionAcknowledgementModel.declinedOn = new Date();
            this.consentDefinitionAcknowledgementModel.acceptedOn = null;
            this.isAgreed = false;
        } else {
            this.consentDefinitionAcknowledgementModel.declinedOn = null;
            this.isDeclined = false;
        }
    }

    closeModal() {
        this.activeModal.dismiss('Close Clicked');
    }

    private replaceNewlines(txt) {
        if (txt != null) {
            return txt.replace(/<p\b[^>]*><br[\/]?><\/p>/g, '');
        }
        return null;
    }
}

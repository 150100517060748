export class Company {
  key: string;
  companyName: string;
  companyId: number;
  hasResultsAccess: boolean;
  companyURLToken: string;
  visitorCode: string;
  loginType: string;
  supportsLocalParticipantRegistration: boolean;
  defaultZipCodeRadius: number;

  loginTypeVisitorDescription() {

    let tempName = this.companyName;
    if (this.loginType && this.visitorCode) {
      tempName = `${tempName} (${this.getLoginTypeMapping(this.loginType)} - ${this.visitorCode})`;
    } else if (this.loginType && !this.visitorCode) {
      tempName = `${tempName} (${this.getLoginTypeMapping(this.loginType)})`;
    } else if (!this.loginType && this.visitorCode) {
      tempName = `${tempName} (${this.visitorCode})`;
    }
    return tempName;
  }
  getLoginTypeMapping(loginType: string) {
    const loginTypeMapping = {
      "SSO_Par": "SSO Verified Users Only",
      "SSO-Local_Vis": "SSO Verified + Local Visitors",
      "SSO-Local_Par": "SSO Verified + Local Verified",
      "SSO-Local_Par-Vis": "SSO Verified + Local Verified + Visitors",
      "Local_Par": "Local Verified Users Only",
      "Local_Par-Vis": "Local Verified + Visitors",
      "Local_Vis": "Local Visitors Only"
    };
    return loginTypeMapping[loginType] || ""
  }
}


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PersonWidgetCollection, PersonWidgetCollectionNeedResult, PersonWidgetCollectionProgress, PersonWidgetCollectionProgressCriteria } from './person.widget.models';

@Injectable()
export class PersonWidgetService {
    constructor(private _http: HttpClient) { }

    getPersonWidgetCollectionWidget(search: PersonWidgetCollectionProgressCriteria): Observable<PersonWidgetCollection> {
        return this._http.post<PersonWidgetCollection>(environment.baseCoreApiEndpoint + `/person/widget/collection/progress/for-person-user`, search).pipe(catchError(this.handleError));
    }
    createProgress(userKey: string, progress: PersonWidgetCollectionProgress): Observable<PersonWidgetCollectionProgress> {
        progress['createdBy'] = userKey;
        return this._http.post<PersonWidgetCollectionProgress>(environment.baseCoreApiEndpoint + `/person/collection/progress`, progress).pipe(catchError(this.handleError));
    }
    updateProgress(userKey: string, progress: PersonWidgetCollectionProgress): Observable<PersonWidgetCollectionProgress> {
        progress['modifiedBy'] = userKey;
        return this._http.put<PersonWidgetCollectionProgress>(environment.baseCoreApiEndpoint + `/person/collection/progress`, progress).pipe(catchError(this.handleError));
    }
    requiresWidgetCollection(userKey: string, programServiceKey: string, companyKey: string = null): Observable<PersonWidgetCollectionNeedResult> {
        const query = new PersonWidgetCollectionProgressCriteria();
        query.userKey = userKey;
        query.programServiceKey = programServiceKey;
        query.companyKey = companyKey;
        return this.getPersonWidgetCollectionWidget(query).pipe(
            map((result) => {
                if (result && result.collectionWidgets) {
                    const isNeeded = result.collectionWidgets.length > 0;
                    return new PersonWidgetCollectionNeedResult(true, isNeeded);
                }
                return new PersonWidgetCollectionNeedResult(false, false);
            }),
            catchError(this.handleError),);
    }
    private handleError(err: HttpErrorResponse) {
        if (err.status == 400 && (err.error instanceof String || typeof err.error == 'string')) {
            return observableThrowError(() => err);
        }
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

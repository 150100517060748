<ng-container *ngIf="readyForRender">
    <h3 class="primary-text" id="titleId-Customize">{{ 'customize' | lesCustomLocalize : 'label' }}</h3>
    <select class="form-control" name="company" [(ngModel)]="company">
        <option *ngFor="let co of companies" [ngValue]="co">{{ co.companyName }}</option>
    </select>

    <div class="pt-2">
        <ngb-tabset>
            <ngb-tab class="secondary-text" id="customized-misc" title="{{ 'misc' | lesCustomLocalize : 'label' }}">
                <ng-template ngbTabContent>
                    <div class="pt-2">
                        <app-customize-misc [currentUser]="currentUser" [company]="company"></app-customize-misc>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="customized-text" class="primary-text" title="{{ 'customizabletext' | lesCustomLocalize : 'label' }}">
                <ng-template ngbTabContent>
                    <div class="pt-2">
                        <app-customizable-text [currentUser]="currentUser" [company]="company"></app-customizable-text>
                    </div>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</ng-container>

import { Component, EventEmitter, Input, OnDestroy,OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../../person-widgets/person.widget.models';
import { OrderableTestsTest } from '../../shared/billable/orderable-tests-test';
import { TestConstants } from '../../shared/billable/TestConstants';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Person } from '../../shared/person/person';
import { PersonBiometric, PersonBiometricPut, PersonBiometricSubmits } from '../../shared/user/person-biometric';
import { UserService } from '../../shared/user/user.service';
import { HeightFeetSelection, HeightInchesSelection } from '../../shared/user/user-biometric-selections';
import { UserBiometricCaptureResult } from '../../user-biometric-capture-modal/user-biometric-capture';

@Component({
    selector: 'app-person-biometric-widget',
    templateUrl: './person-biometric-widget.component.html',
    styleUrls: ['./person-biometric-widget.component.css']
})
export class PersonBiometricWidgetComponent implements OnInit, OnDestroy {
    @Input() personWidget: PersonWidgetCollectionWidget;
    @Input() currentPerson: Person;
    @Input() otherPerson?: Person;
    @Input() programServiceKey: string;
    @Input() programServiceTypeKey: string;
    @Input() personKey: string;
    @Output() onWidgetCompleted = new EventEmitter<any>();
    @Output() onUpdateAttempted = new EventEmitter<any>();
    biometricForm: FormGroup;
    userBiometric: PersonBiometric = new PersonBiometric();
    selfBioMetricTests: OrderableTestsTest[] = [];
    userBiometricCaptureResult: UserBiometricCaptureResult = new UserBiometricCaptureResult();
    subscriptions: ISubscription[] = [];
    heightFeetChoices: HeightFeetSelection[] = [];
    heightInchesChoices: HeightInchesSelection[] = [];
    isRenderReady = false;
    justDoNotKnowSelections = 0;
    noDigitsRegex = /^-?\d+$/;
    oneDigitRegex = /^\d\d{0,9}(\.\d{1,1})?%?$/;
    errorList: any;
    errorMsg: string = null;
    public hasError = false;
    showHeightFeet = false;
    showHeightInches = false;
    showWeight = false;
    showWaist = false;
    showNeck = false;
    showHip = false;
    showBloodPressureDBP = false;
    showBloodPressureSBP = false;
    showHeartRate = false;
    showBodyFat = false;
    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentPerson;
    }

    constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private _metaDataService: MetadataService, private _userService: UserService, private _localizationService: LocalizeService) {
        this.loadDateDropDowns();
    }
    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }
    ngOnInit(): void {
        this._userService.getUserBiometric(this.person().userKey, this.programServiceKey).subscribe((info) => {
            if (info != null) {
                this.userBiometric = info;
            }
            if (this.programServiceKey && this.isValidGuid(this.programServiceKey)) {
                this.userBiometric.userKey = this.person().userKey;
                this.userBiometric.programServiceKey = this.programServiceKey;
            }
            const typkey = this.activeServiceTypeKey;
            if (!typkey) {
                this.onError();
            }
            this._userService.getSelfBioMetricTests(this.person().userKey, this.userBiometric.programServiceKey).subscribe((tests) => {
                if (tests != null) {
                    this.selfBioMetricTests = tests;
                    this.showHeightFeet = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.HeightFeet;
                    });
                    this.showHeightInches = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.HeightInches;
                    });
                    this.showWeight = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.Weight;
                    });
                    this.showWaist = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.WaistInches;
                    });

                    this.showNeck = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.Neck;
                    });
                    this.showHip = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.Hip;
                    });
                    this.showBloodPressureDBP = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.BloodPressureDBP;
                    });
                    this.showBloodPressureSBP = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.BloodPressureSBP;
                    });
                    this.showHeartRate = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.HeartRate;
                    });
                    this.showBodyFat = _.find(this.selfBioMetricTests, function (c: OrderableTestsTest) {
                        return c.key.toUpperCase() === TestConstants.BodyFat;
                    });
                }
                this.createForm();
            });
        });
    }
    get activeServiceTypeKey() {
        const services = this.person().UserServices;
        if (services && services.length > 0) {
            const currentService = services.find((r) => r.programService.programServiceKey.toLowerCase() == this.programServiceKey.toLowerCase());
            if (currentService) {
                return currentService.programService.serviceType;
            }
        }
        return null;
    }
    // Height in feet and inches are dop downs and are populated here.
    private loadDateDropDowns(): void {
        for (let iFeet = 2; iFeet <= 7; iFeet++) {
            const heightFeetChoice = new HeightFeetSelection();
            if (iFeet === 2) {
                heightFeetChoice.value = '';
                heightFeetChoice.desc = 'Select';
            } else {
                heightFeetChoice.value = iFeet.toString();
                heightFeetChoice.desc = iFeet.toString();
            }
            this.heightFeetChoices.push(heightFeetChoice);
        }

        for (let iFeet = -0.5; iFeet <= 11.5; iFeet += 0.5) {
            const heightInchesChoice = new HeightInchesSelection();
            if (iFeet === -0.5) {
                heightInchesChoice.value = '';
                heightInchesChoice.desc = 'Select';
            } else {
                heightInchesChoice.value = iFeet.toString();
                heightInchesChoice.desc = iFeet.toString();
            }
            this.heightInchesChoices.push(heightInchesChoice);
        }
        this.isReadyForRender();
    }

    // Form defined for all biometrics. Bits established above based on lab
    // references codes control which biometrics are visible. The form supports
    // and "I don't know" option for each biometric. When selected, form input
    // validation needs to be removed. As a result, adding and removing validation,
    // which can normally be done as part of form definition, are handled in a
    // separate function that can be leveraged when initially defining the form
    // and when the "I don't know" is used.
    private createForm(): void {
        this.biometricForm = this.formBuilder.group({
            heightFeet: [this.userBiometric.heightFeet],
            heightInches: [this.userBiometric.heightInches],
            weight: [this.userBiometric.weight],
            waistInches: [this.userBiometric.waistInches],
            neck: [this.userBiometric.neck],
            hip: [this.userBiometric.hip],
            bpSystolic: [this.userBiometric.bpSystolic],
            bpDiastolic: [this.userBiometric.bpDiastolic],
            heartRate: [this.userBiometric.heartRate],
            bodyFat: [this.userBiometric.bodyFat]
        });
        if (this.showHeightFeet) {
            this.establishValidation('heightFeet');
        }
        if (this.showHeightInches) {
            this.establishValidation('heightInches');
        }
        if (this.showWeight) {
            this.establishValidation('weight');
        }
        if (this.showWaist) {
            this.establishValidation('waistInches');
        }
        if (this.showNeck) {
            this.establishValidation('neck');
        }
        if (this.showHip) {
            this.establishValidation('hip');
        }
        if (this.showBloodPressureDBP) {
            this.establishValidation('bpDiastolic');
        }
        if (this.showBloodPressureSBP) {
            this.establishValidation('bpSystolic');
        }
        if (this.showHeartRate) {
            this.establishValidation('heartRate');
        }
        if (this.showBodyFat) {
            this.establishValidation('bodyFat');
        }
        this.isReadyForRender();
    }

    // Set validators and enable and required form input elements.
    private establishValidation(input: string): void {
        switch (input) {
            case 'heightFeet':
            case 'heightInches': {
                const control = this.biometricForm.get(input);
                control.setValidators(Validators.required);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'weight': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(50), Validators.max(500), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'waistInches': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(20), Validators.max(69), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'neck': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(8), Validators.max(30), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'hip': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(20), Validators.max(60), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'bpSystolic': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(70), Validators.max(240), Validators.pattern(this.noDigitsRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'bpDiastolic': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(20), Validators.max(150), Validators.pattern(this.noDigitsRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'heartRate': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(30), Validators.max(150), Validators.pattern(this.noDigitsRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
            case 'bodyFat': {
                const control = this.biometricForm.get(input);
                control.setValidators([Validators.required, Validators.min(4), Validators.max(50), Validators.pattern(this.oneDigitRegex)]);
                control.updateValueAndValidity();
                control.setErrors({ required: true });
                this.establishFormValidation(input);
                break;
            }
        }
    }

    // Invoked when the "I don't know" option has been unchecked.
    private establishFormValidation(input: string): void {
        const formInput = document.getElementById(input);
        if (formInput != null) {
            formInput.removeAttribute('disabled');
            formInput.setAttribute('required', 'required');
        }
    }

    // Invoked when the "I don't know" option has been checked.
    private removeFormValidation(input: string): void {
        const formInput = document.getElementById(input);
        if (formInput != null) {
            formInput.setAttribute('disabled', 'disabled');
            formInput.removeAttribute('required');
        }
    }

    // Clear validation is used when the "I don't know" option has been checked.
    private clearValidation(input: string): void {
        const control = this.biometricForm.get(input);
        control.clearValidators();
        control.setValue(null);
        control.setErrors(null);
        control.markAsPristine();
        control.markAsUntouched();
        control.updateValueAndValidity();
        this.removeFormValidation(input);
    }

    private isReadyForRender(): void {
        if (this.userBiometric && this.heightFeetChoices.length > 0 && this.heightInchesChoices.length > 0) {
            this.isRenderReady = true;
        }
    }

    // The "I don't know" option has been either checked or unchecked.
    theyJustDoNotKnow($event): void {
        const formIds = $event.currentTarget.value;
        const isChecked = $event.currentTarget.checked;
        const inputIds = formIds.split(',');

        for (const input of inputIds) {
            if (isChecked) {
                this.justDoNotKnowSelections++;
                this.clearValidation(input);
            } else {
                this.justDoNotKnowSelections--;
                this.establishValidation(input);
            }
        }
    }

    cancel(): void {
        this.userBiometricCaptureResult.hasBiometricCapture = false;
        this.activeModal.close(this.userBiometricCaptureResult);
    }

    // Validate bpSystolic exceeds bpDiastolic.
    private isBloodPressureValid(): boolean {
        const formModel = this.biometricForm.value;
        if (formModel.bpSystolic == null || formModel.bpDiastolic == null) {
            return true;
        }
        if (formModel.bpSystolic < formModel.bpDiastolic) {
            return false;
        }
        return true;
    }

    save(): void { }

    get putBiometric() {
        return new PersonBiometricPut(this.userBiometric);
    }

    get postBiometric() {
        return new PersonBiometricSubmits(this.userBiometric);
    }

    localizeMessage(msgTag: string): string {
        let errorTag = 'unknownerror';
        if (msgTag) {
            try {
                errorTag = msgTag.split('.')[1];
            } catch (error) {
                this.showErorr(error);
            }
        }
        return errorTag;
    }

    localizeValidationErrors(err): string {
        let errorName = this.localizeMessage(err.value);
        if (errorName !== 'unknownerror') {
            errorName = err.key + errorName;
        }
        return errorName;
    }

    showErorr(response: any): void {
        if (response.errorMessage && response.errors) {
            this.hasError = true;
            this.errorList = response.errors;
            this.errorMsg = response.errorMessage;
        }
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
    }

    addUserKey(): void {
        if (!this.isValidGuid(this.userBiometric.userKey) && this.isValidGuid(this.person().userKey)) {
            this.userBiometric.userKey = this.person().userKey;
        }
    }

    isValidGuid(guid: string): boolean {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
    onError() {
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
        this.hasError = true;
    }
    getTranslation(tag: string) {
        return this._localizationService.getCombinedTag(tag, null, null, null, this.tagOptions);
    }
    getNextMessage() {
        if (this.personWidget.position == WidgetCollectionPosition.last) {
            return this.getTranslation('button.submit');
        }
        return this.getTranslation('button.next');
    }
    onNextButtonClick(isValid) {
        if (!isValid) {
            this.hasError = true;
            return;
        }
        this.processAction(WidgetCloseAction.forward, true);
    }
    closeError() {
        this.hasError = false;
    }
    onPreviousButtonClick() {
        //if button is present
        if (this.personWidget.position == WidgetCollectionPosition.first) return;
        this.processAction(WidgetCloseAction.backward, false);
    }
    private processAction(next: WidgetCloseAction, validate = true) {
        if (!validate) {
            return this.closePanel(false, next);
        }
        if (!this.biometricForm.valid) {
            return;
        }

        if (!this.isBloodPressureValid) {
            return;
        }

        this.addUserKey();

        const formModel = this.biometricForm.value;

        if (this.userBiometric.key && this.isValidGuid(this.userBiometric.key)) {
            const putModel = this.putBiometric;
            putModel.key = this.userBiometric.key;
            putModel.userKey = this.userBiometric.userKey;
            putModel.programServiceKey = this.userBiometric.programServiceKey;
            putModel.userProgramTransactionKey = this.userBiometric.userProgramTransactionKey;
            putModel.heightFeet = formModel.heightFeet;
            putModel.heightInches = formModel.heightInches;
            putModel.weight = formModel.weight;
            putModel.waistInches = formModel.waistInches;
            putModel.neck = formModel.neck;
            putModel.hip = formModel.hip;
            putModel.bpSystolic = formModel.bpSystolic;
            putModel.bpDiastolic = formModel.bpDiastolic;
            putModel.heartRate = formModel.heartRate;
            putModel.bodyFat = formModel.bodyFat;
            this._userService.updateUserBiometric(putModel).subscribe(
                (result) => {
                    this.closePanel(true, next);
                },
                (error) => {
                    this.showErorr(error);
                }
            );
        } else {
            const postModel = this.postBiometric;
            postModel.userKey = this.userBiometric.userKey;
            postModel.programServiceKey = this.userBiometric.programServiceKey;
            postModel.userProgramTransactionKey = this.userBiometric.userProgramTransactionKey;
            postModel.heightFeet = formModel.heightFeet;
            postModel.heightInches = formModel.heightInches;
            postModel.weight = formModel.weight;
            postModel.waistInches = formModel.waistInches;
            postModel.neck = formModel.neck;
            postModel.hip = formModel.hip;
            postModel.bpSystolic = formModel.bpSystolic;
            postModel.bpDiastolic = formModel.bpDiastolic;
            postModel.heartRate = formModel.heartRate;
            postModel.bodyFat = formModel.bodyFat;
            this._userService.createUserBiometric(postModel).subscribe(
                (response) => {
                    this.closePanel(true, next);
                },
                (error) => {
                    this.showErorr(error);
                }
            );
        }
    }
    private closePanel(complete: boolean, action: WidgetCloseAction) {
        //send back result
        const result = new WidgetCompleted();
        result.dataCollectionKey = this.personWidget.dataCollectionKey;
        result.widgetKey = this.personWidget.widgetKey;
        result.isComplete = complete;
        result.action = action;
        result.widgetPanelIndex = this.personWidget.widgetPanelIndex;
        this.onWidgetCompleted.emit(result);
    }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../localize/localize.service';



@Component({
    selector: 'app-gaps-in-care-selection-modal',
    templateUrl: './gaps-in-care-selection-modal.component.html',
    styleUrls: ['./gaps-in-care-selection-modal.component.css']
})
export class GapsInCareSelectionModalComponent implements OnInit {

    gicSelectionForm: FormGroup;
    constructor(
        private _activeModal: NgbActiveModal,
        private _formBuilder: FormBuilder,
        private _localizationService: LocalizeService
    ) {
        this.createForm();
    }

    ngOnInit(): void {
        console.info('Init GapsInCareSelectionModalComponent');
    }

    close() {
        this._activeModal.close();
    }

    handleError() {
        alert(this._localizationService.get('unknownerrortryagain', 'message'));
    }
    save() {
        const result = this.gicSelectionForm.get('modality').value;
        if (!result) {
            alert(this._localizationService.get('unknownerrortryagain', 'message'));
        } else {
            this._activeModal.close(result);
        }

    }

    private createForm(): void {
        this.gicSelectionForm = this._formBuilder.group({
            modality: ['', Validators.required],
        });
    }


}

import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { NgbActiveModal,NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AbbreviatedUser } from '../../shared/abbreviated-user';
import { AccessMode } from '../../shared/access-mode';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { User } from '../../shared/user/user';
import { Utils } from '../../shared/utils';
import { AddSecureMessageComponent } from './add-secure-message.component';
import { NewMessageService } from './new-message.service';
import { SecureMessage } from './secure-message';
import { SecureMessageSearchParams } from './secure-message-search-params';
import { SecureMessagingService } from './secure-messaging.service';

@Component({
  selector: 'app-secure-messaging',
  templateUrl: './secure-messaging.component.html',
  styleUrls: ['./secure-messaging.component.css']
})

export class SecureMessagingComponent implements OnInit, OnDestroy {
  @Input() mode: AccessMode;

  @Input() coach: Person;
  @Input() participant: Person;

  AccessMode = AccessMode;
  hasError = false;
  readyForRender = false;
  fetchingRecords = false;
  specificMessageShown = false;

  messages: SecureMessage[] = [];
  currentUser: Person;
  toUser: User;
  unreadOnly = true;
  followUpOnly = false;
  generalInboxOnly = false;
  dateFilter = '';
  modalRef: NgbModalRef;
  subscriptions: ISubscription[] = [];

  private REGARDING_TYPE = 'coachingappointment';
  private NUMBER_PER_PAGE = 250;

  constructor(private _secureMessagingService: SecureMessagingService, private _personService: PersonService,
    private _modalService: NgbModal, private _newMessageService: NewMessageService,
    private _localizeService: LocalizeService) { }


  restoreFilters() {
    this.specificMessageShown = false;
    this.getSecureMessages();
  }

  getPerspectiveUser() {
    return this.participant || this.coach;
  }

  toggleShowReplies(msg: SecureMessage) {
    if (!msg.hasFullThreadAccess) {
      this.modalRef = this._modalService.open(AlertModalComponent, {
        backdrop: 'static'
      });
      this.modalRef.componentInstance.msg = this._localizeService.get('thismessagecannotbeviewedwithoutallaccess', 'message');
    } else if (msg.showChildren) {
      msg.showChildren = false;
    } else {
      this.subscriptions.push(this._secureMessagingService.getSecureMessage(msg.key)
        .subscribe(fullMsg => {
          msg.childrenMessages = fullMsg.childrenMessages;
          msg.showChildren = true;

          if (this.participant && this.participant.isParticipant) {
            const unreadKeys = this.getUnreadKeys(msg);
            if (unreadKeys.length) {
              this._secureMessagingService.markAsRead(unreadKeys)
                .subscribe( r => {
                  setTimeout(function() {
                    msg.unreadReceiveCount = 0;
                  }, 1000 );
                });
            }
          }
        },
        error => this.onError()));
    }
  }

  addReply(msg: SecureMessage) {
    let toUser: AbbreviatedUser = null;

    this.modalRef = this._modalService.open(AddSecureMessageComponent, {
      size: 'lg',
      backdrop: 'static'
    });

    const user = (msg.sendingUser.key !== this.currentUser.userKey || (msg.isGeneralInbox && !this.currentUser.isParticipant))
      ? msg.sendingUser : msg.receivingUser;

    if (user) {
      toUser = new AbbreviatedUser();
      toUser.key = user.key;
      toUser.companyName = user.company ? user.company.companyName : null;
      toUser.participantKey = user.participantKey;
      toUser.firstName = user.firstName;
      toUser.lastName = user.lastName;
    }

    this.modalRef.componentInstance.toUser = toUser;
    this.modalRef.componentInstance.parentMessageKey = msg.key;
    this.modalRef.componentInstance.isGeneralInbox = msg.isGeneralInbox;
    this.modalRef.componentInstance.parentComponent = this;
    this.modalRef.componentInstance.unreadKeys = this.getUnreadKeys(msg);
  }

  private getUnreadKeys(msg: SecureMessage): string[] {
    const keys = [],
          that = this;

    if (((msg.receivingUser && msg.receivingUser.key === this.getPerspectiveUser().userKey) ||
        (msg.isGeneralInbox && this.getPerspectiveUser().isGeneralInboxMonitor &&
        this.getPerspectiveUser().userKey !== msg.sendingUser.key)) && msg.readOn === null) {
      keys.push(msg.key);
    }

    msg.childrenMessages.forEach(function(child) {
      if ((child.receivingUser && child.receivingUser.key === that.getPerspectiveUser().userKey) ||
          (child.isGeneralInbox && that.getPerspectiveUser().isGeneralInboxMonitor && (child.receivingUser === null ||
            msg.sendingUser.key !== child.receivingUser.key)) && child.readOn === null) {
        keys.push(child.key);
      }
    });

    return keys;
  }

  unreadByUser(msg: SecureMessage) {
    return (!msg.readOn && msg.sendingUser && msg.sendingUser.key !== this.currentUser.userKey) ||
        (msg.unreadReceiveCount && msg.unreadReceiveCount > 0);
  }

  newMessage(toUser: Person, regardingKey: string) {
    const modalRef = this._modalService.open(AddSecureMessageComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.currentUser = this.getPerspectiveUser();

    if (toUser) {
      const usr = new AbbreviatedUser();
      usr.key = toUser.userKey;
      usr.firstName = toUser.firstName;
      usr.lastName = toUser.lastName;
      usr.companyName = toUser.company ? toUser.company.companyName : null;
      usr.participantKey = toUser.participantKey;
      modalRef.componentInstance.toUser = usr;
    }

    if (regardingKey) {
      modalRef.componentInstance.regardingKey = regardingKey;
    }
  }

  doShowAddReply(msg: SecureMessage) {
    return msg.hasFullThreadAccess &&
      ((msg.sendingUser && this.getPerspectiveUser().userKey === msg.sendingUser.key) ||
      (msg.receivingUser && this.getPerspectiveUser().userKey === msg.receivingUser.key) ||
      (msg.isGeneralInbox && this.getPerspectiveUser().isGeneralInboxMonitor));
  }

  doShowMarkAsRead(msg: SecureMessage) {
    return !msg.readOn && ((msg.receivingUser && msg.receivingUser.key === this.getPerspectiveUser().userKey &&
      !this.getPerspectiveUser().isParticipant) || (msg.isGeneralInbox && this.getPerspectiveUser().isGeneralInboxMonitor &&
      msg.sendingUser.key !== this.getPerspectiveUser().userKey));
  }

  markMessageAsRead(msg: SecureMessage, parentMsg: SecureMessage) {
    msg.markAsReadInflight = true;
    this.subscriptions.push(this._secureMessagingService.markAsRead([msg.key])
      .subscribe(data => {
        msg.readOn = new Date(new Date().getTime());
        
        // Subtract one from the unreadReceiveCount for parentMsg
        if(parentMsg){
          --parentMsg.unreadReceiveCount;
        }
      },
      error => this.onError()));
  }

  getSecureMessages(openFirst = false) {
    this.fetchingRecords = true;

    this.subscriptions.push(this._secureMessagingService.getSecureMessages(this.buildSearchParams())
      .subscribe(messages => {
        this.messages = messages as SecureMessage[];
        this.readyForRender = true;
        this.fetchingRecords = false;

        if (this.currentUser.isParticipant) {
          const unreads = this.messages
            .filter(function(msg) {
              return msg.readOn === null && msg.receivingUser && msg.receivingUser.key === this.currentUser.userKey;
            }, this)
            .map(function(msg) {
              return msg.key;
            });

          if (unreads.length) {
            const that = this;
            this._secureMessagingService.markAsRead(unreads)
              .subscribe( r => {
                setTimeout(function() {
                  that.messages.forEach(function(m) {
                    m.unreadReceiveCount = 0;
                  });
                }, 1000);
              });
          }
        }

        if (openFirst && this.messages.length) {
          this.toggleShowReplies(this.messages[0]);
        }

        window.scrollTo(0, 0);
      },
      error => {
        this.fetchingRecords = false;
        this.onError();
      }));
  }

  onUnreadOnly() {
    this.unreadOnly = !this.unreadOnly;
    this.getSecureMessages();
  }

  onFollowUpOnly() {
    this.followUpOnly = !this.followUpOnly;
    this.getSecureMessages();
  }

  onGeneralInboxOnly() {
    this.generalInboxOnly = !this.generalInboxOnly;
    this.getSecureMessages();
  }

  private buildSearchParams(): SecureMessageSearchParams {
    const params = new SecureMessageSearchParams();
    params.userKey = this.getPerspectiveUser().userKey;
    params.isUnread = this.unreadOnly ? true : null;
    params.perspective = this.unreadOnly ? 'RECEIVER' : null;
    params.isGeneralInbox = this.generalInboxOnly ? true : null;
    params.hasRegarding = this.followUpOnly ? true : null;
    params.regardingType = this.followUpOnly ? this.REGARDING_TYPE : null;
    params.dateFilter = this.buildDateFilter();
    params.offset = 0;
    params.numberOfRecords = this.NUMBER_PER_PAGE;

    return params;
  }

  private buildDateFilter(): Date {
    let _date = new Date(new Date().setHours(0, 0, 0, 0));
    if (this.dateFilter === 'today') {
      return Utils.toUTC(_date);
    } else if (this.dateFilter === 'last7days') {
      _date = new Date(_date.setDate(_date.getDate() - 7));
      return Utils.toUTC(_date);
    } else if (this.dateFilter === 'last30days') {
      _date = new Date(_date.setDate(_date.getDate() - 30));
      return Utils.toUTC(_date);
    } else if (this.dateFilter === 'last6months') {
      _date = new Date(_date.setMonth(_date.getMonth() - 6));
      return Utils.toUTC(_date);
    } else {
      return null;
    }
  }

  private onError(msg: string = null) {
    this.modalRef = this._modalService.open(AlertModalComponent, {
      backdrop: 'static'
    });
    this.modalRef.componentInstance.msg = msg ? msg : this._localizeService.get('unknownerror', 'message');
    this.hasError = true;
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe( person => {
      if (person) {
        this.currentUser = person;
        this.getSecureMessages();
      }
    }));

    this.subscriptions.push(this._secureMessagingService.requestedMessage.subscribe( msg => {
      if (!msg) {
        return;
      }

      this.messages = [msg];
      this.specificMessageShown = true;
    }));

    this.subscriptions.push(this._newMessageService.newMessage.subscribe( msg => {
      if (!msg) {
        return;
      }

      this.getSecureMessages();
    }));

    this.subscriptions.push(this._newMessageService.createNewMessage.subscribe( msg => {
      if (!msg) {
        return;
      }

      const modalRef = this._modalService.open(AddSecureMessageComponent, {
        size: 'lg',
        backdrop: 'static'
      });
      modalRef.componentInstance.currentUser = this.getPerspectiveUser();

      if (msg.receivingUserKey) {
        const user = new AbbreviatedUser();
        user.key = msg.receivingUserKey;
        user.firstName = msg.receivingUserFirstName;
        user.lastName = msg.receivingUserLastName;
        user.companyName = msg.receivingUserCompanyName;
        modalRef.componentInstance.toUser = user;
      }

      if (msg.regardingKey) {
        modalRef.componentInstance.regardingKey = msg.regardingKey;
      }

      this._newMessageService.setCreateNewMessage(null);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}

<ng-container *ngIf="readyForRender && !hasError">
    <div class="screen-only pb-2 float-right">
        <a href="#" (click)="print($event)" id="actionId-PrintResultDetail"><i class="fa fa-print print"></i> {{ 'printthispage' | lesCustomLocalize : 'label' }}</a>
    </div>

    <h1 class="primary-text">{{ 'resultdetail' | lesCustomLocalize : 'label' }}</h1>
    <a class="pb-2 screen-only" routerLink="/results">{{ 'gotoresults' | lesCustomLocalize : 'message' }}</a>
    <p class="screen-only" [innerHTML]="'resultsblurb' | lesCustomLocalize : 'message'"></p>
    <p class="print-only">{{ 'resultsblurbforprint' | lesCustomLocalize : 'message' }}</p>

    <div class="result-detail-section" id="resultsId-ResultDetail">
        <div>
            <span class="bold">{{ 'type' | lesCustomLocalize : 'label' }}:</span> {{ result.screenData.screenTypeName }}
        </div>
        <div>
            <span class="bold">{{ 'recorddate' | lesCustomLocalize : 'label' }}:</span> {{ result.resultDate | amDateFormat : 'M/D/YYYY' }}
        </div>
        <div>
            <span class="bold">{{ 'dateofservice' | lesCustomLocalize : 'label' }}:</span>
            {{ result.dateOfService | amDateFormat : 'M/D/YYYY' }}
        </div>
        <div>
            <span class="bold">{{ 'fastingstatus' | lesCustomLocalize : 'label' }}:</span> {{ result.fastingState }}
        </div>
        <div>
            <span class="bold">{{ 'eventlocation' | lesCustomLocalize : 'label' }}:</span> {{ result.eventLocation }}
        </div>
        <div *ngIf="result.displayRisk">
            <span class="bold">{{ 'physicianrisk' | lesCustomLocalize : 'label' }}</span>
        </div>
        <div class="print-only">
            <span class="bold">{{ 'name' | lesCustomLocalize : 'label' }}:</span> {{ currentUser.fullName }}
        </div>
        <div class="print-only">
            <span class="bold">{{ 'dateofbirth' | lesCustomLocalize : 'label' }}:</span>
            {{ currentUser.dateOfBirth | amDateFormat : 'M/D/YYYY' }}
        </div>
        <div class="print-only">
            <span class="bold">{{ 'gender' | lesCustomLocalize : 'label' }}:</span> {{ currentUser.participant?.gender }}
        </div>
        <div class="print-only">
            <span class="bold">{{ 'company' | lesCustomLocalize : 'label' }}:</span> {{ currentUser.company?.companyName }}
        </div>
        <div *ngIf="!result.screenData.resultsAreReady">
            <span class="bold result-not-ready">{{ 'resultsarenotready' | lesCustomLocalize : 'label' }}</span>
        </div>
    </div>

    <div *ngIf="getShowGroupTitle('Health Risk')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-HealthRisk">{{ 'healthrisk' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Health Risk'); let i = index">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Biometrics')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-Biometrics">{{ 'biometrics' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Biometrics')">
            <app-detail-line-item *ngIf="display(map)" [person]="currentUser" [map]="map" [maps]="getLabGroupResults('Biometrics')" [exceptionCodes]="resultLookUp.exceptionCodes"></app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Blood Lipids')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-BloodLipids">{{ 'bloodlipids' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Blood Lipids')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Blood Chemistry')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-BloodChemistry">{{ 'bloodchemistry' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Blood Chemistry')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Completed Blood Count')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-CompletedBloodCount">{{ 'completedbloodcount' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Completed Blood Count')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Hormones')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-Hormones">{{ 'hormones' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Hormones')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Nutritional')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-Nutritional">{{ 'nutritional' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Nutritional')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Other')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-Other">{{ 'other' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Other')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Medical Conditions')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-MedicalConditions">{{ 'medicalconditions' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Medical Conditions')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('Flu')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-Flu">{{ 'flu' | lesCustomLocalize : 'label' }}</h4>
        <div class="row">
            <div *ngIf="(currentUserProfile.isVerified === true || currentUserProfile.isNoEligibility === true) && canDownloadVaxProof" class="col-md-4" style="max-width: 250px">
                <button type="button" class="tertiary-bg btn btn-block" id="actionId-DownloadProofofFluVaccinationl" style="color: white" title="{{ 'listdownloadproofofflu' | lesCustomLocalize : 'label' }}" (click)="getImmunizationRecord()">
                    {{ 'listdownloadproofofflu' | lesCustomLocalize : 'label' }}
                </button>
            </div>
            <div *ngIf="(currentUserProfile.isVerified === true || currentUserProfile.isNoEligibility === true) && !canDownloadVaxProof" class="col-md-4">
                <p [innerHTML]="'proofofvaxnotavailable' | lesCustomLocalize : 'label' | safeHtml"></p>
            </div>
        </div>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('Flu')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>

    <div *ngIf="getShowGroupTitle('COVID-19')" class="result-detail-section">
        <h4 class="primary-text" id="resultsId-COVID-19">{{ 'COVID-19' | lesCustomLocalize : 'label' }}</h4>
        <div class="row result-detail-header-wrap">
            <div class="col-md-2 result-detail-header-items test">{{ 'test' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-2 result-detail-header-items result">{{ 'result' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items risk">{{ 'risk' | lesCustomLocalize : 'label' }}</div>

            <div class="col-md-4 result-detail-header-items notes">{{ 'notes' | lesCustomLocalize : 'label' }}</div>
        </div>
        <ng-container *ngFor="let map of getLabGroupResults('COVID-19')">
            <app-detail-line-item [person]="currentUser" [map]="map" [exceptionCodes]="resultLookUp.exceptionCodes"> </app-detail-line-item>
        </ng-container>
    </div>
</ng-container>

<app-error *ngIf="hasError" [htmlId]="'ResultDetail'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

import { Component, OnDestroy,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { BaseApiModel } from '../scheduler/model/apimodels/api-models';
import { AlertModalComponent } from '../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../shared/localize/localize.service';
import { Report } from '../shared/reporting/report';
import { ReportingService } from '../shared/reporting/reporting.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit, OnDestroy {
  readyForRender = false;
  hasError = false;
  subscriptions: ISubscription[] = [];
  userReports: Report[];
  localizeServiceReady = false;
  hasReportedModalError = false;

  constructor(private _localizeService: LocalizeService,
    private _reportingService: ReportingService,
    private _router: Router,
    private _modalService: NgbModal
    
    ) {
  }

  private onError() {
    this.hasError = true;
  }

  private isReadyForRender(){
    if(this.localizeServiceReady && this.userReports){
      this.readyForRender = true;
    }
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe( isReady => {
        this.localizeServiceReady = true;
        this.isReadyForRender();
    },
    error => this.onError()));

    this.subscriptions.push(this._reportingService.tableauUserAccess.subscribe( data => {
      if(data){
        if (data.isSuccess) {
          const userAccess = data.data;
          this._reportingService.fetchReports(userAccess.tableauUserId);
        }else{        
          this.processModalAlert(data);
        }
      }
    },
    error => this.onError()));

    this.subscriptions.push(this._reportingService.allReports.subscribe( data => {
        if(data){
          if (data.isSuccess) {
            this.userReports = data.data;
            this.isReadyForRender();
        }else{
          this.processModalAlert(data);
        }
        }
      },
      error => this.onError()));
  }
  processModalAlert(model: BaseApiModel){
      if(model && model.executionMessages && model.executionMessages.length)
        this.showModalAlert(model.executionMessages.map(m => m.localizeName));
      else if(model && !model.isSuccess)
        this.hasReportedModalError = true;
  }
  showModalAlert(localizeMessageNames: string[]) {
      const modalRef = this._modalService.open(AlertModalComponent, { backdrop: 'static'});
      modalRef.componentInstance.localizeNames = localizeMessageNames;    
      modalRef.result.then((result) => {      
        this.hasReportedModalError = true;
      });
      
  }
  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}

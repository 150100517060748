import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';

import { CoachGuard } from '../coach-guard.service';
import { UserPreferenceModalComponent } from '../coaching/appointments/user-preference-modal/user-preference-modal.component';
import { CoachAdminGuard } from '../coaching-admin-guard.service';
import { CoachingGuard } from '../coaching-guard.service';
import { ConsentGuard } from '../consent-guard.service';
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../shared/user/user.service';
import { AppointmentListComponent } from './appointments/appointment-list/appointment-list.component';
import { AppointmentViewerComponent } from './appointments/appointment-viewer/appointment-viewer.component';
import { CoachingAppointmentService } from './appointments/appointments.service';
import { NextAppointmentComponent } from './appointments/next-appointment/next-appointment.component';
import { CoachEditComponent } from './coach-edit/coach-edit.component';
import { CoachListComponent } from './coach-list/coach-list.component';
import { CoachingAdminDashboardComponent } from './coaching-admin-dashboard/coaching-admin-dashboard.component';
import { CoachingDashboardComponent } from './coaching-dashboard/coaching-dashboard.component';
import { ParticipantViewComponent } from './participant-view/participant-view.component';
import { SecureMessageAccessRequestService } from './secure-message-access-request/secure-message-access-request.service';
import { SecureMessageAccessRequestListComponent } from './secure-message-access-request/secure-message-request-access-list/secure-message-access-request-list-component';
import { AddMassSecureMessageComponent } from './secure-messaging/add-mass-secure-message/add-mass-secure-message.component';
import { AddSecureMessageComponent } from './secure-messaging/add-secure-message.component';
import { NewMessageService } from './secure-messaging/new-message.service';
import { SecureMessagingComponent } from './secure-messaging/secure-messaging.component';
import { SecureMessagingService } from './secure-messaging/secure-messaging.service';

@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'coaching', component: ParticipantViewComponent, canActivate: [CoachingGuard, ConsentGuard] },
            { path: 'coaching/admin', component: CoachingAdminDashboardComponent, canActivate: [CoachAdminGuard] },
            { path: 'coaching/dashboard', component: CoachingDashboardComponent, canActivate: [CoachGuard] },
            { path: 'coaching/dashboard/:key', component: CoachingDashboardComponent, canActivate: [CoachAdminGuard] }
        ]),
        CommonModule,
        SharedModule,
        MomentModule,
        NgbModule,
        FormsModule,
        QuillModule.forRoot(),
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [
        CoachingDashboardComponent,
        SecureMessageAccessRequestListComponent,
        SecureMessagingComponent,
        AppointmentListComponent,
        AddSecureMessageComponent,
        CoachingAdminDashboardComponent,
        AppointmentViewerComponent,
        CoachEditComponent,
        CoachListComponent,
        AddMassSecureMessageComponent,
        NextAppointmentComponent,
        ParticipantViewComponent,
        UserPreferenceModalComponent
    ],

    providers: [
        SecureMessagingService,
        SecureMessageAccessRequestService,
        NewMessageService,
        CoachingAppointmentService,
        UserService,
        provideNgxMask()
    ],
    exports: [
        AppointmentListComponent,
        SecureMessageAccessRequestListComponent,
        SecureMessagingComponent,
        NextAppointmentComponent
    ]
})
export class CoachingModule { }

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbDateParserFormatter, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core'
import { OAuthModule } from 'angular-oauth2-oidc';
import { ColorPickerModule } from 'ngx-color-picker';
import { CookieService } from 'ngx-cookie-service';
import { LocalTimePipe, MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';

import { AdminGuard } from './admin-guard.service';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './authorization.interceptor';
import { CoachGuard } from './coach-guard.service';
import { CoachingModule } from './coaching/coaching.module';
import { CoachAdminGuard } from './coaching-admin-guard.service';
import { CoachingGuard } from './coaching-guard.service';
import { ConsentGuard } from './consent-guard.service';
import { ContactUsComponent } from './contact-us/contact-us/contact-us.component';
import { CustomizeModule } from './customize/customize.module';
import { DIServiceLocator } from './di.service.locator';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { NgbDateCustomParserFormatter } from './ngb-date-custom-parser-formatter';
import { NoCacheInterceptor } from './no-cache-interceptor';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ParticipantModule } from './participant/participant.module';
import { PersonWidgetModule } from './person-widgets/person.widget.module';
import { PortalPromptModalComponent } from './portal-prompt-modal/portal-prompt-modal.component';
import { PortalPromptModalService } from './portal-prompt-modal/portal-prompt-modal.service';
import { PreferredLanguageInterceptor } from './preferred-language.interceptor';
import { ProfileModule } from './profile/profile.module';
import { ReportingDetailComponent } from './reporting/report-detail/report-detail.component';
import { ReportingComponent } from './reporting/reporting.component';
import { ResultsModule } from './results/results.module';
import { ResultsGuard } from './results-guard.service';
import { SchedulerModule } from './scheduler/scheduler.module';
import { SchedulerGuard } from './scheduler-guard.service';
import { SearchGuard } from './search-guard.service';
import { SelfVerifyModalComponent } from './self-verify-modal/self-verify-modal.component';
import { SelfVerifyModalService } from './self-verify-modal/self-verify-modal.service';
import { LabcorpAppointmentService } from "./services/appointments/services/labcorp.appointment.service";
import { ServicesModule } from './services/services.module';
import { AppRouteReuseStrategy } from './shared/app-route-reuse-strategy';
import { SharedModule } from './shared/shared.module';
import { SmartyStreetsService } from "./shared/smartystreets/smartystreets.api.service";
import { UserDataCaptureModalComponent } from './user-data-capture-modal/user-data-capture-modal.component';
type PathMatch = "full" | "prefix" | undefined;
declare global {
    interface Navigator {
        msSaveBlob: (blob: Blob, fileName: string) => boolean;
    }
}
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LogoutComponent,
        ContactUsComponent,
        PageNotFoundComponent,
        ReportingComponent,
        ReportingDetailComponent,
        SelfVerifyModalComponent,
        UserDataCaptureModalComponent,
        PortalPromptModalComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        ColorPickerModule,
        NgbModule,
        FontAwesomeModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        MomentModule,
        //MomentTimezoneModule,
        QuillModule.forRoot(),
        CoachingModule,
        ParticipantModule,
        ServicesModule,
        SchedulerModule,
        ProfileModule,
        CustomizeModule,
        ResultsModule,
        PersonWidgetModule,
        // NgIdleKeepaliveModule.forRoot(),
        NgIdleModule.forRoot(),
        NgbDatepickerModule,
        OAuthModule.forRoot(),
        RouterModule.forRoot([
            { path: 'home', component: HomeComponent },
            { path: 'logout', component: LogoutComponent },
            { path: 'support', component: ContactUsComponent },
            { path: 'reporting', component: ReportingComponent },
            { path: 'reporting/:key', component: ReportingDetailComponent },
            {
                path: '',
                redirectTo: '/home',
                pathMatch: 'full' as PathMatch
            },
            { path: '**', component: PageNotFoundComponent },
        ],),
        NgbAccordionModule
    ]
    ,
    providers: [
        { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PreferredLanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PreferredLanguageInterceptor, multi: true },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        LocalTimePipe,
        CookieService,
        ConsentGuard,
        AdminGuard,
        CoachGuard,
        CoachAdminGuard,
        SearchGuard,
        ResultsGuard,
        SchedulerGuard,
        CoachingGuard,
        SelfVerifyModalService,
        PortalPromptModalService,
        LabcorpAppointmentService,
        SmartyStreetsService,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule {
    constructor(private injector: Injector) {
        DIServiceLocator.injector = injector;
    }
}

<div class="container-fluid" *ngIf="readyForRender && !hasError">
    <app-person-blurb [person]="participant" [htmlId]="'Participant'" [headinglesCustomLocalizeProp]="'participant'"></app-person-blurb>
    <ngb-tabset>
        <ngb-tab title="{{ 'services' | lesCustomLocalize : 'label' }}" *ngIf="canSeeServices">
            <ng-template ngbTabContent>
                <h3 class="primary-text" id="titleId-Services">{{ 'services' | lesCustomLocalize : 'label' }}</h3>
                <app-services-list [otherUser]="participant"></app-services-list>
                <div class="next-appts row">
                    <div class="col-md-12" *ngIf="participant.hasSchedulerAccess">
                        <app-past-appointments [disableIfUpcomingAppointment]="false" [otherUser]="participant"></app-past-appointments>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="coaching" title="{{ 'coaching' | lesCustomLocalize : 'label' }}" *ngIf="participant && participant.hasCoaching && canSeeCoaching">
            <ng-template ngbTabContent>
                <div class="pt-3">
                    <div class="row d-lg-none" *ngIf="isCoaching">
                        <div class="col text-center pb-3">
                            <div class="btn-group" role="group">
                                <input type="radio" class="btn-check" [(ngModel)]="sectionToShow" [value]="sectionValues.Appointments" id="participant_detail_sectionToShow_Appointments" name="sectionToShow" />
                                <label class="btn btn-primary" for="participant_detail_sectionToShow_Appointments">{{ 'appointments' | lesCustomLocalize : 'label' }}</label>
                                <input type="radio" class="btn-check" [(ngModel)]="sectionToShow" [value]="sectionValues.Messaging" id="participant_detail_sectionToShow_Messaging" name="sectionToShow" />
                                <label class="btn btn-primary" for="participant_detail_sectionToShow_Messaging">{{ 'messages' | lesCustomLocalize : 'label' }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div [ngClass]="{ 'col-md-12': true, 'col-lg-6': true }">
                            <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.Appointments }">
                                <app-coaching-appointment-list [mode]="accessMode" [participant]="participant" (sectionChangeRequest)="changeSection($event)"></app-coaching-appointment-list>
                            </div>
                            <div [ngClass]="{ 'd-none d-lg-block': sectionToShow !== sectionValues.AllAccess }" *ngIf="isCoaching">
                                <app-secure-message-access-request-list-component [mode]="accessMode" [participant]="participant"></app-secure-message-access-request-list-component>
                            </div>
                        </div>
                        <div [ngClass]="{ 'col-md-12': true, 'col-lg-6': true, 'd-none d-lg-block': sectionToShow !== sectionValues.Messaging }" *ngIf="isCoaching">
                            <app-secure-messaging [mode]="secureMessagingMode" [participant]="participant"></app-secure-messaging>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>

<app-loading *ngIf="!readyForRender && !hasError"></app-loading>

<div class="alert alert-danger" role="alert" *ngIf="hasError">
    <span *ngIf="lesCustomLocalizeReady"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'unknownerror' | lesCustomLocalize : 'message' }}</span>
    <span *ngIf="!lesCustomLocalizeReady"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> An unknown error has occurred. Please reload the page and try again.</span>
</div>

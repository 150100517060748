import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from "@angular/router";

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return super.shouldDetach(route);

    }
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        super.store(route, detachedTree)
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return super.shouldAttach(route);
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return super.retrieve(route);
    }
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig || future.data.reuseComponent;
    }
}
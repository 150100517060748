import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { PersonWidgetService } from '../../person-widgets/person.widget.service';
import { UpcomingAppointmentsComponent } from '../../scheduler/appointments/upcoming-appointments/upcoming-appointments.component';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import AppNotificationMessage from '../../shared/app-notification/app-notification-message';
import { AppNotificationService } from '../../shared/app-notification/app-notification-service';
import { AttestationModel } from '../../shared/attestation/attestation.model';
import { AttestationService } from '../../shared/attestation/attestation.service';
import { AttestationModalComponent } from '../../shared/attestation/attestation-modal.component';
import { ConsentsNeeded, ConsentsNeededByServiceSearchModel } from '../../shared/consent-modal/consent';
import { ConsentService } from '../../shared/consent-modal/consent.service';
import { ConsentModalComponent } from '../../shared/consent-modal/consent-modal.component';
import { GapsInCareViewModel } from '../../shared/gaps-in-care/gaps-in-care.model';
import { GapsInCareService } from '../../shared/gaps-in-care/gaps-in-care.service';
import { GapsInCareModalComponent } from '../../shared/gaps-in-care/gaps-in-care-modal.component';
import { GapsInCareSelectionModalComponent } from '../../shared/gaps-in-care/gaps-in-care-selection-modal.component';
import { Globals } from '../../shared/globals';
import { IntegrationService } from '../../shared/integration/integration.service';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { SevereSymptomCheckPost } from '../../shared/servere-symptom-modal/severe-symptom';
import { SevereSymptomService } from '../../shared/servere-symptom-modal/severe-symptom.service';
import { SevereSymptomModalComponent } from '../../shared/servere-symptom-modal/severe-symptom-modal.component';
import { GicModality, Service, ServiceQualificationRuleFailures, UserServices } from '../../shared/service';
import { ServiceQualificationRuleFailureModalComponent } from '../../shared/service-qualification-rule-failure-modal/service-qualification-rule-failure-modal.component';
import { ServiceQualificationRuleOverrideCodeService } from '../../shared/service-qualification-rule-failure-modal/service-qualification-rule-override-code-service';
import { UserService } from '../../shared/user/user.service';
import { Utils } from '../../shared/utils';
import { UserBiometricCaptureResult } from '../../user-biometric-capture-modal/user-biometric-capture';
import { UserBiometricCaptureModalComponent } from '../../user-biometric-capture-modal/user-biometric-capture-modal.component';
import { UserDataCaptureResult } from '../../user-data-capture-modal/user-data-capture';
import { UserDataCaptureModalComponent } from '../../user-data-capture-modal/user-data-capture-modal.component';
import { LabcorpAppointSchedulerComponent } from '../appointments/labcorp.appointment.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { ServicesService } from './services.service';
@Component({
    selector: 'app-services-list',
    templateUrl: './services-list.component.html',
    styleUrls: ['./services-list.component.css']
})
export class ServicesListComponent implements OnInit, OnDestroy {
    @ViewChild(UpcomingAppointmentsComponent) upcomingAppointments: UpcomingAppointmentsComponent;
    // An admin may be looking at this list.
    _otherUser: Person;
    _runServiceKey: string;
    _runUpload = false;

    isMobileDevice = Utils.isMobileDevice();

    @Input() set otherUser(val: Person) {
        this._otherUser = val;

        this.subscriptions.push(
            this._personService.getOtherPerson(val.userKey, false).subscribe({
                complete: () => this.checkReadyForRender()
            })
        );
    }

    get otherUser(): Person {
        return this._otherUser;
    }

    pdfServiceType = Globals.ServiceTypes.PDF;
    subscriptions: ISubscription[] = [];
    currentPerson: Person = null;
    userServices: UserServices[] = [];
    localizeReady = false;
    readyForRender = false;
    modalRef: NgbModalRef;
    loadingModalRef: NgbModalRef = null;
    notificationMessages: AppNotificationMessage[] = [];

    constructor(
        private _appNotificationService: AppNotificationService,
        private _personService: PersonService,
        private activeModal: NgbActiveModal,
        private _localizeService: LocalizeService,
        private _modalService: NgbModal,
        private _router: Router,
        private _route: ActivatedRoute,
        private servicesService: ServicesService,
        private _consentService: ConsentService,
        private _userService: UserService,
        private _integrationService: IntegrationService,
        private _severeSymptomService: SevereSymptomService,
        private _overrideCodeService: ServiceQualificationRuleOverrideCodeService,
        private _attestationService: AttestationService,
        private _gapsInCareService: GapsInCareService,
        private _personWidgetService: PersonWidgetService,
        private _location: Location
    ) { }

    ngOnInit() {
        this.subscriptions.push(
            this._personService.currentPerson.subscribe((currentPerson) => {
                this.currentPerson = currentPerson;

                // Go get app notification messages
                this.subscriptions.push(
                    this._appNotificationService.getNotificationMessages(currentPerson.userKey).subscribe((messages) => {
                        this.notificationMessages = messages;
                    })
                );

                if (!this.otherUser) {
                    if (this.currentPerson.isCustomerAdmin && this.currentPerson.isParticipant) {
                        this.userServices = currentPerson.UserServices;
                    } else {
                        this.userServices = currentPerson.UserServices.filter((userService) => new Date(userService.programService.portalPublishDate) <= new Date(Date.now()));
                    }
                } else {
                    if ((this.currentPerson.isWcsAdmin || this.currentPerson.isSupportUser || this.currentPerson.isPartnerAdmin) && this.otherUser.isCustomerAdmin && this.otherUser.isParticipant) {
                        this.userServices = this.otherUser.UserServices;
                    } else {
                        this.userServices = this.otherUser.UserServices.filter((userService) => new Date(userService.programService.portalPublishDate) <= new Date(Date.now()));
                    }
                }
                this.userServices = _.sortBy(this.userServices, ['programService.orderBy', 'programService.serviceCardLabel']);
                this.run();
                this.checkReadyForRender();
            })
        );

        this.subscriptions.push(
            this._localizeService.isReady.subscribe((isReady) => {
                this.localizeReady = isReady;
                this.checkReadyForRender();
            })
        );
        this.subscriptions.push(
            this._route.queryParams.subscribe((params) => {
                this._runServiceKey = params.service;
                this._runUpload = params.upload || false;
                this.run();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
    /**
     *
     * @returns void:
     * this is a temporary work around to return modal tasks to the service page. Future solution will
     * send users to appropriate page to perform needed tasks after widget page.
     */
    run() {
        if (!this._runServiceKey) return;

        const service = this.userServices.find((t) => {
            return t.programService.programServiceKey == this._runServiceKey;
        });
        if (service && service.programService) {
            const shouldUpload = this._runUpload;
            //clear current
            this._runUpload = false;
            this._runServiceKey = null;
            this.route(service.programService, shouldUpload);
            this._router.navigate([], { queryParams: { upload: null, service: null } });
        }
    }
    getUser(): Person {
        return this.otherUser ? this.otherUser : this.currentPerson;
    }

    checkReadyForRender() {
        this.readyForRender = this.localizeReady && this.currentPerson !== null;
    }

    requiresDataCaptureInformation(service: Service, upload: any) {
        this._userService.isUserInformationNeeded(this.getUser().userKey, service.programServiceKey).subscribe((response) => {
            if (response) {
                this.captureUserData(service, upload);
            } else {
                this.requiresBiometricCaptureInformation(service, upload);
            }
        });
    }

    captureUserData(service: Service, upload) {
        const userDataCaptureModal = this._modalService.open(UserDataCaptureModalComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'userdatacapture-modal-window'
        });

        if (service.serviceType === Globals.ServiceTypes.Pixel) {
            this.showLoader(false);
        }

        userDataCaptureModal.componentInstance.userKey = this.getUser().userKey;
        userDataCaptureModal.result.then((result) => {
            //
            const response = result as UserDataCaptureResult;
            if (response.hasDataCapture) {
                // continue
                if (service.serviceType === Globals.ServiceTypes.Pixel) {
                    this.showLoader(true);
                }
                this.route(service, upload);
            } else {
                // are going to pop up again?
            }
        });
    }

    requiresBiometricCaptureInformation(service: Service, upload: any) {
        this._userService.isUserBiometricNeeded(this.getUser().userKey, service.serviceType, service.programServiceKey).subscribe((response) => {
            if (response) {
                this.captureUserBiometric(service, upload);
            } else {
                this.route(service, upload);
            }
        });
    }

    captureUserBiometric(service: Service, upload) {
        const userBiometricCaptureModal = this._modalService.open(UserBiometricCaptureModalComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'userbiometriccapture-modal-window'
        });

        if (service.serviceType === Globals.ServiceTypes.Pixel) {
            this.showLoader(false);
        }

        userBiometricCaptureModal.componentInstance.userKey = this.getUser().userKey;
        userBiometricCaptureModal.componentInstance.programServiceTypeKey = service.programServiceTypeKey;
        userBiometricCaptureModal.componentInstance.programServiceKey = service.programServiceKey;
        userBiometricCaptureModal.result.then((result) => {
            //
            const response = result as UserBiometricCaptureResult;
            if (response.hasBiometricCapture) {
                // continue
                if (service.serviceType === Globals.ServiceTypes.Pixel) {
                    this.showLoader(true);
                }
                this.route(service, upload);
            } else {
                // are going to pop up again?
            }
        });
    }

    private processNonWidgetFlow(userConsentService: UserServices, upload) {
        // check if there are outstanding consents
        const serviceKeys: string[] = [userConsentService.programService.programServiceKey];
        const consentsNeeded = new ConsentsNeededByServiceSearchModel(true, serviceKeys);
        if (!this.currentPerson.isWcsAdmin && !this.isImpersonating()) {
            this._consentService.getConsentsNeededByService(consentsNeeded).subscribe((result) => {
                if (result && result.result.length) {
                    const consentsNeeded = _.filter(result.result, (outConsent: ConsentsNeeded) => {
                        return outConsent.programServiceKey === userConsentService.programService.programServiceKey;
                    });
                    if (consentsNeeded) {
                        this.requiresConsent(consentsNeeded, userConsentService, upload);
                    } else {
                        this.requiresSevereSymptomCapture(userConsentService.programService, upload);
                    }
                } else {
                    this.requiresSevereSymptomCapture(userConsentService.programService, upload);
                }
            });
        } else {
            this.requiresSevereSymptomCapture(userConsentService.programService, upload);
        }
    }

    public go(userConsentService: UserServices, upload) {
        if (!this.adminCanPerform(userConsentService.programService)) {
            alert('This action cannot be performed by an admin.');
            return;
        } else {
            this._personWidgetService.requiresWidgetCollection(this.getUser().userKey, userConsentService.programService.programServiceKey, this.getUser().companyKey)
                .subscribe({
                    next: (s) => {
                        if (s.isCollectionRequired) {
                            //this service requires widget processing
                            if (s.isCollectionNeeded) {
                                this._router.navigate([Globals.Routes.widgets], { queryParams: { service: userConsentService.programService.programServiceKey, upload: upload, user: this.getUser().userKey } });
                            } else {
                                if (userConsentService.programService.serviceType === Globals.ServiceTypes.Pixel) {
                                    this.showLoader(true);
                                }
                                this.route(userConsentService.programService, upload);
                            }
                        } else {
                            if (userConsentService.programService.serviceType === Globals.ServiceTypes.Pixel) {
                                this.showLoader(true);
                            }
                            this.processNonWidgetFlow(userConsentService, upload);
                        }
                    }
                });
        }
    }

    private uploadModal(service: Service) {
        this.modalRef = this._modalService.open(FileUploadComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.service = service;
        this.modalRef.componentInstance.userKey = this.getUser().userKey;
    }

    public isDownLoad(service: Service) {
        if (service.serviceType === Globals.ServiceTypes.PDF) {
            return true;
        }
        return false;
    }

    public adminCanPerform(service: Service) {
        if (!this.otherUser) {
            return true;
        }
        if (this._otherUser && service.serviceType == Globals.ServiceTypes.Coaching) {
            return false;
        }
        return this.currentPerson.canSeeParticipantServices && Globals.AdminForbiddenServiceTypes.indexOf(service.programServiceTypeKey.toLowerCase()) < 0;
    }

    private route(service: Service, upload = false) {
        const doRoute = (overrideCode: string) => {
            switch (service.serviceType) {
                case Globals.ServiceTypes.Coaching:
                    this._router.navigate([Globals.Routes.coaching]);
                    break;
                case Globals.ServiceTypes.OnlineScheduler: {
                    const userKey = this.getUser().userKey;
                    if (this.currentPerson.userKey === userKey) {
                        this._router.navigate([Globals.Routes.scheduler]);
                    } else {
                        this._router.navigate([Globals.Routes.scheduler], { queryParams: { user: userKey } });
                    }
                    break;
                }
                case Globals.ServiceTypes.InfoOnly:
                    this.openWindow(service.informationUrl);
                    this._personService.fetchPerson();
                    break;
                case Globals.ServiceTypes.ThirdParty:
                    this.servicesService.getThirdParty(this.getUser().company.key, service.programServiceKey, this.getUser().participantKey, this.getUser().userKey).subscribe((thirdParty) => {
                        if (thirdParty.status && thirdParty.status.toUpperCase() === 'FAIL') {
                            alert(this._localizeService.get('unknownerrortryagain', 'message'));
                            return;
                        }
                        this.openWindow(thirdParty.result.action);
                        this._personService.fetchPerson();
                    });
                    break;
                case Globals.ServiceTypes.PDF:
                    if (service.isOnlineScheduler === true) {
                        this.scheduleLapcorpAppointment(service);
                        return;
                    }
                    if (!upload) {
                        this.servicesService.getDocument(service.programServiceKey, this.getUser().userKey, this.getProgramLabel(service.serviceCardLabel, service)).subscribe((res) => {
                            if (!res) {
                                alert(this._localizeService.get('unknownerrortryagain', 'message'));
                            }
                        });
                    } else {
                        this.uploadModal(service);
                    }
                    break;
                case Globals.ServiceTypes.Pixel:
                    this.showLoader(true);

                    this._integrationService.getPixel(service.programServiceKey, overrideCode).subscribe(
                        {
                            next: (response) => {
                                if (Number(response.status) === 0) {
                                    this.openPixelWindow(response.result);
                                } else {
                                    if (response.serviceQualificationRuleFailuresResult.length > 0) {
                                        this.displayModalError(response.serviceQualificationRuleFailuresResult[0].failureMessage);
                                        this.openPixelWindow(null);
                                    } else {
                                        alert(this._localizeService.get('unknownerrortryagain', 'message'));
                                        this.openPixelWindow(null);
                                    }
                                    return;
                                }
                            },
                            error: () => {
                                this.showLoader(false);
                                alert(this._localizeService.get('unknownerrortryagain', 'message'));
                            }
                        });
                    break;
                case Globals.ServiceTypes.Attestation:
                    this.showLoader(true);
                    this._attestationService.getAttestation(service.programServiceKey, this.getUser().locale.languageLocale).subscribe((response) => {
                        if (response) {
                            this.showLoader(false);
                            this.openAttestationModal(response);
                        } else {
                            alert(this._localizeService.get('unknownerrortryagain', 'message'));
                        }
                    });
                    break;
                case Globals.ServiceTypes.GapsInCare:
                    this.processGicSelection(service, overrideCode);
                    break;
            }
        };
        if (service.qualificationRuleOverrideCode) {
            if (service.serviceType === Globals.ServiceTypes.Pixel) {
                //Pixel service will validate qualification rules server side
                //so pass the override code to it
                doRoute(service.qualificationRuleOverrideCode);
            } else {
                //mark the override code as used before routing the user
                this.showLoader(true, this._localizeService.get('applyingoverridecode', 'message'));
                this._overrideCodeService.useOverrideCode(service.qualificationRuleOverrideCode, service.programServiceKey, this.getUser().userKey).subscribe((response) => {
                    this.showLoader(false);
                    if (!response || response.isValid !== true) {
                        //invalid code or code reused
                        this.displayModalError('invalidoverridecode');
                    } else {
                        this.userServices.find((x) => x.programService.programServiceKey === service.programServiceKey).serviceQualificationRuleFailures = response.serviceQualificationRuleFailures;
                        doRoute(service.qualificationRuleOverrideCode);
                    }
                });
            }
        } else {
            doRoute(null);
        }
    }

    openGapsInCareModal(gapsInCareViewModel: GapsInCareViewModel) {
        //load gic data for modal
        this.modalRef = this._modalService.open(GapsInCareModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });

        this.modalRef.componentInstance.title = this._localizeService.get(gapsInCareViewModel.titleLabel, 'program');
        this.modalRef.componentInstance.gapsInCareViewModel = gapsInCareViewModel;
        this.modalRef.componentInstance.currentPerson = this.getUser();
        this.modalRef.componentInstance.userKey = this.getUser().userKey;
    }

    openAttestationModal(attestation: AttestationModel) {
        //load attestation data for modal
        this.modalRef = this._modalService.open(AttestationModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        this.modalRef.componentInstance.attestation = attestation;
        this.modalRef.componentInstance.userKey = this.getUser().userKey;
        this.modalRef.result.then((result) => {
            this._personService.fetchPerson();
        });
    }

    requiresSevereSymptomCapture(service: Service, upload: any) {
        const checkRequest = new SevereSymptomCheckPost();
        const serviceKeys: string[] = [service.programServiceKey];
        checkRequest.serviceKeys = serviceKeys;
        checkRequest.userKey = this.getUser().userKey;
        this._severeSymptomService.checkSevereSymptomResponse(checkRequest).subscribe((response) => {
            // if the user has not responded today, or they have, but answered 'yes' to severesymptoms then require an answer severesymptom;
            if (!response || (response && response.toLowerCase() === 'true')) {
                this.captureSevereSymptomResponse(false, checkRequest.userKey, service, upload);
            }
            // otherwise, they've answered 'No' or this is not applicable for this service
            else {
                this.requiresDataCaptureInformation(service, upload);
            }
        });
    }

    private captureSevereSymptomResponse(displaySevereSymptomWarning: boolean, userKey: string, service: Service, upload) {
        if (service.serviceType === Globals.ServiceTypes.Pixel) {
            this.showLoader(false);
        }

        this.modalRef = this._modalService.open(SevereSymptomModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        this.modalRef.componentInstance.displaySevereSymptomWarning = displaySevereSymptomWarning;
        this.modalRef.componentInstance.userKey = userKey;
        this.modalRef.result.then((hasSevereSymptoms) => {
            if (hasSevereSymptoms === false) {
                this.requiresDataCaptureInformation(service, upload);
            } else {
                // Do not proceed onto the next step
                return;
            }
        });
    }

    private isImpersonating(): boolean {
        const impersonator = this.currentPerson;
        const subject = this.otherUser;
        if (impersonator && subject && impersonator.canImpersonate) {
            if (impersonator.user && subject.user) {
                return impersonator.user.key != subject.user.key;
            }
            if (impersonator.participant && subject.participant) {
                return impersonator.participant.key != subject.participant.key;
            }
        }
        return false;
    }

    private requiresConsent(consentsNeeded: ConsentsNeeded[], userService: UserServices, upload) {
        this.modalRef = this._modalService.open(ConsentModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });

        if (userService.programService.serviceType === Globals.ServiceTypes.Pixel) {
            this.showLoader(false);
        }

        this.modalRef.componentInstance.consentsNeeded = consentsNeeded;
        this.modalRef.componentInstance.personKey = this.getUser().personKey;
        this.modalRef.componentInstance.userKey = this.getUser().userKey;
        this.modalRef.componentInstance.participantKey = this.getUser().participantKey;
        this.modalRef.result.then(
            (consent) => {
                if (userService.programService.serviceType === Globals.ServiceTypes.Pixel) {
                    this.showLoader(true);
                }

                if (consent && consent.hasConsent) {
                    this.requiresSevereSymptomCapture(userService.programService, upload);
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }

    public openQualificationRuleFailureReason(serviceQualificationRuleFailures: ServiceQualificationRuleFailures[]) {
        if (serviceQualificationRuleFailures.length > 0) {
            this.modalRef = this._modalService.open(ServiceQualificationRuleFailureModalComponent, {
                backdrop: 'static'
            });
            this.modalRef.componentInstance.header = this._localizeService.get('thisserviceiscurrentlyunavailable', 'message');

            this.modalRef.componentInstance.serviceQualificationRuleFailures = serviceQualificationRuleFailures;
            this.modalRef.componentInstance.applyOverrideCode.on('applyOverrideCode', (code, programServiceKey, serviceRuleFailures) => {
                const tempServices = [...this.userServices];
                for (let i = 0; i < tempServices.length; i++) {
                    if (tempServices[i].programService.programServiceKey === programServiceKey) {
                        //code has been validated, remove failures that are overridden
                        tempServices[i].serviceQualificationRuleFailures = serviceRuleFailures;
                        tempServices[i].programService.qualificationRuleOverrideCode = code;
                        this.userServices = [...tempServices];
                        this.modalRef.close();
                        break;
                    }
                }
            });
        }
    }

    private openWindow(href: string) {
        window.open(href);
    }

    openPixelWindow(href: string) {
        this.showLoader(false);

        if (href != null) {
            if (Utils.isMobileDevice()) {
                window.location.href = href;
            } else {
                this.openWindow(href);
            }
        }
    }

    public getProgramLabel(token: string, service: Service = null) {
        let prgLabel = '';
        if (service) {
            const options = `{"programServiceKey":"${service.programServiceKey}","programServiceTypeKey":"${service.programServiceTypeKey}"}`;
            prgLabel = this._localizeService.get(Utils.getServiceToken(token).toLowerCase(), 'program', null, null, null, null, options);
        } else {
            prgLabel = this._localizeService.get(Utils.getServiceToken(token).toLowerCase(), 'program');
        }

        if (!prgLabel || prgLabel.trim() === '') {
            return token;
        }

        return prgLabel.toUpperCase();
    }

    public getHtmlId(token: string, service: Service = null) {
        const result = this.getProgramLabel(token, service);
        return result.replace(/\s+/g, '-');
    }

    public getHtmlButtonId(token: string, service: Service = null, customizationCode: string, defaultKey: string) {
        const result = this.getHtmlId(token, service);
        const buttonLabel = this.getProgramCustomization(customizationCode, defaultKey, service);
        return buttonLabel.replace(/\s+/g, '-') + result;
    }

    public getProgramDescription(token: string, service: Service = null) {
        if (service) {
            const options = `{"programServiceKey":"${service.programServiceKey}","programServiceTypeKey":"${service.programServiceTypeKey}"}`;
            return this._localizeService.get(Utils.getServiceDescriptions(token), 'program', null, null, null, null, options);
        } else {
            return this._localizeService.get(Utils.getServiceDescriptions(token), 'program');
        }
    }

    private displayModalError(tagMsg: string = null) {
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.msg = tagMsg ? this._localizeService.get(tagMsg, 'message') : this._localizeService.get('unknownerror', 'message');
    }

    private showLoader(doShow: boolean, msg: string = null) {
        if (doShow) {
            if (typeof this.loadingModalRef !== 'undefined' && this.loadingModalRef !== null) {
                this.loadingModalRef.close();
            }

            this.loadingModalRef = this._modalService.open(LoadingComponent, {
                backdrop: 'static',
                windowClass: 'loadingModal'
            });

            if (msg) {
                this.loadingModalRef.componentInstance.msg = msg;
            }
        } else {
            this.loadingModalRef.close();
        }
    }

    public getProgramCustomization(customizationCode: string, defaultKey: string, service: Service = null) {
        const token = typeof customizationCode === 'undefined' || !customizationCode ? defaultKey : customizationCode.toLowerCase() + defaultKey;
        if (service) {
            const options = `{"programServiceKey":"${service.programServiceKey}","programServiceTypeKey":"${service.programServiceTypeKey}"}`;
            return this._localizeService.get(token, 'program', null, null, null, null, options);
        } else {
            return this._localizeService.get(token, 'program');
        }
    }

    public scheduleLapcorpAppointment(service: Service) {
        this.modalRef = this._modalService.open(LabcorpAppointSchedulerComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.currentService = service;
        this.modalRef.componentInstance.currentUser = this.getUser();
    }

    public processGicSelection(service: Service, overrideCode: string) {
        const gicModality = this._gapsInCareService.gicModality(service);
        if (gicModality == GicModality.Both) {
            this.modalRef = this._modalService.open(GapsInCareSelectionModalComponent, {
                backdrop: 'static'
            })
            this.modalRef.result.then((result) => {
                if (result == 'PSC Visit') {
                    return this.processGicPSC(service, overrideCode);
                } else if (result === 'Home Test Kit') {
                    return this.processGicHomeTestKit(service, overrideCode);
                }
            });
        } else if (gicModality == GicModality.HomeTestKit) {
            return this.processGicHomeTestKit(service, overrideCode);
        } else if (gicModality == GicModality.PSC) {
            this.processGicPSC(service, overrideCode);
        } else { //this probably should not happen
            alert(this._localizeService.get('unknownerror', 'message'));
        }
    }
    processGicHomeTestKit(service: Service, overrideCode: string) {
        this.showLoader(true);
        this._gapsInCareService.getTransactionId(service.programServiceKey, this.getUser().userKey, overrideCode).subscribe(
            (response) => {
                if (Number(response.status) === 0) {
                    const gapsInCareViewModel = new GapsInCareViewModel();
                    gapsInCareViewModel.titleLabel = service.serviceCardLabel;
                    gapsInCareViewModel.programServiceKey = service.programServiceKey;
                    gapsInCareViewModel.companyKey = service.companyKey;
                    gapsInCareViewModel.allowOptinPaperCommunication = service.allowOptinPaperCommunication;
                    gapsInCareViewModel.transactionId = response.result;
                    gapsInCareViewModel.participantServiceEligibilityKey = response.participantServiceEligibilityKey;
                    gapsInCareViewModel.lcaAccountNumber = response.participantServiceAccountNumber;
                    gapsInCareViewModel.gicModality = 'Home Test Kit'

                    this.openGapsInCareModal(gapsInCareViewModel);
                    this.showLoader(false);
                } else {
                    if (response.serviceQualificationRuleFailuresResult.length > 0) {
                        this.displayModalError(response.serviceQualificationRuleFailuresResult[0].failureMessage);
                        this.showLoader(false);
                    } else {
                        alert(this._localizeService.get('unknownerrortryagain', 'message'));
                        this.showLoader(false);
                    }
                    return;
                }
            },
            (error) => {
                this.showLoader(false);
                alert(this._localizeService.get('unknownerrortryagain', 'message'));
            }
        );
    }
    processGicPSC(service: Service, overrideCode: string) {
        this.scheduleLapcorpAppointment(service)
    }

}

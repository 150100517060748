<div *ngIf="personDataCollection && isRenderReady" class="primary-widget-container" id="{{ getFriendlyId('collectionId', widgetTitle) }}">
    <form [formGroup]="personForm" class="form data-collection-form" id="{{ getFriendlyId('formId', widgetTitle) }}">
        <div class="form-group row custom-card" *ngFor="let control of personDataCollection; let i = index">
            <div class="col-xs-12">
                <div *ngIf="control.prePromptTag" class="patient-preprompt">
                    {{ getTranslation(control.prePromptTag) }}
                </div>

                <div *ngIf="control.tag" class="patient-tag">
                    {{ getTranslation(control.tag) }}
                </div>
                <div *ngIf="control.htmlInputElement === 'yesnobuttons'" class="container-center text-center">
                    <div class="grp-btn-container btn-group" role="group">
                        <input class="btn-check" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" type="radio" value="yes" id="{{ 'formInputId-YesNo' + getHtmlId(control.internalName) + 'Widget' }}" />
                        <label class="btn btn-outline-primary les-widget les-widget-radio-label" for="{{ 'formInputId-YesNo' + getHtmlId(control.internalName) + 'Widget' }}">
                            {{ 'label.yes' | lesCustomLocalize : '' : null : null : tagOptions }}
                        </label>
                        <input class="btn-check" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" type="radio" value="no" id="{{ 'formInputId-No' + getHtmlId(control.internalName) + 'Widget' }}" />
                        <label class="btn btn-outline-primary les-widget les-widget-radio-label" for="{{ 'formInputId-No' + getHtmlId(control.internalName) + 'Widget' }}">
                            {{ 'label.no' | lesCustomLocalize : '' : null : null : tagOptions }}
                        </label>
                    </div>
                </div>

                <div *ngIf="control.htmlInputElement === 'yesnoradiobuttons'" class="container-center text-center">
                    <div class="grp-btn-container btn-group">
                        <input class="btn-check" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" type="radio" [value]="1" id="{{ 'formInputId-Yes' + getHtmlId(control.internalName) + 'Widget' }}" />
                        <label class="btn-outline-primary les-widget les-widget-radio-label" for="{{ 'formInputId-Yes' + getHtmlId(control.internalName) + 'Widget' }}">
                            {{ 'label.yes' | lesCustomLocalize : ':null:null:tagOptions' }}
                        </label>
                        <input class="btn-check" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" type="radio" [value]="0" id="{{ 'formInputId-No' + getHtmlId(control.internalName) + 'Widget' }}" />
                        <label class="btn-outline-primary les-widget les-widget-radio-label" for="{{ 'formInputId-No' + getHtmlId(control.internalName) + 'Widget' }}">
                            {{ 'label.no' | lesCustomLocalize : '' : null : null : tagOptions }}
                        </label>
                    </div>
                </div>

                <div *ngIf="control.htmlInputElement === 'textbox'">
                    <input class="form-control" type="text" value="{{ control.response }}" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" />
                </div>

                <div *ngIf="control.htmlInputElement === 'textarea'">
                    <textarea class="form-control" rows="4" value="{{ control.response }}" name="{{ control.internalName }}" formControlName="{{ control.internalName }}"> </textarea>
                </div>

                <div *ngIf="control.htmlInputElement === 'numberbox'">
                    <input class="form-control" type="number" value="{{ control.response }}" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" />
                </div>

                <div *ngIf="control.htmlInputElement === 'selectbox'" class="container-center">
                    <div class="multi-wrapper-container">
                        <label *ngFor="let choice of control.responseChoices" class="col-sm-12 multi-wrapper-container-response">
                            <input type="radio" formControlName="{{ control.internalName }}" value="{{ choice.value }}" />
                            {{ getTranslation(choice.option) }}
                        </label>
                    </div>
                </div>

                <div *ngIf="control.htmlInputElement === 'multi-selectbox'" class="container-center">
                    <div class="multi-wrapper-container">
                        <label *ngFor="let choice of control.responseChoices" class="col-sm-12 multi-wrapper-container-response">
                            <input
                                type="checkbox"
                                formArrayName="{{ control.internalName }}"
                                value="{{ choice.value }}"
                                [checked]="choice.isSelected"
                                id="{{ 'formInputId-' + getHtmlId(control.internalName) + 'Widget' }}"
                                (change)="onMultiSelectChange(choice, control.internalName)"
                                [attr.disabled]="choice.isDisabled ? true : null"
                            />
                            {{ getTranslation(choice.option) }}
                        </label>
                    </div>
                </div>

                <div *ngIf="control.htmlInputElement === 'datetime'">
                    <div class="input-group">
                        <input class="form-control" placeholder="yyyy-mm-dd" value="{{ control.response }}" ngbDatepicker #d="ngbDatepicker" name="{{ control.internalName }}" formControlName="{{ control.internalName }}" />
                        <button class="input-group-addon" (click)="d.toggle()" type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--input section end-->

            <div class="col-sm-12">
                <div *ngIf="control.postPromptTag" class="patient-postPromptTagt">
                    {{ 'control.postPromptTag' | lesCustomLocalize : '' : null : null : tagOptions }}
                </div>
            </div>
        </div>

        <div class="form-group row mobile-field" *ngIf="personWidget.footerLanguage">
            <div class="col-sm-12">
                <div [innerHTML]="personWidget.footerLanguage | safeHtml" class="les-information-text"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="alert alert-danger" *ngIf="hasError">
                <button type="button" class="close" id="{{ 'actionId-CloseAlert' + getFriendlyId('Btn', widgetTitle) }}" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span [innerHTML]="'message.invalidform' | lesCustomLocalize : '' : null : null : tagOptions"></span>
            </div>

            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li
                        *ngIf="personWidget.position > 0"
                        [ngClass]="{
                            'page-item': true,
                            disabled: personWidget.position == 0
                        }"
                    >
                        <a class="les-widget-link" id="{{ 'actionId-Previous' + getFriendlyId('Btn', widgetTitle) }}" (click)="onPreviousButtonClick()" [attr.disabled]="personWidget.position == 0 ? 'true' : null">Previous</a>
                    </li>
                    <li [ngClass]="{ 'page-item': true, disabled: personForm.invalid }">
                        <a class="les-widget-link" id="{{ 'actionId-Next' + getFriendlyId('Btn', widgetTitle) }}" (click)="onNextButtonClick()">
                            {{ 'button.next' | lesCustomLocalize : '' : null : null : tagOptions }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </form>
</div>

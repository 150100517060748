import { Component, Input,OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import _ from "lodash";
import { DateFormatPipe } from "ngx-moment";
import { SubscriptionLike as ISubscription } from "rxjs";

import { environment } from "../../../environments/environment";
import { ServicesService } from "../../services/services-list/services.service";
import { WCSMessageService } from "../../services/wcs-message/wcs.message.service";
import { WCSMessageTypes } from "../../services/wcs-message/wcs-message";
import { LocalizeService } from "../../shared/localize/localize.service";
import { Person } from "../../shared/person/person";
import { PersonService } from "../../shared/person/person.service";
import { Profile } from "../../shared/profile/profile";
import { ProfileService } from "../../shared/profile/profile.service";
import { ResultDataPointMapping } from "../model/mappings";
import { Result } from "../model/result";
import { ScreenType } from "../model/result-lookup";
import { ResultLookup } from "../model/result-lookup";
import { ResultsService } from "../results.service";

@Component({
  selector: "app-result-detail",
  templateUrl: "./result-detail.component.html",
  styleUrls: ["./result-detail.component.css"],
})
export class ResultDetailComponent implements OnInit, OnDestroy {
  localizeReady = false;
  resultLoaded = false;
  combinedReady = false;
  readyForRender = false;
  hasError = false;
  subscriptions: ISubscription[] = [];
  results: Result[] = [];
  resultKey: string;
  result: Result;
  currentUser: Person = null;
  mappings: ResultDataPointMapping[] = [];
  displayRisk = false;
  resultLookUp: ResultLookup;
  currentUserProfile: Profile = null;
  canDownloadVaxProof = false
  vaxCheckRan = false;
  constructor(
    private route: ActivatedRoute,
    private _localizeService: LocalizeService,
    private _resultService: ResultsService,
    private _dateFormatPipe: DateFormatPipe,
    private _personService: PersonService,
    private _router: Router,
    private _wcsMessageService: WCSMessageService,
    private _profileService: ProfileService,
    private _servicesService: ServicesService
  ) { }

  private onError(errorMessage) {
    console.log(errorMessage);
    this.hasError = true;
  }

  print($event) {
    $event.preventDefault();
    window.print();
  }

  checkReady() {
    const ready =
      this.localizeReady &&
      this.resultLoaded &&
      this.combinedReady &&
      this.currentUser !== null &&
      this.currentUserProfile !== null
      && this.vaxCheckRan;

    if (ready) {
      const screenTypes = this.resultLookUp.screenTypes;

      const thisType: ScreenType = _.find(screenTypes, (st: ScreenType) => {
        return (
          st.screenTypeId === this.result.screenData.screenTypeId &&
          st.languageCode.toLowerCase() === this.currentUser.locale.languageLocale
        );
      });

      if (thisType) {
        this.result.screenData.screenTypeName = thisType.displayName;
      }
    }

    this.readyForRender = ready;
  }

  getLabGroupResults(group): ResultDataPointMapping[] {
    const labGroupResults = _.filter(
      this.mappings,
      (map: ResultDataPointMapping) => {
        return map.displayInfo.groupName === group;
      }
    );
    const orderBy = _.orderBy(labGroupResults, (map: ResultDataPointMapping) => {
      return map.displayInfo.fieldSort;
    });
    return orderBy;
  }
  getShowGroupTitle(group): boolean {
    const groupTitle = _.filter(this.mappings, (map: ResultDataPointMapping) => {
      return map.displayInfo.groupName === group;
    });
    if (groupTitle && groupTitle.length > 0) {
      return true;
    }
    return false;
  }

  display(map: ResultDataPointMapping) {
    if (map.displayInfo.secondaryDisplay) {
      return false;
    }
    return true;
  }

  loadData() {
    this.subscriptions.push(
      this._localizeService.isReady.subscribe(
        (isReady) => {
          this.localizeReady = true;
          this.checkReady();
        },
        (error) => this.onError(error)
      )
    );

    this.subscriptions.push(
      this._personService.currentPerson.subscribe((currentPerson) => {
        if (currentPerson) {
          this.currentUser = currentPerson;
          this.checkCanDownloadVaxProof(this.resultKey, this.currentUser.participantKey)
          this.checkReady();
        }
      })
    );

    this.subscriptions.push(
      this._profileService.getUserProfile().subscribe((profile) => {
        if (profile) {
          this.currentUserProfile = profile;
          this.checkReady();
        }
      })
    )

    this.subscriptions.push(
      this._wcsMessageService.message.subscribe((message) => {
        if (message && message.type == WCSMessageTypes.PhysicianRiskAlert) {
          this.displayRisk = true;
        }
      })
    );
    this.subscriptions.push(
      this._resultService.results.subscribe((resultsContainer) => {
        if (resultsContainer) {
          this.results = resultsContainer.results;
          const filtered = this.results.filter((result) => {
            return result.screenData.key === this.resultKey;
          });

          if (filtered.length) {
            this.result = filtered[0];
            this.resultLoaded = true;
            this.checkReady();
          } else {
            this.onError("result not found");
          }
        }
      })
    );

    this.subscriptions.push(
      this._resultService.getCombined().subscribe((combined) => {
        const res: Result = _.find(combined[0].results, (result: Result) => {
          return result.screenData.key === this.resultKey;
        });
        this.resultLookUp = combined[1];
        this.mappings = this._resultService.getMappings(res, combined[1]);
        this.combinedReady = true;
        this.checkReady();
      })
    );

    this.subscriptions.push(
      this._resultService.resultError.subscribe((resultsError) => {
        if (resultsError) {
          this.onError(resultsError);
        }
      })
    );
    this.subscriptions.push(
      this._resultService.resultLookupError.subscribe((resultsError) => {
        if (resultsError) {
          this.onError(resultsError);
        }
      })
    );
  }

  getImmunizationRecord() {
    this._servicesService.getImmunization(this.resultKey, this.currentUser.participantKey);
  }

  ngOnInit() {
    const that = this;

    this.route.params.subscribe(function (params) {
      that.localizeReady = false;
      that.readyForRender = false;
      that.hasError = false;

      that.resultKey = params.key;
      that.loadData();
    });
  }
  checkCanDownloadVaxProof(screenDataKey, participantKey) {
    this._servicesService.canDownloadProof(screenDataKey, participantKey).subscribe(result => {
      this.canDownloadVaxProof = result;
      this.vaxCheckRan = true;
      this.checkReady()
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) { });
  }
}

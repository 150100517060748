import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';

import { WCSMessageService } from '../../../services/wcs-message/wcs.message.service';
import { WCSMessage, WCSMessageTypes } from '../../../services/wcs-message/wcs-message';
import { AlertModalComponent } from '../../../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { ResultDataPointMapping } from '../../model/mappings';
import { ExceptionCode, ReferenceRange } from '../../model/result-lookup';
import { ReferenceRangesComponent } from '../../reference-ranges/reference-ranges.component';
import { ResultsService } from '../../results.service';

@Component({
    selector: 'app-detail-line-item',
    templateUrl: './detail-line-item.component.html',
    styleUrls: ['./detail-line-item.component.css']
})
export class DetailLineItemComponent implements OnInit {
    @Input() exceptionCodes: ExceptionCode[];
    @Input() person: Person;
    @Input() map: ResultDataPointMapping;
    @Input() maps: ResultDataPointMapping[] = [];
    sendRiskUpChain = true;
    modalRef: NgbModalRef;

    constructor(private _modalService: NgbModal, private _localizeService: LocalizeService, private _resultsService: ResultsService, private _wcsMessageService: WCSMessageService) {}
    exc: ExceptionCode;
    displayDetail() {
        const thisVal = this.getValue(this.map);
        if (!this.map.displayInfo.secondaryDisplay && thisVal !== null && typeof thisVal !== 'undefined') {
            return true;
        } else {
            return null;
        }
    }

    getValue(map) {
        const valNumber = parseInt(map.resultProperty, 10);
        if (isNaN(valNumber) || valNumber >= 0) {
            return map.resultProperty;
        }
        this.exc = _.find(this.exceptionCodes, (excCode: ExceptionCode) => {
            return excCode.code === valNumber && excCode.languageCode.toLowerCase() === this._localizeService.locale;
        });
        return this.exc ? this.exc.description : map.resultProperty;
    }

    getSecondaryMap() {
        if (!this.map.displayInfo.combineWithField && !this.maps) {
            return null;
        } else {
            if (this.maps) {
                const map = this.map;
                return _.find(this.maps, function (mp: ResultDataPointMapping) {
                    return mp.displayInfo.fieldName === map.displayInfo.combineWithField;
                });
            }
        }
    }

    getRisk(map) {
        if (!this._resultsService.getRiskReferences(this.person, map).length) {
            return null;
        }
        const secondaryMap = this.getSecondaryMap();
        let currentRisk: ReferenceRange = null;
        if (!secondaryMap) {
            currentRisk = _.first(this._resultsService.getRiskReferences(this.person, map));
        } else {
            currentRisk = this._resultsService.getHigherRiskReference(this.person, map, secondaryMap);
        }
        if (this.sendRiskUpChain && currentRisk != null && _.includes(['#f26c64', '#b10318', '#ffc156', '#ffdd71'], currentRisk.riskColor)) {
            this.sendRiskUpChain = false;
            const riskMessage: WCSMessage = {
                data: currentRisk.riskColor,
                sender: null,
                type: WCSMessageTypes.PhysicianRiskAlert
            };
            this._wcsMessageService.sendMessage(riskMessage);
        }
        return currentRisk;
    }

    getReferenceRange(map) {
        if (!map.referenceRanges.length) {
            return null;
        }
        return this._resultsService.getReferenceRange(this.person, map.referenceRanges);
    }

    getTitle(map) {
        return map.displayInfo.localizedName;
    }

    getUnits(map) {
        return map.displayInfo.units;
    }

    displayAllRanges(map) {
        this.modalRef = this._modalService.open(ReferenceRangesComponent, {
            backdrop: 'static'
        });
        const filter = _.filter(this.getReferenceRange(map), (ref: ReferenceRange) => {
            if (ref.rangeDisplay && ref.fastingState && ref.fastingState === this.map.fastingState) {
                return ref;
            }
            if (ref.rangeDisplay && !ref.fastingState) {
                return ref;
            }
        });
        this.modalRef.componentInstance.referenceRanges = filter;
    }

    getNote(map) {
        return this.replaceNewlines(map.displayInfo.notes);
    }

    showNote(map) {
        return map && map.displayInfo && map.displayInfo.notes && map.displayInfo.notes.length;
    }

    showIcon(map) {
        const displayRanges = _.filter(this.getReferenceRange(map), (range: ReferenceRange) => {
            if (range.rangeDisplay) {
                return range;
            }
        });
        if (displayRanges && displayRanges.length > 0) {
            return true;
        }

        return false;
    }

    replaceNewlines(txt) {
        return txt.replace(/\n/g, '<br />');
    }

    private displayIcon(map): boolean {
        const displayRanges = _.filter(this.getReferenceRange(map), (range: ReferenceRange) => {
            if (range.rangeDisplay) {
                return range;
            }
        });
        if (displayRanges && displayRanges.length > 0) {
            return true;
        }

        return false;
    }

    clickBlurb(map) {
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        const msg = this.replaceNewlines(this._localizeService.get(map.displayInfo.localizedBlurb, 'label'));
        this.modalRef.componentInstance.msg = msg;
        this.modalRef.componentInstance.header = this._localizeService.get(this.getTitle(map), 'label');
    }

    showBlurb(map: ResultDataPointMapping) {
        let isValidBlurb = true;
        if (map && this._localizeService.get(map.displayInfo.localizedBlurb, 'label') !== map.displayInfo.localizedBlurb) {
            const msg = this.replaceNewlines(this._localizeService.get(map.displayInfo.localizedBlurb, 'label'));
            isValidBlurb = msg === null ? false : msg.trim().length == 0 || msg.trim() === '' ? false : true;
        } else {
            isValidBlurb = false;
        }
        return isValidBlurb;
    }

    ngOnInit() {}
}

<div *ngIf="isRenderReady">
    <div class="row">
        <div class="col-xs-12 mb-2">
            <div [innerHTML]="personWidget.descriptionLanguage | safeHtml"></div>
        </div>
    </div>

    <form [formGroup]="attestationForm" id="formId-AttestationWidget" *ngIf="attestation">
        <div class="form-group row custom-card">
            <div class="col-xs-12">
                <div [innerHTML]="attestation.introText | safeHtml"></div>
                <div class="btn-group grp-btn-container" role="group">
                    <input class="btn-check" type="radio" value="false" id="actionId-NoAttestation" name="userResponse" formControlName="userResponse" />
                    <label *ngIf="attestation.allowNoResponse" class="btn btn-outline-primary" for="actionId-NoAttestation">
                        <span [innerHTML]="attestation.noText | safeHtml"></span>
                    </label>
                    <input class="btn-check" type="radio" value="true" id="actionId-YesAttestation" name="userResponse" formControlName="userResponse" />
                    <label *ngIf="attestation.allowYesResponse" class="btn btn-outline-primary" for="actionId-YesAttestation">
                        <span [innerHTML]="attestation.yesText | safeHtml"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row mobile-field" *ngIf="personWidget.footerLanguage">
            <div class="col-sm-12" *ngIf="personWidget.footerLanguage">
                <p [innerHTML]="personWidget.footerLanguage | safeHtml"></p>
            </div>
        </div>

        <div class="form-group">
            <div class="alert alert-danger" *ngIf="showError">
                <button type="button" class="close" id="actionId-CloseAttestationWidgetErrorAlert" aria-label="Close" (click)="closeError()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <span id="errorAlertId-AttestationInvalidForm" [innerHTML]="'message.invalidform' | lesCustomLocalize : '' : null : null : tagOptions"></span>
            </div>

            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li *ngIf="personWidget.position > 0" [ngClass]="{ 'page-item': true, disabled: personWidget.position == 0 || !attestationForm.valid }">
                        <a class="les-widget-link" id="actionId-PreviousAttestationWidget" (click)="onPreviousButtonClick()" [attr.disabled]="personWidget.position == 0 ? 'true' : null">Previous</a>
                    </li>
                    <li [ngClass]="{ 'page-item': true, disabled: !attestationForm.valid }">
                        <a class="les-widget-link" id="actionId-NextAttestationWidget" (click)="onNextButtonClick(attestationForm.valid)">{{ 'button.next' | lesCustomLocalize : '' : null : null : tagOptions }}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </form>
</div>

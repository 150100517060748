import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { Company } from '../../shared/company/company';
import { Globals } from '../../shared/globals';
import { CustomizationLanguage } from '../../shared/localize/customized-data';
import { LocalizeService } from '../../shared/localize/localize.service';
import { TextOverride } from '../../shared/localize/text-override';
import { Person } from '../../shared/person/person';
import { ColumnSetting } from '../../shared/wcs-table/column-setting';
import { WcsTableComponent } from '../../shared/wcs-table/wcs-table.component';
import { CustomizeService } from '../customize.service';
import { ModifyTextComponent } from '../modify-text/modify-text.component';

@Component({
  selector: 'app-customizable-text',
  templateUrl: './customizable-text.component.html',
  styleUrls: ['./customizable-text.component.css']
})
export class CustomizableTextComponent implements OnInit, OnDestroy {
  @ViewChild(WcsTableComponent) tableComponent: WcsTableComponent;

  _modalRef: NgbModalRef;

  _currentUser: Person;
  @Input() set currentUser(val: Person) {
    this._currentUser = val;
    this.checkReady();
  }

  filteredOverrides: TextOverride[];
  filter: string = null;

  get currentUser() {
    return this._currentUser;
  }

  _company: Company;
  @Input() set company(val: Company) {
    this._company = val;

    this.getOverrides();

    this.checkReady();
  }

  get company() {
    return this._company;
  }

  overrides: TextOverride[];
  subscriptions: ISubscription[] = [];
  readyForRender = false;
  localizeReady = false;
  overridesLoaded = false;
  languagesLoaded = false;
  languagesSupported: CustomizationLanguage[];

  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'category',
      headerLocalizeProp: 'category',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'name',
      headerLocalizeProp: 'name',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'description',
      headerLocalizeProp: 'description',
      showOnMobile: true,
      sortable: false
    },
    {
      valueProp: 'localeName',
      headerLocalizeProp: 'locale',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'default',
      headerLocalizeProp: 'default',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'text',
      headerLocalizeProp: 'override',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      sortable: false,
      link: {
        text: 'modify',
        onClick: this.override
      },
      thisContext: this
    }
  ];

  constructor(private _modalService: NgbModal, private _localizeService: LocalizeService, private _customizeService: CustomizeService) { }

  private checkReady() {
    if (this.localizeReady && this.currentUser && this._company && this.languagesLoaded && this.overridesLoaded) {
      this.readyForRender = true;
    }
  }

  filterResults() {
    if (this.filter === '') {
      this.filteredOverrides = this.overrides;
    } else if (this.filter) {
      this.filteredOverrides = _.filter(this.overrides, (ovr: TextOverride) => {
        return ovr.default.toUpperCase().indexOf(this.filter.toUpperCase()) !== -1 ||
          ovr.category.toUpperCase().indexOf(this.filter.toUpperCase()) !== -1 ||
          ovr.name.toUpperCase().indexOf(this.filter.toUpperCase()) !== -1 ||
          (ovr.text && ovr.text.toUpperCase().indexOf(this.filter.toUpperCase()) !== -1);
      });
    }

    this.tableComponent.detectChanges(true);
  }

  override(txt: TextOverride) {
    this._modalRef = this._modalService.open(ModifyTextComponent, {
      backdrop: 'static'
    });

    this._modalRef.componentInstance.textOverride = txt;

    this._modalRef.result.then((ovr: TextOverride) => {
      if (ovr && ovr.key) {
        // txt is from filteredoverrides
        txt.key = ovr.key;
        txt.text = ovr.text;

        // need to also update overrides...
        _.map(this.overrides, (override: TextOverride) => {
          if (override.token === ovr.token && override.languageLocale === ovr.languageLocale) {
            override.key = ovr.key;
            override.text = ovr.text;
          }
        });

        this.tableComponent.detectChanges();
      }
    });
  }

  private getOverrides() {
    if (this.localizeReady && this._company) {
      this.subscriptions.push(this._customizeService.fetchOverrides(this._company.key).subscribe(overrides => {
        if (overrides) {
          this.overridesLoaded = true;
          this.filterLanguagesAsNecessary();
          this.checkReady();
        }
      }));

      this.subscriptions.push(this._customizeService.fetchMisc(this._company.key).subscribe(misc => {
        if (misc) {
          this.languagesSupported = misc.customizationLanguages;
          this.languagesLoaded = true;

          this.filterLanguagesAsNecessary();

          this.checkReady();
        }
      }));
    }
  }

  private filterLanguagesAsNecessary() {
    if (this.overridesLoaded && this.languagesLoaded) {
      const esmx = _.find(this.languagesSupported, (lang: CustomizationLanguage) => {
        return lang.languageLocale === Globals.Locales.MexicoSpanish;
      });

      if (!esmx) {
        this.overrides = _.filter(this.overrides, (over: TextOverride) => {
          return over.languageLocale !== Globals.Locales.MexicoSpanish;
        });
      }

      this.filteredOverrides = this.overrides;
    }
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;

        this.getOverrides();

        this.checkReady();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

import { Component, OnDestroy,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AccessMode } from '../../shared/access-mode';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { User } from '../../shared/user/user';
import { UserService } from '../../shared/user/user.service';
import { CoachEditComponent } from '../coach-edit/coach-edit.component';

@Component({
  selector: 'app-coaching-admin-dashboard',
  templateUrl: './coaching-admin-dashboard.component.html',
  styleUrls: ['./coaching-admin-dashboard.component.css']
})
export class CoachingAdminDashboardComponent implements OnInit, OnDestroy {
  allAccessMode = AccessMode.CoachingAdminDashboard;
  secureMessagingMode = AccessMode.CoachingAdminDashboard;
  readyForRender = false;
  subscriptions: ISubscription[] = [];
  modalRef: NgbModalRef;
  currentUser: Person;

  sectionValues = Globals.SectionValues;
  sectionToShow = Globals.SectionValues.Coaches;

  constructor(private _localizeService: LocalizeService, private _personService: PersonService, private _router: Router,
              private _userService: UserService, private _modalService: NgbModal) { }

  changeSection(section) {
    this.sectionToShow = section;
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe( ready => {
      this.readyForRender = ready;
    }));

    this.subscriptions.push(this._personService.currentPerson.subscribe( person => {
      if (person && !person.isCoachAdmin) {
        this._router.navigate(['home']);
      } else {
        this.currentUser = person;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}

export class ApplicationContent {
    key: string;
    applicationKey: string;
    applicationContentCategoryKey: string;
    programServiceTypeKey: string;
    programServiceKey: string;
    tag: string;
    displayName: string;
    description: string;
    isCompanyLevelContent: boolean;
    isCustomizable: boolean;
    maxCustomLength?: number;
    application: Application;
    applicationContentCategory: ApplicationContentCategory;
    applicationContentTranslations: ApplicationContentTranslation[];
}
export class Application {
    key: string;
    name: string;
}
export class ApplicationContentCategory {
    key: string;
    category: string;
    createdOn: Date;
}
export class ApplicationContentTranslation {
    key: string;
    applicationContentKey: string;
    locale: string;
    translation: string;
    clientCustomizations: ApplicationContentTranslationCustomization[];
}
export class ApplicationContentTranslationCustomization {
    key: string;
    ApplicationContentTranslationKey: string;
    translation: string;
}
export class AppicationContentSearch {
    applicationKey = '036DC096-5EDA-4E2B-A512-8D60D1BA2979';
    programServiceTypeKey: string;
    programServiceKey: string;
    companyKey: string;
    isCompanyLevelContent?: boolean;
    IsCustomizable?: boolean;
}
export class UITranslationView {
    key: string;
    category: string;
    translation: string;
    programServiceKey: string;
    programServiceTypeKey: string;
    tag: string;
    companyKey: string;
    locale: string;
}

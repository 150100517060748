import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AccessMode } from '../../../shared/access-mode';
import { AlertModalComponent } from '../../../shared/alert-modal/alert-modal.component';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { ColumnSetting } from '../../../shared/wcs-table/column-setting';
import { WcsTableComponent } from '../../../shared/wcs-table/wcs-table.component';
import { NewMessageService } from '../../secure-messaging/new-message.service';
import { NewSecureMessage } from '../../secure-messaging/new-secure-message';
import { SecureMessageAccessRequest } from '../secure-message-access-request';
import { SecureMessageAccessRequestService } from '../secure-message-access-request.service';
import { SecureMessageAccessRequestStatus } from '../secure-message-access-request-status';

@Component({
  selector: 'app-secure-message-access-request-list-component',
  templateUrl: './secure-message-access-request-list-component.html',
  styleUrls: ['./secure-message-access-request-list-component.css']
})
export class SecureMessageAccessRequestListComponent implements OnInit, OnDestroy {
  @ViewChild(WcsTableComponent) tableComponent: WcsTableComponent;

  @Input() mode: AccessMode;
  @Input() coach: Person;
  @Input() participant: Person;
  @Output() hasAllAccessToView: EventEmitter<boolean> = new EventEmitter<boolean>();

  AccessMode = AccessMode;

  readyForRender = false;
  allAccessRequests: SecureMessageAccessRequest[] = [];
  hasError = false;
  currentUser: Person;
  allAccessStatus = SecureMessageAccessRequestStatus;
  modalRef: NgbModalRef;
  subscriptions: ISubscription[] = [];

  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'participant',
      headerLocalizeProp: 'participant',
      showOnMobile: true,
      format: 'link',
      link: {
        text: undefined,
        onClick: this.viewParticipant
      },
      doShowFns: [{
        fn: this.getIsCoachingPage,
        val: true
      }],
      thisContext: this,
      sortable: true
    },
    {
      valueProp: 'coach',
      headerLocalizeProp: 'coach',
      showOnMobile: true,
      doShowFns: [{
        fn: this.getIsCoachingDashboard,
        val: false
      }],
      thisContext: this,
      sortable: true
    },
    {
      valueProp: 'company',
      headerLocalizeProp: 'company',
      showOnMobile: true,
      doShowFns: [{
        fn: this.getIsParticipantPage,
        val: false
      }],
      thisContext: this,
      sortable: true
    },
    {
      valueProp: 'requestDate',
      headerLocalizeProp: 'dateofrequest',
      showOnMobile: true,
      format: 'datetime',
      sortable: true
    },
    {
      valueProp: 'status',
      headerLocalizeProp: 'status',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      doShowFns: [{
        fn: this.getIsCoachingDashboard,
        val: true
      }],
      thisContext: this,
      sortable: false,
      format: 'link',
      links: [{
        text: 'message',
        onClick: this.messageParticipant,
        doShowFn: {
          fn: this.showMessageParticipant,
          val: true
        }
      },
      {
        text: 'rerequest',
        onClick: this.rerequest,
        doShowFn: {
          fn: this.showReRequest,
          val: true
        }
      },
      {
        text: 'cancel',
        onClick: this.cancel,
        doShowFn: {
          fn: this.showCancel,
          val: true
        }
      }
      ]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      doShowFns: [{
        fn: this.getIsParticipantView,
        val: true
      }],
      thisContext: this,
      sortable: false,
      format: 'link',
      links: [
        {
          text: 'message',
          onClick: this.messageCoach
        },
        {
          text: 'grant',
          onClick: this.grant,
          doShowFn: {
            fn: this.showGrant,
            val: true
          }
        },
        {
          text: 'revoke',
          onClick: this.revoke,
          doShowFn: {
            fn: this.showRevoke,
            val: true
          }
        }
      ]
    }
  ];

  constructor(private _allAccessService: SecureMessageAccessRequestService, private _personService: PersonService,
    private _newMessageService: NewMessageService, private _router: Router, private _modalService: NgbModal,
    private _localizeService: LocalizeService) { }

  getIsCoachingPage() {
    return this.mode === AccessMode.CoachingDashboard || this.mode === AccessMode.CoachingAdminDashboard;
  }

  getIsCoachingDashboard() {
    return this.mode === AccessMode.CoachingDashboard;
  }

  getIsParticipantView() {
    return this.mode === AccessMode.ParticipantView;
  }

  getIsParticipantPage() {
    return this.mode === AccessMode.ParticipantDetail || this.mode === AccessMode.ParticipantView;
  }

  showCoach() {
    return this.mode !== AccessMode.CoachingDashboard;
  }

  showReRequest(req: SecureMessageAccessRequest): boolean {
    let doShow = true;

    if (req.status !== this.allAccessStatus.Rejected || this.currentUser.userKey !== req.requestingUser.key) {
      return false;
    }

    this.allAccessRequests.forEach(function (r) {
      if (r.requestedUser.key === req.requestedUser.key && r.status === SecureMessageAccessRequestStatus.Pending) {
        doShow = false;
      }
    });
    return doShow;
  }

  showCancel(req: SecureMessageAccessRequest) {
    return req.status === SecureMessageAccessRequestStatus.Pending && this.currentUser.userKey === req.requestingUser.key;
  }

  showGrant(req: SecureMessageAccessRequest) {
    return this.getIsParticipantView() && req.status === SecureMessageAccessRequestStatus.Pending;
  }

  showRevoke(req: SecureMessageAccessRequest) {
    return this.getIsParticipantView() && req.status === SecureMessageAccessRequestStatus.Granted;
  }

  showMessageParticipant(req: SecureMessageAccessRequest) {
    return this.mode === AccessMode.CoachingDashboard && this.currentUser.userKey === req.requestingUser.key;
  }

  participantDetail(key: string) {
    if (!this.currentUser.isParticipant) {
      this._router.navigate(['participant/' + key]);
    }
  }

  viewParticipant(req: SecureMessageAccessRequest) {
    if (req.requestedUser && req.requestedUser.key) {
      this._router.navigate(['participant', req.requestedUser.key]);
    }
  }

  messageParticipant(req: SecureMessageAccessRequest) {
    const newMsg = new NewSecureMessage();
    newMsg.isGeneralInbox = false;
    newMsg.receivingUserKey = req.requestedUser.key;
    newMsg.receivingUserFirstName = req.requestedUser.firstName;
    newMsg.receivingUserLastName = req.requestedUser.lastName;
    newMsg.receivingUserCompanyName = req.requestedUser.companyName;
    newMsg.parentMessageKey = null;
    newMsg.regardingKey = null;
    this._newMessageService.setCreateNewMessage(newMsg);
  }

  messageCoach(req: SecureMessageAccessRequest) {
    const newMsg = new NewSecureMessage();
    newMsg.isGeneralInbox = false;
    newMsg.receivingUserKey = req.requestingUser.key;
    newMsg.receivingUserFirstName = req.requestingUser.firstName;
    newMsg.receivingUserLastName = req.requestingUser.lastName;
    newMsg.receivingUserCompanyName = req.requestingUser.companyName;
    newMsg.parentMessageKey = null;
    newMsg.regardingKey = null;
    this._newMessageService.setCreateNewMessage(newMsg);
  }

  rerequest(key: string) {
    this._allAccessService.createAllAccessRequest(key)
      .subscribe(req => {
        this.allAccessRequests.unshift(req);
        this.onTableChanges()
      });
  }

  cancel(req: SecureMessageAccessRequest) {
    this._allAccessService.cancelAllAccessRequest(req.key)
      .subscribe(resp => {
        req.status = SecureMessageAccessRequestStatus.Canceled;
        this.onTableChanges()
      });
  }

  grant(req: SecureMessageAccessRequest) {
    this._allAccessService.grantAllAccessRequest(req.key)
      .subscribe(resp => {
        req.status = SecureMessageAccessRequestStatus.Granted;
        this.onTableChanges()
      });
  }

  revoke(req: SecureMessageAccessRequest) {
    this._allAccessService.rejectAllAccessRequest(req.key)
      .subscribe(resp => {
        req.status = SecureMessageAccessRequestStatus.Rejected;
        this.onTableChanges()
      });
  }

  showRequestAllAccess() {
    return this.mode === AccessMode.ParticipantDetail && this.currentUser.isCoach;
  }

  requestAllAccess() {
    if (this.mode !== AccessMode.ParticipantDetail || !this.participant.userKey) {
      return;
    }

    this.subscriptions.push(this._allAccessService.createAllAccessRequest(this.participant.userKey)
      .subscribe(r => {
        this.getAllAccessRequests();
      }));
  }

  private getAllAccessRequests() {
    let key,
      isCoach = false;

    if (this.participant && this.participant.userKey) {
      key = this.participant.userKey;
    } else if (this.coach && this.coach.userKey) {
      key = this.coach.userKey;
      isCoach = true;
    }

    this._allAccessService.getAllAccessRequests(key, isCoach)
      .subscribe(requests => {
        this.allAccessRequests = requests;
        this.readyForRender = true;

        this.hasAllAccessToView.emit(requests.length > 0);

        this.onTableChanges()
      },
        error => {
          this.onError();
        });
  }

  private onError(msg: string = null) {
    this.modalRef = this._modalService.open(AlertModalComponent, {
      backdrop: 'static'
    });
    this.modalRef.componentInstance.msg = msg ? msg : this._localizeService.get('unknownerror', 'message');
    this.hasError = true;
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(data => {
      if (data) {
        this.currentUser = data;
        this.getAllAccessRequests();
      }
    },
      error => {
        this.onError();
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
  private onTableChanges() {
    if (this.readyForRender && this.tableComponent) {
      this.tableComponent.detectChanges();
    }
  }
}

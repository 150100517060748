import { Component, OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Params,Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AccessMode } from '../../shared/access-mode';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { SecureMessageAccessRequest } from '../secure-message-access-request/secure-message-access-request';
import { SecureMessageAccessRequestService } from '../secure-message-access-request/secure-message-access-request.service';

@Component({
  selector: 'app-participant-view',
  templateUrl: './participant-view.component.html',
  styleUrls: ['./participant-view.component.css']
})
export class ParticipantViewComponent implements OnInit, OnDestroy {
  accessMode = AccessMode.ParticipantView;
  secureMessagingMode = AccessMode.ParticipantView;
  localizeReady = false;
  readyForRender = false;
  hasError = false;
  hasAllAccessRequestsToShow = false;
  allAccessPending: SecureMessageAccessRequest[];
  modalRef: NgbModalRef;
  subscriptions: ISubscription[] = [];
  participant: Person = null;
  sectionValues = Globals.SectionValues;
  sectionToShow = Globals.SectionValues.Appointments;

  constructor(private _allAccessService: SecureMessageAccessRequestService, private _localizeService: LocalizeService, private _modalService: NgbModal,
    private _personService: PersonService, private _router: Router) {
  }

  grant(req: SecureMessageAccessRequest) {
    this._allAccessService.grantAllAccessRequest(req.key);
    this.removeRequest(req);
  }

  reject(req: SecureMessageAccessRequest) {
    this._allAccessService.rejectAllAccessRequest(req.key);
    this.removeRequest(req);
  }

  allAccessInfo() {
    this.modalRef = this._modalService.open(AlertModalComponent, {
      backdrop: 'static'
    });
    this.modalRef.componentInstance.header = this._localizeService.get('info', 'label');
    this.modalRef.componentInstance.msg = this._localizeService.get('allaccessexplanation', 'message');
  }

  hasAllAccess(does) {
    this.hasAllAccessRequestsToShow = does;
  }

  changeSection(section) {
    this.sectionToShow = section;
  }

  private removeRequest(req: SecureMessageAccessRequest) {
    this.allAccessPending = this.allAccessPending.filter(r => r.key !== req.key);
  }

  private checkReadyForRender() {
    this.readyForRender = this.localizeReady && this.participant !== null && !this.hasError;
  }

  private onError() {
    this.hasError = true;
  }

  ngOnInit() {
    this.subscriptions.push(this._allAccessService.getAllAccessRequestsPending()
      .subscribe(reqs => {
        this.allAccessPending = reqs;
      },
        error => this.onError()));

    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
        this.checkReadyForRender();
      }
    },
      error => this.onError()));

    this.subscriptions.push(this._personService.currentPerson.subscribe(person => {
      if (person && !person.isParticipant) {
        this._router.navigate(['home']);
      } else {
        this.participant = person;
        this.checkReadyForRender();
      }
    },
      error => this.onError()));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

<div *ngIf="readyForRender">
    <h4 class="primary-text" id="titleId-Appointments">
        {{ 'appointments' | lesCustomLocalize : 'label' }}
        <small class="text-muted">
            <button class="btn tertiary-bg btn-primary btn-sm" (click)="newAppt()" id="actionId-BookAnAppointment" *ngIf="showNewAppt()">
                <i class="fa fa-plus-square" aria-hidden="true"></i> {{ 'bookcoachappointment' | lesCustomLocalize : 'label' }}
            </button>
        </small>
    </h4>

    <div class="form-group" id="formSelectId-AppointmentDateFilter" *ngIf="mode !== accessMode.ParticipantView && mode !== accessMode.ParticipantDetail">
        <select class="form-control" name="dateFilter" [(ngModel)]="dateFilter" (change)="getAppts()">
            <option value="today">{{ 'today' | lesCustomLocalize : 'label' }}</option>
            <option value="tomorrow">{{ 'tomorrow' | lesCustomLocalize : 'label' }}</option>
            <option value="yesterday">{{ 'yesterday' | lesCustomLocalize : 'label' }}</option>
            <option value="last7days">{{ 'lastxdays' | lesCustomLocalize : 'label' : '7' }}</option>
            <option value="next7days">{{ 'nextxdays' | lesCustomLocalize : 'label' : '7' }}</option>
            <option value="specificdate">{{ 'specificdate' | lesCustomLocalize : 'label' }}</option>
        </select>
    </div>

    <div class="form-group" *ngIf="dateFilter === 'specificdate'">
        <div class="input-group">
            <input class="form-control" id="formSelectId-AppointmentSpecificDateFilter" placeholder="yyyy-mm-dd" name="specificDate" [(ngModel)]="specificDate" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="getAppts()" />
            <button class="tertiary-bg input-group-addon" id="actionId-AppointmentDateFilterToggle" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <app-wcs-table
        [records]="appts"
        [settings]="displaySettings"
        [currentUser]="currentUser"
        [currentSortProp]="'startDate'"
        [currentSortDirection]="'desc'"
        [isError]="hasError"
        [isLoading]="fetchingRecords"
        [useBootstrapResponsiveTable]="true"
        [htmlId]="'Appointments'"
    ></app-wcs-table>
</div>

<app-error *ngIf="hasError" [htmlId]="'Appointments'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loadingx' | lesCustomLocalize : 'label' : ('appointments' | lesCustomLocalize : 'label')"></app-loading>

import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { CancelReason } from '../../model/api-appointment';
import { SchedulerService } from '../../scheduler.service';

@Component({
  selector: 'app-modify-appointment',
  templateUrl: './modify-appointment.component.html',
  styleUrls: ['./modify-appointment.component.css']
})
export class ModifyAppointmentComponent implements OnInit, OnDestroy {
  @Input() currentUser: Person;
  @Input() appointmentKey: string;
  @Input() appointmentUserKey: string;
  subscriptions: ISubscription[] = [];
  cancelReasons: CancelReason[] = [];

  selectedCancelReason: CancelReason = null;

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService,
    private _schedulerService: SchedulerService, private _router: Router) {
  }

  notCurrentUser(): boolean {
    return this.currentUser.userKey !== this.appointmentUserKey;
  }

  modifyAppointment() {
    // First cancel the appointment
    if (this.notCurrentUser() && !this.selectedCancelReason) {
      alert(this._localizeService.get('cancelappointmentreasonrequired', 'message'));
      return;
    }
    this.subscriptions.push(this._schedulerService.cancelAppointment(this.appointmentKey,
        this.selectedCancelReason ? this.selectedCancelReason.key : null).subscribe(() => {
          // Then redirect to the scheduler
          this.activeModal.close('modified');
          this._router.navigate(['/scheduler']);
    }));
  }

  ngOnInit() {
    if (this.notCurrentUser()) {
      this.subscriptions.push(this._schedulerService.getCancelReasons().subscribe(reasons => {
        this.cancelReasons = reasons;
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}

<ng-container *ngIf="readyForRender && !hasError && activeAppointments.length">
    <div class="card">
        <div class="card-body">
            <h5 class="primary-text" id="titleId-UpcomingAppointment(s)">
                {{ 'upcomingappointments' | lesCustomLocalize : 'label' }}
                <a href="#" id="actionId-ShowAppointments" *ngIf="!showExistingAppointments" class="pl-4" (click)="toggleExistingAppointments($event)"
                    ><small class="btn">{{ 'showtheappointments' | lesCustomLocalize : 'label' }}</small></a
                >
                <a href="#" id="actionId-HideAppointments" *ngIf="showExistingAppointments" class="pl-4" (click)="toggleExistingAppointments($event)"
                    ><small class="btn">{{ 'hidetheappointments' | lesCustomLocalize : 'label' }}</small></a
                >
            </h5>
            <div *ngIf="showExistingAppointments">
                <app-wcs-table
                    [records]="activeAppointments"
                    [htmlId]="'UpcomingAppointments'"
                    [settings]="displaySettings"
                    [currentUser]="currentUser"
                    [currentSortProp]="'time'"
                    [currentSortDirection]="'asc'"
                    [pageSize]="6"
                    [useBootstrapResponsiveTable]="false"
                ></app-wcs-table>

                <a *ngIf="!otherUser" id="actionId-ViewAllAppointments" routerLink="/appointments"
                    ><small class="btn btn-link">{{ 'viewallappointments' | lesCustomLocalize : 'label' }}</small></a
                >
                <a *ngIf="otherUser" id="actionId-ViewAllAppointments" routerLink="/appointments/{{ otherUser.userKey }}"
                    ><small class="btn btn-link">{{ 'viewallappointments' | lesCustomLocalize : 'label' }}</small></a
                >
            </div>
        </div>
    </div>
</ng-container>

<app-error *ngIf="hasError" [htmlId]="'UpcomingAppointments'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

import { Component, OnDestroy,OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal,NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { Gender } from '../../shared/gender/gender';
import { Locale } from '../../shared/localize/locale';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { DaySelection, MonthSelection, YearSelection } from '../../shared/profile/birthdate-selections';
import { Profile } from '../../shared/profile/profile';
import { ProfileService } from '../../shared/profile/profile.service';
import { ProfilePut } from '../../shared/profile/profile-put';
import { State } from '../../shared/state/state';
import { TimeZone } from '../../shared/time-zone/time-zone';
import { CustomValidators } from '../../shared/validators';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit, OnDestroy {
    readyForRender = false;
    submitDisabled = false;
    profile: Profile;
    updatedProfile: ProfilePut;
    currentUser: Person;
    timeZoneList: TimeZone[];
    stateList: State[];
    localeList: Locale[];
    genderList: Gender[];
    birthMonthRange: MonthSelection[] = [];
    birthDayRange: DaySelection[] = [];
    birthYearRange: YearSelection[] = [];
    userInformationForm: FormGroup;
    settingsForm: FormGroup;

    subscriptions: ISubscription[] = [];
    modalRef: NgbModalRef;

    // Edit toggle state and labels
    userInformationEditToggle = false;
    userSettingsEditToggle = false;
    userInformationEditToggleLabel = this._localizeService.get('edit', 'label');
    userSettingsEditToggleLabel = this._localizeService.get('edit', 'label');

    constructor(private _profileService: ProfileService, private _personService: PersonService, private _metadataService: MetadataService, private _modalService: NgbModal, private _localizeService: LocalizeService, private formBuilder: FormBuilder) {
        // Initialize objects
        this.loadDateDropDowns();
        this._metadataService.getTimeZones();
        this._metadataService.getStates();
    }

    private loadDateDropDowns() {
        for (let iMonth = 0; iMonth <= 12; iMonth++) {
            const monthRange = new MonthSelection();
            if (iMonth === 0) {
                monthRange.month = '';
                monthRange.desc = 'Select';
            } else {
                monthRange.month = (iMonth - 1).toString();
                monthRange.desc = iMonth.toString();
            }
            this.birthMonthRange.push(monthRange);
        }

        for (let iDay = 0; iDay <= 31; iDay++) {
            const dayRange = new DaySelection();
            if (iDay === 0) {
                dayRange.day = '';
                dayRange.desc = 'Select';
            } else {
                dayRange.day = iDay.toString();
                dayRange.desc = iDay.toString();
            }
            this.birthDayRange.push(dayRange);
        }

        const currDate = new Date();
        const currYear = currDate.getFullYear() - 18;
        const endYear = currDate.getFullYear() - 120;

        let yearRange = new YearSelection();
        yearRange.year = '';
        yearRange.desc = 'Select';
        this.birthYearRange.push(yearRange);

        for (let iYear = currYear; iYear >= endYear; iYear--) {
            yearRange = new YearSelection();
            yearRange.year = iYear.toString();
            yearRange.desc = iYear.toString();
            this.birthYearRange.push(yearRange);
        }
    }

    toggleUserInformationEdit(): void {
        if (this.userInformationEditToggle) {
            this.loadUserInformationForm();

            this.userInformationEditToggle = false;
        } else {
            this.userInformationEditToggle = true;
            this.userSettingsEditToggle = false;
        }
    }

    loadUserInformationForm(): void {
        const userInformationForm = this.userInformationForm;
        const controlFirstName = userInformationForm.get('firstName');
        const controlLastName = userInformationForm.get('lastName');
        const controlEmail = userInformationForm.get('email');

        const controlEmployeeID = userInformationForm.get('employeeID');

        const controlGender = userInformationForm.get('gender');

        controlFirstName.setValue(this.updatedProfile.firstName);
        controlFirstName.setErrors(null);
        controlFirstName.markAsPristine();
        controlFirstName.markAsUntouched();

        controlLastName.setValue(this.updatedProfile.lastName);
        controlLastName.setErrors(null);
        controlLastName.markAsPristine();
        controlLastName.markAsUntouched();

        controlEmail.setValue(this.updatedProfile.email);
        controlEmail.setErrors(null);
        controlEmail.markAsPristine();
        controlEmail.markAsUntouched();

        controlEmployeeID.setValue(this.updatedProfile.employeeID);
        controlEmployeeID.setErrors(null);
        controlEmployeeID.markAsPristine();
        controlEmployeeID.markAsUntouched();

        controlGender.setValue(this.updatedProfile.genderKey);
        controlGender.setErrors(null);
        controlGender.markAsPristine();
        controlGender.markAsUntouched();

        const dateOfBirthGroup = this.userInformationForm.controls.dateOfBirthGroup;
        const dateOfBirthMonth = dateOfBirthGroup.get('dateOfBirthMonth');
        const dateOfBirthDay = dateOfBirthGroup.get('dateOfBirthDay');
        const dateOfBirthYear = dateOfBirthGroup.get('dateOfBirthYear');

        const dateOfBirth = new Date(this.updatedProfile.dateOfBirth);

        dateOfBirthMonth.setValue(dateOfBirth.getMonth());
        dateOfBirthMonth.setErrors(null);
        dateOfBirthMonth.markAsPristine();
        dateOfBirthMonth.markAsUntouched();

        dateOfBirthDay.setValue(dateOfBirth.getDate());
        dateOfBirthDay.setErrors(null);
        dateOfBirthDay.markAsPristine();
        dateOfBirthDay.markAsUntouched();

        dateOfBirthYear.setValue(dateOfBirth.getFullYear());
        dateOfBirthYear.setErrors(null);
        dateOfBirthYear.markAsPristine();
        dateOfBirthYear.markAsUntouched();
    }

    toggleUserSettingsEdit(): void {
        if (this.userSettingsEditToggle) {
            const emailGroup = this.settingsForm.controls.emailGroup;
            const controlPreferredEmailNew = emailGroup.get('preferredEmailNew');
            const controlPreferredEmailConfirm = emailGroup.get('preferredEmailConfirm');

            controlPreferredEmailNew.setValue('');
            controlPreferredEmailNew.setErrors(null);
            controlPreferredEmailNew.markAsPristine();
            controlPreferredEmailNew.markAsUntouched();

            controlPreferredEmailConfirm.setValue('');
            controlPreferredEmailConfirm.setErrors(null);
            controlPreferredEmailConfirm.markAsPristine();
            controlPreferredEmailConfirm.markAsUntouched();

            const phoneGroup = this.settingsForm.controls.phoneGroup;
            const controlPreferredPhoneNew = phoneGroup.get('phoneNew');
            const controlPreferredPhoneConfirm = phoneGroup.get('preferredPhoneConfirm');

            controlPreferredPhoneNew.setValue('');
            controlPreferredPhoneNew.setErrors(null);
            controlPreferredPhoneNew.markAsPristine();
            controlPreferredPhoneNew.markAsUntouched();

            controlPreferredPhoneConfirm.setValue('');
            controlPreferredPhoneConfirm.setErrors(null);
            controlPreferredPhoneConfirm.markAsPristine();
            controlPreferredPhoneConfirm.markAsUntouched();

            const textMessagePhoneGroup = this.settingsForm.controls.textMessagePhoneGroup;
            const controlPreferredTextMessagePhoneNew = textMessagePhoneGroup.get('preferredTextMessagePhoneNew');
            const controlPreferredTextMessagePhoneConfirm = textMessagePhoneGroup.get('preferredTextMessagePhoneConfirm');

            controlPreferredTextMessagePhoneNew.setValue('');
            controlPreferredTextMessagePhoneNew.setErrors(null);
            controlPreferredTextMessagePhoneNew.markAsPristine();
            controlPreferredTextMessagePhoneNew.markAsUntouched();

            controlPreferredTextMessagePhoneConfirm.setValue('');
            controlPreferredTextMessagePhoneConfirm.setErrors(null);
            controlPreferredTextMessagePhoneConfirm.markAsPristine();
            controlPreferredTextMessagePhoneConfirm.markAsUntouched();

            this.userSettingsEditToggle = false;
        } else {
            this.userSettingsEditToggle = true;
            this.userInformationEditToggle = false;
        }
    }

    saveUserInformation(): void {
        if (this.userInformationForm.valid) {
            const formModel = this.userInformationForm.value;
            const dateOfBirthGroup = formModel.dateOfBirthGroup;
            const year = parseInt(dateOfBirthGroup.dateOfBirthYear, 10);
            const month = parseInt(dateOfBirthGroup.dateOfBirthMonth, 10);
            const day = parseInt(dateOfBirthGroup.dateOfBirthDay, 10);
            this.updatedProfile.firstName = formModel.firstName;
            this.updatedProfile.lastName = formModel.lastName;
            this.updatedProfile.email = formModel.email;
            this.updatedProfile.dateOfBirth = new Date(year, month, day);
            this.updatedProfile.employeeID = formModel.employeeID;
            this.updatedProfile.genderKey = formModel.gender;
            this.saveProfile();
        }
    }

    cancelUserInformationEdit(): void {
        this.updatedProfile.employeeID = this.profile.employeeID;
        this.updatedProfile.firstName = this.profile.firstName;
        this.updatedProfile.lastName = this.profile.lastName;
        this.updatedProfile.email = this.profile.email;
        this.updatedProfile.dateOfBirth = this.profile.dateOfBirth;
        this.updatedProfile.genderKey = this.profile.genderKey;
        this.loadUserInformationForm();
        this.userInformationEditToggle = false;
    }

    saveSettings(): void {
        if (this.userInformationForm.valid) {
            this.mergeFormAndExisting();
            this.saveProfile();
        }
    }

    cancelUserSettingsEdit(): void {
        this.updatedProfile.emailAddress = this.profile.emailAddress;
        this.updatedProfile.languageLocale = this.profile.languageLocale;
        this.updatedProfile.phone = this.profile.phone;
        this.updatedProfile.textMessagePhone = this.profile.textMessagePhone;
        this.updatedProfile.textUseSettingsPhone = this.profile.textUseSettingsPhone;
        this.updatedProfile.receiveSmsReminders = this.profile.receiveSmsReminders;
        this.updatedProfile.stateKey = this.profile.stateKey;
        this.updatedProfile.timeZoneKey = this.profile.timeZoneKey;
        this.userSettingsEditToggle = false;
    }

    saveProfile(): void {
        this.submitDisabled = true;
        this.subscriptions.push(
            this._profileService.saveUserProfile(this.updatedProfile).subscribe(
                (data) => {
                    this.onSuccess();
                },
                (error) => {
                    this.submitDisabled = false;
                    this.onError(this._localizeService.get('errorhandle', 'message'));
                }
            )
        );
    }

    // Create the user information form
    private createUserInformationForm(): void {
        const dateOfBirth = new Date(this.updatedProfile.dateOfBirth);
        this.userInformationForm = this.formBuilder.group({
            firstName: this.updatedProfile.firstName,
            lastName: this.updatedProfile.lastName,
            email: [this.updatedProfile.email, CustomValidators.email],
            employeeID: this.profile.employeeID,
            gender: this.profile.genderKey,
            dateOfBirthGroup: this.formBuilder.group(
                {
                    dateOfBirthYear: dateOfBirth.getFullYear(),
                    dateOfBirthMonth: dateOfBirth.getMonth(),
                    dateOfBirthDay: dateOfBirth.getDate()
                },
                { validator: this.isValidDate(['dateOfBirthMonth', 'dateOfBirthDay', 'dateOfBirthYear']) }
            )
        });
    }

    // Create the settings form
    private createSettingsForm(): void {
        this.settingsForm = this.formBuilder.group({
            emailGroup: this.formBuilder.group(
                {
                    preferredEmailNew: ['', CustomValidators.email],
                    preferredEmailConfirm: ''
                },
                { validator: this.match(['preferredEmailNew', 'preferredEmailConfirm']) }
            ),

            phoneGroup: this.formBuilder.group(
                {
                    phoneNew: '',
                    preferredPhoneConfirm: ''
                },
                { validator: this.match(['phoneNew', 'preferredPhoneConfirm']) }
            ),

            textMessagePhoneGroup: this.formBuilder.group(
                {
                    preferredTextMessagePhoneNew: '',
                    preferredTextMessagePhoneConfirm: ''
                },
                { validator: this.match(['preferredTextMessagePhoneNew', 'preferredTextMessagePhoneConfirm']) }
            ),

            receiveSmsReminders: this.updatedProfile.receiveSmsReminders,
            preferredTextUsePreferredPhone: this.updatedProfile.textUseSettingsPhone,
            preferredLanguage: this.updatedProfile.languageLocale,
            preferredTimeZoneKey: this.updatedProfile.timeZoneKey,
            preferredStateKey: this.updatedProfile.stateKey,
            preferredGenderKey: this.updatedProfile.genderKey,
            preferredGender: this.profile.genderKey
        });
    }

    // Validate two control values (for one form group) match.
    // Note: Expects the two form control parameters to compare.
    private match(params: string[]): ValidatorFn {
        return (control: AbstractControl): Record<string, boolean> | null => {
            if (!control.get(params[0]).value && !control.get(params[1]).value) {
                return null;
            } else if (control.get(params[0]).value !== control.get(params[1]).value) {
                return { match: true };
            }
            return null;
        };
    }

    // Validate date control values
    // Note: Expects the month, day & year form control parameters to compare.
    private isValidDate(params: string[]): ValidatorFn {
        return (control: AbstractControl): Record<string, boolean> | null => {
            if (!control.get(params[0]).value && !control.get(params[1]).value && !control.get(params[2]).value) {
                return null;
            }
            // Check if date part make a valid date
            else {
                const dob = moment((parseInt(control.get(params[0]).value, 10) + 1).toString() + '-' + parseInt(control.get(params[1]).value, 10).toString() + '-' + parseInt(control.get(params[2]).value, 10).toString(), 'M-D-YYYY', true);
                const now = moment();
                const diff = now.diff(dob, 'years');

                if (!dob.isValid() || diff < 18 || diff > 120) return { isValidDate: true };
            }
            return null;
        };
    }

    // Merge the update and existing settings creating an updated profile.
    private mergeFormAndExisting(): void {
        const formModel = this.settingsForm.value;
        const email = formModel.emailGroup;
        const phone = formModel.phoneGroup;
        const textMessagePhone = formModel.textMessagePhoneGroup;

        this.updatedProfile.receiveSmsReminders = formModel.receiveSmsReminders;
        this.updatedProfile.textUseSettingsPhone = formModel.preferredTextUsePreferredPhone;
        this.updatedProfile.languageLocale = formModel.preferredLanguage;
        this.updatedProfile.timeZoneKey = formModel.preferredTimeZoneKey === 'null' ? null : formModel.preferredTimeZoneKey;
        this.updatedProfile.stateKey = formModel.preferredStateKey === 'null' ? null : formModel.preferredStateKey;
        this.updatedProfile.genderKey = formModel.preferredGenderKey;

        if (email.preferredEmailNew && email.preferredEmailConfirm) {
            this.updatedProfile.emailAddress = email.preferredEmailNew;
        } else {
            this.updatedProfile.emailAddress = this.profile.emailAddress;
        }

        if (phone.phoneNew && phone.preferredPhoneConfirm) {
            this.updatedProfile.phone = phone.phoneNew;
        } else {
            this.updatedProfile.phone = this.profile.phone;
        }

        if (textMessagePhone.preferredTextMessagePhoneNew && textMessagePhone.preferredTextMessagePhoneConfirm) {
            this.updatedProfile.textMessagePhone = textMessagePhone.preferredTextMessagePhoneNew;
        } else {
            this.updatedProfile.textMessagePhone = this.profile.textMessagePhone;
        }
    }

    private getUserProfile(): void {
        this.subscriptions.push(
            this._profileService.getUserProfile().subscribe(
                (profile) => {
                    this.profile = profile;
                    this.seedProfileUpdate(profile);
                    this.createUserInformationForm();
                    this.createSettingsForm();
                    this.isReadyForRender();
                },
                (error) => {
                    this.onError();
                }
            )
        );
    }

    private seedProfileUpdate(profile: Profile): void {
        this.updatedProfile = new ProfilePut();
        this.updatedProfile.userKey = profile.userKey;
        this.updatedProfile.participantKey = profile.participantKey;
        this.updatedProfile.employeeID = profile.employeeID;
        this.updatedProfile.firstName = profile.firstName;
        this.updatedProfile.lastName = profile.lastName;
        this.updatedProfile.email = profile.email;
        this.updatedProfile.dateOfBirth = profile.dateOfBirth;
        this.updatedProfile.genderKey = profile.genderKey;
        this.updatedProfile.emailAddress = profile.emailAddress;
        this.updatedProfile.languageLocale = profile.languageLocale;
        this.updatedProfile.phone = profile.phone;
        this.updatedProfile.textMessagePhone = profile.textMessagePhone;
        this.updatedProfile.textUseSettingsPhone = profile.textUseSettingsPhone;
        this.updatedProfile.receiveSmsReminders = profile.receiveSmsReminders;
        this.updatedProfile.stateKey = profile.stateKey;
        this.updatedProfile.timeZoneKey = profile.timeZoneKey;
    }

    translateBoolean(value: boolean): string {
        let answer = '';

        if (value) {
            answer = this._localizeService.get('yes', 'label');
        } else {
            answer = this._localizeService.get('no', 'label');
        }

        return answer;
    }

    getStateDescription(stateKey: string): string {
        let stateDescription = 'None Selected';

        const state: State = _.find(this.stateList, (thisState: State) => thisState.key === stateKey);

        if (state) {
            stateDescription = state.name;
        }

        return stateDescription;
    }

    getLocaleDescription(localeKey: string): string {
        let localeDescription = 'None Selected';

        const locale: Locale = _.find(this.localeList, (thisLocale: Locale) => thisLocale.locale === localeKey);

        if (locale) {
            localeDescription = locale.name;
        }

        return localeDescription;
    }

    getTimeZoneDescription(timezoneKey: string): string {
        let timeZoneDescription = 'None Selected';

        const timeZone: TimeZone = _.find(this.timeZoneList, (thisTimeZone: TimeZone) => thisTimeZone.key === timezoneKey);

        if (timeZone) {
            timeZoneDescription = timeZone.name;
        }

        return timeZoneDescription;
    }

    getGenderDescription(genderKey: string): string {
        let genderDescription = 'None Selected';
        const gender: Gender = _.find(this.genderList, (thisGender: Gender) => thisGender.key === genderKey);
        if (gender) {
            genderDescription = gender.displayName;
        }

        return genderDescription;
    }

    private onSuccess(): void {
        // Close forms.
        this.userInformationEditToggle = false;
        this.userSettingsEditToggle = false;
        this.submitDisabled = false;
        // Refresh data.
        this.getUserProfile();
        // If language has been changed, establish language selection.
        if (this.updatedProfile.languageLocale !== this.profile.languageLocale) {
            location.reload();
        }
        // Show confirmation modal.
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.msg = this._localizeService.get('updatesuccessneedreload', 'message');
        this.modalRef.componentInstance.header = this._localizeService.get('success', 'label');
    }

    private onError(msg: string = null): void {
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.msg = msg ? msg : this._localizeService.get('unknownerror', 'message');
    }

    private isReadyForRender(): void {
        if (this.currentUser && this.profile && this.localeList && this.timeZoneList && this.stateList && this.genderList) {
            this.readyForRender = true;
        }
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this._personService.currentPerson.subscribe(
                (data) => {
                    if (data) {
                        this.currentUser = data;
                        this.getUserProfile();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._metadataService.timezoneList.subscribe(
                (data) => {
                    if (data) {
                        this.timeZoneList = data;
                        this.isReadyForRender();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._metadataService.stateList.subscribe(
                (data) => {
                    if (data) {
                        this.stateList = data;
                        this.isReadyForRender();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._metadataService.getLocales(this.currentUser.company.key).subscribe(
                (data) => {
                    if (data) {
                        this.localeList = data;
                        this.isReadyForRender();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );

        this.subscriptions.push(
            this._metadataService.getGenders(this.currentUser.locale.languageLocale).subscribe(
                (data) => {
                    if (data) {
                        this.genderList = data;
                        this.isReadyForRender();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
}

export class WCSMessage {
    type: WCSMessageTypes;
    data: any;
    sender: string
}

export enum WCSMessageTypes {
    AdminCompanyInformation,
    PhysicianRiskAlert,
    SelfVerifyStyleUpdate,
    WidgetValidation
}
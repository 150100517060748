
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable, Subject,throwError as observableThrowError } from 'rxjs';
import { catchError, map,shareReplay } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ConfirmationType } from '../shared/confirmation/confirmation-types';
import { Globals } from '../shared/globals';
import { LabReferenceCode } from '../shared/lab-reference-code/lab-reference-code';
import { MetadataService } from '../shared/metadata/metadata.service';
import { PersonService } from '../shared/person/person.service';
import { ApiAppointment, CancelReason } from './model/api-appointment';
import { ApiEvent } from './model/api-event';
import { ApiSlot } from './model/api-slot';
import { DateSummaryCollection } from './model/date-summary';
import { EventDateAndLocationCollection } from './model/event-dates-and-location';
import { EventLocation } from './model/event-location';
import { ItinerarySubmission } from './model/itinerary';
import { AvailableProgramServiceType, ProgramServiceAndType } from './model/program-service-and-type';
import { SlotSearchCriteria } from './model/slot-search-criteria';

@Injectable()
export class SchedulerService {
    constructor(private _http: HttpClient, private _personService: PersonService, private _metaDataService: MetadataService) { }

    hasActiveAppointments = false;
    getAvailableLocations(userKey: string): Observable<EventLocation[]> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);

        // return this._http.get<ApiEvent[]>('../../api/locations.json')
        return this._http
            .get<EventLocation[]>(environment.baseSchedulerEndpoint + '/locations', { params: params }).pipe(
                map((locs: EventLocation[]) => {
                    return locs.map((loc) => {
                        return new EventLocation(loc);
                    });
                }),
                catchError(this.handleError),);
    }

    getEventDatesAndLocations(userKey: string): Observable<EventDateAndLocationCollection> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);

        // return this._http.get<ApiEvent[]>('../../api/eventdatesandlocation.json')
        return this._http.get<EventDateAndLocationCollection>(environment.baseSchedulerEndpoint + '/events/getEventDatesAndLocation', { params: params }).pipe(catchError(this.handleError));
    }

    getAvailableServiceTypes(userKey: string): Observable<AvailableProgramServiceType[]> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);

        // return this._http.get<ServiceType[]>('../../api/servicetypes.json')
        return this._http.get<AvailableProgramServiceType[]>(environment.baseSchedulerEndpoint + '/services/servicetypes', { params: params }).pipe(catchError(this.handleError));
    }

    getAvailableSlots(criteria: SlotSearchCriteria): Observable<DateSummaryCollection> {
        // return this._http.get<ApiEvent[]>('../../api/slots.json')
        return this._http
            .post<ApiEvent[]>(environment.baseSchedulerEndpoint + '/slots', criteria).pipe(
                map((events: ApiEvent[]) => {
                    const _events = _.sortBy(events, (event: ApiEvent) => {
                        return event.startDate;
                    });

                    _.forEach(_events, (event: ApiEvent) => {
                        event.availableSlots = _.sortBy(event.availableSlots, (slot: ApiSlot) => {
                            return slot.time;
                        });
                    });

                    return new DateSummaryCollection(_events);
                }),
                catchError(this.handleError),);
    }

    getAppointments(userKey: string): Observable<ApiAppointment[]> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        // return this._http.get<ApiAppointment[]>('../../api/appointments.json')
        return this._http
            .get<ApiAppointment[]>(environment.baseSchedulerEndpoint + '/appointments', { params: params }).pipe(
                map((appts: ApiAppointment[]) => {
                    return appts.map((appt) => {
                        return new ApiAppointment(appt);
                    });
                }), shareReplay(),
                catchError(this.handleError),);
    }

    getPscAppointments(userKey: string): Observable<ApiAppointment[]> {
        let params = new HttpParams();
        params = params.append('userKey', userKey);

        // return this._http.get<ApiAppointment[]>('../../api/appointments.json')
        return this._http
            .get<ApiAppointment[]>(environment.baseSchedulerEndpoint + '/psc/appointments', { params: params }).pipe(
                map((appts: ApiAppointment[]) => {
                    return appts.map((appt) => {
                        return new ApiAppointment(appt);
                    });
                }), shareReplay(),
                catchError(this.handleError),);
    }

    cancelAppointment(key: string, reasonKey: string = null) {
        const body = {
            appointmentKey: key,
            reasonKey: reasonKey
        };
        return this._http
            .post(environment.baseSchedulerEndpoint + '/appointments/cancel', body).pipe(
                map((res) => {
                    this._personService.fetchPerson();
                    return res;
                }),
                catchError(this.handleError),);
    }

    getCancelReasons(): Observable<CancelReason[]> {
        return this._http.get<CancelReason[]>(environment.baseSchedulerEndpoint + '/appointments/cancelreasons').pipe(catchError(this.handleError));
    }

    submitItinerary(itinerary: ItinerarySubmission): Observable<any> {
        return this._http
            .post<any>(environment.baseSchedulerEndpoint + '/itinerary', itinerary, { observe: 'response' }).pipe(
                map((res) => {
                    if (res) {
                        if (res.status === 201) {
                            return { status: res.status, body: res.body };
                        } else if (res.status === 200) {
                            return { status: res.status, body: res.body };
                        }
                    }
                }),
                catchError(this.handleError),);
    }

    resendConfirmationEmail(key: string, confirmationType: ConfirmationType = ConfirmationType.CONFIRM) {
        const body = {
            appointmentKey: key,
            confirmationName: ConfirmationType[confirmationType]
        };
        return this._http.post(environment.baseSchedulerEndpoint + '/appointments/resendconfirmation', body).pipe(catchError(this.handleError));
    }

    getLabcorpServiceId(programServiceTypeKey: string, programServiceKey: string): Observable<number> {
        // currently returns only 1 match. could refactor later if it becomes possible for a portal service card to map to more than one labcorp service
        let serviceId: number;
        const subject = new Subject<number>();
        serviceId = Globals.LabcorpServices.RoutineBloodWork;
        this._metaDataService.getLabReferenceCodes(programServiceTypeKey, programServiceKey).subscribe((codes) => {
            let labCode: LabReferenceCode;
            if (codes != null) {
                const keys = Object.keys(Globals.LabcorpServices.ServiceOptionMap);
                const values = Object.values(Globals.LabcorpServices.ServiceOptionMap);
                for (var i = 0; i < keys.length; i++) {
                    // currently returns first matching panel/service option
                    labCode = _.find(codes, function (c: LabReferenceCode) {
                        return c.testCode === keys[i];
                    });
                    if (labCode != null) {
                        serviceId = values[i];
                        break;
                    }
                }
            }
            subject.next(serviceId);
        });

        return subject.asObservable();
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.status === 400) {
            return observableThrowError({ status: err.status, body: err.error });
        }
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

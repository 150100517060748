<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-GapsInCare">{{ title }}</h4>
    <button type="button" class="close" id="actionId-Close-GapsInCare" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form [formGroup]="captureGapsInCareRequestForm" id="formId-GapsInCare" (ngSubmit)="save()" novalidate>
    <div class="modal-body" *ngIf="isReady">
        <div class="row capture-header p-2">
            {{ 'gapsincaremodalintro' | lesCustomLocalize : 'message' }}
        </div>

        <h4 class="primary-text" id="titleId-ShippingAddress">{{ 'shippingaddress' | lesCustomLocalize : 'label' }}</h4>

        <div class="row capture-header p-2 alert alert-danger" *ngIf="addressValidationMessage && addressValidationMessage.length" style="margin: 10px">
            {{ this.addressValidationMessage }}
        </div>

        <div class="form-group row">
            <div class="col-sm-6">
                <label for="address1">{{ 'address' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" formControlName="address1" required [(ngModel)]="gapsInCare.address1" />
            </div>
            <div class="col-sm-6">
                <label for="address2">{{ 'address2' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" formControlName="address2" [(ngModel)]="gapsInCare.address2" />
            </div>
        </div>

        <div class="row form-group">
            <div class="col-sm-4">
                <label for="city">{{ 'city' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" formControlName="city" required [(ngModel)]="gapsInCare.city" />
            </div>
            <div class="col-sm-4">
                <label for="state">{{ 'state' | lesCustomLocalize : 'label' }}</label>
                <select formControlName="stateAbbreviation" required class="form-control" [(ngModel)]="gapsInCare.stateAbbreviation">
                    <option value="null">{{ 'noneselected' | lesCustomLocalize : 'label' }}</option>
                    <option *ngFor="let state of states" [value]="state.abbreviation">
                        {{ state.name }}
                    </option>
                </select>
            </div>
            <div class="col-sm-4">
                <label for="zipcode">{{ 'zipcode' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" formControlName="zip" required pattern="^[0-9]{5}(?:-[0-9]{4})?$" [(ngModel)]="gapsInCare.zip" />
            </div>
        </div>

        <h4 class="primary-text" id="titleId-GapsInCareContactInformation">Contact Information</h4>

        <div class="form-group row" formGroupName="emailGroup">
            <div class="col-sm-6">
                <label for="email">{{ 'email' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" formControlName="email" required [(ngModel)]="gapsInCare.email" placeholder="" />
                <div class="alert alert-danger" *ngIf="captureGapsInCareRequestForm.controls.emailGroup.controls.email.errors?.email">
                    {{ 'emailinvalidformat' | lesCustomLocalize : 'message' }}
                </div>
            </div>
            <div class="col-sm-6">
                <label for="confirmEmail" *ngIf="captureGapsInCareRequestForm.get('emailGroup.email').touched && !captureGapsInCareRequestForm.get('emailGroup.email').pristine">{{ 'confirmemail' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" required formControlName="confirmEmail" placeholder="" *ngIf="captureGapsInCareRequestForm.get('emailGroup.email').touched && !captureGapsInCareRequestForm.get('emailGroup.email').pristine" />
                <div
                    class="alert alert-danger"
                    *ngIf="captureGapsInCareRequestForm.get('emailGroup.confirmEmail').touched && !captureGapsInCareRequestForm.get('emailGroup.confirmEmail').pristine && captureGapsInCareRequestForm.controls.emailGroup.errors?.match"
                >
                    {{ 'confirmmatchnew' | lesCustomLocalize : 'message' }}
                </div>
            </div>
        </div>

        <div class="form-group row" formGroupName="phoneGroup">
            <div class="col-sm-6">
                <label for="phone">{{ 'phonenumber' | lesCustomLocalize : 'label' }}</label>
                <input
                    type="text"
                    mask="(000) 000-0000"
                    class="form-control"
                    formControlName="phone"
                    required
                    [(ngModel)]="gapsInCare.phone"
                    pattern="^\s*(?:\+?\d{1,3})?[- (]*\d{3}(?:[- )]*\d{3})?[- ]*\d{4}(?: *[x/#]\d+)?\s*$"
                    placeholder="(555) 555-5555"
                />
            </div>
            <div class="col-sm-6">
                <label for="confirmPhone" *ngIf="captureGapsInCareRequestForm.get('phoneGroup.phone').touched && !captureGapsInCareRequestForm.get('phoneGroup.phone').pristine">{{ 'confirmphone' | lesCustomLocalize : 'label' }}</label>
                <input
                    type="text"
                    mask="(000) 000-0000"
                    class="form-control"
                    required
                    formControlName="confirmPhone"
                    placeholder=""
                    *ngIf="captureGapsInCareRequestForm.get('phoneGroup.phone').touched && !captureGapsInCareRequestForm.get('phoneGroup.phone').pristine"
                />
                <div
                    class="alert alert-danger"
                    *ngIf="captureGapsInCareRequestForm.get('phoneGroup.phone').touched && !captureGapsInCareRequestForm.get('phoneGroup.confirmPhone').pristine && captureGapsInCareRequestForm.controls.phoneGroup.errors?.match"
                >
                    {{ 'confirmmatchnew' | lesCustomLocalize : 'message' }}
                </div>
            </div>
        </div>

        <div class="form-check">
            <label class="form-check-label">
                <input class="form-check-input" type="checkbox" formControlName="receiveSmsReminders" [(ngModel)]="gapsInCare.receiveSmsReminders" />
                {{ 'receivesms' | lesCustomLocalize : 'label' }}
            </label>
        </div>

        <div class="form-check" *ngIf="gapsInCareViewModel.allowOptinPaperCommunication">
            <label class="form-check-label">
                <input class="form-check-input" type="checkbox" formControlName="receivePaperCommunications" [(ngModel)]="gapsInCare.receivePaperCommunications" />
                {{ 'receivepapercommunications' | lesCustomLocalize : 'label' }}
            </label>
        </div>
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn btn-secondary" id="actionId-Cancel-GapsInCare" (click)="close()">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
        <button [disabled]="!isReady || !this.captureGapsInCareRequestForm.valid || this.isSubmitting" id="actionId-Submit-GapsInCare" type="submit" class="tertiary-bg btn btn-primary" value="Submit">
            {{ 'submit' | lesCustomLocalize : 'label' }}
        </button>
    </div>
</form>

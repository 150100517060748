export const Globals = {
  Timeout: {
    Idle: 30,
    Timeout: 1200, //600// 10 minutes;TODO put back to 600
    Warning: 120, // 2 minutes remaining
  },

  LogoutReminder: {
    Timeout: 120000, // miliseconds - 2 minutes
  },

  ServiceTypes: {
    Coaching: "Coaching",
    OnlineScheduler: "Online Scheduler",
    PDF: "PDF",
    ThirdParty: "Third Party",
    InfoOnly: "Information Only",
    Pixel: "Pixel",
    Attestation: "Attestation",
    GapsInCare: "GIC Labcorp at Home"
  },

  ServiceOptions: {
    Attestation: {
      Pregnant: "CBA9EA12-0F18-48DB-BA33-64A56B33916F",
      TobaccoUse: "46B277E8-E63C-44D9-9D74-2E92973A063E"
    }
  },

  ServiceTypeImages: [
    {
      pgtkey: "05fde875-b58c-4922-8696-d06f1fb6df7b",
      servicetype: "Onsite - COVID Temperature Check",
      fontawesomeclass: "fa-thermometer-three-quarters",
    },
    {
      pgtkey: "206e200b-3511-4574-bc7d-9cb346fbf75f",
      servicetype: "Onsite - Venipuncture (CRL)",
      fontawesomeclass: "fa-heart",
    },
    {
      pgtkey: "4c82820c-1cc3-47e0-91ba-579a39c341ae",
      servicetype: "Third Party",
      fontawesomeclass: "fa-heart",
    },
    {
      pgtkey: "5b682ff8-874c-4fd4-b2fa-60a699872734",
      servicetype: "Onsite - LabCorp DBS",
      fontawesomeclass: "fa-tint",
    },
    {
      pgtkey: "6af50406-c3ca-4094-9e8e-287ead637994",
      servicetype: "Onsite - COVID Serology",
      fontawesomeclass: "fa-vial",
    },
    {
      pgtkey: "6e071d64-7112-42bd-bf70-0720e0631765",
      servicetype: "Onsite - Immunizations",
      fontawesomeclass: "fa-syringe",
    },
    {
      pgtkey: "813e690d-142c-4bd4-9317-0a7b833413b4",
      servicetype: "Onsite - Fingerstick w/ DBS",
      fontawesomeclass: "fa-heart",
    },
    {
      pgtkey: "8cdc34f5-59ee-4541-86c4-01b46cc24600",
      servicetype: "Onsite - Fingerstick",
      fontawesomeclass: "fa-heart",
    },
    {
      pgtkey: "bebe54b2-011f-44df-ac33-5b281019f51d",
      servicetype: "Onsite - Flu",
      fontawesomeclass: "fa-band-aid",
    },
    {
      pgtkey: "ebacbb2d-ed60-4be3-82af-50c951ee86a2",
      servicetype: "Onsite - COVID PCR",
      fontawesomeclass: "fa-virus",
    },
    {
      pgtkey: "544e0126-3281-40fb-bcee-8a6e0c6f5a68",
      servicetype: "Onsite - COVID DBS",
      fontawesomeclass: "fa-tint",
    },
    {
      pgtkey: "ff63370f-ee47-480e-8aab-b54375157b91",
      servicetype: "Onsite – LabCorp Processing",
      fontawesomeclass: "fa-vial",
    },
    {
      pgtkey: "db1fde43-1b02-4da5-8cb3-96097d7bdeb0",
      servicetype: "Onsite - COVID POC",
      fontawesomeclass: "fa-viruses",
    },
  ],

  TabValues: {
    Coaching: "coaching",
    SomethingElse: "somethingelse", // TODO: remove
  },

  SectionValues: {
    AllAccess: "allaccess",
    Appointments: "appointments",
    Messaging: "messages",
    Coaches: "coaches",
  },

  Locales: {
    USEnglish: "en-us",
    MexicoSpanish: "es-mx",
  },

  Routes: {
    coaching: "coaching",
    scheduler: "scheduler",
    services: "services",
    widgets: '/person/collect',
    home: '/home',
    participant: '/participant'
  },

  Colors: {
    color1: "#1a2188",
    color2: "#2998e3",
    color3: "#1a2188 ",
  },

  FastingTokens: {
    fasting: "stronglyencouraged",
    both: "recommended",
    nonfasting: "notrequired",
    "n/a": "na",
  },

  Radii: [
    {
      id: 0,
      desc: "0",
    },
    {
      id: 1,
      desc: "1",
    },
    {
      id: 5,
      desc: "5",
    },
    {
      id: 10,
      desc: "10",
    },
    {
      id: 25,
      desc: "25",
    },
    {
      id: 50,
      desc: "50",
    },
    {
      id: 100,
      desc: "100",
    },
  ],

  DefaultRadius: 25,

  AppointmentInvalidReasons: {
    SlotTaken: 0,
    ExistingFutureAppointment: 1,
    Conflict: 2,
    ConsentNeeded: 3,
    NotEligble: 4,
    OverlappingServiceTypes: 5,
  },

  ServiceTokens: {
    appealswaivers: "appealslabel",
    coaching: "coachinglabel",
    fluvoucher: "walgreenfluvoucherlabel",
    flucircle: "fclabel",
    hometestkit: "htklabel",
    incentiveactivityform: "incentiveformlabel",
    internaltraining: "internaltrainlabel",
    offsitealereescreen: "escreenlabel",
    offsitecrlhomekit: "crltestkitlabel",
    offsitecrlinclinic: "crlincliniclabel",
    offsitecvsminuteclinic: "cvslabel",
    offsitelabcorp: "labcorplabel",
    offsitewalgreens: "walgreenslabel",
    onsitefingerstick: "onsitefingersticklabel",
    onsitefingerstickwdbs: "onsitefingerstickdbslabel",
    onsiteflu: "onsiteflulabel",
    onsitenonblood: "onsitenonbloodlabel",
    onsitevenipuncturecrl: "onsitevenilabel",
    pcpform: "pcplabel",
    preventivecare: "preventativecarelabel",
    quest: "questlabel",
    scheduleronly: "scheduleronlylabel",
    selfreported: "selfreportedlabel",
    telephonichealtheducation: "telephonichelabel",
    thirdparty: "thirdpartylabel",
    venipuncture: "venipuncturelabel",
    onsitepoc: "onsitepoclabel"
  },

  ServiceDescriptionTokens: {
    AppealsWaivers: "appealsdescription",
    CoachingService: "coachingdescription",
    FluVoucher: "walgreenfluvoucherdesc",
    FullCircle: "fcdesc",
    HomeTestKitScreening: "htkdesc",
    Usedtocaptureselfreportedincentiveinformationactivitieshealthyhabitspreventiveservicesetc:
      "incentiveformdesc",
    ThisisforVenipunctureEvaluationsandOnsiteFingerStickTrainings:
      "internaltraindesc",
    OffsiteAlereeScreen: "escreendesc",
    CRLHomeKit: "crltestkitdesc",
    CRLOffsiteVoucher: "crlinclinicdesc",
    CVSVoucher: "cvsdesc",
    LabCorpVoucher: "labcorpdesc",
    WalgreensVoucher: "walgreensdesc",
    OnsiteScreenings: "onsitefingerstickdescription",
    OnsiteFingerstickwDBS: "onsitefingerstickdbsdesc",
    FluService: "onsitefludesc",
    Onsitescreeningeventforonlybiometricsoralcotinineorothertestwherenoblooddraworfingerstickisperformed:
      "onsiteblooddesc",
    CRLLabVouchers: "onsitevenidesc",
    PCPFormVoucher: "pcpdesc",
    PreventativeCare: "preventivecaredesc",
    QuestVoucher: "questdesc",
    OnsiteScheduler: "scheduleronlydesc",
    SelfReported: "selfreporteddesc",
    TelephonicHealthEducation: "telephonichedesc",
    ThirdPartyScreening: "thirdpartydesc",
    VenipunctureScreening: "venipunctredesc",
  },
  LabcorpServices: {
    RoutineBloodWork: 5,
    EmployeeWellness: 17,
    ServiceOptionMap: {
      "101300": 17, //LabCorp Biometrics Panel
    }
  },
  ApplicationKeys: {
    WellConnectPlus: '036dc096-5eda-4e2b-a512-8d60d1ba2979'
  },
  OrganizationTypes: {
    Medicare: 'cb8557fd-9d8a-4a6c-a7e2-23a1fcf3d566',
    PrivateInsurance: '54e75d22-4284-4f6a-a115-563dfea829d1'
  },
  PaymentOptions: {
    PrivateInsurance: '3c156164-2288-4692-8e21-2309b4cf0121',
    DirectBill: '072325be-dcde-4947-8162-865b7265d9f8',
    MedicarePartB: 'a710836f-5021-4a00-9da0-b6557cc02be1'
  },
  Widgets: {
    Consent: '6fc555aa-0285-4fed-a23b-ae73a8b291bb',
    DataCollection: '60b466f7-6a4f-42aa-befd-51f3f6528695',
    Insurance: 'acb9ba73-8087-4ebe-97b1-471dd70dd509',
    Attestation: 'fb3d0f66-915f-46d8-acaa-5df611760dff',
    Demographic: 'bde8a1e5-b664-4004-85cc-97cade1f88ea'
  },
  DefaultValues: {
    EmptyGuid: "00000000-0000-0000-0000-000000000000"
  },
  SessionValues: {
    WCPlusTranslations: 'WCPlusTranslations',
    PreferredLocale: "en-us",
  },
  PersonProgramBillingSources: {
    WellConnectPlus: "C08D8BA6-E8B7-49AF-8D6C-F566A9996F60"
  },
  HTMLInputElements: {
    MultiSelect: 'multi-selectbox',
    SingleSelect: 'selectbox'
  },

  // Service Types that should not be blocked from Admin View
  // Attestations: 2d9b13f9-cf1b-4ed2-8fba-d32f00bc6e10
  // Full Circle: bdf4d3fc-4f45-4d9e-a999-43366b081d7c
  // Telephonic Health Educations: 8b77acd4-f282-4554-8d93-2e0190e575fb
  // Labcorp OnDemand: a0461d33-1182-4c1b-84b2-63c1017ab214
  // Labcorp OnDemand (COVID): 49c17b3b-59b5-4b8d-a308-cd47875e0483
  AdminForbiddenServiceTypes: ['a0461d33-1182-4c1b-84b2-63c1017ab214', '49c17b3b-59b5-4b8d-a308-cd47875e0483', '2d9b13f9-cf1b-4ed2-8fba-d32f00bc6e10', 'bdf4d3fc-4f45-4d9e-a999-43366b081d7c', '8b77acd4-f282-4554-8d93-2e0190e575fb'] //stop gap for real permission actions
  //
  , SOGIStates: ['6b48b126-0696-4ef5-9cb4-cd73592f38cf', '98188a32-7355-4b38-b073-3bad6361665e'],
  ProgramServiceTypeKeys: {
    GIC: '5e646e76-e6c1-41ca-8273-445b617bde89'
  }
};

<div class="modal-header">
    <h4 class="modal-title" id="titleId-ScheduleAppointmentConfirmation">{{ 'rescheduleappointmentconfirmation' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="close" id="actionId-CloseScheduleAppointmentConfirmation" aria-label="Close" (click)="activeModal.close('cancel')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <p>{{ 'cancelexistingappointmentconfirm' | lesCustomLocalize : 'message' }}</p>
</div>

<div class="modal-footer pt-10">
    <button type="button" class="btn" id="actionId-NoToScheduleAppointmentConfirmation" (click)="activeModal.close('cancel')">{{ 'no' | lesCustomLocalize : 'label' }}</button>
    <button type="submit" class="btn btn-primary" id="actionId-YesScheduleAppointmentConfirmation" (click)="confirmAction()">{{ 'yes' | lesCustomLocalize : 'label' }}</button>
</div>


import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CompleteProfile, Profile } from './profile';
import { ProfilePut } from './profile-put';

@Injectable()
export class ProfileService {
    constructor(private _http: HttpClient) { }

    getUserProfile() {
        return this._http.get<Profile>(environment.baseCoreApiEndpoint + '/profiles/mine', {})
            .pipe(
                retry(1),
            ).pipe(
                catchError(this.handleError));
    }
    getOtherUserProfile(userKey: string) {
        let params = new HttpParams();
        params = params.append('userKey', userKey);
        return this._http.get<CompleteProfile>(environment.baseCoreApiEndpoint + '/profiles/other', { params: params })
            .pipe(
                retry(1),
            )
    }

    saveUserProfile(profile: ProfilePut) {
        return this._http.put(environment.baseCoreApiEndpoint + '/profiles', profile).pipe(
            catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate, NgbDateStruct, NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ApiAppointment } from '../../scheduler/model/api-appointment';
import { SchedulerService } from '../../scheduler/scheduler.service';
import { AddressAutoCompleteComponent } from '../../shared/addressautocomplete/addressautocomplete.component';
import { AddressAutoCompleteSettings } from '../../shared/addressautocomplete/addressautocomplete-settings';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { GapsInCareService } from '../../shared/gaps-in-care/gaps-in-care.service';
import { Globals } from '../../shared/globals';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Person } from '../../shared/person/person';
import { CompleteProfile } from '../../shared/profile/profile';
import { ProfileService } from '../../shared/profile/profile.service';
import { Service } from '../../shared/service';
import { SmartyStreetsService } from '../../shared/smartystreets/smartystreets.api.service';
import { ICoordinate, IUsAutoCompleteSuggestion } from '../../shared/smartystreets/smartystreets.models';
import { State } from '../../shared/state/state';
import { CustomValidators } from '../../shared/validators';
import { ServicesService } from '../services-list/services.service';
import {
    IElectronicOrderModel,
    IEmailDocumentPostViewModel,
    ILabcorpAppointmentLocation,
    ILabcorpAppointmentLocationSearch,
    ILabcorpAppointmentRequest,
    ILabcorpAppointmentRequestAppointment,
    ILabcorpAppointmentRequestPatient,
    ILabcorpAppointmentTimeSlot
} from './labcorp.appointment.models';
import { LabcorpAppointmentService } from './services/labcorp.appointment.service';

declare const google: any;
// Marker SVG Path: https://material.io/tools/icons/?icon=place&style=baseline
const MAP_MARKER = 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';

@Component({
    selector: 'app-labcorp-appointment-scheduler',
    templateUrl: './labcorp.appointment.component.html',
    styleUrls: ['./labcorp.appointment.component.css']
})
export class LabcorpAppointSchedulerComponent implements OnInit, OnDestroy {
    @ViewChild(AddressAutoCompleteComponent) locationQuery: AddressAutoCompleteComponent;
    @ViewChild(AddressAutoCompleteComponent) patientAutocompleteQuery: AddressAutoCompleteComponent;
    @Input() currentUser: Person;
    @Input() currentService: Service;
    @Input() apiAppointment: ApiAppointment;

    locationsList: ILabcorpAppointmentLocation[] = [];
    allLocationsList: ILabcorpAppointmentLocation[] = [];
    displayTimeSlotsList: ILabcorpAppointmentTimeSlot[] = [];
    availableTimeSlotsList: ILabcorpAppointmentTimeSlot[] = [];
    ShowChoiceCard = true;
    ShowFindLocation = false;
    ShowLocationsList = false;
    ShowLocationMap = false;
    ShowTimeSlotList = false;
    ShowAppointmentRequestForm = false;
    ShowAppointmentDetailsCard = false;
    ShowNoSlotsMessage = false;
    ShowCancelConfirm = false;
    ShowBookingConfirmation = false;
    ShowLocateAppointmentForm = false;
    ShowAppointmentLocated = false;
    ShowAppointmentNotLocated = false;
    AllowAppointmentEdit = true;
    isValidAddress = true;
    IsWideMode = false;
    isTimeSlotSelected = false;
    UserHasActiveAppointment = false;
    locationRadius: number;
    searchForm: FormGroup;
    slotFilterForm: FormGroup;
    contactForm: FormGroup;
    locateAppointmentForm: FormGroup;
    requestedAppointment: ILabcorpAppointmentRequestAppointment = {};
    statesList: State[] = [];
    subscriptions: ISubscription[] = [];
    selectedLabLocation: ILabcorpAppointmentLocation = null;
    loadingModalRef: NgbModalRef = null;
    showLocationDetails: Map<number, boolean>;
    isAppointmentModification = false;
    useModifyFlow = false;
    useCancelFlow = false;

    mapMarkers: any[] = [];
    mapCenter: any = {};
    locationMap: any;
    locationMapMarkers: any[] = [];
    LOCATIONMARKERICON: any = '/assets/LabcorpLocationMarker.png';
    LOCATIONMARKERICONSELECTED = '/assets/LabcorpLocationMarkerSelected.png';
    minDate: any = { year: 2021, month: 11, day: 23 };
    maxDate: any = { year: 2022, month: 11, day: 23 };
    currentLocation: ICoordinate;

    labcorpLocationAutocompleteSettings: AddressAutoCompleteSettings;
    patientAutocompleteSettings: AddressAutoCompleteSettings;
    isMobile: boolean;
    modalRef: NgbModalRef;
    isAppointmentAssociation: boolean;
    didSendEmailAttachment = false;
    didDownloadDocument = false;
    isSubmitting = false
    pageSize = 6;
    currentPage = 1;
    maxPagesSize = 50;
    showAddAppointment = false;
    isRenderReady = false;
    useGicFlow: boolean;
    completProfile: CompleteProfile
    constructor(

        public _activeModal: NgbActiveModal,
        private _appointmentService: LabcorpAppointmentService,
        private _formBuilder: FormBuilder,
        private _metaDataService: MetadataService,
        private _schedulerService: SchedulerService,
        private _smartyStreetsService: SmartyStreetsService,
        private _modalService: NgbModal,
        private _servicesService: ServicesService,
        private _localizeService: LocalizeService,
        private _gapsInCareService: GapsInCareService,
        private _profileService: ProfileService
    ) {
        this._metaDataService.getStates();
        this.isMobile = this.mobileAndTabletCheck();
        this.ShowLocationMap = !this.isMobile;
        this.onError = this.onError.bind(this);
    }

    ngOnInit() {
        //prevent body from scrolling after modal is open
        document.body.style.setProperty('overflow', 'hidden', 'important');
        this.labcorpLocationAutocompleteSettings = new AddressAutoCompleteSettings();
        this.labcorpLocationAutocompleteSettings.formControlId = 'locationQuery';
        this.labcorpLocationAutocompleteSettings.inputFormatter = (v) => {
            return this.addressAutoCompleteInputFormatter(v);
        };
        this.labcorpLocationAutocompleteSettings.resultFormatter = (s) => {
            return this.addressAutoCompleteResultFormatter(s);
        };
        this.labcorpLocationAutocompleteSettings.selectItem = (e) => {
            this.addressAutoCompleteSelect(e);
        };
        this.labcorpLocationAutocompleteSettings.typeaheadMinLength = 4;
        this.labcorpLocationAutocompleteSettings.placeHolderText = this._localizeService.get('pscaddressplaceholder', 'label');
        this.labcorpLocationAutocompleteSettings.autoComplete = 'off';
        this.labcorpLocationAutocompleteSettings.classList = 'form-control';
        this.patientAutocompleteSettings = new AddressAutoCompleteSettings();
        this.patientAutocompleteSettings.formControlId = 'patientAutocompleteQuery';
        this.patientAutocompleteSettings.inputFormatter = this.patientAutoCompleteInputFormatter;
        this.patientAutocompleteSettings.resultFormatter = this.patientAutoCompleteResultFormatter;
        this.patientAutocompleteSettings.selectItem = (e) => {
            this.patientAutoCompleteSelect(e);
        };
        this.patientAutocompleteSettings.typeaheadMinLength = 4;
        this.patientAutocompleteSettings.placeHolderText = this._localizeService.get('pscaddressplaceholder', 'label');
        this.patientAutocompleteSettings.autoComplete = 'nothankyou';
        this.patientAutocompleteSettings.classList = 'form-control';
        this.patientAutocompleteSettings.requiredField = true;


        const that = this;
        const observable = that.apiAppointment
            ? Observable.create(function (observer) {
                observer.next([that.apiAppointment]);
                observer.complete();
            })
            : this._appointmentService.getAppointments(this.currentUser.userKey, false);
        observable.subscribe((appts) => {
            const dtNow = new Date().getTime();
            that.apiAppointment = appts.find(
                (s) =>
                    s.programServiceKey === (that.apiAppointment ? that.apiAppointment.programServiceKey : that.currentService.programServiceKey) &&
                    s.appointmentType === 'PatientServiceCenter' &&
                    s.isCanceled === false &&
                    s.slot &&
                    s.slot.timeUtc &&
                    new Date(s.slot.timeUtc).getTime() > dtNow
            );

            that.UserHasActiveAppointment = that.apiAppointment !== null && that.apiAppointment !== undefined;
            //gic flow works a little differently.
            let gicServiceTypeKey = null
            if (this.currentService == null && this.UserHasActiveAppointment) {
                gicServiceTypeKey = that.apiAppointment.programServiceTypeKey
            } else {
                gicServiceTypeKey = this.currentService.programServiceTypeKey
            }
            this.useGicFlow = gicServiceTypeKey && gicServiceTypeKey.toLowerCase() == Globals.ProgramServiceTypeKeys.GIC;
            this.subscriptions.push(
                this._metaDataService.stateList.subscribe((data) => {
                    this.statesList = data;
                }, this.onError)
            );

            this.searchForm = this._formBuilder.group({
                locationQuery: [''],
                locationRadius: [25]
            });
            this.slotFilterForm = this._formBuilder.group({
                appointmentDate: [null, Validators.required]
            });

            this.contactForm = this._formBuilder.group({
                patientAutocompleteQuery: [''],
                city: [this.UserHasActiveAppointment ? '' : this.currentUser.city ? this.currentUser.city : this.currentUser.participant ? this.currentUser.participant.city : '', Validators.required],
                stateKey: [this.UserHasActiveAppointment ? '' : this.currentUser.state ? this.currentUser.state : this.currentUser.participant ? this.currentUser.participant.state : '', Validators.required],
                zipCode: [
                    this.UserHasActiveAppointment ? '' : this.currentUser.zip ? this.currentUser.zip : this.currentUser.participant ? this.currentUser.participant.zip : '',
                    [Validators.required, Validators.minLength(5), Validators.maxLength(5)]
                ],
                email: [
                    this.UserHasActiveAppointment
                        ? this.apiAppointment.owner.preferredEmailAddress
                            ? this.apiAppointment.owner.preferredEmailAddress
                            : this.apiAppointment.owner.email
                        : this.currentUser.userInfoEmail
                            ? this.currentUser.userInfoEmail
                            : this.currentUser.email
                                ? this.currentUser.email
                                : this.currentUser.participantEmail,
                    [Validators.required, CustomValidators.email]
                ],
                phone: [this.currentUser.preferredTextUsePreferredPhone && this.currentUser.preferredTextMessagePhone ? this.currentUser.preferredTextMessagePhone : this.currentUser.preferredPhone
                    , this.useGicFlow ? Validators.required : Validators.nullValidator
                ],
                smsApproved: [false],
                receivePaperCommunications: [false]
            });

            this.locateAppointmentForm = this._formBuilder.group({
                firstName: [this.UserHasActiveAppointment ? this.apiAppointment.onBehalfOf.firstName : this.currentUser.firstName, Validators.required],
                lastName: [this.UserHasActiveAppointment ? this.apiAppointment.onBehalfOf.lastName : this.currentUser.lastName, Validators.required],
                confirmationNumber: [this.UserHasActiveAppointment ? this.apiAppointment.confirmationNumber : '', Validators.required]
            });

            const programServiceTypeKey = this.UserHasActiveAppointment === true ? this.apiAppointment.programServiceTypeKey : this.currentService.programServiceTypeKey;
            const programServiceKey = this.UserHasActiveAppointment === true ? this.apiAppointment.programServiceKey : this.currentService.programServiceKey;
            this.subscriptions.push(
                this._schedulerService.getLabcorpServiceId(programServiceTypeKey, programServiceKey).subscribe((data) => {
                    this.requestedAppointment.serviceId = data;
                    if (this.UserHasActiveAppointment) {
                        this.selectedLabLocation = {
                            address1: this.apiAppointment.location.address1,
                            address2: this.apiAppointment.location.address2,
                            city: this.apiAppointment.location.city,
                            stateProvince: this.apiAppointment.location.state,
                            postalCode: this.apiAppointment.location.zip,
                            showDetails: true,
                            id: this.apiAppointment.labcorpLocationId
                        };

                        this.selectedLabLocation.address1 = this.apiAppointment.location.address1;
                        this.selectedLabLocation.address2 = this.apiAppointment.location.address2;
                        this.selectedLabLocation.city = this.apiAppointment.location.city;
                        this.selectedLabLocation.stateProvince = this.apiAppointment.location.state;
                        this.selectedLabLocation.postalCode = this.apiAppointment.location.zip;
                        this.requestedAppointment = Object.assign(this.requestedAppointment, {
                            confirmationNumber: this.apiAppointment.confirmationNumber,
                            appointmentTime: this.apiAppointment.slot.time.toString(),
                            locationId: this.apiAppointment.labcorpLocationId
                        });

                        this.requestedAppointment.patient = {
                            firstName: this.apiAppointment.onBehalfOf.firstName,
                            lastName: this.apiAppointment.onBehalfOf.lastName,
                            email: this.apiAppointment.owner.email,
                            phone: this.apiAppointment.owner.phone,
                            gender: this.apiAppointment.owner.gender.substr(0, 1),
                            smsApproved: this.apiAppointment.owner.phone !== null ? true : false
                        };
                    } else {
                        this.requestedAppointment.patient = {
                            firstName: this.currentUser.firstName,
                            lastName: this.currentUser.lastName,
                            gender: this.currentUser.gender,
                            dateOfBirth: new Date(this.currentUser.dateOfBirth).toISOString(),
                            email: this.currentUser.userInfoEmail ? this.currentUser.userInfoEmail : this.currentUser.email ? this.currentUser.email : this.currentUser.participantEmail
                        };
                    }
                    this.editContactPhoneChange();
                    this.setModalSize(false);
                    document.getElementsByTagName('ngb-modal-window')[0].classList.add('psc-modal');
                    if (this.useGicFlow && !this.UserHasActiveAppointment) {
                        this.makeAppointmentClick();
                    }
                    else {
                        this.ShowChoiceCard = true;
                    }
                    this.isRenderReady = true;
                }, this.onError)
            );

            if (this.currentUser) {
                this.subscriptions.push(this._profileService.getOtherUserProfile(this.currentUser.userKey).subscribe(t => {
                    this.completProfile = t;
                    const phone = this.getPhone(this.currentUser, this.completProfile)
                    if (phone && this.contactForm) {
                        this.contactForm.controls.phone.setValue(phone);
                    }
                    this.editContactPhoneChange();

                }))
            }
        });
    }

    showExistingAppointMessage() {
        this.onError(this._localizeService.get('existingappointment', 'message'));
        this.modalRef.result.then(() => {
            this._activeModal.close();
        });
    }

    ngOnDestroy() {
        document.body.style.overflow = 'scroll';
        if (document.getElementsByTagName('ngb-modal-window').length) {
            document.getElementsByTagName('ngb-modal-window')[0].classList.remove('psc-modal');
        }
        this.isRenderReady = false;
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    private getPhone(person: Person, profile: CompleteProfile): string {
        if (profile) {
            if (profile.phone) return profile.phone;
            if (profile.textMessagePhone) return profile.textMessagePhone;
        }

        if (person) {
            if (person.preferredTextUsePreferredPhone && person.preferredTextMessagePhone) return person.preferredTextMessagePhone;
            if (person.preferredPhone) return person.preferredPhone;
        }
        return person.preferredPhone;
    }
    setModalDimensions(isWide: boolean, isTall = true, setModalSize = true) {
        this.IsWideMode = isWide;
        if (isWide === true) {
            document.getElementsByTagName('ngb-modal-window')[0].classList.add('psc-modal-wide');
        } else {
            document.getElementsByTagName('ngb-modal-window')[0].classList.remove('psc-modal-wide');
        }
        if (isTall === true) {
            document.getElementsByTagName('ngb-modal-window')[0].classList.add('psc-modal-tall');
        } else {
            document.getElementsByTagName('ngb-modal-window')[0].classList.remove('psc-modal-tall');
        }
        if (setModalSize === true) {
            this.setModalSize(!this.isMobile && isWide === false);
        }
    }

    setModalSize(isLarge: boolean) {
        const modalElement = document.getElementsByClassName('modal-dialog')[0];
        if (modalElement === undefined) {
            return;
        }
        if (isLarge === true) {
            modalElement.classList.add('modal-lg');
        } else {
            modalElement.classList.remove('modal-lg');
        }
    }

    showCancelConfirm() {
        this.ShowChoiceCard = false;
        this.ShowTimeSlotList = false;
        this.ShowAppointmentRequestForm = false;
        this.ShowAppointmentDetailsCard = true;
        this.ShowFindLocation = false;
        this.ShowLocationsList = false;
        this.isTimeSlotSelected = true;
        this.ShowCancelConfirm = true;
        this.AllowAppointmentEdit = true;

    }
    cancelAppointment() {
        this.showLoader(true);
        this._appointmentService.cancelAppointment(this.apiAppointment.key, this.createAppointmentRequest(this.requestedAppointment, true)).subscribe((r) => {
            this.showLoader(false);
            window.location.reload();
        }, this.onError);
    }

    makeAppointmentClick() {
        const that = this;
        that.showLoader(true);
        this._appointmentService.getAppointments(this.currentUser.userKey, false).subscribe((appts) => {
            const dtNow = new Date().getTime();
            that.showLoader(false);
            if (
                appts.find((s) => s.programServiceKey === that.currentService.programServiceKey && s.appointmentType === 'PatientServiceCenter' && s.isCanceled === false && s.slot && s.slot.timeUtc && new Date(s.slot.timeUtc).getTime() > dtNow)
            ) {
                this.showExistingAppointMessage();
            } else {
                this.ShowChoiceCard = false;
                this.setModalDimensions(false);
                this.ShowFindLocation = true;
            }
        });
    }

    downloadRequisitionFormClick() {
        this.didDownloadDocument = true;
        this._servicesService.getDocument(
            this.apiAppointment ? this.apiAppointment.programServiceKey : this.currentService.programServiceKey,
            this.currentUser.userKey,
            this.apiAppointment ? this.apiAppointment.serviceDisplayName : this._localizeService.get(this.currentService.serviceCardLabel, 'program')
        ).subscribe({
            complete: () =>
                this.showLoader(false)
            ,
            error: () => {
                this.onError(this._localizeService.get('pscrequisitionformdownloaded', 'message'), this._localizeService.get('success', 'message')).then((r) => {
                    this._activeModal.close();
                });
            }
        });
    }

    emailRequisitionFormClick(showModalMessage = false) {
        this.didSendEmailAttachment = true;
        this.showLoader(true);
        const request: IEmailDocumentPostViewModel = {
            email: this.currentUser.userInfoEmail,
            programServiceKey: this.apiAppointment ? this.apiAppointment.programServiceKey : this.currentService.programServiceKey,
            userKey: this.currentUser.userKey,
            participantKey: this.currentUser.participantKey,
            templateNormalizedName: 'patient-care-requisition-form-attachment',
            locale: this.currentUser.locale.languageLocale,
            source: 'PSC-Scheduler',
            templateContent: {
                '-supporttext-': this._localizeService.get('supportmessage', 'message'),
                '-portalurl-': environment.originUrl
            }
        };

        this._servicesService.emailDocument(request).subscribe((b) => {
            if (!b) {
                this.onError();
            }
            this.showLoader(false);
            if (showModalMessage === true) {
                this.onError(this._localizeService.get('pscrequisitionformattached', 'message'), this._localizeService.get('success', 'message')).then((r) => {
                    this._activeModal.close();
                });
            }
        }, this.onError);
    }

    modifyAppointmentClick() {
        this.ShowChoiceCard = false;
        //do not allow updating user's demographic info, only appt location/time
        this.isAppointmentModification = true;
        this.isTimeSlotSelected = true;
        this.ShowAppointmentDetailsCard = true;
        this.ShowAppointmentRequestForm = true;
        this.useModifyFlow = true;
    }

    cancelAppointmentClick() {
        this.showCancelConfirm();
        this.AllowAppointmentEdit = false;
        this.useCancelFlow = true;
    }

    locateMeClick() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((geoLocation) => this.searchLocations({ latitude: geoLocation.coords.latitude, longitude: geoLocation.coords.longitude }));
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    }
    addressAutoCompleteInputFormatter(value: any) {
        if (typeof value === 'string' || value instanceof String) {
            return this.locationQuery.value;
        }
        return this.addressAutoCompleteResultFormatter(value);
    }

    addressAutoCompleteResultFormatter(value: IUsAutoCompleteSuggestion) {
        if (value) {
            return `${value.street} ${value.city}, ${value.state} ${value.zipCode}`;
        }
        return '';
    }

    addressAutoCompleteSelect(event: NgbTypeaheadSelectItemEvent) {
        const suggestion = event.item as IUsAutoCompleteSuggestion;
        this.locationQuery.value = `${suggestion.street} ${suggestion.city}, ${suggestion.state} ${suggestion.zipCode}`;
        this.searchForm.controls.locationQuery.setValue(this.locationQuery.value);
        this.searchLocations();
    }

    patientAutoCompleteResultFormatter(value: IUsAutoCompleteSuggestion) {
        return `${value.street} ${value.city}, ${value.state} ${value.zipCode}`;
    }

    patientAutoCompleteInputFormatter(value: IUsAutoCompleteSuggestion) {
        if (value) {
            return value.street;
        }
        return '';
    }

    patientAutoCompleteSelect(event: NgbTypeaheadSelectItemEvent) {
        const suggestion = event.item as IUsAutoCompleteSuggestion;
        this.patientAutocompleteQuery.value = suggestion.street;
        this.contactForm.controls.patientAutocompleteQuery.setValue(suggestion.street);
        this.contactForm.controls.city.setValue(suggestion.city);
        this.contactForm.controls.stateKey.setValue(suggestion.state);
        this.contactForm.controls.zipCode.setValue(suggestion.zipCode);
        this.checkAddressInputValidity();
    }

    searchLocations(coordinate?: ICoordinate) {
        this.showLoader(true);
        const appointmentLocationSearch: ILabcorpAppointmentLocationSearch = {
            radius: this.searchForm.controls.locationRadius.value,
            serviceId: this.requestedAppointment.serviceId,
            maxResults: 50
        };
        this.currentLocation = null;
        if (!coordinate) {
            //method is callback for browser geolocate api and also called when auto completing
            //an address which should use the address not the location for the search
            appointmentLocationSearch.address = this.locationQuery.typedValue;
        } else {
            this.currentLocation = coordinate;
            appointmentLocationSearch.latitude = coordinate.latitude;
            appointmentLocationSearch.longitude = coordinate.longitude;
            let locationName = 'No address matches found';
            this._smartyStreetsService.reverseGeocodeLocation(this.currentLocation).subscribe((r) => {
                if (r && r.length) {
                    locationName = `${r[0].address.street} ${r[0].address.city}, ${r[0].address.stateAbbreviation} ${r[0].address.zipCode}`;
                }
                this.locationQuery.value = locationName;
            }, this.onError);
        }

        this.mapMarkers = [];
        this.locationMap = null;
        const currentDate = new Date();
        const currentDatePrefix = currentDate.toISOString().substr(0, currentDate.toISOString().indexOf('T') + 1);
        const that = this;
        that._appointmentService.queryAppointmentLocations(appointmentLocationSearch).subscribe((locations) => {
            if (!locations.length) {
                that.onError('No locations found.');
                return;
            }
            const centerCoord = that.currentLocation !== null ? that.currentLocation : locations[(locations.length / 2) | 0];
            that.mapCenter = { lat: centerCoord.latitude, lng: centerCoord.longitude };
            that.showLocationDetails = locations.reduce((map, location) => ({ ...map, [location.id]: false }), {});
            //get hours for service requested - api returns hours for all services provided by the location
            for (let i = 0; i < locations.length; i++) {
                //add date to time value so angular can parse it
                const hours = locations[i].services.find((s) => s.serviceId === appointmentLocationSearch.serviceId).hours;
                hours.forEach((h) => {
                    h.openingTime = currentDatePrefix + h.openingTime;
                    h.closingTime = currentDatePrefix + h.closingTime;
                });
                locations[i].serviceHours = hours;
            }
            that.locationsList = [];
            that.allLocationsList = locations;
            if (locations.length > that.pageSize) {
                that.onLocationListPageChange(1, true);
            } else {
                that.locationsList = locations;
            }
            that.ShowChoiceCard = false;
            that.ShowFindLocation = true;
            that.ShowLocationsList = true;

            that.ShowTimeSlotList = false;
            that.toggleMap(!that.isMobile);
            that.showLoader(false);
        }, this.onError);
    }

    onLocationListPageChange(pageNumber: number, setMapMarkers?: boolean) {
        this.locationsList = this.allLocationsList.slice((pageNumber - 1) * this.pageSize, pageNumber * this.pageSize);
        if (setMapMarkers !== true && this.ShowLocationMap === true) {
            this.createMapMarkers();
            this.locationMap.setCenter(this.locationsList[0].latitude, this.locationsList[0].longitude);
            const zoomLevel = 11 - this.locationsList[0].distanceFromStartingPoint / 8;
            this.locationMap.setZoom(zoomLevel > 8 ? zoomLevel : 8);
        }
    }

    createMarkerIcon(latitude: number, longitude: number, selected = false, mapZoom = 10) {
        return {
            path: MAP_MARKER,
            fillColor: selected ? '#23ADE3' : '#1A2188',
            scale: 2,
            fillOpacity: 1,
            anchor: new google.maps.Point(10, 27)
        };
    }

    toggleMap(showMap: boolean, setModalSize = true) {
        this.ShowLocationMap = showMap;
        this.setModalDimensions(showMap, !this.isMobile && this.locationsList.length > 0, setModalSize);
        if (showMap && this.locationsList.length) {
            const that = this;
            window.setTimeout(() => {
                that.locationMap = new google.maps.Map(document.getElementById('googleMap'), {
                    center: new google.maps.LatLng(that.locationsList[0].latitude, that.locationsList[0].longitude),
                    zoom: 10
                });
                that.createMapMarkers();
            }, 500);
        }
    }

    createMapMarkers() {
        this.locationMapMarkers.forEach((marker) => {
            marker.setMap(null);
        });
        this.locationsList.forEach((location) => {
            const marker = new google.maps.Marker(
                Object.assign(
                    { map: this.locationMap },
                    {
                        position: { lat: location.latitude, lng: location.longitude },
                        title: location.address1,
                        locationId: location.id,
                        icon: this.createMarkerIcon(location.latitude, location.longitude)
                    }
                )
            );
            this.locationMapMarkers.push(marker);
            google.maps.event.addListener(marker, 'click', () => {
                this.onMapMarkerSelected(marker);
            });
        });
    }

    filterAvailableSlots(dateToShow: NgbDateStruct) {
        this.displayTimeSlotsList = [];
        this.displayTimeSlotsList = this.availableTimeSlotsList.filter((slot) => this.createDateForPicker(new Date(slot.DateTime)).equals(new NgbDate(dateToShow.year, dateToShow.month, dateToShow.day)));
        this.ShowNoSlotsMessage = this.displayTimeSlotsList.length <= 0;
    }

    onMapMarkerSelected(marker: any) {
        this.selectLocation(marker.locationId, false);
    }

    selectLocation(locationId: number, panZoomMap = true) {
        //reset any previously selected icons
        this.locationMapMarkers.forEach((m) => {
            m.setIcon(this.createMarkerIcon(m.position.lat(), m.position.lng(), m.locationId === locationId));
        });
        const location = this.locationsList.find((l) => l.id === locationId);
        if (!location) {
            alert('Non existing location selected.');
        }
        this.selectedLabLocation = location;
        if (panZoomMap && this.ShowLocationMap && !this.isMobile) {
            //pan and zoom map to selected location
            const labCoords = new google.maps.LatLng(this.selectedLabLocation.latitude, this.selectedLabLocation.longitude);
            this.locationMap.setCenter(labCoords);
            this.locationMap.setZoom(12);
        }
        this.isTimeSlotSelected = false;
        this.goToTimeSlotList();
    }

    goToTimeSlotList() {
        this.showLoader(true);
        const programServiceKey = this.apiAppointment ? this.apiAppointment.programServiceKey : this.currentService.programServiceKey;
        this._appointmentService.queryAppointmentTimes(this.requestedAppointment.serviceId, this.selectedLabLocation.id, programServiceKey, this.selectedLabLocation.timeZone).subscribe((slots) => {
            this.availableTimeSlotsList = [];

            if (slots.length === 0) {
                const userMessage = this._localizeService.get('noappointmentslocation', 'message');
                this.showLoader(false);
                this.onError(userMessage);
                return;
            }

            this.availableTimeSlotsList = slots;
            this.filterAvailableSlots(this.createDateForPicker(new Date(slots[0].DateTime)));
            this.minDate = this.createDateForPicker(new Date(slots[0].DateTime));
            this.maxDate = this.createDateForPicker(new Date(slots[slots.length - 1].DateTime));
            this.ShowChoiceCard = false;
            this.ShowLocationsList = false;
            this.ShowFindLocation = false;
            this.ShowAppointmentDetailsCard = true;
            this.ShowTimeSlotList = true;
            this.ShowAppointmentRequestForm = false;
            this.setModalDimensions(false);
            this.showLoader(false);

        }, this.onError);
    }

    goToAppointmentForm() {
        this.ShowChoiceCard = false;
        this.ShowLocationsList = false;
        this.ShowFindLocation = false;
        this.ShowTimeSlotList = false;
        this.ShowAppointmentRequestForm = true;
        this.ShowAppointmentDetailsCard = true;
        this.isTimeSlotSelected = true;
        window.setTimeout(() => {
            if (!this.useModifyFlow) {
                this.setCurrentAddress();
            }
        }, 500);
    }
    setCurrentAddress() {
        this.patientAutocompleteQuery.value = this.currentUser.address1 ? this.currentUser.address1 : this.currentUser.participant ? this.currentUser.participant.address1 : '';
        this.checkAddressInputValidity();
    }
    locateAppointmentClick() {
        this.ShowChoiceCard = false;
        this.ShowTimeSlotList = false;
        this.ShowAppointmentRequestForm = false;
        this.ShowAppointmentDetailsCard = false;
        this.ShowFindLocation = false;
        this.ShowLocationsList = false;
        this.isTimeSlotSelected = false;
        this.ShowCancelConfirm = false;
        this.AllowAppointmentEdit = false;
        this.ShowLocateAppointmentForm = true;
    }
    locateAppointment() {
        if (!this.locateAppointmentForm.valid) {
            this.onError();
            return;
        }
        this.isSubmitting = true
        this.ShowAppointmentNotLocated = false;
        this.showLoader(true);
        //make call to labcorp using confirmation number
        this._appointmentService.locateAppointment(this.locateAppointmentForm.controls.confirmationNumber.value, this.locateAppointmentForm.controls.firstName.value, this.locateAppointmentForm.controls.lastName.value).subscribe((r) => {
            if (!r) {
                this.ShowAppointmentNotLocated = true;
                this.isSubmitting = false;
                this.showLoader(false);
            } else {
                this.ShowAppointmentNotLocated = false;
                this.requestedAppointment = {
                    appointmentTime: r.appointmentTime,
                    confirmationNumber: r.confirmationNumber,
                    confirmationUrl: r.confirmationUrl,
                    locationId: r.locationId,
                    patient: {
                        firstName: r.patient.firstName,
                        lastName: r.patient.lastName,
                        email: this.currentUser.userInfoEmail
                    },
                    serviceId: r.serviceId
                };
                this._appointmentService.getLocation(r.locationId).subscribe((l) => {
                    this.selectedLabLocation = l;
                    this.isAppointmentAssociation = true;
                    this.ShowAppointmentDetailsCard = true;
                    this.ShowAppointmentDetailsCard = true;
                    this.ShowLocateAppointmentForm = false;
                    this.isTimeSlotSelected = true;
                    this.showAddAppointment = true;
                    this.showLoader(false);
                }, this.onError);
            }
        }, this.onError);

    }
    createDateForPicker(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }
    addAppointment() {
        if (!this.requestedAppointment) {
            this.onError();
        }
        this._appointmentService.addAppointment(this.createAppointmentRequest(this.requestedAppointment)).subscribe((d) => {
            this.goToBookingConfirm(d.confirmationNumber.toString());
        }, this.onError);
    }

    isDisabled = (date: NgbDateStruct, current: { month: number; year: number }) => {
        //only enable dates which have appointments available
        return this.availableTimeSlotsList.find((x) => this.createDateForPicker(new Date(x.DateTime)).equals(new NgbDate(date.year, date.month, date.day))) ? false : true;
    };

    selectLocationAvailableTimeSlotsClick(timeSlot: string) {
        this.requestedAppointment.appointmentTime = timeSlot;
        this.displayTimeSlotsList.forEach((s) => {
            const selected = s.DateTime === timeSlot;
            s.Selected = selected;
        });
        this.goToAppointmentForm();
    }

    toggleDetails(locationId: number) {
        if (this.locationsList.length <= 0) {
            return;
        }
        this.showLocationDetails[locationId] = !this.showLocationDetails[locationId];
    }

    editLabLocationClick() {
        this.toggleMap(this.ShowLocationMap, false);
        this.setModalDimensions(!this.isMobile && !this.useModifyFlow, false);
        this.selectedLabLocation = null;
        this.ShowChoiceCard = false;
        this.ShowTimeSlotList = false;
        this.ShowAppointmentRequestForm = false;
        this.ShowAppointmentDetailsCard = false;
        this.ShowFindLocation = true;
        this.isTimeSlotSelected = false;
    }

    editContactPhoneChange() {
        if (this.contactForm.controls.phone.value && this.contactForm.controls.phone.value.trim().length > 0) {
            this.contactForm.controls.smsApproved.enable();
        } else {
            this.contactForm.controls.smsApproved.disable();
            this.contactForm.controls.smsApproved.setValue(false);
        }
    }

    createAppointmentRequest(appointmentRequest: ILabcorpAppointmentRequestAppointment, isCanceled = false): ILabcorpAppointmentRequest {

        const request: ILabcorpAppointmentRequest = {
            ownerKey: this.apiAppointment ? this.apiAppointment.owner.key : this.currentUser.userKey,
            programServiceKey: this.apiAppointment ? this.apiAppointment.programServiceKey : this.currentService.programServiceKey,
            appointment: appointmentRequest,
            electronicOrder: isCanceled ? null : this.getElectronicOrderRequest(appointmentRequest.patient)
        };
        return request;
    }
    patientQueryAddressAutoCompleteValidate() {
        this.patientAutocompleteSettings.classList = 'form-control ng-pristine ng-valid ng-touched';
    }

    patientQueryAddressAutoCompleteInValidate() {
        this.patientAutocompleteSettings.classList = 'form-control ng-dirty ng-invalid ng-touched';
    }

    checkAddressInputValidity() {
        if (this.patientAutocompleteQuery.checkInnerValue.trim().length < 4) {
            this.patientQueryAddressAutoCompleteInValidate();
            this.isValidAddress = false;
            return false;
        }
        this.patientQueryAddressAutoCompleteValidate();
        this.isValidAddress = true;
        return true;
    }
    isContactFormValid() {
        for (const field in this.contactForm.controls) {
            const current = this.contactForm.get(field);
            if ((current.touched || current.dirty) && current.invalid) {
                return false;
            }
        }
        return true;
    }
    bookAppointment() {
        if (!this.isContactFormValid()) {
            return;
        }

        if (!this.useModifyFlow && !this.checkAddressInputValidity()) {
            this.isValidAddress = false;
            return;
        }
        this.showLoader(true);
        this.isSubmitting = true;
        this.requestedAppointment.patient.email = this.contactForm.controls.email.value;
        this.requestedAppointment.patient.phone = this.contactForm.controls.smsApproved.value ? this.contactForm.controls.phone.value : null;
        this.requestedAppointment.patient.smsApproved = this.contactForm.controls.smsApproved.value;
        this.requestedAppointment.locationId = this.selectedLabLocation.id;

        if (this.useModifyFlow === true && this.apiAppointment !== undefined) {
            this._appointmentService.updateAppointment(this.apiAppointment.key, this.requestedAppointment).subscribe((d) => {
                this.goToBookingConfirm(d.confirmationNumber.toString());
            }, this.onError);
        } else {
            this.requestedAppointment.patient.address = {
                city: this.contactForm.controls.city.value,
                line1: this.patientAutocompleteQuery.typedValue,
                state: this.contactForm.controls.stateKey.value,
                zip: this.contactForm.controls.zipCode.value,
            };
            this._appointmentService.createAppointment(this.createAppointmentRequest(this.requestedAppointment)).subscribe((d) => {
                this.goToBookingConfirm(d.confirmationNumber.toString());
            }, this.onError);
        }
    }

    goToBookingConfirm(confirmationNumber: string) {
        this.ShowAppointmentDetailsCard = false;
        this.ShowAppointmentRequestForm = false;
        this.ShowCancelConfirm = false;
        this.ShowChoiceCard = false;
        this.ShowFindLocation = false;
        this.ShowLocationMap = false;
        this.ShowLocationsList = false;
        this.ShowNoSlotsMessage = false;
        this.ShowTimeSlotList = false;
        this.ShowAppointmentLocated = false;
        this.requestedAppointment.confirmationNumber = confirmationNumber;
        this.ShowBookingConfirmation = true;
        this.showLoader(false);
        this.isSubmitting = false;
    }

    showLoader(doShow: boolean) {
        if (doShow) {
            this.modalRef = this._modalService.open(LoadingComponent, {
                backdrop: 'static',
                windowClass: 'loadingModal'
            });
        } else {
            if (this.modalRef) {
                this.modalRef.close();
            }
        }
    }

    onTimeSlotDateSelected(e) {
        window.setTimeout(() => {
            this.filterAvailableSlots(this.slotFilterForm.controls.appointmentDate.value);
        }, 500);
    }

    mobileAndTabletCheck(): boolean {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor);
        return check;
    }

    onError(msg: any = null, header: any = null, callback?: Function): Promise<any> {
        this.showLoader(false);
        let errorMessage = this._localizeService.get('unknownerror', 'message');
        if (msg) {
            errorMessage = typeof msg === 'string' || msg instanceof String ? msg.toString() : errorMessage;
            if (msg.error && msg.error.errorMessages && msg.error.errorMessages.length) {
                const messageValue = msg.error.errorMessages;
                if (typeof messageValue === 'string' || messageValue instanceof String) {
                    errorMessage = messageValue.toString()
                } else if (messageValue instanceof Array) {
                    errorMessage = msg.error.errorMessages.join('</br></br>');
                }
            }
        }
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        if (header != null) {
            this.modalRef.componentInstance.header = this._localizeService.get(header, 'label');
        }
        this.modalRef.componentInstance.msg = errorMessage;
        this.modalRef.result.then((err) => {
            this.isSubmitting = false;
        });
        return this.modalRef.result;
    }

    cancelModal(result: any = false, now = false) {
        if (now) {
            if (this.ShowBookingConfirmation) {
                window.location.reload();
                this._activeModal.close(result);
                return;
            }
            this._activeModal.close(result);
            return;
        }
        if (confirm(this._localizeService.get('areyousureyouwanttocancelmessage', 'message'))) {
            this._activeModal.close(result);
        }
    }
    getElectronicOrderRequest(patient: ILabcorpAppointmentRequestPatient): IElectronicOrderModel {
        const receivePaperCommunications: boolean = this.contactForm.controls.receivePaperCommunications.value;
        const order: IElectronicOrderModel = {
            userKey: this.apiAppointment ? this.apiAppointment.owner.key : this.currentUser.userKey,
            personKey: this.apiAppointment ? this.apiAppointment.owner.personKey : this.currentUser.personKey,
            programServiceKey: this.apiAppointment ? this.apiAppointment.programServiceKey : this.currentService.programServiceKey,
            email: patient.email,
            phone: patient.phone,
            address1: patient.address.line1,
            city: patient.address.city,
            stateAbbreviation: patient.address.state,
            zip: patient.address.zip,
            receivePaperCommunications: receivePaperCommunications,
        }
        return order;
    }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-COVID19TestingReportingRequirements">{{ 'coviduserdatacapturemodalheader' | lesCustomLocalize : 'label' }}</h4>
</div>

<div class="modal-body">
    <form ngForm #userDataCaptureForm id="formId-COVID19TestingReportingRequirements">
        <div class="row capture-header p-2">
            {{ 'covidrequirementdatacapture' | lesCustomLocalize : 'message' }}
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
                <label for="address1">{{ 'address' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="address1" id="address1" required [(ngModel)]="userInformation.address1" />
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <label for="address2">{{ 'address2' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="address2" id="address2" [(ngModel)]="userInformation.address2" />
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-4">
                <label for="city">{{ 'city' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="city" id="city" required [(ngModel)]="userInformation.city" />
            </div>
            <div class="col-sm-4">
                <label for="state">{{ 'state' | lesCustomLocalize : 'label' }}</label>
                <select name="stateKey" id="stateKey" required class="form-control" [(ngModel)]="userInformation.stateKey">
                    <option value="null">{{ 'noneselected' | lesCustomLocalize : 'label' }}</option>
                    <option *ngFor="let state of states" [value]="state.key">
                        {{ state.name }}
                    </option>
                </select>
            </div>
            <div class="col-sm-4">
                <label for="zipcode">{{ 'zipcode' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="zip" id="zip" required pattern="^[0-9]{5}(?:-[0-9]{4})?$" [(ngModel)]="userInformation.zip" />
            </div>
        </div>
        <!-- https://stackoverflow.com/questions/46422160/angular2-4-telephone-validation -->
        <div class="form-group row">
            <div class="col-sm-6">
                <label for="phone">{{ 'phonenumber' | lesCustomLocalize : 'label' }}</label>
                <input type="text" class="form-control" name="phone" id="phone" required [(ngModel)]="userInformation.phone" pattern="^\s*(?:\+?\d{1,3})?[- (]*\d{3}(?:[- )]*\d{3})?[- ]*\d{4}(?: *[x/#]\d+)?\s*$" placeholder="555-555-5555" />
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-6">
                <label for="ethnicity">{{ 'ethnicity' | lesCustomLocalize : 'label' }}</label>
                <select name="ethnicityKey" id="ethnicityKey" required class="form-control" [(ngModel)]="userInformation.ethnicityKey" minlength="32">
                    <option value="null">{{ 'noneselected' | lesCustomLocalize : 'label' }}</option>
                    <option *ngFor="let ethnicity of ethnicities" [value]="ethnicity.key">
                        {{ ethnicity.labcorpValue | lesCustomLocalize : 'ethnicity' }}
                    </option>
                </select>
            </div>
            <div class="col-sm-6">
                <label for="race">{{ 'race' | lesCustomLocalize : 'label' }}</label>
                <select name="raceKey" id="raceKey" required class="form-control" [(ngModel)]="userInformation.raceKey" minlength="32">
                    <option value="null">{{ 'noneselected' | lesCustomLocalize : 'label' }}</option>
                    <option *ngFor="let race of races" [ngValue]="race.key">
                        {{ race.labcorpValue | lesCustomLocalize : 'race' }}
                    </option>
                </select>
            </div>
        </div>
    </form>

    <div class="row" *ngIf="showError && errorMsg">
        <div class="col-sm-12" id="errorAlertId-COVID19TestingReportingRequirements">
            {{ lesCustomLocalizeMessage(errorMsg) | lesCustomLocalize : 'message' }}
        </div>
    </div>

    <div class="row" *ngIf="showError && errorList.length">
        <div class="col-sm-12" id="validationErrorId-COVID19TestingReportingRequirements">
            {{ 'validationerrors' | lesCustomLocalize : 'message' }}
        </div>
        <div class="col-sm-12" id="validationErrorId-COVID19TestingReportingRequirements" *ngFor="let error of errorList">
            {{ lesCustomLocalizeValidationErrors(error) | lesCustomLocalize : 'message' }}
        </div>
    </div>
</div>

<div class="modal-footer pt-10">
    <button class="tertiary-bg btn btn-primary" id="actionId-CancelCOVID19TestingReportingRequirements" (click)="cancel()">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
    <button type="submit" class="tertiary-bg btn btn-primary" id="actionId-SaveCOVID19TestingReportingRequirements" [disabled]="!userDataCaptureForm.form.valid" (click)="save()">
        {{ 'save' | lesCustomLocalize : 'label' }}
    </button>
</div>

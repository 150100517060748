import { Component, Input,OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../shared/localize/localize.service';
import { Person } from '../shared/person/person';
import { PortalPromptModalService } from './portal-prompt-modal.service';
import { PortalPrompt } from './portalprompt'

@Component({
  selector: 'app-portal-prompt-modal',
  templateUrl: './portal-prompt-modal.component.html',
  styleUrls: ['./portal-prompt-modal.component.css']
})
export class PortalPromptModalComponent implements OnInit {
    @Input() person: Person
    @Input() portalPrompt: PortalPrompt
    hidePromptPermanently = false
  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService,
     private _portalPromptModalService: PortalPromptModalService) { }

  ngOnInit() {
    
  }

  savePromptAcknowledgement(){ 
    this._portalPromptModalService.savePromptAcknowledgement(this.portalPrompt.key, this.person.userKey, this.hidePromptPermanently)
    .subscribe(result =>{
      this.activeModal.close();
    })
  }
}

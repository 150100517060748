
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PersonDataCollected, PersonDataCollectedCriteria, PersonDataToCollectSaveRequest, PersonDataToCollectView, PersonValidation, PersonValidationRuleViolationResult } from './person.data.collection.models';

@Injectable()
export class PersonDataCollectionService {

    constructor(private _http: HttpClient) { }

    getPersonDataToCollect(personKey: string, search: PersonDataCollectedCriteria): Observable<PersonDataToCollectView[]> {
        return this._http.post<PersonDataToCollectView[]>(environment.baseCoreApiEndpoint + `/person/data-collection/${personKey}/to-collect`, search).pipe(
            catchError(this.handleError));
    }
    validatePersonResponses(personKey: string, dataResponsesValidation: PersonValidation): Observable<PersonValidationRuleViolationResult> {

        return this._http.post<PersonValidationRuleViolationResult>(environment.baseCoreApiEndpoint + `/person/data-collection/${personKey}/validate`,
            dataResponsesValidation).pipe(
                catchError(this.handleError));
    }
    savePersonDataCollectedResponses(personKey: string, companyKey: string, dataResponses: PersonDataToCollectSaveRequest): Observable<PersonDataCollected> {
        let params = new HttpParams();
        params = params.append('companyKey', companyKey);
        return this._http.post<PersonDataCollected>(environment.baseCoreApiEndpoint + `/person/data-collection/${personKey}`,
            dataResponses, { params: params }).pipe(
                catchError(this.handleError));
    }
    private handleError(err: HttpErrorResponse) {
        if (err.status == 400 && (err.error instanceof String || typeof err.error == "string")) {
            return observableThrowError(err);
        }
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }

}

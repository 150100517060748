
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of,throwError as observableThrowError } from 'rxjs';
import { catchError, map,publishReplay, refCount } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiAppointment, CancelReason } from '../../scheduler/model/api-appointment';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { Utils } from '../../shared/utils';
import { CoachingAppointment } from './appointment';
import { AppointmentSearchCriteria } from './appointment-search-criteria';


@Injectable()
export class CoachingAppointmentService {
  private nextApptsSource = new BehaviorSubject<CoachingAppointment[]>(null);
  nextAppts = this.nextApptsSource.asObservable();

  constructor(private _http: HttpClient, private _localizeService: LocalizeService) { }

  getAppts(criteria: AppointmentSearchCriteria, resetFutureAppts = false) {
    if (resetFutureAppts) {
      this.getFutureAppts(criteria.participantIdentifier);
    }

    if (criteria.myIdentifier) {
      this.getFutureAppts(criteria.myIdentifier);
    } else {
      return this._http.post<CoachingAppointment[]>(environment.baseCoachingApptsEndpoint + '/search', criteria).pipe(

        map((apptsFetched: CoachingAppointment[]) => {
          return apptsFetched.map(appt => {
            return new CoachingAppointment(appt);
          });
        }), publishReplay(1), refCount(),
        catchError(this.handleError),);
    }
  }

  getFutureAppts(userKey: string) {
    const criteria = new AppointmentSearchCriteria();
    criteria.participantIdentifier = userKey;
    criteria.rangeStartDate = Utils.toUTC(new Date());
    criteria.rangeEndDate = new Date('2100-01-01T00:00:00Z');

    this._http.post<CoachingAppointment[]>(environment.baseCoachingApptsEndpoint + '/search', criteria).pipe(
      map((apptsFetched: CoachingAppointment[]) => {
        return apptsFetched.map(appt => {
          return new CoachingAppointment(appt);
        });
      }))
      .subscribe(data => {
        this.nextApptsSource.next(data);
      }, err => this.handleError(err));
  }

  getUpcomingAppts(userKey: string, viewAll: boolean, person: Person): Observable<ApiAppointment[]> {
    if (!person.isParticipant && !person.canSeeParticipantCoaching) {
      const emptyAppointments: ApiAppointment[] = [];
      return of(emptyAppointments)
    }
    const criteria = new AppointmentSearchCriteria();
    criteria.participantIdentifier = userKey;
    if (viewAll) {
      criteria.rangeStartDate = new Date('2000-01-01T00:00:00.000Z');
    } else {
      criteria.rangeStartDate = Utils.toUTC(new Date());
    }
    criteria.rangeEndDate = new Date('2100-01-01T00:00:00Z');

    return this._http.post<ApiAppointment[]>(environment.baseCoachingApptsEndpoint + '/search', criteria).pipe(
      map((apptsFetched: ApiAppointment[]) => {
        return apptsFetched.map(appt => {
          if (appt.location) {
            appt.location.address1 = this._localizeService.get(appt.location.address1, 'label');
          }

          return new ApiAppointment(appt);
        });
      }),
      catchError(this.handleError),);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-Timeout">{{ 'timeout' | lesCustomLocalize : 'label' }}</h4>
</div>

<div class="modal-body">
    <ng-container id="errorAlertId-YourSessionWillExpire" *ngIf="numberOfMinutes != null">
        {{ 'yoursessionisabouttoexpire' | lesCustomLocalize : 'message' : numberOfMinutes }}
    </ng-container>
</div>

<div class="modal-footer pt-10">
    <button type="button" class="tertiary-bg btn btn-primary" id="actionId-LogoutTimeout" (click)="logout()">{{ 'logout' | lesCustomLocalize : 'label' }}</button>
    <button type="button" class="tertiary-bg btn btn-primary" id="actionId-CloseTimeout" (click)="activeModal.close('Close click')">{{ 'yes' | lesCustomLocalize : 'label' }}</button>
</div>

<ng-container *ngIf="person">
    <div class="alert alert-info" role="alert">
        <div class="row">
            <div class="col">
                <h4 class="secondary-text alert-heading" id="{{ 'titleId-' + htmlId }}">
                    {{ headingLocalizeProp | lesCustomLocalize : 'label' }}:
                    <a href="#" (click)="goToDetail($event)" id="{{ 'actionId-GoToDetail' + htmlId }}">
                        <small class="text-muted"
                            >{{ person.fullName }}<span *ngIf="person.company">, {{ person.company.companyName }}</span></small
                        >
                    </a>
                </h4>
            </div>
        </div>

        <div class="row">
            <div *ngIf="person.preferredPhone" id="displayId-PrimaryPhone" class="col-sm-auto">
                <h6 class="detail secondary-text">
                    <span class="detail-title">{{ 'primaryphone' | lesCustomLocalize : 'label' }}:</span> <span class="text-muted">{{ person.preferredPhone }}</span>
                </h6>
            </div>

            <div *ngIf="person.preferredPhone" class="d-none d-sm-block pipe">|</div>
            <div *ngIf="person.preferredTextMessagePhone" id="displayId-MobilePhone" class="col-sm-auto">
                <h6 class="detail secondary-text">
                    <span class="detail-title">{{ 'mobilephone' | lesCustomLocalize : 'label' }}:</span> <span class="text-muted">{{ person.preferredTextMessagePhone }}</span>
                </h6>
            </div>

            <div *ngIf="person.preferredTextMessagePhone" class="d-none d-sm-block pipe">|</div>
            <div *ngIf="!person.hasPreferredPhoneValues && person.participantPhone" id="displayId-Eligibility1Phone" class="col-sm-auto">
                <h6 class="detail secondary-text">
                    <span class="detail-title">{{ 'eligibilityphone1' | lesCustomLocalize : 'label' }}:</span> <span class="text-muted">{{ person.participantPhone }}</span>
                </h6>
            </div>

            <div *ngIf="!person.hasPreferredPhoneValues && person.participantPhone" class="d-none d-sm-block pipe">|</div>
            <div *ngIf="!person.hasPreferredPhoneValues && person.participantPhone2" id="displayId-Eligibility2Phone" class="col-sm-auto">
                <h6 class="detail secondary-text">
                    <span class="detail-title">{{ 'eligibilityphone2' | lesCustomLocalize : 'label' }}:</span> <span class="text-muted">{{ person.participantPhone2 }}</span>
                </h6>
            </div>

            <div *ngIf="!person.hasPreferredPhoneValues && person.participantPhone2" id="displayId-NoPhoneInformationAvailable" class="d-none d-sm-block pipe">|</div>
            <div class="detail text-muted col-sm-auto" *ngIf="!person.hasPhoneValues">
                <span>{{ 'nophonesavailable' | lesCustomLocalize : 'label' }}</span>
            </div>

            <div *ngIf="!person.hasPhoneValues" class="d-none d-sm-block pipe">|</div>
            <div class="col-sm-auto">
                <h6 *ngIf="person.userInfoEmail" class="detail secondary-text" id="displayId-EmailAddress">
                    <span class="detail-title">{{ 'email' | lesCustomLocalize : 'label' }}:</span> <span class="text-muted">{{ person.userInfoEmail }}</span>
                </h6>
            </div>
        </div>
    </div>
</ng-container>

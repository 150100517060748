import _ from 'lodash';
import moment from 'moment';

export class EventDateAndLocation {
  programServiceTypeKey: string;
  eventLocationKey: string;
  locationName: string;
  startDate: Date;
  endDate: Date;
  scheduleLockDate: Date;
  scheduleLockDateUTC: Date;
  hasSLotsAvailable: boolean;

  constructor(data) {
    this.programServiceTypeKey = data.programServiceTypeKey;
    this.eventLocationKey = data.locationKey;
    this.locationName = data.location;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.scheduleLockDate = data.scheduleLockDate;
    this.scheduleLockDateUTC = data.scheduleLockDateUTC;
    this.hasSLotsAvailable = data.hasSLotsAvailable;
  }
}

export class EventDateAndLocationCollection {
  eventDateAndLocation: EventDateAndLocation[] = [];

  constructor(data) {
    this.eventDateAndLocation = data;
  }

  eventsExist(theDate: Date): boolean {
    let isEventsFound = false;
    const eventDateAndLocation: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
      const thisStartDt = moment(new Date(edl.startDate));
      const thisEndDt = moment(new Date(edl.endDate));
      return moment(theDate).startOf('day').isSameOrAfter(thisStartDt.startOf('day'))
        && moment(theDate).startOf('day').isSameOrBefore(thisEndDt.startOf('day'));
    });
    if (eventDateAndLocation) {
      isEventsFound = true;
    }
    return isEventsFound;
  }

  isEventClosed(theDate: Date, serviceTypesNeeded: string[]): boolean {
    let isEventClosed = true;
    if (serviceTypesNeeded.length) {
      for (let service = 0; service < serviceTypesNeeded.length; service++) {
        const eventDateAndLocation: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
          const thisStartDt = moment(new Date(edl.startDate));
          const thisEndDt = moment(new Date(edl.endDate));
          const thisScheduleLockDt = new Date(edl.scheduleLockDate);
          return moment(theDate).startOf('day').isSameOrAfter(thisStartDt.startOf('day'))
            && moment(theDate).startOf('day').isSameOrBefore(thisEndDt.startOf('day'))
            && edl.programServiceTypeKey === serviceTypesNeeded[service]
            && moment(thisScheduleLockDt).isAfter(moment());
        });
        if (eventDateAndLocation) {
          isEventClosed = false;
        }
      }
    }
    return isEventClosed;
  }

  isEventFull(theDate: Date, serviceTypesNeeded: string[]): boolean {
    let isEventFull = false;
    if (serviceTypesNeeded.length) {
      for (let service = 0; service < serviceTypesNeeded.length; service++) {
        const eventDateAndLocation: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
          const thisStartDt = moment(new Date(edl.startDate));
          const thisEndDt = moment(new Date(edl.endDate));
          const thisScheduleLockDt = new Date(edl.scheduleLockDate);
          return moment(theDate).startOf('day').isSameOrAfter(thisStartDt.startOf('day'))
            && moment(theDate).startOf('day').isSameOrBefore(thisEndDt.startOf('day'))
            && edl.programServiceTypeKey === serviceTypesNeeded[service]
            && !edl.hasSLotsAvailable
            && moment(thisScheduleLockDt).isAfter(moment());
        });
        if (eventDateAndLocation) {
          isEventFull = true;
        }
      }
    } else {
      isEventFull = true;
    }
    return isEventFull;
  }

  // Two modes exist, one when searching by location and the other when searching by date.
  // The presence of the location parameter indicates the mode.
  getDateStyle(theDate: Date, serviceTypesNeeded: string[], locationName: string): string {
    if (this.eventDateAndLocation.length) {
      let servicesFound = '';
      if (serviceTypesNeeded.length === 1) {
        // Only set style for first service found. May have same service combination for multiple locations.
        const eventDateAndLocation: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
          const thisDt = new Date(edl.startDate);
          const thisScheduleLockDt = new Date(edl.scheduleLockDate);
          return thisDt.getFullYear() === theDate.getFullYear()
            && thisDt.getMonth() === theDate.getMonth()
            && thisDt.getDate() === theDate.getDate()
            && edl.programServiceTypeKey === serviceTypesNeeded[0]
            && (edl.locationName === locationName || locationName === null)
            && edl.hasSLotsAvailable
            && moment(thisScheduleLockDt).isAfter(moment());
        });
        if (eventDateAndLocation) {
          servicesFound = '-match';
        }
      } else {
        let thisDt = null;
        let thisScheduleLockDt = null;
        // Check events to see if any location offers both
        for (let d = 0; d < this.eventDateAndLocation.length; d++) {
          thisDt = new Date(this.eventDateAndLocation[d].startDate);
          thisScheduleLockDt = new Date(this.eventDateAndLocation[d].scheduleLockDate);
          if (thisDt.getFullYear() === theDate.getFullYear()
            && thisDt.getMonth() === theDate.getMonth()
            && thisDt.getDate() === theDate.getDate()
            && (this.eventDateAndLocation[d].locationName === locationName || locationName === null)
            && this.eventDateAndLocation[d].hasSLotsAvailable
            && moment(thisScheduleLockDt).isAfter(moment())) {
            // Look for another event at same location for different service type.
            const bothServicesOffered: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
              thisDt = new Date(edl.startDate);
              thisScheduleLockDt = new Date(edl.scheduleLockDate);
              return thisDt.getFullYear() === theDate.getFullYear()
                && thisDt.getMonth() === theDate.getMonth()
                && thisDt.getDate() === theDate.getDate()
                && edl.eventLocationKey === this.eventDateAndLocation[d].eventLocationKey
                && edl.programServiceTypeKey !== this.eventDateAndLocation[d].programServiceTypeKey
                && edl.hasSLotsAvailable
                && moment(thisScheduleLockDt).isAfter(moment());
            });
            if (bothServicesOffered) {
              servicesFound = '-match';
              break;
            }
          }
        }
        if (!servicesFound.length) {
          // Set for a date on which there is ANY location that has an event
          // and no other locations offer the other event or both events on this date.
          let onlyOneServiceFound = '';
          for (let service = 0; service < serviceTypesNeeded.length; service++) {
            for (let dateAndLoc = 0; dateAndLoc < this.eventDateAndLocation.length; dateAndLoc++) {
              thisDt = new Date(this.eventDateAndLocation[dateAndLoc].startDate);
              thisScheduleLockDt = new Date(this.eventDateAndLocation[dateAndLoc].scheduleLockDate);
              if (thisDt.getFullYear() === theDate.getFullYear()
                && thisDt.getMonth() === theDate.getMonth()
                && thisDt.getDate() === theDate.getDate()
                && (this.eventDateAndLocation[dateAndLoc].locationName === locationName || locationName === null)
                && this.eventDateAndLocation[dateAndLoc].programServiceTypeKey === serviceTypesNeeded[service]
                && this.eventDateAndLocation[dateAndLoc].hasSLotsAvailable
                && moment(thisScheduleLockDt).isAfter(moment())) {
                const otherServiceTypesExists: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
                  thisDt = new Date(edl.startDate);
                  thisScheduleLockDt = new Date(edl.scheduleLockDate);
                  return thisDt.getFullYear() === theDate.getFullYear()
                    && thisDt.getMonth() === theDate.getMonth()
                    && thisDt.getDate() === theDate.getDate()
                    && (edl.locationName === locationName || locationName === null)
                    && edl.programServiceTypeKey !== serviceTypesNeeded[service]
                    && edl.hasSLotsAvailable
                    && moment(thisScheduleLockDt).isAfter(moment());
                });
                if (otherServiceTypesExists === undefined) {
                  onlyOneServiceFound = serviceTypesNeeded[service];
                  break;
                }
              }
            }
            if (onlyOneServiceFound.length) {
              break;
            }
          }
          if (onlyOneServiceFound.length) {
            servicesFound = '-partial-match';
          }
        }
        // Set for a date on which there is ANY location that has EITHER type of event.
        // No other locations offers both on this date.
        if (!servicesFound.length && !locationName) {
          const atLeastOneServiceTypeExists: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
            thisDt = new Date(edl.startDate);
            thisScheduleLockDt = new Date(edl.scheduleLockDate);
            return thisDt.getFullYear() === theDate.getFullYear()
              && thisDt.getMonth() === theDate.getMonth()
              && thisDt.getDate() === theDate.getDate()
              && edl.hasSLotsAvailable
              && moment(thisScheduleLockDt).isAfter(moment());
          });
          if (atLeastOneServiceTypeExists) {
            servicesFound = '-partial-match';
          }
        }
      }
      // If no services have been found, check to see if any closed or full events exist for this day.
      if (!servicesFound.length) {
        const eventFullOrClosed: EventDateAndLocation = _.find(this.eventDateAndLocation, (edl: EventDateAndLocation) => {
          const thisDt = new Date(edl.startDate);
          const thisScheduleLockDt = new Date(edl.scheduleLockDate);
          return thisDt.getFullYear() === theDate.getFullYear()
            && thisDt.getMonth() === theDate.getMonth()
            && thisDt.getDate() === theDate.getDate()
            && (edl.locationName === locationName || locationName === null)
            && ((serviceTypesNeeded.length === 1 && edl.programServiceTypeKey === serviceTypesNeeded[0]) || serviceTypesNeeded.length > 1)
            && (!edl.hasSLotsAvailable || moment(thisScheduleLockDt).isBefore(moment()));
        });
        if (eventFullOrClosed) {
          servicesFound = '-fullorclosedevent';
        }
      }
      return servicesFound;
    } else {
      return '';
    }
  }
}

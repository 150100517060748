
import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of as ObservableOf, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { SmartyStreetsService } from '../smartystreets/smartystreets.api.service';
import { IUsAutoCompleteSuggestion } from "../smartystreets/smartystreets.models";
import { AddressAutoCompleteSettings } from './addressautocomplete-settings';
const EMPTY = new Observable<never>(sub => sub.complete());
const addressAutoCompleteControlValueAccessor: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddressAutoCompleteComponent),
  multi: true,
};

@Component({
  selector: 'app-addressautocomplete',
  templateUrl: './addressautocomplete.component.html',
  styleUrls: ['./addressautocomplete.component.css'],
  providers: [addressAutoCompleteControlValueAccessor]
})
export class AddressAutoCompleteComponent implements ControlValueAccessor {
  @Input() settings: AddressAutoCompleteSettings;
  @Input()
  get value(): string {
    return this.innerValue;
  }
  get typedValue() {
    return this.innerInputValue;
  }
  get selectedSuggestions() {
    return this.suggestions;
  }

  set selectedSuggestions(suggestions: IUsAutoCompleteSuggestion[]) {
    this.suggestions = suggestions;
  }
  innerValue = "";
  disabled = false;
  requiredField = true;
  private innerInputValue = "";
  private suggestions: IUsAutoCompleteSuggestion[] = [];
  private onTouched: Function;
  private onChanged: Function;
  constructor(private _smartyStreetsService: SmartyStreetsService) {

  }

  set value(v: string) {
    if (this.innerValue !== v) {
      this.innerValue = v;
      this.innerInputValue = v;
    }
  }
  get checkInnerValue(): string {
    return this.innerInputValue;
  }
  writeValue(value: string): void {
    if (value !== this.innerValue) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  autoCompleteAddress(query: any, maxResults: number, selected: string): Observable<IUsAutoCompleteSuggestion[]> {
    this.innerInputValue = query;
    if ((typeof query === "string" || query instanceof String) && query.toString().length >= this.settings.typeaheadMinLength) {
      if (this.selectedSuggestions && this.selectedSuggestions.length > 0) {
        const filteredSuggest = this.getFilterSuggestions(query.toString());
        if (filteredSuggest && filteredSuggest.length > 1 || (filteredSuggest.length == 1 && parseInt(filteredSuggest[0].entries) <= 1)) {
          return ObservableOf(filteredSuggest);
        } else {
          const filteredselected = this.getFilteredSelected(query.toString(), selected);
          if (filteredselected && filteredselected.length == 0) {
            selected = "";
          }
        }
      }
      return this._smartyStreetsService.autoCompleteAddress(query.toString(), maxResults, selected);
    }
    this.suggestions = [];
    this.settings.selected = null;
    return EMPTY;
  }

  getFilteredSelected(query: string, suggestionSelected: string): IUsAutoCompleteSuggestion[] {
    const trimmedQuery = `${query.toLowerCase().trim()}`;
    const filteredselected = this.selectedSuggestions.filter(suggestion => {
      const remainingQuery = trimmedQuery.replace(suggestion.street.toLowerCase(), '').trim();
      return remainingQuery !== '' && suggestionSelected.toLowerCase().includes(` ${remainingQuery} `);
    });

    return filteredselected;
  }

  getFilterSuggestions(query: string): IUsAutoCompleteSuggestion[] {
    const trimmedQuery = `${query.toLowerCase().trim()}`;
    const filteredSuggestions = this.selectedSuggestions.filter(suggestion => {
      const remainingQuery = trimmedQuery.replace(suggestion.street.toLowerCase(), '').trim();
      return remainingQuery !== '' && suggestion.secondary.toLowerCase().includes(remainingQuery);

    });

    return filteredSuggestions;
  }

  restoreFocusToInput() {
    const inputElement = document.getElementById(this.settings.formControlId);
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus();
        const event = new Event('input', { bubbles: true });
        inputElement.dispatchEvent(event);
      }, 0);

    }
  }
  onTypeaheadQuery = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((query) => {
        return this.autoCompleteAddress(query, this.settings.maxResults, this.settings.selected);
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
}

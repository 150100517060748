import { AbbreviatedUser } from '../../shared/abbreviated-user';

export class CoachingAppointment {
  key: string;
  coachUser: AbbreviatedUser;
  participantUser: AbbreviatedUser;
  startDate: Date;
  endDate: Date;
  status: string;
  confirmationNumber: string;
  followUpMessageKey: string;
  followUpDate: Date;
  appointmentType: string;
  // Updated manually from outside this class
  timeZone: string;

  constructor(config: any) {
    this.key = config.key;
    this.coachUser = config.coachUser;
    this.participantUser = config.participantUser;
    this.startDate = config.startDate;
    this.endDate = config.endDate;
    this.status = config.status;
    this.confirmationNumber = config.confirmationNumber;
    this.followUpMessageKey = config.followUpMessageKey;
    this.followUpDate = config.followUpDate;
    this.appointmentType = config.appointmentType;
  }

  get isCancelled(): boolean {
    return this.status.toUpperCase() === 'CANCELLED';
  }

  get isPending(): boolean {
    return this.status.toUpperCase() !== 'CANCELLED' &&
      this.status.toUpperCase() !== 'COMPLETED' &&
      this.status.toUpperCase() !== 'NOSHOW';
  }

  get participant(): string {
    return this.participantUser ? (this.participantUser.firstName + ' ' + this.participantUser.lastName) : undefined;
  }

  get coach(): string {
    return this.coachUser ? (this.coachUser.firstName + ' ' + this.coachUser.lastName) : undefined;
  }

  get company(): string {
    return this.participantUser && this.participantUser.companyName ? this.participantUser.companyName : undefined;
  }

  get hasFollowUp(): boolean {
    return this.followUpMessageKey !== null && typeof this.followUpMessageKey !== 'undefined';
  }
}

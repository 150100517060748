<div class="wrapper" *ngIf="(isReadyForRender && !hasError) || hasReloadError">
    <div class="content-area">
        <nav id="nav-header" class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/home">
                <img [src]="logoSrc" class="img-fluid" alt="Logo" />
            </a>

            <button class="tertiary-bg navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" [attr.aria-expanded]="!isNavbarCollapsed" aria-controls="navbarContent">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isNavbarCollapsed" *ngIf="!hasReloadError">
                <ul class="navbar-nav ms-auto secondary-text">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home" routerLinkActive="active" id="actionId-Home" (click)="isNavbarCollapsed = true">{{ 'home' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && currentUser.hasResultsAccess">
                        <a class="nav-link" routerLink="/results" routerLinkActive="active" id="actionId-Results" (click)="isNavbarCollapsed = true">{{ 'results' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && currentUser.isCoachAdmin">
                        <a class="nav-link" routerLink="/coaching/admin" routerLinkActive="active" id="actionId-CoachingAdmin" (click)="isNavbarCollapsed = true">{{ 'coachingadmindashboard' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && currentUser.isCoach">
                        <a class="nav-link" routerLink="/coaching/dashboard" routerLinkActive="active" id="actionId-CoachingDashboard" (click)="isNavbarCollapsed = true">{{ 'coachingdashboard' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && currentUser.canCustomize">
                        <a class="nav-link" routerLink="/customize" routerLinkActive="active" id="actionId-Customize" (click)="isNavbarCollapsed = true">{{ 'customize' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && currentUser.canUserAdmin">
                        <a class="nav-link" [href]="userAdminURL" id="actionId-AccountAdministration">{{ 'accountadministration' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="userHasTableauAccess">
                        <a class="nav-link" routerLink="/reporting" routerLinkActive="active" id="actionId-Reporting" (click)="isNavbarCollapsed = true">{{ 'reporting' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/support" routerLinkActive="active" id="actionId-Support" (click)="isNavbarCollapsed = true">{{ 'support' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && currentUser.isParticipant">
                        <a class="nav-link" routerLink="/my-profile" routerLinkActive="active" id="actionId-MyProfile" (click)="isNavbarCollapsed = true">{{ 'myprofile' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item" *ngIf="currentUser && !currentUser.participantKey && currentUser.company.supportsLocalParticipantRegistration" [ngStyle]="selfVerifyStyle">
                        <a class="nav-link" [href]="selfVerifyUrl" id="actionId-SelfVerify">{{ 'selfverify' | lesCustomLocalize : 'label' }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/logout" routerLinkActive="active" id="actionId-Logout" (click)="isNavbarCollapsed = true">{{ 'logout' | lesCustomLocalize : 'label' }}</a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="text-right welcome">
            <span *ngIf="formattedCompanyInformation" class="companyadmininfo"> {{ formattedCompanyInformation }}</span> <span *ngIf="currentUser">{{ 'welcomeuser' | lesCustomLocalize : 'message' : currentUser.firstName }}</span>
        </div>

        <!-- Logout Reminder -->
        <div *ngIf="showLogoutReminder" class="alert alert-info-reminder primary-bg">
            <button type="button" class="close" aria-label="Close" id="actionId-CloseLogoutReminder" (click)="closeLogoutReminder()">
                <span aria-hidden="true">&times;</span>
            </button>
            <span class="logout-reminder-bold">{{ 'logoutreminderhelp' | lesCustomLocalize : 'message' }}</span> <span class="logout-reminder-logout">{{ 'logoutreminderlogout' | lesCustomLocalize : 'message' }}</span>
        </div>

        <div class="container-fluid body-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<footer class="primary-bg" id="footer" *ngIf="isReadyForRender">
    <div class="row" style="padding: 1rem">
        <div class="col-md-6">
            <div class="footer-content">
                <span> &copy;Labcorp Employer Services, Inc., a subsidiary of Laboratory Corporation of America Holdings (Labcorp). All Rights Reserved. </span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="float-md-right footer-content">
                <span>
                    <a class="footer-link" href="https://www.labcorp.com/compliance/privacy/hipaa" id="actionId-LabcorpNoticeofPrivacyPractices" target="_blank">{{ 'labcorphipaanotice' | lesCustomLocalize : 'label' }}</a> |
                    <a class="footer-link" (click)="showTermsOfUse()" id="actionId-TermsOfUse">{{ 'termsofuse' | lesCustomLocalize : 'label' }}</a> |
                    <a class="footer-link" (click)="showPrivacyPolicy()" id="actionId-PrivacyPolicy">{{ 'privacypolicy' | lesCustomLocalize : 'label' }}</a>
                </span>
            </div>
        </div>
    </div>
</footer>

<div *ngIf="!isReadyForRender && !hasError && !hasReloadError" class="text-center p-5">
    <div class="alert alert-info" role="alert"><i class="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i> Loading...</div>
</div>
<div *ngIf="hasError && isReadyForRender" class="text-center p-5">
    <div class="alert alert-danger" role="alert"><i class="fa fa-spinner fa-2x fa-spin" aria-hidden="true"></i> {{ 'unknownerrorloggedout' | lesCustomLocalize : 'message' }}</div>
</div>

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-PersonWidget">{{ header }}</h4>
    <button type="button" class="close" id="actionId-ClosePersonWidget" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" [innerHTML]="msgLanguage"></div>

<div class="modal-body" *ngIf="showCustomError && msgTagLanguages.length > 0" id="errorAlertId-PersonWidget">
    <p *ngFor="let error of msgTagLanguages" [innerHTML]="error"></p>
</div>

<div class="modal-footer pt-10">
    <button type="button" class="tertiary-bg btn btn-primary" id="actionId-ClosePersonWidget" (click)="activeModal.close()">{{ 'close' | lesCustomLocalize : 'label' }}</button>
</div>

<ng-container *ngIf="readyForRender && !hasError">
    <div class="modal-header">
        <h4 class="secondary-text modal-title" id="titleId-Consent">
            {{ consent.authorizationPrompt }}
        </h4>
        <button type="button" class="close" id="action-CloseConsent" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <p class="error text-danger font-weight-bold" *ngIf="isOffsiteConsents">{{ 'loginConsentPrompt' | lesCustomLocalize : 'label' }}</p>
        <div [innerHTML]="replaceNewlines(consent.authorizationText) | safeHtml"></div>
        <br />
        <div class="" style="display: block">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="agree" [(ngModel)]="isAgreed" (change)="toggleAgree($event)" />
                <label class="form-check-label" for="agree">
                    {{ consent.acceptActionText }}
                </label>
            </div>

            <div class="form-check" *ngIf="!consent.declineNotAvailable">
                <input type="checkbox" class="form-check-input" id="decline" [(ngModel)]="isDeclined" (change)="toggleDecline($event)" />
                <label class="form-check-label" for="decline">
                    {{ consent.declineActionText }}
                    <span *ngIf="consent.declineText" [innerHTML]="consent.declineText | safeHtml : 'font-size:1.2em;display:inline'"> </span>
                </label>
            </div>

            <div class="row" *ngIf="isDeclined && consent.declineConfirmationText">
                <div class="col-md-12">
                    <div [innerHTML]="replaceNewlines(consent.declineConfirmationText) | safeHtml"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p *ngIf="showAttemptSaveError" class="error text-danger font-weight-bold">
                        {{ 'missingconsent2' | lesCustomLocalize : 'message' }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer pt-10" style="display: block">
        <div class="float-right pb-2">
            <button *ngIf="showBackButton" type="button" class="tertiary-bg btn" id="action-BackConsent" (click)="prev()"><i class="fa fa-backward pr-1"></i>{{ 'goback' | lesCustomLocalize : 'label' }}</button>
            <button *ngIf="showNextButton" type="button" class="tertiary-bg btn" id="action-NextConsent" (click)="next()">
                {{ 'goforward' | lesCustomLocalize : 'label' }}
                <div class="fa fa-forward pl-1"></div>
            </button>
            <button *ngIf="showSaveButton" type="button" class="tertiary-bg btn" id="action-SaveConsent" (click)="save(true)">
                {{ 'save' | lesCustomLocalize : 'label' }}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="hasError">
    <div class="modal-header">
        <h4 class="secondary-text modal-title" id="titleId-Error">
            {{ 'error' | lesCustomLocalize : 'label' }}
        </h4>
        <button type="button" class="tertiary-bg close" id="action-CloseConsent" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-error [htmlId]="'Consent'"></app-error>
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn" id="action-OkConsent" (click)="activeModal.close('Close click')">
            {{ 'ok' | lesCustomLocalize : 'label' }}
        </button>
    </div>
</ng-container>

<app-loading *ngIf="!readyForRender && !hasError" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'events';

import { LocalizeService } from '../localize/localize.service';
import { ServiceQualificationRuleFailures } from '../service';
import { ServiceQualificationRuleOverrideCodeService } from './service-qualification-rule-override-code-service';

@Component({
    selector: 'app-service-qualification-rule-failure-modal',
    templateUrl: './service-qualification-rule-failure-modal.component.html',
    styleUrls: ['./service-qualification-rule-failure-modal.component.css']
})
export class ServiceQualificationRuleFailureModalComponent implements OnInit {
    @Input() header: string = this._localizeService.get('error', 'label');
    @Input() serviceQualificationRuleFailures: ServiceQualificationRuleFailures[];
    @Output() applyOverrideCode = new EventEmitter();
    public download: string;
    messageList: string[] = [];
    showOverrideCode = true;
    hasError = false;
    programServiceKey: string = null;
    constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, private _overrideCodeService: ServiceQualificationRuleOverrideCodeService) { }

    ngOnInit() {
        const rawMessages: string[] = [];
        // To limit displayed messages to one message change for loop below to loop once.
        for (const servceQFailure of this.serviceQualificationRuleFailures) {
            rawMessages.push(servceQFailure.failureMessage);
            this.programServiceKey = servceQFailure.programServiceKey;
        }
        this.messageList = rawMessages;
        this.showOverrideCode = this._overrideCodeService.shouldAllowOverrideCodeUse(this.programServiceKey, this.serviceQualificationRuleFailures);
    }

    validateOverrideCode(code) {
        this._overrideCodeService.validateOverrideCode(code, this.programServiceKey, this.serviceQualificationRuleFailures).subscribe((response) => {
            if (!response || !response.isValid) {
                this.hasError = true;
            } else {
                this.applyOverrideCode.emit('applyOverrideCode', code, this.programServiceKey, response.serviceQualificationRuleFailures);
            }
        });
    }
    private replaceNewlines(txt: string) {
        if (txt != null) {
            return txt.replace(/<p\b[^>]*><br[\/]?><\/p>/g, '');
        }
        return null;
    }
}

import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";

import { IUsAutoCompleteSuggestion } from "../smartystreets/smartystreets.models";

export class AddressAutoCompleteSettings {
  formControlId: string;
  ngModel: string;
  resultFormatter: (item: IUsAutoCompleteSuggestion) => string;
  inputFormatter: (value: IUsAutoCompleteSuggestion) => string;
  selectItem: (event: NgbTypeaheadSelectItemEvent) => void;
  typeaheadMinLength: number;
  maxResults = 5;
  placeHolderText: string;
  autoComplete: string;
  classList: string;
  requiredField: boolean;
  selected: string;
}

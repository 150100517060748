import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ColorPickerModule } from "ngx-color-picker";
import { DateFormatPipe } from "ngx-moment";

import { SafeHtmlPipe } from "../services/services-list/safe-html.pipe";
import { WCSMessageService } from "../services/wcs-message/wcs.message.service";
import { AddressValidationModalComponent } from "./address-validation-modal/address-validation-modal.component";
import { AddressAutoCompleteComponent } from './addressautocomplete/addressautocomplete.component';
import { AlertModalComponent } from "./alert-modal/alert-modal.component";
import { ApplicationContentService } from "./application-content/application-content-service";
import { AttestationService } from "./attestation/attestation.service";
import { CompanyService } from "./company/company.service";
import { ConsentService } from "./consent-modal/consent.service";
import { ConsentModalComponent } from "./consent-modal/consent-modal.component";
import { TzPipe } from "./date-helpers/tz-pipe";
import { ErrorComponent } from "./error/error.component";
import { ErrorReloadComponent } from "./error-reload/error-reload.component";
import { InsuranceService } from "./insurance/insurance.service";
import { IntegrationService } from "./integration/integration.service";
import { LoadDocumentComponent } from "./load-document/load-document.component";
import { LoadingComponent } from "./loading/loading.component";
import { LocalStorageService } from './local-storage-service'
import { LocalizePipe } from "./localize/localize.pipe";
import { LocalizeService } from "./localize/localize.service";
import { MetadataService } from "./metadata/metadata.service";
import { PartnerService } from "./partner/partner.service";
import { PersonService } from "./person/person.service";
import { PersonBlurbComponent } from "./person/person-blurb/person-blurb.component";
import { PersonDataCollectionService } from "./person-data-collection/person.data.collection.service";
import { ProfileService } from "./profile/profile.service";
import { ReportingService } from "./reporting/reporting.service";
import { SevereSymptomService } from "./servere-symptom-modal/severe-symptom.service";
import { SevereSymptomModalComponent } from "./servere-symptom-modal/severe-symptom-modal.component";
import { ServiceQualificationRuleFailureModalComponent } from "./service-qualification-rule-failure-modal/service-qualification-rule-failure-modal.component";
import { SizeCheckerService } from "./size-checker/size-checker.service";
import { TimeoutModalComponent } from "./timeout-modal/timeout-modal.component";
import { UserService } from "./user/user.service";
import { Utils } from "./utils";
import { FormatCellPipe } from "./wcs-table/format-cell.pipe";
import { WcsTableComponent } from "./wcs-table/wcs-table.component";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    NgbModule,
    RouterModule.forRoot([
      { path: "error-reload", component: ErrorReloadComponent },
    ]),
  ],
  declarations: [
    LocalizePipe,
    AlertModalComponent,
    AddressValidationModalComponent,
    ConsentModalComponent,
    LoadingComponent,
    ErrorComponent,
    ErrorReloadComponent,
    WcsTableComponent,
    FormatCellPipe,
    SafeHtmlPipe,
    PersonBlurbComponent,
    TimeoutModalComponent,
    ServiceQualificationRuleFailureModalComponent,
    SevereSymptomModalComponent,
    LoadDocumentComponent,
    AddressAutoCompleteComponent,
    TzPipe
  ],
  providers: [
    UserService,
    LocalizeService,
    Utils,
    LocalStorageService,
    CompanyService,
    PartnerService,
    ProfileService,
    ReportingService,
    PersonService,
    MetadataService,
    CurrencyPipe,
    DateFormatPipe,
    TzPipe,
    SizeCheckerService,
    NgbActiveModal,
    ConsentService,
    WCSMessageService,
    IntegrationService,
    SevereSymptomService,
    AttestationService,
    ApplicationContentService,
    InsuranceService,
    PersonDataCollectionService,
  ],

  exports: [
    LocalizePipe,
    AlertModalComponent,
    AddressValidationModalComponent,
    ConsentModalComponent,
    LoadingComponent,
    ErrorComponent,
    WcsTableComponent,
    FormatCellPipe,
    SafeHtmlPipe,
    PersonBlurbComponent,
    ColorPickerModule,
    AddressAutoCompleteComponent],
}) export class SharedModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AdminGuard } from '../admin-guard.service';
import { SharedModule } from '../shared/shared.module';
import { CustomizableTextComponent } from './customizable-text/customizable-text.component';
import { CustomizeService } from './customize.service';
import { CustomizeComponent } from './customize/customize.component';
import { CustomizeMiscComponent } from './customize-misc/customize-misc.component';
import { ModifyTextComponent } from './modify-text/modify-text.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild([
      { path: 'customize', component: CustomizeComponent, canActivate: [AdminGuard] }
    ])
  ],

  declarations: [CustomizeComponent, CustomizableTextComponent, ModifyTextComponent, CustomizeMiscComponent],
  providers: [
    CustomizeService
  ]
})
export class CustomizeModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { NgbActiveModal,NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { PersonPortalSettings } from '../../../shared/user/person-portal-settings';
import { PersonPortalSettingsPut } from '../../../shared/user/person-portal-settings-put';
import { UserService } from '../../../shared/user/user.service';

@Component({
    selector: 'app-user-preference-modal',
    templateUrl: './user-preference-modal.component.html',
    styleUrls: ['./user-preference-modal.component.css']
})
export class UserPreferenceModalComponent implements OnInit {
    userSettings: PersonPortalSettings;
    participant: Person;
    phoneForm: FormGroup;
    subscriptions: ISubscription[] = [];
    modalRef: NgbModalRef;
    isRenderReady = false;

    constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, private _personService: PersonService, private formBuilder: FormBuilder, private _userService: UserService) {
        this.createForm();
    }

    private createForm() {
        this.phoneForm = this.formBuilder.group({
            phoneNew: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this._userService.getUserSettings(this.participant.userKey).subscribe(
                (settings) => {
                    this.userSettings = settings;
                    this.isRenderReady = true;
                },
                (error) => {
                    this.activeModal.dismiss();
                    this.onError(this._localizeService.get('errorhandle', 'message'));
                }
            )
        );
    }

    get phoneNew() {
        const control = this.phoneForm.get('phoneNew');
        return control;
    }

    savePhone() {
        if (!this.participant || !this.phoneForm.valid) {
            return;
        }

        const newUserSettings = new PersonPortalSettingsPut();
        newUserSettings.key = this.userSettings.key;
        newUserSettings.emailAddress = this.userSettings.emailAddress;
        newUserSettings.languageLocale = this.userSettings.languageLocale;

        if (this.userSettings.state) {
            newUserSettings.stateKey = this.userSettings.state.key;
        }
        newUserSettings.textMessagePhone = this.userSettings.textMessagePhone;
        newUserSettings.textUseSettingsPhone = this.userSettings.textUseSettingsPhone;

        if (this.userSettings.timeZone) {
            newUserSettings.timeZoneKey = this.userSettings.timeZone.key;
        }
        newUserSettings.receiveSmsReminders = this.userSettings.receiveSmsReminders;

        newUserSettings.phone = this.phoneForm.controls.phoneNew.value;

        this.subscriptions.push(
            this._userService.setUserSettings(newUserSettings).subscribe(
                (data) => {
                    this.participant.user.phone = newUserSettings.phone;
                    this.activeModal.close();
                },
                (error) => {
                    this.onError(this._localizeService.get('errorhandle', 'message'));
                }
            )
        );
    }

    private onError(msg: string = null) {
        alert(msg);
    }
}

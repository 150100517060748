export class SlotSearchCriteria {
  userKey: string;
  locationKey: string;
  rangeStart: Date;
  rangeEnd: Date;
  serviceTypeKeys: string[] = [];

  isValid(): boolean {
    return this.userKey && this.locationKey && this.rangeStart && this.rangeEnd &&
      this.serviceTypeKeys.length > 0;
  }
}

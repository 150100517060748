import { Component, Input,OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal,NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { Company } from '../../shared/company/company';
import { CompanyService } from '../../shared/company/company.service';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.css']
})
export class CustomizeComponent implements OnInit, OnDestroy {
  currentUser: Person;
  company: Company;
  companies: Company[] = [];

  modalRef: NgbModalRef;
  subscriptions: ISubscription[] = [];
  readyForRender = false;
  localizeReady = false;

  constructor(private _personService: PersonService, private _modalService: NgbModal,
    private _localizeService: LocalizeService, private _router: Router, private _companyService: CompanyService) { }

  private checkReady() {
    if (this.localizeReady && this.currentUser && this.company) {
      this.readyForRender = true;
    }
  }

  private loadDefaultCompany() {
    if (this.currentUser && this.companies.length > 0) {
      const companyKey = this.currentUser.company.key;

      for (let i = 0; i < this.companies.length; i++) {
        if (this.companies[i].key === companyKey) {
          this.company = this.companies[i];
          break;
        }
      }
    }
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
        this.checkReady();
      }
    }));

    this.subscriptions.push(this._personService.currentPerson.subscribe(person => {
      if (person) {
        this.currentUser = person;

        if (this.currentUser && !this.currentUser.canCustomize) {
          this._router.navigate(['home']);
        }

        this.loadDefaultCompany();
        this.checkReady();
      }
    }));

    this.subscriptions.push(this._companyService.allCompanies.subscribe(companies => {
      if (companies) {
        this.companies = companies;
        this.loadDefaultCompany();
        this.checkReady();
      }
    }));

    this._companyService.fetchCompanies();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

export class EventLocation {
  key: string;
  name: string;
  description: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  timezone: string;
  latitude: number;
  longitude: number;
  closed = false;
  unavailable = false;
  // This is the distance from either the zip code or the
  // current location.
  distanceFromStartingPoint: number;

  get locationName(): string {
    //let name = '';

    //if (this.city) {
    //  name += this.city;

    //  if (this.state) {
    //    name += ', ' + this.state;
    //  }
    //} else if (this.state) {
    //  name += this.state;
    //}

    //if (this.address1) {
    //  name += ' - ' + this.address1;
    //}   
    return this.name;
  }

  constructor(data) {
    this.key = data.key;
    this.name = data.name;
    this.description = data.description;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.zip = data.zip;
    this.state = data.state;
    this.country = data.country;
    this.timezone = data.timeZone;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }
}

// Contains location and indicates what services are available by location
export class EventLocationWithServices {
  key: string;
  name: string;
  zip: string;
  latitude: number;
  longitude: number;
  // This is the distance from either the zip code or the
  // current location.
  distanceFromStartingPoint: number;
  serviceType: string;
  hasBothServices: boolean;
  serviceTypeKey1: string;
  serviceTypeKey2: string;
  hasClosedOrFullEvents: boolean;

  constructor(data) {
    this.key = data.key;
    this.name = data.name;
    this.zip = data.zip;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.serviceType = data.serviceType;
    this.hasBothServices = data.hasBothServices;
    this.serviceTypeKey1 = data.serviceTypeKey1;
    this.serviceTypeKey2 = data.serviceTypeKey2;
    this.hasClosedOrFullEvents = data.hasClosedOrFullEvents;
  }
}

// Classes below are used to construct drop-down location selections grouped by type of service.
export class EventLocationSelection {
  key: string;
  name: string;

  constructor(key: string, name: string) {
    this.key = key;
    this.name = name;
  }
}

// Class holds the selection group name and an array of locations.
export class EventLocationSelections {
  groupName: string;
  sortOrder: number;
  serviceTypeKey1: string;
  serviceTypeKey2: string;
  eventLocationSelection: EventLocationSelection[] = [];

  constructor(groupName: string, sortOrder: number, serviceTypeKey1: string, serviceTypeKey2: string, eventLocationSelection: EventLocationSelection[]) {
    this.groupName = groupName;
    this.sortOrder = sortOrder;
    this.serviceTypeKey1 = serviceTypeKey1;
    this.serviceTypeKey2 = serviceTypeKey2;
    this.eventLocationSelection = eventLocationSelection;
  }
}

export class EventLocationWithServiceTypeCollection {
  eventLocationSelection: EventLocationSelection[] = [];

  constructor(data) {
    this.eventLocationSelection = data;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject ,  ReplaySubject } from 'rxjs';

export enum BootstrapSize {
  xs,
  sm,
  md,
  lg,
  xl
}

@Injectable()
export class SizeCheckerService  {
  private _currentSize: BootstrapSize;
  private _currentHeight: number;
  private _currentMainBodyHeight: number;

  private currentSizeSource = new BehaviorSubject<BootstrapSize>(null);
  currentSize = this.currentSizeSource.asObservable();

  private currentHeightSource = new ReplaySubject<number>();
  currentHeight = this.currentHeightSource.asObservable();

  private mainBodyHeightSource = new ReplaySubject<number>();
  currentBodyHeight = this.mainBodyHeightSource.asObservable();

  constructor() {
    const that = this;

    this.onResize();

    window.onresize = function() {
      that.onResize();
    };
  }

  getCurrentSize(): BootstrapSize {
    const innerWidth = window.innerWidth;
    if (innerWidth < 576) {
      return BootstrapSize.xs;
    } else if (innerWidth >= 576 && innerWidth < 768) {
      return BootstrapSize.sm;
    } else if (innerWidth >= 768 && innerWidth < 992) {
      return BootstrapSize.md;
    } else if (innerWidth >= 992 && innerWidth < 1200) {
      return BootstrapSize.lg;
    } else if (innerWidth >= 1200) {
      return BootstrapSize.xl;
    }
  }

  onResize() {
    const size = this.getCurrentSize();

    if (size !== this._currentSize) {
      this.currentSizeSource.next(size);
      this._currentSize = size;
    }

    const currentHeight = window.innerHeight;
    if (currentHeight !== this._currentHeight) {
      this._currentHeight = currentHeight;
      this.currentHeightSource.next(currentHeight);
    }

    const headerHeight = window.document.getElementById('nav-header').clientHeight;
    const footerHeight = window.document.getElementById('footer').clientHeight;

    this.mainBodyHeightSource.next(currentHeight - headerHeight - footerHeight);
  }
}

<div class="modal-header">
    <h4 secondary-text class="modal-title" id="titleId-SecureMessage">{{ 'newmassmessage' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form #newMessageForm="ngForm" (ngSubmit)="sendMessage()" id="formId-SecureMessage">
    <div class="modal-body">
        <div class="form-group row mt-0">
            <label for="toStatic" class="col-sm-1 col-form-label"
                ><b>{{ 'to' | lesCustomLocalize : 'label' }}:</b></label
            >
            <div class="col-sm-11">
                <input type="text" readonly class="form-control-plaintext" id="toStatic" name="toStatic" value="{{ 'sendmessagetoxparticipants' | lesCustomLocalize : 'message' : coach.fullName }}" />
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <b>{{ 'message' | lesCustomLocalize : 'label' }}:</b>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12" id="new-message-body">
                <quill-editor [(ngModel)]="messageBody" name="messageBody" bounds="#new-message-body" [style]="{ height: '300px' }" required #msgBody="ngModel">
                    <div quill-editor-toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" [title]="'Bold'"></button>
                            <button class="ql-italic" [title]="'Italic'"></button>
                            <button class="ql-underline" [title]="'Underline'"></button>
                            <button class="ql-strike" [title]="'Strikethrough'"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-indent" value="-1"></button>
                            <button class="ql-indent" value="+1"></button>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-header" [title]="'Heading'">
                                <option selected></option>
                                <option value="1"></option>
                                <option value="2"></option>
                                <option value="3"></option>
                                <option value="4"></option>
                                <option value="5"></option>
                                <option value="6"></option>
                            </select>
                            <select class="ql-align" [title]="'Aligment2'">
                                <option selected></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-link" [title]="'Link'"></button>
                        </span>
                    </div>
                </quill-editor>
                <div *ngIf="msgBody.invalid && (msgBody.dirty || msgBody.touched)" class="alert alert-danger">
                    <div *ngIf="msgBody.errors.required">
                        {{ 'messagebodyrequired' | lesCustomLocalize : 'message' }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer pt-10">
        <button type="button" class="tertiary-bg btn btn-primary" id="actionId-CancelSecureMessage" (click)="activeModal.close('Close click')">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
        <button type="submit" class="tertiary-bg btn btn-primary" id="actionId-SendSecureMessage" [disabled]="!newMessageForm.form.valid || disableSubmit">
            <i class="fa fa-paper-plane-o" aria-hidden="true"></i> {{ 'send' | lesCustomLocalize : 'label' }}
        </button>
    </div>
</form>


import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Customization, CustomizedData, CustomizedMisc } from '../shared/localize/customized-data'; // TODO: does this belong in this package?
import { TextOverride } from '../shared/localize/text-override';

@Injectable()
export class CustomizeService {
    constructor(private _http: HttpClient) { }

    modifyTextOverride(override: TextOverride) {
        const data = override.customizedDataObj;

        if (override.key) {
            data['key'] = override.key;
        }

        return this._http.post(environment.baseCoreApiEndpoint + '/customization/text', data).pipe(catchError(this.handleError));
    }

    fetchOverrides(companyKey: string): Observable<CustomizedData[]> {
        // TODO: hook up to real API
        // return this._http.get<CustomizedData[]>('../../../api/overrides_' + companyKey + '.json');

        let params = new HttpParams();
        params = params.append('companyKey', companyKey);

        return this._http.get<CustomizedData[]>(environment.baseCoreApiEndpoint + '/customization/text', { params: params }).pipe(catchError(this.handleError));
    }
    modifyMisc(customizedMisc: CustomizedMisc): Observable<CustomizedMisc> {
        return this._http.post<CustomizedMisc>(environment.baseCoreApiEndpoint + '/customization/edit', customizedMisc).pipe(catchError(this.handleError));
    }

    fetchMisc(companyKey: string): Observable<CustomizedMisc> {
        let params = new HttpParams();
        params = params.append('companyKey', companyKey);

        return this._http.get<CustomizedMisc>(environment.baseCoreApiEndpoint + '/customization/edit', { params: params }).pipe(catchError(this.handleError));
    }
    fetchCustomization(companyKey: string): Observable<Customization> {
        const params = new HttpParams();

        return this._http.get<Customization>(environment.baseCoreApiEndpoint + '/customization', { params: params }).pipe(catchError(this.handleError));
    }
    fetchLanguages(): Observable<CustomizedMisc> {
        // TODO: hook up to real API
        // return this._http.get<CustomizedData[]>('../../../api/overrides_' + companyKey + '.json');

        const params = new HttpParams();
        return this._http.get<CustomizedMisc>(environment.baseCoreApiEndpoint + '/customization/language', { params: params }).pipe(catchError(this.handleError));
    }
    fetchLogoMaxFileSize(): Observable<number> {
        return this._http.get<number>(environment.baseCoreApiEndpoint + '/customization/get-logo-max-file-size').pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        if (err.status == 400 && (err.error instanceof String || typeof err.error == 'string')) {
            return observableThrowError(err);
        }
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return observableThrowError(() => errorMessage);
    }
}

import { Component, Input,OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { WCSMessageService } from '../services/wcs-message/wcs.message.service';
import { WCSMessageTypes } from '../services/wcs-message/wcs-message';
import { LocalizeService } from '../shared/localize/localize.service';
import { Person } from '../shared/person/person';
import { User } from '../shared/user/user';
import { SelfVerifyModalService } from './self-verify-modal.service';

@Component({
  selector: 'app-self-verify-modal',
  templateUrl: './self-verify-modal.component.html',
  styleUrls: ['./self-verify-modal.component.css']
})
export class SelfVerifyModalComponent implements OnInit {
  @Input() msg: string = this._localizeService.get('selfverifymodal', 'message');
  @Input() person: Person
  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService, 
    private wcsMessageService: WCSMessageService, private _selfVerifyModalService: SelfVerifyModalService) { }

  ngOnInit() {
  }
  saveSelfVerifyDate(){
    console.log('saving');    
    this._selfVerifyModalService.setSelfVerifyLastSeenDate(this.person.userKey)
    .subscribe(result =>{
      this.activeModal.close();
      this.wcsMessageService.sendMessage({ type : WCSMessageTypes.SelfVerifyStyleUpdate, data: null , sender: null})
    })
  }

}

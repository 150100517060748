import { Globals } from './../shared/globals';
export interface PersonWidgetCollection {
    widgetCollectionKey: any;
    collectionName: string;
    titleTag: string;
    titleLanguage: string;
    descriptionTag: string;
    descriptionLanguage: string;
    presentedBeforeAction: boolean;
    collectionWidgets: PersonWidgetCollectionWidget[];
}

export class PersonWidgetCollectionProgressCriteria {
    programServiceTypeKey?: any;
    partnerKey?: any;
    companyKey?: any;
    eventKey?: any;
    programServiceKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    unMatchedDataKey?: any;
    applicationKey: string = Globals.ApplicationKeys.WellConnectPlus;
}
export class PersonWidgetCollectionNeedResult {
    constructor(required: boolean, needed: boolean) {
        this.isCollectionRequired = required;
        this.isCollectionNeeded = needed;
    }
    //this service requires collections
    isCollectionRequired: boolean;
    //this person does not have any to be collected (likely consent only)
    isCollectionNeeded: boolean;
}
export class PersonWidgetCollectionWidget {
    widgetCollectionKey: any;
    consentDefinitionKey: any;
    collectionName: string;
    widgetKey: any;
    key: any;
    widgetIcon?: string;
    collectionIcon?: string;
    dataCollectionKey?: any;
    titleTag: string;
    titleLanguage: string;
    subtitleTag: string;
    subtitleLanguage: string;
    descriptionTag: string;
    descriptionLanguage: string;
    footerTag: string;
    footerLanguage: string;
    sortOrder: number;
    isRequired: boolean;
    personWidgetCollectionProgressKey?: any;
    isComplete?: boolean;
    //different from sortOrder. Used to determine position in accordion or navigation
    position: WidgetCollectionPosition;
    //different from sortOrder. Used as array position
    widgetPanelIndex: number;
    status = 'incomplete';
}
export class PersonWidgetCollectionProgress {
    widgetCollectionKey: any;
    key: any;
    widgetKey: any;
    dataCollectionKey?: any;
    consentDefinitionKey?: any;
    eventKey?: any;
    programServiceKey?: any;
    participantKey?: any;
    userKey?: any;
    isComplete?: boolean;
    personKey?: any;
}
export interface PersonSelectedWidgetCriteria {
    programServiceKey?: any;
    userKey?: any;
    loginUserKey?: any;
}
export class WidgetCompleted {
    dataCollectionKey: any;
    widgetKey: any;
    isComplete?: boolean;
    action: WidgetCloseAction;
    personWidgetCollectionProgressKey?: any;
    widgetPanelIndex: any;
    consentDefinitionKey: any;
}
export enum WidgetCloseAction {
    forward,
    backward,
    cancel
}
export enum WidgetCollectionPosition {
    first,
    last,
    other
}
export class PersonDataCollectedCriteria {
    companyKey?: any;
    eventKey?: any;
    programServiceKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    unMatchedDataKey?: any;
    applicationKey?: any = Globals.ApplicationKeys.WellConnectPlus;
    dataCollectionKey?: any;
}
export class PersonDataCollectedCollectionRequest {
    applicationKey: any = Globals.ApplicationKeys.WellConnectPlus;
    programServiceTypeKey?: any;
    partnerKey?: any;
    companyKey: any;
    programServiceKey?: any;
    eventKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    unMatchedDataKey?: any;
}
export interface PersontDataCollectionsToCollectVew {
    dataCollectionKey: any;
    collectionName: string;
    patientDataToCollection: PersonDataToCollectView[];
}
export interface PersonDataToCollectView {
    dataPromptKey: any;
    internalName: string;
    prePromptTag: string;
    tag: string;
    postPromptTag: string;
    htmlInputElement: string;
    genderKey?: any;
    patientOlderThan?: number;
    patientYoungerThan?: number;
    response: string;
    sortOrder: number;
    isRequired: boolean;
    patientDataCollectedKey?: any;
    patientDataCollectedResponseKey?: any;
    validators: PersonDataPromptValidator;
}
export interface PersonDataPromptValidator {
    min?: number;
    max?: number;
    required?: boolean;
    email?: boolean;
    minLenght?: boolean;
    maxLenght?: boolean;
    pattern?: string;
}
export class PersonDataCollected {
    key: any;
    dataCollectionKey: any;
    dataPromptKey: any;
    eventKey?: any;
    programServiceKey?: any;
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    createdBy?: any;
    modifiedBy?: any;
    isDeleted = false;
    companyKey: any;
}
export class PersonDataCollectedResponse {
    key: any;
    patientDataCollectedKey: any;
    dataPromptKey: any;
    response: any;
    isDeleted = false;
}
export class PersonDataToCollectSaveRequest {
    personDataToCollect: PersonDataCollected;
    personDataCollectedResponses: PersonDataCollectedResponse[];
}
export class PatientInsuranceCaptureModel {
    personKey?: any;
    participantKey?: any;
    userKey?: any;
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    stateKey: any;
    memberNumber: any;
    groupNumber: any;
    primaryCoverage: any;
    primaryCoverageYes: boolean;
    primaryCoverageNo: boolean;
}

import { Component, Input,OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';

import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { Utils } from '../../../shared/utils';
import { ApiAppointment } from '../../model/api-appointment';
import { ProgramServiceAndType } from '../../model/program-service-and-type';
import { SchedulerService } from '../../scheduler.service';

@Component({
  selector: 'app-next-screening-appointment',
  templateUrl: './next-screening-appointment.component.html',
  styleUrls: ['./next-screening-appointment.component.css']
})
export class NextScreeningAppointmentComponent implements OnInit, OnDestroy {
  hasError = false;
  readyForRender = false;
  localizeReady = false;
  appointmentsRetrieved = false;
  subscriptions: Subscription[] = [];
  currentUser: Person;
  appointments: ApiAppointment[] = [];
  nextAppointment: ApiAppointment = null;

  constructor(private _localizeService: LocalizeService, private _schedulerService: SchedulerService,
    private _personService: PersonService) { }

  checkReady() {
    if (this.localizeReady && this.appointmentsRetrieved) {
      this.readyForRender = true;
    }
  }

  setNextAppointment() {
    if (this.appointments.length) {
      const bufferedDate = new Date();
      bufferedDate.setHours(bufferedDate.getHours() - 6);
      this.appointments = _.orderBy(this.appointments, ['time'], ['asc']);
      this.nextAppointment = _.find(this.appointments, (appt: ApiAppointment) => {
        return new Date(appt.time) >= bufferedDate && !appt.isCanceled;
      });
    }
  }

  getAppointments() {
    const that = this;

    this.appointmentsRetrieved = false;

    this.subscriptions.push(this._schedulerService.getAppointments(this.currentUser.userKey).subscribe(appts => {
      that.appointmentsRetrieved = true;
      that.appointments = appts;
      that.setNextAppointment();
      that.checkReady();
    },
    error => {
      this.hasError = true;
    }));
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentUser = currentPerson;

        this.getAppointments();
      }
    }));

    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      if (isReady) {
        this.localizeReady = true;
        this.checkReady();
      }
    }));

  }

  ngOnDestroy() {
    this.subscriptions.forEach( sub => {
      sub.unsubscribe();
    });
  }

  getLocaleMonth(month: string) {
    return this._localizeService.get(month.toLowerCase(), 'month');
  }
  public getProgramLabel(token: string) {
    return this._localizeService.get(Utils.getServiceToken(token), 'program');
  }

}


<ng-container *ngIf="readyForRender">
    <app-loading *ngIf="isLoading" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

    <div class="form-group d-xs-block d-sm-none">
        <label for="sortBy">{{ 'sortby' | lesCustomLocalize : 'label' }}</label>
        <select class="form-control" id="sortBy" [(ngModel)]="currentSortProp" (change)="sortXs()">
            <option value="">{{ 'selectsortcolumn' | lesCustomLocalize : 'label' }}</option>
            <ng-container *ngFor="let map of settings">
                <option *ngIf="map.sortable && doShowColumn(map)" [value]="map.valueProp">
                    {{ map.headerLocalizeProp | lesCustomLocalize : 'label' }}
                </option>
            </ng-container>
        </select>

        <select class="form-control d-xs-block d-sm-none" name="sortDirection" id="{{ 'formSelectId-' + htmlId + 'SortDirection' }}" [(ngModel)]="currentSortDirection" (change)="sortXs()">
            <option value="asc">{{ 'ascending' | lesCustomLocalize : 'label' }}</option>
            <option value="desc">{{ 'descending' | lesCustomLocalize : 'label' }}</option>
        </select>
    </div>

    <div class="d-xs-block d-sm-none" *ngIf="records" id="{{ 'resultsId-' + htmlId }}">
        {{ 'xrecordsfound' | lesCustomLocalize : 'message' : records.length }}
    </div>

    <table [ngClass]="{ 'table table-striped wcs d-xs-block d-sm-none': true }" id="{{ 'resultsId-' + htmlId }}" *ngIf="!isLoading">
        <thead>
            <tr>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let record of recordsToShow">
                <td class="d-xs-table-cell d-sm-none">
                    <ng-container *ngFor="let map of settings">
                        <div *ngIf="doShowColumn(map)">
                            <ng-container *ngFor="let valMap of getMapForValue(map, record)">
                                <ng-container *ngIf="isEmpty(record)">&nbsp;</ng-container>
                                <ng-container *ngIf="!isEmpty(record)">
                                    <ng-container *ngIf="valMap.links">
                                        <ng-container *ngFor="let link of valMap.links">
                                            <ng-container *ngIf="!link.doShowFn || link.doShowFn.val === link.doShowFn.fn.call(map.thisContext, record)">
                                                <button class="btn btn-link" (click)="onClick(link.onClick, valMap.thisContext, record)">
                                                    {{ link.text | lesCustomLocalize : 'label' | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) }}
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="valMap.spans">
                                        <ng-container *ngFor="let span of valMap.spans">
                                            <ng-container *ngIf="!span.doShowFn || span.doShowFn.val === span.doShowFn.fn.call(map.thisContext, record)">
                                                <span class="{{ span.cssClass }}">{{ span.text | lesCustomLocalize : 'label' }}</span>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <span [ngClass]="{ sortable: map.valueProp && currentSortProp === map.valueProp }" *ngIf="map.headerLocalizeProp"
                                        ><strong>{{ map.headerLocalizeProp | lesCustomLocalize : 'label' }}:</strong>
                                    </span>
                                    <span *ngIf="!valMap.link && !valMap.links && !valMap.spans" [innerHTML]="record[valMap.valueProp] | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) : record"></span>
                                    <button *ngIf="valMap.link && valMap.link.text" class="btn btn-link" (click)="onClick(valMap.link.onClick, valMap.thisContext, record)">
                                        {{ valMap.link.text | lesCustomLocalize : 'label' | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) }}
                                    </button>
                                    <button *ngIf="valMap.link && !valMap.link.text" class="btn btn-link" (click)="onClick(valMap.link.onClick, valMap.thisContext, record)">
                                        {{ record[valMap.valueProp] | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) }}
                                    </button>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>

    <table [ngClass]="{ 'table table-striped wcs d-xs-none': true, 'table-responsive': useBootstrapResponsiveTable }" id="{{ 'resultsId-' + htmlId }}" *ngIf="!isLoading">
        <thead>
            <tr>
                <ng-container *ngFor="let map of settings">
                    <th *ngIf="doShowColumn(map)" [ngClass]="{ 'd-none d-sm-table-cell': true, pointer: map.sortable }" (click)="sortClick(map)">
                        <span [ngClass]="{ sortable: map.primaryKey && currentSortPrimaryKey === map.primaryKey }">{{ map.headerLocalizeProp | lesCustomLocalize : 'label' }}</span>
                        <span *ngIf="map.valueProp && currentSortProp === map.valueProp && currentSortDirection === 'asc'">
                            <i class="fas fa-sort-amount-up" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="map.valueProp && currentSortProp === map.valueProp && currentSortDirection === 'desc'">
                            <i class="fas fa-sort-amount-down" aria-hidden="true"></i>
                        </span>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let record of recordsToShow">
                <ng-container *ngFor="let map of settings">
                    <td *ngIf="doShowColumn(map)" class="d-none d-sm-table-cell">
                        <ng-container *ngFor="let valMap of getMapForValue(map, record)">
                            <ng-container *ngIf="isEmpty(record)">&nbsp;</ng-container>
                            <ng-container *ngIf="!isEmpty(record)">
                                <ng-container *ngIf="valMap.links">
                                    <ng-container *ngFor="let link of valMap.links">
                                        <ng-container *ngIf="!link.doShowFn || link.doShowFn.val === link.doShowFn.fn.call(map.thisContext, record)">
                                            <button class="btn btn-link" (click)="onClick(link.onClick, valMap.thisContext, record)">
                                                {{ link.text | lesCustomLocalize : 'label' | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) }}
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="valMap.spans">
                                    <ng-container *ngFor="let span of valMap.spans">
                                        <ng-container *ngIf="!span.doShowFn || span.doShowFn.val === span.doShowFn.fn.call(map.thisContext, record)">
                                            <span class="{{ span.cssClass }}">{{ span.text | lesCustomLocalize : 'label' }}</span>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <span
                                    class="{{ valMap.cssClass }}"
                                    *ngIf="!valMap.link && !valMap.links && !valMap.spans"
                                    [innerHTML]="record[valMap.valueProp] | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) : record"
                                ></span>
                                <button *ngIf="valMap.link && valMap.link.text" class="btn btn-link" (click)="onClick(valMap.link.onClick, valMap.thisContext, record)">
                                    {{ valMap.link.text | lesCustomLocalize : 'label' | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) }}
                                </button>
                                <button *ngIf="valMap.link && !valMap.link.text" class="btn btn-link" (click)="onClick(valMap.link.onClick, valMap.thisContext, record)">
                                    {{ record[valMap.valueProp] | formatCell : valMap.format : (currentUser ? currentUser.preferredTimeZone : undefined) }}
                                </button>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="records && records.length && records.length > pageSize" class="table-footer">
                <td colspan="100" class="d-none d-sm-table-cell">
                    <div class="foot-wrapper">
                        <div class="pull-left">
                            <ngb-pagination [collectionSize]="records.length" [(page)]="currentPage" [maxSize]="maxSize" [pageSize]="pageSize" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                        <div class="pull-right page-count">{{ 'xrecordsfound' | lesCustomLocalize : 'message' : records.length }}</div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="!isLoading && !isError && records && records.length === 0" class="alert alert-info" role="alert">
        {{ 'nodata' | lesCustomLocalize : 'message' }}
    </div>

    <div *ngIf="isError" class="alert alert-warning" role="alert">
        {{ 'unknownerror' | lesCustomLocalize : 'message' }}
    </div>
</ng-container>

import moment from 'moment';

import { DIServiceLocator } from '../../di.service.locator';
import { LocalizeService } from '../../shared/localize/localize.service';
import { User } from '../../shared/user/user';
import { Utils } from '../../shared/utils';
import { ApiSlot } from './api-slot';
import { EventLocation } from './event-location';
import { OnBehalfOf } from './on-behalf-of';
export class CancelReason {
    key: string;
    reason: string;
}

export class ApiAppointment {
    key: string;
    itineraryKey: string;
    slot: ApiSlot;
    location: EventLocation;
    fastingState: string;
    room: string;
    onBehalfOf: OnBehalfOf;
    owner: User;
    createdOn: Date;
    modifiedOn: Date;
    isCanceled: boolean;
    cancelReason: CancelReason;
    timezone: string;
    serviceDisplayName: string;
    showFastingState: boolean;
    appointmentType: string;
    confirmationNumber: string;
    confirmationUrl: string;
    programServiceKey: string;
    programServiceTypeKey?: string;
    labcorpLocationId?: number;
    manageText: string;
    localizeService: LocalizeService = DIServiceLocator.injector.get(LocalizeService);
    get time(): Date {
        let theTime: Date;

        if (this.slot) {
            theTime = this.slot.time;
        }

        return theTime;
    }

    get timeUtc(): Date {
        let theTime: Date;

        if (this.slot) {
            theTime = this.slot.timeUtc;
        }

        return theTime;
    }

    get timeText(): string {
        let appointmentTime = '';

        if (this.slot) {
            const formattedDate = moment(this.slot.time).format('MM/DD/YYYY h:mm a');
            appointmentTime = formattedDate;
            if (this.timezone) {
                appointmentTime = appointmentTime + '(' + this.timezone + ')';
            }
        }

        return appointmentTime;
    }

    get serviceTypeName(): string {
        let svcType = null;

        if (this.slot && this.slot.programServiceType) {
            svcType = this.slot.programServiceType.name;
        }

        return svcType;
    }

    get appointmentFor(): string {
        let name = null;

        if (this.onBehalfOf) {
            name = this.onBehalfOf.firstName + ' ' + this.onBehalfOf.lastName;
        } else {
            name = this.owner.firstName + ' ' + this.owner.lastName;
        }

        return name;
    }

    get locationName(): string {
        return this.location ? this.location.name : null;
    }

    get locationText(): string {
        let location = '';

        if (this.location) {
            if (this.location.address1) {
                location += this.location.address1 + '<br />';
            }

            if (this.location.address2) {
                location += this.location.address2 + '<br />';
            }

            if (this.location.city) {
                location += this.location.city;
            }

            if (this.location.state) {
                location += ', ' + this.location.state;
            }

            if (this.location.zip) {
                location += ' ' + this.location.zip;
            }

            if (this.location.city || this.location.state || this.location.zip) {
                location += '<br />';
            }

            if (this.room) {
                location += this.room;
            }
            if (this.location.closed) {
                location += '<br />' + this.localizeService.getCombinedTag('message.locationclosed');
            }
            if (this.location.unavailable) {
                location += this.localizeService.getCombinedTag('message.locationunavailable');
            }
        }

        return location;
    }

    get fastingToken(): string {
        return Utils.getFastingStateToken(this.fastingState);
    }

    constructor(data) {
        this.key = data.key;
        this.itineraryKey = data.itineraryKey;
        this.slot = data.slot;
        this.onBehalfOf = data.onBehalfOf;
        this.owner = data.owner;
        this.createdOn = data.createdOn;
        this.modifiedOn = data.modifiedOn;
        this.isCanceled = data.isCanceled;
        this.cancelReason = data.cancelReason;
        this.location = data.location;
        this.fastingState = data.fastingState;
        this.room = data.room;
        this.timezone = data.timezone;
        this.serviceDisplayName = data.serviceDisplayName;
        this.showFastingState = data.showFastingState;
        this.appointmentType = data.appointmentType;
        this.confirmationNumber = data.confirmationNumber;
        this.confirmationUrl = data.confirmationUrl;
        this.programServiceKey = data.programServiceKey;
        this.programServiceTypeKey = data.programServiceTypeKey;
        this.labcorpLocationId = data.labcorpLocationId;
    }
}

<ng-container *ngIf="readyForRender && !hasError">
    <ng-container *ngIf="otherPerson !== null">
        <app-person-blurb [person]="otherPerson" [htmlId]="'Other Person'"></app-person-blurb>
    </ng-container>

    <div class="row">
        <div class="col">
            <h1 class="primary-text" id="titleId-ScheduleYourAppointments">{{ 'appointmentscheduler' | lesCustomLocalize : 'label' }}</h1>
            <p>{{ 'schedulerblurb' | lesCustomLocalize : 'message' }}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-upcoming-appointments></app-upcoming-appointments>
        </div>
    </div>

    <p></p>

    <div class="row d-lg-none">
        <div class="col text-center pb-3">
            <div class="btn-group" role="group" id="actionId-SchedulerChoices">
                <input type="radio" [value]="'search'" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="scheduler_sectionToShow_search" />
                <label class="btn btn-primary" id="actionId-SearchSchedule" for="scheduler_sectionToShow_search"> {{ 'search' | lesCustomLocalize : 'label' }} </label>
                <input type="radio" [value]="'select'" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="scheduler_sectionToShow_select" />
                <label class="btn btn-primary" id="actionId-SelectSchedule" for="scheduler_sectionToShow_select"> {{ 'select' | lesCustomLocalize : 'label' }} </label>
                <input type="radio" [value]="'itinerary'" class="btn-check" [(ngModel)]="sectionToShow" name="{{ sectionToShow }}" id="scheduler_sectionToShow_itinerary" />
                <label class="btn btn-primary" id="actionId-ItinerarySchedule" for="scheduler_sectionToShow_itinerary"> {{ 'itinerary' | lesCustomLocalize : 'label' }} </label>
            </div>
        </div>
    </div>

    <div class="row pb-2">
        <div id="filters" [ngClass]="{ 'col-md-12': true, 'col-lg-3': true, 'd-none d-lg-block': sectionToShow !== 'search' }">
            <div class="scheduler-panel filter-panel">
                <h4 class="primary-text" id="titleId-SearchSchedule">{{ 'search' | lesCustomLocalize : 'label' }}</h4>

                <div id="filterOptions">
                    <form id="formId-SearchSchedule">
                        <div class="form-group">
                            <p class="primary-text mb-1">{{ 'selectservice' | lesCustomLocalize : 'label' }}:</p>
                            <div class="form-check" *ngFor="let type of serviceTypesAvailable">
                                <label class="form-check-label service-type">
                                    <input class="form-check-input" type="checkbox" [value]="type.key" (change)="serviceTypeSelected($event)" [checked]="isSelectedServiceType(type.key)" />
                                    <span class="icon">
                                        <i class="fas" [ngClass]="type.serviceTypeIcon"></i>
                                    </span>
                                    <span class="name">{{ getProgramLabel(type.displayName, type.key, type.serviceSearchKey) }}</span>
                                </label>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="enableBookForOthers && selectedServiceTypes?.length > 0">
                            <p class="primary-text mb-1">{{ 'bookservicesfor' | lesCustomLocalize : 'label' }}:</p>
                            <div class="form-check">
                                <label class="form-check-label pr-4">
                                    <input class="form-check-input" type="checkbox" [value]="bookForMe" [checked]="bookForMe" (change)="bookForMeSelected($event)" />
                                    {{ 'bookforme' | lesCustomLocalize : 'label' }}
                                </label>
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" [value]="bookForGuest" [checked]="bookForGuest" (change)="bookForGuestSelected($event)" />
                                    {{ 'bookforguest' | lesCustomLocalize : 'label' }}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="filtersAreVisible && selectedServiceTypes?.length > 0">
                            <div class="form-group">
                                <p class="primary-text mb-1">{{ 'appointmentschedulersearchtypeoption' | lesCustomLocalize : 'label' }}:</p>
                                <div class="form-group row">
                                    <div class="col">
                                        <label>
                                            <input type="radio" name="selectedSearchMode" [(ngModel)]="selectedSearchMode" value="location" (click)="filterByLocationSelected()" />
                                            {{ 'location' | lesCustomLocalize : 'label' }}
                                        </label>
                                        <label class="ms-3">
                                            <input type="radio" name="selectedSearchMode" [(ngModel)]="selectedSearchMode" value="date" (click)="filterByDateSelected()" />
                                            {{ 'date' | lesCustomLocalize : 'label' }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="selectedSearchMode === 'date' && selectedServiceTypes?.length > 0 && eventDateAndLocationCollection">
                                <label for="dateFilterDay">
                                    {{ 'date' | lesCustomLocalize : 'label' }}: <span *ngIf="isFetching">{{ 'loading' | lesCustomLocalize : 'label' }}</span>
                                    <span [ngbTooltip]="dateFilterPopupLegend">
                                        <i class="fa fa-info-circle"></i>
                                    </span>
                                </label>
                                <ng-template #dateFilterPopupLegend>
                                    <div class="dateFilterPopupLegend">
                                        <div>
                                            <span class="date-legend date-legend-match pr-2"></span><span class="name">{{ 'allservicesavailable' | lesCustomLocalize : 'label' }}</span>
                                        </div>
                                        <ng-container *ngIf="serviceTypeSelectedCount() > 1">
                                            <div>
                                                <span class="date-legend date-legend-partial-match pr-2"></span><span class="name">{{ 'someservicesavailable' | lesCustomLocalize : 'label' }}</span>
                                            </div>
                                        </ng-container>
                                        <div>
                                            <span class="date-legend date-legend-fullorclosedevent pr-2"></span><span class="name">{{ 'fullorclosedeventlegend' | lesCustomLocalize : 'label' }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                                <div class="input-group mb-2">
                                    <input
                                        class="form-control"
                                        placeholder="mm/dd/yyyy"
                                        name="dateFilterDay"
                                        [required]="true"
                                        (ngModelChange)="onDateFilterChange()"
                                        [(ngModel)]="dateFilterDay"
                                        [dayTemplate]="customFilterDay"
                                        [minDate]="minDate()"
                                        [maxDate]="maxDate()"
                                        [markDisabled]="isDisabled"
                                        [firstDayOfWeek]="7"
                                        [outsideDays]="hidden"
                                        ngbDatepicker
                                        type="text"
                                        #filterByDateDatePicker="ngbDatepicker"
                                    />
                                    <button class="input-group-addon" (click)="disabled ? true : toggleDateFilterDatePicker()" type="button">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </button>
                                </div>

                                <ng-template #customFilterDay let-date="date" let-currentMonth="currentMonth" let-disabled="disabled">
                                    <span class="custom-day" [ngClass]="getDayFilterClass(date)" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>

                            <div class="form-group row" *ngIf="(selectedSearchMode === 'location' || dateFilterDaySelected) && selectedServiceTypes?.length > 0">
                                <div class="col-md-6">
                                    <label for="selectedZipCode">{{ 'zipcode' | lesCustomLocalize : 'label' }}:</label>
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            id="selectedZipCode"
                                            name="selectedZipCode"
                                            type="text"
                                            placeholder="{{ 'optional' | lesCustomLocalize : 'label' }}"
                                            [(ngModel)]="selectedZipCode"
                                            (change)="calculateLocationDistances()"
                                            (focus)="selectAllContent($event)"
                                        />
                                        <span class="input-group-btn">
                                            <button class="btn btn-outline-secondary" type="button" (click)="onLocationChange('current')"><i class="fa fa-location-arrow" aria-hidden="true"></i></button>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label for="radius">{{ 'radius' | lesCustomLocalize : 'label' }}:</label>
                                    <select class="form-control" id="radius" name="radius" [(ngModel)]="selectedRadius" [disabled]="!selectedZipCode" (change)="radiusSelected($event)">
                                        <option value="9999"></option>
                                        <ng-container *ngFor="let radius of radiiAvailable">
                                            <option value="{{ radius.id }}">{{ radius.desc }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group mt-2 mb-2" *ngIf="(selectedSearchMode === 'location' || dateFilterDaySelected) && selectedServiceTypes?.length > 0">
                                <label for="location">{{ 'location' | lesCustomLocalize : 'label' }}:</label>
                                <select class="form-control" id="location" name="location" *ngIf="selectedSearchMode === 'date'" [(ngModel)]="selectedDateFilterLocation" [required]="true" (change)="fetchSlotsFilteredByDate()">
                                    <option value=""></option>
                                    <ng-container>
                                        <optgroup *ngFor="let group of filteredLocationsWithService" label="{{ group.groupName }}">
                                            <option *ngFor="let loc of group.eventLocationSelection" [value]="loc.key">{{ loc.name }}</option>
                                        </optgroup>
                                    </ng-container>
                                </select>
                                <select class="form-control" id="location" name="location" *ngIf="selectedSearchMode === 'location'" [(ngModel)]="selectedLocation" [required]="true" (change)="fetchSlotsFilteredByLocation()">
                                    <option value=""></option>
                                    <ng-container>
                                        <ng-container *ngFor="let loc of filteredLocations">
                                            <option [ngValue]="loc">{{ loc.locationName }}</option>
                                        </ng-container>
                                    </ng-container>
                                </select>
                            </div>

                            <div class="form-group" *ngIf="selectedSearchMode === 'location' && selectedServiceTypes?.length > 0">
                                <label for="dateOfEvent">
                                    {{ 'date' | lesCustomLocalize : 'label' }}: <span *ngIf="isFetching">{{ 'loading' | lesCustomLocalize : 'label' }}</span>
                                    <span [ngbTooltip]="datePopupLegend">
                                        <i class="fa fa-info-circle"></i>
                                    </span>
                                </label>
                                <ng-template #datePopupLegend>
                                    <div class="dateFilterPopupLegend">
                                        <div>
                                            <span class="date-legend date-legend-match pr-2"></span><span class="name">{{ 'allservicesavailable' | lesCustomLocalize : 'label' }}</span>
                                        </div>
                                        <ng-container *ngIf="serviceTypeSelectedCount() > 1">
                                            <div>
                                                <span class="date-legend date-legend-partial-match pr-2"></span><span class="name">{{ 'someservicesavailable' | lesCustomLocalize : 'label' }}</span>
                                            </div>
                                        </ng-container>
                                        <div>
                                            <span class="date-legend date-legend-fullorclosedevent pr-2"></span><span class="name">{{ 'fullorclosedeventlegend' | lesCustomLocalize : 'label' }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        placeholder="mm/dd/yyyy"
                                        name="dateOfEvent"
                                        [required]="true"
                                        (ngModelChange)="onDateOfEventChange()"
                                        [(ngModel)]="dateOfEvent"
                                        [dayTemplate]="customDay"
                                        [minDate]="minDate()"
                                        [disabled]="true"
                                        [maxDate]="maxDate()"
                                        [markDisabled]="isDisabled"
                                        [firstDayOfWeek]="7"
                                        [outsideDays]="hidden"
                                        ngbDatepicker
                                        #filterByLocationDatePicker="ngbDatepicker"
                                    />
                                    <button class="input-group-addon" (click)="disabled ? true : toggleLocationFilterDatePicker()" type="button" [disabled]="selectedLocation === null || selectedServiceTypes.length === 0">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                    </button>
                                </div>

                                <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-disabled="disabled">
                                    <span class="custom-day" [ngClass]="getDayLocationClass(date)" [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>

                            <div class="d-lg-none">
                                <button type="submit" class="btn btn-primary tertiary-bg" id="actionId-NextScheduleYourAppointments" (click)="next()">{{ 'next' | lesCustomLocalize : 'label' }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div id="appointment-picker" [ngClass]="{ 'col-md-12': true, 'col-lg-5': true, 'd-none d-lg-block': sectionToShow !== 'select' }">
            <div class="scheduler-panel" id="titleId-AppointmentSelection">
                <h4 class="primary-text" id="select-top">{{ 'select' | lesCustomLocalize : 'label' }}</h4>
                <div *ngIf="isFetching">
                    <app-loading [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>
                </div>

                <div *ngIf="!dateCollectionSummary && !isFetching">
                    {{ 'selectappointmentsearchoptions' | lesCustomLocalize : 'message' }}
                </div>

                <div *ngIf="dateCollectionSummary && !isFetching">
                    <div class="scheduler-month" *ngIf="dateOfEvent">
                        <div class="scheduler-month-label">
                            <h4 class="tertiary-text">{{ getMonthString(dateOfEvent) }}</h4>
                        </div>
                    </div>
                    <div class="scheduler-month" *ngIf="dateFilterDay">
                        <div class="scheduler-month-label">
                            <h4 class="tertiary-text">{{ getMonthString(dateFilterDay) }}</h4>
                        </div>
                    </div>
                </div>

                <div *ngIf="dateCollectionSummary && !isFetching && noApptsForThisMonth()">
                    {{ 'noavailabletimeslotforthismonth' | lesCustomLocalize : 'message' }}
                </div>

                <div *ngIf="dateCollectionSummary && !isFetching && !noApptsForThisMonth() && noApptsForThisDay()">
                    {{ 'noavailabletimeslotforthisday' | lesCustomLocalize : 'message' }}
                </div>

                <div id="select-days" #selectdays *ngIf="dateCollectionSummary && !noApptsForThisDay()" (scroll)="onSelectDaysScroll()">
                    <div id="select-days-top"></div>
                    <div id="go-to-top" class="hidden" #gototop (click)="goToTop()"><i class="fa fa-arrow-up fa-2x"></i></div>
                    <div id="go-to-top" class="hidden" #gototopmobile (click)="goToTopMobile()"><i class="fa fa-arrow-up fa-2x"></i></div>

                    <ng-container *ngFor="let dateSummary of dateCollectionSummary.dates; let dateIdx = index">
                        <div class="scheduler-day" *ngIf="showDate(dateSummary)" [id]="dateSummary.date.getTime()">
                            <ng-container *ngIf="dateSummary.times?.length === 0">
                                <div [ngClass]="{ 'scheduler-date-label': true, 'tertiary-text': true }">
                                    {{ getDayString(dateSummary.dateLabel) }}
                                </div>
                                <div [id]="dateSummary.date.getTime()">
                                    {{ 'eventisfull' | lesCustomLocalize : 'message' }}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="dateSummary.times?.length > 0">
                                <div [ngClass]="{ 'scheduler-date-label': true, 'tertiary-text': true }">
                                    {{ getDayString(dateSummary.dateLabel) }}
                                </div>

                                <div [id]="dateSummary.date.getTime()" *ngIf="dateSummary.dateHasOpenEvent === false">
                                    {{ 'eventisclosed' | lesCustomLocalize : 'message' }}
                                </div>
                            </ng-container>
                            <div ngbAccordion [closeOthers]="true" #ac="ngbAccordion">
                                <div *ngFor="let dateSummaryTime of dateSummary.times; let timeIdx = index" ngbAccordionItem="Scheduler_Slot_{{ timeIdx }}">
                                    <div
                                        ngbAccordionHeader
                                        *ngIf="showTime(dateSummaryTime)"
                                        [id]="dateSummaryTime.hour"
                                        [disabled]="dateSummary.eventIsFull || !dateSummary.dateHasOpenEvent || !timeShouldBeShown(dateSummaryTime)"
                                        [ngClass]="{ 'card-header': true, 'time-picker': true, 'card-header-closed-or-full': !dateSummary.dateHasOpenEvent || dateSummary.eventIsFull }"
                                    >
                                        <button
                                            ngbAccordionToggle
                                            [ngClass]="{
                                                'scheduler-time-grouping': true,
                                                clearfix: true,
                                                'w-100 d-flex justify-content-between': true,
                                                available: timeHasEnough(dateSummaryTime.id) === 1 && dateSummary.dateHasOpenEvent && !dateSummary.eventIsFull,
                                                'event-closed-or-full': !dateSummary.dateHasOpenEvent || dateSummary.eventIsFull,
                                                'semi-available': timeHasEnough(dateSummaryTime.id) === 0 && dateSummary.dateHasOpenEvent && !dateSummary.eventIsFull,
                                                'not-available': timeHasEnough(dateSummaryTime.id) === -1 && dateSummary.dateHasOpenEvent && !dateSummary.eventIsFull
                                            }"
                                        >
                                            <div *ngIf="timeIdx > 0 && dateCollectionSummary.dates[dateIdx].times[timeIdx].eventKey !== dateCollectionSummary.dates[dateIdx].times[timeIdx - 1].eventKey">
                                                <hr class="scheduler-time-event-break" />
                                            </div>
                                            <div [ngClass]="{ 'hour-label': dateSummary.dateHasOpenEvent, 'hour-label-closed': !dateSummary.dateHasOpenEvent }">{{ dateSummaryTime.hour }}</div>
                                            <div class="pull-right">
                                                <ng-container *ngFor="let serviceType of dateSummaryTime.serviceTypes">
                                                    <div
                                                        [ngClass]="{
                                                            'service-type-time-select': dateSummary.dateHasOpenEvent,
                                                            'service-type-time-select-closed': serviceType.eventIsClosed || serviceType.slotOptions.length === 0
                                                        }"
                                                        *ngIf="isSelectedServiceType(serviceType.key)"
                                                    >
                                                        <i class="fas" [ngClass]="serviceType.serviceTypeIcon"></i><span style="margin-left: 5px">{{ serviceType.numberOfSlots }}</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </button>
                                    </div>
                                    <div
                                        ngbAccordionCollapse
                                        [id]="dateSummaryTime.hour"
                                        [disabled]="dateSummary.eventIsFull || !dateSummary.dateHasOpenEvent || !timeShouldBeShown(dateSummaryTime)"
                                        [ngClass]="{ 'card-header': true, 'time-picker': true, 'card-header-closed-or-full': !dateSummary.dateHasOpenEvent || dateSummary.eventIsFull }"
                                    >
                                        <div ngbAccordionBody *ngIf="showTime(dateSummaryTime)">
                                            <ng-template>
                                                <div *ngIf="dateSummary.eventIsFull || !dateSummary.dateHasOpenEvent">
                                                    {{ 'notavailable' | lesCustomLocalize : 'message' }}
                                                </div>
                                                <div *ngIf="!dateSummary.eventIsFull && dateSummary.dateHasOpenEvent">
                                                    <div *ngFor="let serviceType of dateSummaryTime.serviceTypes">
                                                        <div class="slot-selection" *ngIf="isSelectedServiceType(serviceType.key) && !serviceType.eventIsClosed && serviceType.slotOptions.length > 0">
                                                            <h4 class="tertiary-text">{{ getProgramLabel(serviceType.displayName, serviceType.key) }}</h4>
                                                            <div *ngIf="numberOfParticipants > 0 && (bookForMe || bookForGuest)">
                                                                <ng-container *ngFor="let participantOrdinal of this.numberOfParticipantsCollection; let i = index">
                                                                    <div class="participant-slot form-group">
                                                                        <label *ngIf="i === 0 && bookForMe"> {{ person().firstName }} {{ person().lastName }} </label>
                                                                        <label *ngIf="i === 1 && bookForGuest">
                                                                            {{ 'guest' | lesCustomLocalize : 'label' }}
                                                                        </label>
                                                                        <p *ngIf="i === 1 && bookForGuest && !serviceType.bookForOthers">{{ 'cannotbookforothers' | lesCustomLocalize : 'label' }}</p>
                                                                        <select
                                                                            *ngIf="(i === 0 && bookForMe) || (i === 1 && bookForGuest && serviceType.bookForOthers)"
                                                                            class="form-control"
                                                                            (change)="addAppointment($event, serviceType, participantOrdinal)"
                                                                        >
                                                                            <option value="">({{ 'selectatimeslot' | lesCustomLocalize : 'message' }})</option>
                                                                            <option *ngFor="let slotOption of serviceType.slotOptions" [ngValue]="slotOption.time" [selected]="optionSelected(serviceType, participantOrdinal, slotOption.time)">
                                                                                {{ slotOption.time | amDateFormat : 'hh:mm A' }} ({{ 'slotsavailable' | lesCustomLocalize : 'label' : slotOption.count }})
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="next-month" *ngIf="showNextMonth()">
                        <a href="#" id="actionId-NextMonthSchedule" (click)="nextMonth($event)">{{ 'nextmonth' | lesCustomLocalize : 'label' }}</a>
                    </div>
                </div>

                <div class="d-lg-none pt-2 mt-2">
                    <button type="submit" id="actionId-NextSchedule" class="btn btn-primary tertiary-bg" (click)="next()">{{ 'next' | lesCustomLocalize : 'label' }}</button>
                </div>
            </div>
        </div>

        <div id="itinerary" [ngClass]="{ 'col-md-12': true, 'col-lg-4': true, 'd-none d-lg-block': sectionToShow !== 'itinerary' }">
            <div class="scheduler-panel">
                <h4 class="primary-text" id="titleId-Schedule">{{ 'youritinerary' | lesCustomLocalize : 'label' }}</h4>

                <div id="itineraryDetails">
                    <ng-container *ngIf="itinerary.numberOfAppointments === 0">
                        <p>{{ 'noappointmentsselected' | lesCustomLocalize : 'message' }}</p>
                    </ng-container>

                    <ng-container *ngIf="itinerary.numberOfAppointments > 0">
                        <ng-container *ngFor="let participant of itinerary.participants; let i = index">
                            <div class="itinerary-participant" *ngIf="participant.appointments.length > 0">
                                <h4 class="tertiary-text" *ngIf="participant.ordinal === 1">{{ person().fullName }}</h4>
                                <div class="additional-participant" *ngIf="participant.ordinal > 1">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" [(ngModel)]="participant.firstName" [required]="true" placeholder="{{ 'firstname' | lesCustomLocalize : 'label' }}" />
                                        </div>
                                        <div class="col">
                                            <input type="text" class="form-control" [(ngModel)]="participant.lastName" [required]="true" placeholder="{{ 'lastname' | lesCustomLocalize : 'label' }}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="itinerary-appt" *ngFor="let appt of participant.appointments">
                                    <div class="svc-type">{{ getProgramLabel(appt.programServiceType.displayName, appt.programServiceType.programServiceTypeKey) }}</div>
                                    <div class="appt-date">
                                        {{ getLocaleDay(appt.time | amDateFormat : 'dddd') }}, {{ getLocaleMonth(appt.time | amDateFormat : 'MMMM').substring(0, 3) }} {{ appt.time | amDateFormat : 'D, YYYY' }}
                                        {{ appt.time | amDateFormat : 'hh:mm A' }} <span *ngIf="appt.eventLocation && appt.eventLocation.timezone">({{ appt.eventLocation.timezone }})</span>
                                    </div>
                                    <div class="appt-location" [innerHTML]="appt.locationText"></div>
                                    <div class="appt-fasting" *ngIf="appt.showFastingState">{{ 'fasting' | lesCustomLocalize : 'label' }}: {{ appt.fastingToken | lesCustomLocalize : 'label' }}</div>
                                    <div class="already-taken" *ngIf="appt.alreadyTaken">{{ 'slotnolongeravailable' | lesCustomLocalize : 'label' }}</div>
                                    <div class="already-taken" *ngIf="appt.alreadyHasAppointmentInFuture">{{ 'slotalreadyhasappointmentinfuture' | lesCustomLocalize : 'label' }}</div>
                                    <div class="consent-needed" *ngIf="appt.consentNeeded">{{ 'mustagreetoconsentstatementfirst' | lesCustomLocalize : 'message' }}</div>
                                    <div class="appt-delete">
                                        <a href="#" id="actionId-DeleteAppointment" (click)="deleteAppt($event, appt)">{{ 'remove' | lesCustomLocalize : 'label' }}</a>
                                    </div>
                                    <hr *ngIf="i !== participant.appointments.length - 1" />
                                </div>
                            </div>
                        </ng-container>

                        <div class="covid-capture-container">
                            {{ 'additionalcovid' | lesCustomLocalize : 'message' }}
                        </div>

                        <button type="button" class="btn btn-primary tertiary-bg" id="actionId-BookAppointmentst" (click)="bookAppointments()" [disabled]="isProcessing">
                            <span *ngIf="!isProcessing">{{ 'scheduleappointments' | lesCustomLocalize : 'label' }}</span>
                            <span *ngIf="isProcessing">{{ 'scheduleappointments' | lesCustomLocalize : 'label' }} <i class="fa fa-spinner fa-spin"></i> </span>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="noEvents">
    <h4 id="titleId-OnlineScheduler">{{ 'onlinescheduler' | lesCustomLocalize : 'label' }}</h4>
    <p>{{ 'noeventstoregister' | lesCustomLocalize : 'message' }}</p>
</ng-container>

<app-error *ngIf="hasError" [htmlId]="'Scheduler'"></app-error>
<app-loading *ngIf="!readyForRender && !hasError && !noEvents" [msg]="'loading' | lesCustomLocalize : 'label'"></app-loading>

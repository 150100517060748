export class ProgramServiceAndType {
    programServiceKey: string;
    name: string;
    maySupportBookForOthers: boolean;
    displayName: string;
    programServiceTypeKey: string;
    serviceTypeIcon: string;
    serviceSearchKey: string;
}
export class AvailableProgramServiceType{
    key:string
    name: string;
    maySupportBookForOthers: boolean;
    displayName: string;    
    serviceSearchKey: string;
    serviceTypeIcon: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';

import { LocalizeService } from '../../shared/localize/localize.service';
import { ColumnSetting } from '../../shared/wcs-table/column-setting';
import { ReferenceRange } from '../model/result-lookup';

@Component({
  selector: 'app-reference-ranges',
  templateUrl: './reference-ranges.component.html',
  styleUrls: ['./reference-ranges.component.css']
})
export class ReferenceRangesComponent implements OnInit {
  @Input() referenceRanges: ReferenceRange[];
  hasError = false;

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService
                ) { }

  ngOnInit() {
  }

  showAge() {
    const refs = _.find(this.referenceRanges, (ref: ReferenceRange) => { return ref.ageMax > 0; });
    if (refs && refs.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  showGender() {
    const refs = _.filter(this.referenceRanges, (ref: ReferenceRange) => {
      return ref.gender;
    });
    if (refs && refs.length > 1) {
      return true;
    }
    else {
      return false;
    }
  }

  showUnits(ref: ReferenceRange): boolean {
    return ref.rangeDisplay && ref.rangeDisplay.substr(0, 1) !== '*';
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAccordionDirective, NgbModal, NgbModalRef, } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { Subscription } from 'rxjs';

import { LabcorpAppointSchedulerComponent } from '../../services/appointments/labcorp.appointment.component';
import { FileUploadComponent } from '../../services/file-upload/file-upload.component';
import { ServicesService } from '../../services/services-list/services.service';
import { AlertModalComponent } from '../../shared/alert-modal/alert-modal.component';
import { AttestationModel } from '../../shared/attestation/attestation.model';
import { AttestationService } from '../../shared/attestation/attestation.service';
import { AttestationModalComponent } from '../../shared/attestation/attestation-modal.component';
import { GapsInCareViewModel } from '../../shared/gaps-in-care/gaps-in-care.model';
import { GapsInCareService } from '../../shared/gaps-in-care/gaps-in-care.service';
import { GapsInCareModalComponent } from '../../shared/gaps-in-care/gaps-in-care-modal.component';
import { Gender } from '../../shared/gender/gender';
import { Globals } from '../../shared/globals';
import { IntegrationService } from '../../shared/integration/integration.service';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { LocalizeService } from '../../shared/localize/localize.service';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Person, PersonCreatePost } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { PersonValidation, PersonValidationRuleViolationResult } from '../../shared/person-data-collection/person.data.collection.models';
import { PersonDataCollectionService } from '../../shared/person-data-collection/person.data.collection.service';
import { Service, UserServices } from '../../shared/service';
import { ServiceQualificationRuleOverrideCodeService } from '../../shared/service-qualification-rule-failure-modal/service-qualification-rule-override-code-service';
import { Utils } from '../../shared/utils';
import { PersonWidgetCollection, PersonWidgetCollectionProgress, PersonWidgetCollectionProgressCriteria, PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../person.widget.models';
import { PersonWidgetService } from '../person.widget.service';
import { PersonWidgetModalComponent } from '../person-widget-modal/person-widget-modal.component';

@Component({
    selector: 'app-person-widget-engine',
    templateUrl: './person-widget-engine.component.html',
    styleUrls: ['./person-widget-engine.component.css']
})
export class PersonWidgetEngineComponent implements OnInit, OnDestroy {
    _widgetCollectionWidgets: PersonWidgetCollectionWidget[] = [];
    personWidgetCollection: PersonWidgetCollection;
    currentUser: Person;
    otherPerson: Person;
    otherUserKey: string;
    programServiceKey: string;
    programServiceTypeKey: string;
    subscriptions: Subscription[] = [];
    isRenderReady = false;
    activeId: string;
    panelPrefix = 'panelID_';
    hasError = false;
    modalRef: NgbModalRef;
    loadingModalRef: NgbModalRef = null;
    serviceTypeName: string;
    upload = false;
    progress = 0;
    showContinueButton = false;
    isReturn = false;
    didUpdate = false;
    genderList: Gender[];
    personKey: string;
    updateAttempts: number[] = [];

    @ViewChild('acc') accordion: NgbAccordionDirective;
    constructor(
        private _personWidgetService: PersonWidgetService,
        private _personService: PersonService,
        private route: ActivatedRoute,
        private _activatedRoute: ActivatedRoute,
        private _localizeService: LocalizeService,
        private _modalService: NgbModal,
        private _router: Router,
        private _servicesService: ServicesService,
        private _integrationService: IntegrationService,
        private _overrideCodeService: ServiceQualificationRuleOverrideCodeService,
        private _attestationService: AttestationService,
        private _gapsInCareService: GapsInCareService,
        private _personDataCollectionService: PersonDataCollectionService,
        private _metadataService: MetadataService
    ) { }

    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentUser;
    }

    logInPerson(): Person {
        return this.currentUser ? this.currentUser : this.otherPerson;
    }

    get genderKey() {
        if (this.person().gender && this.genderList && this.genderList.length > 0) {
            const genderValue = this.person().gender.substring(0, 1).toLowerCase();
            const gender = this.genderList.find((g) => g.labcorpValue.toLowerCase() == genderValue.toLowerCase());
            return gender ? gender.key : null;
        }
        return null;
    }

    public getHtmlId(token: string) {
        return token.replace(/\s+/g, '-');
    }

    ngOnInit(): void {
        const that = this;
        this.subscriptions.push(
            this.route.queryParams.subscribe((params) => {
                this.otherUserKey = params.user;
                this.programServiceKey = params.service;
                this.upload = params.upload;
                if (!this.currentUser) {
                    this.subscriptions.push(
                        this._personService.currentPerson.subscribe(
                            (person) => {
                                if (person) {
                                    this.currentUser = person;

                                    if (this.otherUserKey) {
                                        this.subscriptions.push(
                                            this._personService.getOtherPerson(this.otherUserKey, false).subscribe(
                                                (other) => {
                                                    if (other) {
                                                        this.otherPerson = other;
                                                        this.loadPersonKey();
                                                    }
                                                },
                                                (error) => {
                                                    this.onError();
                                                }
                                            )
                                        );
                                    } else {
                                        this.loadPersonKey();
                                    }
                                }
                            },
                            (error) => {
                                this.onError();
                            }
                        )
                    );
                } else {
                    this.loadPersonKey();
                }
            })
        );
    }

    onCompleted(completedAction: WidgetCompleted) {
        if (completedAction.isComplete != true && completedAction.action != WidgetCloseAction.backward) {
            this.displayModalError();
            return;
        }
        const index: number = this._widgetCollectionWidgets.findIndex((r) => r.widgetPanelIndex == completedAction.widgetPanelIndex);
        if (index < 0) {
            this.displayModalError();
            return;
        }
        const currentWidgetCollection = this._widgetCollectionWidgets[index];
        //Save progress and proceed or allow go back
        if (completedAction.isComplete != true && completedAction.action == WidgetCloseAction.backward) {
            //go back without validation
            return this.loadNextWidget(completedAction, currentWidgetCollection);
        }
        const progress = new PersonWidgetCollectionProgress();
        const validation = new PersonValidation();
        validation.companyKey = this.person().companyKey;
        progress.dataCollectionKey = validation.dataCollectionKey = currentWidgetCollection.dataCollectionKey;
        progress.participantKey = validation.participantKey = this.person().participantKey;
        progress.userKey = validation.userKey = this.person().userKey;
        progress.programServiceKey = validation.programServiceKey = this.programServiceKey;
        progress.widgetKey = currentWidgetCollection.widgetKey;
        progress.key = currentWidgetCollection.personWidgetCollectionProgressKey;
        progress.consentDefinitionKey = currentWidgetCollection.consentDefinitionKey;
        progress.widgetCollectionKey = this.personWidgetCollection.widgetCollectionKey;
        progress.personKey = this.personKey;
        const currentKey = this.currentUser ? this.currentUser.userKey : this.person().userKey;

        this._personDataCollectionService.validatePersonResponses(this.personKey, validation).subscribe((r) => {
            //failed update progress if exists
            if (r && ((r.generalPassFailTag && r.generalPassFailTag.length) || (r.passFailTag && r.passFailTag.length))) {
                //reset progress
                //if exists update, else do nothing
                progress.isComplete = false;
                if (currentWidgetCollection.personWidgetCollectionProgressKey) {
                    this._personWidgetService.updateProgress(currentKey, progress).subscribe(
                        (res) => {
                            currentWidgetCollection.isComplete = res.isComplete;
                            currentWidgetCollection.status = res.isComplete == true ? 'complete' : 'incomplete';
                            currentWidgetCollection.personWidgetCollectionProgressKey = res.key;
                            this._widgetCollectionWidgets[index] = currentWidgetCollection;
                            this.displayWidgetValidationError(r);
                        },
                        (error) => {
                            this.onError();
                        }
                    );
                } else {
                    //there is nothing to update, just display error.
                    this.displayWidgetValidationError(r);
                }
            } else {
                //passed
                progress.isComplete = true;
                this.createOrUpdateProgress(completedAction, progress, currentWidgetCollection);
            }
        });
    }

    private createOrUpdateProgress(completedAction: WidgetCompleted, progress: PersonWidgetCollectionProgress, currentWidgetCollection: PersonWidgetCollectionWidget) {
        if (currentWidgetCollection.personWidgetCollectionProgressKey) {
            this._personWidgetService.updateProgress(this.logInPerson().userKey, progress).subscribe(
                (res) => {
                    currentWidgetCollection.isComplete = res.isComplete;
                    currentWidgetCollection.status = res.isComplete == true ? 'complete' : 'incomplete';
                    currentWidgetCollection.personWidgetCollectionProgressKey = res.key;
                    this._widgetCollectionWidgets[completedAction.widgetPanelIndex] = currentWidgetCollection;
                    this.loadNextWidget(completedAction, this._widgetCollectionWidgets[completedAction.widgetPanelIndex]);
                },
                (error) => {
                    this.onError();
                }
            );
        } else {
            this._personWidgetService.createProgress(this.logInPerson().userKey, progress).subscribe(
                (res) => {
                    currentWidgetCollection.isComplete = res.isComplete;
                    currentWidgetCollection.status = res.isComplete == true ? 'complete' : 'incomplete';
                    currentWidgetCollection.personWidgetCollectionProgressKey = res.key;
                    this._widgetCollectionWidgets[completedAction.widgetPanelIndex] = currentWidgetCollection;
                    this.loadNextWidget(completedAction, this._widgetCollectionWidgets[completedAction.widgetPanelIndex]);
                },
                (error) => {
                    this.onError();
                }
            );
        }
    }

    private displayWidgetValidationError(validation: PersonValidationRuleViolationResult) {
        this.modalRef = this._modalService.open(PersonWidgetModalComponent, { backdrop: 'static' });
        this.modalRef.componentInstance.msgTag = validation.generalPassFailTag;
        this.modalRef.componentInstance.msgTags = validation.passFailTag;
        this.modalRef.result.then((result) => {
            this._router.navigate(['/']);
        });
    }

    private loadNextWidget(completedAction: WidgetCompleted, currentWidgetCollection: PersonWidgetCollectionWidget) {
        if (completedAction.action == WidgetCloseAction.backward) {
            //can we go back?
            if (currentWidgetCollection.position == WidgetCollectionPosition.first) {
                //we cannot go back
                //do we need to do something else? Will this even happen?
            } else {
                const newToOpen = this._widgetCollectionWidgets.find((r) => r.widgetPanelIndex == currentWidgetCollection.widgetPanelIndex - 1);
                if (!newToOpen) {
                    this.displayModalError();
                } else {
                    this.removePassedAttempt(currentWidgetCollection.widgetPanelIndex);
                    //this.activeIds = [this.panelPrefix + newToOpen.widgetPanelIndex];
                    this.activeId = this.panelPrefix + newToOpen.widgetPanelIndex;
                    this.onAccordionChange(this.activeId);
                }
            }
        } else if (completedAction.action == WidgetCloseAction.forward) {
            if (currentWidgetCollection.position == WidgetCollectionPosition.last || this._widgetCollectionWidgets.length == 1) {
                //time to leave widgets page
                //did you skip something?
                const firstIncomplete = this._widgetCollectionWidgets.find((r) => r.widgetPanelIndex != currentWidgetCollection.widgetPanelIndex && r.isComplete != true);
                if (firstIncomplete && firstIncomplete.widgetPanelIndex > -1) {
                    //this.activeIds = [this.panelPrefix + firstIncomplete.widgetPanelIndex];
                    this.activeId = this.panelPrefix + firstIncomplete.widgetPanelIndex;
                    this.onAccordionChange(this.activeId);
                    return;
                } else {
                    return this.widgetComplete(this.upload);
                }
            }
            const newToOpen = this._widgetCollectionWidgets.find((r) => r.widgetPanelIndex == currentWidgetCollection.widgetPanelIndex + 1);
            if (!newToOpen) {
                this.displayModalError();
            } else {
                this.removePassedAttempt(currentWidgetCollection.widgetPanelIndex);
                //this.activeIds = [this.panelPrefix + newToOpen.widgetPanelIndex];
                this.activeId = this.panelPrefix + newToOpen.widgetPanelIndex;
                this.onAccordionChange(this.activeId);
            }
        } else {
            this.displayModalError();
        }
    }

    private togglePanel(id: string) {
        this.accordion.toggle(id);
    }

    private loadServiceDetails() {
        const currentService = this.person().UserServices.find((r) => r.programService.programServiceKey.toLowerCase() === this.programServiceKey.toLowerCase());
        if (currentService) {
            this.serviceTypeName = currentService.programService.programType ? currentService.programService.programType : currentService.programService.programName;
            this.programServiceTypeKey = currentService.programService.serviceType;
        }
    }

    get UserServices(): UserServices[] {
        return this.person().UserServices;
    }

    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }

    private loadPersonKey() {
        const locale = this.person().locale ? this.person().locale.languageLocale : 'en-us';
        this.subscriptions.push(
            this._metadataService.getGenders(locale).subscribe(
                (data) => {
                    if (data) {
                        this.genderList = data;
                        this._personService.createPerson(new PersonCreatePost(this.person(), this.logInPerson().userKey, this.genderKey)).subscribe((r) => {
                            this.personKey = r;
                            this.loadData();
                        });
                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    this.onError();
                }
            )
        );
    }

    private loadData() {
        if (!this._widgetCollectionWidgets.length) {
            const search: PersonWidgetCollectionProgressCriteria = new PersonWidgetCollectionProgressCriteria();
            search.userKey = this.person().userKey;
            search.programServiceKey = this.programServiceKey;
            const companyKey = this.person().company ? this.person().company.key : null;
            if (companyKey) {
                search.companyKey = companyKey;
            }
            this.loadServiceDetails();

            this._personWidgetService.getPersonWidgetCollectionWidget(search).subscribe((resultContainer) => {
                this.personWidgetCollection = resultContainer;
                this.personWidgetCollection.titleLanguage = this._localizeService.getCombinedTag(this.personWidgetCollection.titleTag, null, null, null, this.tagOptions);
                this.personWidgetCollection.descriptionLanguage = this._localizeService.getCombinedTag(this.personWidgetCollection.descriptionTag, null, null, null, this.tagOptions);
                const result = resultContainer.collectionWidgets;

                this._widgetCollectionWidgets = result.map((r, index) => {
                    const currentItem = r;
                    currentItem.widgetPanelIndex = index;
                    if (index == 0) {
                        currentItem.position = WidgetCollectionPosition.first;
                    } else if (index + 1 == result.length) {
                        currentItem.position = WidgetCollectionPosition.last;
                    } else {
                        currentItem.position = WidgetCollectionPosition.other;
                    }

                    currentItem.widgetIcon = this.getWidgetIcon(r.widgetIcon);
                    //add tags
                    if (r.titleTag) {
                        currentItem.titleLanguage = this._localizeService.getCombinedTag(r.titleTag, null, null, null, this.tagOptions);
                    }
                    if (r.subtitleTag) {
                        currentItem.titleLanguage = this._localizeService.getCombinedTag(r.subtitleTag, null, null, null, this.tagOptions);
                    }
                    if (r.footerTag) {
                        currentItem.footerLanguage = this._localizeService.getCombinedTag(r.footerTag, null, null, null, this.tagOptions);
                    }
                    if (r.descriptionTag) {
                        currentItem.descriptionLanguage = this._localizeService.getCombinedTag(r.descriptionTag, null, null, null, this.tagOptions);
                    } else {
                        currentItem.descriptionLanguage = '';
                    }
                    currentItem.status = r.isComplete == true ? 'complete' : 'incomplete';
                    return currentItem;
                });

                const indexOfFirst = _.findIndex(this._widgetCollectionWidgets, (r) => !r.isComplete || r.isComplete == false);
                if (indexOfFirst > -1) {
                    this.activeId = this.panelPrefix + this._widgetCollectionWidgets[indexOfFirst].widgetPanelIndex;
                }
                this.isRenderReady = true;

                const anyRequiredAndIncomplete = result.filter((r) => r.isRequired == true && !r.isComplete);
                if (!anyRequiredAndIncomplete || anyRequiredAndIncomplete.length == 0 || this._widgetCollectionWidgets.length == 0) {
                    this.showContinueButton = true;
                    this.isReturn = true;
                }
            });
        }
    }

    onAccordionChange(id: string) {
        if (this.updateAttempts.length == 0 && this.isReturn) {
            this.showContinueButton = true;
            //this.accordion.expand(id);
            return;
        }
        //this.accordion.expand(id);
        this.showContinueButton = false;
    }
    onAccordionHidden(id: string) {
        // if ($event.nextState) {
        //     this.activeIds = [$event.panelId];
        // } else {
        //     this.activeIds = [];
        //     if (this.updateAttempts.length == 0 && this.isReturn) {
        //         this.showContinueButton = true;
        //         return;
        //     }
        // }
        //console.log("hidding " + id)
        // this.accordion.expand($event);
        //this.showContinueButton = false;
        if (this.activeId == id) {
            this.activeId = "";
        }
        if (this.updateAttempts.length == 0 && this.isReturn) {
            this.showContinueButton = true;
            return;
        }
    }
    onAccordionShown(id: string) {
        // if ($event.nextState) {
        //     this.activeIds = [$event.panelId];
        // } else {
        //     this.activeIds = [];
        //     if (this.updateAttempts.length == 0 && this.isReturn) {
        //         this.showContinueButton = true;
        //         return;
        //     }
        // }
        //console.log("showing " + id)
        // this.accordion.expand($event);
        //this.showContinueButton = false;
        this.activeId = id;
    }

    private removePassedAttempt(val: number) {
        if (this.updateAttempts.length > 0) {
            const location = this.updateAttempts.findIndex((r) => r == val);
            if (location > -1) {
                this.updateAttempts.splice(location, 1);
            }
        }
    }

    onUpdateAttempted(val: number) {
        if (val && this.updateAttempts.indexOf(val) < 0) {
            this.updateAttempts.push(val);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }

    private onError() {
        this.hasError = true;
        this.showLoader(false);
    }

    getTitle(title: string) {
        return this._localizeService.get(title, 'label');
    }

    getWidgetIcon(icon?: string) {
        if (icon && icon.indexOf('fas') < 0) {
            icon = 'fas ' + icon;
        }
        return icon;
    }

    onContinueButton() {
        this.widgetComplete(this.upload);
    }

    //Services page code. We need to support completion of service card selection, especially those dealing with
    //modal pop ups
    public scheduleLapcorpAppointment(service: Service) {
        this.modalRef = this._modalService.open(LabcorpAppointSchedulerComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.currentService = service;
        this.modalRef.componentInstance.currentUser = this.person();
        this.modalRef.result.then((err) => {
            this._router.navigate(['/']);
        });
    }

    private widgetComplete(upload = false): void {
        const service = this.UserServices.find((r) => r.programService.programServiceKey.toLowerCase() == this.programServiceKey.toLowerCase()).programService;
        switch (service.serviceType) {
            case Globals.ServiceTypes.Coaching:
                this._router.navigate([Globals.Routes.coaching]);
                break;
            case Globals.ServiceTypes.OnlineScheduler:
                if (this.otherPerson && this.otherPerson.userKey != this.currentUser.userKey) {
                    this._router.navigate([Globals.Routes.scheduler], { queryParams: { user: this.otherPerson.userKey } });
                } else {
                    this._router.navigate([Globals.Routes.scheduler]);
                }
                break;
            case Globals.ServiceTypes.InfoOnly:
                this.openWindow(service.informationUrl);
                this._personService.fetchPerson();
                if (this.otherPerson && this.otherPerson.userKey != this.currentUser.userKey) {
                    this._router.navigate([Globals.Routes.participant + `/${this.otherPerson.userKey}`], { queryParams: { service: service.programServiceKey, upload: this.upload, queryParamsHandling: false } });
                } else {
                    this._router.navigate(['/']);
                }
                break;
            case Globals.ServiceTypes.ThirdParty:
            case Globals.ServiceTypes.PDF:
            case Globals.ServiceTypes.Pixel:
            case Globals.ServiceTypes.Attestation:
            case Globals.ServiceTypes.GapsInCare:
                if (this.otherPerson && this.otherPerson.userKey != this.currentUser.userKey) {
                    this._router.navigate([Globals.Routes.participant + `/${this.otherPerson.userKey}`], { queryParams: { service: service.programServiceKey, upload: this.upload, queryParamsHandling: false } });
                } else {
                    this._router.navigate([Globals.Routes.home], { queryParams: { service: service.programServiceKey, upload: this.upload, queryParamsHandling: false } });
                }
                break;
        }
    }

    openGapsInCareModal(gapsInCareViewModel: GapsInCareViewModel) {
        //load gic data for modal
        this.modalRef = this._modalService.open(GapsInCareModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });

        this.modalRef.componentInstance.title = this._localizeService.get(gapsInCareViewModel.titleLabel, 'program');
        this.modalRef.componentInstance.gapsInCareViewModel = gapsInCareViewModel;
        this.modalRef.componentInstance.currentPerson = this.person();
        this.modalRef.componentInstance.userKey = this.person().userKey;
        this.modalRef.result.then((err) => {
            this._router.navigate(['/']);
        });
    }

    private openWindow(href: string) {
        window.open(href);
    }

    openPixelWindow(href: string) {
        this.showLoader(false);

        if (href != null) {
            if (Utils.isMobileDevice()) {
                window.location.href = href;
            } else {
                this.openWindow(href);
            }
        }
        this._router.navigate(['/']);
    }

    private displayModalError(tagMsg: string = null) {
        this.modalRef = this._modalService.open(AlertModalComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.msg = tagMsg ? this._localizeService.get(tagMsg, 'message') : this._localizeService.get('unknownerror', 'message');
    }

    private uploadModal(service: Service) {
        this.modalRef = this._modalService.open(FileUploadComponent, {
            backdrop: 'static'
        });
        this.modalRef.componentInstance.service = service;
        this.modalRef.componentInstance.userKey = this.person().userKey;
        this.modalRef.result.then((err) => {
            this._router.navigate(['/']);
        });
    }

    private showLoader(doShow: boolean, msg: string = null) {
        if (doShow) {
            if (typeof this.loadingModalRef !== 'undefined' && this.loadingModalRef !== null) {
                this.loadingModalRef.close();
            }

            this.loadingModalRef = this._modalService.open(LoadingComponent, {
                backdrop: 'static',
                windowClass: 'loadingModal'
            });

            if (msg) {
                this.loadingModalRef.componentInstance.msg = msg;
            }
        } else {
            this.loadingModalRef.close();
        }
    }

    openAttestationModal(attestation: AttestationModel) {
        //load attestation data for modal
        this.modalRef = this._modalService.open(AttestationModalComponent, {
            backdrop: 'static',
            size: 'lg'
        });
        this.modalRef.componentInstance.attestation = attestation;
        this.modalRef.componentInstance.userKey = this.person().userKey;
        this.modalRef.result.then((result) => {
            this._personService.fetchPerson();
            this._router.navigate(['/']);
        });
    }

    public getProgramLabel(token: string) {
        const prgLabel = this._localizeService.get(Utils.getServiceToken(token).toLowerCase(), 'program');
        if (!prgLabel || prgLabel.trim() === '') {
            return token;
        }

        return prgLabel.toUpperCase();
    }
    //end services code
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../localize/localize.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent implements OnInit {
  @Input() header: string = this._localizeService.get('error', 'label');
  @Input() tokenType = 'error'
  @Input() msg: string = this._localizeService.get('unknownerror', 'message');
  @Input() localizeNames: string[];
  @Input() localizeTags: any[];
  @Input() confirmBtn: string = this._localizeService.get('download', 'label');
  @Input() cancelBtn: string = this._localizeService.get('close', 'label');
  public download: string;
  showCustomError = false;
  customErrorList: string[] = [];
  mutedErrorList: string[] = [];

  constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService) { }

  ngOnInit() {
    if (this.localizeNames && this.localizeNames.length) {
      this.localizeNames.forEach(element => {
        this.customErrorList.push(this._localizeService.get(element, 'error'))
      });
      this.showCustomError = true;
    }
    if (this.localizeTags) {

      this.localizeTags.forEach(element => {
        if (element.secondary) {
          this.mutedErrorList.push(this._localizeService.get(element.tag, element.token))
        } else {
          this.customErrorList.push(this._localizeService.get(element.tag, element.token))
        }
      });
      this.showCustomError = true;
    }
  }

  public downloadPDF() {
    window.open(this.download, '_blank');
  }

}

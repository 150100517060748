import { EventLocation } from '../../scheduler/model/event-location';
import { Utils } from '../../shared/utils';
import { ResultService } from './service';

export class ResultEvent {
    key: string;
    startDate: Date;
    endDate: Date;
    location: EventLocation;
}

export class ScreenDataDetail {
    key: string;
    screenDataKey: string;
    referenceRangeKey: string;
    loinc: string;
    testName: string;
    analyteName: string;
    testResult: string;
    columnMatch: string;
    abnormal: string;
    refRange: string;
    units: string;
    notes: string;
    reflex: string;
}
export class ScreenData {
    key: string;
    programServiceKey: string;
    service: ResultService;
    event: ResultEvent;
    participantKey: string;
    screenTypeId: number;
    screenTypeName: string;
    fastingState: string;
    controlDate: Date;
    dateofService: Date;
    heightFeet: number;
    heightInches: number;
    weight: number;
    bodyFat: number;
    bmi: number;
    waistInches: number;
    hip: number;
    hipWaistRatio: number;
    heartRate: number;
    neck: number;
    bpsystolic1: number;
    bpdiastolic1: number;
    bpsystolic2: number;
    bpdiastolic2: number;
    totalCholesterol: number;
    hdl: number;
    ldl: number;
    triglycerides: number;
    totalHdlratio: number;
    glucose: number;
    a1c: number;
    cotinineNicotine: string;
    cotinineSerum: number;
    cotinineUrine: number;
    nicotineSerum: number;
    nicotineUrine: number;
    tobaccoUse: string;
    pregnant: string;
    psa: number;
    medConditionBp: string;
    medConditionChol: string;
    medConditionGlucose: string;
    medConditionBmi: string;
    lotnumber: string;
    manufacturer: string;
    vaclocation: string;
    vaccinetype: string;
    resultsAreReady: boolean;
    resultsPending: string;
}

export class LabCollectionData {
    key: string;
    screenDataKey: string;
    ggt: number;
    ast: number;
    alt: number;
    alkalinePhosphatase: number;
    bun: number;
    buncreatinineRatio: number;
    uricAcid: number;
    eGfrNonAfricanAmerican: number;
    eGfrAfricanAmerican: number;
    bilirubin: number;
    proteinSerum: number;
    albuminSerum: number;
    globulinSerum: number;
    agratio: number;
    ldh: number;
    calcium: number;
    iron: number;
    phosphorus: number;
    sodium: number;
    potassium: number;
    chloride: number;
    co2: number;
    monocytes: number;
    rbccount: number;
    hemoglobin: number;
    hemotocrit: number;
    mcv: number;
    mchc: number;
    plateletCount: number;
    wbc: number;
    lymphocyte: number;
    eosinophils: number;
    basophils: number;
    neutrophils: number;
    tsh: number;
    fructosamine: number;
    vitaminD: number;
    crp: number;
    creatinine: number;
    mchg: number;
    gsp: number;
    vldl: number;
    thyroxine: number;
    magnesium: number;
    dirBilirubin: number;
    uibc: number;
    tibc: number;
    ironSat: number;
    ferritin: number;
    insulinSerum: number;
    redCellDistribution: number;
    enhEstradiol: number;
    testosteroneTot: number;
    homocysteine: number;
    vitB12: number;
    folate: number;
    cea: number;
    ca125: number;
    ntprobnp: number;
    meanPlateletVol: number;
    monocytesAbs: number;
    neutrophilsAbs: number;
    basophilsAbs: number;
    t3total: number;
    t3uptake: number;
    freeThyroxineIndex: number;
    freeT4: number;
    fsh: number;
    indirectBilirubin: number;
    lymphoctyeAbs: number;
    eosinophilsAbs: number;
    fiT_OccultBlood_Fecal: string;
}

export class RiskStratification {
    key: string;
    screenDataKey: string;
    metabolicSyndrome: string;
    biometricScore: string;
    adadiabetesScore: number;
    framingham: string;
}

export class Result {
    screenData: ScreenData;
    labCollectionData: LabCollectionData;
    riskStratification: RiskStratification;
    screenDataDetails: ScreenDataDetail[];

    get key(): string {
        return this.screenData ? this.screenData.key : null;
    }

    get resultsPending(): string {
        return this.screenData && this.screenData.resultsAreReady ? '' : 'pending';
    }

    get resultDate(): Date {
        return this.screenData ? this.screenData.controlDate : null;
    }

    get dateOfService(): Date {
        return this.screenData ? this.screenData.dateofService : null;
    }

    get serviceType(): string {
        return this.screenData && this.screenData.service ? this.screenData.service.serviceType : null;
    }

    get serviceDisplayName(): string {
        return this.screenData && this.screenData.service ? this.screenData.service.displayName : null;
    }

    get screenTypeName(): string {
        return this.screenData ? this.screenData.screenTypeName : null;
    }

    get eventLocation(): string {
        if (this.screenData && this.screenData.event && this.screenData.event.location) {
            const loc = this.screenData.event.location;
            let eventLocation = '';
            eventLocation += loc.city + ', ' + loc.state + ' - ' + loc.address1;

            return eventLocation;
        } else {
            return null;
        }
    }

    get fastingState(): string {
        return this.screenData ? this.screenData.fastingState : null;
    }

    get fastingToken(): string {
        return Utils.getFastingStateToken(this.screenData.fastingState);
    }

    constructor(data) {
        this.screenData = data.screenData;
        this.labCollectionData = data.labCollectionData;
        this.riskStratification = data.riskStratification;
        this.screenDataDetails = data.screenDataDetail;
    }
}

export class ResultsContainer {
    results: Result[];
    resultDetailCode: string;
}

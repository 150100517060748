import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

import { environment } from '../../environments/environment';
import { Globals } from '../shared/globals';
import { Person } from '../shared/person/person';
import { SelfVerifyModal } from './verify-modal';

@Injectable()
export class SelfVerifyModalService {
    constructor(private _http: HttpClient) { }

    shouldSeeSelfVerifyModal(person: Person): boolean {
        if (!person) return false;

        return !person.participantKey && person.company.supportsLocalParticipantRegistration && this.hasNotSeenModalPopupIn90Days(person.user.selfVerifyPopupLastSeenDate);
    }
    get selfVerifyStyle() {
        return {
            border: `1.2px solid ${Globals.Colors.color2}`,
            borderRadius: '51%'
        };
    }
    private hasNotSeenModalPopupIn90Days(dateLastSeenPopup: Date): boolean {
        if (!dateLastSeenPopup) return true;

        const cloneOfDateLastSeenPopup = moment(dateLastSeenPopup).clone().startOf('day');
        const diffInDays = Math.abs(cloneOfDateLastSeenPopup.diff(moment().startOf('day'), 'days'));

        return diffInDays >= 90;
    }

    setSelfVerifyLastSeenDate(userKey: string) {
        const verifyModal: SelfVerifyModal = { userKey: userKey };
        return this._http.post(environment.baseCoreApiEndpoint + '/users/update-self-verify-last-seen-date', verifyModal);
    }
}

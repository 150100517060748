
import { HttpClient, HttpErrorResponse,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SecureMessageAccessRequest } from './secure-message-access-request';
import { SecureMessageAccessRequestStatus } from './secure-message-access-request-status';

@Injectable()
export class SecureMessageAccessRequestService {

  constructor(private _http: HttpClient) { }

  getAllAccessRequests(user: string, isCoach: boolean): Observable<SecureMessageAccessRequest[]> {
    return this._http.post<SecureMessageAccessRequest[]>(environment.baseSecureMessageAccessRequestApiEndpoint + '/search', {
      coachUserKey: isCoach ? user : null,
      participantUserKey: !isCoach ? user : null
    }).pipe(
      map((reqs: SecureMessageAccessRequest[]) => {
        return reqs.map(req => {
          return new SecureMessageAccessRequest(req);
        });
      }),
      catchError(this.handleError),);
  }

  getAllAccessRequestsPending(): Observable<SecureMessageAccessRequest[]> {
    return this._http.post<SecureMessageAccessRequest[]>(environment.baseSecureMessageAccessRequestApiEndpoint + '/search', {
      participantUserKey: null,
      status: SecureMessageAccessRequestStatus.Pending
    }).pipe(
      map((reqs: SecureMessageAccessRequest[]) => {
        return reqs.map(req => {
          return new SecureMessageAccessRequest(req);
        });
      }),
      catchError(this.handleError),);
  }

  createAllAccessRequest(participantKey: string): Observable<SecureMessageAccessRequest> {
    return this._http.post<SecureMessageAccessRequest>(environment.baseSecureMessageAccessRequestApiEndpoint, {
      requestedUserKey: participantKey
    }).pipe(
      catchError(this.handleError));
  }

  grantAllAccessRequest(key: string) {
    return this._http.post(environment.baseSecureMessageAccessRequestApiEndpoint + '/' + key + '/status?status=' + SecureMessageAccessRequestStatus.Granted, {
      id: key,
      status: SecureMessageAccessRequestStatus.Granted
    }).pipe(catchError(this.handleError));
  }

  rejectAllAccessRequest(key: string) {
    return this._http.post(environment.baseSecureMessageAccessRequestApiEndpoint + '/' + key + '/status?status=' + SecureMessageAccessRequestStatus.Rejected, {
      id: key,
      status: SecureMessageAccessRequestStatus.Rejected
    }).pipe(catchError(this.handleError));
  }

  cancelAllAccessRequest(key: string) {
    return this._http.post(environment.baseSecureMessageAccessRequestApiEndpoint + '/' + key + '/status?status=' + SecureMessageAccessRequestStatus.Canceled, {
      id: key,
      status: SecureMessageAccessRequestStatus.Canceled
    }).pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ICoordinate,IReverseGeocodeResponseItem, IUsAutoCompleteLookupRequest, IUsAutoCompleteSuggestion } from './smartystreets.models';

@Injectable()
export class SmartyStreetsService {
  usReverseGeocodeBaseUrl: string;
  usAutoCompleteBaseUrl: string;
  constructor(private _http: HttpClient) {
    this.usReverseGeocodeBaseUrl = environment.usReverseGeocodeBaseUrl;
    this.usAutoCompleteBaseUrl = environment.usAutoCompleteBaseUrl;
  }

  autoCompleteAddress(query: string, maxResults: number, selected: string): Observable<IUsAutoCompleteSuggestion[]> {
    const request: IUsAutoCompleteLookupRequest = {
      maxResults: maxResults,
      query: query,
      selected: selected
    };
    return this._http.post<IUsAutoCompleteSuggestion[]>(this.usAutoCompleteBaseUrl, request)
      .pipe(
        catchError((error) => {
          console.error('error:', error);
          return of([]);
        })

      );

  }

  reverseGeocodeLocation(coordinate: ICoordinate): Observable<IReverseGeocodeResponseItem[]> {
    return this._http.post<IReverseGeocodeResponseItem[]>(this.usReverseGeocodeBaseUrl, coordinate);
  }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-GapsInCare">{{ title }}</h4>
    <button type="button" class="close" id="actionId-CloseGapsInCare" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    {{ 'gapsincaremodalconfirmation' | lesCustomLocalize : 'message' }}
</div>

<div class="modal-footer pt-10">
    <button type="button" class="tertiary-bg btn btn-secondary" id="actionId-CloseGapsInCare" (click)="close()">{{ 'close' | lesCustomLocalize : 'label' }}</button>
</div>

import { Component, Input,OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';
import { Person } from '../../shared/person/person';
import { PersonService } from '../../shared/person/person.service';
import { Utils } from '../../shared/utils';
import { ColumnSetting } from '../../shared/wcs-table/column-setting';
import { Result } from '../model/result';
import { ResultLookup, ScreenType } from '../model/result-lookup';
import { ResultsService } from '../results.service';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.css']
})
export class ResultListComponent implements OnInit, OnDestroy {
  @Input() daysToShow;
  @Input() pageSize = 5;

  localizeReady = false;
  resultsLoaded = false;
  lookupLoaded = false;
  readyForRender = false;
  hasError = false;
  subscriptions: ISubscription[] = [];
  currentUser: Person;
  resultList: Result[] = [];
  resultLookup: ResultLookup;


  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'resultDate',
      headerLocalizeProp: 'recorddate',
      showOnMobile: true,
      sortable: true,
      format: 'dateasis'
    },
    {
      valueProp: 'resultsPending',
      headerLocalizeProp: undefined,
      showOnMobile: true,
      sortable: false,
      format: 'localizeToken',
      cssClass: 'warning-text'
    },
    {
      valueProp: 'screenTypeName',
      headerLocalizeProp: 'type',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'dateOfService',
      headerLocalizeProp: 'dateofservice',
      showOnMobile: true,
      sortable: true,
      format: 'dateasis'
    },
    {
      valueProp: 'eventLocation',
      headerLocalizeProp: 'eventlocation',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'viewdetails',
        onClick: this.viewDetail
      },
      thisContext: this,
      sortable: false
    }
  ];

  constructor(private _localizeService: LocalizeService, private _resultService: ResultsService,
    private _personService: PersonService, public _router: Router) {
  }

  public viewDetail(res: Result) {
    this._router.navigate(['results/' + res.key]);
  }

  public getScreenType(res: Result) {
    if (res.screenData && res.screenData.screenTypeId) {
      const screenTypeId = res.screenData.screenTypeId;
      const screenTypes = this.resultLookup.screenTypes;

      if (screenTypes && screenTypes.length) {
        const thisType = _.find(screenTypes, (st: ScreenType) => {
          return st.screenTypeId === screenTypeId && st.languageCode.toLowerCase() === this.currentUser.locale.languageLocale;
        });

        return thisType ? thisType : null;
      }
    } else {
      return null;
    }
  }

  private onError() {
    this.hasError = true;
  }

  private checkReady() {
    const ready = this.localizeReady && this.resultsLoaded && this.currentUser !== null && this.lookupLoaded;

    if (ready) {
      const screenTypes = this.resultLookup.screenTypes;

      _.forEach(this.resultList, (res: Result) => {
        const thisType: ScreenType = _.find(screenTypes, (st: ScreenType) => {
          return st.screenTypeId === res.screenData.screenTypeId && st.languageCode.toLowerCase() === this.currentUser.locale.languageLocale;
        });

        if (thisType) {
          res.screenData.screenTypeName = thisType.displayName;
        }
      });
    }

    this.readyForRender = ready;
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe(isReady => {
      this.localizeReady = true;
      this.checkReady();
    },
      error => this.onError()));

    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentUser = currentPerson;
        this.checkReady();
      }
    }));

    this.subscriptions.push(this._resultService.results.subscribe(resultsContainer => {
      if (resultsContainer) {
        if (this.daysToShow) {
          const today = new Date();
          this.resultList = resultsContainer.results.filter((result) => {
            const resultDate = new Date(result.screenData.dateofService);
            return Utils.daysBetween(resultDate, today) <= this.daysToShow;

          });
        } else {
          this.resultList = resultsContainer.results;
        }

        this.resultsLoaded = true;
        this.checkReady();
      }
    }));

    this.subscriptions.push(this._resultService.resultLookup.subscribe(lookup => {
      if (lookup) {
        this.resultLookup = lookup;

        this.lookupLoaded = true;
        this.checkReady();
      }
    }));

    this.subscriptions.push(this._resultService.resultError.subscribe(resultsError => {
      if (resultsError) {
        this.resultList = [];
        this.resultsLoaded = false;
        this.onError();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(function (sub) {
      sub.unsubscribe();
    });
  }
}

<div class="modal-header">
    <h4 class="secondary-text modal-title" id="titleId-CoachEdit">{{ 'edit' | lesCustomLocalize : 'label' }}</h4>
    <button type="button" class="tertiary-bg close" id="actionId-CloseCoachEdit" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form #editForm="ngForm" id="formId-CoachEdit" (ngSubmit)="updateCoach()">
        <div class="row">
            <div class="col-sm-6 order-2 order-sm-1">
                <div class="form-group row mt-0">
                    <label class="col-md-3 col-form-label" id="formInputId-CoachFirstName" for="firstName"
                        ><b>{{ 'firstname' | lesCustomLocalize : 'label' }}</b></label
                    >
                    <div class="col-md-9">
                        <input type="text" readonly class="form-control" name="firstName" [(ngModel)]="coach.firstName" />
                    </div>
                </div>
                <div class="form-group row mt-0">
                    <label class="col-md-3 col-form-label" id="formInputId-CoachLastName" for="lastName"
                        ><b>{{ 'lastname' | lesCustomLocalize : 'label' }}</b></label
                    >
                    <div class="col-md-9">
                        <input type="text" readonly class="form-control" name="lastName" [(ngModel)]="coach.lastName" />
                    </div>
                </div>
                <div class="form-group row mt-0">
                    <label class="col-md-3 col-form-label" id="formInputId-CoachEmailAddress" for="email"
                        ><b>{{ 'emailaddress' | lesCustomLocalize : 'label' }}</b></label
                    >
                    <div class="col-md-9">
                        <input type="text" readonly class="form-control" name="email" [(ngModel)]="coach.email" />
                    </div>
                </div>
                <div class="form-group row mt-0">
                    <label class="col-md-3 col-form-label" id="formInputId-CoachNameSuffix" for="suffix"
                        ><b>{{ 'suffix' | lesCustomLocalize : 'label' }}</b></label
                    >
                    <div class="col-md-9">
                        <input type="text" class="form-control" name="suffix" [(ngModel)]="coach.suffix" />
                    </div>
                </div>
                <div class="form-group row mt-0">
                    <label class="col-md-3 col-form-label" id="formInputId-GeneralInboxMonitor" for="isGeneralInbox"
                        ><b>{{ 'generalinboxmonitor' | lesCustomLocalize : 'label' }}</b></label
                    >
                    <div class="col-md-4">
                        <select class="form-control" name="isGeneralInbox" [(ngModel)]="coach.isGeneralInboxMonitor">
                            <option value="false">{{ 'no' | lesCustomLocalize : 'label' }}</option>
                            <option value="true">{{ 'yes' | lesCustomLocalize : 'label' }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 order-1 order-sm-2">
                <div *ngIf="coachImageDataURL">
                    <div class="row">
                        <div class="profile-image-container mx-auto align-self-center">
                            <img class="img-fluid" src="{{ coachImageDataURL }}" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="mx-auto">
                            <a class="" href="javascript:void(0);" id="actionId-UpdateCoachPhoto" (click)="file.click()">
                                {{ 'updatephoto' | lesCustomLocalize : 'label' }}
                            </a>
                            /
                            <a class="" href="javascript:void(0);" id="actionId-RemoveCoachPhoto" (click)="removePhoto()">
                                {{ 'removephoto' | lesCustomLocalize : 'label' }}
                            </a>
                        </div>
                    </div>
                </div>
                <div *ngIf="!coachImageDataURL" class="uploadArea d-flex">
                    <div class="mx-auto align-self-center">
                        <a href="javascript:void(0);" id="actionId-InsertCoachPhoto" (click)="file.click()">
                            {{ 'insertphoto' | lesCustomLocalize : 'label' }}
                        </a>
                    </div>
                </div>
                <input type="file" #file (change)="uploadPhoto($event)" style="display: none" />
                <div *ngIf="imageError">
                    <div class="alert alert-danger text-center">{{ 'uploadimagexbyxpixels' | lesCustomLocalize : 'message' : maxImageSizeX : maxImageSizeY }}</div>
                </div>
            </div>
        </div>

        <div class="form-group row mt-0">
            <div class="col-sm-12">
                <label class="col-form-label" id="actionId-CoachBiography" for="biography"
                    ><b>{{ 'biography' | lesCustomLocalize : 'label' }}</b></label
                >
                <quill-editor [(ngModel)]="coach.biography" name="biography" bounds="#new-biography-body" [style]="{ height: '300px' }" #biographyBody="ngModel">
                    <div quill-editor-toolbar>
                        <span class="ql-formats">
                            <button class="tertiary-bg ql-bold" [title]="'Bold'"></button>
                            <button class="tertiary-bg ql-italic" [title]="'Italic'"></button>
                            <button class="tertiary-bg ql-underline" [title]="'Underline'"></button>
                            <button class="tertiary-bg ql-strike" [title]="'Strikethrough'"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="tertiary-bg ql-indent" value="-1"></button>
                            <button class="tertiary-bg ql-indent" value="+1"></button>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-header" [title]="'Heading'">
                                <option selected></option>
                                <option value="1"></option>
                                <option value="2"></option>
                                <option value="3"></option>
                                <option value="4"></option>
                                <option value="5"></option>
                                <option value="6"></option>
                            </select>
                            <select class="ql-align" [title]="'Aligment2'">
                                <option selected></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button class="tertiary-bg ql-list" value="ordered"></button>
                            <button class="tertiary-bg ql-list" value="bullet"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="tertiary-bg ql-link" [title]="'Link'"></button>
                        </span>
                    </div>
                </quill-editor>
            </div>
        </div>

        <div class="modal-footer pt-10">
            <button type="button" class="tertiary-bg btn" id="actionId-CancelCoachEdit" (click)="activeModal.close('Close click')">{{ 'cancel' | lesCustomLocalize : 'label' }}</button>
            <button type="submit" class="tertiary-bg btn btn-primary" id="actionId-UpdateCoachEdit" [disabled]="disableSubmit || !editForm.valid">
                {{ 'update' | lesCustomLocalize : 'label' }}
            </button>
        </div>
    </form>
</div>

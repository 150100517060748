import { Component, EventEmitter, Input, OnDestroy,OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { of,SubscriptionLike as ISubscription } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AddressValidationModalComponent } from '../../shared/address-validation-modal/address-validation-modal.component';
import { AddressValidationSuggestion } from '../../shared/address-validation-modal/address-validation-suggestion';
import { AddressAutoCompleteComponent } from '../../shared/addressautocomplete/addressautocomplete.component';
import { AddressAutoCompleteSettings } from '../../shared/addressautocomplete/addressautocomplete-settings';
import { Gender } from '../../shared/gender/gender';
import { GenderIdentity, SexualOrientation } from '../../shared/gender/SOGI';
import { Globals } from '../../shared/globals';
import { LocalizeService } from '../../shared/localize/localize.service';
import { Ethnicity } from '../../shared/metadata/ethnicity';
import { MetadataService } from '../../shared/metadata/metadata.service';
import { Race } from '../../shared/metadata/race';
import { Person } from '../../shared/person/person';
import { IUsAutoCompleteSuggestion } from '../../shared/smartystreets/smartystreets.models';
import { State } from '../../shared/state/state';
import { UserService } from '../../shared/user/user.service';
import { UserAddressValidation, UserInformation, UserInformationPut, UserInformationSubmits } from '../../shared/user/user-information';
import { UserDataCaptureResult } from '../../user-data-capture-modal/user-data-capture';
import { PersonWidgetCollectionWidget, WidgetCloseAction, WidgetCollectionPosition, WidgetCompleted } from '../person.widget.models';

@Component({
    selector: 'app-person-demographic-widget',
    templateUrl: './person-demographic-widget.component.html',
    styleUrls: ['./person-demographic-widget.component.css']
})
export class PersonDemographicWidgetComponent implements OnInit, OnDestroy {
    @Input() personWidget: PersonWidgetCollectionWidget;
    @Input() currentPerson: Person;
    @Input() otherPerson?: Person;
    @Input() programServiceKey: string;
    @Input() programServiceTypeKey: string;
    @Input() personKey: string;
    @Output() onWidgetCompleted = new EventEmitter<any>();
    @Output() onUpdateAttempted = new EventEmitter<any>();
    userInformation: UserInformation = new UserInformation();
    races: Race[];
    ethnicities: Ethnicity[];
    isPersonRender = false;
    states: State[];
    userDataCaptureResult: UserDataCaptureResult = new UserDataCaptureResult();
    subscriptions: ISubscription[] = [];
    _userKey: string;
    showError = false;
    isRenderReady = false;
    errorList: any;
    errorMsg: string = null;
    genderList: Gender[];
    filteredGenderList: Gender[];
    sexualOrientationList: SexualOrientation[];
    genderIdentityList: GenderIdentity[];
    isSOGI = false;
    isFormInvalid = false;
    modalRef: NgbModalRef;
    person(): Person {
        return this.otherPerson ? this.otherPerson : this.currentPerson;
    }
    validationAddressRequest: UserAddressValidation;
    // Smarty Autocomplete
    @ViewChild(AddressAutoCompleteComponent) demographicAddressAutocompleteQuery: AddressAutoCompleteComponent;
    addressVerificationCompleted = false;
    addressVerificationRequired = false;
    demographicAddressForm: FormGroup;
    demographicAddressAutocompleteSettings: AddressAutoCompleteSettings;
    demographicForm: FormGroup;
    //

    get userKey(): string {
        return this._userKey;
    }
    constructor(
        public activeModal: NgbActiveModal,
        private _metadataService: MetadataService,
        private _userService: UserService,
        private _localizeService: LocalizeService,
        private formBuilder: FormBuilder,
        private _modalService: NgbModal) {
        _metadataService.getEthinicities();
        _metadataService.getRaces();
        _metadataService.getStates();
        _metadataService.getSexualOrientationList();
        _metadataService.getGenderIdentityList();
    }
    public get tagOptions() {
        return `{"programServiceTypeKey":"${this.programServiceTypeKey}","programServiceKey":"${this.programServiceKey}"}`;
    }
    ngOnInit(): void {

        // Auto-complete feature
        this.demographicAddressAutocompleteSettings = new AddressAutoCompleteSettings();
        this.demographicAddressAutocompleteSettings.formControlId = 'demographicAddressAutocompleteQuery';
        this.demographicAddressAutocompleteSettings.inputFormatter = (v) => {
            return this.demographicAddressAutoCompleteInputFormatter(v);
        };
        this.demographicAddressAutocompleteSettings.resultFormatter = (s) => {
            return this.demographicAddressAutoCompleteResultFormatter(s);
        };
        this.demographicAddressAutocompleteSettings.selectItem = (e) => {
            this.demographicAddressAutoCompleteSelect(e);
        };
        this.demographicAddressAutocompleteSettings.typeaheadMinLength = 4;
        this.demographicAddressAutocompleteSettings.placeHolderText = this._localizeService.get('pscaddressplaceholder', 'label');
        this.demographicAddressAutocompleteSettings.autoComplete = 'nothankyou';
        this.demographicAddressAutocompleteSettings.classList = 'form-control ng-pristine ng-invalid ng-touched';
        this.demographicAddressAutocompleteSettings.requiredField = true;
        //


        this.subscriptions.push(
            this._metadataService.ethnicityList.subscribe((data) => {
                this.ethnicities = data;
            })
        );
        this.subscriptions.push(
            this._metadataService.raceList.subscribe((data) => {
                this.races = data;
            })
        );
        this.subscriptions.push(
            this._metadataService.stateList.subscribe((data) => {
                this.states = data;
                this.loadDemographics();
            })
        );
        this.subscriptions.push(
            this._metadataService.sexualOrientaionList.subscribe((data) => {
                this.sexualOrientationList = data;
            })
        );
        this.subscriptions.push(
            this._metadataService.genderIdentityList.subscribe((data) => {
                this.genderIdentityList = data;
            })
        );

    }
    loadDemographics() {
        this._userService.getDemographicWidgetInformation(this.person().userKey).subscribe(
            (info) => {
                if (info != null) {
                    this.userInformation = info;
                }
                if (!this.isValidGuid(this.userInformation.userKey)) {
                    this.userInformation.userKey = this.person().userKey;
                }

                this.createForm();
                this.checkForSOGI();
                this.validateAddressOnLoad();
                this.isReadyForRender();
            },
            (err) => {
                this.onError();
            }
        );
    }
    checkForSOGI() {
        let stateKey = this.userInformation.stateKey;
        if (!stateKey) {
            const state = this.states.find((t) => t.abbreviation == this.person().state);
            if (state) stateKey = state.key;
        }
        if (Globals.SOGIStates.indexOf(stateKey) > -1) {
            this.isSOGI = true;

        }
        this.onGenderChange();
        this.onSexualOrientationChange();

    }
    get putInformation() {
        return new UserInformationPut(this.userInformation);
    }

    get postInformation() {
        this.addUserKey();
        return new UserInformationSubmits(this.userInformation);
    }
    localizeMessage(msgTag) {
        let errorTag = 'unknownerror';
        if (msgTag) {
            try {
                errorTag = msgTag.split('.')[1];
            } catch (error) { }
        }
        return errorTag;
    }
    localizeValidationErrors(err) {
        let errorName = this.localizeMessage(err.value);
        if (errorName !== 'unknownerror') errorName = err.key + errorName;
        return errorName;
    }

    private closePanel(complete: boolean, action: WidgetCloseAction) {
        //send back result
        const result = new WidgetCompleted();
        result.dataCollectionKey = this.personWidget.dataCollectionKey;
        result.widgetKey = this.personWidget.widgetKey;
        result.isComplete = complete;
        result.action = action;
        result.widgetPanelIndex = this.personWidget.widgetPanelIndex;
        this.onWidgetCompleted.emit(result);
    }
    onPreviousButtonClick() {
        //if button is present
        if (this.personWidget.position == WidgetCollectionPosition.first) return;

        this.processAction(WidgetCloseAction.backward, false);
    }
    closeError() {
        this.showError = false;
        this.isFormInvalid = false;
    }
    onStateKeyChange(e: string) {
        if (!e) return;
        if (Globals.SOGIStates.indexOf(e) > -1) {
            this.isSOGI = true;
        } else {
            this.isSOGI = false;
        }
        this.onGenderChange();
        this.onSexualOrientationChange();
    }

    onStateChangeByAbbreviation(e: string) {
        if (!e) return;
        const selectedState = this.states.find(x => x.abbreviation == e);
        this.userInformation.stateAbbreviation = selectedState.abbreviation;
        this.userInformation.stateKey = selectedState.key;
        this.onStateKeyChange(this.userInformation.stateKey);
        return selectedState.key;
    }

    private isValid(): boolean {
        let isValidForm = true;
        if (!this.demographicFormValid) isValidForm = false;
        if (isValidForm && !this.demographicAddressFormValid) {
            this.checkAddressInputValidity();
            if (!this.demographicAddressFormValid) isValidForm = false;
        }
        if (!isValidForm) this.isFormInvalid = true;
        return isValidForm;
    }

    get demographicFormValid() {
        return this.demographicForm.valid;
    }
    get demographicAddressFormValid() {
        return this.demographicAddressForm.valid;
    }

    /*
    clean record on inital submit. If user returns and changes state, do not clean responses.
    */
    private cleanSubmit(info: UserInformationSubmits) {
        if (Globals.SOGIStates.indexOf(info.stateKey) < 0) {
            info.genderIdentityKey = null;
            info.genderIdentityOther = null;
            info.sexualOrientationKey = null;
            info.sexualOrientationOther = null;
        }
        return info;
    }
    private processAction(next: WidgetCloseAction, validate = true) {
        if (!validate) {
            return this.closePanel(false, next);
        }
        if (this.userInformation.key && this.isValidGuid(this.userInformation.key)) {
            this.validationAddressRequest = new UserAddressValidation(this.userInformation);
            this._userService.validateUserInformation(this.validationAddressRequest).subscribe(
                (result) => {
                    this.userInformation.address1 = result.address1;
                    this.userInformation.address2 = result.address2;
                    this.userInformation.city = result.city;
                    this.userInformation.zip = result.zip;
                    this.userInformation.stateAbbreviation = result.stateAbbreviation;
                    this.userInformation.stateKey = this.states.find((t) => t.abbreviation == result.stateAbbreviation).key;
                    const putModel = this.putInformation;
                    if (result.valid) {

                        this._userService.updateUserInformation(putModel).subscribe(
                            (result) => {
                                if (result.errorMessage && result.errors) {
                                    this.onError(result.errorMessage, result.errors);
                                    return;
                                } else {
                                    this.closePanel(true, next);
                                }
                            },
                            (error) => {
                                this.onError(error);
                            }
                        );
                    } else {
                        const address: AddressValidationSuggestion = {
                            address1: putModel.address1,
                            address2: putModel.address2,
                            city: putModel.city,
                            zip: putModel.zip,
                            stateAbbreviation: putModel.stateAbbreviation
                        };
                        this.displayVerificationError(address).then((result: boolean) => {
                            if (result) {
                                this._userService.updateUserInformation(putModel).subscribe(
                                    (result) => {
                                        if (result.errorMessage && result.errors) {
                                            this.onError(result.errorMessage, result.errors);
                                            return;
                                        } else {
                                            this.closePanel(true, next);
                                        }
                                    },
                                    (error) => {
                                        this.onError(error);
                                    }
                                );
                            }

                        });




                    }
                },
                (error) => {
                    this.onError(error);
                }
            );

        } else {
            this.validationAddressRequest = new UserAddressValidation(this.userInformation);
            this._userService.validateUserInformation(this.validationAddressRequest).subscribe(
                (result) => {
                    this.userInformation.address1 = result.address1;
                    this.userInformation.address2 = result.address2;
                    this.userInformation.city = result.city;
                    this.userInformation.zip = result.zip;
                    this.userInformation.stateAbbreviation = result.stateAbbreviation;
                    this.userInformation.stateKey = this.states.find((t) => t.abbreviation == result.stateAbbreviation).key;
                    const postModel = this.cleanSubmit(this.postInformation);

                    if (result.valid) {
                        this._userService.createUserInformation(postModel).subscribe(
                            (result) => {
                                if (result.errorMessage && result.errors) {
                                    this.onError(result.errorMessage, result.errors);
                                    return;
                                } else {
                                    this.closePanel(true, next);
                                }
                            },
                            (error) => {

                                this.onError(error);
                            }
                        );
                    } else {
                        const address: AddressValidationSuggestion = {
                            address1: postModel.address1,
                            address2: postModel.address2,
                            city: postModel.city,
                            zip: postModel.zip,
                            stateAbbreviation: postModel.stateAbbreviation
                        };
                        this.displayVerificationError(address).then((result: boolean) => {
                            if (result) {
                                this._userService.createUserInformation(postModel).subscribe(
                                    (result) => {
                                        if (result.errorMessage && result.errors) {
                                            this.onError(result.errorMessage, result.errors);
                                            return;
                                        } else {
                                            this.closePanel(true, next);
                                        }
                                    },
                                    (error) => {

                                        this.onError(error);
                                    }
                                );
                            }

                        });



                    }
                },
                (error) => {
                    this.onError(error);
                }
            );
        }
    }
    onNextButtonClick() {
        if (this.isValid()) {
            this.updateUserInformationFromForm();
            this.processAction(WidgetCloseAction.forward, true);
        } else {
            this.showError = true;
        }
    }

    displayVerificationError(address: AddressValidationSuggestion): Promise<boolean> {
        this.isRenderReady = true;
        return new Promise((resolve, reject) => {
            this.modalRef = this._modalService.open(AddressValidationModalComponent, {
                backdrop: 'static'
            });
            this.modalRef.componentInstance.addressSuggestion = address;
            this.modalRef.result.then((result) => {
                const choice = result as boolean;
                resolve(choice);
            }).catch((error) => {
                console.error('Error: ', error)
                reject(error);
            });
        });
    }
    private onError(message: any = null, list: any = null) {
        this.showError = true;
        if (list) {
            this.errorList = list;
        }
        if (message) {
            this.errorMsg = message;
        }
        this.onUpdateAttempted.emit(this.personWidget.widgetPanelIndex);
    }
    addUserKey() {
        if (!this.isValidGuid(this.userInformation.userKey)) {
            this.userInformation.userKey = this.person().userKey;
        }
    }
    isValidGuid(guid: string): boolean {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(guid);
    }
    getNextMessage() {
        if (this.personWidget.position == WidgetCollectionPosition.last) {
            return this.getTranslation('button.submit');
        }
        return this.getTranslation('button.next');
    }
    getTranslation(tag: string) {
        return this._localizeService.getCombinedTag(tag, null, null, null, this.tagOptions);
    }
    updateUserInformationFromForm() {
        this.userInformation.address1 = this.demographicAddressForm.controls.demographicAddressAutocompleteQuery.value;
        this.userInformation.address2 = this.demographicAddressForm.controls.address2.value;
        this.userInformation.city = this.demographicAddressForm.controls.city.value;
        this.userInformation.zip = this.demographicAddressForm.controls.zipCode.value;
        this.userInformation.ethnicityKey = this.demographicForm.controls.ethnicityKey.value;
        this.userInformation.raceKey = this.demographicForm.controls.raceKey.value;
        if (this.isSOGI) {
            if (this.demographicForm.controls.genderIdentityKey.value != null && this.demographicForm.controls.genderIdentityKey.value !== '') {
                this.userInformation.genderIdentityKey = this.demographicForm.controls.genderIdentityKey.value;
            }
            if (this.demographicForm.controls.sexualOrientationKey.value != null && this.demographicForm.controls.sexualOrientationKey.value !== '') {
                this.userInformation.sexualOrientationKey = this.demographicForm.controls.sexualOrientationKey.value;
            }
            if (this.demographicForm.controls.sexualOrientationOther.value != null && this.demographicForm.controls.sexualOrientationOther.value !== '') {
                this.userInformation.sexualOrientationOther = this.demographicForm.controls.sexualOrientationOther.value;
            }
            if (this.demographicForm.controls.genderIdentityOther.value != null && this.demographicForm.controls.genderIdentityOther.value !== '') {
                this.userInformation.genderIdentityOther = this.demographicForm.controls.genderIdentityOther.value;
            }
        }
        this.userInformation.phone = this.demographicForm.controls.phoneNumber.value;
        const state = this.states.find((t) => t.key == this.demographicAddressForm.controls.stateKey.value);
        this.userInformation.stateKey = state.key;
        this.userInformation.stateAbbreviation = state.abbreviation;
    }

    // SMARTY AUTO-COMPLETE LOGIC
    private createForm(): void {
        this.demographicForm = this.formBuilder.group({
            phoneNumber: [this.userInformation.phone ? this.userInformation.phone : '', Validators.required],
            raceKey: [this.userInformation.raceKey ? this.userInformation.raceKey : null, Validators.required],
            ethnicityKey: [this.userInformation.ethnicityKey ? this.userInformation.ethnicityKey : null, Validators.required],
            genderIdentityKey: [this.userInformation.genderIdentityKey ? this.userInformation.genderIdentityKey : null],
            sexualOrientationKey: [this.userInformation.sexualOrientationKey ? this.userInformation.sexualOrientationKey : null],
            genderIdentityOther: [this.userInformation.genderIdentityOther ? this.userInformation.genderIdentityOther : null],
            sexualOrientationOther: [this.userInformation.sexualOrientationOther ? this.userInformation.sexualOrientationOther : null]
        });

        this.demographicAddressForm = this.formBuilder.group({
            demographicAddressAutocompleteQuery: [this.userInformation.address1 ? this.userInformation.address1 : '', Validators.required],
            address2: [this.userInformation.address2],
            city: [this.userInformation.city, Validators.required],
            stateKey: [this.userInformation.stateKey, Validators.required],
            zipCode: [this.userInformation.zip, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]]
        });
    }


    demographicAddressAutoCompleteSelect(event: NgbTypeaheadSelectItemEvent) {
        const suggestion = event.item as IUsAutoCompleteSuggestion;
        const streetLine = suggestion.secondary
            ? `${suggestion.street} ${suggestion.secondary}`
            : suggestion.street;
        if (parseInt(suggestion.entries) > 1) {
            event.preventDefault();
            this.demographicAddressAutocompleteSettings.selected = `${streetLine} (${suggestion.entries}) ${suggestion.city} ${suggestion.state} ${suggestion.zipCode}`;
            this.demographicAddressAutocompleteQuery.onTypeaheadQuery(of(streetLine))
                .pipe(debounceTime(0))
                .subscribe(
                    (response: IUsAutoCompleteSuggestion[]) => {
                        if (response && response.length > 1) {
                            this.demographicAddressAutocompleteQuery.selectedSuggestions = response;
                        }
                        this.demographicAddressAutocompleteQuery.restoreFocusToInput();
                    },
                    (error) => {
                        console.error('Error Fetched: ', error);
                    }
                );
            this.demographicAddressForm.controls.demographicAddressAutocompleteQuery.setValue(`${streetLine}`);
            this.demographicAddressForm.controls.city.setValue('');
            this.demographicAddressForm.controls.stateKey.setValue('');
            this.demographicAddressForm.controls.zipCode.setValue('');
            this.demographicAddressForm.controls.address2.setValue('');
        }
        else {
            this.demographicAddressAutocompleteSettings.selected = "";
            this.demographicAddressAutocompleteQuery.value = suggestion.street;
            this.demographicAddressForm.controls.demographicAddressAutocompleteQuery.setValue(suggestion.street);
            this.demographicAddressForm.controls.city.setValue(suggestion.city);
            this.demographicAddressForm.controls.stateKey.setValue(this.onStateChangeByAbbreviation(suggestion.state));
            this.demographicAddressForm.controls.zipCode.setValue(suggestion.zipCode);
            if (suggestion.secondary != null && suggestion.secondary.trim().length > 0) this.demographicAddressForm.controls.address2.setValue(suggestion.secondary);
            else { this.demographicAddressForm.controls.address2.setValue("") }
            this.demographicAddressAutoCompleteValidate();
        }
    }
    demographicAddressAutoCompleteValidate() {
        if (this.demographicAddressAutocompleteQuery && this.demographicAddressAutocompleteQuery.checkInnerValue && this.demographicAddressAutocompleteQuery.checkInnerValue.length > 4 && this.demographicAddressAutocompleteQuery.innerValue != this.demographicAddressAutocompleteQuery.checkInnerValue) {
            this.demographicAddressForm.controls.demographicAddressAutocompleteQuery.setValue(this.demographicAddressAutocompleteQuery.checkInnerValue);
        }

        this.demographicAddressAutocompleteSettings.classList = 'form-control ng-pristine ng-valid ng-touched';
    }

    demographicAddressAutoCompleteInValidate() {
        this.demographicAddressAutocompleteSettings.classList = 'form-control ng-dirty ng-invalid ng-touched';
    }

    checkAddressInputValidity() {
        if (this.demographicAddressAutocompleteQuery.checkInnerValue.trim().length < 4) {
            this.demographicAddressAutoCompleteInValidate();
            this.demographicAddressForm.controls.city.setValue('');
            this.demographicAddressForm.controls.stateKey.setValue('');
            this.demographicAddressForm.controls.zipCode.setValue('');
            this.demographicAddressForm.controls.address2.setValue('');
        } else {
            this.demographicAddressAutoCompleteValidate();
        }
    }
    validateAddressOnLoad() {
        if (this.trim(this.demographicAddressForm.controls.demographicAddressAutocompleteQuery.value).length > 4 &&
            this.trim(this.demographicAddressForm.controls.city.value).length > 0 &&
            this.trim(this.demographicAddressForm.controls.stateKey.value).length > 0 &&
            this.trim(this.demographicAddressForm.controls.zipCode.value).length > 0) {
            this.demographicAddressAutoCompleteValidate();
        }
        else {
            this.demographicAddressAutoCompleteInValidate();
            this.demographicAddressForm.controls.demographicAddressAutocompleteQuery.setValue('');
            this.demographicAddressForm.controls.city.setValue('');
            this.demographicAddressForm.controls.stateKey.setValue('');
            this.demographicAddressForm.controls.zipCode.setValue('');
            this.demographicAddressForm.controls.address2.setValue('');
        }
    }


    trim(value) {
        if (value) {
            return value.replace(/^\s+|\s+$/g, "");
        }
        else return "";
    }

    demographicAddressAutoCompleteInputFormatter(value: IUsAutoCompleteSuggestion) {
        if (value) {
            return value.street
        }
        return;
    }

    demographicAddressAutoCompleteResultFormatter(value: IUsAutoCompleteSuggestion) {
        let streetLine = value.secondary != null && value.secondary.trim().length > 0
            ? `${value.street} ${value.secondary}`
            : value.street;

        if (parseInt(value.entries) > 1) {
            streetLine += ` (${value.entries} more entries)`;
        }

        return `${streetLine} ${value.city}, ${value.state} ${value.zipCode}`;
    }

    validateAllFields() {
        this.validateAllDemographicFields(this.demographicForm);
    }

    validateAllDemographicFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
                control.updateValueAndValidity();
            }
        });
    }

    onSexualOrientationChange() {
        const sexualOrientationControl = this.demographicForm.get('sexualOrientationKey');
        sexualOrientationControl.updateValueAndValidity();
        // Check if this qualifies for SOGI
        if (this.isSOGI) {
            if (!this.isValidGuid(this.demographicForm.controls.sexualOrientationKey.value)) {
                sexualOrientationControl.setErrors({ required: true });
            } else {
                this.userInformation.sexualOrientationKey = this.demographicForm.controls.sexualOrientationKey.value;
            }
            sexualOrientationControl.setValidators([Validators.required]);
        }
        else {
            sexualOrientationControl.clearValidators();
            sexualOrientationControl.updateValueAndValidity();
        }
    }

    onGenderChange(): void {
        const genderIdentityControl = this.demographicForm.get('genderIdentityKey');
        genderIdentityControl.updateValueAndValidity();
        // Check if this qualifies for SOGI
        if (this.isSOGI) {
            if (!this.isValidGuid(this.demographicForm.controls.genderIdentityKey.value)) {
                genderIdentityControl.setErrors({ required: true });
            } else {
                this.userInformation.genderIdentityKey = this.demographicForm.controls.genderIdentityKey.value;
            }
            genderIdentityControl.setValidators([Validators.required]);
        }
        else {
            genderIdentityControl.clearValidators();
            genderIdentityControl.updateValueAndValidity();
        }
    }

    //

    private isReadyForRender(): void {
        this.isRenderReady = true;
    }
    ngOnDestroy() {
        this.subscriptions.forEach(function (sub) {
            sub.unsubscribe();
        });
    }
}

import { Company } from '../company/company';
import { Globals } from '../globals';
import { Locale } from '../localize/locale';
import { Partner } from '../partner/partner';
import { UserServices } from '../service';

export class User {
  key: string;
  participantKey: string;
  photo: string;
  firstName: string;
  lastName: string;
  locale: Locale;
  company: Company;
  partner: Partner;
  email: string;
  preferredEmailAddress: string;
  languageLocale: string;
  phone: string;
  textMessagePhone: string;
  textUseSettingsPhone: boolean;
  biography: string;
  suffix: string;
  image: string;
  personKey: string;
  timeZone: string;
  isCoach: boolean;
  isCoachAdmin: boolean;
  isParticipant: boolean;
  isGeneralInboxMonitor: boolean;
  isCustomerAdmin: boolean;
  isPartnerAdmin: boolean;
  isCoaching: boolean;
  isWcsAdmin: boolean;
  isSupportUser: boolean;
  userServices: UserServices[];
  gender: string;
  dateOfBirth: Date;
  marketingEmailOptIn: boolean;
  acceptedTOUPP: boolean;
  selfVerifyPopupLastSeenDate: Date;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  get fullName(): string {
    return this.firstName && this.lastName ? this.firstName + ' ' + this.lastName : null;
  }

  get hasPhoto(): boolean {
    return this.image ? true : false;
  }

  get hasBio(): boolean {
    return this.biography ? true : false;
  }

  get hasSchedulerAccess(): boolean {
    if (!this.isParticipant) {
      return true;
    } else {
      const schedServices = this.userServices.filter(c => {
        return c.programService.serviceType === Globals.ServiceTypes.OnlineScheduler;
      });
      const gicPSC = this.userServices.filter(c => {
        const programOrderableTest = c.programService.programOrderableTests;
        if (!programOrderableTest) return null;

        const hasPsc = programOrderableTest.some(t => t.orderableTestModality === 'PSC Visit');
        if (hasPsc) return c;
      })
      return schedServices.length > 0 || gicPSC && gicPSC.length > 0;
    }
  }

  get hasOnlineScheduler(): boolean {
    const onlineSchedServices = this.userServices.filter(c => {
      return c.programService.isOnlineScheduler;
    });

    return onlineSchedServices.length > 0;
  }

  constructor(config) {
    this.key = config.key;
    this.participantKey = config.participantKey;
    this.photo = config.photo;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.locale = config.locale;
    this.company = config.company;
    this.partner = config.partner;
    this.personKey = config.personKey;
    this.email = config.email;
    this.preferredEmailAddress = config.preferredEmailAddress;
    this.biography = config.biography;
    this.suffix = config.suffix;
    this.image = config.image;
    this.timeZone = config.timeZone ? config.timeZone.name.replace(' ', '_') : null;
    this.userServices = config.userServices;
    this.gender = config.gender;
    this.dateOfBirth = config.dateOfBirth;
    this.marketingEmailOptIn = config.marketingEmailOptIn;
    this.acceptedTOUPP = config.acceptedTOUPP;
    this.phone = config.phone;
    this.textMessagePhone = config.textMessagePhone;
    this.textUseSettingsPhone = config.textUseSettingsPhone;
    this.selfVerifyPopupLastSeenDate = config.selfVerifyPopupLastSeenDate;
    if (config.roles) {
      const roles = config.roles.map(function (role) {
        return role.toLowerCase();
      });
      this.isCoach = roles.indexOf('coach') >= 0;
      this.isGeneralInboxMonitor = roles.indexOf('generalinboxmonitor') >= 0;
      this.isParticipant = roles.indexOf('participant') >= 0;
      this.isCoachAdmin = roles.indexOf('coachadmin') >= 0;
      this.isCustomerAdmin = roles.indexOf('customeradmin') >= 0;
      this.isPartnerAdmin = roles.indexOf('partneradmin') >= 0;
      this.isWcsAdmin = roles.indexOf('wcsadmin') >= 0;
      this.isSupportUser = roles.indexOf('wcssupport') >= 0;
      this.isCoaching = this.isCoach || this.isCoachAdmin;
    }
    this.address1 = config.address1;
    this.address2 = config.address2;
    this.city = config.city;
    this.state = config.state;
    this.zipCode = config.zip;
  }
}

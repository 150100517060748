(function (apiKey) {
    (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
                o[m] =
                    o[m] ||
                    function () {
                        o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                    };
            })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
})('0f277ff3-0be1-4a1c-4b5b-981d03bbb0c4');

function pendoBootstrap(pendouser) {
    // This function creates visitors and accounts in Pendo
    initPendo(pendouser);
    function initPendo(data) {
        if (data.visitor) {
            pendo.initialize({
                visitor: getVisitor(data.visitor),
                account: getAccount(data.account)
            });
        } else {
            pendo.initialize({
                account: getAccount(data.account)
            });
        }
        function getVisitor(visitor) {
            var _visitor = Object.assign({}, visitor);
            return _visitor;
        }
        function getAccount(data) {
            return {
                id: data.id,
                name: data.name,
                partner: data.partner,
                loginType: data.loginType,
                matchCriteria: data.matchCriteria,
                status: data.status,
                resultsAccess: data.resultsAccess,
                resultsCommunication: data.resultsCommunication
            };
        }
    }
}

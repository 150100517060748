import { StandardClassification } from "../metadata/standardclassification";
import { State } from "../state/state";

export class UserInformationBase {
    userKey: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    stateKey: string;
    zip: string;
    ethnicityKey: string;
    raceKey: string;
    genderIdentityKey: string;
    genderIdentityOther: string;
    sexualOrientationKey: string;
    sexualOrientationOther: string;
    personKey: string;
    participantKey : string;
    stateAbbreviation : string;
    emailAddress: string;
    personAddressKey: string;
}
export class UserInformation extends UserInformationBase {
    state: State;
    ethnicity: StandardClassification;
    race: StandardClassification;
    genderIdentity: StandardClassification;
    sexualOrientation: StandardClassification;
    key: string;
}
export class UserInformationSubmits extends UserInformationBase {

    constructor(userInformation: UserInformation) {
        super()
        this.userKey = userInformation.userKey;
        this.address1 = userInformation.address1;
        this.address2 = userInformation.address2;
        this.city = userInformation.city;
        this.stateKey = userInformation.stateKey;
        this.zip = userInformation.zip;
        this.ethnicityKey = userInformation.ethnicityKey;
        this.raceKey = userInformation.raceKey
        this.phone = userInformation.phone;
        this.genderIdentityKey = userInformation.genderIdentityKey;
        this.genderIdentityOther = userInformation.genderIdentityOther;
        this.sexualOrientationKey = userInformation.sexualOrientationKey;
        this.sexualOrientationOther = userInformation.sexualOrientationOther;
        this.personKey = userInformation.personKey;
        this.participantKey = userInformation.participantKey;
        this.stateAbbreviation = userInformation.stateAbbreviation;
        this.emailAddress = userInformation.emailAddress;
        this.personAddressKey = userInformation.personAddressKey;
    }
}

export class UserInformationPut extends UserInformationBase {
    key: string;
    constructor(userInformation: UserInformation) {
        super()
        this.key = userInformation.key;
        this.userKey = userInformation.userKey;
        this.address1 = userInformation.address1;
        this.address2 = userInformation.address2;
        this.city = userInformation.city;
        this.stateKey = userInformation.stateKey;
        this.zip = userInformation.zip;
        this.ethnicityKey = userInformation.ethnicityKey;
        this.raceKey = userInformation.raceKey;
        this.phone = userInformation.phone;
        this.genderIdentityKey = userInformation.genderIdentityKey;
        this.genderIdentityOther = userInformation.genderIdentityOther;
        this.sexualOrientationKey = userInformation.sexualOrientationKey;
        this.sexualOrientationOther = userInformation.sexualOrientationOther;
        this.personKey = userInformation.personKey;
        this.participantKey = userInformation.participantKey;
        this.stateAbbreviation = userInformation.stateAbbreviation;
        this.emailAddress = userInformation.emailAddress;
        this.personAddressKey = userInformation.personAddressKey;
    }
}
export class UserInformationRequestVerification {
    constructor(public programServiceKeys: string[], public userKey: string) { }
}
export class UserInformationResult {
    key: string;
    errorMessage: string;
    errors: any[]
}

export class UserAddressValidation extends UserInformationBase {
    constructor(userInformation: UserInformation) {
        super()
        this.address1 = userInformation.address1;
        this.address2 = userInformation.address2;
        this.city = userInformation.city;
        this.zip = userInformation.zip;
        this.stateAbbreviation = userInformation.stateAbbreviation;
    }
}

export class UserAddressValidationResult {
    valid: boolean;
    address1: string;
    address2: string;
    city: string;
    stateAbbreviation: string;
    zip: string;
}

import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { environment } from '../../environments/environment';
import { Globals } from '../shared/globals';
import { LocalStorageService } from '../shared/local-storage-service';
import { Person } from '../shared/person/person';
import { PersonService } from '../shared/person/person.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  showLogin = false;
  readyForRender = false;

  constructor(private _oauthService: OAuthService,
    private _personService: PersonService) { }

  ngOnInit() {
    const currentUser = this._personService.getCurrentPerson();
    this._oauthService.logOut(true);
    LocalStorageService.deleteValue(Globals.SessionValues.WCPlusTranslations);
    const logout = '/account/logout' + (currentUser && currentUser.company && currentUser.company.companyURLToken ?
      '?company=' + currentUser.company.companyURLToken : '') + '&language=' + currentUser.locale.languageLocale;
    window.location.href = environment.identityProvider + logout;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalizeService } from '../localize/localize.service';
import { AddressValidationSuggestion } from './address-validation-suggestion';

@Component({
    selector: 'app-address-validation-modal',
    templateUrl: './address-validation-modal.component.html',
    styleUrls: ['./address-validation-modal.component.css']
})
export class AddressValidationModalComponent implements OnInit {
    @Input() header: string = this._localizeService.get('invalidAddressValidation', 'label');
    @Input() addressSuggestion: AddressValidationSuggestion;
    @Input() continueBtn: string = this._localizeService.get('continue', 'button');
    @Input() editBtn: string = this._localizeService.get('editAddress', 'label');
    @Input() invalidMsg: string = this._localizeService.get('verifyinvalidaddress', 'message');
    @Output() addressValidationOverride: EventEmitter<boolean> =  new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal, private _localizeService: LocalizeService) { }

    ngOnInit() {
      // empty -- nothing to do
    }

    setFlag(value: boolean) {
        this.addressValidationOverride.emit(value);
        this.activeModal.close(value);
    }

}

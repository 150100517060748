<ng-container *ngIf="readyForRender">
    <h3 class="primary-text">{{ 'reporting' | lesCustomLocalize : 'label' }}</h3>

    <div class="reportsContainer">
        <div *ngFor="let report of userReports" class="reportContainer col-md-4">
            <a class="nav-link" routerLink="/reporting/{{ report.name }}" routerLinkActive="active">
                <div class="report">
                    <div class="report-image">
                        <img src="{{ report.previewImageURL }}" />
                    </div>
                    <div class="report-name">
                        {{ report.name }}
                    </div>
                </div>
            </a>
        </div>
    </div>
</ng-container>

<app-loading *ngIf="!readyForRender && !hasError && !hasReportedModalError"></app-loading>

<div class="alert alert-danger" role="alert" *ngIf="hasError">
    <span *ngIf="lesCustomLocalizeReady"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'unknownerror' | lesCustomLocalize : 'message' }}</span>
    <span *ngIf="!lesCustomLocalizeReady"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> An unknown error has occurred. Please reload the page and try again.</span>
</div>

import { CustomizedData } from './customized-data';

export class TextOverride implements CustomizedData {
  key: string;
  companyKey: string;
  category: string;
  token: string; // includes 'category' with dot notation.
  name: string;
  description: string;
  default: string;
  languageLocale: string;
  localeName: string;
  maxCustomLength = 500;
  text: string;

  get hasOverride() {
    return this.text !== null && typeof this.text !== 'undefined';
  }

  get customizedDataObj() {
    return {
      companyKey: this.companyKey,
      token: this.token,
      text: this.text,
      language: this.languageLocale
    };
  }
}

<ng-container *ngIf="readyForRender">
    <h3 class="primary-text">{{ 'support' | lesCustomLocalize : 'label' }}</h3>

    <p [innerHTML]="'supportmessage' | lesCustomLocalize : 'message'"></p>
</ng-container>

<app-loading *ngIf="!readyForRender && !hasError"></app-loading>

<div class="alert alert-danger" role="alert" *ngIf="hasError">
    <span *ngIf="lesCustomLocalizeReady"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{ 'unknownerror' | lesCustomLocalize : 'message' }}</span>
    <span *ngIf="!lesCustomLocalizeReady"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> An unknown error has occurred. Please reload the page and try again.</span>
</div>

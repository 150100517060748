
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ServiceQualificationResult } from '../../services/services-list/service-qualification/service-qualification';

@Injectable()
export class IntegrationService {
    constructor(private _http: HttpClient) { }

    getPixel(programServiceKey: string, qualificationRuleOverrideCode: string = null): Observable<ServiceQualificationResult> {
        let params = new HttpParams();
        params = params.append('programServiceKey', programServiceKey);
        params = params.append('overrideCode', qualificationRuleOverrideCode);
        return this._http
            .get<ServiceQualificationResult>(environment.baseCoreApiEndpoint + '/integrations/pixel', {
                params: params
            }).pipe(
                catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        return observableThrowError(() => errorMessage);
    }
}

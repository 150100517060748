import _ from 'lodash';

import { Globals } from '../../shared/globals';
import { Appointment } from './appointment';
import { ProgramServiceAndType } from './program-service-and-type';

export class ItineraryParticipant {
    ordinal: number;
    userKey: string;
    firstName: string;
    lastName: string;
    appointments: Appointment[] = [];

    constructor(ordinal: number) {
        this.ordinal = ordinal;
    }
}

export class ItineraryAppointment {
    slotKey: string;
    ownerKey: string;
    onBehalfOfFirstName: string;
    onBehalfOfLastName: string;
    programServiceTypeKey: string;
    programServiceKey: string;
    programServiceType:ProgramServiceAndType
    eventKey: string;
    slotTime: Date;
    dateSummaryTimeId: string;
    alreadyHasAppointmentInFuture: boolean;
    existingAppointmentKey: string;
}

export class ItinerarySubmission {
    appointments: ItineraryAppointment[] = [];
    rescheduleExistingEnabled = false;

    hasAppointmentsInFuture(): boolean {
        let hasAppointmentInFuture = false;

        _.each(this.appointments, (appt: ItineraryAppointment) => {
            if (appt.alreadyHasAppointmentInFuture) {
                hasAppointmentInFuture = true;
            }
        });

        return hasAppointmentInFuture;
    }
}

export class Itinerary {
    participants: ItineraryParticipant[] = [];
    ownerKey: string;
    private _submission: ItinerarySubmission;

    get numberOfAppointments() {
        let count = 0;

        for (const participant of this.participants) {
            count += participant.appointments.length;
        }

        return count;
    }

    get forSubmission(): ItinerarySubmission {
        this._submission = new ItinerarySubmission();

        _.each(this.participants, (participant: ItineraryParticipant) => {
            _.each(participant.appointments, (appt: Appointment) => {
                const newAppt = new ItineraryAppointment();
                newAppt.ownerKey = this.ownerKey;
                newAppt.onBehalfOfFirstName = participant.ordinal > 1 ? participant.firstName : null;
                newAppt.onBehalfOfLastName = participant.ordinal > 1 ? participant.lastName : null;
                newAppt.programServiceTypeKey = appt.programServiceType.programServiceTypeKey;
                newAppt.programServiceKey = appt.programServiceKey;
                newAppt.dateSummaryTimeId = appt.dateSummaryTimeId;
                newAppt.slotTime = appt.time;
                newAppt.eventKey = appt.eventKey;
                newAppt.alreadyHasAppointmentInFuture = appt.alreadyHasAppointmentInFuture;
                newAppt.programServiceType = appt.programServiceType;
                this._submission.appointments.push(newAppt);
            });
        });

        return this._submission;
    }

    constructor(ownerKey: string) {
        this.ownerKey = ownerKey;
    }

    isValid(): boolean {
        let valid = true;

        _.each(this.participants, (part: ItineraryParticipant) => {
            if (part.ordinal > 1 && (!part.firstName || !part.lastName) && part.appointments.length > 0) {
                valid = false;
            }
        });

        return valid;
    }

    hasServiceType(serviceType: string, participantIndex: number): boolean {
        let hasServiceType = false;

        _.each(this.participants, (part: ItineraryParticipant) => {
            if (part.ordinal === participantIndex) {
                _.each(part.appointments, (appt: Appointment) => {
                    if (appt.programServiceType && appt.programServiceType.programServiceTypeKey === serviceType) {
                        hasServiceType = true;
                    }
                });
            }
        });

        return hasServiceType;
    }

    addAppointment(appt: Appointment): boolean {
        let participantFound = false;
        let apptAdded = false;

        for (let i = 0; i < this.participants.length; i++) {
            if (this.participants[i].ordinal === appt.participantOrdinal) {
                participantFound = true;
                break;
            }
        }

        if (!participantFound) {
            const newParticipant = new ItineraryParticipant(appt.participantOrdinal);
            this.participants.push(newParticipant);
        }

        for (let i = 0; i < this.participants.length; i++) {
            if (this.participants[i].ordinal === appt.participantOrdinal) {
                this.participants[i].appointments.push(appt);
                apptAdded = true;
                break;
            }
        }

        return apptAdded;
    }

    markAppointmentAsInvalid(eventKey: string, slotTime: Date, isOnBehalfOf: boolean, invalidReason: number) {
        _.each(this.participants, (participant: ItineraryParticipant) => {
            if (isOnBehalfOf && !participant.lastName) {
                return false;
            }

            _.each(participant.appointments, (appt: Appointment) => {
                if (appt.eventKey == eventKey && new Date(appt.time).getTime() == new Date(slotTime).getTime()) {
                    if (invalidReason === Globals.AppointmentInvalidReasons.SlotTaken) {
                        appt.alreadyTaken = true;
                    } else if (invalidReason === Globals.AppointmentInvalidReasons.ExistingFutureAppointment) {
                        appt.alreadyHasAppointmentInFuture = true;
                    } else if (invalidReason === Globals.AppointmentInvalidReasons.ConsentNeeded) {
                        appt.consentNeeded = true;
                    } else if (invalidReason === Globals.AppointmentInvalidReasons.Conflict) {
                        appt.hasConflict = true;
                    }
                }
            });
        });
    }

    removeAppointment(programServiceTypeKey: string, participantOrdinal: number, dateSummaryTimeId: string): Date {
        let dateRemoved: Date;

        const participant: ItineraryParticipant = _.find(this.participants, (thisParticipant: ItineraryParticipant) => thisParticipant.ordinal === participantOrdinal);

        if (participant) {
            const apptToRemove: Appointment[] = participant.appointments.filter((thisAppt) => thisAppt.dateSummaryTimeId === dateSummaryTimeId && thisAppt.programServiceType.programServiceTypeKey === programServiceTypeKey);

            if (apptToRemove && apptToRemove.length) {
                dateRemoved = apptToRemove[0].time;

                participant.appointments = participant.appointments.filter((thisAppt) => thisAppt.programServiceType.programServiceTypeKey !== programServiceTypeKey);
            }
        }

        return dateRemoved;
    }

    removeAppointments() {
        if (this.participants.length > 0) {
            this.participants.forEach((participant) => {
                if (participant.appointments.length > 0) {
                    participant.appointments.splice(0, participant.appointments.length);
                }
            });
        }
    }

    containsAppointment(participantOrdinal: number, programServiceTypeKey: string, time: Date): boolean {
        let contains = false;

        const participant: ItineraryParticipant = _.find(this.participants, (part: ItineraryParticipant) => part.ordinal === participantOrdinal);

        if (participant) {
            const appt = _.find(participant.appointments, (appointment: Appointment) => appointment.programServiceType.programServiceTypeKey === programServiceTypeKey && appointment.time === time);

            if (appt) {
                contains = true;
            }
        }

        return contains;
    }
}


import {User} from '../../shared/user/user';

export class SecureMessage {
  key: string;
  parentMessageKey: string;
  message: string;
  sentOn: Date;
  readOn: Date;
  sendingUser: User;
  receivingUser: User;
  regardingKey: string;
  childrenMessages: SecureMessage[];
  repliesTotalCount: number;
  unreadReceiveCount: number;
  hasFullThreadAccess: boolean;
  isGeneralInbox: boolean;

  commentLinkHover = false;
  showChildren = false;
  markAsReadInflight = false;

  // private _sendingUserPhoto: string;
  get sendingUserPhoto(): string {
    if (this.sendingUser && this.sendingUser.image) {
      return atob(this.sendingUser.image);
    } else {
      return undefined;
    }
  }

  private _sendingUserInitials: string;
  get sendingUserInitials(): string {
    if (this.sendingUser && this.sendingUser.firstName && this.sendingUser.lastName) {
      return this.sendingUser.firstName.substring(0, 1).toUpperCase() + this.sendingUser.lastName.substring(0, 1).toUpperCase();
    } else {
      return undefined;
    }
  }

  constructor(config) {
    this.key = config.key;
    this.message = config.message;
    this.sentOn = config.sentOn;
    this.readOn = config.readOn;
    this.sendingUser = config.sendingUser;
    this.receivingUser = config.receivingUser;
    this.childrenMessages = config.childrenMessages;
    this.repliesTotalCount = config.repliesTotalCount;
    this.unreadReceiveCount = config.unreadReceiveCount;
    this.hasFullThreadAccess = config.hasFullThreadAccess;
    this.isGeneralInbox = config.isGeneralInbox;
    this.parentMessageKey = config.parentMessageKey;
  }
}

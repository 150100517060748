
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Company } from './company';

@Injectable()
export class CompanyService {
  private allCompanySource = new BehaviorSubject<Company[]>(null);
  private companySource = new BehaviorSubject<Company>(null);

  allCompanies = this.allCompanySource.asObservable();
  company = this.companySource.asObservable();

  constructor(private _http: HttpClient) {
    this.fetchCompanies();
  }

  fetchCompanies() {
    this._http.get<Company[]>(environment.baseCoreApiEndpoint + '/companies')
      .subscribe(data => {
        this.allCompanySource.next(data);
      });
  }

  fetchCompany(key: string) {
    this._http.get<Company>(environment.baseCoreApiEndpoint + `/companies/${key}`).subscribe(data => {
      this.companySource.next(data)
    })
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return observableThrowError(() => errorMessage);
  }
}

export class NewSecureMessage {
  message: string;
  receivingUserKey: string;
  receivingUserFirstName: string;
  receivingUserLastName: string;
  receivingUserCompanyName: string;
  parentMessageKey: string;
  regardingKey: string;
  isGeneralInbox: boolean;
  unreads: string[]
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CustomizeService } from '../../customize/customize.service';
import { UITranslationView } from '../application-content/application-content-models';
import { ApplicationContentService } from '../application-content/application-content-service';
import { Globals } from '../globals';
import { Person } from '../person/person';
import { PersonService } from '../person/person.service';
import { CustomizedData } from './customized-data';
import { TextOverride } from './text-override';

@Injectable()
export class LocalizeService {
    private _overridesLoaded = false;
    private _iuTranslationLoaded = false;
    private _currentUser: Person;
    private _OtherUser: Person;
    private _uiTranslations: UITranslationView[];
    locale: string;

    private isReadySource = new BehaviorSubject<boolean>(false);
    isReady = this.isReadySource.asObservable();

    shouldRerenderSource = new BehaviorSubject<any>(undefined);
    shouldRerender = this.shouldRerenderSource.asObservable();
    _currentCompanyKey = 'CurrentPersonCompanyKey';

    constructor(private _http: HttpClient, private _personService: PersonService, private _customizeService: CustomizeService, private appContentService: ApplicationContentService) {
        //current person
        this._personService.currentPerson.subscribe((data) => {
            if (data) {
                this.locale = data.locale && data.locale.languageLocale ? data.locale.languageLocale : Globals.Locales.USEnglish;
                window.sessionStorage.setItem(this._currentCompanyKey, data.companyKey);
                this._currentUser = data;
                this.fetchUITranslationOverrides();
            } else {
                this.fetchAllUITranslations();
            }
        });

        this._personService.currentPersonError.subscribe((hasError) => {
            if (hasError) {
                // TODO: Correct assumption that if current person is in an error state here, then this will be caught downstream elsewhere?
                this.locale = Globals.Locales.USEnglish;
                this._overridesLoaded = true;
                this.checkReady();
            }
        });

        //other person
        //Do not set locale for 'other person'. Locale should always be the logged in person
        this._personService.otherPerson.subscribe((data) => {
            this._OtherUser = data;
            if (data) {
                this.fetchUITranslationOverrides();
            }
        });
    }

    get personOfInterest() {
        return this._OtherUser ? this._OtherUser : this._currentUser;
    }
    private fetchAllUITranslations() {
        this.appContentService.GetUITranslations().subscribe((data: UITranslationView[]) => {
            this._uiTranslations = data;
            this._iuTranslationLoaded = true;
            this.checkReady();
        });
    }
    private fetchUITranslationOverrides() {
        if (this.personOfInterest) {
            const companyKey = this.personOfInterest.company.key;
            if (companyKey) {
                this.appContentService.GetUITranslations(companyKey).subscribe((data) => {
                    this._uiTranslations = data;
                    this._overridesLoaded = true;
                    this.checkReady();
                    const sessionCompanyKey = window.sessionStorage.getItem(this._currentCompanyKey);
                    if (sessionCompanyKey != companyKey) {
                        window.sessionStorage.setItem(this._currentCompanyKey, companyKey);
                        this.shouldRerenderSource.next(true);
                    }
                });
            }
        }
    }

    private checkReady() {
        if (this._iuTranslationLoaded && this._overridesLoaded && this.locale) {
            this.isReadySource.next(true);
        }
    }

    getTermsOfUse(): Observable<string> {
        let params = new HttpParams();
        params = params.append('locale', this.locale);
        return this._http.get(environment.identityProvider + '/Registration/TermsOfUse', { params: params, responseType: 'text' });
    }

    getPrivacyPolicy(): Observable<string> {
        let params = new HttpParams();
        params = params.append('locale', this.locale);
        return this._http.get(environment.identityProvider + '/Registration/PrivacyPolicy', { params: params, responseType: 'text' });
    }

    getTagExists(token: string, tokenType: string, options = null): boolean {
        if (!token) {
            return false;
        }
        const translation = this.getApplicationContentTranslation(token, tokenType, options);
        if (!translation || translation === '*****tag missing*****') {
            return false;
        }
    }
    get(token: string, tokenType: string, replaceToken1: string = null, replaceToken2: string = null, replaceToken3: string = null, replaceToken4: string = null, options = null): string {
        if (!token) {
            return '';
        }
        //short cut

        if (options && typeof options == 'string') {
            options = JSON.parse(options);
        }
        let translation = this.getApplicationContentTranslation(token, tokenType, options);
        if (translation === '*****tag missing*****') {
            return tokenType ? tokenType + '.' + token : token;
        }
        if (!translation) {
            return '';
        }
        const re1 = /\$1/gi;
        const re2 = /\$2/gi;
        const re3 = /\$3/gi;
        const re4 = /\$4/gi;

        if (typeof replaceToken1 !== 'undefined' && replaceToken1 !== null) {
            translation = translation.replace(re1, replaceToken1);
        }

        if (typeof replaceToken2 !== 'undefined' && replaceToken2 !== null) {
            translation = translation.replace(re2, replaceToken2);
        }

        if (typeof replaceToken3 !== 'undefined' && replaceToken3 !== null) {
            translation = translation.replace(re3, replaceToken3);
        }

        if (typeof replaceToken4 !== 'undefined' && replaceToken4 !== null) {
            translation = translation.replace(re4, replaceToken4);
        }
        return translation;
    }
    /**
     *
     * @param tag
     * @param tokenType
     * @param replaceToken1
     * @param replaceToken2
     * @param options <span *ngIf="currentUser">{{'welcomeuser' | localize:'message' : currentUser.firstName : null : '{"programServiceTypeKey":"BEBE54B2-011F-44DF-AC33-5B281019F51D"}'}}</span>
     * @returns
     */
    getCombinedTag(tag: string, tokenType?: string, replaceToken1: string = null, replaceToken2: string = null, options = null) {
        if (!tag) return null;

        let replaceToken3 = null;
        let replaceToken4 = null;
        let parsedOptions = null;
        if (options) {
            parsedOptions = JSON.parse(options);
            if (Object.prototype.hasOwnProperty.call(parsedOptions, 'replaceToken3')) {
                replaceToken3 = parsedOptions['replaceToken3'];
            }
            if (Object.prototype.hasOwnProperty.call(parsedOptions, 'replaceToken4')) {
                replaceToken4 = parsedOptions['replaceToken4'];
            }
        }
        const tags = tag.split('.');
        if (tags.length == 2) {
            return this.get(tags[1], tags[0], replaceToken1, replaceToken2, replaceToken3, replaceToken4, parsedOptions);
        }
        if (tags.length > 2) {
            const theToken = tags[0];
            tags.splice(0, 1);
            const theTags = tags.join('.');
            return this.get(theTags, theToken, replaceToken1, replaceToken2, replaceToken3, replaceToken4, parsedOptions);
        }
        return this.get(tag, tokenType, replaceToken1, replaceToken2, replaceToken3, replaceToken4, parsedOptions);
    }
    private getApplicationContentTranslation(token: string, tokenType: string, options = null): string {
        if (this._uiTranslations == null || !this._uiTranslations.length) return '';

        // If contents below can't be found the code has an unknown tag (not in the table). Could also be tags used in widget
        // and data collections where the tag is not in the database and we want the tag itself to be displayed. Content
        // used in consent widgets is an example where consent language from the consent defiinition is sent as the
        // content tag. No tag exists in the content table and/ we simply want to render the tag itself.

        const contents = this._uiTranslations.filter((s: UITranslationView) => s.tag.toLowerCase() == token.toLowerCase() && s.category.toLowerCase() == tokenType.toLowerCase());
        // Translation tag not found.
        if (!contents || !contents.length) return '*****tag missing*****';

        //are there are locale tranlations
        let currentTranslation: UITranslationView = null;
        //this will be used if there are no customizations
        let currentTranslations: UITranslationView[] = contents.filter((x) => {
            return (x.programServiceTypeKey == null || x.programServiceTypeKey == undefined) && (x.programServiceKey == null || x.programServiceKey == undefined);
        });
        let programTypeLevelTranslations: UITranslationView[];
        let programLevelTranslations: UITranslationView[];
        //filter by options (program service type, program service)
        if (options) {
            const programServiceTypeKey = this.getObjectFieldCaseInsensitive(options, 'programservicetypekey');
            if (programServiceTypeKey) {
                programTypeLevelTranslations = contents.filter((x) => {
                    return x.programServiceTypeKey && x.programServiceTypeKey.toLowerCase() == programServiceTypeKey.toLowerCase() && (x.programServiceKey == null || x.programServiceKey == undefined);
                });
            }

            const programServiceKey = this.getObjectFieldCaseInsensitive(options, 'programservicekey');
            if (programServiceKey) {
                programLevelTranslations = contents.filter((x) => {
                    return x.programServiceKey && x.programServiceKey.toLowerCase() == programServiceKey.toLowerCase();
                });
            }
        }

        if (programLevelTranslations && programLevelTranslations.length > 0) {
            currentTranslations = programLevelTranslations;
        } else if (programTypeLevelTranslations && programTypeLevelTranslations.length > 0) {
            currentTranslations = programTypeLevelTranslations;
        }
        //get appropriate language
        if (this.locale) {
            currentTranslations = currentTranslations.filter((c) => c.locale.toLowerCase() == this.locale.toLowerCase());
        } else {
            currentTranslations = currentTranslations.filter((c) => c.locale.toLowerCase() == 'en-us');
        }

        //if more than one, select first one by company
        if (currentTranslations && currentTranslations.length > 1) {
            currentTranslation = currentTranslations.find((c) => c.companyKey != undefined && c.companyKey != null);
        } else {
            currentTranslation = currentTranslations[0];
        }

        // If the translation for this locale does not exist or has been deleted we have a data definition issue.
        // Return null so the tag itself will be displayed.
        if (!currentTranslation) return '*****tag missing*****';

        return currentTranslation.translation;
    }


    private findCustomizedTextOverride(item: TextOverride, overrides: CustomizedData[]): CustomizedData {
        const match = overrides.find(function (override) {
            return item.token === override.token && item.languageLocale === override.languageLocale;
        });

        return match;
    }

    private getObjectFieldCaseInsensitive(object, key) {
        return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())];
    }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from "lodash";
import moment from 'moment';
import { Subscription } from 'rxjs';

import { CoachingAppointmentService } from '../../../coaching/appointments/appointments.service';
import { LocalizeService } from '../../../shared/localize/localize.service';
import { Person } from '../../../shared/person/person';
import { PersonService } from '../../../shared/person/person.service';
import { ColumnSetting } from '../../../shared/wcs-table/column-setting';
import { WcsTableComponent } from '../../../shared/wcs-table/wcs-table.component';
import { ApiAppointment } from '../../model/api-appointment';
import { SchedulerService } from '../../scheduler.service';
import { CancelConfirmComponent } from '../cancel-confirm/cancel-confirm.component';
import { ResendConfirmationEmailComponent } from '../resend-confirmation-email/resend-confirmation-email.component';

@Component({
  selector: 'app-past-appointments',
  templateUrl: './past-appointments.component.html',
  styleUrls: ['./past-appointments.component.css']
})
export class PastAppointmentsComponent implements OnInit, OnDestroy {
  @ViewChild(WcsTableComponent) tableComponent: WcsTableComponent;
  @Input() otherUser: Person;
  @Input() disableIfUpcomingAppointment = false;

  hasError = false;
  readyForRender = false;
  localizeReady = false;
  appointmentsRetrieved = 0;
  isDisabled: boolean;
  subscriptions: Subscription[] = [];
  currentUser: Person;
  activeAppointments: ApiAppointment[] = [];
  inactiveAppointments: ApiAppointment[] = [];
  modalRef: NgbModalRef;

  displaySettings: ColumnSetting[] = [
    {
      valueProp: 'time',
      headerLocalizeProp: 'date',
      showOnMobile: true,
      sortable: true,
      format: 'datetimeasis'
    },
    {
      valueProp: 'timezone',
      headerLocalizeProp: 'timezone',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'serviceDisplayName',
      headerLocalizeProp: 'servicetype',
      format: 'programTypeToken',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'fastingToken',
      headerLocalizeProp: 'fasting',
      format: 'localizeToken',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'locationText',
      headerLocalizeProp: 'location',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'appointmentFor',
      headerLocalizeProp: 'appointmentfor',
      showOnMobile: true,
      sortable: true
    },
    {
      valueProp: 'isCanceled',
      headerLocalizeProp: 'canceled',
      showOnMobile: true,
      format: 'boolean',
      sortable: true
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'modify',
        onClick: this.modifyAppointment
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.isActiveAppointment,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'cancel',
        onClick: this.cancelAppointment
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showCancelAppointment,
          val: true
        }]
    },
    {
      valueProp: undefined,
      headerLocalizeProp: undefined,
      showOnMobile: true,
      format: 'link',
      link: {
        text: 'Resend',
        onClick: this.resendConfirmationEmail
      },
      thisContext: this,
      sortable: false,
      doShowValueFns: [
        {
          fn: this.showResendConfirmationEmail,
          val: true
        }]
    }
  ];

  constructor(private _localizeService: LocalizeService, private _schedulerService: SchedulerService,
    private _personService: PersonService, private _modalService: NgbModal, private _router: Router, private _apptService: CoachingAppointmentService) { }

  cancelAppointment(appt: ApiAppointment) {
    if (!appt.isCanceled) {
      const modalRef = this._modalService.open(CancelConfirmComponent, { backdrop: 'static' });
      modalRef.result.then((result) => {
        if (result === 'submit') {
          this.getAppointments();
          this.tableComponent.detectChanges();
        }
      });

      modalRef.componentInstance.currentUser = this.currentUser;
      modalRef.componentInstance.appointmentKey = appt.key;
      modalRef.componentInstance.appointmentUserKey = appt.owner.key;
    }
  }

  showCancelAppointment(appt: ApiAppointment) {
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();

    return appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  modifyAppointment(appt: ApiAppointment) {
    if (!appt.isCanceled) {
      const userKey = this.getUserOfInterest().userKey;
      if (this.currentUser.userKey === userKey) {
        this._router.navigate(['/scheduler']);
      }
      else {
        this._router.navigate(['/scheduler'], { queryParams: { user: userKey } });
      }
    }
  }

  isActiveAppointment(appt: ApiAppointment) {
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();

    return appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }

  checkReady() {
    if (this.localizeReady && this.appointmentsRetrieved > 2) {
      this.readyForRender = true;
    }
  }

  getAppointments() {
    this.appointmentsRetrieved = 0;

    this.subscriptions.push(this._schedulerService.getAppointments(this.getUserOfInterest().userKey).subscribe((appts) => {
      this.appointmentsRetrieved += 1;

      this.activeAppointments = this.activeAppointments.concat(appts.filter(appt => this.isActiveAppointment(appt)));
      if (this.disableIfUpcomingAppointment && this.activeAppointments.length) {
        this.isDisabled = true;
      }
      this.inactiveAppointments = this.inactiveAppointments.concat(appts.filter(appt => !this.isActiveAppointment(appt))).slice(0, 3);

      this.checkReady();

      if (this.tableComponent) {
        this.tableComponent.detectChanges();
      }
    }));

    this.subscriptions.push(this._schedulerService.getPscAppointments(this.getUserOfInterest().userKey).subscribe((appts) => {
      this.appointmentsRetrieved += 1;

      if (appts.length) {
        this.activeAppointments = this.activeAppointments.concat(appts.filter((appt) => this.isActiveAppointment(appt)));
        if (this.disableIfUpcomingAppointment && this.activeAppointments.length) {
          this.isDisabled = true;
        }
        this.inactiveAppointments = this.inactiveAppointments.concat(appts.filter((appt) => !this.isActiveAppointment(appt)));

        this.inactiveAppointments = _.sortBy(this.inactiveAppointments, function (o) {
          return moment(o.time);
        }).reverse().slice(0, 3);
      }

      this.checkReady();

      if (this.tableComponent) {
        this.tableComponent.detectChanges();
      }
    }));
    this.subscriptions.push(this._apptService.getUpcomingAppts(this.getUserOfInterest().userKey, false, this.currentUser).subscribe((appts) => {
      this.appointmentsRetrieved += 1;

      if (appts.length) {
        this.activeAppointments = this.activeAppointments.concat(appts.filter((appt) => this.isActiveAppointment(appt)));
        if (this.disableIfUpcomingAppointment && this.activeAppointments.length) {
          this.isDisabled = true;
        }
        this.activeAppointments = _.sortBy(this.activeAppointments, function (o) {
          return moment(o.time);
        });
      }

      this.checkReady();

      if (this.tableComponent) {
        this.tableComponent.detectChanges();
      }
    }));
  }

  getUserOfInterest() {
    return this.otherUser ? this.otherUser : this.currentUser;
  }

  ngOnInit() {
    this.subscriptions.push(this._personService.currentPerson.subscribe(currentPerson => {
      if (currentPerson) {
        this.currentUser = currentPerson;
        this.getAppointments();
      }
    }));

    this.subscriptions.push(this._localizeService.isReady.subscribe((isReady) => {
      if (isReady) {
        this.localizeReady = true;
        this.checkReady();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  resendConfirmationEmail(appt: ApiAppointment) {
    const modalRef = this._modalService.open(ResendConfirmationEmailComponent, { backdrop: 'static' });
    modalRef.result.then((result) => {
      if (result === 'submit') {
        this.getAppointments();
        this.tableComponent.detectChanges();
      }
    });

    modalRef.componentInstance.currentUser = this.currentUser;
    modalRef.componentInstance.appointmentKey = appt.key;
    modalRef.componentInstance.appointmentUserKey = appt.owner.key;
  }

  showResendConfirmationEmail(appt: ApiAppointment) {
    const apptUtc = moment.utc(moment(appt.timeUtc).format());
    const nowUtc = moment.utc();
    const validUser = (this.currentUser.isWcsAdmin || this.currentUser.isSupportUser
      || this.currentUser.isPartnerAdmin || this.currentUser.isCustomerAdmin);

    if (appt.appointmentType === "PatientServiceCenter") {
      return false;
    }
    return validUser && appt.isCanceled === false && nowUtc.isBefore(apptUtc);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle } from '@ng-idle/core';


@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.css']
})
export class TimeoutModalComponent implements OnInit {
  numberOfMinutes: number;

  constructor(private _router: Router, private idle: Idle, public activeModal: NgbActiveModal) { }

  logout() {
    this._router.navigate(['logout']);
    this.activeModal.close();
  }

  ngOnInit() {
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.numberOfMinutes = Math.ceil(countdown / 60);
    });
  }

}

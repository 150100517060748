import { Component, OnDestroy,OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { LocalizeService } from '../../shared/localize/localize.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy {
  readyForRender = false;
  hasError = false;
  subscriptions: ISubscription[] = [];

  constructor(private _localizeService: LocalizeService) { }

  private onError() {
    this.hasError = true;
  }

  ngOnInit() {
    this.subscriptions.push(this._localizeService.isReady.subscribe( isReady => {
      this.readyForRender = true;
    },
    error => this.onError()));
  }


  ngOnDestroy() {
    this.subscriptions.forEach(function(sub) {
      sub.unsubscribe();
    });
  }
}
